import React from "react";
import Page from "../../../layout/Page";
import {
  Modal,
  Icon,
  Button,
  DropdownItemProps,
  Segment,
  Header,
} from "semantic-ui-react";
import Result from "../../../../common/repository/Result";
import { toast } from "../../../common/Toast";
import Table from "../../../app/Table";
import RequirePermission from "../../../base/RequirePermission";
import permissions from "../../../../common/permissions";

import UploadBulkDispatchForm from "./UploadBulkDispatchForm";
import DispatchTemplateDownloadForm from "./DispatchTemplateDownloadForm";
import DocDispatchUpdateForm from "./DocDispatchUpdateForm";
import DocDispatchDocInfoForm from "./DocDispatchDocInfoForm";
import DocDispatchSection from "../../../../models/DocDispatchSection";
import DocDispatchSectionColumnRenderer from "./DocDispatchSectionColumnRenderer";
import Filters from "./Filters";
import FilterPageContext from "./FilterPageContext";
import ClientRepository from "../../../../common/repository/ClientRepository";
import DocDispatchSectionRepository, {
  GetDocDispatchSectionRequestObject,
} from "../../../../common/repository/DocDispatchSectionRepository";
import CaseTypeRepository from "../../../../common/repository/CaseTypeRepository";

interface State {
  loading: boolean;

  filter: string;
  limit: number;
  offset: number;
  totalCount: number;
  docDispatchSection: DocDispatchSection[];

  docInfo_modalOpen: boolean;
  update_modalOpen: boolean;
  update_model: DocDispatchSection | null;

  remove_modalOpen: boolean;
  remove_model: DocDispatchSection | null;

  downloadTemplateModalOpen: boolean;
  uploadTemplateModalOpen: boolean;

  loadingEssentialData: boolean;
  clientOptions: DropdownItemProps[];
  caseTypeOptions: DropdownItemProps[];
  clientFilter: string;
  caseTypeFilter: string;
  claimNumberFilter: string;
  fromFilter: string;
  toFilter: string;

  searchByDispatchPending: boolean;

  bulkUploadResponseStatus: boolean;
  bulkUploadResponse: number[];
  Reinvestigation: boolean
  Pushback: boolean
}

class DocDispatchSectionPage extends React.Component<{}, State> {
  private docDispatchSectionRepo = new DocDispatchSectionRepository();
  private clientRepo = new ClientRepository();
  private caseTypeRepo = new CaseTypeRepository();
  private filters: any;

  private columnRenderer = new DocDispatchSectionColumnRenderer(
    (model: DocDispatchSection) => {
      let receipt = <a className={"ui button"}>NA</a>;
      if (model.getDispatch() != null) {
        receipt = (
          <a
            className={"ui primary button"}
            href={`${model.getDispatch()!.getSlipUrl()}`}
            target={"_blank"}
          >
            View
          </a>
        );
      }
      return (
        <>
          {receipt}
          <Button
            positive
            onClick={() =>
              this.setState({ update_modalOpen: true, update_model: model })
            }
          >
            Open
          </Button>
          <Button
            primary
            onClick={() =>
              this.setState({ docInfo_modalOpen: true, update_model: model })
            }
          >
            Info
          </Button>
        </>
      );
    },
    (obj: any) => true
  );

  constructor(props: any) {
    super(props);

    this.filters = props.history.location.state;
    this.state = {
      loading: false,
      limit: Table.ROWS_PER_PAGE[Table.ROWS_PER_PAGE.length - 1] * 2,
      offset: 0,
      filter: "",
      totalCount: 0,
      docDispatchSection: [],
      docInfo_modalOpen: false,
      update_modalOpen: false,
      update_model: null,
      downloadTemplateModalOpen: false,
      uploadTemplateModalOpen: false,
      remove_modalOpen: false,
      remove_model: null,

      loadingEssentialData: true,
      clientOptions: [],
      caseTypeOptions: [],
      clientFilter: "",
      caseTypeFilter: "",
      claimNumberFilter: "",
      fromFilter: "",
      toFilter: "",

      bulkUploadResponse: [],
      bulkUploadResponseStatus: false,
      searchByDispatchPending: false,

      Reinvestigation: false,
      Pushback: false,
    };
  }

  async componentDidMount() {
    this.loadData();
    await this.effectLoadEssentialData();
  }

  private setFilter = (filter: string) => {
    this.setState({ filter, offset: 0 }, this.loadData);
  };

  private clearAllFilters = async () => {
    await this.setState({
      limit: 0,
      offset: 0,
      caseTypeFilter: "",
      clientFilter: "",
      claimNumberFilter: "",
      fromFilter: "",
      toFilter: "",
      Reinvestigation: false,
      Pushback: false,
    });
    await this.loadData();
  };

  private onDocDispatchSectionUpdated = async (prop: DocDispatchSection) => {
    const docDispatchSection = this.state.docDispatchSection;
    const index = docDispatchSection.findIndex(
      (it) => it.getId() === prop.getId()
    );
    if (index !== -1) docDispatchSection[index] = prop;

    this.setState({
      docDispatchSection: [...docDispatchSection],
      update_modalOpen: false,
      update_model: null,
    });
  };

  private getDocDispatchSection = async () => {
    const ro: GetDocDispatchSectionRequestObject = {
      limit: this.state.limit,
      offset: this.state.offset,
    };

    if (this.state.filter) ro.search = this.state.filter;
    if (this.state.searchByDispatchPending)
      ro.dispatchPending = this.state.searchByDispatchPending;

    if (this.state.claimNumberFilter)
      ro.claim_number = this.state.claimNumberFilter;
    if (this.state.clientFilter) ro.client_id = this.state.clientFilter;
    if (this.state.caseTypeFilter) ro.case_type_id = this.state.caseTypeFilter;
    if (this.state.fromFilter) ro.from = this.state.fromFilter;
    if (this.state.toFilter) ro.to = this.state.toFilter;
    if (this.state.Reinvestigation) ro.reinvestigation = this.state.Reinvestigation
    if (this.state.Pushback) ro.pushback = this.state.Pushback
    return await this.docDispatchSectionRepo.getData(ro);
  };

  private bulkUploadResponse = (rs: any) => {
    if ((rs as number[]).length > 0)
      this.setState({
        uploadTemplateModalOpen: false,
        bulkUploadResponseStatus: true,
        bulkUploadResponse: rs,
      });
    else this.setState({ uploadTemplateModalOpen: false });
    this.loadData();
  };

  /**
   * loads new data
   * if offset is 0 then set it as new list
   * else appends to the old list
   */
  private loadData = async () => {
    if (
      this.state.caseTypeFilter ||
      this.state.searchByDispatchPending ||
      this.state.fromFilter ||
      this.state.toFilter ||
      this.state.clientFilter ||
      this.state.claimNumberFilter ||
      this.state.filter ||
      this.state.Pushback ||
      this.state.Reinvestigation
    ) {
      await this.setState({ offset: 0 });
    }
    let offset = this.state.offset;

    this.setState({ loading: true }, async () => {
      const result = await this.getDocDispatchSection();

      if (result instanceof Result.Success) {
        if (offset === 0) {
          //if offset 0 initialize as new list
          const invoice = result.data.items;
          const totalCount = result.data.totalItemCount;
          this.setState({
            loading: false,
            offset: invoice.length,
            docDispatchSection: invoice,
            totalCount: totalCount,
          });
        } else {
          //else check for duplicate items in the new data
          // and then concat with the old
          const docDispatchSection = [
            ...this.state.docDispatchSection,
            ...result.data.items,
          ];
          const offset = docDispatchSection.length;
          this.setState({ loading: false, offset, docDispatchSection });
        }
      } else {
        const message = result.message || "Something went wrong";
        this.setState({ loading: false }, () => toast.error(message));
      }
    });
  };

  private fetchData = async () => {
    await this.setState({ limit: 100, offset: 0 });
    this.loadData();
  };

  private effectLoadEssentialData = async () => {
    if (!this.state.loadingEssentialData) return;
    const p1 = this.clientRepo.getClients();

    const p2 = this.caseTypeRepo.getCaseTypes({});
    const results = await Promise.all([p1, p2]);

    if (results[0] instanceof Result.Success) {
      this.setState({
        clientOptions: results[0].data.items.map((it) => ({
          text: it.getName(),
          value: it.getId(),
          key: it.getId(),
        })),
      });
    } else {
      const message = results[0].message || "Could not load Clients";
      toast.error(message);
    }

    if (results[1] instanceof Result.Success) {
      this.setState({
        caseTypeOptions: results[1].data.items.map((it) => ({
          text: it.getTitle(),
          value: it.getId(),
          key: it.getId(),
        })),
      });
    } else {
      const message = results[1].message || "Could not load Case Types";
      toast.error(message);
    }

    this.setState({ loadingEssentialData: false });
  };

  private setClientFilter = (val: string) =>
    this.setState({ clientFilter: val });
  private setCaseTypeFilter = (val: string) =>
    this.setState({ caseTypeFilter: val });
  private setClaimNumberFilter = (val: string) =>
    this.setState({ claimNumberFilter: val });
  private setFromFilter = (val: string) => this.setState({ fromFilter: val });
  private setToFilter = (val: string) => this.setState({ toFilter: val });
  private setSearchByDispatchPending = (val: boolean) =>
    this.setState({ searchByDispatchPending: val });
  private setReinvestigation = (val: boolean) => this.setState({ Reinvestigation: val })
  private setPushback = (val: boolean) => this.setState({ Pushback: val })

  render() {
    let paymentUploadResponse = null;
    if (this.state.bulkUploadResponseStatus) {
      paymentUploadResponse = (
        <Segment>
          <h4>
            Rows Skipped while bulk Uploading &nbsp;&nbsp;
            <Button
              onClick={() =>
                this.setState({
                  bulkUploadResponseStatus: false,
                  bulkUploadResponse: [],
                })
              }
            >
              Dismiss
            </Button>{" "}
          </h4>
          <p>
            {this.state.bulkUploadResponse.map((it) => {
              return <span style={{ padding: "10px" }}>{it} &nbsp; </span>;
            })}
          </p>
        </Segment>
      );
    }

    return (
      <FilterPageContext.Provider
        value={{
          options: {
            client: this.state.clientOptions,
            caseType: this.state.caseTypeOptions,
          },
          filters: {
            client: this.state.clientFilter,
            setClient: this.setClientFilter,
            caseType: this.state.caseTypeFilter,
            setCaseType: this.setCaseTypeFilter,
            claimNumber: this.state.claimNumberFilter,
            setClaimNumber: this.setClaimNumberFilter,
            from: this.state.fromFilter,
            setFrom: this.setFromFilter,
            to: this.state.toFilter,
            setTo: this.setToFilter,
            searchByDispatchPending: this.state.searchByDispatchPending,
            setSearchByDispatchPending: this.setSearchByDispatchPending,
            applyFilters: this.fetchData,
            clearAllFilters: this.clearAllFilters,

            Reinvestigation: this.state.Reinvestigation,
            setReinvestigation: this.setReinvestigation,
            Pushback: this.state.Pushback,
            setPushback: this.setPushback,
          },
        }}
      >
        <Page
          title="Document Dispatch"
          description="Manage document dispatch information"
        >
          {paymentUploadResponse}
          <Segment.Group>
            <Segment>
              <Modal
                open={this.state.downloadTemplateModalOpen}
                size="mini"
                closeIcon
                closeOnDimmerClick={false}
                closeOnEscape={false}
                onClose={() =>
                  this.setState({ downloadTemplateModalOpen: false })
                }
                trigger={
                  <span>
                    <label style={{ marginRight: 16 }}>Step 1:</label>
                    <Button
                      primary
                      onClick={() =>
                        this.setState({ downloadTemplateModalOpen: true })
                      }
                    >
                      Download Template
                    </Button>
                  </span>
                }
              >
                <Modal.Header>Download Template</Modal.Header>
                <Modal.Content>
                  <DispatchTemplateDownloadForm
                    onSuccess={() => {
                      this.setState({ downloadTemplateModalOpen: false });
                    }}
                  />
                </Modal.Content>
              </Modal>

              <Icon name="arrow right" style={{ margin: "0 2rem" }} />
              <label>Step 2: Fill it.</label>
              <Icon name="arrow right" style={{ margin: "0 2rem" }} />
              <Modal
                open={this.state.uploadTemplateModalOpen}
                size="mini"
                closeIcon
                closeOnDimmerClick={false}
                closeOnEscape={false}
                onClose={() =>
                  this.setState({ uploadTemplateModalOpen: false })
                }
                trigger={
                  <span>
                    <label style={{ marginRight: 16 }}>Step 3:</label>
                    <Button
                      onClick={() => {
                        this.setState({ uploadTemplateModalOpen: true });
                      }}
                      primary
                    >
                      Upload Dispatch Template
                    </Button>
                  </span>
                }
              >
                <Modal.Header>Upload Dispatch Template</Modal.Header>
                <Modal.Content>
                  <UploadBulkDispatchForm onSuccess={this.bulkUploadResponse} />
                </Modal.Content>
              </Modal>
            </Segment>
          </Segment.Group>

          <Segment>
            <Filters />
          </Segment>

          {/*Update doc dispatch section Modal */}
          <Modal
            open={this.state.update_modalOpen}
            onClose={() =>
              this.setState({ update_modalOpen: false, update_model: null })
            }
            size="tiny"
            closeIcon
            closeOnDimmerClick={false}
            closeOnEscape={false}
          >
            <Modal.Header>Update Information</Modal.Header>
            <Modal.Content>
              <DocDispatchUpdateForm
                model={this.state.update_model || undefined}
                onSuccess={this.onDocDispatchSectionUpdated}
              />
            </Modal.Content>
          </Modal>
          {/*Update doc dispatch section Modal */}

          {/*doc dispatch section investigator docs info Modal */}
          <Modal
            open={this.state.docInfo_modalOpen}
            onClose={() => this.setState({ docInfo_modalOpen: false })}
            size="tiny"
            closeIcon
            closeOnDimmerClick={false}
            closeOnEscape={false}
          >
            <Modal.Header>Documents/Evidences Information</Modal.Header>
            <Modal.Content>
              <DocDispatchDocInfoForm
                model={this.state.update_model || undefined}
                onSuccess={() => this.setState({ docInfo_modalOpen: false })}
              />
            </Modal.Content>
          </Modal>
          {/*doc dispatch section investigator docs info Modal */}

          <Table
            renderer={this.columnRenderer}
            data={this.state.docDispatchSection}
            totalDataCount={this.state.totalCount}
            loading={this.state.loading}
            load={this.loadData}
            onSearch={this.setFilter}
            onClear={() => this.setFilter("")}
          />
        </Page>
      </FilterPageContext.Provider>
    );
  }
}

export default (props: any) => {
  return (
    <RequirePermission permission={permissions.Billing.update}>
      <DocDispatchSectionPage {...props} />
    </RequirePermission>
  );
};
