import DataMapper from './DataMapper'
import DocDispatchSectionRM from '../responsemodel/DocDispatchSectionRM'
import DocDispatchSection, { DocDispatchSection_Investigation, DocDispatchSection_Dispatch } from '../../models/DocDispatchSection'

export default class DocDispatchSectionDataMapper extends DataMapper<DocDispatchSectionRM, DocDispatchSection> {
  map = (rm: DocDispatchSectionRM) => {
    let investigations: DocDispatchSection_Investigation[] = []
    if (rm.investigation)
      rm.investigation.forEach((it) => {
        investigations.push(
          new DocDispatchSection_Investigation(
            it.investigator_emp_id,
            it.investigator_name,
            it.document_charges,
            it.approved_charges,
            it.extra_charges,
            it.document_receipts_arrived_date,
            it.approved_receipts_arrived_date,
            it.extra_receipts_arrived_date,
            it.evidence_arrived_date
          )
        )
      })

    return new DocDispatchSection(
      rm.id,
      rm.case_id,
      rm.claim_number,
      rm.client_id,
      rm.client_name,
      rm.case_type_id,
      rm.case_type_title,
      rm.close_date,
      investigations,
      rm.dispatch != null ? new DocDispatchSection_Dispatch(rm.dispatch.date, rm.dispatch.slip_url, rm.dispatch.created_on, rm.dispatch.modified_on) : null
    )
  }
}
