import React from "react";
import { Container } from "semantic-ui-react";
import { Title, Description } from "../../elements/Text";

interface Props {
  title?: string;
  description?: string;
  children?: React.ReactNode;
}
const Page = (props: Props) => {
  return (
    <Container style={{ marginTop: "16px", marginBottom: "16px" }}>
      {props.title ? <Title>{props.title}</Title> : null}
      {props.description ? (
        <Description>{props.description}</Description>
      ) : null}
      {props.children}
    </Container>
  );
};

export default Page;
