import ProtectedAPIRepository from './ProtectedAPIRepository'
import APIResponseModel from './APIResponseModel'
import Result from './Result'
import DocDispatchSectionRM from '../responsemodel/DocDispatchSectionRM'
import DocDispatchSectionDataMapper from '../datamapper/DocDispatchSectionDataMapper'
import DocDispatchSection from '../../models/DocDispatchSection'

export default class DocDispatchSectionRepository extends ProtectedAPIRepository {
  private dataMapper = new DocDispatchSectionDataMapper()

  getData = async (ro?: GetDocDispatchSectionRequestObject) => {
    const response = await this.post<APIResponseModel<DocDispatchSectionRM>>('/billing/dispatch', ro)
    if (response instanceof Result.Success) {
      const data: APIResponseModel<DocDispatchSectionRM> = response.data
      if (data.data !== null) {
        const items = data.data.items.map((it) => this.dataMapper.map(it))
        return new Result.Success({
          totalItemCount: data.data.total_item_count,
          items: items,
        })
      } else {
        return new Result.Error(0, 'Data is null')
      }
    } else {
      return response
    }
  }

  updateData = async (ro: UpdateDocDispatchSectionRM) => {
    const formData = this.constructFormData(ro)
    const response = await this.patch<APIResponseModel<DocDispatchSectionRM>>('/billing/dispatch', formData)
    if (response instanceof Result.Success) {
      const data: APIResponseModel<DocDispatchSectionRM> = response.data
      if (data.data !== null) {
        const receivedData = this.dataMapper.map(data.data.items[0])
        return new Result.Success(receivedData)
      } else {
        return new Result.Error(0, 'Data is null')
      }
    } else {
      return response
    }
  }

  uploadDispatchPendencySheet = async (sheet: File, slip: File) => {
    const formData = this.constructFormData({ sheet, slip })
    const response = await this.patch<APIResponseModel<number[]>>('/billing/dispatch/uploadTemplate', formData)

    if (response instanceof Result.Success) {
      const data: APIResponseModel<number[]> = response.data
      if (data.data !== null) {
        let receivedData = data.data.items
        return new Result.Success({
          totalItemCount: data.data.total_item_count,
          items: receivedData,
        })
      } else {
        return new Result.Error(0, 'Data is null')
      }
    } else {
      return response
    }
  }
}

export interface GetDocDispatchSectionRequestObject {
  id?: string
  case_id?: string
  claim_number?: string
  client_id?: string
  client_name?: string
  case_type_id?: string
  case_type_title?: string

  dispatchPending?: boolean

  from?: string
  to?: string
  search?: string
  limit?: number
  offset?: number

  reinvestigation?: boolean
  pushback?: boolean
}

export interface UpdateDocDispatchSectionRM {
  claim_number: string
  pod_receipt: File
  dispatch: {
    date: string
  }
}
