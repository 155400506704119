import DomainModel from './../DomainModel'

class HospitalInfrastructureData extends DomainModel {
    constructor(
        private bed_icu: string,
        private changeNamePastMonth: string,
        private contact_person: string,
        private createdAt: string,
        private dateOfChange: string,
        private designation: string,
        private full_time_doctor: string,
        private hospitalData: { name: string },
        private hospital: string,
        private hospitalType: string,
        private hospital_images: any,
        private hospital_reg_number: string,
        private icu_facility: string,
        private inHouse_lab: string,
        private inHouse_pharmacy: string,
        private isFieldSaved: boolean,
        private number_of_doctor: any,
        private old_name: any,
        private ot_facility: string,
        private patients_in_icu: any,
        private patients_in_opd: any,
        private phone: string,
        private visible_staff: any,
    ) {
        super()
    }
    getBedIcu = () => this.bed_icu
    getChangeNamePastMonth = () => this.changeNamePastMonth
    getContactPerson = () => this.contact_person
    getCreatedAt = () => this.createdAt
    getDateOfChange = () => this.dateOfChange
    getDesignation = () => this.designation
    getFullTimeDoctor = () => this.full_time_doctor
    getHospitalData = () => this.hospitalData
    getHospital = () => this.hospital
    getHospitalType = () => this.hospitalType
    getHospitalImages = () => this.hospital_images
    getHospitalRegNumber = () => this.hospital_reg_number
    getIcuFacility = () => this.icu_facility
    getInHouseLab = () => this.inHouse_lab
    getInHousePharmacy = () => this.inHouse_pharmacy
    getIsFieldSaved = () => this.isFieldSaved
    getNumberOfDoctor = () => this.number_of_doctor
    getOldName = () => this.old_name
    getOtFacility = () => this.ot_facility
    getPatientsInIcu = () => this.patients_in_icu
    getPatientsInOpd = () => this.patients_in_opd
    getPhone = () => this.phone
    getVisibleStaff = () => this.visible_staff

}

export default HospitalInfrastructureData
