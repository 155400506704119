import DomainModel from '../DomainModel'

export default class Notifecations extends DomainModel {
    constructor(
        private _id: string,
        private createdAt: string,
        private message: string,
        private name: string,
        private title: string,
        private type: string,
        private type_id: string,
        private unread: boolean,
    ) {
        super()
    }

    getId = () => this._id
    getCreatedAt = () => this.createdAt
    getMessage = () => this.message
    getName = () => this.name
    getTitle = () => this.title
    getType = () => this.type
    getType_id = () => this.type_id
    getUnRead = () => this.unread
}