import DomainModel from './DomainModel'

export default class LeaveRequest extends DomainModel {
    constructor(
        private id: string,
        private session: string,
        private startDate: string,
        private endDate: string,
        private status: string,
        private reason: string,
        private rejectedReason: string,
        private appliedBy: string

    ) {
        super()
    }

    getId = () => this.id
    getSession = () => this.session
    getStartDate = () => this.startDate
    getEndDate = () => this.endDate
    getStatus = () => this.status
    getReason = () => this.reason
    getRejectedReason = () => this.rejectedReason
    getAppliedBy = () => this.appliedBy
}
