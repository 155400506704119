import RawRepository from './RawRepository'
import Result from './Result'
import { CancelToken } from 'axios'

export default class WrappingRepository extends RawRepository {
  wrappedGet = async <T>(path: string, queryParams?: {}, cancelToken?: CancelToken) => {
    try {
      const response = await this.rawGet<T>(path, queryParams, cancelToken)
      return new Result.Success(response)
    } catch (e) {
      if (e.response) {
        const httpStatusCode = parseInt(e.response.status)
        const message = e.response.data.message
        return new Result.Error(httpStatusCode, message)
      } else {
        return new Result.Error(0, e.message)
      }
    }
  }

  wrappedPut = async <T>(path: string, body?: {}, cancelToken?: CancelToken) => {
    try {
      const response = await this.rawPut<T>(path, body, cancelToken)
      return new Result.Success(response)
    } catch (e) {
      if (e.response) {
        const httpStatusCode = parseInt(e.response.status)
        const message = e.response.data.message
        return new Result.Error(httpStatusCode, message)
      } else {
        return new Result.Error(0, e.message)
      }
    }
  }

  wrappedPost = async <T>(path: string, body?: {}, cancelToken?: CancelToken) => {
    try {
      const response = await this.rawPost<T>(path, body, cancelToken)
      return new Result.Success(response)
    } catch (e) {
      if (e.response) {
        const httpStatusCode = parseInt(e.response.status)
        const message = e.response.data.message
        return new Result.Error(httpStatusCode, message)
      } else {
        return new Result.Error(0, e.message)
      }
    }
  }

  wrappedPatch = async <T>(path: string, body?: {}, cancelToken?: CancelToken) => {
    try {
      const response = await this.rawPatch<T>(path, body, cancelToken)
      return new Result.Success(response)
    } catch (e) {
      if (e.response) {
        const httpStatusCode = parseInt(e.response.status)
        const message = e.response.data.message
        return new Result.Error(httpStatusCode, message)
      } else {
        return new Result.Error(0, e.message)
      }
    }
  }

  wrappedDelete = async <T>(path: string, queryParams?: {}, cancelToken?: CancelToken) => {
    try {
      const response = await this.rawDelete<T>(path, queryParams, cancelToken)
      return new Result.Success(response)
    } catch (e) {
      if (e.response) {
        const httpStatusCode = parseInt(e.response.status)
        const message = e.response.data.message
        return new Result.Error(httpStatusCode, message)
      } else {
        return new Result.Error(0, e.message)
      }
    }
  }
}
