import React, { useState, useEffect } from "react";
import CompleteCase from "../../../../../models/CompleteCase";
import styled from "styled-components";
import CaseRepository from "../../../../../common/repository/CaseRepository";
import CaseHistory from "../../../../../models/CaseHistory";
import Result from "../../../../../common/repository/Result";
import { toast } from "../../../../common/Toast";
import { Header, Icon, Table, Button } from "semantic-ui-react";
import parser from "./parser";
import moment from "moment";
import { Link } from "react-router-dom";

const Wrapper = styled.div<{ width: string }>`
  width: ${(p) => p.width};
  height: 100%;

  padding: 16px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  background-color: #eee;
  transition: all 0.33s ease;
`;

const Item = styled.div`
  padding: 4px;
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 4px;
  overflow-wrap: break-word;
  overflow: auto;
`;

interface HistoryProps {
  model: CompleteCase;
}

function History({ model }: HistoryProps) {
  const repo = new CaseRepository();
  const [history, setHistory] = useState<CaseHistory[]>([]);
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    effectLoadHistory();
  }, [model]);

  const historyElements = history.map((it) => {
    const parsed = parser(it);
    return (
      <Item>
        <strong>State: {parsed.getCaseState()}</strong>
        <strong>{parsed.getUser()}</strong>
        <span>{moment(parsed.getTimestamp()).format("LLL")}</span>
        {parsed.getSummary().length > 0 ? (
          <Table style={{ fontSize: 12 }} compact size="small" fixed>
            {parsed.getSummary().map((it) => {
              return (
                <Table.Row>
                  <Table.Cell>{it.key}</Table.Cell>
                  <Table.Cell>{it.value}</Table.Cell>
                </Table.Row>
              );
            })}
          </Table>
        ) : null}
      </Item>
    );
  });
  return (
    <Wrapper width={isExpanded ? "480px" : "60px"}>
      <Icon
        name="expand"
        size="big"
        onClick={() => setIsExpanded(!isExpanded)}
      />
      {isExpanded && (
        <>
          <div style={{ display: "flex", justifyContent: "space-between" }}>

            <Header as="h3">Case History</Header>
            <div title="Case Investigator History">
              <Button
                size='tiny'
                style={{ width: 110, marginBottom: 10 }}
                primary
                as={Link}
                to={{
                  pathname: `/case/case-investigators-history/${model.getId()}`,
                }}
              >
                Case Inve His
              </Button>
            </div>
          </div>
          {historyElements}
        </>
      )}
    </Wrapper>
  );

  async function effectLoadHistory() {
    const result = await repo.getCaseHistory(model.getId());
    if (result instanceof Result.Success) {
      setHistory(result.data.items);
    } else {
      const message = result.message || "Something went wrong";
      toast.error(message);
    }
  }
}

export default History;
