import React from 'react'
import Page from '../../../layout/Page'
import { Modal, Icon, Button, DropdownItemProps, Segment, Header } from 'semantic-ui-react'
import DocumentPendencyForm from './DocumentPendencyForm'
import DocumentPendency from '../../../../models/DocumentPendency'
import DocumentPendencyRepository, { GetDocumentPendencyRequestObject } from '../../../../common/repository/DocumentPendencyRepository'
import Result from '../../../../common/repository/Result'
import { toast } from '../../../common/Toast'
import Table from '../../../app/Table'
import DocumentPendencyColumnRenderer from './DocumentPendencyColumnRenderer'
import RequirePermission from '../../../base/RequirePermission'
import permissions from '../../../../common/permissions'
import Filters from './Filters'
import FilterPageContext from './FilterPageContext'
import ClientRepository from '../../../../common/repository/ClientRepository'
import UserRepository from '../../../../common/repository/UserRepository'
import constants from '../../../../common/constants'
import UploadDocumentPendencyForm from './UploadDocumentPendencyForm'
import DocumentPendencyTemplateDownloadForm from './DocumentPendencyTemplateDownloadForm'

interface State {
  loading: boolean

  filter: string
  limit: number
  offset: number
  totalCount: number
  documentPendency: DocumentPendency[]

  update_modalOpen: boolean
  update_model: DocumentPendency | null

  remove_modalOpen: boolean
  remove_model: DocumentPendency | null

  loadingEssentialData: boolean
  clientOptions: DropdownItemProps[]
  userOptions: DropdownItemProps[]
  clientFilter: string
  userRoleFilter: string
  userFilter: string
  claimNumberFilter: string
  fromFilter: string
  toFilter: string
  Reinvestigation: boolean
  Pushback: boolean
  downloadTemplateModalOpen: boolean
  uploadTemplateModalOpen: boolean
}

class DocumentPendecyPage extends React.Component<{}, State> {
  private documentPendencyRepo = new DocumentPendencyRepository()
  private clientRepo = new ClientRepository()
  private userRepo = new UserRepository()
  private filters: any

  private columnRenderer = new DocumentPendencyColumnRenderer(
    (model: DocumentPendency) => {
      return (
        <Button.Group size="mini">
          <Button onClick={() => this.setState({ update_modalOpen: true, update_model: model })}>Open</Button>
          {/*<Button negative onClick={() => this.setState({ remove_modalOpen: true, remove_model: model })}>Remove</Button>*/}
        </Button.Group>
      )
    },
    (obj: any) => true
  )

  constructor(props: any) {
    super(props)

    this.filters = props.history.location.state
    this.state = {
      loading: false,
      limit: 10,
      offset: 0,
      filter: '',
      totalCount: 0,
      documentPendency: [],
      update_modalOpen: false,
      update_model: null,
      remove_modalOpen: false,
      remove_model: null,

      loadingEssentialData: true,
      clientOptions: [],
      userOptions: [],
      clientFilter: '',
      userRoleFilter: '',
      userFilter: '',
      claimNumberFilter: '',
      fromFilter: '',
      toFilter: '',
      Reinvestigation: false,
      Pushback: false,
      downloadTemplateModalOpen: false,
      uploadTemplateModalOpen: false,
    }
  }

  async componentDidMount() {
    this.loadData()
    await this.effectLoadEssentialData()
  }
  private onRowsPerPageChange = (page: number, rowsPerPage: number) => {
    // console.log('onRowsPerPageChange', page, rowsPerPage)
    const offset = (page - 1) * rowsPerPage,
      limit = offset + rowsPerPage
    this.setState({ offset, limit }, this.loadData)
  }

  private setFilter = (filter: string) => {
    this.setState({ filter, offset: 0 }, this.loadData)
  }

  private clearAllFilters = async () => {
    await this.setState({
      limit: 0,
      offset: 0,
      userFilter: '',
      clientFilter: '',
      claimNumberFilter: '',
      fromFilter: '',
      toFilter: '',
      Reinvestigation: false,
      Pushback: false,
    })
    await this.loadData()
  }

  private onDocumentPendencyUpdated = async (prop: DocumentPendency) => {
    const documentPendency = this.state.documentPendency
    const index = documentPendency.findIndex((it) => it.getId() === prop.getId())
    if (index !== -1) {
      if (!(prop.getEvidenceStatus() && prop.getApprovedReceiptStatus() && prop.getExtraReceiptStatus() && prop.getDocumentReceiptStatus())) documentPendency[index] = prop
      else documentPendency.splice(index, 1)
    }
    this.setState({
      documentPendency: [...documentPendency],
      update_modalOpen: false,
      update_model: null,
    })
  }

  private getDocumentPendencys = async () => {
    const ro: GetDocumentPendencyRequestObject = {
      limit: this.state.limit,
      offset: this.state.offset,
    }

    if (this.state.filter) ro.search = this.state.filter
    if (this.state.fromFilter) ro.from = this.state.fromFilter
    if (this.state.toFilter) ro.to = this.state.toFilter
    if (this.state.claimNumberFilter) ro.claim_number = this.state.claimNumberFilter
    if (this.state.clientFilter) ro.client_id = this.state.clientFilter
    if (this.state.userFilter) ro.investigator_id = this.state.userFilter

    if (this.state.Reinvestigation) ro.reinvestigation = this.state.Reinvestigation
    if (this.state.Pushback) ro.pushback = this.state.Pushback

    return await this.documentPendencyRepo.getData(ro)
  }

  /**
   * loads new data
   * if offset is 0 then set it as new list
   * else appends to the old list
   */
  private loadData = async () => {
    if (this.state.userFilter || this.state.fromFilter || this.state.toFilter || this.state.clientFilter || this.state.claimNumberFilter || this.state.filter|| this.state.Pushback||this.state.Reinvestigation) {
      await this.setState({ offset: 0 })
    }
    let offset = this.state.offset

    this.setState({ loading: true }, async () => {
      const result = await this.getDocumentPendencys()

      if (result instanceof Result.Success) {
        if (offset === 0) {
          //if offset 0 initialize as new list
          const documentPendency = result.data.items
          const totalCount = result.data.totalItemCount
          await this.setState({
            loading: false,
            offset: documentPendency.length,
            documentPendency,
            totalCount: totalCount,
          })
        } else {
          //else check for duplicate items in the new data
          // and then concat with the old
          const documentPendency = [...this.state.documentPendency, ...result.data.items]
          const offset = documentPendency.length
          this.setState({ loading: false, offset, documentPendency })
        }
      } else {
        const message = result.message || 'Something went wrong'
        await this.setState({ loading: false }, () => toast.error(message))
      }
    })
  }

  private effectLoadEssentialData = async () => {
    if (!this.state.loadingEssentialData) return
    let userRole = constants.roleTitle.investigator
    // const p0 = await this.roleRepo.getRoles();
    // if(p0 instanceof Result.Success){
    //     p0.data.items.forEach(it=>{
    //         if(it.getTitle() == constants.roleTitle.investigator){
    //             userRole = it.getId();
    //         }
    //     })
    // }
    const p1 = this.clientRepo.getClients()

    const p2 = this.userRepo.getUsers({ role: userRole, is_active: true })
    const results = await Promise.all([p1, p2])

    if (results[0] instanceof Result.Success) {
      this.setState({
        clientOptions: results[0].data.items.map((it) => ({
          text: it.getName(),
          value: it.getId(),
          key: it.getId(),
        })),
      })
    } else {
      const message = results[0].message || 'Could not load Clients'
      toast.error(message)
    }

    if (results[1] instanceof Result.Success) {
      this.setState({
        userOptions: results[1].data.items.map((it) => ({
          text: it.getName(),
          value: it.getId(),
          key: it.getId(),
        })),
      })
    } else {
      const message = results[1].message || 'Could not load Users'
      toast.error(message)
    }

    this.setState({ loadingEssentialData: false })
  }

  private setClientFilter = (val: string) => this.setState({ clientFilter: val })
  private setUserFilter = (val: string) => this.setState({ userFilter: val })
  private setClaimNumberFilter = (val: string) => this.setState({ claimNumberFilter: val })
  private setFromFilter = (val: string) => this.setState({ fromFilter: val })
  private setToFilter = (val: string) => this.setState({ toFilter: val })

  private setReinvestigation = (val: boolean) => this.setState({ Reinvestigation: val })
  private setPushback = (val: boolean) => this.setState({ Pushback: val })

  render() {
    return (
      <FilterPageContext.Provider
        value={{
          options: {
            client: this.state.clientOptions,
            user: this.state.userOptions,
          },
          filters: {
            client: this.state.clientFilter,
            setClient: this.setClientFilter,
            user: this.state.userFilter,
            setUser: this.setUserFilter,
            claimNumber: this.state.claimNumberFilter,
            setClaimNumber: this.setClaimNumberFilter,
            from: this.state.fromFilter,
            setFrom: this.setFromFilter,
            to: this.state.toFilter,
            setTo: this.setToFilter,
            Reinvestigation: this.state.Reinvestigation,
            setReinvestigation: this.setReinvestigation,
            Pushback: this.state.Pushback,
            setPushback: this.setPushback,

            applyFilters: this.loadData,
            clearAllFilters: this.clearAllFilters,
          },
        }}
      >
        <Page title="Document Pendency" description="Update cases whose documents or evidences are arrived">
          <Segment.Group>
            <Segment>
              {/*<span>*/}
              {/*    <label style={{ marginRight: 16 }}>Step 1:</label>*/}
              {/*    <Button onClick={this.downloadTemplate} primary>Download Template</Button>*/}
              {/*</span>*/}
              <Modal
                open={this.state.downloadTemplateModalOpen}
                size="mini"
                closeIcon
                onClose={() => this.setState({ downloadTemplateModalOpen: false })}
                closeOnDimmerClick={false}
                closeOnEscape={false}
                trigger={
                  <span>
                    <label style={{ marginRight: 16 }}>Step 1:</label>
                    <Button primary onClick={() => this.setState({ downloadTemplateModalOpen: true })}>
                      Download Template
                    </Button>
                  </span>
                }
              >
                <Modal.Header>Download Template</Modal.Header>
                <Modal.Content>
                  <DocumentPendencyTemplateDownloadForm
                    onSuccess={() => {
                      this.setState({ downloadTemplateModalOpen: false })
                    }}
                  />
                </Modal.Content>
              </Modal>

              <Icon name="arrow right" style={{ margin: '0 2rem' }} />
              <label>Step 2: Fill it.</label>
              <Icon name="arrow right" style={{ margin: '0 2rem' }} />
              <Modal
                open={this.state.uploadTemplateModalOpen}
                size="mini"
                closeIcon
                closeOnDimmerClick={false}
                closeOnEscape={false}
                onClose={() => this.setState({ uploadTemplateModalOpen: false })}
                trigger={
                  <span>
                    <label style={{ marginRight: 16 }}>Step 3:</label>
                    <Button
                      onClick={() => {
                        this.setState({ uploadTemplateModalOpen: true })
                      }}
                      primary
                    >
                      Upload Document Pendency Template
                    </Button>
                  </span>
                }
              >
                <Modal.Header>Upload Document Pendency Template</Modal.Header>
                <Modal.Content>
                  <UploadDocumentPendencyForm
                    onSuccess={() => {
                      this.setState({ uploadTemplateModalOpen: false })
                      this.loadData()
                    }}
                  />
                </Modal.Content>
              </Modal>
            </Segment>
          </Segment.Group>
          <Segment>
            <Filters />
          </Segment>
          {/* Update DocumentPendency Modal */}
          <Modal
            open={this.state.update_modalOpen}
            onClose={() => this.setState({ update_modalOpen: false, update_model: null })}
            size="tiny"
            closeIcon
            closeOnDimmerClick={false}
            closeOnEscape={false}
          >
            <Modal.Header>Update Information</Modal.Header>
            <Modal.Content>
              <DocumentPendencyForm model={this.state.update_model || undefined} onSuccess={this.onDocumentPendencyUpdated} />
            </Modal.Content>
          </Modal>
          {/* Update DocumentPendency Modal */}

          <Table
            renderer={this.columnRenderer}
            data={this.state.documentPendency}
            totalDataCount={this.state.totalCount}
            loading={this.state.loading}
            load={this.loadData}
            onRowsPerPageChange={this.onRowsPerPageChange}
            onSearch={this.setFilter}
            onClear={() => this.setFilter('')}
          />
        </Page>
      </FilterPageContext.Provider>
    )
  }
}

export default (props: any) => {
  return (
    <RequirePermission permission={permissions.Billing.update}>
      <DocumentPendecyPage {...props} />
    </RequirePermission>
  )
}
