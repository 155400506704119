import React from 'react'
import Page from '../../../layout/Page'
import { Tab } from 'semantic-ui-react'
import HospitalRepository from '../../../../common/repository/HospitalRepository'
import Result from '../../../../common/repository/Result'
import { toast } from '../../../common/Toast'
import Hospital from '../../../../models/Hospital'
import RequirePermission from '../../../base/RequirePermission'
import permissions from '../../../../common/permissions'
import HospitalPage from "./Hospital"
import TreatingDoctors from './TreatingDoctors'
import Pathologist from './Pathologist'
import Insured from './Insured'
import Attendent from './Attendent'

interface State {
    loading: boolean
    filter: string
    limit: number
    offset: number
    totalHospitalCount: number
    hospitals: Hospital[]

}

class ReportsPage extends React.Component<{}, State> {
    constructor(props: {}) {
        super(props)
        this.state = {
            loading: false,
            filter: '',
            limit: 10,
            offset: 0,
            totalHospitalCount: 0,
            hospitals: [],
        }
    }

    render() {
        let ServiceTab = [
            {
                menuItem: 'HOSPITAL',
                render: () => <Tab.Pane attached={false}>
                    <HospitalPage />
                </Tab.Pane>,
            },
            {
                menuItem: 'TREATING DOCTOR',
                render: () => <Tab.Pane attached={false}>
                    <TreatingDoctors/>
                </Tab.Pane>,
            },
            {
                menuItem: 'PATHOLOGIST',
                render: () => <Tab.Pane attached={false}>
                   <Pathologist/>
                </Tab.Pane>,
            },
            {
                menuItem: 'INSURED',
                render: () => <Tab.Pane attached={false}>
              <Insured/>
                </Tab.Pane>,
            },
            {
                menuItem: 'ATTENDANT',
                render: () => <Tab.Pane attached={false}>
                    <Attendent/>
                </Tab.Pane>,
            },
        ]
        const CaseServiceTab = () => <Tab menu={{ pointing: true }} panes={ServiceTab} />
        return (
            <Page title="Reports" description="Manage Reports">
                {<CaseServiceTab />}
            </Page>
        )
    }
}

export default (props: any) => {
    return (
        <RequirePermission permission={permissions.Master.CRUD}>
            <ReportsPage {...props} />
        </RequirePermission>
    )
}
