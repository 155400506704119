import React from "react";
import styled from "styled-components";
import Range from "./Range";

const StyledContainer = styled.div`
  width: 500px;
  overflow-x: scroll;
`;

function Container() {
  return (
    <StyledContainer>
      <Range />
    </StyledContainer>
  );
}

export default Container;
