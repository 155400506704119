import ActionColumnRenderer from '../../../app/Table/renderer/ActionColumnRenderer'
import UserWarning from '../../../../models/UserWarning'

export default class UserWarningColumnRenderer extends ActionColumnRenderer<UserWarning> {
  getColumns() {
    return [
      {
        name: 'User',
        selector: 'user.name',
      },
      {
        name: 'Warning',
        selector: 'warning',
      },
      {
        date: 'Date',
        selector: 'date',
      },
      {
        name: 'Actions',
        selector: 'id',
        grow: 0,
        width: '180px',
        cell: this.renderActionColumn,
      },
    ]
  }
}
