import React from 'react'
import User from '../../../../models/User'
import UserRepository from '../../../../common/repository/UserRepository'
import Salary from "../../../../models/Salary";

interface UserFormPageContextType {
    userRepo: UserRepository
    isLoading: boolean
    setIsLoading(bool: boolean): void
    user: User | null
    setUser(user: User): void
    salary: Salary | null
    setSalary(salary: Salary): void
    salaryMonth: string
    salaryYear: string
}

const UserFormPageContext = React.createContext({} as UserFormPageContextType)
export default UserFormPageContext
