import DomainModel from './DomainModel'
import User from './User'
import AllocatedInvestigator from './AllocatedInvestigator'
import Grade from './Grade'

class AllocatedClaimAnalyst extends DomainModel {
  constructor(private grade: Grade | null, private feedback: string | null, private claimAnalyst: User, private allocatedInvestigators: AllocatedInvestigator[]) {
    super()
  }

  getGrade = () => this.grade
  getFeedback = () => this.feedback
  getClaimAnalyst = () => this.claimAnalyst
  getAllocatedInvestigators = () => this.allocatedInvestigators
}

export default AllocatedClaimAnalyst
