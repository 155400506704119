import React from "react";
import styled from "styled-components";
import Remark from "../../../../../models/Remark";
import { Header } from "semantic-ui-react";

const Container = styled.div`
  padding: 4px 16px;

  background: white;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
`;

interface Props {
  model: Remark;
}

const RemarkListItem = ({ model }: Props) => {
  return (
    <Container>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Header as="h5" style={{ margin: 0 }}>
          {model.getMadeBy().getName()}
        </Header>
        <div>{model.getMadeOn()}</div>
      </div>
      {model.getText()}
    </Container>
  );
};

export default RemarkListItem;
