import React, { useState, useContext, useEffect } from "react";
import { Form } from "semantic-ui-react";
import ReportPageContext from "./ReportPageContext";
import Result from "../../../../common/repository/Result";
import { toast } from "../../../common/Toast";

function UploadAttendanceForm({ onSuccess }: Props) {
  const ctx = useContext(ReportPageContext);
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState<File | null>(null);

  useEffect(() => {
    uploadAttendance();
  }, [isLoading, file]);

  return (
    <Form loading={isLoading}>
      <Form.Input
        type="file"
        label="Attendance sheet"
        accept=".xlsx"
        onChange={(e) => {
          if (e.target.files) setFile(e.target.files[0]);
          else setFile(null);
        }}
      />
      <Form.Button
        positive
        disabled={isLoading || file === null}
        onClick={() => setIsLoading(true)}
      >
        Submit
      </Form.Button>
    </Form>
  );

  async function uploadAttendance() {
    if (!isLoading || file === null) return;
    const result = await ctx.attendanceRepo.uploadAttendance(file);
    if (result instanceof Result.Success) {
      toast.success("Attendance uploaded successfully");
      if (onSuccess) onSuccess();
    } else {
      const message = result.message || "Something went wrong";
      toast.error(message);
    }
    setIsLoading(false);
  }
}

interface Props {
  onSuccess?(): void;
}

export default UploadAttendanceForm;
