import React, { useContext } from 'react'
import Page from '../../../layout/Page'
import UserForm from './UserForm'

function Layout() {

    return (
        <Page
            title={getPageTitle()}>
            <UserForm />
        </Page>
    )

    function getPageTitle() {
        return ''
    }
}

export default Layout
