import React, { useState, useEffect } from 'react'
import { Form, Button, Message, InputOnChangeData } from 'semantic-ui-react'
import UserRepository from '../../../common/repository/UserRepository'
import Result from '../../../common/repository/Result'
import { useDispatch } from 'react-redux'
import { toast } from '../../common/Toast'
import { setAuth } from '../../../redux/auth/actioncreators'
import User from '../../../models/User'

const ChangePasswordForm = ({ onSuccess }: Props) => {
    const [oldPassword, setOldPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [submitting, setSubmitting] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const dispatch = useDispatch()

    useEffect(effectCheckPasswordMatches, [newPassword, confirmPassword])
    useEffect(() => { effectSubmitForm() }, [submitting])

    return (
        <Form loading={submitting} error={errorMessage.length > 0}>
            <Form.Input
                type='password'
                label='Old Password'
                value={oldPassword}
                onChange={(_, { value }) => setOldPassword(value)}/>
            <Form.Input
                type='password'
                label='New Password'
                value={newPassword}
                onChange={(_, { value }) => setNewPassword(value)} />
            <Form.Input
                type='password'
                label='Confirm Password'
                value={confirmPassword}
                onChange={(_, { value }) => setConfirmPassword(value)} />

            { errorMessage && (
                <Message error compact size='tiny'>
                    {errorMessage}
                </Message>
            )}

            <Form.Button 
                positive 
                onClick={() => setSubmitting(true)}
                disabled={shouldDisableSubmit()}>
                Submit
            </Form.Button>
        </Form>
    )

    function effectCheckPasswordMatches() {
        if (newPassword === '' || confirmPassword === '')
            return

        if (confirmPassword.length > newPassword.length) {
            setErrorMessage('Password dont match')
            return
        }

        if (newPassword.slice(0, confirmPassword.length) !== confirmPassword) {
            setErrorMessage('Password dont match')
            return
        }
        setErrorMessage('')
    }

    async function effectSubmitForm() {
        if (!submitting)
            return
        const repo = new UserRepository()
        const result = await repo.changePassword({
            old_password: oldPassword,
            new_password: newPassword
        })
        if (result instanceof Result.Success) {
            const user = result.data
            toast.success('Successfully updated password')
            dispatch(setAuth(user))
            if (onSuccess) onSuccess(user)
        } else {
            const message = result.message || 'Something went wrong'
            toast.error(message)
        }
        setSubmitting(false)
    }

    function shouldDisableSubmit() {
        return (
            errorMessage.length > 0 ||
            submitting ||
            oldPassword.trim().length === 0 ||
            newPassword.trim().length === 0 ||
            confirmPassword.trim().length === 0
        )
    }
}

interface Props {
    onSuccess?(user: User): void 
}

export default ChangePasswordForm