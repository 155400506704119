import DomainModel from './DomainModel'

class DocDispatchSection extends DomainModel {
  constructor(
    private id: string,
    private caseId: string,
    private claimNumber: string,
    private clientId: string,
    private clientName: string,
    private caseTypeId: string,
    private caseTypeTitle: string,
    private closeDate: string,

    private investigation: DocDispatchSection_Investigation[],
    private dispatch: DocDispatchSection_Dispatch | null
  ) {
    super()
  }

  getId = () => this.id
  getCaseId = () => this.caseId
  getClaimNumber = () => this.claimNumber
  getClientId = () => this.clientId
  getClientName = () => this.clientName
  getCaseTypeId = () => this.caseTypeId
  getCaseTypeTitle = () => this.caseTypeTitle
  getCloseDate = () => this.closeDate

  getInvestigation = () => this.investigation
  getDispatch = () => this.dispatch
}

export class DocDispatchSection_Investigation {
  constructor(
    private investigator_emp_id: string,
    private investigator_name: string,
    private document_charges: number,
    private approved_charges: number,
    private extra_charges: number,
    private document_receipts_date: string,
    private approved_receipts_date: string,
    private extra_receipts_date: string,
    private evidence_date: string
  ) {}

  getInvestigatorEmpId = () => this.investigator_emp_id
  getInvestigatorName = () => this.investigator_name
  getDocumentDate = () => this.document_receipts_date
  getApprovedDocDate = () => this.approved_receipts_date
  getExtraDocDate = () => this.extra_receipts_date
  getEvidenceDate = () => this.evidence_date

  getDocumentCharges = () => this.document_charges
  getApprovedCharges = () => this.approved_charges
  getExtraCharges = () => this.extra_charges
}

export class DocDispatchSection_Dispatch {
  constructor(private date: string, private slip_url: string, private created_on: string, private modified_on: string) {}

  getDate = () => this.date
  getSlipUrl = () => this.slip_url
  getCreatedOn = () => this.created_on
  getModifiedOn = () => this.modified_on
}

export default DocDispatchSection
