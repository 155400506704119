import React from "react";
import { Form } from "semantic-ui-react";
import Button from "../../elements/Button";
import Result from "../../../common/repository/Result";
import { toast } from "../Toast";
import StateDM from "./../../../models/State";

import RepudiationGround from "../../../models/RepudiationGround"
import RepudiationGroundRepository from "../../../common/repository/RepudiationGroundRepository"
interface State {
    form_loading: boolean;

    repudiation_ground: string;
    point: any;
}

interface Props {
    model?: RepudiationGround;
    states?: StateDM[];
    onSuccess(hospital: RepudiationGround): void;
    onError?(): void;
}

class RepudiationGroundForm extends React.Component<Props, State> {
    private RepudiationGroundRepository = new RepudiationGroundRepository()

    constructor(props: Props) {
        super(props);
        let loadStates = true;
        if (props.states) loadStates = false;

        const stateFromModel = this.repudiationGroundFromModel();
        this.state = {
            form_loading: false,
            ...stateFromModel,
        };
    }

    private repudiationGroundFromModel = () => {
        const model = this.props.model;
        return {
            repudiation_ground: (model && model.getRepudiationGround()) || "",
            point: (model && model.getpoint()) || "",
        };
    };

    private onSubmit = () => {
        this.setState({ form_loading: true }, () => {
            if (this.props.model) this.updateRepuditation();
            else this.addRepudiation();
        });
    };

    private addRepudiation = async () => {
        const result = await this.RepudiationGroundRepository.addRepudiationGround({
            repudiation_ground: this.state.repudiation_ground,
            point: this.state.point,
        });

        if (result instanceof Result.Success) {
            const hospital = result.data;
            toast.success("Repudiation Ground Added Successfully!");
            this.props.onSuccess(hospital);
        } else {
            const message = result.message || "Something went wrong";
            this.setState({ form_loading: false }, () => {
                toast.error(message);
                if (this.props.onError) this.props.onError();
            });
        }
    };

    private updateRepuditation = async () => {
        const repudiationGroundId = this.props.model!.getId();
        const result = await this.RepudiationGroundRepository.updateRepuditationGround({
            id: repudiationGroundId,
            repudiation_ground: this.state.repudiation_ground,
            point: this.state.point,
        });

        if (result instanceof Result.Success) {
            const repudiationGround = result.data;
            toast.success("Repudiation Ground Updated Successfully!");
            this.props.onSuccess(repudiationGround);
        } else {
            const message = result.message || "Something went wrong";
            this.setState({ form_loading: false }, () => {
                toast.error(message);
                if (this.props.onError) this.props.onError();
            });
        }
    };

    render() {

        return (
            <Form loading={this.state.form_loading}>
                {/* name */}
                <Form.Input
                    label="Type"
                    placeholder=""
                    value={this.state.repudiation_ground}
                    onChange={(_, { value }) => this.setState({ repudiation_ground: value })}
                />
                {/* name */}
                <Form.Input
                    maxLength={1}
                    label="Point"
                    placeholder=""
                    value={this.state.point}
                    onChange={(_, { value }) => this.setState({ point: value })}
                />
                <Button
                    positive
                    onClick={this.onSubmit}
                    disabled={
                        this.state.repudiation_ground.length === 0 ||
                        this.state.point.length === 0
                    }
                >
                    Submit
                </Button>
            </Form>
        );
    }
}

export default RepudiationGroundForm;
