import React, { useState, useEffect } from 'react'
import { DropdownItemProps, Segment, Table, Header, Button } from 'semantic-ui-react'
import Page from '../../layout/Page'
import ClientRepository from '../../../common/repository/ClientRepository'
import RoleRepository from '../../../common/repository/RoleRespository'
import CaseCategoryRepository from '../../../common/repository/CaseCategoryRepository'
import StateDistrictRepository from '../../../common/repository/StateDistrictRepository'
import UserRepository from '../../../common/repository/UserRepository'
import CaseRepository, { PerformanceEvaluationRequestObject } from '../../../common/repository/CaseRepository'
import HospitalRepository from '../../../common/repository/HospitalRepository'
import PerformanceEvaluationPageContext from './PerformanceEvaluationPageContext'
import Filters from './Filters'
import Result from '../../../common/repository/Result'
import { toast } from '../../common/Toast'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from '../../../redux'
import LocalStorage from '../../../lib/LocalStorage'
import axios from 'axios'

import config from '../../../common/repository/config'

function PerformanceEvaluationPage() {
  const authState = useSelector((state: AppState) => state.auth)
  const user = authState.user
  const clientRepo = new ClientRepository()
  const roleRepo = new RoleRepository()
  const caseCategoryRepo = new CaseCategoryRepository()
  const stateRepo = new StateDistrictRepository()
  const userRepo = new UserRepository()
  const caseRepo = new CaseRepository()
  const hospitalRepo = new HospitalRepository()

  const [isLoading, setIsLoading] = useState(false)
  // const [performanceEvaluationData, setPerformanceEvaluationData] = useState<{key: string, value: string}[]>([])
  const [performanceEvaluationData, setPerformanceEvaluationData] = useState<any[]>([])
  const [performanceEvaluationReport, setPerformanceEvaluationReport] = useState('')
  const [roleOptions, setRoleOptions] = useState<DropdownItemProps[]>([])
  const [userOptions, setUserOptions] = useState<DropdownItemProps[]>([])
  const [caseCategoryOptions, setCaseCategoryOptions] = useState<DropdownItemProps[]>([])
  const [clientOptions, setClientOptions] = useState<DropdownItemProps[]>([])
  const [districtOptions, setDistrictOptions] = useState<DropdownItemProps[]>([])
  const [hospitalOptions, setHospitalOptions] = useState<DropdownItemProps[]>([])
  const [loadingEssentialData, setLoadingEssentialData] = useState(true)
  const [searchFilter, setSearchFilter] = useState('')
  const [userRoleFilter, setUserRoleFilter] = useState('')
  const [userFilter, setUserFilter] = useState('')
  const [caseCategoryFilter, setCaseCategoryFilter] = useState('')
  const [clientFilter, setClientFilter] = useState('')
  const [districtFilter, setDistrictFilter] = useState('')
  const [hospitalFilter, setHospitalFilter] = useState('')
  const [fromFilter, setFromFilter] = useState('')
  const [toFilter, setToFilter] = useState('')
  const [viewTypeFilter, setViewTypeFilter] = useState('')
  const [evaluationByClosedDateFilter, setEvaluationByClosedDateFilter] = useState(false)
  const [source, _] = useState(caseRepo.createSource())

  useEffect(effectTriggerPerfomanceEvaluation, [
    userFilter,
    caseCategoryFilter,
    clientFilter,
    districtFilter,
    searchFilter,
    searchFilter,
    hospitalFilter,
    fromFilter,
    toFilter,
    viewTypeFilter,
    evaluationByClosedDateFilter,
  ])
  useEffect(() => {
    effectPerformanceEvalution()
  }, [isLoading])
  useEffect(() => {
    effectLoadEssentialData()
  }, [loadingEssentialData])
  useEffect(() => {
    effectLoadUsers()
  }, [userRoleFilter])

  const rowComponents = performanceEvaluationData.map((it) => {
    if (viewTypeFilter == 'investigator') {
      return (
        <Table.Body>
          <Table.Row>
            {/* <Table.Cell>{it.case_type}</Table.Cell> */}
            <Table.Cell>{it.investigator_name}</Table.Cell>
            <Table.Cell>{it.total_cases}</Table.Cell>
            <Table.Cell>{it.repudiated_case}</Table.Cell>
            <Table.Cell>{it.repudiated_case_percentage}</Table.Cell>
            <Table.Cell>{it.reinvestigation}</Table.Cell>
            <Table.Cell>{it.closed_within_tat}</Table.Cell>
            <Table.Cell>{it.closed_within_tat_percentage}</Table.Cell>
            <Table.Cell>{it.average_tat}</Table.Cell>
            {/* <Table.Cell>{it.repudiation_ground || "--"}</Table.Cell> */}
          </Table.Row>
        </Table.Body>
      )
    } else if (viewTypeFilter == 'user') {
      return (
        <Table.Body>
          <Table.Row>
            <Table.Cell>{it.case_type}</Table.Cell>
            <Table.Cell>{it.total_cases}</Table.Cell>
            <Table.Cell>{it.repudiated_case}</Table.Cell>
            <Table.Cell>{it.repudiated_case_percentage}</Table.Cell>
            <Table.Cell>{it.reinvestigation}</Table.Cell>
            <Table.Cell>{it.inconclusive_cases}</Table.Cell>
            <Table.Cell>{it.inconclusive_cases_percentage}</Table.Cell>
            <Table.Cell>{it.closed_within_tat}</Table.Cell>
            <Table.Cell>{it.closed_within_tat_percentage}</Table.Cell>
            <Table.Cell>{it.average_tat}</Table.Cell>
            <Table.Cell>{it.average_stars}</Table.Cell>
          </Table.Row>
        </Table.Body>
      )
    } else {
      return (
        <Table.Body>
          <Table.Row>
            {viewTypeFilter == 'claim_analyst' ?
              <Table.Cell>{it.claim_analyst}</Table.Cell>
              :
              <Table.Cell>{it.case_type}</Table.Cell>
            }
            <Table.Cell>{it.total_cases}</Table.Cell>
            <Table.Cell>{it.sr_percentage}</Table.Cell>
            <Table.Cell>{it.repudiated_case}</Table.Cell>
            <Table.Cell>{it.reinvestigation}</Table.Cell>
            <Table.Cell>{it.payable_cases}</Table.Cell>
            <Table.Cell>{it.inconclusive_cases}</Table.Cell>
            <Table.Cell>{it.closed_within_tat}</Table.Cell>
            <Table.Cell>{it.closed_within_tat_percentage}</Table.Cell>
            <Table.Cell>{it.average_tat}</Table.Cell>
          </Table.Row>
        </Table.Body>
      )
    }
  })

  const downloadData = async () => {
    const token = LocalStorage.get(LocalStorage.KEY_AUTH_TOKEN)
    try {
      toast.success('Download will start shortly!')
      let data = createRequestObject()
      let reqData = axios({
        url: config.apiBaseURL + `/case/evaluationReport/download`,
        method: 'POST',
        responseType: 'blob',
        data: data,
        headers: { ['x-access-token']: token },
        onDownloadProgress: (progressEvent) => {
          //let percentage = `${Math.round(((progressEvent.loaded / 1024) / 1024))} MB Downloaded`;
          // console.log(downloadedData)
        },
      })
      reqData
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'evaluationReport.xlsx')
          document.body.appendChild(link)
          link.click()
          link.remove()
        })
        .catch((e) => {
          toast.error('Something went wrong while downloading data!')
        })
    } catch (e) {
      toast.error('Something went wrong while downloading')
    }
  }

  return (
    <PerformanceEvaluationPageContext.Provider
      value={{
        options: {
          category: caseCategoryOptions,
          client: clientOptions,
          districts: districtOptions,
          user: userOptions,
          userRoles: roleOptions,
          hospital: hospitalOptions,
        },
        filters: {
          category: caseCategoryFilter,
          setCategory: setCaseCategoryFilter,
          client: clientFilter,
          setClient: setClientFilter,
          district: districtFilter,
          setDistrict: setDistrictFilter,
          user: userFilter,
          setUser: setUserFilter,
          userRole: userRoleFilter,
          setUserRole: setUserRoleFilter,
          search: searchFilter,
          setSearch: setSearchFilter,
          hospital: hospitalFilter,
          setHospital: setHospitalFilter,
          from: fromFilter,
          setFrom: setFromFilter,
          to: toFilter,
          setTo: setToFilter,
          viewType: viewTypeFilter,
          setViewType: setViewTypeFilter,
          evaluationByClosedDate: evaluationByClosedDateFilter,
          setEvaluationByClosedDate: setEvaluationByClosedDateFilter,
        },
      }}
    >
      <Page title="Performance Evaluation">
        <Segment loading={isLoading}>
          <Filters />
        </Segment>
        {rowComponents.length > 0 && (
          <Segment>
            <Header as="h3">
              Performance Evaluation Result{' '}
              <Button onClick={downloadData} primary>
                Download Report
              </Button>
            </Header>
            <Table>
              {viewTypeFilter == 'investigator' && (
                <Table.Header>
                  <Table.Row>
                    {/* <Table.HeaderCell>Case Type</Table.HeaderCell> */}
                    <Table.HeaderCell>Investigator Name</Table.HeaderCell>
                    <Table.HeaderCell>Total Cases</Table.HeaderCell>
                    <Table.HeaderCell>Repudiated Cases</Table.HeaderCell>
                    <Table.HeaderCell>Repudiated Cases Percentage</Table.HeaderCell>
                    <Table.HeaderCell>Reinvestigate Cases </Table.HeaderCell>
                    <Table.HeaderCell>Closed Within TAT</Table.HeaderCell>
                    <Table.HeaderCell>Closed Within TAT Percentage</Table.HeaderCell>
                    <Table.HeaderCell>Average TAT</Table.HeaderCell>
                    {/* <Table.HeaderCell>Repudiation Ground</Table.HeaderCell> */}
                  </Table.Row>
                </Table.Header>
              )}
              {viewTypeFilter == 'user' && (
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Case Type</Table.HeaderCell>
                    <Table.HeaderCell>Total Cases</Table.HeaderCell>
                    <Table.HeaderCell>Repudiated Cases</Table.HeaderCell>
                    <Table.HeaderCell>Repudiated Cases Percentage</Table.HeaderCell>
                    <Table.HeaderCell>Reinvestigate Cases </Table.HeaderCell>
                    <Table.HeaderCell>Inconclusive Cases</Table.HeaderCell>
                    <Table.HeaderCell>Inconclusive Cases Percentage</Table.HeaderCell>
                    <Table.HeaderCell>Closed Within TAT</Table.HeaderCell>
                    <Table.HeaderCell>Closed Within TAT Percentage</Table.HeaderCell>
                    <Table.HeaderCell>Average TAT</Table.HeaderCell>
                    <Table.HeaderCell>Average Stars</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
              )}
              {viewTypeFilter != 'user' && viewTypeFilter != 'investigator' && (
                <Table.Header>
                  <Table.Row>
                    {viewTypeFilter == 'claim_analyst' ?
                      <Table.HeaderCell>claim Analyst</Table.HeaderCell> :
                      <Table.HeaderCell>Case Type</Table.HeaderCell>
                    }
                    <Table.HeaderCell>Total Cases</Table.HeaderCell>
                    <Table.HeaderCell>SR Percentage</Table.HeaderCell>
                    <Table.HeaderCell>Repudiated Cases</Table.HeaderCell>
                    <Table.HeaderCell>Reinvestigate Cases </Table.HeaderCell>
                    <Table.HeaderCell>Payable Cases</Table.HeaderCell>
                    <Table.HeaderCell>Inconclusive Cases</Table.HeaderCell>
                    <Table.HeaderCell>Closed Within TAT</Table.HeaderCell>
                    <Table.HeaderCell>Closed Within TAT Percentage</Table.HeaderCell>
                    <Table.HeaderCell>Average TAT</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
              )}
              {rowComponents}
            </Table>
          </Segment>
        )}
      </Page>
    </PerformanceEvaluationPageContext.Provider>
  )

  function effectTriggerPerfomanceEvaluation() {
    setIsLoading(true)
    source.cancel()
  }

  async function effectPerformanceEvalution() {
    if (!isLoading || !fromFilter || !toFilter || !viewTypeFilter) {
      setIsLoading(false)
      return
    }
    const result = await caseRepo.performanceEvaluation(createRequestObject(), source.token)
    if (result instanceof Result.Success) {
      setPerformanceEvaluationData(result.data.getSummary())
      setPerformanceEvaluationReport(result.data.getFile())
    } else {
      const message = result.message || 'Something went wrong'
      toast.error(message)
    }
    setIsLoading(false)
  }

  function createRequestObject() {
    const ro: PerformanceEvaluationRequestObject = {
      from: fromFilter,
      to: toFilter,
    }
    if (evaluationByClosedDateFilter) ro.evaluation_by_closed_date = true
    if (caseCategoryFilter) ro.case_category = caseCategoryFilter
    if (hospitalFilter) ro.hospital_id = hospitalFilter
    if (districtFilter) ro.location = JSON.parse(districtFilter)
    if (clientFilter) ro.client_id = clientFilter
    if (userRoleFilter && userFilter) ro.user = { role: userRoleFilter, id: userFilter }
    if (searchFilter) ro.search = searchFilter
    if (viewTypeFilter) ro.view_type = viewTypeFilter
    return ro
  }

  async function effectLoadEssentialData() {
    if (!loadingEssentialData) return

    const p1 = roleRepo.getRoles({ level_greater_than: user?.getRole().getLevel() })
    const p2 = caseCategoryRepo.getCategories()
    const p3 = clientRepo.getClients()
    const p4 = stateRepo.getAll()
    const p5 = hospitalRepo.getHospitals()
    const results = await Promise.all([p1, p2, p3, p4, p5])

    if (results[0] instanceof Result.Success) {
      setRoleOptions(
        results[0].data.items.map((it) => ({
          text: it.getTitle(),
          value: it.getTitle(),
          key: it.getId(),
        }))
      )
    } else {
      const message = results[0].message || 'Could not load User Roles'
      toast.error(message)
    }

    if (results[1] instanceof Result.Success) {
      setCaseCategoryOptions(
        results[1].data.items.map((it) => ({
          text: it.getTitle(),
          value: it.getCode(),
          key: it.getCode(),
        }))
      )
    } else {
      const message = results[1].message || 'Could not load Case Categories'
      toast.error(message)
    }

    if (results[2] instanceof Result.Success) {
      setClientOptions(
        results[2].data.items.map((it) => ({
          text: it.getName(),
          value: it.getId(),
          key: it.getId(),
        }))
      )
    } else {
      const message = results[2].message || 'Could not load Clients'
      toast.error(message)
    }

    if (results[3] instanceof Result.Success) {
      setDistrictOptions(
        results[3].data.items.map((it, i) => ({
          text: `${it.getDistrict()}, ${it.getState()}`,
          value: JSON.stringify({ state: it.getState(), district: it.getDistrict() }),
          key: i.toString(),
        }))
      )
    } else {
      const message = results[3].message || 'Could not load Districts'
      toast.error(message)
    }

    if (results[4] instanceof Result.Success) {
      setHospitalOptions(
        results[4].data.items.map((it, i) => ({
          text: it.getName(),
          value: it.getId(),
          key: it.getId(),
        }))
      )
    } else {
      const message = results[4].message || 'Could not load Hospitals'
      toast.error(message)
    }

    setLoadingEssentialData(false)
  }

  async function effectLoadUsers() {
    if (userRoleFilter.length === 0) return
    const result = await userRepo.getUsersByRoleTitle({
      role: userRoleFilter,
      // is_active: true 
    })
    if (result instanceof Result.Success) {
      setUserOptions(
        result.data.items.map((it) => ({
          text: it.getName(),
          value: it.getId(),
          key: it.getId(),
        }))
      )
    } else {
      const message = result.message || 'Could not load Users'
      toast.error(message)
    }
  }
}

export default PerformanceEvaluationPage
