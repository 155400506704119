import DataMapper from './DataMapper'
import PaymentSectionRM from '../responsemodel/PaymentSectionRM'
import PaymentSection, { PaymentSection_Invoice, PaymentSection_Payment } from '../../models/PaymentSection'

export default class PaymentSectionDataMapper extends DataMapper<PaymentSectionRM, PaymentSection> {
  map = (rm: PaymentSectionRM) => {
    return new PaymentSection(
      rm.id,
      rm.case_id,
      rm.claim_number,
      rm.client_id,
      rm.client_name,
      rm.case_type_id,
      rm.case_type_title,
      rm.close_date,
      rm.invoice != null
        ? new PaymentSection_Invoice(
            rm.invoice.invoice_number,
            rm.invoice.invoice_date,
            rm.invoice.professional_fee,
            rm.invoice.document_expense,
            rm.invoice.approved_expense,
            rm.invoice.other_expense,
            rm.invoice.incentive,
            rm.invoice.deduction,
            rm.invoice.gst,
            rm.invoice.invoice_url
          )
        : null,
      rm.payment != null
        ? new PaymentSection_Payment(rm.payment.tds, rm.payment.received_amount, rm.payment.payment_date, rm.payment.utr_no, rm.payment.settled, rm.payment.status, rm.payment.slip_url)
        : null
    )
  }
}
