import Location from '../models/Location'
import Hospital from '../models/Hospital'
import CaseType from '../models/CaseType'
import CaseCategory from '../models/CaseCategory'
import PolicyType from '../models/PolicyType'
import Case from '../models/Case'
import constants from '../common/constants'
import DraftCase from '../models/DraftCase'
import CompleteCase from '../models/CompleteCase'
import URLResource from '../models/URLResource'
import Remark from '../models/Remark'
import Reminder from '../models/Reminder'
import Client from '../models/Client'

interface Params {
  id: string
  caseType: CaseType
  caseCategory: CaseCategory
  inwardDateTime: string
  claimNumber: string
  TPA: Client | null
  insuranceCompany: Client | null
  insuranceCompanyName: string | null
  subjectLine: string
  insuredName: string | null
  insuredContact: string | null
  insuredAddress: string | null
  insuredLocation: Location | null
  insuredAadhaarNumber: string | null
  hospital: Hospital | null
  policyType: PolicyType | null
  policyInception: string | null
  policyNumber: string | null
  availableTAT: number
  remainingTAT: number
  triggers: string | null
  remarks: Remark[]
  reminders: Reminder[]
  documents: URLResource[] | null
  repudiationEvidence: URLResource[]
  isVerifiedByDoctor: boolean
  isVerifiedByPathologist: boolean
  report: URLResource | null
  finalReport: URLResource | null
  finalRemarks: string | null
  outcome: string | null
  state: string
  tatTiming: any
  reinvestigation: boolean
  repudiation_ground: string
  pushback: boolean
  query_trigger_document: URLResource|null,
  isWithdrawed: boolean
  closedOn: string | null
}

export default class CaseFactory {
  static create = (params: Params): Case => {
    if (params.state === constants.caseStates.initiated) {
      return new DraftCase(
        params.id,
        params.caseType,
        params.caseCategory,
        params.insuredLocation,
        params.inwardDateTime,
        params.claimNumber,
        params.TPA,
        params.insuranceCompany,
        params.insuranceCompanyName,
        params.availableTAT,
        params.remainingTAT,
        params.subjectLine,
        params.state,
        params.tatTiming,
        params.reinvestigation,
        params.repudiation_ground,
        params.pushback,
        params.query_trigger_document

      )
    } else {
      return new CompleteCase(
        params.id,
        params.caseType,
        params.caseCategory,
        params.insuredLocation,
        params.inwardDateTime,
        params.claimNumber,
        params.TPA,
        params.insuranceCompany,
        params.insuranceCompanyName,
        params.availableTAT,
        params.remainingTAT,
        params.subjectLine,
        params.state,
        params.tatTiming,
        params.reinvestigation,
        params.repudiation_ground,
        params.pushback,
        params.query_trigger_document,
        params.insuredName!,
        params.insuredContact,
        params.insuredAddress,
        params.insuredAadhaarNumber,
        params.hospital!,
        params.policyType!,
        params.policyInception!,
        params.policyNumber!,
        params.triggers!,
        params.remarks,
        params.reminders,
        params.documents!,
        params.repudiationEvidence,
        params.isVerifiedByDoctor,
        params.isVerifiedByPathologist,
        params.report,
        params.finalReport,
        params.finalRemarks,
        params.outcome,
        params.isWithdrawed,
        params.closedOn,

      )
    }
  }
}
