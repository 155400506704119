import DomainModel from './DomainModel'

class Settings extends DomainModel {
  constructor(
    private id: string,
    private salary: {
      hraPercentage: number | null
      bonusPercentage: number | null
      miAllowancePercentage: number | null
      investigator: {
        conveyanceAllowance: number | null
        extraCaseFee: number | null
        cityExpense: number | null
        travelAllowance: {
          primary: number | null
          secondary: number | null
          tertiary: number | null
        }
      }
    },
    private incentive: {
      investigator: {
        saleReportPoints: number[]
        tatAveragePoints: number[]
        caseTargetPoints: number[]
        noLeavePoints: number | null
        noRedmarkPoints: number | null
        incentives: number[]
      }
      claimAnalyst: {
        gradeAveragePoints: number[]
        caseClosedPoints: number[]
        noLeavePoints: number | null
        noRedmarkPoints: number | null
        incentives: number[]
      }
      teamLead: {
        gradeAveragePoints: number[]
        caseClosedPoints: number[]
        noLeavePoints: number | null
        noRedmarkPoints: number | null
        incentives: number[]
      }
    },
    private leaves: {
      paidLeavesCount: number | null
      shortLeaveWageDeductPercentage: number | null
      halfLeaveWageDeductPercentage: number | null
      fullLeaveWageDeductPercentage: number | null
      leaveAccumulationCount: number | null
      leaveAccumulationBegining: number | null
    },
    private claimSize: {
      documentSize: number
      evidenceSizeLTI: number
      evidenceSizeHVI: number
      evidenceSizeDTI: number
    }
  ) {
    super()
  }

  getId = () => this.id
  getSalary = () => this.salary
  getIncentive = () => this.incentive
  getLeaves = () => this.leaves
  getClaimSize = () => this.claimSize
}

export default Settings
