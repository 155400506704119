import React, { useContext } from "react";
import FilterPageContext from "./FilterPageContext";
import DateTimePicker from "../../../app/DateTimePicker";
import { Grid, Form, Header, Icon, Button, Segment, Radio } from "semantic-ui-react";
function Filters() {
  const ctx = useContext(FilterPageContext);

  let data: { key: string; value: any }[] = [];
  if (ctx.filters.to) data.push({ key: "to", value: ctx.filters.to });
  if (ctx.filters.from) data.push({ key: "from", value: ctx.filters.from });
  if (ctx.filters.claimNumber)
    data.push({ key: "claimNumber", value: ctx.filters.claimNumber });
  if (ctx.filters.client)
    data.push({ key: "client", value: ctx.filters.client });
  if (ctx.filters.user) data.push({ key: "user", value: ctx.filters.user });

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Header as="h4">Filter</Header>
      </div>
      <Grid columns={5}>
        <Grid.Row>
          <Grid.Column>
            <Form.Input label="From Date">
              <DateTimePicker
                placeholder="Select From Date"
                type="date"
                value={ctx.filters.from}
                onChange={ctx.filters.setFrom}
              />
            </Form.Input>
          </Grid.Column>
          <Grid.Column>
            <Form.Input label="To Date">
              <DateTimePicker
                placeholder="Select To Date"
                type="date"
                value={ctx.filters.to}
                onChange={ctx.filters.setTo}
              />
            </Form.Input>
          </Grid.Column>
          <Grid.Column>
            <Form.Dropdown
              label="Investigator"
              search
              selection
              placeholder="Select Investigator"
              options={ctx.options.user}
              value={ctx.filters.user}
              onChange={(_, { value }) => ctx.filters.setUser(value as string)}
            />
          </Grid.Column>
          <Grid.Column>
            <Form.Dropdown
              label="Client"
              search
              selection
              placeholder="Select Client"
              options={ctx.options.client}
              value={ctx.filters.client}
              onChange={(_, { value }) =>
                ctx.filters.setClient(value as string)
              }
            />
          </Grid.Column>
          <Grid.Column>
            <Form.Input
              label="Claim Number"
              placeholder="Enter Claim Number"
              value={ctx.filters.claimNumber}
              onChange={(_, { value }) => ctx.filters.setClaimNumber(value)}
            />
          </Grid.Column>

            <Grid.Column >
              <Segment compact>
                <Radio
                  toggle
                  label={"Reinvestigation"}
                  checked={ctx.filters.Reinvestigation}
                  onChange={() => {
                    ctx.filters.setReinvestigation(
                      !ctx.filters.Reinvestigation
                    );
                  }}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column>
              <Segment compact>
                <Radio
                  toggle
                  label={"Pushback"}
                  checked={ctx.filters.Pushback}
                  onChange={() => {
                    ctx.filters.setPushback(
                      !ctx.filters.Pushback
                    );
                  }}
                />
              </Segment>
            </Grid.Column>
        


          <Grid.Column>
            &nbsp;
            <div className="ui buttons">
              <button
                className="ui blue button"
                onClick={ctx.filters.applyFilters}
              >
                Apply Filters
              </button>
              <button
                className="ui button"
                onClick={ctx.filters.clearAllFilters}
              >
                Clear
              </button>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
}

export default Filters;
