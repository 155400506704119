import React from "react";
import BaseProps from "../../../base/BaseProps";

interface Props extends BaseProps {
  historySidebar: JSX.Element;
}

const Page = (props: Props) => {
  return (
    <div style={{ display: "flex", flexDirection: "row", minHeight: "100%" }}>
      <div style={{ flex: 1, padding: 16 }}>{props.children}</div>

      <div>{props.historySidebar}</div>
    </div>
  );
};

export default Page;
