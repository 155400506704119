import React, { useState, useEffect } from "react";
import Page from "../../../layout/Page";
import UserWarningForm from "./UserWarningForm";
import { Modal, Button, Icon } from "semantic-ui-react";
import User from "../../../../models/User";
import UserWarningPageContext from "./UserWarningPageContext";
import RequirePermission from "../../../base/RequirePermission";
import permissions from "../../../../common/permissions";
import UserWarning from "../../../../models/UserWarning";
import UserRepository from "../../../../common/repository/UserRepository";
import Result from "../../../../common/repository/Result";
import { toast } from "../../../common/Toast";
import Table from "../../../app/Table";
import UserWarningColumnRenderer from "./UserWarningColumnRenderer";
import Remove from "../../../common/Remove";

function UserWarningPage() {
  const userRepo = new UserRepository();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoadingUserWarnings, setIsLoadingUserWarnings] = useState(true);
  const [users, setUsers] = useState<User[]>([]);
  const [limit, setLimit] = useState(
    Table.ROWS_PER_PAGE[Table.ROWS_PER_PAGE.length - 1] * 2
  );
  const [offset, setOffset] = useState(0);
  const [searchFilter, setSearchFilter] = useState("");
  const [userWarnings, setUserWarnings] = useState<UserWarning[]>([]);
  const [userWarningCount, setUserWarningCount] = useState(0);
  const [selectedUserWarning, setSelectedUserWarning] =
    useState<UserWarning | null>(null);
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);

  const columnRenderer = new UserWarningColumnRenderer(
    (model: UserWarning) => {
      return (
        <Button.Group size="mini">
          <Button
            onClick={() => {
              setSelectedUserWarning(model);
              setIsModalOpen(true);
            }}
          >
            View
          </Button>
          <Button
            onClick={() => {
              setSelectedUserWarning(model);
              setIsRemoveModalOpen(true);
            }}
            negative
          >
            Remove
          </Button>
        </Button.Group>
      );
    },
    (obj: any) => true
  );

  useEffect(() => {
    loadUserWarnings();
  }, [isLoadingUserWarnings, searchFilter]);
  useEffect(() => {
    loadUsers();
  }, []);
  useEffect(() => {
    removeUserWarning();
  }, [isRemoving]);

  const onRowsPerPageChange = (page: number, rowsPerPage: number) => {
    const offset = (page - 1) * rowsPerPage,
      limit = offset + rowsPerPage
    setLimit(limit)
    setOffset(offset)
    setIsLoadingUserWarnings(true)
    loadUserWarnings();
  }

  return (
    <UserWarningPageContext.Provider
      value={{
        userRepo,
        users,
        setUsers,
        userWarnings,
        setUserWarnings,
        userWarningCount,
        setUserWarningCount,
        selectedUserWarning,
        setSelectedUserWarning,
      }}
    >
      <Page title="User Warning" description="Manage User Warnings">
        <Modal
          open={isModalOpen}
          onClose={() => {
            setSelectedUserWarning(null);
            setIsModalOpen(false);
          }}
          size="tiny"
          closeIcon
          closeOnDimmerClick={false}
          closeOnEscape={false}
          trigger={
            <Button
              icon
              labelPosition="left"
              color="green"
              onClick={() => setIsModalOpen(true)}
            >
              <Icon name="add" />
              Create New
            </Button>
          }
        >
          <Modal.Header>
            {selectedUserWarning ? "User Warning" : "Add User Warning"}
          </Modal.Header>
          <Modal.Content>
            <UserWarningForm
              onSuccess={(model) => {
                if (selectedUserWarning !== null) return;
                addNewUserWarning(model);
                setIsModalOpen(false);
              }}
            />
          </Modal.Content>
        </Modal>

        <Remove.Modal
          open={isRemoveModalOpen}
          onClose={() => {
            setSelectedUserWarning(null);
            setIsRemoveModalOpen(false);
          }}
        >
          <Remove
            loading={isRemoving}
            onRemove={() => setIsRemoving(true)}
            onCancel={() => {
              setSelectedUserWarning(null);
              setIsRemoveModalOpen(false);
            }}
          />
        </Remove.Modal>

        <Table
          renderer={columnRenderer}
          data={userWarnings}
          totalDataCount={userWarningCount}
          loading={isLoadingUserWarnings}
          load={() => setIsLoadingUserWarnings(true)}
          onRowsPerPageChange={onRowsPerPageChange}
          onClear={() => {
            setOffset(0);
            setSearchFilter("");
            setIsLoadingUserWarnings(true);
          }}
          onSearch={(value) => {
            setOffset(0);
            setSearchFilter(value);
            setIsLoadingUserWarnings(true);
          }}
        />
      </Page>
    </UserWarningPageContext.Provider>
  );

  async function loadUsers() {
    const result = await userRepo.getUsers({ is_active: true });
    if (result instanceof Result.Success) {
      setUsers(result.data.items);
    } else {
      const message = result.message || "Could not load users";
      toast.error(message);
    }
  }

  async function loadUserWarnings() {
    if (!isLoadingUserWarnings) return;
    const result = await userRepo.getWarnings({
      search: searchFilter,
      limit,
      offset,
    });
    if (result instanceof Result.Success) {
      if (offset === 0) {
        setUserWarnings(result.data.items);
        setUserWarningCount(result.data.totalItemCount);
        setOffset(result.data.items.length);
      } else {
        const totalItems = [...userWarnings, ...result.data.items];
        setUserWarnings(totalItems);
        setOffset(totalItems.length);
      }
    } else {
      const message = result.message || "Could not load user warnings";
      toast.error(message);
    }
    setIsLoadingUserWarnings(false);
  }

  function addNewUserWarning(userWarning: UserWarning) {
    setUserWarnings([userWarning, ...userWarnings]);
    setUserWarningCount(userWarningCount + 1);
  }

  async function removeUserWarning() {
    if (!isRemoving || selectedUserWarning === null) return;
    const result = await userRepo.removeWarning(selectedUserWarning.getId());
    if (result instanceof Result.Success) {
      removeUserWarningFromUserWarnings(selectedUserWarning);
      toast.success("Successfully removed User warning");
    } else {
      const message = result.message || "Something went wrong";
      toast.error(message);
    }
    setIsRemoving(false);
    setIsRemoveModalOpen(false);
    setSelectedUserWarning(null);
  }

  function removeUserWarningFromUserWarnings(userWarning: UserWarning) {
    const index = userWarnings.findIndex(
      (it) => it.getId() === userWarning.getId()
    );
    if (index === -1) return;
    userWarnings.splice(index, 1);
    setUserWarnings([...userWarnings]);
    setUserWarningCount(userWarningCount - 1);
  }
}

export default (props: any) => {
  return (
    <RequirePermission permission={permissions.Manage.CRUD}>
      <UserWarningPage {...props} />
    </RequirePermission>
  );
};
