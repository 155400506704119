import DataMapper from './DataMapper'
import QCDataRM from '../responsemodel/QCDataRM'
import QCData from '../../models/QCData'
import URLResource from '../../models/URLResource'
import AllocatedClaimAnalystDataMapper from './AllocatedClaimAnalystDataMapper'

class QCDataDataMapper extends DataMapper<QCDataRM, QCData> {
  private allocatedClaimAnalystDataMapper = new AllocatedClaimAnalystDataMapper()

  map = (rm: QCDataRM) => {
    const repudiationEvidences = rm.repudiation_evidences.map((it) => new URLResource(it))
    const allocations = rm.allocation.map((it) => this.allocatedClaimAnalystDataMapper.map(it))
    return new QCData(rm.case_id,
      new URLResource(rm.report),
      new URLResource(rm?.query_trigger_document),
      // rm?.repudiation_ground,
      repudiationEvidences,
      allocations)
  }
}

export default QCDataDataMapper
