import ProtectedAPIRepository from './ProtectedAPIRepository'
import APIResponseModel from './APIResponseModel'
import Result from './Result'
import HospitalInfraInvestigatorDataMapper from '../datamapper/ReportDataMapper/HospitalInfraInvestigatorDataMapper'
import HospitalInfraInvestigatorRM from '../responsemodel/ReportRM/HospitalInfraInvestigatorRM'
import HospitalInfraInvestigatorDetailDataMapper from '../datamapper/ReportDataMapper/HospitalInfraInvestigatorDetailDataMapper'
import HospitalInfraInvestigatorDetailRM from '../responsemodel/ReportRM/HospitalInfraInvestigatorDetailRM'
import TreatingDoctorDataMapper from '../datamapper/ReportDataMapper/TreatingDoctorDataMapper'
import TreatingDoctorRM from '../responsemodel/ReportRM/TreatingDoctorRM'
import PathologistInvestigatorDataMapper from '../datamapper/ReportDataMapper/PathologistInvestigatorDataMapper'
import InsuredInvestigatorDataMapper from '../datamapper/ReportDataMapper/InsuredInvestigatorDataMapper'
import AttendentInvestigatorDataMapper from '../datamapper/ReportDataMapper/AttendentInvestigatorDataMapper'
import AttendentDataMapper from '../datamapper/ReportDataMapper/AttendentDataMapper'
import AttendentRM from '../responsemodel/ReportRM/AttendentRM'
import InsuredDataMapper from '../datamapper/ReportDataMapper/InsuredDataMapper'
import TreatingDoctorDetailFormRM from '../responsemodel/ReportRM/TreatingDoctorDetailFormRM'
import TratingDoctorDetailDataMapper from '../datamapper/ReportDataMapper/TratingDoctorDetailDataMapper'
import PathologistDetailRM from '../responsemodel/ReportRM/PathologistDetailRM'
import PathologistDetailDataMapper from '../datamapper/ReportDataMapper/PathologistDetailDataMapper'
import InsuredDetailDataMapper from '../datamapper/ReportDataMapper/InsuredDetailDataMapper'
import InsuredDetailRM from '../responsemodel/ReportRM/InsuredDetailRM'
import InsuredRM from '../responsemodel/ReportRM/InsuredRM'

export default class ReportRepository extends ProtectedAPIRepository {
    private HospitalInfraInvestigatorDataMapper = new HospitalInfraInvestigatorDataMapper()
    private HospitalInfraInvestigatorDetailDataMapper = new HospitalInfraInvestigatorDetailDataMapper()
    private TreatingDoctorDataMapper = new TreatingDoctorDataMapper()
    private PathologistInvestigatorDataMapper = new PathologistInvestigatorDataMapper()
    private InsuredInvestigatorDataMapper = new InsuredInvestigatorDataMapper()
    private AttendentInvestigatorDataMapper = new AttendentInvestigatorDataMapper()
    private AttendentDataMapper = new AttendentDataMapper()
    private InsuredDataMapper = new InsuredDataMapper()
    private TratingDoctorDetailDataMapper = new TratingDoctorDetailDataMapper()
    private PathologistDetailDataMapper = new PathologistDetailDataMapper()
    private InsuredDetailDataMapper = new InsuredDetailDataMapper()

    getHospitalInfraInvestigatorList = async (ro?: GetHospitalInfraInvestigatorRequestObject) => {
        const response = await this.post<APIResponseModel<HospitalInfraInvestigatorRM>>('/hospital/hospitalInfraInvestigatorList', ro)
        if (response instanceof Result.Success) {
            const data: APIResponseModel<HospitalInfraInvestigatorRM> = response.data
            if (data.data !== null) {
                const hospitals = data.data.items.map((it) => this.HospitalInfraInvestigatorDataMapper.map(it))
                return new Result.Success({
                    totalItemCount: data.data.total_item_count,
                    items: hospitals,
                })
            } else {
                return new Result.Error(0, 'Data is null')
            }
        } else {
            return response
        }
    }

    getHospitalInfraInvestigatorDetail = async (ro?: GetHospitalInfraInvestigatorDetailRequestObject) => {
        const response = await this.post<APIResponseModel<HospitalInfraInvestigatorDetailRM>>('/hospital/hospitalInfraInvestigatorDetail', ro)

        if (response instanceof Result.Success) {
            const data: APIResponseModel<HospitalInfraInvestigatorDetailRM> = response.data
            if (data.data !== null) {
                const hospitalsDetail = data.data.items.map((it) => this.HospitalInfraInvestigatorDetailDataMapper.map(it))
                return new Result.Success({
                    totalItemCount: data.data.total_item_count,
                    items: hospitalsDetail,
                })
            } else {
                return new Result.Error(0, 'Data is null')
            }
        } else {
            return response
        }
    }

    updateHospitalFeedBack = async (ro: updateHospitalFeedBackRequestObject) => {
        const response = await this.post<APIResponseModel<HospitalInfraInvestigatorDetailRM>>('/hospital/feedback', ro)
        if (response instanceof Result.Success) {
            const data: APIResponseModel<HospitalInfraInvestigatorDetailRM> = response.data
            if (data.data !== null) {
                const state = this.HospitalInfraInvestigatorDetailDataMapper.map(data.data.items[0])
                return new Result.Success(state)
            } else {
                return new Result.Error(0, 'Data is null')
            }
        } else {
            return response
        }
    }


    getTreatngDoctor = async (ro?: GetTreatngDoctorRequestObject) => {
        const response = await this.post<APIResponseModel<TreatingDoctorRM>>('/hospital/treatingDoctorList', ro)
        if (response instanceof Result.Success) {
            const data: APIResponseModel<TreatingDoctorRM> = response.data
            if (data.data !== null) {
                const hospitals = data.data.items.map((it) => this.TreatingDoctorDataMapper.map(it))
                return new Result.Success({
                    totalItemCount: data.data.total_item_count,
                    items: hospitals,
                })
            } else {
                return new Result.Error(0, 'Data is null')
            }
        } else {
            return response
        }
    }
    getTreatngDoctorInvestigatorList = async (ro?: GetTreatngDoctorInvestigatorRequestObject) => {
        const response = await this.post<APIResponseModel<HospitalInfraInvestigatorRM>>('/hospital/treatingDoctorInvestigatorList', ro)
        if (response instanceof Result.Success) {
            const data: APIResponseModel<HospitalInfraInvestigatorRM> = response.data
            if (data.data !== null) {
                const hospitals = data.data.items.map((it) => this.HospitalInfraInvestigatorDataMapper.map(it))
                return new Result.Success({
                    totalItemCount: data.data.total_item_count,
                    items: hospitals,
                })
            } else {
                return new Result.Error(0, 'Data is null')
            }
        } else {
            return response
        }
    }

    getTreatingDoctorDetail = async (ro?: GetTreatingDoctorDetailRequestObject) => {
        const response = await this.post<APIResponseModel<TreatingDoctorDetailFormRM>>('/hospital/treatingDoctorDetail', ro)

        if (response instanceof Result.Success) {
            const data: APIResponseModel<TreatingDoctorDetailFormRM> = response.data
            if (data.data !== null) {
                const hospitalsDetail = data.data.items.map((it) => this.TratingDoctorDetailDataMapper.map(it))
                return new Result.Success({
                    totalItemCount: data.data.total_item_count,
                    items: hospitalsDetail,
                })
            } else {
                return new Result.Error(0, 'Data is null')
            }
        } else {
            return response
        }
    }


    updateTreatingDoctorFeedBack = async (ro: updateTreatingDoctorFeedBackRequestObject) => {
        const response = await this.post<APIResponseModel<TreatingDoctorDetailFormRM>>('/hospital/feedback', ro)
        if (response instanceof Result.Success) {
            const data: APIResponseModel<TreatingDoctorDetailFormRM> = response.data
            if (data.data !== null) {
                const state = this.TratingDoctorDetailDataMapper.map(data.data.items[0])
                return new Result.Success(state)
            } else {
                return new Result.Error(0, 'Data is null')
            }
        } else {
            return response
        }
    }


    getPathologistList = async (ro?: GetPathologistListRequestObject) => {
        const response = await this.post<APIResponseModel<TreatingDoctorRM>>('/hospital/pathologistList', ro)
        if (response instanceof Result.Success) {
            const data: APIResponseModel<TreatingDoctorRM> = response.data
            if (data.data !== null) {
                const hospitals = data.data.items.map((it) => this.TreatingDoctorDataMapper.map(it))
                return new Result.Success({
                    totalItemCount: data.data.total_item_count,
                    items: hospitals,
                })
            } else {
                return new Result.Error(0, 'Data is null')
            }
        } else {
            return response
        }
    }

    getPathologistInvestigatorList = async (ro?: GetPathologistInvestigatorRequestObject) => {
        const response = await this.post<APIResponseModel<HospitalInfraInvestigatorRM>>('/hospital/pathologistInvestigatorList', ro)
        if (response instanceof Result.Success) {
            const data: APIResponseModel<HospitalInfraInvestigatorRM> = response.data
            if (data.data !== null) {
                const hospitals = data.data.items.map((it) => this.PathologistInvestigatorDataMapper.map(it))
                return new Result.Success({
                    totalItemCount: data.data.total_item_count,
                    items: hospitals,
                })
            } else {
                return new Result.Error(0, 'Data is null')
            }
        } else {
            return response
        }
    }

    getPathologistDetail = async (ro?: GetPathologistDetailRequestObject) => {
        const response = await this.post<APIResponseModel<PathologistDetailRM>>('/hospital/pathologistDetail', ro)

        if (response instanceof Result.Success) {
            const data: APIResponseModel<PathologistDetailRM> = response.data
            if (data.data !== null) {
                const pathologistDetail = data.data.items.map((it) => this.PathologistDetailDataMapper.map(it))
                return new Result.Success({
                    totalItemCount: data.data.total_item_count,
                    items: pathologistDetail,
                })
            } else {
                return new Result.Error(0, 'Data is null')
            }
        } else {
            return response
        }
    }

    updatepathologistFeedBack = async (ro: updatepathologistFeedBackRequestObject) => {
        const response = await this.post<APIResponseModel<PathologistDetailRM>>('/hospital/feedback', ro)
        if (response instanceof Result.Success) {
            const data: APIResponseModel<PathologistDetailRM> = response.data
            if (data.data !== null) {
                const state = this.PathologistDetailDataMapper.map(data.data.items[0])
                return new Result.Success(state)
            } else {
                return new Result.Error(0, 'Data is null')
            }
        } else {
            return response
        }
    }

    getInsuredList = async (ro?: GetInsuredListRequestObject) => {
        const response = await this.post<APIResponseModel<InsuredRM>>('/hospital/insuredList', ro)
        if (response instanceof Result.Success) {
            const data: APIResponseModel<InsuredRM> = response.data
            if (data.data !== null) {
                const hospitals = data.data.items.map((it) => this.InsuredDataMapper.map(it))
                return new Result.Success({
                    totalItemCount: data.data.total_item_count,
                    items: hospitals,
                })
            } else {
                return new Result.Error(0, 'Data is null')
            }
        } else {
            return response
        }
    }

    getInsuredInvestigatorList = async (ro?: GetinsuredInvestigatorRequestObject) => {
        const response = await this.post<APIResponseModel<HospitalInfraInvestigatorRM>>('/hospital/insuredInvestigatorList', ro)
        if (response instanceof Result.Success) {
            const data: APIResponseModel<HospitalInfraInvestigatorRM> = response.data
            if (data.data !== null) {
                const hospitals = data.data.items.map((it) => this.InsuredInvestigatorDataMapper.map(it))
                return new Result.Success({
                    totalItemCount: data.data.total_item_count,
                    items: hospitals,
                })
            } else {
                return new Result.Error(0, 'Data is null')
            }
        } else {
            return response
        }
    }

    getInsuredInvestigatorDetail = async (ro?: GetInsuredInvestigatorDetailRequestObject) => {
        const response = await this.post<APIResponseModel<InsuredDetailRM>>('/hospital/insuredDetail', ro)
        if (response instanceof Result.Success) {
            const data: APIResponseModel<InsuredDetailRM> = response.data
            if (data.data !== null) {
                const hospitalsDetail = data.data.items.map((it) => this.InsuredDetailDataMapper.map(it))
                return new Result.Success({
                    totalItemCount: data.data.total_item_count,
                    items: hospitalsDetail,
                })
            } else {
                return new Result.Error(0, 'Data is null')
            }
        } else {
            return response
        }
    }

    updateInsuredFeedBack = async (ro: updateInsuredFeedBackRequestObject) => {
        const response = await this.post<APIResponseModel<InsuredDetailRM>>('/hospital/feedback', ro)
        if (response instanceof Result.Success) {
            const data: APIResponseModel<InsuredDetailRM> = response.data
            if (data.data !== null) {
                const state = this.InsuredDetailDataMapper.map(data.data.items[0])
                return new Result.Success(state)
            } else {
                return new Result.Error(0, 'Data is null')
            }
        } else {
            return response
        }
    }

    getAttendentList = async (ro?: GetAttendentRequestObject) => {
        const response = await this.post<APIResponseModel<AttendentRM>>('/hospital/attendentList', ro)
        if (response instanceof Result.Success) {
            const data: APIResponseModel<AttendentRM> = response.data
            if (data.data !== null) {
                const hospitals = data.data.items.map((it) => this.AttendentDataMapper.map(it))
                return new Result.Success({
                    totalItemCount: data.data.total_item_count,
                    items: hospitals,
                })
            } else {
                return new Result.Error(0, 'Data is null')
            }
        } else {
            return response
        }
    }
    getAttendentInvestigatorList = async (ro?: GetAttendentInvestigatorRequestObject) => {
        const response = await this.post<APIResponseModel<HospitalInfraInvestigatorRM>>('/hospital/attendentInvestigatorList', ro)
        if (response instanceof Result.Success) {
            const data: APIResponseModel<HospitalInfraInvestigatorRM> = response.data
            if (data.data !== null) {
                const hospitals = data.data.items.map((it) => this.AttendentInvestigatorDataMapper.map(it))
                return new Result.Success({
                    totalItemCount: data.data.total_item_count,
                    items: hospitals,
                })
            } else {
                return new Result.Error(0, 'Data is null')
            }
        } else {
            return response
        }
    }

    getAttendentDetail = async (ro?: GetAttendentDetailRequestObject) => {
        const response = await this.post<APIResponseModel<InsuredDetailRM>>('/hospital/attendentDetail', ro)
        if (response instanceof Result.Success) {
            const data: APIResponseModel<InsuredDetailRM> = response.data
            if (data.data !== null) {
                const hospitalsDetail = data.data.items.map((it) => this.InsuredDetailDataMapper.map(it))
                return new Result.Success({
                    totalItemCount: data.data.total_item_count,
                    items: hospitalsDetail,
                })
            } else {
                return new Result.Error(0, 'Data is null')
            }
        } else {
            return response
        }
    }

    updateAttendentFeedBack = async (ro: updateAttendentFeedBackRequestObject) => {
        const response = await this.post<APIResponseModel<InsuredDetailRM>>('/hospital/feedback', ro)
        if (response instanceof Result.Success) {
            const data: APIResponseModel<InsuredDetailRM> = response.data
            if (data.data !== null) {
                const state = this.InsuredDetailDataMapper.map(data.data.items[0])
                return new Result.Success(state)
            } else {
                return new Result.Error(0, 'Data is null')
            }
        } else {
            return response
        }
    }


}

export interface GetHospitalInfraInvestigatorRequestObject {
    search?: any
    offset?: any
    limit?: any
    hospital_id?: string
}

export interface GetHospitalInfraInvestigatorDetailRequestObject {
    search?: any
    offset?: any
    limit?: any
    hospitalInfra_id?: string
}
export interface GetTreatngDoctorRequestObject {
    search?: any
    offset?: any
    limit?: any
}
export interface GetTreatngDoctorInvestigatorRequestObject {
    search?: any
    offset?: any
    limit?: any
    registrationNumber?: any
}

export interface GetTreatingDoctorDetailRequestObject {
    search?: any
    offset?: any
    limit?: any
    treating_doctor_id?: string
}
export interface GetPathologistListRequestObject {
    search?: any
    offset?: any
    limit?: any
}
export interface GetPathologistInvestigatorRequestObject {
    search?: any
    offset?: any
    limit?: any
    registrationNumber?: any
}
export interface GetPathologistDetailRequestObject {
    search?: any
    offset?: any
    limit?: any
    pathologist_id?: string
}
export interface GetInsuredListRequestObject {
    search?: any
    offset?: any
    limit?: any
}
export interface GetinsuredInvestigatorRequestObject {
    search?: any
    offset?: any
    limit?: any
    mobile?: any
    date?: any
}


export interface GetInsuredInvestigatorDetailRequestObject {
    search?: any
    offset?: any
    limit?: any
    name?: string
    dob?: string
}

export interface GetAttendentRequestObject {
    search?: any
    offset?: any
    limit?: any
}
export interface GetAttendentInvestigatorRequestObject {
    search?: any
    offset?: any
    limit?: any
    mobile?: any
    date?: any
}
export interface GetAttendentDetailRequestObject {
    search?: any
    offset?: any
    limit?: any
    name?: string
    dob?: string
}

export interface updateHospitalFeedBackRequestObject {
    type: string
    feedback?: string
    feedback_id?: string
    hospital_id?: string
}

export interface updateTreatingDoctorFeedBackRequestObject {
    type: string
    feedback?: string
    feedback_id?: string
    treatingdoctor_id?: string
}
export interface updatepathologistFeedBackRequestObject {
    type: string
    feedback?: string
    feedback_id?: string
    pathologist_id?: string
}


export interface updateInsuredFeedBackRequestObject {
    type: string
    feedback?: string
    feedback_id?: string
    insured_name: string
    insured_dob: string
}


export interface updateAttendentFeedBackRequestObject {
    type: string
    feedback?: string
    feedback_id?: string
    attendent_name: string
    attendent_dob: string
}

