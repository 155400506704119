import React from "react";
import { Form, Button, Message } from "semantic-ui-react";
import CaseFormInputClaimNumber from "../CaseFormInputClaimNumber";
import CaseFormInputInsuranceCompany from "../CaseFormInputInsuranceCompany";
import CaseFormInputSubjectLine from "../CaseFormInputSubjectLine";
import CaseRepository, {
  InitiateCaseRequestObject,
} from "../../../../common/repository/CaseRepository";
import Result from "../../../../common/repository/Result";
import CaseFormInputInwardDateTimeFactory from "../CaseFormInputInwardDateTimeFactory";
import CaseFormInputTPA from "../CaseFormInputTPA";
import Case from "../../../../models/Case";
import { toast } from "../../../common/Toast";
import { LocationInputValue } from "../../../common/LocationInput";
import CaseFormInputTypeAndCategory, {
  CaseFormInputTypeAndCategoryValue,
} from "../CaseFormInputTypeAndCategory";
import constants from "../../../../common/constants";
import ClientRepository from "../../../../common/repository/ClientRepository";
import CaseFormInputLocation from "../CaseFormInputLocation";
import StateDistrictRepository from "../../../../common/repository/StateDistrictRepository";
// import StatesDistrictsRepository from "../../../../common/repository/StatesDistrictsRepository";
import CaseCategory from "../../../../models/CaseCategory";

interface Props {
  onSuccess(c: Case): void;
}

interface State {
  case_category: CaseCategory | null;
  claimNumber: string;
  inwardDateTime: string;
  caseType: string;
  caseCategory: string;

  /** company or TPA + company name is required */
  company: string;
  TPA: string;
  companyName: string;

  subjectLine: string;

  case_insuredLocation: LocationInputValue | null;

  /**  form_error is only set to true if claim number already exits*/
  error: boolean;
  loading: boolean;
}

export default class InitiateCaseForm extends React.Component<Props, State> {
  private caseRepo = new CaseRepository();
  private clientRepo = new ClientRepository();
  private stateDistrictRepo = new StateDistrictRepository();

  constructor(props: Props) {
    super(props);
    this.state = {
      case_category: null,
      claimNumber: "",
      inwardDateTime: "",
      TPA: "",
      caseType: "",
      caseCategory: "",
      company: "",
      companyName: "",
      subjectLine: "",
      case_insuredLocation: null,
      loading: false,
      error: false,
    };
  }

  private onSubmit = () => {
    this.setState({ loading: true }, async () => {
      let inwardDateTime = this.state.inwardDateTime;
      if (this.state.caseCategory !== constants.UDI.CaseCategory.code.LTI)
        inwardDateTime += " 09:00";
      const ro: InitiateCaseRequestObject = {
        claim_number: this.state.claimNumber,
        inward_date_time: inwardDateTime,
        subject_line: this.state.subjectLine,
        case_type: this.state.caseType,
        case_category: this.state.caseCategory,
        insured_location: {
          state: this.state.case_insuredLocation!.state || "",
          district: this.state.case_insuredLocation!.district || "",
        },
      };

      if (this.state.TPA) ro.tpa = this.state.TPA;

      if (this.state.company) ro.insurance_company = this.state.company;
      else ro.insurance_company_name = this.state.companyName;

      const result = await this.caseRepo.initateCase(ro);
      if (result instanceof Result.Success) {
        this.setState({ loading: false }, () => {
          toast.success("Case initiated successfully");
          this.props.onSuccess(result.data);
        });
      } else {
        this.setState({ loading: false }, () => {
          const message = result.message || "Something went wrong";
          toast.error(message);
        });
      }
    });
  };

  private disableSubmitButton = () => {
    return (
      this.state.caseType.length === 0 ||
      this.state.caseCategory.length === 0 ||
      this.state.claimNumber.trim().length === 0 ||
      this.state.error ||
      this.state.subjectLine.trim().length === 0 ||
      this.state.inwardDateTime.length === 0 ||
      (this.state.company.length === 0 &&
        (this.state.companyName.trim().length === 0)) ||
      (this.state.case_insuredLocation !== null
        ? this.state.case_insuredLocation.state.trim().length === 0 ||
        this.state.case_insuredLocation.district.trim().length === 0
        : true)
    );
  };

  private insuredStateAndDistrictIsRequired = () => {
    const caseCategory = this.state.case_category;
    if (caseCategory === null) return false;

    if (caseCategory.getCode() === constants.UDI.CaseCategory.code.LTI)
      return false;
    return true;
  };

  render() {
    return (
      <Form loading={this.state.loading}>
        <Message info compact>
          <p>
            Fields marked with <span style={{ color: "red" }}>*</span> are
            mandatory.
          </p>
        </Message>

        <CaseFormInputTypeAndCategory
          labelWidth="200px"
          value={{
            caseType: this.state.caseType,
            caseCategory: this.state.caseCategory,
          }}
          onChange={(it) =>
            this.setState({
              caseType: it.caseType,
              caseCategory: it.caseCategory,
            })
          }
        />

        <CaseFormInputClaimNumber
          labelWidth="200px"
          value={this.state.claimNumber}
          onChange={(value) =>
            this.setState({ claimNumber: value, error: false })
          }
          onError={() => this.setState({ error: true })}
          caseRepository={this.caseRepo}
        />

        <CaseFormInputSubjectLine
          labelWidth="200px"
          value={this.state.subjectLine}
          onChange={(value) => this.setState({ subjectLine: value })}
        />

        <CaseFormInputInwardDateTimeFactory
          labelWidth="200px"
          caseCategoryCode={this.state.caseCategory}
          value={this.state.inwardDateTime}
          onChange={(value) => {
            this.setState({ inwardDateTime: value });
          }}
        />

        <CaseFormInputTPA
          labelWidth="200px"
          isDisabled={false}
          value={this.state.TPA}
          onChange={(value) => this.setState({ TPA: value })}
          clientRepo={this.clientRepo}
        />

        <CaseFormInputInsuranceCompany
          labelWidth="200px"
          isDisabled={false}
          insuranceCompany={this.state.company}
          onInsuranceCompanyChange={(value) =>
            this.setState({ company: value })
          }
          insuranceCompanyName={this.state.companyName}
          onInsuranceCompanyNameChange={(value) =>
            this.setState({ companyName: value })
          }
          clientRepo={this.clientRepo}
        />

        {/* insured state/district input */}
        <CaseFormInputLocation
          labelWidth="200px"
          required={this.insuredStateAndDistrictIsRequired()}
          label="Insured State & District"
          value={this.state.case_insuredLocation}
          onChange={(value) => this.setState({ case_insuredLocation: value })}
          stateDistrictRepo={this.stateDistrictRepo}
        />
        {/* insured state/district input */}

        <Button
          primary
          size="large"
          loading={this.state.loading}
          disabled={this.disableSubmitButton()}
          onClick={this.onSubmit}
          style={{ margin: "16px 0" }}
        >
          Initiate Case
        </Button>
      </Form>
    );
  }
}
