import { NetworkManagerConfig } from './RawRepository'
import config from './config'
import Repository from './Repository'
import LocalStorage from '../../lib/LocalStorage'

export default class ProtectedAPIRepository extends Repository {
  constructor() {
    const token = LocalStorage.get(LocalStorage.KEY_AUTH_TOKEN)
    const c: NetworkManagerConfig = {
      baseURL: config.apiBaseURL,
      headers: { ['x-access-token']: token },
    }
    super(c)
  }
}
