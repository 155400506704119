import { createContext } from 'react'
import User from '../../../../models/User'
import UserWarning from '../../../../models/UserWarning'
import UserRepository from '../../../../common/repository/UserRepository'

interface ContextType {
  userRepo: UserRepository
  users: User[]
  setUsers(users: User[]): void
  userWarnings: UserWarning[]
  setUserWarnings(userWarnings: UserWarning[]): void
  userWarningCount: number
  setUserWarningCount(userWarningCount: number): void
  selectedUserWarning: UserWarning | null
  setSelectedUserWarning(userWarning: UserWarning | null): void
}

export default createContext({} as ContextType)
