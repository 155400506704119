import DomainModel from './DomainModel'
import User from './User'

export default class UserAttendance extends DomainModel {
  constructor(
    private id: string,
    private user: User,
    private month: number,
    private year: number,
    private summary: {
      present: number
      absent: number
      leave: number
      shortLeave: number
      halfLeave: number
      compensation: number
      holiday: number
      offroll: number
      totalDayCount: number
    },
    private attendance: string[]
  ) {
    super()
  }

  getId = () => this.id
  getUser = () => this.user
  getMonth = () => this.month
  getYear = () => this.year
  getSummary = () => this.summary
  getAttendance = () => this.attendance
}
