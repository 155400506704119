import React, { useState, useContext, useEffect } from 'react'
import { Form, Header, Input, Button } from 'semantic-ui-react'
import FormLabel from '../../common/FormLabel'
import SettingsPageContext from './SettingsPageContext'
import Result from '../../../common/repository/Result'
import { toast } from '../../common/Toast'
import validator from '../../../common/validator'

function ClaimSizeSegment() {
  const ctx = useContext(SettingsPageContext)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [docSize, setDocSize] = useState('')
  const [evidenceSizeLTI, setEvidenceSizeLTI] = useState('')
  const [evidenceSizeHVI, setEvidenceSizeHVI] = useState('')
  const [evidenceSizeDTI, setEvidenceSizeDTI] = useState('')

  useEffect(effectInitializeValues, [ctx.settings])
  useEffect(() => {
    effectOnSubmit()
  }, [isSubmitting])

  return (
    <Form loading={isSubmitting}>
      <Form.Group inline>
        <Form.Field>
          <FormLabel width="150px">Documents Size</FormLabel>
          <Input
            label={{ basic: true, content: 'MB' }}
            labelPosition="right"
            value={docSize}
            onChange={(_, { value }) => {
              if (validator.number.isValid(value)) setDocSize(value)
            }}
          />
        </Form.Field>
      </Form.Group>
      <Form.Group inline>
        <Form.Field>
          <FormLabel width="150px">Low TAT Case Evidences Size</FormLabel>
          <Input
            label={{ basic: true, content: 'MB' }}
            labelPosition="right"
            value={evidenceSizeLTI}
            onChange={(_, { value }) => {
              if (validator.number.isValid(value)) setEvidenceSizeLTI(value)
            }}
          />
        </Form.Field>
      </Form.Group>
      <Form.Group inline>
        <Form.Field>
          <FormLabel width="150px">High Value Case Evidences Size</FormLabel>
          <Input
            label={{ basic: true, content: 'MB' }}
            labelPosition="right"
            value={evidenceSizeHVI}
            onChange={(_, { value }) => {
              if (validator.number.isValid(value)) setEvidenceSizeHVI(value)
            }}
          />
        </Form.Field>
      </Form.Group>
      <Form.Group inline>
        <Form.Field>
          <FormLabel width="150px">Detailed TAT Case Evidences Size</FormLabel>
          <Input
            label={{ basic: true, content: 'MB' }}
            labelPosition="right"
            value={evidenceSizeDTI}
            onChange={(_, { value }) => {
              if (validator.number.isValid(value)) setEvidenceSizeDTI(value)
            }}
          />
        </Form.Field>
      </Form.Group>

      <Button primary onClick={() => setIsSubmitting(true)} disabled={isSubmitButtonDisabled()}>
        Submit
      </Button>
    </Form>
  )

  function effectInitializeValues() {
    if (ctx.settings === null) return

    //const leaves = ctx.settings.getLeaves()
    const claimSizes = ctx.settings.getClaimSize()
    let docSize = Math.round((claimSizes.documentSize / 1024 / 1024) * 10) / 10
    let evSizeLTI = Math.round((claimSizes.evidenceSizeLTI / 1024 / 1024) * 10) / 10
    let evSizeHVI = Math.round((claimSizes.evidenceSizeHVI / 1024 / 1024) * 10) / 10
    let evSizeDTI = Math.round((claimSizes.evidenceSizeDTI / 1024 / 1024) * 10) / 10

    setDocSize(docSize.toString())
    setEvidenceSizeLTI(evSizeLTI.toString())
    setEvidenceSizeHVI(evSizeHVI.toString())
    setEvidenceSizeDTI(evSizeDTI.toString())
  }

  async function effectOnSubmit() {
    if (ctx.settings === null || !isSubmitting) return

    const result = await ctx.settingsRepo.updateSettings({
      id: ctx.settings.getId(),
      claim_size: {
        document_size: Number(docSize) * 1024 * 1024,
        evidence_size_LTI: Number(evidenceSizeLTI) * 1024 * 1024,
        evidence_size_HTI: Number(evidenceSizeHVI) * 1024 * 1024,
        evidence_size_DVI: Number(evidenceSizeDTI) * 1024 * 1024,
      },
    })

    if (result instanceof Result.Success) {
      const settings = result.data
      ctx.setSettings(settings)
      toast.success('Successfully updated Settings')
    } else {
      const message = result.message || 'Something went wrong'
      toast.error(message)
    }
    setIsSubmitting(false)
  }

  function isSubmitButtonDisabled() {
    return (
      isSubmitting || docSize.trim().length === 0
      //||
      //evidenceSize.trim().length === 0
    )
  }
}

export default ClaimSizeSegment
