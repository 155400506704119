import DomainModel from './DomainModel'
import User from './User'

export default class UserWarning extends DomainModel {
  constructor(private id: string, private user: User, private warning: string, private date: string) {
    super()
  }

  getId = () => this.id
  getUser = () => this.user
  getWarning = () => this.warning
  getDate = () => this.date
}
