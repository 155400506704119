import React, { useState, useEffect } from "react";
import { Header, Button } from "semantic-ui-react";
import Remove from "../../../../../common/Remove";
import Case from "../../../../../../models/Case";
import CaseRepository from "../../../../../../common/repository/CaseRepository";
import Result from "../../../../../../common/repository/Result";
import { toast } from "../../../../../common/Toast";
import { useHistory } from "react-router-dom";
import RequirePermission from "../../../../../base/RequirePermission";
import permissions from "../../../../../../common/permissions";
import constants from "../../../../../../common/constants";


function ActionRemove({ model }: Props) {
  const caseRepo = new CaseRepository();
  const history = useHistory();
  const [isOpenRemoveModal, setIsOpenRemoveModal] = useState(false);
  const [isOpenWithdrawModal, setIsOpenWithdrawModal] = useState(false);
  const [openReInvestigationModal, SetOpenReInvestigationModal] = useState(false)

  const [isRemoving, setIsRemoving] = useState(false);
  const [isWithdrawing, setIsWithdrawing] = useState(false);
  const [openReInvestigation, SetOpenReInvestigation] = useState(false)


  useEffect(() => {
    effectOnCaseRemove();
  }, [isRemoving]);
  useEffect(() => {
    effectOnCaseWithdraw();
  }, [isWithdrawing]);

  useEffect(() => {
    effectOnReInvestigationCase();
  }, [openReInvestigation]);

  return (
    <>
      <Header as="h3">Case Removal/Withdrawl</Header>

      <RequirePermission permission={permissions.Case.remove}>
        <Remove.Modal
          open={isOpenRemoveModal}
          onClose={() => setIsOpenRemoveModal(false)}
          trigger={
            <Button onClick={() => setIsOpenRemoveModal(true)} negative>
              Remove Case
            </Button>
          }
        >
          <Remove
            loading={isRemoving}
            onCancel={() => setIsOpenRemoveModal(false)}
            onRemove={() => setIsRemoving(true)}
          />
        </Remove.Modal>
      </RequirePermission>

      <RequirePermission permission={permissions.Case.withdraw}>
        <Remove.Modal
          open={isOpenWithdrawModal}
          header="Withdrawl Confirmation"
          onClose={() => setIsOpenWithdrawModal(false)}
          trigger={
            <Button onClick={() => setIsOpenWithdrawModal(true)} negative basic>
              Withdraw Case
            </Button>
          }
        >
          <Remove
            loading={isWithdrawing}
            buttonTitle="Withdraw"
            onCancel={() => setIsOpenWithdrawModal(false)}
            onRemove={() => setIsWithdrawing(true)}
          />
        </Remove.Modal>
      </RequirePermission>


      {(model.getState() === constants.caseStates.graded || model.getState() === constants.caseStates.close) && <RequirePermission permission={permissions.Case.reinvestigate}>
        <Remove.Modal
          open={openReInvestigationModal}
          header="Open Re-Investigation Confirmation"
          onClose={() => SetOpenReInvestigationModal(false)}
          trigger={
            <Button onClick={() => SetOpenReInvestigationModal(true)} color='blue' basic>
              Open Re-Investigation Case
            </Button>
          }
        >
          <Remove
            loading={openReInvestigation}
            buttonTitle="Re-Investigation"
            onCancel={() => SetOpenReInvestigationModal(false)}
            onRemove={() => SetOpenReInvestigation(true)}
          />
        </Remove.Modal>
      </RequirePermission>}

    </>
  );

  async function effectOnCaseRemove() {
    if (isRemoving === false) return;
    const result = await caseRepo.remove(model.getId());
    if (result instanceof Result.Success) {
      setIsOpenRemoveModal(false);
      toast.success("Successfully removed Case");
      history.push(
        `/case/list?category=${model
          .getCategory()
          .getCode()}&state=${model.getState()}`
      );
    } else {
      const message = result.message || "Something went wrong";
      toast.error(message);
    }
    setIsRemoving(false);
  }

  async function effectOnCaseWithdraw() {
    if (isWithdrawing === false) return;
    const result = await caseRepo.withdraw({ case_id: model.getId() });
    if (result instanceof Result.Success) {
      setIsOpenWithdrawModal(false);
      toast.success("Successfully withdraw Case");
      history.push(
        `/case/list?category=${model
          .getCategory()
          .getCode()}&state=${model.getState()}`
      );
    } else {
      const message = result.message || "Something went wrong";
      toast.error(message);
    }
    setIsWithdrawing(false);
  }

  async function effectOnReInvestigationCase() {
    if (openReInvestigation === false) return;
    const result = await caseRepo.OpenReInvestigation({ id: model.getId() });
    if (result instanceof Result.Success) {
      SetOpenReInvestigationModal(false);
      toast.success("Successfully Open Re-Investigation Case");
      history.push(
        `/case/list?category=${model
          .getCategory()
          .getCode()}&state=${model.getState()}`
      );
    } else {
      const message = result.message || "Something went wrong";
      toast.error(message);
    }
    SetOpenReInvestigation(false);
  }

}

interface Props {
  model: Case;
}

export default (props: any) => {
  return (
    <RequirePermission
      permission={[permissions.Case.withdraw, permissions.Case.remove, permissions.Case.reinvestigate]}
      any
    >
      <ActionRemove {...props} />
    </RequirePermission>
  );
};
