import React from "react";
import { Form, Segment } from "semantic-ui-react";
import Button from "../../../../elements/Button";
import InvoiceSection from "../../../../../models/InvoiceSection";
import Result from "../../../../../common/repository/Result";
import { toast } from "../../../../common/Toast";
import { RouteComponentProps } from "react-router-dom";
import ReportRepository from '../../../../../common/repository/ReportRepository'
import HospitalInfraInvestigatorListDetails from "../../../../../models/ReportModels/HospitalInfraInvestigatorListDetails"
import config from "../../../../../common/repository/config"

interface Props {
  //if model is passed that means update
  model?: HospitalInfraInvestigatorListDetails;
  onSuccess: (invoiceSection: InvoiceSection) => void;
  onError?: () => void;
}

interface MatchProps {
  Id: string;
}
interface Props extends RouteComponentProps<MatchProps> { }

interface State {
  form_loading: boolean;
  hospitalInfraInvestigatorListDetail: HospitalInfraInvestigatorListDetails[]
  bed_icu: number
  case_id: string
  changeNamePastMonth: boolean
  contact_person: string
  createdAt: string
  dateOfChange: string
  designation: string
  full_time_doctor: number
  hospital: string
  hospitalType: string
  hospital_id: string
  hospital_images: string
  hospital_owner: string
  hospital_reg_certificate: string
  hospital_reg_number: string
  icu_facility: string
  inHouse_lab: string
  inHouse_pharmacy: string
  investigator_id: string
  isFieldSaved: string
  name: string
  number_of_doctor: boolean
  old_name: string
  ot_facility: string
  patients_in_icu: boolean
  patients_in_opd: boolean
  phone: string
  updatedAt: string
  visible_staff: boolean
  feedback: string
  feedBackID: string
}

class HospitalInfraInvestigatorDetailForm extends React.Component<Props, State> {
  private ReportRepository = new ReportRepository()

  constructor(props: Props) {
    super(props);

    const stateFromModel = this.deriveStateFromModel();
    this.state = {
      form_loading: false,
      hospitalInfraInvestigatorListDetail: [],
      feedBackID: "",
      ...stateFromModel,
    };
  }


  componentDidMount() {
    this.getHospitalInfraInvestigatorDetail()
  }
  private deriveStateFromModel = () => {
    const model = this.props.model;

    return {

      bed_icu: (model && model.getBedIcu()) || 0,
      case_id: (model && model.getCaseId()) || "",
      changeNamePastMonth: (model && model.getChangeNamePastMonth()) || false,
      contact_person: (model && model.getContactPerson()) || "",
      createdAt: (model && model.getCreatedAt()) || "",
      dateOfChange: (model && model.getDateOfChange()) || "",
      designation: (model && model.getDesignation()) || "",
      full_time_doctor: (model && model.getFullTimeDoctor()) || 0,
      hospital: (model && model.getHospital()) || "",
      hospitalType: (model && model.getHospitalType()) || "",
      hospital_id: (model && model.getHospital_id()) || "",
      hospital_images: (model && model.getHospital_images()) || "",
      hospital_owner: (model && model.getHospital_owner()) || "",
      hospital_reg_certificate: (model && model.getHospital_reg_certificate()) || "",
      hospital_reg_number: (model && model.getHospital_reg_number()) || "",
      icu_facility: (model && model.getIcu_facility()) || "",
      inHouse_lab: (model && model.getInHouse_lab()) || "",
      inHouse_pharmacy: (model && model.getInHouse_pharmacy()) || "",
      investigator_id: (model && model.getInvestigator_id()) || "",
      isFieldSaved: (model && model.getIsFieldSaved()) || "",
      name: (model && model.getName()) || "",
      number_of_doctor: (model && model.getNumber_of_doctor()) || false,
      old_name: (model && model.getOld_name()) || "",
      ot_facility: (model && model.getOt_facility()) || "",
      patients_in_icu: (model && model.getPatients_in_icu()) || false,
      patients_in_opd: (model && model.getPatients_in_opd()) || false,
      phone: (model && model.getPhone()) || "",
      updatedAt: (model && model.getUpdatedAt()) || "",
      visible_staff: (model && model.getVisibleStaff()) || false,
      feedback: (model && model.getFeedBack()) || "",
    };
  };

  private updateFeedBack = async () => {
    const InveId = this.props.match.params;
    const result = await this.ReportRepository.updateHospitalFeedBack({
      type: "hospital",
      feedback: this.state.feedback,
      feedback_id: this.state.feedBackID,
      hospital_id: InveId.Id,
    });
    if (result instanceof Result.Success) {
      const state = result.data;
      toast.success("state Updated Successfully!");
    } else {
      const message = result.message || "Something went wrong";
      this.setState({ form_loading: false }, () => {
        toast.error(message);
        if (this.props.onError) this.props.onError();
      });
    }
  };


  private getHospitalInfraInvestigatorDetail = () => {
    const InveId = this.props.match.params;
    this.setState({ form_loading: true }, async () => {
      const result = await this.ReportRepository.getHospitalInfraInvestigatorDetail({ hospitalInfra_id: InveId.Id });
      if (result instanceof Result.Success) {
        const HospitalInfraInvestigatorDetail = result.data.items;
        this.setState({
          form_loading: false,
          hospitalInfraInvestigatorListDetail: HospitalInfraInvestigatorDetail,
          feedback: HospitalInfraInvestigatorDetail[0].getFeedBack(),
          feedBackID: HospitalInfraInvestigatorDetail[0].getFeedBackId()
        });
      } else {
        const message = result.message || "Something went wrong";
        this.setState({ form_loading: false }, () =>
          toast.error(message)
        );
      }
    });
  };

  render(): JSX.Element {
    let noAvailable = "not available"


    return (
      <Segment >
        <h1>Hospital Infrastructure Investigator Detail</h1>
        <Form loading={this.state.form_loading}>
          <Form.Group>
            <Form.Input
              required
              type="input"
              width="4"
              label="Hospital Name"
              value={this.state.hospitalInfraInvestigatorListDetail[0]?.getHospital() || noAvailable}
            />
            <Form.Input
              required
              type="input"
              width="4"
              label="City"
              value={noAvailable}
            />
            <Form.Input
              required
              type="input"
              width="4"
              label="Address"
              value={noAvailable}
            />
            <Form.Input
              required
              type="input"
              width="4"
              label="Landmark"
              value={noAvailable}
            />
          </Form.Group>


          <Form.Group>
            {this.state.hospitalInfraInvestigatorListDetail[0]?.getChangeNamePastMonth() === true && <>
              <Form.Input
                required
                type="input"
                width="4"
                label="Previous Name of Hospital"
                value={this.state.hospitalInfraInvestigatorListDetail[0]?.getHospital() || noAvailable}
              />
              <Form.Input
                required
                type="input"
                width="4"
                label="
              Date of Change of Hospital Name"
                value={noAvailable}
              />
            </>}
            {this.state.hospitalInfraInvestigatorListDetail[0]?.getHospital_reg_certificate() ? <Form.Input
              required
              type="input"
              width="4"
              label="Hospital Registration Certificate"
              value={noAvailable}
            >
              {<a className={"ui green  button"}
                href={`${config.mrdRecipt}${this.state.hospitalInfraInvestigatorListDetail[0]?.getHospital_reg_certificate()}`}
                target={"_blank"}
              >
                Certificate
              </a> || "not available "}
            </Form.Input> :
              <Form.Input
                required
                type="input"
                width="4"
                label="Hospital Registration Certificate"
                value={noAvailable}
              />}
          </Form.Group>
          <Form.Group>
            <Form.Input
              required
              type="input"
              width="4"
              label="Hospital Registration Number"
              value={this.state.hospitalInfraInvestigatorListDetail[0]?.getHospital_reg_number() || noAvailable}
            />
            <Form.Input
              required
              type="input"
              width="4"
              label="Hospital Type"
              value={this.state.hospitalInfraInvestigatorListDetail[0]?.getHospitalType() || noAvailable}
            />
            <Form.Input
              required
              type="input"
              width="4"
              label="Hospital Owner"
              value={this.state.hospitalInfraInvestigatorListDetail[0]?.getHospital_owner() || noAvailable}
            />
            <Form.Input
              required
              type="input"
              width="4"
              label="Number of Doctors"
              value={this.state.hospitalInfraInvestigatorListDetail[0]?.getNumber_of_doctor() || noAvailable}
            />
          </Form.Group>
          <Form.Group>
            {this.state.hospitalInfraInvestigatorListDetail[0]?.getHospital_images() ? <Form.Input
              required
              type="input"
              width="4"
              label="Hospital Image"
              value={noAvailable}
            >
              {<a className={"ui yellow  button"}
                href={`${config.mrdRecipt}${this.state.hospitalInfraInvestigatorListDetail[0]?.getHospital_images()}`}
                target={"_blank"}
              >
                Hospital Img
              </a> || "not available "}
            </Form.Input> :
              <Form.Input
                required
                type="input"
                width="4"
                label="Hospital Image"
                value={noAvailable}
              />
            }

          </Form.Group>

          <Form.Group>
            <Form.Input
              required
              type="input"
              width="4"
              label="Full Time Doctors"
              value={this.state.hospitalInfraInvestigatorListDetail[0]?.getFullTimeDoctor() || noAvailable}
            />
            <Form.Input
              required
              type="input"
              width="4"
              label="ICU Facility"
              value={this.state.hospitalInfraInvestigatorListDetail[0]?.getIcu_facility() || noAvailable}
            />
            <Form.Input
              required
              type="input"
              width="4"
              label="Beds in ICU"
              value={this.state.hospitalInfraInvestigatorListDetail[0]?.getBedIcu() || noAvailable}
            />
            <Form.Input
              required
              type="input"
              width="4"
              label="Patients in ICU"
              value={this.state.hospitalInfraInvestigatorListDetail[0]?.getPatients_in_icu() || noAvailable}
            />
          </Form.Group>

          <Form.Group>
            <Form.Input
              required
              type="input"
              width="4"
              label="OT Facility"
              value={this.state.hospitalInfraInvestigatorListDetail[0]?.getOt_facility() || noAvailable}
            />
            <Form.Input
              required
              type="input"
              width="4"
              label="In House Lab"
              value={this.state.hospitalInfraInvestigatorListDetail[0]?.getInHouse_lab() || noAvailable}
            />
            <Form.Input
              required
              type="input"
              width="4"
              label="In House Pharmacy"
              value={this.state.hospitalInfraInvestigatorListDetail[0]?.getInHouse_pharmacy() || noAvailable}
            />
            <Form.Input
              required
              type="input"
              width="4"
              label="Patients in OPD"
              value={this.state.hospitalInfraInvestigatorListDetail[0]?.getPatients_in_opd() || noAvailable}
            />
          </Form.Group>

          <Form.Group>
            <Form.Input
              required
              type="input"
              width="4"
              label="Visible staff in Hospital"
              value={this.state.hospitalInfraInvestigatorListDetail[0]?.getVisibleStaff() || noAvailable}
            />
            <Form.Input
              required
              type="input"
              width="4"
              label="Cases Investigated in past 6 months"
              value={this.state.hospitalInfraInvestigatorListDetail[0]?.getCaseInvestigatedSix()}
            />
            <Form.Input
              required
              type="input"
              width="4"
              label="
              % Repudiation under Fraud"
              value={this.state.hospitalInfraInvestigatorListDetail[0]?.getRepudiationFraud()}
            />
            <Form.Input
              required
              type="input"
              width="4"
              label="% Other Repudiation"
              value={this.state.hospitalInfraInvestigatorListDetail[0]?.getRepudiationOther()}
            />
          </Form.Group>
          <Form.Group>
            <Form.Input
              type="input"
              width="4"
              label="% Total Repudiation"
              value={this.state.hospitalInfraInvestigatorListDetail[0]?.getRepudiationTotal()}
            />
          </Form.Group>
          <h1>Contact Person</h1>
          <Form.Group>
            <Form.Input
              type="input"
              width="6"
              label="Name of Contact Person"
            // value={noAvailable}
            />
            <Form.Input
              type="input"
              width="6"
              label="Designation"
            // value={noAvailable}
            />
            <Form.Input
              type="input"
              width="6"
              label="
              Contact Number"
            // value={noAvailable}
            />
          </Form.Group>
          <Form.Group>
            <Form.Input
              type="input"
              width="16"
              label="Address"
            // value={noAvailable}
            />
          </Form.Group>
          <h1>Feedback</h1>
          <Form.Group>
            <Form.Input
              type="input"
              width="16"
              label="Feedback"
              value={this.state.feedback}
              onChange={(_, { value }) => this.setState({ feedback: value })}
            />
          </Form.Group>
          <Button
            positive
            onClick={this.updateFeedBack}
            disabled={this.state.feedback.length === 0}
          >
            Submit
          </Button>
        </Form>
      </Segment>
    );
  }
}

export default HospitalInfraInvestigatorDetailForm;
