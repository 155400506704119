import React from "react";
import styled from "styled-components";
import User from "../../../models/User";
import AttendanceCard from "./AttendanceCard";

const maxWidth = 1100;
const gutter = 16;
const columnCount = 5;
const columnWidth = (maxWidth - (gutter * columnCount - 1)) / columnCount;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(${columnCount}, ${columnWidth}px);
  grid-auto-rows: 1fr;
  grid-gap: ${gutter}px;
`;

function AttendanceGrid({ value, onChange, editable }: Props) {
  const attendanceCardComponents = value.map((it, i) => (
    <AttendanceCard
      key={i.toString()}
      user={it.user}
      attendanceState={it.attendanceState}
      onChange={editable ? onItemChange : undefined}
    />
  ));
  return <Grid>{attendanceCardComponents}</Grid>;

  function onItemChange(userId: string, attendanceState: string) {}
}

export interface AttendanceGridValue {
  user: User;
  attendanceState: string;
}

interface Props {
  value: AttendanceGridValue[];
  onChange(value: AttendanceGridValue[]): void;
  editable?: boolean;
}

AttendanceGrid.defaultProps = {
  editable: true,
};

export default AttendanceGrid;
