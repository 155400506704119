import DomainModel from './DomainModel'
import User from './User'

class Remark extends DomainModel {
  constructor(private id: string, private text: string, private madeBy: User, private madeOn: string) {
    super()
  }

  getId = () => this.id
  getText = () => this.text
  getMadeBy = () => this.madeBy
  getMadeOn = () => this.madeOn
}

export default Remark
