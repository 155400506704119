import React from 'react'
import { DropdownItemProps } from 'semantic-ui-react'

interface ContextType {
  options: Options
  filters: Filters
}

interface Options {
  client: DropdownItemProps[]
  caseType: DropdownItemProps[]
}

interface Filters {
  client: string
  setClient(client: string): void
  caseType: string
  setCaseType(caseType: string): void
  claimNumber: string
  setClaimNumber(claimNumber: string): void
  from: string
  setFrom(from: string): void
  to: string
  setTo(to: string): void
  setToInvoiceDate(to: string): void
  fromInvoiceDate: string
  setFromInvoiceDate(fromInvoiceDate: string): void
  toInvoiceDate: string
  applyFilters(): void
  clearAllFilters(): void

  invoiceNumber: string
  setInvoiceNumber(invoiceNumber: string): void

  searchByDocPending: boolean
  setSearchByDocPending(searchByDocPending: boolean): void
  searchByInvoicePending: boolean
  setSearchByInvoicePending(searchByInvoicePending: boolean): void
  searchByUploadPending: boolean
  setSearchByUploadPending(searchByUploadPending: boolean): void

  Reinvestigation: boolean
  setReinvestigation(Reinvestigation: boolean): void
  Pushback: boolean
  setPushback(Pushback: boolean): void

}

const Context = React.createContext({} as ContextType)
export default Context
