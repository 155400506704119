import DomainModel from './../DomainModel'

class InsuredInfoData extends DomainModel {
    constructor(
        private alternativePhone: string,
        private contactNumber: string,
        private email: string,
        private hospitalData: { name: string },
        private insuredConfirmation: string,
        private investigatorName: string,
        private isFieldSaved: string,
        private occupation: string,
        private occupationAddress: string,
        private occupationId: string,
        private meetingPlace: string,
        private name: string,
        private relationWithInsured: string,
        private supportingDocuments: any,
    ) {

        super()
    }
    getAlternativePhone = () => this.alternativePhone
    getContactNumber = () => this.contactNumber
    getEmail = () => this.email
    getHospitalData = () => this.hospitalData
    getInsuredConfirmation = () => this.insuredConfirmation
    getInvestigatorName = () => this.investigatorName
    getIsFieldSaved = () => this.isFieldSaved
    getOccupation = () => this.occupation
    getOccupationAddress = () => this.occupationAddress
    getOccupationId = () => this.occupationId
    getMeetingPlace = () => this.meetingPlace
    getName = () => this.name
    getRelationWithInsured = () => this.relationWithInsured
    getSupportingDocuments = () => this.supportingDocuments


}

export default InsuredInfoData
