import React from "react";
import styled from "styled-components";
import BaseProps from "../../base/BaseProps";
import { motion } from "framer-motion";

interface BoxProps {
  size: number;
}
const Box = styled.div<BoxProps>`
  width: ${(p) => `${p.size}px`};
  height: ${(p) => `${p.size}px`};

  background-color: rgba(0, 0, 0, 0.04);
  border-radius: ${(p) => `${p.size / 4}px`};
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

interface Props extends BaseProps {}

const Background = (props: Props) => {
  const boxes = [];
  for (let i = 0; i < 20; i++) {
    const x = getRandomInt(0 + 100, window.innerWidth - 100);
    const y = getRandomInt(0 + 100, window.innerHeight - 100);
    const size = getRandomInt(40, 100);
    const duration = getRandomInt(10, 50);
    boxes.push(<AnimatedBox size={size} x={x} y={y} duration={duration} />);
  }

  return (
    <Container>
      {boxes}
      {props.children}
    </Container>
  );
};

interface AnimatedBoxProps {
  size: number;
  x: number;
  y: number;
  duration: number;
}

const AnimatedBox = ({ size, x, y, duration }: AnimatedBoxProps) => {
  return (
    <div
      style={{
        width: `${size}px`,
        height: `${size}px`,
        position: "absolute",
        top: `${y}px`,
        left: `${x}px`,
      }}
    >
      <motion.div
        animate={{ scale: 0.5, rotate: 360 }}
        transition={{ yoyo: Infinity, duration }}
      >
        <Box size={size} />
      </motion.div>
    </div>
  );
};

const getRandomInt = (min: number, max: number) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export default Background;
