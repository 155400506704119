import React from "react";
import Case from "../../../../models/Case";
import CompleteCase from "../../../../models/CompleteCase";
import CaseType from "../../../../models/CaseType";
import ColumnRenderer from "../../../app/Table/renderer/ColumnRenderer";
import constants from "../../../../common/constants";
import { Button, Popup } from "semantic-ui-react";
import { Link } from "react-router-dom";
import Reminder from "../../../../models/Reminder";
import LocalStorage from "../../../../lib/LocalStorage";
import axios from "axios";
import { toast } from "../../../common/Toast";
import config from "../../../../common/repository/config";
export default class CaseColumnRenderer extends ColumnRenderer {
  //if view type is false, it will return remainder, available tat fields along with case else return final remark,
  // closure tat, allocation delay along with case
  private viewType: boolean = false;
  constructor(viewType?: boolean | undefined) {
    super();
    if (viewType) this.viewType = viewType;
  }

  fun = async (caseId: string) => {
    toast.success("Please wait, while we are collecting data.");
    const token = LocalStorage.get(LocalStorage.KEY_AUTH_TOKEN);
    const response = await axios({
      url: config.apiBaseURL + `/download`,
      method: "POST",
      responseType: "blob",
      data: {
        action: "case",
        data: [caseId],
      },
      headers: { ["x-access-token"]: token },
    });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "CaseRecord.zip");
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  calculateHours(startDate: Date, endDate: Date) {
    // body...

    // Store minutes worked
    let minutesWorked = 0;

    // Validate input
    if (endDate < startDate) {
      return 0;
    }

    // Loop from your Start to End dates (by hour)
    let current = startDate;

    // Define work range
    let workHoursStart = 9;
    let workHoursEnd = 18;
    let includeWeekends = false;

    // Loop while currentDate is less than end Date (by minutes)
    while (current <= endDate) {
      // Is the current time within a work day (and if it
      // occurs on a weekend or not)
      if (
        current.getHours() >= workHoursStart &&
        current.getHours() < workHoursEnd &&
        (includeWeekends
          ? current.getDay() !== 0 && current.getDay() !== 6
          : true)
      ) {
        minutesWorked++;
      }

      // Increment current time
      current.setTime(current.getTime() + 1000 * 60);
    }
    // minutesWorked--

    // Return the number of hours
    // console.log(minutesWorked)
    //if(hrs>)
    return Math.round(minutesWorked / 60);
  }

  getColumns = () => {
    if (this.viewType)
      return [
        {
          name: "Case Category",
          selector: "id",
          cell: (row: {}) => {
            if (row instanceof Case) return row.getCategory().getTitle();
          },
        },
        {
          name: "Case Type",
          selector: "id",
          cell: (row: {}) => {
            if (row instanceof Case) return row.getType().getTitle();
          },
        },
        {
          name: "Closure TAT",
          selector: "id",
          cell: (row: {}) => {
            if (row instanceof CompleteCase) {
              let inwardDate = new Date(row.getInwardDateTime());
              let closeDate = new Date(row.getClosedOn()!);
              let hrs = this.calculateHours(inwardDate, closeDate);
              let avTAT = "";
              let days = Math.floor(hrs / 9);
              let hours = hrs % 9;
              if (days > 1) avTAT = `${days} Days `;
              else if (days == 1) avTAT = `${days} Day `;
              if (hours > 0) avTAT += `${hours} Hours`;
              return avTAT;
            }
          },
        },
        {
          name: "Claim Number",
          selector: "claimNumber",
        },
        {
          name: "TPA/Company Name",
          selector: "id",
          cell: (row: {}) => {
            if (row instanceof Case) {
              const tpa = row.getTPA();
              const company = row.getInsuranceCompany();
              const companyName = row.getInsuranceCompanyName();
              if (tpa !== null) return tpa.getName();
              else if (company !== null) return company.getName();
              else if (companyName !== null) return companyName;
              return "";
            }
          },
        },
        {
          name: "Insured Name",
          selector: "id",
          cell: (row: {}) => {
            if (row instanceof CompleteCase) return row.getInsuredName();
          },
        },
        // {
        //     name: 'Insured District',
        //     selector: 'id',
        //     cell: (row: {}) => {
        //         if (row instanceof CompleteCase) {
        //             const location = row.getInsuredLocation()
        //             if (location !== null)
        //                 return location.getDistrict()
        //         }
        //     }
        // },
        {
          name: "Insured District",
          selector: "id",
          cell: (row: {}) => {
            if (row instanceof Case)
              return row.getInsuredLocation()?.getDistrict();
          },
        },
        {
          name: "Hospital",
          selector: "id",
          cell: (row: {}) => {
            if (row instanceof CompleteCase) return row.getHospital().getName();
          },
        },
        {
          name: "Outcome",
          selector: "id",
          cell: (row: {}) => {
            if (row instanceof CompleteCase) return row.getOutcome();
          },
        },
        {
          name: "Actions",
          selector: "id",
          width: "120px",
          cell: (row: {}) => {
            if (row instanceof CompleteCase) {
              const state = row.getState();
              const id = row.getId();
              const code = row.getCategory().getCode();

              let buttonText: string = "";
              let buttonColor: string = "";
              if (state === constants.caseStates.generated) {
                buttonText = "Allocate";
                buttonColor = "#ffe75e";
              } else if (state === constants.caseStates.open) {
                buttonText = "Open";
                buttonColor = "#deff8b";
              }
              else if (state === constants.caseStates.reinvestigate) {
                buttonText = "Reinvestigate";
                buttonColor = "#deff8b";
              }
              else if (state === constants.caseStates.fold) {
                buttonText = "Perform QC";
                buttonColor = "#ff6f5e";
              } else if (state === constants.caseStates.checked) {
                buttonText = "P. Closure";
                buttonColor = "#f0134d";
              } else if (state === constants.caseStates.close) {
                buttonText = "P. Grading";
                buttonColor = "#d1f5d3";
              } else {
                buttonText = "Closed";
                if (row.getIsWithdrawed() == true) buttonColor = "#cd5c5c";
              }

              return (
                <Button
                  as={Link}
                  style={{ width: 150, backgroundColor: buttonColor }}
                  to={{
                    pathname: `/case/view/${id}`,
                    state: `/case/list?category=${code}&state=${state}`,
                  }}
                >
                  {buttonText}
                </Button>
              );
            } else if (row instanceof Case) {
              return (
                <Button
                  style={{ width: 150 }}
                  primary
                  as={Link}
                  to={`/case/generate/${row.getId()}`}
                >
                  Generate
                </Button>
              );
            }
          },
        },
        {
          name: "Actions",
          selector: "id",
          width: "100px",
          cell: (row: {}) => {
            if (row instanceof CompleteCase) {
              const id = row.getId();

              return (
                <Button
                  style={{
                    width: 90,
                    marginLeft: "-10px",
                    paddingLeft: "0px",
                    paddingRight: "0px",
                  }}
                  onClick={(_, { value }) => this.fun(id)}
                >
                  Download
                </Button>
              );
            } else if (row instanceof Case) {
              const id = row.getId();
              return (
                <Button
                  style={{
                    width: 90,
                    marginLeft: "-10px",
                    paddingLeft: "0px",
                    paddingRight: "0px",
                  }}
                  onClick={(_, { value }) => this.fun(id)}
                >
                  Download
                </Button>
              );
            }
          },
        },
      ];
    else
      return [
        {
          name: "Reminders",
          width: "80px",
          selector: "id",
          grow: 0,
          cell: (row: {}) => {
            if (row instanceof CompleteCase) {
              const reminders = row.getReminders();
              if (reminders.length === 0) return null;

              //find reminder with least remaining time
              let reminder: Reminder | null = null;
              reminders.forEach((r) => {
                if (!r.getActive()) return;

                if (reminder === null) {
                  reminder = r;
                } else if (r.getRemainingTime() < reminder.getRemainingTime()) {
                  reminder = r;
                }
              });
              if (reminder === null) return null;

              let backgroundColor = "green";
              let textColor = "#222";
              const time = reminder!.getRemainingTime();
              if (time < 18000) {
                backgroundColor = "red";
                textColor = "white";
              } else if (time < 54000) {
                backgroundColor = "yellow";
              }

              return (
                <Popup
                  basic
                  position="top center"
                  trigger={
                    <div
                      style={{
                        width: 70,
                        height: 20,
                        borderRadius: 2,
                        opacity: 0.8,
                        background: backgroundColor,
                        cursor: "pointer",
                        color: textColor,
                        fontSize: 9,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {reminder!.getValidUpto()}
                    </div>
                  }
                >
                  <Popup.Content>
                    <div>{reminder!.getText()}</div>
                    <div>@ {reminder!.getValidUpto()}</div>
                  </Popup.Content>
                </Popup>
              );
            }
          },
        },
        {
          name: "Case Category",
          selector: "id",
          cell: (row: {}) => {
            if (row instanceof Case) return row.getCategory().getTitle();
          },
        },
        {
          name: "Case Type",
          selector: "id",
          cell: (row: {}) => {
            if (row instanceof Case) return row.getType().getTitle();
          },
        },
        {
          name: "Running TAT",
          selector: "id",
          cell: (row: {}) => {
            // if (row instanceof Case)
            //     return CaseType.formatTAT(row.getRemainingTAT())

            if (row instanceof Case) {
              // console.log(row.getTatTiming(), "tatatiming")
              if (row.getReinvestigation() === false) {
                let inwardDate = new Date(row.getInwardDateTime());
                let closeDate = new Date();
                // let hrs = (closeDate.getTime() - inwardDate.getTime())/1000;
                // hrs /= (60 * 60);
                // hrs = Math.abs(Math.round(hrs));

                let hrs = this.calculateHours(inwardDate, closeDate);
                let avTAT = "";
                let days = Math.floor(hrs / 9);
                let hours = hrs % 9;
                if (days > 1) avTAT = `${days} Days `;
                else if (days == 1) avTAT = `${days} Day `;
                if (hours > 0) avTAT += `${hours} Hours`;
                return avTAT;
              } else {
                let tatTiming = row.getTatTiming();
                //console.log(tatTiming)
                let hrs = 0;
                tatTiming.forEach((e: any) => {
                  let inwardDate = new Date(e.start_time);
                  let closeDate = e.close_time ? new Date(e.close_time) : new Date();
                  //console.log(inwardDate, closeDate,"closeDatecloseDatecloseDate")
                  //var duration = moment.duration(closeDate.diff(inwardDate));
                  //var hours = duration.asHours();
                  hrs += this.calculateHours(inwardDate, closeDate);
                  //  hrs+=hours
                });

                let avTAT = "";
                let days = Math.floor(hrs / 9);
                let hours = hrs % 9;
                if (days > 1) avTAT = `${days} Days `;
                else if (days == 1) avTAT = `${days} Day `;
                if (hours > 0) avTAT += `${hours} Hours`;
                return avTAT;
              }
            }
            /*
                            var diff =(dt2.getTime() - dt1.getTime()) / 1000;
                            diff /= (60 * 60);
                            return Math.abs(Math.round(diff));
                         */
          },
        },
        {
          name: "Re-Investigation TAT",
          selector: "id",
          cell: (row: {}) => {
            // if (row instanceof Case)
            //     return CaseType.formatTAT(row.getRemainingTAT())

            if (row instanceof Case) {
              // console.log(row.getTatTiming(), "tatatiming")
              if (row.getReinvestigation() === false) {
                return "N/A"
              } else {
                let tatTiming = row.getTatTiming();
                //console.log(tatTiming)
                let hrs = 0;
                tatTiming.forEach((e: any,i:number) => {
                  if(i>0){

                    let inwardDate = new Date(e.start_time);
                    let closeDate = e.close_time ? new Date(e.close_time) : new Date();
                    //console.log(inwardDate, closeDate,"closeDatecloseDatecloseDate")
                    //var duration = moment.duration(closeDate.diff(inwardDate));
                    //var hours = duration.asHours();
                    hrs += this.calculateHours(inwardDate, closeDate);
                  }
                  //  hrs+=hours
                });

                let avTAT = "";
                let days = Math.floor(hrs / 9);
                let hours = hrs % 9;
                if (days > 1) avTAT = `${days} Days `;
                else if (days == 1) avTAT = `${days} Day `;
                if (hours > 0) avTAT += `${hours} Hours`;
                return avTAT;
              }
            }
            /*
                            var diff =(dt2.getTime() - dt1.getTime()) / 1000;
                            diff /= (60 * 60);
                            return Math.abs(Math.round(diff));
                         */
          },
        },
        {
          name: "Claim Number",
          selector: "claimNumber",
        },
        {
          name: "TPA/Company Name",
          selector: "id",
          cell: (row: {}) => {
            if (row instanceof Case) {
              const tpa = row.getTPA();
              const company = row.getInsuranceCompany();
              const companyName = row.getInsuranceCompanyName();
              if (tpa !== null) return tpa.getName();
              else if (company !== null) return company.getName();
              else if (companyName !== null) return companyName;
              return "";
            }
          },
        },
        {
          name: "Insured Name",
          selector: "id",
          cell: (row: {}) => {
            if (row instanceof CompleteCase) return row.getInsuredName();
          },
        },
        {
          name: "Insured District",
          selector: "id",
          cell: (row: {}) => {
            if (row instanceof Case) {
              return row.getInsuredLocation()
                ? row.getInsuredLocation()?.getDistrict()
                : "";
            }
          },
        },
        {
          name: "Hospital",
          selector: "id",
          cell: (row: {}) => {
            if (row instanceof CompleteCase) return row.getHospital().getName();
          },
        },
        {
          name: "Actions",
          selector: "id",
          width: "120px",
          cell: (row: {}) => {
            if (row instanceof CompleteCase) {
              const state = row.getState();
              const id = row.getId();
              const code = row.getCategory().getCode();

              let buttonText: string = "";
              let buttonColor: string = "";
              if (state === constants.caseStates.generated) {
                buttonText = "Allocate";
                buttonColor = "#ffe75e";
              } else if (state === constants.caseStates.open) {
                buttonText = "Open";
                buttonColor = "#deff8b";
              } else if (state === constants.caseStates.reinvestigate) {
                buttonText = "Reinvestigate";
                buttonColor = "#deff8b";
              }
              else if (state === constants.caseStates.fold) {
                buttonText = "Perform QC";
                buttonColor = "#ff6f5e";
              } else if (state === constants.caseStates.checked) {
                buttonText = "P. Closure";
                buttonColor = "#f0134d";
              } else if (state === constants.caseStates.close) {
                buttonText = "P. Grading";
                buttonColor = "#d1f5d3";
              } else {
                buttonText = "Closed";
                if (row.getIsWithdrawed() == true) buttonColor = "#cd5c5c";
              }

              return (
                <Button
                  as={Link}
                  style={{ width: 150, backgroundColor: buttonColor }}
                  to={{
                    pathname: `/case/view/${id}`,
                    state: `/case/list?category=${code}&state=${state}`,
                  }}
                >
                  {buttonText}
                </Button>
              );
            } else if (row instanceof Case) {
              return (
                <Button
                  style={{ width: 150 }}
                  primary
                  as={Link}
                  to={`/case/generate/${row.getId()}`}
                >
                  Generate
                </Button>
              );
            }
          },
        },
        {
          name: "Actions",
          selector: "id",
          width: "100px",
          cell: (row: {}) => {
            if (row instanceof CompleteCase) {
              const id = row.getId();

              return (
                <Button
                  style={{
                    width: 90,
                    marginLeft: "-10px",
                    paddingLeft: "0px",
                    paddingRight: "0px",
                  }}
                  onClick={(_, { value }) => this.fun(id)}
                >
                  Download
                </Button>
              );
            } else if (row instanceof Case) {
              const id = row.getId();
              return (
                <Button
                  style={{
                    width: 90,
                    marginLeft: "-10px",
                    paddingLeft: "0px",
                    paddingRight: "0px",
                  }}
                  onClick={(_, { value }) => this.fun(id)}
                >
                  Download
                </Button>
              );
            }
          },
        },
      ];
  };
}
