import React from 'react'
import Page from '../../../layout/Page'
import { Modal, Icon, Button } from 'semantic-ui-react'
import HospitalForm from '../../../common/HospitalForm'
import HospitalRepository from '../../../../common/repository/HospitalRepository'
import Result from '../../../../common/repository/Result'
import { toast } from '../../../common/Toast'
import Hospital from '../../../../models/Hospital'
import RemovalConfirmationForm from '../../../common/RemovalConfirmationForm'
import Table from '../../../app/Table'
import HospitalColumnRenderer from './HospitalColumnRenderer'
import RequirePermission from '../../../base/RequirePermission'
import permissions from '../../../../common/permissions'

interface State {
  loading: boolean
  filter: string
  limit: number
  offset: number
  totalHospitalCount: number
  hospitals: Hospital[]

  add_modalOpen: boolean

  update_modalOpen: boolean
  update_model: Hospital | null

  remove_modalOpen: boolean
  remove_model: Hospital | null
}

class HospitalPage extends React.Component<{}, State> {
  private hospitalRepo = new HospitalRepository()
  private columnRenderer = new HospitalColumnRenderer(
    (model: Hospital) => {
      return (
        <Button.Group size="mini">
          <Button onClick={() => this.setState({ update_model: model, update_modalOpen: true })}>Open</Button>
          <Button negative onClick={() => this.setState({ remove_model: model, remove_modalOpen: true })}>
            Remove
          </Button>
        </Button.Group>
      )
    },
    (obj: any) => obj.name !== undefined
  )

  constructor(props: {}) {
    super(props)
    this.state = {
      loading: false,
      filter: '',
      limit: 10,
      offset: 0,
      totalHospitalCount: 0,
      hospitals: [],
      add_modalOpen: false,
      update_modalOpen: false,
      update_model: null,
      remove_modalOpen: false,
      remove_model: null,
    }
  }

  componentDidMount() {
    this.load()
  }

  private onRowsPerPageChange = (page: number, rowsPerPage: number) => {
    // console.log('onRowsPerPageChange', page, rowsPerPage)
    const offset = (page - 1) * rowsPerPage,
      limit = offset + rowsPerPage
    this.setState({ offset, limit }, this.load)
  }
  private load = () => {
    const { offset } = this.state
    this.setState({ loading: true }, async () => {
      const result = await this.getHospitals()
      if (result instanceof Result.Success) {
        if (offset === 0) {
          const hospitals = result.data.items
          const totalHospitalCount = result.data.totalItemCount
          const offset = hospitals.length
          this.setState({ loading: false, offset, hospitals, totalHospitalCount })
        } else {
          const hospitals = [...this.state.hospitals, ...result.data.items]
          const offset = hospitals.length
          this.setState({ loading: false, offset, hospitals })
        }
      } else {
        this.setState({ loading: false }, () => {
          const message = result.message || 'Something went wrong'
          toast.error(message)
        })
      }
    })
  }

  private getHospitals = async () => {
    const ro = {
      search: this.state.filter,
      limit: this.state.limit,
      offset: this.state.offset,
    }
    return await this.hospitalRepo.getHospitals(ro)
  }

  private setFilter = (filter: string) => {
    this.setState({ filter, offset: 0 }, this.load)
  }

  private onHospitalAdded = (hospital: Hospital) => {
    this.setState((currentState) => {
      const hospitals = [hospital, ...currentState.hospitals]
      const totalHospitalCount = currentState.totalHospitalCount + 1
      return { hospitals, totalHospitalCount, add_modalOpen: false }
    })
  }

  private onHospitalUpdated = (hospital: Hospital) => {
    const hospitals = this.state.hospitals
    const index = hospitals.findIndex((it) => it.getId() === hospital.getId())
    if (index !== -1) hospitals[index] = hospital
    this.setState({ hospitals: [...hospitals], update_modalOpen: false, update_model: null })
  }

  private removeHospital = async () => {
    const id = this.state.remove_model!.getId()
    const result = await this.hospitalRepo.removeHospital(id)
    if (result instanceof Result.Success) {
      const hospitals = this.state.hospitals
      let totalHospitalCount = this.state.totalHospitalCount
      const index = hospitals.findIndex((it) => it.getId() === id)
      if (index !== -1) {
        hospitals.splice(index, 1)
        totalHospitalCount--
      }
      this.setState({ hospitals: [...hospitals], totalHospitalCount, remove_modalOpen: false, remove_model: null }, () => toast.success('Hospital removed successfully'))
    } else {
      const message = result.message || 'Something went wrong'
      toast.error(message)
    }
  }

  render() {
    return (
      <Page title="Hospital" description="Manage Hospitals">
        {/* Add Hospital Modal */}
        <Modal
          open={this.state.add_modalOpen}
          onClose={() => this.setState({ add_modalOpen: false })}
          size="tiny"
          closeIcon
          closeOnDimmerClick={false}
          closeOnEscape={false}
          trigger={
            <Button icon labelPosition="left" color="green" onClick={() => this.setState({ add_modalOpen: true })}>
              <Icon name="add" />
              Add New
            </Button>
          }
        >
          <Modal.Header>Add New Hospital</Modal.Header>
          <Modal.Content>
            <HospitalForm onSuccess={this.onHospitalAdded} hospitalList={this.state.hospitals} />
          </Modal.Content>
        </Modal>
        {/* Add Hospital Modal */}

        {/* Update Hospital Modal */}
        <Modal open={this.state.update_modalOpen} onClose={() => this.setState({ update_modalOpen: false, update_model: null })} size="tiny" closeIcon closeOnDimmerClick={false} closeOnEscape={false}>
          <Modal.Header>Update Hospital</Modal.Header>
          <Modal.Content>
            <HospitalForm model={this.state.update_model || undefined} onSuccess={this.onHospitalUpdated} hospitalList={this.state.hospitals} />
          </Modal.Content>
        </Modal>
        {/* Update Hospital Modal */}

        {/* Remove Hospital Modal */}
        <Modal open={this.state.remove_modalOpen} onClose={() => this.setState({ remove_modalOpen: false, remove_model: null })} size="mini" closeIcon closeOnDimmerClick={false} closeOnEscape={false}>
          <Modal.Header>Confirmation</Modal.Header>
          <Modal.Content>
            <RemovalConfirmationForm title="Are you sure you want to remove this?" remove={this.removeHospital} cancel={() => this.setState({ remove_modalOpen: false, remove_model: null })} />
          </Modal.Content>
        </Modal>
        {/* Remove Hospital Modal */}



        <Table
          renderer={this.columnRenderer}
          data={this.state.hospitals}
          totalDataCount={this.state.totalHospitalCount}
          loading={this.state.loading}
          load={this.load}
          onRowsPerPageChange={this.onRowsPerPageChange}
          onSearch={this.setFilter}
          onClear={() => this.setFilter('')}
        />
      </Page>
    )
  }
}

export default (props: any) => {
  return (
    <RequirePermission permission={permissions.Master.CRUD}>
      <HospitalPage {...props} />
    </RequirePermission>
  )
}
