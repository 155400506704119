import React, { useContext, useState } from 'react'
import Page from '../../../layout/Page'
import { Button, Icon, Modal } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import Table from '../../../app/Table'
import ClientListingPageContext from './ClientListingPageContext'
import ClientColumnRenderer from './ClientColumnRenderer'
import Client from '../../../../models/Client'
import RemovalConfirmationForm from '../../../common/RemovalConfirmationForm'

const Layout = () => {
  const ctx = useContext(ClientListingPageContext)
  const columnRenderer = new ClientColumnRenderer(
    (model: Client) => {
      return (
        <Button.Group size="mini">
          <Button as={Link} to={`/master/client/update/${model.getId()}`}>
            Open
          </Button>
          <Button onClick={() => openModal(model)} negative>
            Remove
          </Button>
        </Button.Group>
      )
    },
    (obj: any) => obj.name !== undefined
  )

  /** states */
  const [open, setOpen] = useState(false)
  const [model, setModel] = useState<Client | null>(null)

  const openModal = (model: Client) => {
    setOpen(true)
    setModel(model)
  }

  const closeModal = () => {
    setOpen(false)
    setModel(null)
  }
  const onRowsPerPageChange = (page: number, rowsPerPage: number) => {
    const offset = (page - 1) * rowsPerPage,
      limit = offset + rowsPerPage
    ctx.setLimit(limit)
    ctx.setOffset(offset)
    ctx.load()
  }

  return (
    <Page title="Clients" description="View clients">
      <Button icon labelPosition="left" color="green" as={Link} to="/master/client/add">
        <Icon name="add" />
        Create New
      </Button>

      <Modal open={open} onClose={closeModal} size="mini" closeIcon closeOnDimmerClick={false} closeOnEscape={false}>
        <Modal.Header>Confirmation</Modal.Header>
        <Modal.Content>
          <RemovalConfirmationForm title="Are you sure you want to remove this?" remove={() => ctx.remove(model!, () => closeModal())} cancel={closeModal} />
        </Modal.Content>
      </Modal>

      <Table
        renderer={columnRenderer}
        data={ctx.clients}
        totalDataCount={ctx.count}
        loading={ctx.loading}
        load={ctx.load}
        onRowsPerPageChange={onRowsPerPageChange}
        onSearch={(text) => {
          ctx.setFilter(text)
          ctx.setOffset(0)
          ctx.setCount(0)
          ctx.load()
        }}
        onClear={() => {
          ctx.setFilter('')
          ctx.setOffset(0)
          ctx.setCount(0)
          ctx.load()
        }}
      />
    </Page>
  )
}

export default Layout
