import React from "react";
import { Form, Input } from "semantic-ui-react";
import FormLabel from "../../common/FormLabel";
import DateTimePicker from "../../app/DateTimePicker";
import DateTime from "../../../lib/DateTime";

/**
 * Render this component inside a <Form />
 */

interface CaseFormInputDateProps {
  label: string;
  value: string;
  onChange(value: string): void;

  labelWidth?: string;
  placeholder?: string;
}

const CaseFormInputDate = (props: CaseFormInputDateProps) => {
  const { label, labelWidth, ...restProps } = props;
  const width = labelWidth || "100px";

  return (
    <Form.Group inline>
      <Form.Field required>
        <FormLabel width={width}>{label}</FormLabel>
        <Input>
          <DateTimePicker type="date" {...restProps} />
        </Input>
      </Form.Field>
    </Form.Group>
  );
};

export default React.memo(CaseFormInputDate);
