import React from 'react'
import Page from '../../../../layout/Page'
import { Button } from 'semantic-ui-react'
import Result from '../../../../../common/repository/Result'
import { toast } from '../../../../common/Toast'
import Table from '../../../../app/Table'
import RequirePermission from '../../../../base/RequirePermission'
import permissions from '../../../../../common/permissions'
import { Link } from 'react-router-dom'
import ReportRepository from '../../../../../common/repository/ReportRepository'
import TreatingDoctorColumnRenderer from './TreatingDoctorColumnRenderer'
import TreatingDoctor from '../../../../../models/ReportModels/TreatingDoctor'
interface State {
    loading: boolean
    filter: string
    limit: number
    offset: number
    totalHospitalCount: number
    treatingDoctor: TreatingDoctor[]
}

class TreatingDoctors extends React.Component<{}, State> {
    private ReportRepository = new ReportRepository()
    private columnRenderer = new TreatingDoctorColumnRenderer(
        (model: TreatingDoctor) => {
            let id = model.getId()
            console.log(id)
            return (
                <Button
                    style={{ width: 100 }}
                    primary
                    as={Link}
                    to={{
                        pathname: `/master/reports/treating-doctor/treatng-doctor-investigator/${id}`,
                    }}
                >
                    View
                </Button>
            )
        },
        (obj: any) => obj.name !== undefined
    )

    constructor(props: {}) {
        super(props)
        this.state = {
            loading: false,
            filter: '',
            limit: 10,
            offset: 0,
            totalHospitalCount: 0,
            treatingDoctor: [],
        }
    }

    componentDidMount() {
        this.load()
    }

    private onRowsPerPageChange = (page: number, rowsPerPage: number) => {
        const offset = (page - 1) * rowsPerPage,
            limit = offset + rowsPerPage
        this.setState({ offset, limit }, this.load)
    }
    private load = () => {
        const { offset } = this.state
        this.setState({ loading: true }, async () => {
            const result = await this.getTreatngDoctor()
            if (result instanceof Result.Success) {
                if (offset === 0) {
                    const treatingDoctor = result.data.items
                    const totalHospitalCount = result.data.totalItemCount
                    const offset = treatingDoctor.length
                    this.setState({ loading: false, offset, treatingDoctor, totalHospitalCount })
                } else {
                    const treatingDoctor = [...this.state.treatingDoctor, ...result.data.items]
                    const offset = treatingDoctor.length
                    this.setState({ loading: false, offset, treatingDoctor })
                }
            } else {
                this.setState({ loading: false }, () => {
                    const message = result.message || 'Something went wrong'
                    toast.error(message)
                })
            }
        })
    }

    private getTreatngDoctor = async () => {
        const ro = {
            search: this.state.filter,
            limit: this.state.limit,
            offset: this.state.offset,
        }
        return await this.ReportRepository.getTreatngDoctor(ro)
    }

    private setFilter = (filter: string) => {
        this.setState({ filter, offset: 0 }, this.load)
    }

    render() {
        return (
            <Page title="Treating Doctors" >

                <Table
                    renderer={this.columnRenderer}
                    data={this.state.treatingDoctor}
                    totalDataCount={this.state.totalHospitalCount}
                    loading={this.state.loading}
                    load={this.load}
                    onRowsPerPageChange={this.onRowsPerPageChange}
                    onSearch={this.setFilter}
                    onClear={() => this.setFilter('')}
                />
            </Page>
        )
    }
}

export default (props: any) => {
    return (
        <RequirePermission permission={permissions.Master.CRUD}>
            <TreatingDoctors {...props} />
        </RequirePermission>
    )
}
