import ActionColumnRenderer from '../../../app/Table/renderer/ActionColumnRenderer'
import Client from '../../../../models/Client'

class ClientColumnRenderer extends ActionColumnRenderer<Client> {
  getColumns() {
    return [
      {
        name: 'Name',
        selector: 'name',
      },
      {
        name: 'Type',
        selector: 'type',
      },
      {
        name: 'Handler',
        selector: 'handler',
        format: (row: {}) => {
          if (row instanceof Client) return row.getHandler().getName()
        },
      },
      {
        name: 'MOU Expiration',
        selector: 'MOUExpirationDate',
      },
      {
        name: 'Actions',
        selector: 'id',
        width: 180,
        cell: this.renderActionColumn,
      },
    ]
  }
}

export default ClientColumnRenderer
