import Result from './Result'
import ProtectedAPIRepository from './ProtectedAPIRepository'
import APIResponseModel from './APIResponseModel'
import CaseCategoryRM from '../responsemodel/CaseCategoryRM'
import CaseCategoryDataMapper from '../datamapper/CaseCategoryDataMapper'

export default class CaseCategoryRepository extends ProtectedAPIRepository {
  private dataMapper = new CaseCategoryDataMapper()

  getCategories = async (ro?: GetCategoriesRequestObject) => {
    const response = await this.post<APIResponseModel<CaseCategoryRM>>('/caseCategory', ro)
    if (response instanceof Result.Success) {
      const data: APIResponseModel<CaseCategoryRM> = response.data
      if (data.data !== null) {
        const items = data.data.items.map((it) => this.dataMapper.map(it))
        return new Result.Success({
          items,
          totalItemCount: data.data.total_item_count,
        })
      } else {
        return new Result.Error(0, 'Data is null')
      }
    } else {
      return response
    }
  }


  getCaseCategory = async (ro?: GetCaseCategoryRequestObject) => {
    const response = await this.post<APIResponseModel<CaseCategoryRM>>('/casecategory', ro)
    if (response instanceof Result.Success) {
      const data: APIResponseModel<CaseCategoryRM> = response.data
      if (data.data !== null) {
        const caseCategory = data.data.items.map((it) => this.dataMapper.map(it))
        console.log(caseCategory, "kklkkl")
        return new Result.Success({
          totalItemCount: data.data.total_item_count,
          items: caseCategory,
        })
      } else {
        return new Result.Error(0, 'Data is null')
      }
    } else {
      return response
    }
  }


  addCaseCategory = async (ro: AddCaseCategoryRequestObject) => {
    const response = await this.put<APIResponseModel<CaseCategoryRM>>('/casecategory', ro)
    if (response instanceof Result.Success) {
      const data: APIResponseModel<CaseCategoryRM> = response.data
      if (data.data !== null) {
        const caseCategory = this.dataMapper.map(data.data.items[0])
        console.log(caseCategory, "kkklklkkkljsajhgasjh")
        return new Result.Success(caseCategory)
      } else {
        return new Result.Error(0, 'Data is null')
      }
    } else {
      return response
    }
  }

  updateCateCategory = async (ro: UpdateCaseCategoryRequestObject) => {
    const response = await this.patch<APIResponseModel<CaseCategoryRM>>('/casecategory', ro)
    if (response instanceof Result.Success) {
      const data: APIResponseModel<CaseCategoryRM> = response.data
      if (data.data !== null) {
        const state = this.dataMapper.map(data.data.items[0])
        return new Result.Success(state)
      } else {
        return new Result.Error(0, 'Data is null')
      }
    } else {
      return response
    }
  }

  // Soft delete
  removeCategory = async (ro?: RemoveCategoriesRequestObject) => {
    const response = await this.post<APIResponseModel<null>>('/caseCategory/toogleDelete', ro)
    return response
  }



  // hard Delete
  removeCaseCategory = async (CaseCategoryId: string) => {
    const response = await this.delete<APIResponseModel<null>>(`/casecategory/${CaseCategoryId}`)
    return response
  }

}

interface GetCategoriesRequestObject {
  id?: string
  code?: string
}

interface RemoveCategoriesRequestObject {
  id: string
  isDeleted: boolean
}

export interface GetCaseCategoryRequestObject {
  search?: string
  name?: string
  code?: string
}

export interface AddCaseCategoryRequestObject {
  name: string
  code: string
}

export interface UpdateCaseCategoryRequestObject {
  id: string
  name: string
  code: string
}