import React from 'react'
import Page from '../../../layout/Page'
import { Modal, Icon, Button, Segment, DropdownItemProps, SegmentGroup } from 'semantic-ui-react'
import Result from '../../../../common/repository/Result'
import { toast } from '../../../common/Toast'
// import RemovalConfirmationForm from '../../../common/RemovalConfirmationForm'
// import Table from '../../../app/Table'
import RequirePermission from '../../../base/RequirePermission'
import permissions from '../../../../common/permissions'
import LeaveRequest from "../../../../models/LeaveRequest"
import LeaverequestRepository, { GetLeaveRequestObject } from "../../../../common/repository/LeaveRequestRepository"
// import LeaveRequestColumnRender from "./LeaveRequestColumnRenderer"
import DataTable from "react-data-table-component";
// import LeaveDeclineForm from "../../../common/LeaveDeclineForm/index"
import { Form } from "semantic-ui-react";
import moment from 'moment'
import FilterPageContext from './FilterPageContext'
import Filters from './Filters'

const statusData = ["Pending", "Approved", "Declined", "Canceled"]
const statusDatas = statusData.map((it) => {
    return { text: it, value: it, key: it };
});

interface State {
    loading: boolean
    filter: string
    limit: number
    offset: number
    totalHospitalCount: number
    leaveRequest: LeaveRequest[]

    add_modalOpen: boolean

    update_modalOpen: boolean
    update_model: LeaveRequest | null
    getLevedetail: any

    remove_modalOpen: boolean
    remove_model: LeaveRequest | null

    applied_by: string | null
    session: string | null
    startDate: string | null
    endDate: string | null
    status: string | null
    reason: string | null
    decline_reason: any
    view_decline: any

    // clientOptions: DropdownItemProps[]
    statusOptions: DropdownItemProps[]
    clientFilter: string
    userRoleFilter: string
    statusFilter: string
    applierFilter: string
    fromFilter: string
    toFilter: string
}

class LeaveRequestPage extends React.Component<{}, State> {
    private leaverequestRepository = new LeaverequestRepository()
    // private columnRenderer = new LeaveRequestColumnRender(
    //     (model: LeaveRequest) => {
    //         return (
    //             <Button.Group size="mini">
    //                 <Button onClick={() => this.setState({ update_model: model, update_modalOpen: true })}>Open</Button>
    //                 <Button negative onClick={() => this.setState({ remove_model: model, remove_modalOpen: true })}>
    //                     Remove
    //                 </Button>
    //             </Button.Group>
    //         )
    //     },
    //     (obj: any) => obj.name !== undefined
    // )
    constructor(props: {}) {
        super(props)
        this.state = {
            loading: false,
            filter: '',
            limit: 10,
            offset: 0,
            totalHospitalCount: 0,
            leaveRequest: [],
            add_modalOpen: false,
            update_modalOpen: false,
            update_model: null,
            remove_modalOpen: false,
            remove_model: null,
            getLevedetail: "",
            applied_by: "",
            session: "",
            startDate: "",
            endDate: "",
            status: "",
            reason: "",
            decline_reason: "",
            view_decline: "",

            // clientOptions: [],
            statusOptions: statusDatas,
            clientFilter: '',
            userRoleFilter: '',
            statusFilter: '',
            applierFilter: '',
            fromFilter: '',
            toFilter: '',
        }
    }

    private columns = [
        {
            name: 'Applied-By',
            selector: 'appliedBy',
        },
        {
            name: 'Session',
            selector: 'session',
        },
        {
            name: 'StartDate',
            selector: 'startDate',
            format: (row: {}) => {
                if (row instanceof LeaveRequest) {
                    const startDate = row.getStartDate()
                    if (startDate !== null) return moment(startDate).format('LL')
                }
            },
        },
        {
            name: 'EndDate',
            selector: 'endDate',
            format: (row: {}) => {
                if (row instanceof LeaveRequest) {
                    const endDate = row.getEndDate()
                    if (endDate !== null) return moment(endDate).format('LL')
                }
            },
        },
        {
            name: 'Status',
            selector: 'status',
        },
        {
            name: 'Actions',
            selector: 'id',
            grow: 0,
            width: '180px',
            cell: (row: any) => <>

                {/* {console.log(this.state.status," this.state.status")} */}
                {row.status == "Pending" ? < Button.Group size="mini">
                    <Button onClick={() => this.setState({
                        update_modalOpen: true,
                        getLevedetail: row.id,
                        applied_by: row.appliedBy,
                        session: row.session,
                        startDate: moment(row.startDate).format("LL"),
                        endDate: moment(row.endDate).format("LL"),
                        status: row.status,
                        reason: row.reason,
                        view_decline: row.rejectedReason
                    })}>Approve</Button>
                    <Button negative onClick={() => this.setState({ remove_modalOpen: true, getLevedetail: row.id, status: row.status, })}>
                        Decline
                    </Button>
                </Button.Group> :
                    <Button onClick={() => this.setState({
                        update_modalOpen: true,
                        getLevedetail: row.id,
                        session: row.session,
                        applied_by: row.appliedBy,
                        startDate: moment(row.startDate).format("LL"),
                        endDate: moment(row.endDate).format("LL"),
                        status: row.status,
                        reason: row.reason,
                        view_decline: row.rejectedReason
                    })}>View</Button>
                }

            </>
        },
    ];

    componentDidMount() {
        this.load()
    }

    // private onRowsPerPageChange = (page: number, rowsPerPage: number) => {
    //     const offset = (page - 1) * rowsPerPage,
    //         limit = offset + rowsPerPage
    //     this.setState({ offset, limit }, this.load)
    // }
    private load = async () => {
        if (this.state.statusFilter || this.state.fromFilter || this.state.toFilter || this.state.applierFilter || this.state.filter) {
            await this.setState({ offset: 0 })
        }
        const { offset } = this.state
        this.setState({ loading: true }, async () => {
            const result = await this.getLeaveRequest()
            if (result instanceof Result.Success) {
                if (offset === 0) {
                    const leaveRequest = result.data.items
                    const totalHospitalCount = result.data.totalItemCount
                    // const offset = leaveRequest.length
                    this.setState({ loading: false, offset, leaveRequest, totalHospitalCount })
                } else {
                    const leaveRequest = [...this.state.leaveRequest, ...result.data.items]
                    // const offset = leaveRequest.length
                    this.setState({ loading: false, offset, leaveRequest })
                }
            } else {
                this.setState({ loading: false }, () => {
                    const message = result.message || 'Something went wrong'
                    toast.error(message)
                })
            }
        })
    }

    private getLeaveRequest = async () => {
        const ro: GetLeaveRequestObject = {
            limit: this.state.limit,
            offset: this.state.offset,
        }
        if (this.state.applierFilter) ro.search = this.state.applierFilter
        if (this.state.fromFilter) ro.startDate = this.state.fromFilter
        if (this.state.toFilter) ro.endDate = this.state.toFilter
        if (this.state.statusFilter) ro.status = this.state.statusFilter
        return await this.leaverequestRepository.getLeaveRequest(ro)
    }


    private updateLeave = async (status: any, reason?: any) => {
        const leaveId = this.state.getLevedetail;
        const result = await this.leaverequestRepository.updateLeave({
            id: leaveId,
            status: status,
            rejectedReason: reason
        });

        if (result instanceof Result.Success) {
            const leaveRequest = this.state.leaveRequest
            const leaveReq = result.data;
            toast.success(`Leave ${status} Successfully!`);
            // this.props.onSuccess(leaveReq);
            this.setState({ leaveRequest: [...leaveRequest], update_modalOpen: false, update_model: null, remove_modalOpen: false, decline_reason: "" }, this.load)
            // this.load()
        } else {
            const message = result.message || "Something went wrong";
            this.setState({ loading: false }, () => {
                toast.error(message);
                // if (this.props.onError) this.props.onError();
            });
        }
    };

    private clearAllFilters = async () => {
        await this.setState({
            limit: 0,
            offset: 0,
            statusFilter: '',
            clientFilter: '',
            applierFilter: '',
            fromFilter: '',
            toFilter: '',
        })
        await this.load()
    }
    // private setClientFilter = (val: string) => this.setState({ clientFilter: val })
    private setStatsFilter = (val: string) => this.setState({ statusFilter: val })
    private setApplierFilter = (val: string) => this.setState({ applierFilter: val })
    private setFromFilter = (val: string) => this.setState({ fromFilter: val })
    private setToFilter = (val: string) => this.setState({ toFilter: val })

    render() {
        return (
            <FilterPageContext.Provider
                value={{
                    options: {
                        statusData: this.state.statusOptions,
                    },
                    filters: {
                        status: this.state.statusFilter,
                        setStatus: this.setStatsFilter,
                        applier: this.state.applierFilter,
                        setApplier: this.setApplierFilter,
                        from: this.state.fromFilter,
                        setFrom: this.setFromFilter,
                        to: this.state.toFilter,
                        setTo: this.setToFilter,
                        applyFilters: this.load,
                        clearAllFilters: this.clearAllFilters,
                    },
                }}
            >
                <Page title="Leave" description="Leaves Request">
                    <Segment>
                        <Filters />
                    </Segment>

                    {/* Approve Modal */}
                    <Modal open={this.state.update_modalOpen} onClose={() => this.setState({ update_modalOpen: false, update_model: null })} size="tiny" closeIcon closeOnDimmerClick={false} closeOnEscape={false}>
                        <Modal.Header>Approve</Modal.Header>
                        <Modal.Content>
                            {/* <LeaveDeclineForm model={this.state.update_model || undefined} onSuccess={this.onleaveReqUpdated} /> */}
                            <Form loading={this.state.loading}>
                                {/* applied_by */}
                                <Form.Input
                                    label="Applied-By"
                                    placeholder="..."
                                    value={this.state.applied_by}
                                />
                                {/* applied_by */}
                                {/* name */}
                                <Form.Input
                                    label="Session"
                                    placeholder="..."
                                    value={this.state.session}
                                />
                                {/* name */}
                                <Form.Input
                                    label="StartDate"
                                    placeholder="..."
                                    value={this.state.startDate}
                                />
                                <Form.Input
                                    label="EndDate"
                                    placeholder="..."
                                    value={this.state.endDate}
                                />
                                <Form.Input
                                    label="Status"
                                    placeholder="..."
                                    value={this.state.status}
                                />
                                <Form.Input
                                    label="Reason"
                                    placeholder="..."
                                    value={this.state.reason}
                                />
                                {this.state.status == "Declined" && <Form.Input
                                    label="Decline-Reason"
                                    placeholder="..."
                                    value={this.state.view_decline}
                                />}

                                {this.state.status == "Pending" && <Button
                                    positive
                                    onClick={() => this.updateLeave("Approved")}
                                // disabled={this.state.status != "Pending"}
                                >
                                    Approve
                                </Button>}
                            </Form>
                        </Modal.Content>
                    </Modal>
                    {/* Approve Modal */}

                    {/*Leave Decline Modal */}
                    <Modal open={this.state.remove_modalOpen} onClose={() => this.setState({ remove_modalOpen: false, remove_model: null, decline_reason: "" })} size="mini" closeIcon closeOnDimmerClick={false} closeOnEscape={false}>
                        <Modal.Header>Confirmation</Modal.Header>
                        <Modal.Content>
                            <Form loading={this.state.loading}>
                                <Form.Input
                                    label="Reason"
                                    placeholder="..."
                                    value={this.state.decline_reason}
                                    onChange={(_, { value }) => this.setState({ decline_reason: value })}
                                />
                                <Button
                                    positive
                                    onClick={() => this.updateLeave("Declined", this.state.decline_reason)}
                                    disabled={this.state.decline_reason.length === 0 || this.state.status != "Pending"}
                                >
                                    Decline
                                </Button>
                            </Form>
                        </Modal.Content>
                    </Modal>
                    {/* Leave Decline Modal */}

                    <Segment>
                        <DataTable
                            title=""
                            columns={this.columns}
                            data={this.state.leaveRequest}
                            // defaultSortFieldID={1}
                            pagination
                            striped
                            // dense
                            noHeader
                        // paginationComponent={BootyPagination}
                        // selectableRows
                        // selectableRowsComponent={BootyCheckbox}
                        />
                    </Segment>
                </Page>
            </FilterPageContext.Provider>
        )
    }
}

export default (props: any) => {
    return (
        <RequirePermission permission={permissions.Master.CRUD}>
            <LeaveRequestPage {...props} />
        </RequirePermission>
    )
}
