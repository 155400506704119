import DomainModel from './DomainModel'

export default class PerformanceEvaluation extends DomainModel {
  constructor(private summary: any[], private file: string) {
    super()
  }

  getSummary = () => this.summary
  getFile = () => this.file
}
