import ActionColumnRenderer from '../../../../app/Table/renderer/ActionColumnRenderer'
import InsuredModel from '../../../../../models/ReportModels/InsuredModel'
import moment from 'moment'
export default class InsuredColumnRenderer extends ActionColumnRenderer<InsuredModel> {
    getColumns() {
        return [
            {
                name: 'Name',
                selector: 'name',
                format: (row: {}) => {
                    if (row instanceof InsuredModel) {
                        const reason = row.getName()
                        if (reason !== null) return reason
                        else return "--"
                    }
                },
            },
            {
                name: 'Mobile',
                selector: 'mobile',
            },
            {
                name: 'Date Of Birth',
                selector: 'dob',
                format: (row: {}) => {
                    if (row instanceof InsuredModel) {
                        const dob = row.getDOB()
                        if (dob !== null) return moment(dob).format('LL')
                    }
                },
            },
            {
                name: 'Actions',
                selector: '_id',
                grow: 0,
                width: '180px',
                cell: this.renderActionColumn,
            },
        ]
    }
}
