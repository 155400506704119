import ActionColumnRenderer from '../../../app/Table/renderer/ActionColumnRenderer'
import PolicyType from '../../../../models/PolicyType'

export default class PolicyTypeColumnRenderer extends ActionColumnRenderer<PolicyType> {
  getColumns() {
    return [
      {
        name: 'Title',
        selector: 'title',
        width: '200px',
      },
      {
        name: 'Description',
        selector: 'description',
        grow: 1,
        maxWidth: '710px',
      },
      {
        name: 'Actions',
        selector: 'id',
        grow: 0,
        width: '180px',
        cell: this.renderActionColumn,
      },
    ]
  }
}
