import React, { useContext, useState } from "react";
import { Grid, Form, Header, Icon } from "semantic-ui-react";
import FilterPageContext from "./FilterPageContext";
import DateTimePicker from "../../../../app/DateTimePicker";


function Filters() {
    const ctx = useContext(FilterPageContext);
    const [isExpanded, setIsExpanded] = useState(false);
    let data: { key: string; value: any }[] = [];
    if (ctx.filters.from) data.push({ key: "from", value: ctx.filters.from });
    return (
        <>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Header as="h4">Advance Filter</Header>
                <Icon
                    name="arrow alternate circle down outline"
                    size="large"
                    onClick={() => setIsExpanded(!isExpanded)}
                />
            </div>
            {isExpanded && (<Grid columns={5}>
                <Grid.Row>
                    <Grid.Column>
                        <Form.Input label="Date of Birth">
                            <DateTimePicker
                                placeholder="Select Start Date"
                                type="date"
                                value={ctx.filters.from}
                                onChange={ctx.filters.setFrom}
                            />
                        </Form.Input>
                    </Grid.Column>
                    <Grid.Column>
                        &nbsp;
                        <div className="ui buttons">
                            <button
                                className="ui blue button"
                                onClick={ctx.filters.applyFilters}
                            >
                                Apply Filters
                            </button>
                            <button
                                className="ui button"
                                onClick={ctx.filters.clearAllFilters}
                            >
                                Clear
                            </button>
                        </div>
                    </Grid.Column>
                </Grid.Row>
            </Grid>)}
        </>
    );
}

export default Filters;
