import DomainModel from './DomainModel'

export default class MrdChargesss extends DomainModel {
    //  public  x: any; 
    constructor(public _id: string, private claim_number: string, private hospital_name: string, private investigator_name: string, private mrd_amount: string, private mrd_receipt: string, private accepted: string) {

        super()
        // this.x=_id

    }

    getId = () => this._id
    getClaimNumber = () => this.claim_number
    getHospital = () => this.hospital_name
    getInvestigator = () => this.investigator_name
    getMrd_amount = () => this.mrd_amount
    getMrd_receipt = () => this.mrd_receipt
    getAccepted = () => this.accepted
}
