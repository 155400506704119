

import DomainModel from '../DomainModel'

export default class HospitalInfraInvestigatorListDetails extends DomainModel {
    constructor(
        private _id: string,
        private bed_icu: number,
        private case_id: string,
        private changeNamePastMonth: boolean,
        private contact_person: string,
        private createdAt: string,
        private dateOfChange: string,
        private designation: string,
        private full_time_doctor: number,
        private hospital: string,
        private hospitalType: string,
        private hospital_id: string,
        private hospital_images: string,
        private hospital_owner: string,
        private hospital_reg_certificate: string,
        private hospital_reg_number: string,
        private icu_facility: string,
        private inHouse_lab: string,
        private inHouse_pharmacy: string,
        private investigator_id: string,
        private isFieldSaved: string,
        private name: string,
        private number_of_doctor: boolean,
        private old_name: string,
        private ot_facility: string,
        private patients_in_icu: boolean,
        private patients_in_opd: boolean,
        private phone: string,
        private updatedAt: string,
        private visible_staff: boolean,
        private feedback: string,
        private feedback_id: string,
        private case_investigated_six: number,
        private repudiation_fraud: number,
        private repudiation_other: number,
        private repudiation_total: number,

    ) {
        super()
    }
    getId = () => this._id
    getBedIcu = () => this.bed_icu
    getCaseId = () => this.case_id
    getChangeNamePastMonth = () => this.changeNamePastMonth
    getContactPerson = () => this.contact_person
    getCreatedAt = () => this.createdAt
    getDateOfChange = () => this.dateOfChange
    getDesignation = () => this.designation
    getFullTimeDoctor = () => this.full_time_doctor
    getHospital = () => this.hospital
    getHospitalType = () => this.hospitalType
    getHospital_id = () => this.hospital_id
    getHospital_images = () => this.hospital_images
    getHospital_owner = () => this.hospital_owner
    getHospital_reg_certificate = () => this.hospital_reg_certificate
    getHospital_reg_number = () => this.hospital_reg_number
    getIcu_facility = () => this.icu_facility
    getInHouse_lab = () => this.inHouse_lab
    getInHouse_pharmacy = () => this.inHouse_pharmacy
    getInvestigator_id = () => this.investigator_id
    getIsFieldSaved = () => this.isFieldSaved
    getName = () => this.name
    getNumber_of_doctor = () => this.number_of_doctor
    getOld_name = () => this.old_name
    getOt_facility = () => this.ot_facility
    getPatients_in_icu = () => this.patients_in_icu
    getPatients_in_opd = () => this.patients_in_opd
    getPhone = () => this.phone
    getUpdatedAt = () => this.updatedAt
    getVisibleStaff = () => this.visible_staff
    getFeedBack = () => this.feedback
    getFeedBackId = () => this.feedback_id

    getCaseInvestigatedSix = () => this.case_investigated_six
    getRepudiationFraud = () => this.repudiation_fraud
    getRepudiationOther = () => this.repudiation_other
    getRepudiationTotal = () => this.repudiation_total

}
