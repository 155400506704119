import DomainModel from './DomainModel'
import User from './User'

class Salary extends DomainModel {
  constructor(
    private user: User,
    private month: number,
    private year: number,
    private earnings: {
      basicSalary: number
      hra: number
      miAllowance: number
      specialAllowance: number
      conveyanceAllowance: number
      otherAllowance: number
      otherAllowanceRemarks?: string
    },
    private reimbursements: {
      travelAllowance: number
      documentExpense: number
      cityAllowance: number
      otherExpense: number
      otherExpenseRemarks?: string
    },
    private otherBenefits: {
      caseAllowance: number
      incentive: number
    },
    private deductions: {
      leaveDeduction: number
      otherDeduction: number
      otherDeductionRemarks?: string
      bonus: number
    },
    private other: {
      paidLeaves: number
      unpaidLeaves: number
      availableLeaves: number
      totalCases: number
    }
  ) {
    super()
  }

  getUser = () => this.user
  getMonth = () => this.month
  getYear = () => this.year

  getEarning_basicSalary = () => this.earnings.basicSalary
  getEarning_hra = () => this.earnings.hra
  getEarning_miAllowance = () => this.earnings.miAllowance
  getEarning_specialAllowance = () => this.earnings.specialAllowance
  getEarning_conveyanceAllowance = () => this.earnings.conveyanceAllowance
  getEarning_otherAllowance = () => this.earnings.otherAllowance
  getEarning_otherAllowanceRemarks = () => this.earnings.otherAllowanceRemarks

  getReimbursement_travelAllowance = () => this.reimbursements.travelAllowance
  getReimbursement_documentExpense = () => this.reimbursements.documentExpense
  getReimbursement_cityAllowance = () => this.reimbursements.cityAllowance
  getReimbursement_otherExpense = () => this.reimbursements.otherExpense
  getReimbursement_otherExpenseRemarks = () => this.reimbursements.otherExpenseRemarks

  getOtherBenefit_caseAllowance = () => this.otherBenefits.caseAllowance
  getOtherBenefit_incentive = () => this.otherBenefits.incentive

  getDeduction_leaveDeduction = () => this.deductions.leaveDeduction
  getDeduction_otherDeduction = () => this.deductions.otherDeduction
  getDeduction_otherDeductionRemarks = () => this.deductions.otherDeductionRemarks
  getDeduction_bonus = () => this.deductions.bonus

  getOther_paidLeaves = () => this.other.paidLeaves
  getOther_unpaidLeaves = () => this.other.unpaidLeaves
  getOther_availableLeaves = () => this.other.availableLeaves
  getOther_totalCases = () => this.other.totalCases
}

export default Salary
