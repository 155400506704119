
import DataMapper from '../DataMapper'
import TreatingDoctorDetail from '../../../models/ReportModels/TreatingDoctorDetail'
import TreatingDoctorDetailFormRM from '../../responsemodel/ReportRM/TreatingDoctorDetailFormRM'

export default class TratingDoctorDetailDataMapper extends DataMapper<TreatingDoctorDetailFormRM, TreatingDoctorDetail> {
    map = (rm: TreatingDoctorDetailFormRM) => new TreatingDoctorDetail(
        rm._id,
        rm.case_id,
        rm.contactNumber,
        rm.createdAt,
        rm.doctorAvailableInHospital,
        rm.doctorConfirmation,
        rm.hospital_id,
        rm.hospital_name,
        rm.investigator_id,
        rm.isFieldSaved,
        rm.personalMeeting,
        rm.personalMeetingPic,
        rm.qualification,
        rm.registrationCertificate,
        rm.registrationNumber,
        rm.treatingDoctorName,
        rm.updatedAt,
        rm.feedback,
        rm.feedback_id
    )
}
