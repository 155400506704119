import DomainModel from './DomainModel'

class CaseServiceData extends DomainModel {
    constructor(public AttendentInfoData: any, private DoctorPathologistsData: any, private HospitalInfrastructureData: any, private InsuredInfoData: any, private TreatingDoctorsData: any,) {
        super()
    }
    getAttendentInfoData = () => this.AttendentInfoData
    getDoctorPathologistsData = () => this.DoctorPathologistsData
    getHospitalInfrastructureData = () => this.HospitalInfrastructureData
    getInsuredInfoData = () => this.InsuredInfoData
    getTreatingDoctorsData = () => this.TreatingDoctorsData

}

export default CaseServiceData
