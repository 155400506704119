import React from "react";
import Page from "../../layout/Page";
import { Modal, Icon, Button, Label } from "semantic-ui-react";
import Result from "../../../common/repository/Result";
import { toast } from "../../common/Toast";
import Table from "../../app/Table";
import RequirePermission from "../../base/RequirePermission";
import permissions from "../../../common/permissions";
import LocalStorage from "../../../lib/LocalStorage";
import axios from "axios";

interface State {
  loading: boolean;
  backupLocalButtonDisabled: boolean;
  backupAmazonButtonDisabled: boolean;
  lastBackupDate: string;
}

class Backup extends React.Component<{}, State> {
  constructor(props: {}) {
    super(props);
    this.state = {
      loading: false,
      backupAmazonButtonDisabled: false,
      backupLocalButtonDisabled: false,
      lastBackupDate: "",
    };
  }

  async componentDidMount() {
    this.getLastBackupInfo();
  }

  getLastBackupInfo = async () => {
    const token = LocalStorage.get(LocalStorage.KEY_AUTH_TOKEN);
    const response = await axios({
      url: `/api/backup/info`,
      method: "GET",
      headers: { ["x-access-token"]: token },
    });
    if (response.data.data)
      this.setState({ lastBackupDate: response.data.data.items.modifiedOn });
  };

  downloadBackup = async () => {
    this.setState({ backupLocalButtonDisabled: true });
    setTimeout(
      () => this.setState({ backupLocalButtonDisabled: false }),
      10000
    );
    toast.success("Download will start shortly!");
    const token = LocalStorage.get(LocalStorage.KEY_AUTH_TOKEN);
    const response = await axios({
      url: `/api/backup/download`,
      method: "GET",
      responseType: "blob",
      headers: { ["x-access-token"]: token },
    });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "backup.zip");
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  saveBackupToDrive = async () => {
    this.setState({ backupAmazonButtonDisabled: true });
    toast.success("Process started...");

    setTimeout(
      () => this.setState({ backupAmazonButtonDisabled: false }),
      10000
    );
    const token = LocalStorage.get(LocalStorage.KEY_AUTH_TOKEN);
    const response = await axios({
      url: `/api/backup/save-to-s3`,
      method: "GET",
      headers: { ["x-access-token"]: token },
    });

    toast.success(response.data.message);
    this.getLastBackupInfo();
  };

  render() {
    return (
      <Page
        title="DataBase Backup"
        description="Create database backup locally or save to amazon"
      >
        {this.state.lastBackupDate.length > 0 && (
          <>
            <Label basic>
              Last Backup saved to Amazon at {this.state.lastBackupDate}
            </Label>
            <br />
            <br />
          </>
        )}
        <Button
          color={"blue"}
          onClick={this.downloadBackup}
          disabled={this.state.backupLocalButtonDisabled}
        >
          Download Backup
        </Button>
        <Button
          color={"orange"}
          onClick={this.saveBackupToDrive}
          disabled={this.state.backupAmazonButtonDisabled}
        >
          Save Backup To Amazon
        </Button>
      </Page>
    );
  }
}

export default (props: any) => {
  return (
    <RequirePermission permission={permissions.Master.CRUD}>
      <Backup {...props} />
    </RequirePermission>
  );
};
