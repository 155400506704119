import DomainModel from './DomainModel'

class PaymentSection extends DomainModel {
  constructor(
    private id: string,
    private caseId: string,
    private claimNumber: string,
    private clientId: string,
    private clientName: string,
    private caseTypeId: string,
    private caseTypeTitle: string,
    private closeDate: string,

    private invoice: PaymentSection_Invoice | null,
    private payment: PaymentSection_Payment | null
  ) {
    super()
  }

  getId = () => this.id
  getCaseId = () => this.caseId
  getClaimNumber = () => this.claimNumber
  getClientId = () => this.clientId
  getClientName = () => this.clientName
  getCaseTypeId = () => this.caseTypeId
  getCaseTypeTitle = () => this.caseTypeTitle
  getCloseDate = () => this.closeDate

  getInvoice = () => this.invoice
  getPayment = () => this.payment
}

export class PaymentSection_Invoice {
  constructor(
    private invoiceNumber: string,
    private invoiceDate: string,
    private professionalFee: number,
    private documentExpense: number,
    private approvedExpense: number,
    private extraExpense: number,
    private incentive: number,
    private deduction: number,
    private gst: number,
    private invoiceUrl: string | null
  ) {}
  getInvoiceNumber = () => this.invoiceNumber
  getInvoiceDate = () => this.invoiceDate
  getProfessionalFee = () => this.professionalFee
  getDocumentExpense = () => this.documentExpense
  getApprovedExpense = () => this.approvedExpense
  getExtraExpense = () => this.extraExpense
  getIncentive = () => this.incentive
  getDeduction = () => this.deduction
  getGst = () => this.gst
  getInvoiceUrl = () => this.invoiceUrl
  getInvoiceAmount = () => {
    let total = this.professionalFee + this.incentive + this.documentExpense + this.approvedExpense + this.gst + this.extraExpense - this.deduction

    return total
  }
}

export class PaymentSection_Payment {
  constructor(
    private tds: number,
    private receivedAmount: number,
    private paymentDate: string,
    private utrNo: string,
    private settled: boolean,

    private status: string,
    private slip_url: string | null
  ) {}

  getTds = () => this.tds
  getReceivedAmount = () => this.receivedAmount
  getPaymentDate = () => this.paymentDate
  getUTRNo = () => this.utrNo
  getSettledStatus = () => this.settled
  getPaymentStatus = () => this.status
  getReceiptUrl = () => this.slip_url
}

export default PaymentSection
