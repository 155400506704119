import React from 'react'
import { DropdownItemProps } from 'semantic-ui-react'

interface ContextType {
    options: Options
    filters: Filters
}

interface Options {
    statusData: DropdownItemProps[]
}

interface Filters {
    status: string
    setStatus(status: string): void
    applyFilters(): void
    clearAllFilters(): void
}

const Context = React.createContext({} as ContextType)
export default Context
