import React, { useEffect, useState } from "react";
import Page from "../../layout/Page";
import AttendanceGrid, { AttendanceGridValue } from "./AttendanceGrid";
import UserRepository from "../../../common/repository/UserRepository";
import Result from "../../../common/repository/Result";

function AttendancePage() {
  const userRepo = new UserRepository();
  const [attendanceGridValues, setAttendanceGridValues] = useState<
    AttendanceGridValue[]
  >([]);

  useEffect(() => {
    loadUsers();
  }, []);

  return (
    <Page title="Attendance Page">
      <AttendanceGrid
        value={attendanceGridValues}
        onChange={setAttendanceGridValues}
      />
    </Page>
  );

  async function loadUsers() {
    const result = await userRepo.getUsers({ is_active: true });
    if (result instanceof Result.Success) {
      setAttendanceGridValues(
        result.data.items.map((it) => ({ user: it, attendanceState: "" }))
      );
    } else {
    }
  }
}

export default AttendancePage;
