export default {
  /** use in controlled component
   *  when user is entering text
   */
  isValid: (text: string) => {
    if (text === '') return true
    if (text.length < 10 && /^\d+$/.test(text)) return true
    if (text.length === 10 && /^\d{10}$/.test(text)) return true
    else return false
  },
}
