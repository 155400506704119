import ActionColumnRenderer from '../../../app/Table/renderer/ActionColumnRenderer'
import CancelledByInvestigatorList from '../../../../models/CancelledByInvestigatorList'
import moment from 'moment'

export default class CanceliedByInvestigatorColumnRenderer extends ActionColumnRenderer<CancelledByInvestigatorList> {
    getColumns() {
        return [
            {
                name: 'Investigator Name',
                selector: 'name',
            },
            {
                name: 'Claim Number',
                selector: 'claim_number',
            },
            {
                name: 'Reason',
                selector: 'reason',
                format: (row: {}) => {
                    if (row instanceof CancelledByInvestigatorList) {
                        const reason = row.getReason().slice(0, 35)
                        if (reason !== null) return reason
                    }
                },
            },
            {
                name: 'Created At',
                selector: 'createdAt',
                format: (row: {}) => {
                    if (row instanceof CancelledByInvestigatorList) {
                        const createdAt = row.getCreatedAt()
                        if (createdAt !== null) return moment(createdAt).format('LL')
                    }
                },
            },
            {
                name: 'Updated At',
                selector: 'updatedAt',
                format: (row: {}) => {
                    if (row instanceof CancelledByInvestigatorList) {
                        const updatedAt = row.getUpdatedAt()
                        if (updatedAt !== null) return moment(updatedAt).format('LL')
                    }
                },
            },

            // {
            //     name: 'Case State',
            //     selector: 'case_state',
            //     grow: 0,
            //     width: '180px',
            //     cell: this.renderActionColumn,
            // },
        ]
    }
}
