import React from 'react'
import styled from 'styled-components'
import { Header } from 'semantic-ui-react'
import { useLocation } from 'react-router-dom'

const Container = styled.div`
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const NotFound = () => {
  const location = useLocation()
  console.log('matched')
  return (
    <Container>
      <Header as="h1">
        Not Found
        <Header.Subheader>
          Sorry, <em>'{location.pathname}'</em> does not exist, or
          <br />
          you might not have the permission to view it.
        </Header.Subheader>
      </Header>
    </Container>
  )
}

export default NotFound
