import ActionColumnRenderer from '../../../app/Table/renderer/ActionColumnRenderer'
import Hospital from '../../../../models/Hospital'

export default class HospitalColumnRenderer extends ActionColumnRenderer<Hospital> {
  getColumns() {
    return [
      {
        name: 'Name',
        selector: 'name',
      },
      {
        name: 'State',
        selector: 'state',
      },
      {
        name: 'District',
        selector: 'district',
      },
      {
        name: 'Actions',
        selector: 'id',
        grow: 0,
        width: '180px',
        cell: this.renderActionColumn,
      },
    ]
  }
}
