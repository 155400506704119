import DomainModel from './DomainModel'

export default class RepudiationGround extends DomainModel {
    constructor(private repudiation_ground: string, private point: any, private _id: string) {
        super()
    }
    getId = () => this._id
    getRepudiationGround = () => this.repudiation_ground
    getpoint = () => this.point
}
