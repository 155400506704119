import React from 'react'
import ActionColumnRenderer from '../../../app/Table/renderer/ActionColumnRenderer'
import User from '../../../../models/User'
import { Image } from 'semantic-ui-react'
import constants from './../../../../common/constants'

export default class UserColumnRenderer extends ActionColumnRenderer<User> {
  getColumns() {
    return [
      {
        name: 'Profile',
        selector: 'id',
        width: '60px',
        grow: 0,
        cell: (row: {}) => {
          if (row instanceof User) {
            const src = row.getProfilePicURL() || constants.defaultProfileAvatarURL
            return <Image src={src} avatar />
          }
        },
      },
      {
        name: 'Employee ID',
        selector: 'employeeId',
      },
      {
        name: 'Name',
        selector: 'name',
      },
      {
        name: 'Email',
        selector: 'email',
        format: (row: {}) => {
          if (row instanceof User) {
            return row.getEmail()
          }
        },
      },
      {
        name: 'Phone',
        selector: 'phone',
        format: (row: {}) => {
          if (row instanceof User) {
            return row.getPhone()
          }
        },
      },
      {
        name: 'Role',
        selector: 'role.title',
      },
      {
        name: 'Actions',
        selector: 'name',
        cell: this.renderActionColumn,
      },
    ]
  }
}
