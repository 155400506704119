import React from 'react'
// import { DropdownItemProps } from 'semantic-ui-react'

interface ContextType {
    filters: Filters
}

// interface Options {
//     statusData: DropdownItemProps[]
// }

interface Filters {
    from: string
    setFrom(from: string): void
    to: string
    setTo(to: string): void
    applyFilters(): void
    clearAllFilters(): void
    downloadData?(): any
}

const Context = React.createContext({} as ContextType)
export default Context
