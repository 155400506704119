import ColumnRenderer from '../../../app/Table/renderer/ColumnRenderer'
import UserAttendance from '../../../../models/UserAttendance'

export default class AttendanceColumnRenderer extends ColumnRenderer {
  getColumns() {
    return [
      {
        name: 'Name',
        selector: 'id',
        format: (row: {}) => {
          if (row instanceof UserAttendance) return `${row.getUser().getName()} (${row.getUser().getRole().getTitle()})`
        },
      },
      {
        name: 'P',
        selector: 'summary.present',
        grow: 0,
        width: '50px',
        style: {
          backgroundColor: 'aliceblue',
        },
      },
      {
        name: 'A',
        selector: 'summary.absent',
        grow: 0,
        width: '50px',
        style: {
          backgroundColor: 'azure',
        },
      },
      {
        name: 'L',
        selector: 'summary.leave',
        grow: 0,
        width: '50px',
        style: {
          backgroundColor: 'aliceblue',
        },
      },
      {
        name: 'HL',
        selector: 'summary.halfLeave',
        grow: 0,
        width: '50px',
        style: {
          backgroundColor: 'azure',
        },
      },
      {
        name: 'SL',
        selector: 'summary.shortLeave',
        grow: 0,
        width: '50px',
        style: {
          backgroundColor: 'aliceblue',
        },
      },
      {
        name: 'C',
        selector: 'summary.compensation',
        grow: 0,
        width: '50px',
        style: {
          backgroundColor: 'azure',
        },
      },
      {
        name: 'H',
        selector: 'summary.holiday',
        grow: 0,
        width: '50px',
        style: {
          backgroundColor: 'aliceblue',
        },
      },
      {
        name: 'O',
        selector: 'summary.offroll',
        grow: 0,
        width: '50px',
        style: {
          backgroundColor: 'azure',
        },
      },
      ...this.generateDayColumns(),
    ]
  }

  generateDayColumns() {
    const cols = []
    for (let i = 0; i < 31; i++)
      cols.push({
        name: (i + 1).toString(),
        selector: `attendance[${i}]`,
        grow: 0,
        width: '50px',
        conditionalCellStyles: [
          {
            when: (row: {}) => row instanceof UserAttendance && row.getAttendance().length >= i && row.getAttendance()[i] === 'P',
            style: {
              backgroundColor: '#cee397',
            },
          },
          {
            when: (row: {}) => row instanceof UserAttendance && row.getAttendance().length >= i && row.getAttendance()[i] === 'A',
            style: {
              backgroundColor: '#ed6663',
            },
          },
          {
            when: (row: {}) => row instanceof UserAttendance && row.getAttendance().length >= i && row.getAttendance()[i] === 'L',
            style: {
              backgroundColor: '#88e1f2',
            },
          },
          {
            when: (row: {}) => row instanceof UserAttendance && row.getAttendance().length >= i && row.getAttendance()[i] === 'HL',
            style: {
              backgroundColor: '#88e1f2',
            },
          },
          {
            when: (row: {}) => row instanceof UserAttendance && row.getAttendance().length >= i && row.getAttendance()[i] === 'SL',
            style: {
              backgroundColor: '#88e1f2',
            },
          },
          {
            when: (row: {}) => row instanceof UserAttendance && row.getAttendance().length >= i && row.getAttendance()[i] === 'C',
            style: {
              backgroundColor: '#ffa41b',
            },
          },
          {
            when: (row: {}) => row instanceof UserAttendance && row.getAttendance().length >= i && row.getAttendance()[i] === 'O',
            style: {
              backgroundColor: '#ffd082',
            },
          },
        ],
      })
    return cols
  }
}
