class Result<T> {}

class Success<T> extends Result<T> {
  constructor(public data: T) {
    super()
  }
}

class Error extends Result<void> {
  constructor(public httpStatusCode: number, public message: string | null) {
    super()
  }
}

export default { Success, Error }
