import React, { useEffect, useState } from 'react'
import Layout from './Layout'
import { useParams, useHistory } from 'react-router-dom'
import UserFormPageContext from './UserFormPageContext'
import UserRepository from '../../../../common/repository/UserRepository'
import User from '../../../../models/User'
import Result from '../../../../common/repository/Result'
import { toast } from '../../../common/Toast'
import RequirePermission from '../../../base/RequirePermission'
import permissions from '../../../../common/permissions'
import SalaryRepository from '../../../../common/repository/SalaryRepository'
import Salary from '../../../../models/Salary'

function UserFormPage() {
  const params = useParams<{ userId: string; month: string; year: string }>()
  const history = useHistory()
  const userRepo = new UserRepository()
  const salaryRepo = new SalaryRepository()

  const [isLoading, setIsLoading] = useState(false)
  const [user, setUser] = useState<User | null>(null)
  const [salary, setSalary] = useState<Salary | null>(null)
  const [salaryMonth, setSalaryMonth] = useState(params.month)
  const [salaryYear, setSalaryYear] = useState(params.year)

  if (params.userId !== undefined && !isLoading && user === null) setIsLoading(true)

  useEffect(() => {
    effectLoadEssentialData()
  }, [isLoading])

  return (
    <UserFormPageContext.Provider
      value={{
        userRepo,
        isLoading,
        setIsLoading,
        user,
        setUser,
        salary,
        setSalary,
        salaryMonth,
        salaryYear,
      }}
    >
      <Layout />
    </UserFormPageContext.Provider>
  )

  async function effectLoadEssentialData() {
    if (!isLoading) return
    const promise1 = userRepo.getUsers({ id: params.userId! })
    const promise2 = salaryRepo.getSalary({ userId: params.userId!, month: params.month, year: params.year })
    const results = await Promise.all([promise1, promise2])

    const error = results.find((it) => it instanceof Result.Error)
    if (error && error instanceof Result.Error) {
      const message = error.message || 'Something went wrong'
      toast.error(message)
      history.push('/salary')
      return
    }

    if (results[0] instanceof Result.Success) {
      const user = results[0].data.items[0]
      setUser(user)
    }

    if (results[1] instanceof Result.Success) {
      const salary = results[1].data
      setSalary(salary)
    }
    setIsLoading(false)
  }
}

export default (props: any) => {
  return (
    <RequirePermission permission={permissions.Manage.CRUD}>
      <UserFormPage {...props} />
    </RequirePermission>
  )
}
