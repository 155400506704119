import React from "react";
import { Form, Segment } from "semantic-ui-react";
import Button from "../../../../elements/Button";
import InvoiceSection from "../../../../../models/InvoiceSection";
import Result from "../../../../../common/repository/Result";
import { toast } from "../../../../common/Toast";
import { RouteComponentProps } from "react-router-dom";
import ReportRepository from '../../../../../common/repository/ReportRepository'
import InsuredDetail from "../../../../../models/ReportModels/InsuredDetail"
import config from "../../../../../common/repository/config"
import moment from "moment";

interface Props {
    //if model is passed that means update
    model?: InsuredDetail;
    onSuccess: (invoiceSection: InvoiceSection) => void;
    onError?: () => void;
}

interface MatchProps {
    Name: string;
    dob: string;
}
interface Props extends RouteComponentProps<MatchProps> { }

interface State {
    form_loading: boolean;
    insuredDetails: InsuredDetail[]
    commonData: {}
    dataWithDate: any[]
    feedback: string
    feedBackID: string
}

class AttendantDetailsForm extends React.Component<Props, State> {
    private ReportRepository = new ReportRepository()

    constructor(props: Props) {
        super(props);
        const stateFromModel = this.deriveStateFromModel();
        this.state = {
            form_loading: false,
            insuredDetails: [],
            feedback: "",
            feedBackID: "",
            ...stateFromModel,
        };
    }
    componentDidMount() {
        this.getAttendentDetail()
    }
    private deriveStateFromModel = () => {
        const model = this.props.model;
        return {
            commonData: (model && model.getCommonData()) || {},
            dataWithDate: (model && model.getDataWithDate()) || [],
        };
    };

    private updateFeedBack = async () => {
        const InveId = this.props.match.params;
        const result = await this.ReportRepository.updateAttendentFeedBack({
            type: "attendent",
            feedback: this.state.feedback,
            feedback_id: this.state.feedBackID,
            attendent_name: InveId.Name,
            attendent_dob: moment(InveId.dob).format("YYYY/MM/DD")
        });
        if (result instanceof Result.Success) {
            const state = result.data;
            toast.success("state Updated Successfully!");
        } else {
            const message = result.message || "Something went wrong";
            this.setState({ form_loading: false }, () => {
                toast.error(message);
                if (this.props.onError) this.props.onError();
            });
        }
    };

    private getAttendentDetail = () => {
        const InveId = this.props.match.params;
        this.setState({ form_loading: false }, async () => {
            const result = await this.ReportRepository.getAttendentDetail({ name: InveId.Name, dob: moment(InveId.dob).format("YYYY/MM/DD") });
            if (result instanceof Result.Success) {
                const insuredDetail = result.data.items;
                console.log(insuredDetail)
                this.setState({
                    form_loading: false,
                    insuredDetails: insuredDetail,
                    feedback: insuredDetail[0].getFeedBack(),
                    feedBackID: insuredDetail[0].getFeedBackId()
                });
            } else {
                const message = result.message || "Something went wrong";
                this.setState({ form_loading: false }, () =>
                    toast.error(message)
                );
            }
        });
    };

    private onSubmit = () => {
        this.setState({ form_loading: true }, () => {
            // this.updateInvoiceSection();
        });
    };
    render(): JSX.Element {
        let noAvailable = "not available"
        return (
            <Segment >
                <h1>Attendent Detail</h1>
                <Form loading={this.state.form_loading}>
                    <Form.Group>
                        <Form.Input
                            required
                            readOnly
                            type="input"
                            width="4"
                            label="Insured Name"
                            value={this.state.insuredDetails[0]?.getCommonData().name || noAvailable}
                        />
                        <Form.Input
                            required
                            readOnly
                            type="input"
                            width="4"
                            label="Phone Number"
                            value={this.state.insuredDetails[0]?.getCommonData().contactNumber || noAvailable}
                        />
                        <Form.Input
                            readOnly
                            type="input"
                            width="4"
                            label="Alternative Phone (Optional)"
                            value={this.state.insuredDetails[0]?.getCommonData().alternativePhone || noAvailable}
                        />
                        <Form.Input
                            readOnly
                            type="input"
                            width="4"
                            label="Email Address (Optional)"
                            value={this.state.insuredDetails[0]?.getCommonData().email || noAvailable}
                        />
                    </Form.Group>

                    <Form.Group>
                        <Form.Input
                            readOnly
                            type="input"
                            width="4"
                            label="Number of Claims as Insured"
                            value={this.state.insuredDetails[0]?.getClaimsAsInsured()}
                        />
                        <Form.Input
                            readOnly
                            type="input"
                            width="4"
                            label="Number of Claims as Attendant"
                            value={this.state.insuredDetails[0]?.getClaimsAsAttendent()}
                        />
                        <Form.Input
                            readOnly
                            type="input"
                            width="4"
                            label="# Claims Repudiated as Insured"
                            value={this.state.insuredDetails[0]?.getRepudatedInsured()}
                        />
                        <Form.Input
                            readOnly
                            type="input"
                            width="4"
                            label="# Claims Repudiated as Attendant"
                            value={this.state.insuredDetails[0]?.getRepudatedAttendent()}
                        />
                    </Form.Group>
                    {this.state.insuredDetails[0]?.getDataWithDate().map((item) =>
                        <Segment>
                            <h3>Date:-<span> {moment(item.updatedAt).format("LL")}</span></h3>
                            <Form.Group>
                                <Form.Input
                                    required
                                    type="input"
                                    width="4"
                                    label="Attendent Name"
                                    value={item.attendent_name || noAvailable}
                                />

                                <Form.Input
                                    required
                                    type="input"
                                    width="4"
                                    label="Claim Number"
                                    value={item.claim_number || noAvailable}
                                />
                                <Form.Input
                                    required
                                    type="input"
                                    width="4"
                                    label="Hospital Name"
                                    value={item.hospital_name || noAvailable}
                                />
                                <Form.Input
                                    required
                                    type="input"
                                    width="4"
                                    label="Investigator Name"
                                    value={item.investigator_name || noAvailable}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Input
                                    required
                                    type="input"
                                    width="4"
                                    label="Occupation"
                                    value={item.occupation || noAvailable}
                                />
                                <Form.Input
                                    required
                                    type="input"
                                    width="4"
                                    label="Occupation Address"
                                    value={item.occupationAddress || noAvailable}
                                />
                                {item.occupationId ?
                                    <Form.Input
                                        required
                                        type="input"
                                        width="4"
                                        label="occupation Image"
                                    >
                                        {<a className={"ui green  button"}
                                            href={`${config.mrdRecipt}${item.occupationId}`}
                                            target={"_blank"}
                                        >
                                            occupation Image
                                        </a> || "not available "}
                                    </Form.Input> :
                                    <Form.Input
                                        required
                                        type="input"
                                        width="4"
                                        label="occupation Image"
                                        value={noAvailable}
                                    />
                                }
                            </Form.Group>
                            <h3>Documents</h3>
                            <Form.Group>
                                {item.supportingDocuments.map((dock: any) =>
                                    <>
                                        {console.log(item, "item")}
                                        <Form.Input
                                            required
                                            type="input"
                                            width="4"
                                            label="Document Type"
                                            value={dock.type}
                                        />
                                        <Form.Input
                                            required
                                            type="input"
                                            width="4"
                                            label="Document Number"
                                            value={dock.number}
                                        />

                                        {dock.document ? <Form.Input
                                            required
                                            type="input"
                                            width="4"
                                            label="Document Image"
                                        >
                                            {<a className={"ui green  button"}
                                                href={`${config.mrdRecipt}${dock.document}`}
                                                target={"_blank"}
                                            >
                                                Document
                                            </a> || "not available "}
                                        </Form.Input> :
                                            <Form.Input
                                                required
                                                type="input"
                                                width="4"
                                                label="Document Image"
                                                value={noAvailable}
                                            />}
                                    </>
                                )}
                            </Form.Group>
                        </Segment>
                    )}
                    <h2>Feedback</h2>
                    <Form.Group>
                        <Form.Input
                            type="input"
                            width="16"
                            label="Feedback"
                            value={this.state.feedback}
                            onChange={(_, { value }) => this.setState({ feedback: value })}
                        />
                    </Form.Group>
                    <Button
                        positive
                        onClick={this.updateFeedBack}
                        disabled={this.state.feedback.length === 0}
                    >
                        Submit
                    </Button>
                </Form>
            </Segment>
        );
    }
}

export default AttendantDetailsForm;
