import React from "react";
import { Form, Input, Radio } from "semantic-ui-react";
import Button from "../../../elements/Button";
import InvoiceSection from "../../../../models/InvoiceSection";
import Result from "../../../../common/repository/Result";
import { toast } from "../../../common/Toast";
import InvoiceSectionRepository from "../../../../common/repository/InvoiceSectionRepository";
import FormLabel from "../../../common/FormLabel";
import DateTimePicker from "../../../app/DateTimePicker";

interface Props {
  //if model is passed that means update
  model?: InvoiceSection;

  onSuccess: (invoiceSection: InvoiceSection) => void;
  onError?: () => void;
}
interface Invoice {
  invoice_number: string;
  invoice_date: string;
  professional_fee: number;
  approved_expense: number;
  document_expense: number;
  extra_expense: number;
  incentive: number;
  deduction: number;
  gst: number;
  invoice_url: string | null;
}

interface State {
  form_loading: boolean;

  claim_number: string;
  case_type_title: string;
  invoice: Invoice | null;
  invoiceReceipt: File | null;
}

class InvoiceUpdateForm extends React.Component<Props, State> {
  private invoiceSectionRepo = new InvoiceSectionRepository();

  constructor(props: Props) {
    super(props);

    const stateFromModel = this.deriveInvoiceSectionStateFromModel();
    this.state = {
      form_loading: false,
      invoiceReceipt: null,
      ...stateFromModel,
    };
  }

  private deriveInvoiceSectionStateFromModel = () => {
    const model = this.props.model;
    let invoice: {
      invoice_number: string;
      invoice_date: string;
      professional_fee: number;
      approved_expense: number;
      document_expense: number;
      extra_expense: number;
      incentive: number;
      deduction: number;
      gst: number;
      invoice_url: string | null;
    } = {
      invoice_number: "",
      invoice_date: "",
      professional_fee: 0,
      approved_expense: model!.getOldApprovedCharges(),
      document_expense: model!.getOldDocCharges(),
      extra_expense: model!.getOldExtraCharges(),
      incentive: 0,
      deduction: 0,
      gst: 0,
      invoice_url: null,
    };
    if (model?.getInvoice() != null) {
      invoice = {
        invoice_number: model.getInvoice()!.getInvoiceNumber(),
        invoice_date: model.getInvoice()!.getInvoiceDate(),
        professional_fee: model.getInvoice()!.getProfessionalFee(),
        approved_expense: model.getInvoice()!.getApprovedExpense(),
        document_expense: model.getInvoice()!.getDocumentExpense(),
        extra_expense: model.getInvoice()!.getExtraExpense(),
        incentive: model.getInvoice()!.getIncentive(),
        deduction: model.getInvoice()!.getDeduction(),
        gst: model.getInvoice()!.getGst(),
        invoice_url: model.getInvoice()!.getInvoiceUrl(),
      };
    }

    return {
      claim_number: (model && model.getClaimNumber()) || "",
      case_type_title: (model && model.getCaseTypeTitle()) || "",
      invoice: invoice,
    };
  };

  private onSubmit = () => {
    this.setState({ form_loading: true }, () => {
      this.updateInvoiceSection();
    });
  };

  private updateInvoiceSection = async () => {
    const result = await this.invoiceSectionRepo.updateData({
      claim_number: this.state.claim_number,
      invoice_receipt:
        this.state.invoiceReceipt != null
          ? this.state.invoiceReceipt
          : undefined,
      invoice: {
        invoice_number: this.state.invoice!.invoice_number,
        invoice_date: this.state.invoice!.invoice_date,
        professional_fee: this.state.invoice!.professional_fee,
        approved_expense: this.state.invoice!.approved_expense,
        document_expense: this.state.invoice!.document_expense,
        other_expense: this.state.invoice!.extra_expense,
        incentive: this.state.invoice!.incentive,
        deduction: this.state.invoice!.deduction,
        gst: this.state.invoice!.gst,
      },
    });

    if (result instanceof Result.Success) {
      const receivedData = result.data;
      this.setState({ form_loading: false }, () => {
        toast.success("Information updated successfully");
        this.props.onSuccess(receivedData);
      });
    } else {
      this.setState({ form_loading: false }, () => {
        const message = result.message || "Something went wrong";
        toast.error(message);
        if (this.props.onError) this.props.onError();
      });
    }
  };

  render(): JSX.Element {
    let invoiceReceipt = null;
    let invoiceAmount = 0;
    if (this.state.invoice) {
      invoiceAmount = Math.round(
        this.state.invoice.professional_fee +
          this.state.invoice.incentive +
          this.state.invoice.document_expense +
          this.state.invoice.approved_expense +
          this.state.invoice.gst +
          this.state.invoice.extra_expense +
          this.state.invoice.deduction
      );
    }

    if (this.state.invoice?.invoice_url != null) {
      if (typeof this.state.invoice.invoice_url == "string")
        invoiceReceipt = (
          <a
            className="ui primary button"
            href={this.state.invoice!.invoice_url}
            target="_blank"
          >
            {" "}
            View Receipt{" "}
          </a>
        );
    }

    return (
      <Form loading={this.state.form_loading}>
        {/* claim number and case Type */}
        <Form.Group>
          <Form.Input
            width="8"
            label="Claim Number"
            value={this.state.claim_number}
          />
          <Form.Input
            width="8"
            label="Case Type"
            value={this.state.case_type_title}
          />
        </Form.Group>
        {/* claim number and case Type */}

        <Form.Group>
          <Form.Input
            required
            type="number"
            width="8"
            label="Document Charges"
            value={this.state.invoice?.document_expense}
            onChange={(_, { value }) =>
              this.setState((prevState) => {
                let invoice = Object.assign({}, prevState.invoice);
                invoice.document_expense = Number(value);
                return { invoice };
              })
            }
          />
          <Form.Input
            required
            type="number"
            width="8"
            label="Approved Expense (Invoice)"
            value={this.state.invoice?.approved_expense}
            onChange={(_, { value }) =>
              this.setState((prevState) => {
                let invoice = Object.assign({}, prevState.invoice);
                invoice.approved_expense = Number(value);
                return { invoice };
              })
            }
          />
        </Form.Group>
        <Form.Group>
          <Form.Input
            required
            type="number"
            width="8"
            label="Extra Expense (Investigator)"
            value={this.state.invoice?.extra_expense}
            onChange={(_, { value }) =>
              this.setState((prevState) => {
                let invoice = Object.assign({}, prevState.invoice);
                invoice.extra_expense = Number(value);
                return { invoice };
              })
            }
          />
          <Form.Input
            required
            type="number"
            width="8"
            label="Professional Fee"
            value={this.state.invoice?.professional_fee}
            onChange={(_, { value }) =>
              this.setState((prevState) => {
                let invoice = Object.assign({}, prevState.invoice);
                invoice.professional_fee = Number(value);
                return { invoice };
              })
            }
          />
        </Form.Group>
        <Form.Group>
          <Form.Input
            required
            type="number"
            width="8"
            label="Incentive"
            value={this.state.invoice?.incentive}
            onChange={(_, { value }) =>
              this.setState((prevState) => {
                let invoice = Object.assign({}, prevState.invoice);
                invoice.incentive = Number(value);
                return { invoice };
              })
            }
          />
          <Form.Input
            required
            type="number"
            width="8"
            label="Deduction"
            value={this.state.invoice?.deduction}
            onChange={(_, { value }) =>
              this.setState((prevState) => {
                let invoice = Object.assign({}, prevState.invoice);
                invoice.deduction = Number(value);
                return { invoice };
              })
            }
          />
        </Form.Group>
        <Form.Group>
          <Form.Input
            required
            type="number"
            width="8"
            label="GST Amount"
            value={this.state.invoice?.gst}
            onChange={(_, { value }) =>
              this.setState((prevState) => {
                let invoice = Object.assign({}, prevState.invoice);
                invoice.gst = Number(value);
                return { invoice };
              })
            }
          />

          <Form.Input
            disabled={true}
            width="8"
            label="Invoice Amount"
            value={invoiceAmount}
          />
        </Form.Group>
        <Form.Group>
          <Form.Input
            required
            width="8"
            label="Invoice Number"
            value={this.state.invoice?.invoice_number}
            onChange={(_, { value }) =>
              this.setState((prevState) => {
                let invoice = Object.assign({}, prevState.invoice);
                invoice.invoice_number = value;
                return { invoice };
              })
            }
          />
          <Form.Field required>
            <FormLabel width={"100%"}>Invoice Date</FormLabel>
            <Input>
              <DateTimePicker
                type="date"
                placeholder="Pick Invoice Date"
                value={this.state.invoice?.invoice_date}
                onChange={(value) =>
                  this.setState((prevState) => {
                    let invoice = Object.assign({}, prevState.invoice);
                    invoice.invoice_date = value;
                    return { invoice };
                  })
                }
              />
            </Input>
          </Form.Field>
        </Form.Group>
        <Form.Group>
          {invoiceReceipt}
          <Form.Field>
            <FormLabel width="100px">Invoice Receipt</FormLabel>
            <Input
              type="file"
              accept="image/*, .pdf, .doc, .docx, .csv, .xls, .xlsx"
              onChange={(e) => {
                if (e.target.files) {
                  const files = e.target.files;
                  let fileArr: File[] = [];
                  for (let i = 0; i < files.length; i++) fileArr.push(files[i]);
                  this.setState({ invoiceReceipt: fileArr[0] });
                }
              }}
            />
          </Form.Field>
        </Form.Group>

        <Button
          positive
          onClick={this.onSubmit}
          disabled={
            this.state.invoice?.invoice_number.trim().length === 0 ||
            this.state.invoice!.professional_fee < 0 ||
            this.state.invoice!.document_expense < 0 ||
            this.state.invoice!.approved_expense < 0 ||
            this.state.invoice!.extra_expense < 0 ||
            this.state.invoice!.incentive < 0 ||
            this.state.invoice!.deduction < 0 ||
            this.state.invoice!.gst < 0
          }
        >
          Submit
        </Button>
      </Form>
    );
  }
}

export default InvoiceUpdateForm;
