import DomainModel from './DomainModel'

class Medic extends DomainModel {
  constructor(
    private id: string,
    private name: string,
    private qualification: string,
    private registrationNumber: string,
    private councilName: string,
    private labName: string | null,
    private verified: boolean,
    private role: string
  ) {
    super()
  }

  getId = () => this.id
  getName = () => this.name
  getQualification = () => this.qualification
  getRegistrationNumber = () => this.registrationNumber
  getCouncilName = () => this.councilName
  getLabName = () => this.labName
  getVerified = () => this.verified
  getRole = () => this.role
}

export default Medic
