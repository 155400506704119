import React from 'react'
import Page from '../../../../layout/Page'
import { Button, DropdownItemProps } from 'semantic-ui-react'
import Result from '../../../../../common/repository/Result'
import { toast } from '../../../../common/Toast'
import Table from '../../../../app/Table'
import RequirePermission from '../../../../base/RequirePermission'
import permissions from '../../../../../common/permissions'
// import Filters from './Filters'
import FilterPageContext from '../../InvestigatorActivity/FilterPageContext'
import { Link } from 'react-router-dom'
import ReportRepository, { GetinsuredInvestigatorRequestObject } from '../../../../../common/repository/ReportRepository'
import { RouteComponentProps } from "react-router-dom";
import InsuredInvestigatorsColumnRenderer from './InsuredInvestigatorsColumnRenderer'
import InsuredInvestigator from '../../../../../models/ReportModels/InsuredInvestigator'

const statusData = ["Pending", "Accepted", "Rejected"]
const statusDatas = statusData.map((it) => {
    return { text: it, value: it, key: it };
});

interface State {
    loading: boolean
    filter: string
    limit: number
    offset: number
    totalCount: number
    insuredInvesti: InsuredInvestigator[]
    loadingEssentialData: boolean
    statusFilter: string
    statusOptions: DropdownItemProps[]
}

interface MatchProps {
    Id: string;
}
interface Props extends RouteComponentProps<MatchProps> { }

class InsuredInvestigators extends React.Component<Props, State> {

    private ReportRepository = new ReportRepository()

    private columnRenderer = new InsuredInvestigatorsColumnRenderer(
        (model: InsuredInvestigator) => {
            let id = model.getId()
            const insuredId = this.props.match.params;
            return (<>
                <Button
                    style={{ width: 150 }}
                    primary
                    as={Link}
                    to={{
                        pathname: `/master/reports/insured-investigator/${insuredId}/insured-details/${id}`,
                    }}
                >
                    View
                </Button>
            </>)
        },
        (obj: any) => true
    )

    constructor(props: any) {
        super(props)

        this.state = {
            loading: false,
            limit: 10,
            offset: 0,
            filter: '',
            totalCount: 0,
            insuredInvesti: [],
            loadingEssentialData: true,
            statusFilter: '',
            statusOptions: statusDatas,
        }

    }

    async componentDidMount() {
        this.loadData()
    }
    private onRowsPerPageChange = (page: number, rowsPerPage: number) => {
        const offset = (page - 1) * rowsPerPage,
            limit = offset + rowsPerPage
        this.setState({ offset, limit }, this.loadData)
    }

    private setFilter = (filter: string) => {
        this.setState({ filter, offset: 0 }, this.loadData)
    }

    private clearAllFilters = async () => {
        await this.setState({
            limit: 0,
            offset: 0,
            statusFilter: '',
        })
        await this.loadData()
    }

    private getInsuredInvestigatorList = async () => {
        const mobile = this.props.match.params;
        const ro: GetinsuredInvestigatorRequestObject = {
            limit: this.state.limit,
            offset: this.state.offset,
            search: this.state.filter,
            mobile : mobile.Id
        }

        // if (this.state.statusFilter) ro.accepted = this.state.statusFilter

        return await this.ReportRepository.getInsuredInvestigatorList(ro)
    }

    private loadData = async () => {
        if (this.state.statusFilter) {
            await this.setState({ offset: 0 })
        }
        let offset = this.state.offset

        this.setState({ loading: true }, async () => {
            const result = await this.getInsuredInvestigatorList()

            console.log(result, "result")

            if (result instanceof Result.Success) {
                if (offset === 0) {
                    //if offset 0 initialize as new list
                    const insuredInvesti = result.data.items
                    const totalCount = result.data.totalItemCount
                    await this.setState({
                        loading: false,
                        // offset: insuredInvesti.length,
                        insuredInvesti,
                        totalCount: totalCount,
                    })
                } else {
                    //else check for duplicate items in the new data
                    // and then concat with the old
                    const insuredInvesti = [...this.state.insuredInvesti, ...result.data.items]
                    const offset = insuredInvesti.length
                    this.setState({ loading: false, offset, insuredInvesti })
                }
            } else {
                const message = result.message || 'Something went wrong'
                await this.setState({ loading: false }, () => toast.error(message))
            }
        })
    }


    private setStatsFilter = (val: string) => this.setState({ statusFilter: val })

    render() {
        return (
          
                <Page title="Insured Investigator" description="Manage Investigator ">

                    {/* <Segment>
                        <Filters />
                    </Segment> */}

                    <Table
                        renderer={this.columnRenderer}
                        data={this.state.insuredInvesti}
                        totalDataCount={this.state.totalCount}
                        loading={this.state.loading}
                        load={this.loadData}
                        onRowsPerPageChange={this.onRowsPerPageChange}
                        onSearch={this.setFilter}
                        onClear={() => this.setFilter('')}
                    />
                </Page>
        )
    }
}

export default (props: any) => {
    return (
        <RequirePermission permission={permissions.Master.CRUD}>
            <InsuredInvestigators {...props} />
        </RequirePermission>
    )
}
