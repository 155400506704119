import DomainModel from './DomainModel'

class CaseInvestigatorHistory extends DomainModel {
    constructor(
        private state: string,
        private name: string,
        private date: string,
        private startDate: string,
        private endDate: string,
        private remark: any,
        private document: string
    ) {
        super()
    }

    getCaseInvestigatorHistoryState = () => this.state
    getCaseInvestigatorHistoryName = () => this.name
    getCaseInvestigatorHistoryDate = () => this.date
    getCaseInvestigatorHistoryStartDate = () => this.startDate
    getCaseInvestigatorHistoryEndDate = () => this.endDate
    getCaseInvestigatorHistoryRemark = () => this.remark
    getCaseInvestigatorHistoryDocument = () => this.document
}

export default CaseInvestigatorHistory