import React from "react";
import { Form } from "semantic-ui-react";
import Button from "../../../elements/Button";
import Result from "../../../../common/repository/Result";
import { toast } from "../../../common/Toast";
import LodgingExpenses from '../../../../models/LodgingExpenses'
import LodgingExpensesRepository from '../../../../common/repository/LodgingExpensesRepository'

interface State {
    form_loading: boolean;
    state_Id: string
}
interface Props {
    model?: LodgingExpenses;
    lodgingExpenses?: LodgingExpenses[];
    onSuccess(lodgingExpenses: LodgingExpenses): void;
    onError?(): void;
    status?: string
}

class LodgingExpensesForm extends React.Component<Props, State> {

    private LodgingExpensesRepository = new LodgingExpensesRepository();

    constructor(props: Props) {
        super(props);
        let loadStates = true;
        if (props.lodgingExpenses) loadStates = false;

        const stateFromModel = this.deriveLodgingExpensesFromModel();
        this.state = {
            form_loading: false,
            ...stateFromModel,
        };

    }

    private deriveLodgingExpensesFromModel = () => {
        const model = this.props.model;
        return {
            state_Id: (model && model.getId()) || "",
        };
    };

    private onSubmit = (status: any) => {
        this.setState({ form_loading: true }, () => {
            if (this.props.model) this.updateState(status);
        });
    };

    private updateState = async (status: any) => {
        const stateId = this.props.model!.getId();
        const result = await this.LodgingExpensesRepository.UpdateLodgingExpenses({
            id: stateId,
            status: status,
        });

        if (result instanceof Result.Success) {
            const lodgingExpenses = result.data;
            toast.success(`Lodging Expenses ${status} Successfully!`);
            this.props.onSuccess(lodgingExpenses);
        } else {
            const message = result.message || "Something went wrong";
            this.setState({ form_loading: false }, () => {
                toast.error(message);
                if (this.props.onError) this.props.onError();
            });
        }
    };

    render() {

        return (
            <Form loading={this.state.form_loading}>
                <>
                    {this.props.status == "Approvel" ? <p>Are you sure you want to Approve this ?</p> : <p>Are you sure you want to Rejected this?</p>}
                    {this.props.model?.getStatus() === "Pending" && this.props.status == "Approvel" ? < Button color="green" inverted
                        onClick={() => this.onSubmit("Accepted")}
                    >
                        Approved
                    </Button> :
                        < Button color="red" inverted
                            onClick={() => this.onSubmit("Rejected")}>
                            Decline
                        </Button>}
                    {/* <Button style={{ marginLeft: 4 }}>
                        Cancel
                    </Button> */}
                </>
            </Form>
        );
    }
}

export default LodgingExpensesForm;
