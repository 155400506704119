import React from "react";
import { Form } from "semantic-ui-react";
import Button from "../../../elements/Button";
import Result from "../../../../common/repository/Result";
import { toast } from "../../../common/Toast";
import MrdCharges from "../../../../models/MrdCharges"

import MrdRepository from "../../../../common/repository/MrdRepository"

interface State {
    form_loading: boolean;
    state_Id: string
}
interface Props {
    model?: MrdCharges;
    mrdCharges?: MrdCharges[];
    onSuccess(mrdCharges: MrdCharges): void;
    onError?(): void;
    status?: string
}

class MrdApproveForm extends React.Component<Props, State> {

    private MrdRepository = new MrdRepository();

    constructor(props: Props) {
        super(props);
        let loadStates = true;
        if (props.mrdCharges) loadStates = false;

        const stateFromModel = this.deriveMrdChargesFromModel();
        this.state = {
            form_loading: false,
            ...stateFromModel,
        };

    }

    private deriveMrdChargesFromModel = () => {
        const model = this.props.model;
        return {
            state_Id: (model && model.getId()) || "",

        };
    };

    private onSubmit = (status: any) => {
        this.setState({ form_loading: true }, () => {
            if (this.props.model) this.updateState(status);
        });
    };

    private updateState = async (status: any) => {
        const stateId = this.props.model!.getId();
        const result = await this.MrdRepository.UpdateMrdCharge({
            id: stateId,
            accepted: status,
        });
        if (result instanceof Result.Success) {
            const MrdCharges = result.data;
            toast.success(`Mrd Charges ${status} Successfully!`);
            this.props.onSuccess(MrdCharges);
        } else {
            const message = result.message || "Something went wrong";
            this.setState({ form_loading: false }, () => {
                toast.error(message);
                if (this.props.onError) this.props.onError();
            });
        }
    };

    render() {

        return (
            <Form loading={this.state.form_loading}>
                <>
                    {this.props.status == "Approvel" ? <p>Are you sure you want to Approve this ?</p> : <p>Are you sure you want to Rejected this?</p>}
                    {this.props.model?.getAccepted() === "Pending" && this.props.status == "Approvel" ? < Button color="green" inverted
                        onClick={() => this.onSubmit("Accepted")}
                    >
                        Approved
                    </Button> :
                        < Button color="red" inverted
                            onClick={() => this.onSubmit("Rejected")}>
                            Decline
                        </Button>}
                </>
            </Form>
        );
    }
}

export default MrdApproveForm;
