import ActionColumnRenderer from '../../../app/Table/renderer/ActionColumnRenderer'
import AddressChangerequest from "../../../../models/AddressChangeRequest"
import moment from 'moment'

export default class AddressChangeRequesColumnRenderer extends ActionColumnRenderer<AddressChangerequest> {
    getColumns() {
        return [
            {
                name: 'Investigator Name',
                selector: 'name',
            },
            {
                name: 'Previous Address',
                selector: 'address',
            },

            {
                name: "Address Change Date",
                selector: "addressChangeReqTime",
                format: (row: {}) => {
                    if (row instanceof AddressChangerequest) {
                        const createdAt = row.getAddressChangeReqTime()
                        if (createdAt !== null) return moment(createdAt).format('LL')
                    }
                },
            },
            {
                name: 'New Address',
                selector: 'tempNewAddress',
            },
            {
                name: 'Actions',
                selector: '_id',
                grow: 0,
                width: '180px',
                cell: this.renderActionColumn,
            },
        ]
    }
}
