import React from "react";
import { RouteComponentProps } from "react-router-dom";
import Result from "../../../../common/repository/Result";
import RequirePermission from "../../../base/RequirePermission";
import permissions from "../../../../common/permissions";
import { Segment, Table, Header, Accordion, Icon, Tab } from "semantic-ui-react";
import InvestigatoeActivityRepository, { GetInvestigatoeActivityDetailObject } from "../../../../common/repository/InvestigatorActivityRepository"
import InvestigatorActivityDetails from "../../../../models/InvestigatorActivityDetail"
import moment from "moment";
import config from '../../../../common/repository/config'
import FilterPageContext from './FilterPageContext'
import Filters from './Filters'
import axios from "axios";
import { toast } from "../../../common/Toast";
import LocalStorage from "../../../../lib/LocalStorage";
interface MatchProps {
  Id: string;
}
interface Props extends RouteComponentProps<MatchProps> { }
interface State {
  loading: boolean;
  model: InvestigatorActivityDetails | null;
  investigatorDetail: InvestigatorActivityDetails[]
  activeIndex: number
  fromFilter: string
  toFilter: string

}
class InvestigatorActivityDetail extends React.Component<Props, State> {
  private InvestigatoeActivityRepository = new InvestigatoeActivityRepository()

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: true,
      model: null,
      investigatorDetail: [],
      activeIndex: 0,
      fromFilter: "",
      toFilter: ""
    };
    // console.log(this.props.match.params, "this.props.match.params.caseId;")
  }

  componentDidMount() {
    // this.getCase();
    this.getInvestigatoeActivityDetail()
  }


  private getInvestigatoeActivityDetail = () => {

    // if ( this.state.fromFilter || this.state.toFilter ) {
    //   await this.setState({ offset: 0 })
    // }

    const caseId = this.props.match.params;
    this.setState({ loading: true }, async () => {
      const ro: GetInvestigatoeActivityDetailObject = { investigator_id: caseId.Id, startDate: this.state.fromFilter, endDate: this.state.toFilter };
      const result = await this.InvestigatoeActivityRepository.getInvestigatoeActivityDetail(ro);
      if (result instanceof Result.Success) {
        const investigatorDetails = result.data.items.map(it => it);
        // this.state.investigatorDetail.push(investigatorDetails)
        this.setState({ loading: false, investigatorDetail: investigatorDetails });
      } else {
        this.setState({ loading: false });
      }
    });
  };

  private handleClick = (e: any, titleProps: any) => {
    const { index } = titleProps
    console.log(titleProps, "titleProps")
    const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index

    this.setState({ activeIndex: newIndex })
  }

  private clearAllFilters = async () => {
    await this.setState({
      // limit: 0,
      // offset: 0,
      // statusFilter: '',
      // clientFilter: '',
      // applierFilter: '',
      fromFilter: '',
      toFilter: '',
    })
    await this.getInvestigatoeActivityDetail()
  }


  private downloadData = async () => {
    const caseId = this.props.match.params;
    // let rs = createCountRequestObject();
    let ro: GetInvestigatoeActivityDetailObject = { investigator_id: caseId.Id, startDate: this.state.fromFilter, endDate: this.state.toFilter,action:"investigatorActivity", };

    if (!ro) ro = {};
    const token = LocalStorage.get(LocalStorage.KEY_AUTH_TOKEN);
    try {
      if (Object.entries(ro).length > 0) {
        toast.success("Download will start shortly!");
        let reqData = axios({
          url: config.apiBaseURL + `/download`,
          method: "POST",
          responseType: "blob",
          data: ro,
          headers: { ["x-access-token"]: token },
        });
        reqData
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "investigatorActivity.xlsx");
            document.body.appendChild(link);
            link.click();
            link.remove();
          })
          .catch((e) => {
            toast.error("Something went wrong while downloading data!");
          });
      } else {
        toast.error("download feature not available yet!.");
      }
    } catch (e) {
      toast.error("Something went wrong while downloading");
    }
  };

  private setFromFilter = (val: string) => this.setState({ fromFilter: val })
  private setToFilter = (val: string) => this.setState({ toFilter: val })


  render() {
    const { activeIndex } = this.state
    return (

      <FilterPageContext.Provider
        value={{
          filters: {
            from: this.state.fromFilter,
            setFrom: this.setFromFilter,
            to: this.state.toFilter,
            setTo: this.setToFilter,
            applyFilters: this.getInvestigatoeActivityDetail,
            clearAllFilters: this.clearAllFilters,
            downloadData: this.downloadData
          },
        }}
      >
        <Segment loading={this.state.loading}>
          <Segment>
            <Header as="h1">
              Investigator Detail
            </Header>
            <Filters />
            <h3>Investigator Name : <span>{this.state.investigatorDetail[0]?.getInvestigatorName()} </span></h3>
          </Segment>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={3}>Date</Table.HeaderCell>
                <Table.HeaderCell width={3}>Punch In Time</Table.HeaderCell>
                <Table.HeaderCell width={3}>Punch Out Time</Table.HeaderCell>
                <Table.HeaderCell width={3} textAlign='right'>Distance</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
          </Table>
          {this.state.investigatorDetail.map((it, index) =>
            <Accordion fluid styled >
              <Accordion.Title
                active={activeIndex === index}
                index={index}
                onClick={this.handleClick}
              >
                <Table>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell width={3}>{moment(it.getCheckDate()).format("LL")}</Table.Cell>
                      <Table.Cell width={3}>{it?.getPunchInTime() ? moment(it?.getPunchInTime()).format("LT") : "--"}</Table.Cell>
                      <Table.Cell width={3}>{it?.getPunchOutTime() ? moment(it?.getPunchOutTime()).format("LT") : "--"}</Table.Cell>
                      <Table.Cell width={3} textAlign='right'>{`${it?.getTotalDistance()} KM`}<Icon name='dropdown' size='huge' /></Table.Cell>

                    </Table.Row>
                  </Table.Body>
                </Table>
              </Accordion.Title>
              <Accordion.Content active={activeIndex === index}>
                {it.getDetails() ? <Table>
                  <Table.Body style={{ fontWeight: 700 }}>
                    {it.getDetails()?.map(detail =>
                      <Table.Row>
                        <Table.Cell>
                          <Table>
                            <Table.Header>
                              <Table.Row>
                                <Table.HeaderCell colSpan={6} >Claim Number :- {detail?.claim_number || "--"}</Table.HeaderCell>
                              </Table.Row>
                            </Table.Header>
                            {detail.data?.map((value: any) => <>
                              <Table.Body>
                                {/* <Table.Row>
                                  <Table.Cell>Start Address :-</Table.Cell>
                                  <Table.Cell colSpan={6}>{value.startAddress}</Table.Cell>
                                </Table.Row> */}
                                <Table.Row>
                                  <Table.Cell> Address :-</Table.Cell>
                                  <Table.Cell colSpan={1}>{value.destAddress}</Table.Cell>
                                  <Table.Cell colSpan={1} textAlign="right">Coordinate :-</Table.Cell>
                                  <Table.Cell colSpan={2}>{`${value?.investigatorUploadLocation[0]} - 
                                  ${value?.investigatorUploadLocation[1]}` ||"--"}</Table.Cell>
                                </Table.Row>
                                {/* <Table.Row>
                                  <Table.Cell>Document :-</Table.Cell>
                                  {value.document.map((doc: any) => <>
                                    <Table.Cell colSpan={6}> <a className={"ui green  button"}
                                      href={`${config.mrdRecipt}${doc.document}`}
                                      target={"_blank"}
                                    >
                                      Doc
                                    </a>
                                    </Table.Cell>
                                  </>)}
                                </Table.Row> */}
                                <Table.Row>
                                  <Table.Cell>Distance :-</Table.Cell>
                                  <Table.Cell >{value.distance}</Table.Cell>
                                  <Table.Cell colSpan={1} textAlign="right">Purpose Of Visit :-</Table.Cell>
                                  <Table.Cell colSpan={2}>{value?.purposeOfVisit||"--"}</Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                  <Table.Cell width={1}>Odometer Reading :-</Table.Cell>
                                  <Table.Cell width={1}>{value.odometerReading}</Table.Cell>
                                  <Table.Cell width={2} textAlign="right">Odometer Reading Pic :-</Table.Cell>
                                  <Table.Cell width={2} textAlign="left">
                                    {/* <a
                                    className={"ui orange button"}
                                    href={`${config.mrdRecipt}${value.odometerReadingPic}`}
                                    target={"_blank"}
                                  >
                                    Odometer
                                  </a> */}

                                    <a href={`${config.mrdRecipt}${value.odometerReadingPic}`} target="_blank"                                    >
                                      <img style={{ border: "1px solid #ff5400fc", padding: "1px" }} width="50px" height="50px" src={`${config.mrdRecipt}${value.odometerReadingPic}`} alt="Odometer" />
                                    </a>

                                  </Table.Cell>
                                  <Table.Cell width={1} textAlign="right">Selfie :-</Table.Cell>
                                  <Table.Cell width={1} textAlign="left">

                                    <a href={`${config.mrdRecipt}${value.selfie}`} target="_blank">
                                      <img style={{ border: "1px solid #ff5400fc", padding: "1px" }} width="50px" height="50px" src={`${config.mrdRecipt}${value.selfie}`} alt="Odometer" />
                                    </a>

                                    {/* <a
                                      className={"ui olive button"}
                                      href={`${config.mrdRecipt}${value.selfie}`}
                                      target={"_blank"}
                                    >
                                      Selfie
                                    </a> */}
                                  </Table.Cell>
                                </Table.Row>
                              </Table.Body>
                            </>)}
                          </Table>
                        </Table.Cell>
                      </Table.Row>
                    )}
                  </Table.Body>
                </Table> : <Table>
                  <Table.Row>
                    <Table.HeaderCell textAlign="center">There are no records to display</Table.HeaderCell>
                  </Table.Row>
                </Table>}
              </Accordion.Content>
            </Accordion>
          )}
        </Segment>
      </FilterPageContext.Provider>
    );
  }
}
export default (props: any) => {
  return (
    // <RequirePermission permission={permissions.Master.CRUD}>
      <InvestigatorActivityDetail {...props} />
    // </RequirePermission>
  );
};
