import DomainModel from './DomainModel'

export default class CaseCategory extends DomainModel {
  constructor(private id: string, private title: string, private code: string) {
    super()
  }

  getId = () => this.id
  getTitle = () => this.title
  getCode = () => this.code
}
