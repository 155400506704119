import DataMapper from './DataMapper'
import GradeRM from '../responsemodel/GradeRM'
import Grade from '../../models/Grade'

class GradeDataMapper extends DataMapper<GradeRM, Grade> {
  map = (rm: GradeRM) => {
    return new Grade(rm.allocation_hygiene, rm.report_quality, rm.outcome, rm.tat)
  }
}

export default GradeDataMapper
