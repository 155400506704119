import React, { useEffect, useState } from "react";
import { Form, Dropdown, Popup, Button } from "semantic-ui-react";
import FormLabel from "../../common/FormLabel";
import TPA from "../../../models/TPA";
import Result from "../../../common/repository/Result";
import { toast } from "../../common/Toast";
import ClientRepository from "../../../common/repository/ClientRepository";

/**
 * Render this component inside a <Form />
 */

interface CaseFormInputTPAProps {
  value: string;
  onChange(value: string): void;
  clientRepo: ClientRepository;
  tpas?: TPA[];
  isDisabled: boolean;

  labelWidth?: string;
}

const CaseFormInputTPA = (props: CaseFormInputTPAProps) => {
  const { value, onChange, clientRepo, tpas, labelWidth, isDisabled } = props;

  const [loading, setLoading] = useState(false);
  const [TPAOptions, setTPAOptions] = useState(
    tpas &&
      tpas.map((it) => {
        return { text: it.getName(), value: it.getId(), key: it.getId() };
      })
  );
  let [visibility, setVisibility] = useState(isDisabled);

  // console.log("disable check in TPA : ",visibility);

  useEffect(() => {
    if (tpas !== undefined) return;

    setLoading(true);
    (async () => {
      const result = await clientRepo.getTPAs();
      if (result instanceof Result.Success) {
        const items = result.data.items;
        setTPAOptions(
          items.map((it) => {
            return { text: it.getName(), value: it.getId(), key: it.getId() };
          })
        );
      } else {
        const message = result.message || "Could not load TPAs";
        toast.error(message);
      }
      setLoading(false);
    })();
  }, []);

  const width = props.labelWidth || "120px";
  return (
    <Form.Group inline>
      <Form.Field>
        <FormLabel width={width}>TPA</FormLabel>
        <Dropdown
          disabled={visibility}
          search
          selection
          placeholder="Select TPA"
          loading={loading}
          options={TPAOptions}
          value={value}
          onChange={(_, { value }) => onChange(value as string)}
        />
      </Form.Field>
      <Form.Field>
        <Popup
          inverted
          content="Clear Selection"
          position="top center"
          trigger={
            <Button size="mini" icon="erase" onClick={() => onChange("")} />
          }
        ></Popup>
      </Form.Field>
    </Form.Group>
  );
};

export default React.memo(CaseFormInputTPA);
