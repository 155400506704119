import ProtectedAPIRepository from './ProtectedAPIRepository'
import APIResponseModel from './APIResponseModel'
import UserRM from '../responsemodel/UserRM'
import UserHistoryRM from '../responsemodel/UserHistoryRM'
import Result from './Result'
import UserDataMapper from '../datamapper/UserDataMapper'
import UserHistoryDataMapper from "../datamapper/UserHistoryDataMapper"
import UserSalaryRM from '../responsemodel/UserSalaryRM'
import UserWarningRM from '../responsemodel/UserWarningRM'
import UserWarningDataMapper from '../datamapper/UserWarningDataMapper'

export default class UserRepository extends ProtectedAPIRepository {
  private dataMapper = new UserDataMapper()
  private dataUserHistoryMapper = new UserHistoryDataMapper()

  private userWarningDataMapper = new UserWarningDataMapper()

  getUsers = async (ro?: GetUsersRequestObject) => {
    const response = await this.post<APIResponseModel<UserRM>>('/user', ro)
    if (response instanceof Result.Success) {
      const data: APIResponseModel<UserRM> = response.data
      if (data.data !== null) {
        const users = data.data.items.map((it) => this.dataMapper.map(it))
        return new Result.Success({
          items: users,
          totalItemCount: data.data.total_item_count,
        })
      } else {
        return new Result.Error(0, 'Data is null')
      }
    } else {
      return response
    }
  }

  getUsersByRoleLevel = async (level: number) => {
    const response = await this.get<APIResponseModel<UserRM>>(`/user/${level}`)
    if (response instanceof Result.Success) {
      const data: APIResponseModel<UserRM> = response.data
      if (data.data !== null) {
        const users = data.data.items.map((it) => this.dataMapper.map(it))
        return new Result.Success({
          items: users,
          totalItemCount: data.data.total_item_count,
        })
      } else {
        return new Result.Error(0, 'Data is null')
      }
    } else {
      return response
    }
  }

  // get User Change History
  getUsersHistory = async (level: string) => {
    const response = await this.get<APIResponseModel<UserHistoryRM>>(`/user/listTrackHistory/${level}`)
    if (response instanceof Result.Success) {
      const data: APIResponseModel<UserHistoryRM> = response.data
      if (data.data !== null) {
        const users = data.data.items.map((it) => this.dataUserHistoryMapper.map(it))
        return new Result.Success({
          items: users,
          totalItemCount: data.data.total_item_count,
        })
      } else {
        return new Result.Error(0, 'Data is null')
      }
    } else {
      return response
    }
  }


  addUser = async (ro: AddUserRequestObject) => {
    const formData = this.constructFormData(ro)
    const response = await this.put<APIResponseModel<UserRM>>('/user', formData)
    if (response instanceof Result.Success) {
      const data: APIResponseModel<UserRM> = response.data
      if (data.data !== null) {
        const user = this.dataMapper.map(data.data.items[0])
        return new Result.Success(user)
      } else {
        return new Result.Error(0, 'Data is null')
      }
    } else {
      return response
    }
  }

  updateUser = async (ro: UpdateUserRequestObject) => {
    const formData = this.constructFormData(ro)
    const response = await this.patch<APIResponseModel<UserRM>>('/user', formData)
    if (response instanceof Result.Success) {
      const data: APIResponseModel<UserRM> = response.data
      if (data.data !== null) {
        const user = this.dataMapper.map(data.data.items[0])
        return new Result.Success(user)
      } else {
        return new Result.Error(0, 'Data is null')
      }
    } else {
      return response
    }
  }

  removeUser = async (userId: string) => {
    const response = await this.delete<null>(`/user/${userId}`)
    return response
  }

  getUserByToken = async () => {
    const response = await this.get<APIResponseModel<UserRM>>('/user/token/getuser')
    if (response instanceof Result.Success) {
      const data: APIResponseModel<UserRM> = response.data
      if (data.data !== null) {
        const user = this.dataMapper.map(data.data.items[0])
        return new Result.Success(user)
      } else {
        return new Result.Error(0, 'Data is null')
      }
    } else {
      return response
    }
  }

  getUsersByRoleTitle = async (ro: GetUsersByRoleTitleRequestObject) => {
    const response = await this.post<APIResponseModel<UserRM>>('/user/role', ro)
    if (response instanceof Result.Success) {
      const data: APIResponseModel<UserRM> = response.data
      if (data.data !== null) {
        const users = data.data.items.map((it) => this.dataMapper.map(it))
        return new Result.Success({
          items: users,
          totalItemCount: data.data.total_item_count,
        })
      } else {
        return new Result.Error(0, 'Data is null')
      }
    } else {
      return response
    }
  }

  changePassword = async (ro: ChangePasswordRequestObject) => {
    const response = await this.post<APIResponseModel<UserRM>>('/user/changePassword', ro)
    if (response instanceof Result.Success) {
      const data: APIResponseModel<UserRM> = response.data
      if (data.data !== null) {
        const user = this.dataMapper.map(data.data.items[0])
        return new Result.Success(user)
      } else {
        return new Result.Error(0, 'Data is null')
      }
    } else {
      return response
    }
  }

  getSalary = async (ro: GetSalaryRequestObject) => {
    const response = await this.post<APIResponseModel<UserSalaryRM>>('/salary/user/basic', ro)
    if (response instanceof Result.Success) {
      const data: APIResponseModel<UserSalaryRM> = response.data
      if (data.data !== null) {
        const amount = data.data.items[0].basic_salary
        const remainingLeaves = data.data.items[0].remaining_leaves
        const specialAllowance = data.data.items[0].special_allowance
        return new Result.Success({
          amount,
          remainingLeaves,
          specialAllowance,
        })
      } else {
        return new Result.Error(0, 'Data is null')
      }
    } else {
      return response
    }
  }

  updateSalary = async (ro: UpdateUserSalaryRequestObject) => {
    const response = await this.put<APIResponseModel<UserSalaryRM>>('/salary/user/basic', ro)
    if (response instanceof Result.Success) {
      const data: APIResponseModel<UserSalaryRM> = response.data
      if (data.data !== null) {
        const amount = data.data.items[0].basic_salary
        return new Result.Success(amount)
      } else {
        return new Result.Error(0, 'Data is null')
      }
    } else {
      return response
    }
  }

  getWarnings = async (ro?: GetWarningsRequestObject) => {
    const response = await this.post<APIResponseModel<UserWarningRM>>('/user/warning', ro)
    if (response instanceof Result.Success) {
      const data: APIResponseModel<UserWarningRM> = response.data
      if (data.data !== null) {
        const items = data.data.items.map((it) => this.userWarningDataMapper.map(it))
        return new Result.Success({
          items,
          totalItemCount: data.data.total_item_count,
        })
      } else {
        return new Result.Error(0, 'Data is null')
      }
    } else {
      return response
    }
  }

  addWarning = async (ro: AddWarningRequestObject) => {
    const response = await this.put<APIResponseModel<UserWarningRM>>('/user/warning', ro)
    if (response instanceof Result.Success) {
      const data: APIResponseModel<UserWarningRM> = response.data
      if (data.data !== null) {
        const userWarning = this.userWarningDataMapper.map(data.data.items[0])
        return new Result.Success(userWarning)
      } else {
        return new Result.Error(0, 'Data is null')
      }
    } else {
      return response
    }
  }

  updateWarning = async (ro: UpdateWarningRequestObject) => {
    const response = await this.patch<APIResponseModel<UserWarningRM>>('/user/warning', ro)
    if (response instanceof Result.Success) {
      const data: APIResponseModel<UserWarningRM> = response.data
      if (data.data !== null) {
        return new Result.Success(this.userWarningDataMapper.map(data.data.items[0]))
      } else {
        return new Result.Error(0, 'Data is null')
      }
    } else {
      return response
    }
  }

  removeWarning = async (userWarningId: string) => {
    const response = await this.delete<APIResponseModel<null>>(`/user/warning/${userWarningId}`)
    if (response instanceof Result.Success) return new Result.Success(null)
    else return response
  }
}

interface GetUsersRequestObject {
  search?: string
  id?: string
  name?: string
  role?: string
  findTeam?: boolean
  is_active?: boolean
}

export interface AddUserRequestObject {
  emp_id: string
  name: string
  email: string
  other_email?: string
  phone: string
  other_phone?: string
  joining_date: string
  role: string
  parent?: string
  locations: { state: string; district: string }[][]
  profile_pic?: File
  dob?: string
  address?: string
  password: string
  clients?: []
  case_categories?: []
  coordinates?: any[]
}

export interface UpdateUserRequestObject {
  id: string
  emp_id?: string
  name: string
  email: string
  other_email?: string
  phone: string
  other_phone?: string
  joining_date?: string
  role?: string
  locations?: { state: string; district: string }[][]
  parent?: string
  profile_pic?: File
  dob?: string
  address?: string
  password?: string
  is_active?: boolean
  clients?: string[]
  case_categories?: string[]
  coordinates?: any[]
}

export interface GetUsersByRoleTitleRequestObject {
  role: string
  // is_active?: boolean
}

export interface ChangePasswordRequestObject {
  old_password: string
  new_password: string
}

export interface UpdateUserSalaryRequestObject {
  user: string
  basic_salary: number
  special_allowance: number
  increment_history?: { by: string; amount: number }[]
}

export interface GetSalaryRequestObject {
  user: string
}

export interface GetWarningsRequestObject {
  search?: string
  limit?: number
  offset?: number
}

export interface AddWarningRequestObject {
  user_id: string
  warning: string
  date: string
}

export interface UpdateWarningRequestObject {
  id: string
  user_id: string
  warning: string
  date: string
}
