import ActionColumnRenderer from '../../../app/Table/renderer/ActionColumnRenderer'
import MrdCharges from '../../../../models/MrdCharges'

export default class MrdChargeColumnRenderer extends ActionColumnRenderer<MrdCharges> {
    getColumns() {
        return [
            {
                name: 'Investigator Name',
                selector: 'investigator_name',
            },
            {
                name: 'Claim Number',
                selector: 'claim_number',
            },
            {
                name: 'Hospital',
                selector: 'hospital_name',
            },
            {
                name: 'Mrd Amount(RS)',
                selector: 'mrd_amount',
            },
          
            {
                name: 'Actions',
                selector: '_id',
                grow: 0,
                width: '180px',
                cell: this.renderActionColumn,
            },
        ]
    }
}
