import React, { useEffect, useState } from 'react'
import Layout from './Layout'
import { useParams, useHistory } from 'react-router-dom'
import UserFormPageContext from './UserFormPageContext'
import UserRepository from '../../../../common/repository/UserRepository'
import User from '../../../../models/User'
import Result from '../../../../common/repository/Result'
import { toast } from '../../../common/Toast'
import RequirePermission from '../../../base/RequirePermission'
import permissions from '../../../../common/permissions'
import SettingsRepository from '../../../../common/repository/SettingsRepository'
import Settings from '../../../../models/Settings'

function UserFormPage() {
  const params = useParams<{ userId: string }>()
  const history = useHistory()
  const userRepo = new UserRepository()
  const settingsRepo = new SettingsRepository()

  const [isLoading, setIsLoading] = useState(false)
  const [user, setUser] = useState<User | null>(null)
  const [settings, setSettings] = useState<Settings | null>(null)
  const [userSalaryAmount, setUserSalaryAmount] = useState(0)
  const [specialAllowance, setSpecialAllowance] = useState(0)

  if (params.userId !== undefined && !isLoading && user === null) setIsLoading(true)

  useEffect(() => {
    effectLoadEssentialData()
  }, [isLoading])

  return (
    <UserFormPageContext.Provider
      value={{
        userRepo,
        isLoading,
        setIsLoading,
        user,
        setUser,
        settings,
        setSettings,
        userSalaryAmount,
        setUserSalaryAmount,
        specialAllowance,
        setSpecialAllowance(amount: number): void {},
      }}
    >
      <Layout />
    </UserFormPageContext.Provider>
  )

  async function effectLoadEssentialData() {
    if (!isLoading) return
    const promise1 = userRepo.getUsers({ id: params.userId! })
    const promise2 = settingsRepo.getSettings()
    const promise3 = userRepo.getSalary({ user: params.userId! })
    const results = await Promise.all([promise1, promise2, promise3])

    const error = results.find((it) => it instanceof Result.Error)
    if (error && error instanceof Result.Error) {
      const message = error.message || 'Something went wrong'
      toast.error(message)
      history.push('/manage/users')
      return
    }

    if (results[0] instanceof Result.Success) {
      const user = results[0].data.items[0]
      setUser(user)
    }

    if (results[1] instanceof Result.Success) {
      const settings = results[1].data
      setSettings(settings)
    }

    if (results[2] instanceof Result.Success) {
      const userSalaryAmount = results[2].data
      setUserSalaryAmount(userSalaryAmount.amount)
      setSpecialAllowance(userSalaryAmount.specialAllowance)
    }
    setIsLoading(false)
  }
}

export default (props: any) => {
  return (
    <RequirePermission permission={permissions.Manage.CRUD}>
      <UserFormPage {...props} />
    </RequirePermission>
  )
}
