import DataMapper from './DataMapper'
import AllocatedClaimAnalystRM from '../responsemodel/AllocatedClaimAnalystRM'
import AllocatedClaimAnalyst from '../../models/AllocatedClaimAnalyst'
import UserDataMapper from './UserDataMapper'
import AllocatedInvestigatorDataMapper from './AllocatedInvestigatorDataMapper'
import GradeDataMapper from './GradeDataMapper'

class AllocatedClaimAnalystDataMapper extends DataMapper<AllocatedClaimAnalystRM, AllocatedClaimAnalyst> {
  private userDataMapper = new UserDataMapper()
  private allocatedInvestigatorDataMapper = new AllocatedInvestigatorDataMapper()
  private gradeDataMapper = new GradeDataMapper()

  map = (rm: AllocatedClaimAnalystRM) => {
    const claimAnalyst = this.userDataMapper.map(rm.claim_analyst)
    const allocatedInvestigators = rm.allocated_investigators.map((it) => this.allocatedInvestigatorDataMapper.map(it))
    let grade = null
    if (rm.grading_report !== null) grade = this.gradeDataMapper.map(rm.grading_report)
    return new AllocatedClaimAnalyst(grade, rm.grading_feedback, claimAnalyst, allocatedInvestigators)
  }
}

export default AllocatedClaimAnalystDataMapper
