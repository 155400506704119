import React, { useContext } from "react";
import { Grid, Form, Header, Button, ButtonOr, ButtonGroup, } from "semantic-ui-react";
import FilterPageContext from "./FilterPageContext";
import DateTimePicker from "../../../app/DateTimePicker";

function Filters() {
    const ctx = useContext(FilterPageContext);

    let data: { key: string; value: any }[] = [];
    if (ctx.filters.to) data.push({ key: "to", value: ctx.filters.to });
    if (ctx.filters.from) data.push({ key: "from", value: ctx.filters.from });
    return (
        <>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Header as="h4">Filter</Header>
            </div>
            <div>
                <b style={{color:"red",fontSize:"10px"}}>*By Default Data from last 3 month's till now</b>

            </div>
            <div style={{ display: "flex" }}>
                <div style={{ margin: "5px" }}>
                    <DateTimePicker
                        placeholder="Select Start Date"
                        type="date"
                        value={ctx.filters.from}
                        onChange={ctx.filters.setFrom}
                    />
                </div>
                <div style={{ margin: "5px" }}>
                    <DateTimePicker
                        placeholder="Select End Date"
                        type="date"
                        value={ctx.filters.to}
                        onChange={ctx.filters.setTo}
                    />
                </div>

                <div style={{ margin: "5px" }}>
                    <ButtonGroup size='medium'>
                        <Button onClick={ctx.filters.clearAllFilters}>Clear</Button>
                        <ButtonOr />
                        <Button onClick={ctx.filters.applyFilters} positive>Apply Filters</Button>
                        {ctx.filters.downloadData && (
                            <>
                                <ButtonOr />
                                <Button onClick={ctx.filters.downloadData}>Download</Button>
                            </>
                        )}
                    </ButtonGroup>
                </div>
            </div>
        </>
    );
}

export default Filters;
