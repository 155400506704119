import User from '../../models/User'

/**
 * AuthState will be used to keep track of
 * user's login state.
 *
 * When App will first mount initializing will be true
 * and once initializing is set to false then authentication
 * can be confirmed if the user is present or null.
 */
export interface AuthState {
  initializing: boolean
  user: User | null
}

/** Action Types */
export const SET_AUTH = 'SET_AUTH'
export const AUTH_STATE_INITIALIZING = 'AUTH_STATE_INITIALIZING'

interface SetAuthAction {
  type: typeof SET_AUTH
  payload: User | null
}

interface AuthStateInitializing {
  type: typeof AUTH_STATE_INITIALIZING
}

export type AuthActionTypes = SetAuthAction | AuthStateInitializing
