import React from "react";
import CaseRepository, {
  GetCasesRequestObject,
} from "../../../../common/repository/CaseRepository";
import { RouteComponentProps } from "react-router-dom";
import Result from "../../../../common/repository/Result";
import CompleteCase from "../../../../models/CompleteCase";
import CaseViewPageLoading from "./CaseViewPageLoading";
import CaseViewPage from "./CaseViewPage";
import RequirePermission from "../../../base/RequirePermission";
import permissions from "../../../../common/permissions";

interface MatchProps {
  caseId: string;
}
interface Props extends RouteComponentProps<MatchProps> { }
interface State {
  loading: boolean;
  model: CompleteCase | null;
}
class CaseViewPageFactory extends React.Component<Props, State> {
  private caseRepo = new CaseRepository();
  private pageTitle = "Case Page";
  private pageDescription = `
        View case detail and perform appropriate actions.
    `;

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: true,
      model: null,
    };
  }

  componentDidMount() {
    this.getCase();
  }

  private getCase = () => {
    const caseId = this.props.match.params.caseId;
    this.setState({ loading: true }, async () => {
      const ro: GetCasesRequestObject = { id: caseId };
      const result = await this.caseRepo.getCases(ro);
      if (result instanceof Result.Success) {
        if (!result.data.items.length) {
          this.setState({ loading: false });
          this.props.history.push("/");
        }
        const c = result.data.items[0] as CompleteCase;
        this.setState({ loading: false, model: c });
      } else {
        this.setState({ loading: false }, this.redirectBack);
      }
    });
  };

  private redirectBack = () => {
    const from = this.props.location.state;
    if (from) this.props.history.push(from);
    else this.props.history.push("/case/list");
  };

  render() {
    if (this.state.loading) return <CaseViewPageLoading />;
    else if (this.state.loading === false && this.state.model !== null)
      return (
        <CaseViewPage
          title={this.pageTitle}
          description={this.pageDescription}
          model={this.state.model}
          updateModel={(c) => this.setState({ model: c })}
        />
      );
    else return null;
  }
}

export default (props: any) => {
  return (
    <RequirePermission
      permission={[
        permissions.Case.View.initiated,
        permissions.Case.View.generated,
        permissions.Case.View.open,
        permissions.Case.View.reinvestigate,
        permissions.Case.View.fold,
        permissions.Case.View.checked,
        permissions.Case.View.close,
      ]}
      any
    >
      <CaseViewPageFactory {...props} />
    </RequirePermission>
  );
};
