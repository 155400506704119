import React, { useState, useEffect } from "react";
import Page from "../../../layout/Page";
import {
  Modal,
  Button,
  Segment,
  Header,
  Icon,
  Dropdown,
} from "semantic-ui-react";
import DownloadTemplateForm from "./DownloadTemplateForm";
import UploadAttendanceForm from "./UploadAttendanceForm";
import ReportPageContext from "./ReportPageContext";
import AttendanceRepository from "../../../../common/repository/AttendanceRepository";
import Result from "../../../../common/repository/Result";
import { toast } from "../../../common/Toast";
import UserAttendance from "../../../../models/UserAttendance";
import Table from "../../../app/Table";
import AttendanceColumnRenderer from "./AttendanceColumnRenderer";

function ReportPage() {
  const columnRenderer = new AttendanceColumnRenderer();
  const attendanceRepo = new AttendanceRepository();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const today = new Date();
  const m = today.getMonth();
  const [month, setMonth] = useState(m < 10 ? `0${m}` : m.toString());
  const [year, setYear] = useState(today.getFullYear().toString());
  const [isLoading, setIsLoading] = useState(false);
  const [limit, setLimit] = useState(
    Table.ROWS_PER_PAGE[Table.ROWS_PER_PAGE.length - 1] * 2
  );
  const [offset, setOffset] = useState(0);
  const [searchFilter, setSearchFilter] = useState("");
  const [attendanceCount, setAttendanceCount] = useState(0);
  const [attendance, setAttendance] = useState<UserAttendance[]>([]);

  useEffect(triggerLoadAttendance, [month, year, searchFilter]);
  useEffect(() => {
    loadAttendance();
  }, [isLoading]);

  const onRowsPerPageChange = (page: number, rowsPerPage: number) => {
    const offset = (page - 1) * rowsPerPage,
      limit = offset + rowsPerPage
    setLimit(limit)
    setOffset(offset)
    setIsLoading(true)
    loadAttendance();
  }

  return (
    <ReportPageContext.Provider
      value={{
        attendanceRepo,
        today,
        monthOptions,
        yearOptions,
      }}
    >
      <Page title="User Attendance Report">
        <Segment.Group>
          <Segment>
            <Header as="h4" color="grey">
              New Attendance
            </Header>
          </Segment>
          <Segment>
            <Modal
              size="mini"
              closeIcon
              closeOnDimmerClick={false}
              closeOnEscape={false}
              trigger={
                <span>
                  <label style={{ marginRight: 16 }}>Step 1:</label>
                  <Button primary>Download Template</Button>
                </span>
              }
            >
              <Modal.Header>Download Template</Modal.Header>
              <Modal.Content>
                <DownloadTemplateForm />
              </Modal.Content>
            </Modal>
            <Icon name="arrow right" style={{ margin: "0 2rem" }} />
            <label>Step 2: Fill it.</label>
            <Icon name="arrow right" style={{ margin: "0 2rem" }} />
            <Modal
              open={isModalOpen}
              size="mini"
              closeIcon
              closeOnDimmerClick={false}
              closeOnEscape={false}
              onClose={() => setIsModalOpen(false)}
              trigger={
                <span>
                  <label style={{ marginRight: 16 }}>Step 3:</label>
                  <Button onClick={() => setIsModalOpen(true)} primary>
                    Upload Attendance
                  </Button>
                </span>
              }
            >
              <Modal.Header>Upload Attendance</Modal.Header>
              <Modal.Content>
                <UploadAttendanceForm onSuccess={() => setIsModalOpen(false)} />
              </Modal.Content>
            </Modal>
          </Segment>
        </Segment.Group>
        <Header as="h4">Report</Header>

        <Dropdown
          search
          selection
          value={month}
          options={monthOptions}
          onChange={(_, { value }) => setMonth(value as string)}
        />

        <Dropdown
          search
          selection
          value={year}
          options={yearOptions}
          onChange={(_, { value }) => setYear(value as string)}
        />

        <Table
          renderer={columnRenderer}
          data={attendance}
          totalDataCount={attendanceCount}
          loading={isLoading}
          onRowsPerPageChange={onRowsPerPageChange}
          load={() => { }}
          onSearch={(value) => {
            setSearchFilter(value);
            setOffset(0);
          }}
          onClear={() => {
            setSearchFilter("");
            setOffset(0);
          }}
        />
      </Page>
    </ReportPageContext.Provider>
  );

  function triggerLoadAttendance() {
    if (month.trim().length !== 0 && year.trim().length !== 0)
      setIsLoading(true);
  }

  async function loadAttendance() {
    if (!isLoading) return;
    const result = await attendanceRepo.getAttendance({
      month,
      year,
      limit,
      offset,
      search: searchFilter,
    });
    if (result instanceof Result.Success) {
      if (offset === 0) {
        setAttendance(result.data.items);
        setAttendanceCount(result.data.totalItemCount);
        setOffset(result.data.items.length);
      } else {
        const totalItems = [...attendance, ...result.data.items];
        setAttendance(totalItems);
        setOffset(totalItems.length);
      }
    } else {
      const message = result.message || "Could not load Attendance";
      toast.error(message);
    }
    setIsLoading(false);
  }
}

const monthOptions = [
  { text: "January", value: "01", key: "1" },
  { text: "February", value: "02", key: "2" },
  { text: "March", value: "03", key: "3" },
  { text: "April", value: "04", key: "4" },
  { text: "May", value: "05", key: "5" },
  { text: "June", value: "06", key: "6" },
  { text: "July", value: "07", key: "7" },
  { text: "August", value: "08", key: "8" },
  { text: "September", value: "09", key: "9" },
  { text: "October", value: "10", key: "10" },
  { text: "November", value: "11", key: "11" },
  { text: "December", value: "12", key: "12" },
];

const yearOptions = [
  { text: "2020", value: "2020", key: "1" },
  { text: "2021", value: "2021", key: "2" },
  { text: "2022", value: "2022", key: "3" },
  { text: "2023", value: "2023", key: "4" },
  { text: "2024", value: "2024", key: "5" },
  { text: "2025", value: "2025", key: "6" },
  { text: "2026", value: "2026", key: "7" },
  { text: "2027", value: "2027", key: "8" },
  { text: "2028", value: "2028", key: "9" },
  { text: "2029", value: "2029", key: "10" },
  { text: "2030", value: "2030", key: "11" },
  { text: "2031", value: "2031", key: "12" },
  { text: "2032", value: "2032", key: "13" },
  { text: "2033", value: "2033", key: "14" },
  { text: "2034", value: "2034", key: "15" },
  { text: "2035", value: "2035", key: "16" },
  { text: "2036", value: "2036", key: "17" },
  { text: "2037", value: "2037", key: "18" },
  { text: "2038", value: "2038", key: "19" },
  { text: "2039", value: "2039", key: "20" },
];

export default ReportPage;
