import DomainModel from './DomainModel'

class Branch extends DomainModel {
  constructor(private state: string, private GSTNumber: string, private locations: { city: string; address: string }[]) {
    super()
  }

  getState = () => this.state
  getGSTNumber = () => this.GSTNumber
  getLocations = () => this.locations
}

export default Branch
