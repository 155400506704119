import React from "react";

interface Props {
  id: string;
  show: boolean;
  width: string;
}

class SidebarLayout extends React.Component<Props> {
  private sidebar: HTMLElement | null = null;

  render(): JSX.Element {
    return (
      <aside
        {...this.props}
        style={{ backgroundColor: "rgb(18, 33, 214)" }}
      ></aside>
    );
  }

  componentDidMount() {
    this.sidebar = document.getElementById(this.props.id);
    if (this.sidebar === null)
      throw new Error(`Could not find Sidebar(${this.props.id})`);

    this.sidebar.addEventListener("transitionend", this._onTransitionEnd);
    this._toggleSidebar();
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.show === this.props.show) return;
    this._toggleSidebar();
  }

  componentWillUnmount() {
    this.sidebar!.removeEventListener("transitionend", this._onTransitionEnd);
  }

  private _toggleSidebar = (): void => {
    const translation = this.props.show ? "0" : "-100%";
    this.sidebar!.style.transform = `translateX(${translation})`;
  };

  private _onTransitionEnd = (): void => {
    this.sidebar!.style.width = this.props.show ? this.props.width : "0";
  };
}

export default SidebarLayout;
