import ProtectedAPIRepository from './ProtectedAPIRepository'
import HospitalDataMapper from '../datamapper/HospitalDataMapper'
import HospitalRM from '../responsemodel/HospitalRM'
import APIResponseModel from './APIResponseModel'
import Result from './Result'
import MedicDataMapper from '../datamapper/MedicDataMapper'
import MedicRM from '../responsemodel/MedicRM'

export default class HospitalRepository extends ProtectedAPIRepository {
  private hospitalDataMapper = new HospitalDataMapper()
  private medicDataMapper = new MedicDataMapper()

  getHospitals = async (ro?: GetHospitalsRequestObject) => {
    const response = await this.post<APIResponseModel<HospitalRM>>('/hospital', ro)
    if (response instanceof Result.Success) {
      const data: APIResponseModel<HospitalRM> = response.data
      if (data.data !== null) {
        const hospitals = data.data.items.map((it) => this.hospitalDataMapper.map(it))
        return new Result.Success({
          totalItemCount: data.data.total_item_count,
          items: hospitals,
        })
      } else {
        return new Result.Error(0, 'Data is null')
      }
    } else {
      return response
    }
  }

  addHospital = async (ro: AddHospitalRequestObject) => {
    const response = await this.put<APIResponseModel<HospitalRM>>('/hospital', ro)
    if (response instanceof Result.Success) {
      const data: APIResponseModel<HospitalRM> = response.data
      if (data.data !== null) {
        const hospital = this.hospitalDataMapper.map(data.data.items[0])
        return new Result.Success(hospital)
      } else {
        return new Result.Error(0, 'Data is null')
      }
    } else {
      return response
    }
  }

  updateHospital = async (ro: UpdateHospitalRequestObject) => {
    const response = await this.patch<APIResponseModel<HospitalRM>>('/hospital', ro)
    if (response instanceof Result.Success) {
      const data: APIResponseModel<HospitalRM> = response.data
      if (data.data !== null) {
        const hospital = this.hospitalDataMapper.map(data.data.items[0])
        return new Result.Success(hospital)
      } else {
        return new Result.Error(0, 'Data is null')
      }
    } else {
      return response
    }
  }

  removeHospital = async (hospitalId: string) => {
    const response = await this.delete<APIResponseModel<null>>(`/hospital/${hospitalId}`)
    return response
  }

  getDoctors = async (ro: GetDoctorsRequestObject) => {
    const response = await this.post<APIResponseModel<MedicRM>>('/hospital/doctor', ro)
    if (response instanceof Result.Success) {
      const data: APIResponseModel<MedicRM> = response.data
      if (data.data !== null) {
        const doctors = data.data.items.map((it) => this.medicDataMapper.map(it))
        return new Result.Success({
          items: doctors,
          totalItemCount: data.data.total_item_count,
        })
      } else {
        return new Result.Error(0, 'Data is null')
      }
    } else {
      return response
    }
  }

  getPathologists = async (ro: GetPathologistsRequestObject) => {
    const response = await this.post<APIResponseModel<MedicRM>>('/hospital/pathologist', ro)
    if (response instanceof Result.Success) {
      const data: APIResponseModel<MedicRM> = response.data
      if (data.data !== null) {
        const pathologists = data.data.items.map((it) => this.medicDataMapper.map(it))
        return new Result.Success({
          items: pathologists,
          totalItemCount: data.data.total_item_count,
        })
      } else {
        return new Result.Error(0, 'Data is null')
      }
    } else {
      return response
    }
  }
}

export interface GetHospitalsRequestObject {
  search?: string
  name?: string
  limit?: any
  offset?: any
  location?: { state?: string; district?: string }
  district?: string
  state?: string
}

export interface AddHospitalRequestObject {
  name: string
  district: string
  state: string
}

export interface UpdateHospitalRequestObject {
  id: string
  name: string
  district: string
  state: string
}

export interface GetDoctorsRequestObject {
  id?: string
  name?: string
  search?: string
  council_name?: string
  verified?: boolean
  registration_number?: string
}

export interface GetPathologistsRequestObject {
  id?: string
  name?: string
  search?: string
  council_name?: string
  verified?: boolean
  registration_number?: string
}
