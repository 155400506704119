import ProtectedAPIRepository from './ProtectedAPIRepository'
import APIResponseModel from './APIResponseModel'
import StateRM from '../responsemodel/StateRM'
import Result from './Result'
import StateDataMapper from '../datamapper/StateDataMapper'
import LocationRM from '../responsemodel/LocationRM'
import LocationDataMapper from '../datamapper/LocationDataMapper'

export default class StateDistrictRepository extends ProtectedAPIRepository {
  private locationDataMapper = new LocationDataMapper()

  getStates = async () => {
    const response = await this.get<APIResponseModel<StateRM>>('/state')
    if (response instanceof Result.Success) {
      const data = response.data
      if (data.data !== null) {
        const dataMapper = new StateDataMapper()
        const states = data.data.items.map((it) => dataMapper.map(it))
        return new Result.Success({
          totalItemCount: data.data.total_item_count,
          items: states,
        })
      } else {
        return new Result.Error(0, 'Data is null')
      }
    } else {
      return response
    }
  }

  getDistricts = async (ro: GetDistrictsRequestObject) => {
    const response = await this.post<APIResponseModel<string>>('/state', ro)
    if (response instanceof Result.Success) {
      const data = response.data
      if (data.data !== null) {
        return new Result.Success({
          totalItemCount: data.data.total_item_count,
          items: data.data.items,
        })
      } else {
        return new Result.Error(0, 'Data is null')
      }
    } else {
      return response
    }
  }

  getAll = async () => {
    const response = await this.get<APIResponseModel<LocationRM>>('/state/all')
    if (response instanceof Result.Success) {
      const data = response.data
      if (data.data !== null) {
        const locations = data.data.items.map((it) => this.locationDataMapper.map(it))
        return new Result.Success({
          totalItemCount: data.data.total_item_count,
          items: locations,
        })
      } else {
        return new Result.Error(0, 'Data is null')
      }
    } else {
      return response
    }
  }
}

interface GetDistrictsRequestObject {
  state: string
}
