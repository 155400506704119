import React, { useState, useContext, useEffect } from "react";
import { Form } from "semantic-ui-react";
import Result from "../../../../common/repository/Result";
import { toast } from "../../../common/Toast";
import DocDispatchSectionRepository from "../../../../common/repository/DocDispatchSectionRepository";

function UploadBulkPaymentForm({ onSuccess }: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [sheetFile, setSheetFile] = useState<File | null>(null);
  const [slipFile, setSlipFile] = useState<File | null>(null);
  const docDispatchRepo = new DocDispatchSectionRepository();

  useEffect(() => {
    uploadSheet();
  }, [isLoading, sheetFile]);

  return (
    <Form loading={isLoading}>
      <Form.Input
        type="file"
        label="Choose Dispatch Template Sheet"
        accept=".xlsx"
        onChange={(e) => {
          if (e.target.files) setSheetFile(e.target.files[0]);
          else setSheetFile(null);
        }}
      />
      <Form.Input
        type="file"
        label="Choose POD Slip"
        onChange={(e) => {
          if (e.target.files) setSlipFile(e.target.files[0]);
          else setSlipFile(null);
        }}
      />
      <Form.Button
        positive
        disabled={isLoading || sheetFile === null || slipFile === null}
        onClick={() => setIsLoading(true)}
      >
        Submit
      </Form.Button>
    </Form>
  );

  async function uploadSheet() {
    if (!isLoading || sheetFile === null || slipFile === null) return;
    const result = await docDispatchRepo.uploadDispatchPendencySheet(
      sheetFile,
      slipFile
    );
    if (result instanceof Result.Success) {
      if (result.data.items.length == 0)
        toast.success("Data uploaded successfully");
      else {
        toast.success("Data uploaded successfully, But some rows are skipped!");
      }

      if (onSuccess) onSuccess(result.data.items);
    } else {
      const message = result.message || "Something went wrong";
      toast.error(message);
    }
    setIsLoading(false);
  }
}

interface Props {
  onSuccess?(items: any): any;
}

export default UploadBulkPaymentForm;
