import React from "react";
import styled from "styled-components";
import ForgetPasswordForm from "./ForgetPasswordForm";
import Background from "./Background";

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: rgba(0, 0, 0, 0.02);
`;

const ForgetPasswordLayout = () => {
  return (
    <Background>
      <Wrapper>
        <ForgetPasswordForm />
      </Wrapper>
    </Background>
  );
};

export default ForgetPasswordLayout;
