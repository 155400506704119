import React from "react";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import FileLabel from "../../../common/FileLabel";
import { Icon } from "semantic-ui-react";
import URLResource from "../../../../models/URLResource";

const Header = styled.div`
  width: 300px;

  padding: 8px 16px;

  cursor: all-scroll;
  background-color: #031b4d;
  border-radius: 8px 8px 0 0;
  color: white;
`;

const Body = styled.div`
  width: 300px;
  min-height: 275px;

  padding: 16px;
  background: white;
  border-radius: 0 0 8px 8px;
`;

const Container = styled.div`
  position: absolute;

  border-radius: 8px;

  box-shadow: 1px 2px 20px 8px rgba(0, 0, 0, 0.09),
    -1px -2px 20px 8px rgba(0, 0, 0, 0.09);
  z-index: 10000;
`;

interface Props {
  x?: number;
  y?: number;
  title?: string;
  onClose?(): void;
  files: URLResource[];
}

const FilesPopup = (props: Props) => {
  const { x, y, title, onClose, files } = props;

  return (
    <div style={{ position: "absolute", top: `${y}px`, left: `${x}px` }}>
      <motion.div
        drag
        dragMomentum={false}
        animate={{ scale: 1 }}
        initial={{ scale: 0 }}
      >
        <Container>
          <Header>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div>{title}</div>
              <Icon
                name="x"
                size="large"
                color="red"
                style={{ cursor: "pointer" }}
                onClick={onClose}
              />
            </div>
          </Header>
          <Body>
            <FileLabel.Group horizontal>
              {files.map((it) => (
                <FileLabel title={it.getFullName()} link={it.getURL()} />
              ))}
            </FileLabel.Group>
          </Body>
        </Container>
      </motion.div>
    </div>
  );
};

FilesPopup.defaultProps = {
  x: 0,
  y: 0,
  title: "Files",
  onClose: () => {},
};

export default FilesPopup;
