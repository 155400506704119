import React, { useState, useEffect, useContext } from "react";
import { Button, Header, Segment, Card, Grid, Menu, Pagination, } from "semantic-ui-react";
import NotifecationRepo, { GetNotifectionObject, } from "../../../common/repository/NotifecationRepo";
import { toast } from "../../common/Toast";
import Result from "../../../common/repository/Result";
import Notifecations from "../../../models/NotifecationModels/Notifecations";
import moment from "moment";
import { Link, useHistory, useLocation } from "react-router-dom";
import { GlobalContext } from "../../../App";
const NotificationPage = () => {
    const notifecationRepo = new NotifecationRepo();
    const { Count, setCounts } = useContext<any>(GlobalContext);

    const history = useHistory();
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(false);
    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(0);
    // const [filter, setFilter] = useState("");
    const [totalCount, setTotalCount] = useState(0);
    const [notifications, setNotifications] = useState<Notifecations[]>([]);
    const [unRead, setUnRead] = useState<any>(false);
    const [type, setType] = useState<any>(location.state);

    useEffect(() => {
        loadData();
    }, [unRead]);

    const AllNotification = () => {
        setIsLoading(true);
        setLimit(0);
        setOffset(0);
        setUnRead(null);
        setType(null);
    };

    const loadData = async () => {
        try {
            setIsLoading(true);
            const result = await getNotifection();
            if (result instanceof Result.Success) {
                await setNotifications(result.data.items);
                setTotalCount(result.data.totalItemCount);
                setOffset(result.data.items.length);
            }
        } catch (error) {
            toast.error("Something went wrong.!");
        }
        setIsLoading(false);
    };

    const getNotifection = async () => {
        const ro: GetNotifectionObject = {
            limit: limit,
            offset: offset,
            // search: filter,
        };
        if (unRead) ro.unread = unRead;
        if (type) ro.type = type.type;
        return await notifecationRepo.getNotifection(ro);
    };

    const getNotifectionMakeRead = async (data: any) => {
        const notify = notifications.find((item) => item.getId() === data);
        if (isLoading || !notify) return;
        const result = await notifecationRepo.getNotifectionMakeRead({
            notification_id: data,
        });
        if (result instanceof Result.Success) {
            setCounts((prev: any) => ({
                ...prev,
                [notify.getType()]: prev[notify.getType()] - 1,
                totalCount: prev["totalCount"] - 1,
            }));
            setNotifications(notifications);
        } else {
            const message = result.message || "Something went wrong";
            toast.error(message);
        }
        setIsLoading(false);
    };

    const getNotifectionMakeReadAll = async () => {
        if (isLoading) return;
        const result = await notifecationRepo.getNotifectionMakeReadAll();
        if (result instanceof Result.Success) {
            const notifications = result.data.items;
            setNotifications(notifications);
            setCounts({
                totalCount: 0,
                cancel_case: 0,
                case_close: 0,
                case_start: 0,
                close_investigation: 0,
                leave_applied: 0,
                location_change_request: 0,
                lodging_expenses_request: 0,
                mrd_request: 0,
                outlocation_travel: 0,
            });
            setIsLoading(false);
            toast.success("All Read Successfully..!");
        } else {
            const message = result.message || "Something went wrong";
            toast.error(message);
        }
    };

    const NotificationRead = async (data: any) => {
        console.log("this", data);
        getNotifectionMakeRead(data);
    };

    const UnRead = () => {
        setLimit(0);
        setOffset(0);
        setUnRead(true);
    };

    const AllNotificationRead = () => {
        setIsLoading(true);
        getNotifectionMakeReadAll();
    };

    const linkPage = (data: any) => {
        switch (data.getType()) {
            case "location_change_request":
                return data.getUnRead() === false ? (
                    <Button
                        color="red"
                        size="mini"
                        onClick={() => history.push("/master/address-change-request")}
                    >
                        View Details
                    </Button>
                ) : (
                    <Button
                        color="green"
                        size="mini"
                        onClick={() => {
                            NotificationRead(data);
                            setTimeout(() => {
                                history.push("/master/address-change-request");
                            }, 100);
                        }}
                    >
                        View Details
                    </Button>
                );
            case "leave_applied":
                return data.getUnRead() === false ? (
                    <Button
                        color="red"
                        size="mini"
                        onClick={() => history.push("/master/leave-request")}
                    >
                        View Details
                    </Button>
                ) : (
                    <Button
                        color="green"
                        size="mini"
                        onClick={() => {
                            NotificationRead(data.getId());
                            setTimeout(() => {
                                history.push("/master/leave-request");
                            }, 100);
                        }}
                    >
                        View Details
                    </Button>
                );
            case "lodging_expenses_request":
                return data.getUnRead() === false ? (
                    <Button
                        color="red"
                        size="mini"
                        onClick={() => history.push("/master/lodging-expenses")}
                    >
                        View Details
                    </Button>
                ) : (
                    <Button
                        color="green"
                        size="mini"
                        onClick={() => {
                            NotificationRead(data.getId());
                            setTimeout(() => {
                                history.push("/master/lodging-expenses");
                            }, 100);
                        }}
                    >
                        View Details
                    </Button>
                );
            case "mrd_request":
                return data.getUnRead() === false ? (
                    <Button
                        color="red"
                        size="mini"
                        onClick={() => history.push("/master/mrd-charges")}
                    >
                        View Details
                    </Button>
                ) : (
                    <Button
                        color="green"
                        size="mini"
                        onClick={() => {
                            NotificationRead(data.getId());
                            setTimeout(() => {
                                history.push("/master/mrd-charges");
                            }, 100);
                        }}
                    >
                        View Details
                    </Button>
                );
            case "cancel_case":
            case "case_start":
            case "close_investigation":
            case "case_close":
                return data.getUnRead() === false ? (
                    <Button
                        color="red"
                        size="mini"
                        onClick={() => history.push(`/case/view/${data.getType_id()}`)}
                    >
                        View Details
                    </Button>
                ) : (
                    <Button
                        color="green"
                        size="mini"
                        onClick={() => {
                            NotificationRead(data.getId());
                            setTimeout(() => {
                                history.push(`/case/view/${data.getType_id()}`);
                            }, 100);
                        }}
                    >
                        View Details
                    </Button>
                );
        }
    };

    const handlePageClick = (event: any) => {
        const offset = event * limit === 0 ? 0 : limit - 10;
        setOffset(offset);
        loadData();
    };

    return (
        <Segment>
            <Header>Notification</Header>
            <Segment loading={isLoading}>
                <div style={{ minHeight: "80vh", justifyContent: "space-between" }}>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div>
                            <Button onClick={() => AllNotification()}>All</Button>
                            <Button onClick={() => UnRead()}>UnRead</Button>
                        </div>
                        <div>
                            <Button onClick={() => AllNotificationRead()}>
                                Make all as read
                            </Button>
                            <Link to="/">
                                <Button>Back</Button>
                            </Link>
                        </div>
                    </div>

                    <Card.Group
                        color="blue"
                        style={{
                            marginTop: "5px",
                            paddingLeft: "160px ",
                            paddingRight: "160px",
                        }}
                    >
                        {notifications.map((item: any) => (
                            <Card
                                fluid
                                color={item.getUnRead() === true ? "green" : "red"}
                                header={item?.getTitle()}
                            >
                                <Card.Content>
                                    <Grid>
                                        <Grid.Column floated="left" width={5}>
                                            <Menu.Item className="header">
                                                <Card.Header>{item.getName()}</Card.Header>
                                            </Menu.Item>
                                            <Menu.Item className="header">
                                                <Card.Meta>{item?.getTitle()}</Card.Meta>
                                            </Menu.Item>
                                        </Grid.Column>
                                        <Grid.Column floated="right" width={5}>
                                            <Menu.Item textAlign="right">
                                                {moment(item.getCreatedAt()).format("LLL")}
                                            </Menu.Item>
                                            <Menu.Item
                                                textAlign="right"
                                                style={{ marginTop: "10px" }}
                                            >
                                                {linkPage(item)}
                                            </Menu.Item>
                                        </Grid.Column>
                                    </Grid>
                                    <Card.Description>{item.getMessage()}</Card.Description>
                                </Card.Content>
                            </Card>
                        ))}
                    </Card.Group>
                    {notifications.length === 0 && (
                        <Grid columns={1} centered divided>
                            <Grid.Row
                                style={{
                                    marginTop: "10px",
                                    minHeight: "50vh",
                                    textAlign: "center",
                                }}
                            >
                                There are no records to display
                            </Grid.Row>
                        </Grid>
                    )}
                </div>
                <div style={{ marginTop: "16px", textAlign: "center" }}>
                    <Pagination
                        boundaryRange={0}
                        defaultActivePage={1}
                        ellipsisItem={null}
                        firstItem={null}
                        lastItem={null}
                        siblingRange={1}
                        onPageChange={(event, data) => handlePageClick(data.activePage)}
                        totalPages={Math.ceil(totalCount / limit)}
                    />
                </div>
            </Segment>
        </Segment>
    );
};

export default NotificationPage;
