// import React, { Component, createRef } from 'react';
// import { Map, GoogleApiWrapper, Marker, InfoWindow } from 'google-maps-react';

// class MapContainer extends Component {
//   mapRef = createRef();
//   geocoder = null;

//   constructor(props) {
//     super(props);
//     this.state = {
//       markerPosition: null,
//       selectedPlace: '',
//       activeMarker: null,
//       showInfoWindow: false,
//     };
//   }

//   componentDidMount() {
//     this.geocoder = new this.props.google.maps.Geocoder();
//     console.log(this.geocoder, "this.geocoder")
//   }

//   onMapReady = (mapProps, map) => {
//     this.mapRef.current = map;
//     // Set initial marker position to default center (India)
//     // const defaultCenter = { lat: 20.5937, lng: 78.9629 };
//     // this.setState({ markerPosition: defaultCenter });
//   };

//   onMarkerClick = (props, marker) => {
//     this.setState({
//       selectedPlace: props.name || '',
//       activeMarker: marker,
//       showInfoWindow: true,
//     });
//   };

//   onInfoWindowClose = () => {
//     this.setState({
//       activeMarker: null,
//       showInfoWindow: false,
//     });
//   };

//   onMapClick = (mapProps, map, clickEvent) => {
//     const { latLng } = clickEvent;
//     const lat = latLng.lat();
//     const lng = latLng.lng();

//     const markerPosition = {
//       lat,
//       lng,
//     };

//     this.setState(
//       {
//         markerPosition,
//       },
//       () => {
//         // Fetch address using Geocoder API
//         this.geocoder.geocode({ location: markerPosition }, (results, status) => {
//           if (status === this.props.google.maps.GeocoderStatus.OK) {
//             if (results[0]) {
//               const formattedAddress = results[0].formatted_address;
//               console.log('Address:', formattedAddress);
//               // Do something with the address
//             }
//           } else {
//             console.log('Geocode was not successful for the following reason:', status);
//           }
//         });
//       }
//     );
//   };

//   render() {
//     const { markerPosition, selectedPlace, activeMarker, showInfoWindow } = this.state;

//     return (
//       <div>
//         <Map
//           google={this.props.google}
//           zoom={14}
//           onReady={this.onMapReady}
//           onClick={this.onMapClick}
//           initialCenter={{ lat: 30.7046, lng: 76.7179 }} // Set the initial center to India
//         >
//           {markerPosition && (
//             <Marker
//               position={markerPosition}
//               onClick={this.onMarkerClick}
//               name="Dropped Pin"
//             />
//           )}

//           <InfoWindow
//             marker={activeMarker}
//             visible={showInfoWindow}
//             onClose={this.onInfoWindowClose}
//           >
//             <div>
//               <h4>{selectedPlace}</h4>
//             </div>
//           </InfoWindow>
//         </Map>
//       </div>
//     );
//   }
// }

// export default GoogleApiWrapper({
//   apiKey: 'AIzaSyC0y2S4-iE2rHkYdyAsglz_qirv0UtpF1s', // Replace with your actual API key
// })(MapContainer);
import React, { Component } from 'react';
import { Map, GoogleApiWrapper, Marker, InfoWindow } from 'google-maps-react';
import { Input, Button } from 'semantic-ui-react'

class MapContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      markerPosition: null,
      selectedPlace: '',
      activeMarker: null,
      showInfoWindow: false,
      autoCompleteValue: ""
    };
    this.autocomplete = null;
  }
  mapRef = React.createRef();

  componentDidMount() {
    this.autocomplete = new this.props.google.maps.places.Autocomplete(
      document.getElementById('location-search-input')
    );
    this.autocomplete.addListener('place_changed', this.onPlaceChanged);
  }

  // onPlaceChanged = () => {
  //   const place = this.autocomplete.getPlace();
  //   if (place.geometry) {
  //     const { lat, lng } = place.geometry.location;

  //     const markerPosition = {
  //       lat: lat(),
  //       lng: lng(),
  //     };

  //     this.setState({ markerPosition });
  //   }
  // };


  onPlaceChanged = () => {
    const place = this.autocomplete.getPlace();
    if (place.geometry) {
      const { lat, lng } = place.geometry.location;

      const markerPosition = {
        lat: lat(),
        lng: lng(),
      };

      this.setState({ markerPosition, autoCompleteValue: place.formatted_address });

      // Set map center and zoom level based on the selected place
      const { google } = this.props;
      const map = this.mapRef.current.map;
      const bounds = new google.maps.LatLngBounds();
      bounds.extend(place.geometry.location);
      map.fitBounds(bounds);
    }
  };




  onMarkerClick = (props, marker) => {
    this.setState({
      selectedPlace: props.name || '',
      activeMarker: marker,
      showInfoWindow: true,
    });
  };

  onInfoWindowClose = () => {
    this.setState({
      activeMarker: null,
      showInfoWindow: false,
    });
  };

  onMapClick = (mapProps, map, clickEvent) => {
    const { latLng } = clickEvent;
    const lat = latLng.lat();
    const lng = latLng.lng();

    const markerPosition = {
      lat,
      lng,
    };

    this.setState({ markerPosition });
    console.log(markerPosition, "markerPosition")
  };

  render() {
    const { markerPosition, selectedPlace, activeMarker, showInfoWindow, autoCompleteValue } = this.state;

    return (
      <div>
        <Input 
        width="100%"
          id="location-search-input"
          type="text"
          placeholder="Search for a location"
          style={{ width: '300px' }}
          value={autoCompleteValue}
          onChange={(e) => this.setState({ autoCompleteValue: e.target.value })}
        />

        <Button color="green" inverted onClick={() => this.props.add(autoCompleteValue,markerPosition)}  style={{ marginLeft: 5 }}>
          Add
        </Button>
        <Button onClick={this.props.cancel} style={{ marginLeft: 4 }}>
          Cancel
        </Button>

        <Map
          google={this.props.google}
          zoom={14}
          onClick={this.onMapClick}
          initialCenter={{ lat: 30.7046, lng: 76.7179 }} // Set the initial center to India
          ref={this.mapRef}
        >
          {markerPosition && (
            <Marker
              position={markerPosition}
              onClick={this.onMarkerClick}
              name="Dropped Pin"
            />
          )}

          <InfoWindow
            marker={activeMarker}
            visible={showInfoWindow}
            onClose={this.onInfoWindowClose}
          >
            <div>
              <h4>{selectedPlace}</h4>
            </div>
          </InfoWindow>
        </Map>
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyBbfTAWLT5-pNsiCoeWqvyljhkZKRPQTPs', // Replace with your actual API key
})(MapContainer);
