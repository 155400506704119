import React from "react";
import ElementResizeObserver from "./../../../lib/ElementResizeObserver";

interface Props {
  id: string;
  onHeightChanged: (newHeight: string | null) => void;
}

class HeaderLayout extends React.Component<Props> {
  private header: HTMLElement | null = null;
  private resizeObserver: ElementResizeObserver | null = null;

  render(): JSX.Element {
    return <header {...this.props} />;
  }

  componentDidMount() {
    this.header = document.getElementById(this.props.id);
    if (this.header === null)
      throw new Error(`Could not find Header(${this.props.id})`);

    this.resizeObserver = ElementResizeObserver.getInstance(
      this._onHeightChange
    ) as ElementResizeObserver;
    this.resizeObserver.observe(this.header);
  }

  componentWillUnmount() {
    if (this.resizeObserver !== null) this.resizeObserver.disconnect();
  }

  private _onHeightChange = () => {
    const headerHeight =
      this.header!.style.height || getComputedStyle(this.header!).height;
    this.props.onHeightChanged(headerHeight);
  };
}

export default HeaderLayout;
