import React from "react";
import User from "../../models/User";
import { Card, Image, Header } from "semantic-ui-react";
import constants from "./../../common/constants";

interface Props {
  model: User;
}

const UserProfileCard = (props: Props) => {
  const { model } = props;
  const profilePicURL =
    model.getProfilePicURL() || constants.defaultProfileAvatarURL;

  return (
    <Card>
      <Card.Content>
        <Image avatar floated="left" src={profilePicURL} />
        <Header as="h3" style={{ marginTop: 0 }}>
          {model.getName()}
          <Header.Subheader>{model.getRole().getTitle()}</Header.Subheader>
        </Header>
      </Card.Content>
    </Card>
  );
};

export default UserProfileCard;
