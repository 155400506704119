import DataMapper from './DataMapper'
import UserAttendanceRM from '../responsemodel/UserAttendanceRM'
import UserAttendance from '../../models/UserAttendance'
import UserDataMapper from './UserDataMapper'

export default class UserAttendanceDataMapper extends DataMapper<UserAttendanceRM, UserAttendance> {
  private userDataMapper = new UserDataMapper()

  map = (rm: UserAttendanceRM) => {
    return new UserAttendance(
      rm.id,
      this.userDataMapper.map(rm.user),
      rm.month,
      rm.year,
      {
        present: rm.summary.present,
        absent: rm.summary.absent,
        leave: rm.summary.leave,
        halfLeave: rm.summary.half_leave,
        shortLeave: rm.summary.short_leave,
        compensation: rm.summary.compensation,
        holiday: rm.summary.holiday,
        offroll: rm.summary.off_roll,
        totalDayCount: rm.summary.total,
      },
      rm.attendance
    )
  }
}
