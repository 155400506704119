import React, { useState, useContext, useEffect } from 'react'
import { Form, Header, Input, Button } from 'semantic-ui-react'
import FormLabel from '../../common/FormLabel'
import SettingsPageContext from './SettingsPageContext'
import Result from '../../../common/repository/Result'
import { toast } from '../../common/Toast'
import validator from '../../../common/validator'

function LeavesSegment() {
    const ctx = useContext(SettingsPageContext)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [paidLeavesCount, setPaidLeavesCount] = useState('')
    const [shortLeaveWageDeductPercentage, setShortLeaveWageDeductPercentage] = useState('')
    const [leaveAccumulationCount, setLeaveAccumulationCount] = useState('')
    const [leaveAccumulationBegin, setLeaveAccumulationBegin] = useState('')

    useEffect(effectInitializeValues, [ctx.settings])
    useEffect(() => { effectOnSubmit() }, [isSubmitting])

    return (
        <Form loading={isSubmitting}>
            <Header as='h3' color='grey'>Basic</Header>
            <Form.Group inline>
                <Form.Field>
                    <FormLabel width='150px'>Paid Leaves</FormLabel>
                    <Input
                        label={{ basic: true, content: 'Count' }}
                        labelPosition='right'
                        value={paidLeavesCount}
                        onChange={(_, { value }) => { if (validator.number.isValid(value)) setPaidLeavesCount(value) }} />
                </Form.Field>
            </Form.Group>

            <Form.Group inline>
                <Form.Field>
                    <FormLabel width='150px'>Short Leave Wage Deduct.</FormLabel>
                    <Input
                        label={{ basic: true, content: '%' }}
                        labelPosition='right'
                        value={shortLeaveWageDeductPercentage}
                        onChange={(_, { value }) => { if (validator.number.isValid(value)) setShortLeaveWageDeductPercentage(value) }} />
                </Form.Field>
            </Form.Group>
            <Form.Group inline>
                <Form.Field>
                    <FormLabel width='150px'>Leave Accumulation</FormLabel>
                    <Input
                        label={{ basic: true, content: 'Count' }}
                        labelPosition='right'
                        value={leaveAccumulationCount}
                        onChange={(_, { value }) => { if (validator.number.isValid(value)) setLeaveAccumulationCount(value) }} />
                </Form.Field>
            </Form.Group>
            <Form.Group inline>
                <Form.Field>
                    <FormLabel width='150px'>Leave Accumulation Init.</FormLabel>
                    <Input
                        label={{ basic: true, content: 'Count' }}
                        labelPosition='right'
                        value={leaveAccumulationBegin}
                        onChange={(_, { value }) => { if (validator.number.isValid(value)) setLeaveAccumulationBegin(value) }} />
                </Form.Field>
            </Form.Group>

            <Button 
                primary
                onClick={() => setIsSubmitting(true)}
                disabled={isSubmitButtonDisabled()}>
                Submit
            </Button>
        </Form>
    )

    function effectInitializeValues() {
        if (ctx.settings === null) return

        const leaves = ctx.settings.getLeaves()
        setPaidLeavesCount(leaves.paidLeavesCount && leaves.paidLeavesCount.toString() || '')
        setShortLeaveWageDeductPercentage(leaves.shortLeaveWageDeductPercentage && leaves.shortLeaveWageDeductPercentage.toString() || '')
        setLeaveAccumulationCount(leaves.leaveAccumulationCount && leaves.leaveAccumulationCount.toString() || '')
        setLeaveAccumulationBegin(leaves.leaveAccumulationBegining && leaves.leaveAccumulationBegining.toString() || '')
    }

    async function effectOnSubmit() {
        if (ctx.settings === null || !isSubmitting) return

        const result = await ctx.settingsRepo.updateSettings({
            id: ctx.settings.getId(),
            leaves: {
                paid_leaves: parseFloat(paidLeavesCount),
                short_leave_wage: parseFloat(shortLeaveWageDeductPercentage),
                accumulation: parseFloat(leaveAccumulationCount),
                accumulation_start: parseFloat(leaveAccumulationBegin)
            }
        })

        if (result instanceof Result.Success) {
            const settings = result.data
            ctx.setSettings(settings)
            toast.success('Successfully updated Settings')
        } else {
            const message = result.message || 'Something went wrong'
            toast.error(message)
        }
        setIsSubmitting(false)
    }

    function isSubmitButtonDisabled() {
        return (
            isSubmitting ||
            paidLeavesCount.trim().length === 0 ||
            shortLeaveWageDeductPercentage.trim().length === 0 ||
            leaveAccumulationCount.trim().length === 0 ||
            leaveAccumulationBegin.trim().length === 0
        )
    }
}

export default LeavesSegment