import ActionColumnRenderer from '../../../app/Table/renderer/ActionColumnRenderer'
import CaseType from '../../../../models/CaseType'

export default class CaseTypeColumnRenderer extends ActionColumnRenderer<CaseType> {
  getColumns() {
    return [
      {
        name: 'Title',
        selector: 'title',
      },
      {
        name: 'Internal TAT',
        selector: 'internalTAT',
        format: (row: {}) => {
          if (row instanceof CaseType) {
            const [days, hours] = CaseType.getTATPair(row.getInternalTAT())
            if (days === 0) return `${hours} hours`
            return `${days} days, ${hours} hours`
          }
        },
      },
      {
        name: 'Investigator TAT',
        selector: 'investigatorTAT',
        format: (row: {}) => {
          if (row instanceof CaseType) {
            const [days, hours] = CaseType.getTATPair(row.getInvestigatorTAT())
            if (days === 0) return `${hours} hours`
            return `${days} days, ${hours} hours`
          }
        },
      },
      {
        name: 'Initial Portion Cap',
        selector: 'initialCasePortionCapping',
      },
      {
        name: 'Actions',
        selector: 'id',
        cell: this.renderActionColumn,
      },
    ]
  }
}
