import ActionColumnRenderer from '../../../app/Table/renderer/ActionColumnRenderer'
import MrdCharges from '../../../../models/MrdCharges'
import InvestigatorActivity from '../../../../models/InvestigatorActivity'

export default class InvestigatorActivityColumnRenderer extends ActionColumnRenderer<InvestigatorActivity> {
    getColumns() {
        return [
            {
                name: 'Investigator Name',
                selector: 'investigator_name',
            },
            {
                name: 'Distance(KM)',
                selector: 'distance',
                format: (row: {}) => {
                    if (row instanceof InvestigatorActivity) {
                        const reason = row.getDistance()
                        if (reason !== null) return `${reason}`
                    }
                },
            },

            {
                name: 'Actions',
                selector: '_id',
                grow: 0,
                width: '180px',
                cell: this.renderActionColumn,
            },
        ]
    }
}
