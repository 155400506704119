import DataMapper from './DataMapper'
import SalaryRM from '../responsemodel/SalaryRM'
import Salary from '../../models/Salary'
import UserDataMapper from './UserDataMapper'

class SalaryDataMapper extends DataMapper<SalaryRM, Salary> {
  private userDataMapper = new UserDataMapper()
  map = (rm: SalaryRM) => {
    return new Salary(
      this.userDataMapper.map(rm.user),
      rm.month,
      rm.year,
      {
        basicSalary: rm.earnings.basic_salary,
        hra: rm.earnings.hra,
        miAllowance: rm.earnings.mi_allowance,
        specialAllowance: rm.earnings.special_allowance,
        conveyanceAllowance: rm.earnings.conveyance_allowance,
        otherAllowance: rm.earnings.other_allowance,
        otherAllowanceRemarks: rm.earnings.other_allowance_remarks,
      },
      {
        travelAllowance: rm.reimbursements.travel_allowance,
        documentExpense: rm.reimbursements.document_expense,
        cityAllowance: rm.reimbursements.city_allowance,
        otherExpense: rm.reimbursements.other_expense,
        otherExpenseRemarks: rm.reimbursements.other_expense_remarks,
      },
      {
        caseAllowance: rm.other_benefits.case_allowance,
        incentive: rm.other_benefits.incentive,
      },
      {
        leaveDeduction: rm.deductions.leave_deduction,
        otherDeduction: rm.deductions.other_deduction,
        otherDeductionRemarks: rm.deductions.other_deduction_remarks,
        bonus: rm.deductions.bonus,
      },
      {
        paidLeaves: rm.other.paid_leaves,
        unpaidLeaves: rm.other.unpaid_leaves,
        availableLeaves: rm.other.available_leaves,
        totalCases: rm.other.total_cases,
      }
    )
  }
}

export default SalaryDataMapper
