import React from 'react'
import Page from '../../../layout/Page'
import { Modal, Icon, Button } from 'semantic-ui-react'
import RepudiationGroundRepository from '../../../../common/repository/RepudiationGroundRepository'
import Result from '../../../../common/repository/Result'
import { toast } from '../../../common/Toast'
import RemovalConfirmationForm from '../../../common/RemovalConfirmationForm'
import Table from '../../../app/Table'
import RepudiationGroundColumnRenderer from './RepudiationGroundColumnRenderer'
import RepudiationGround from '../../../../models/RepudiationGround'
import RequirePermission from '../../../base/RequirePermission'
import permissions from '../../../../common/permissions'
import RepudiationGroundForm from "../../../common/RepudiationGroundForm"

interface State {
    loading: boolean
    filter: string
    limit: number
    offset: number
    totalCount: number
    repudiationGround: RepudiationGround[]

    add_modalOpen: boolean

    update_modalOpen: boolean
    update_model: RepudiationGround | null

    remove_modalOpen: boolean
    remove_model: RepudiationGround | null
}

class RepudiationGroundPage extends React.Component<{}, State> {
    private RepudiationGroundRepository = new RepudiationGroundRepository()
    private columnRenderer = new RepudiationGroundColumnRenderer(
        (model: RepudiationGround) => {
            return (
                <Button.Group size="mini">
                    <Button onClick={() => this.setState({ update_model: model, update_modalOpen: true })}>Open</Button>
                    <Button negative onClick={() => this.setState({ remove_model: model, remove_modalOpen: true })}>
                        Remove
                    </Button>
                </Button.Group>
            )
        },
        (obj: any) => true
    )

    constructor(props: {}) {
        super(props)
        this.state = {
            loading: false,
            filter: '',
            limit: 10,
            offset: 0,
            totalCount: 0,
            repudiationGround: [],
            add_modalOpen: false,
            update_modalOpen: false,
            update_model: null,
            remove_modalOpen: false,
            remove_model: null,
        }
    }

    componentDidMount() {
        this.load()
    }

    private onRowsPerPageChange = (page: number, rowsPerPage: number) => {
        const offset = (page - 1) * rowsPerPage,
            limit = offset + rowsPerPage
        this.setState({ offset, limit }, this.load)
    }
    private load = () => {
        const { offset } = this.state
        this.setState({ loading: true }, async () => {
            const result = await this.getHospitals()
            if (result instanceof Result.Success) {
                if (offset === 0) {
                    const repudiationGround = result.data.items
                    const totalCount = result.data.totalItemCount
                    const offset = repudiationGround.length
                    this.setState({ loading: false, offset, repudiationGround, totalCount })
                } else {
                    const repudiationGround = [...this.state.repudiationGround, ...result.data.items]
                    const offset = repudiationGround.length
                    this.setState({ loading: false, offset, repudiationGround })
                }
            } else {
                this.setState({ loading: false }, () => {
                    const message = result.message || 'Something went wrong'
                    toast.error(message)
                })
            }
        })
    }

    private getHospitals = async () => {
        const ro = {
            search: this.state.filter,
            limit: this.state.limit,
            offset: this.state.offset,
        }
        return await this.RepudiationGroundRepository.getRepuditationGround(ro)
    }

    private setFilter = (filter: string) => {
        this.setState({ filter, offset: 0 }, this.load)
    }

    private onRepudiationGroundAdded = (hospital: RepudiationGround) => {
        this.setState((currentState) => {
            const repudiationGround = [hospital, ...currentState.repudiationGround]
            const totalCount = currentState.totalCount + 1
            return { repudiationGround, totalCount, add_modalOpen: false }
        })
    }

    private onRepudiationGroundUpdated = (hospital: RepudiationGround) => {
        const repudiationGround = this.state.repudiationGround
        const index = repudiationGround.findIndex((it) => it.getId() === hospital.getId())
        if (index !== -1) repudiationGround[index] = hospital
        this.setState({ repudiationGround: [...repudiationGround], update_modalOpen: false, update_model: null })
    }

    private removeRepudiationGround = async () => {
        const id = this.state.remove_model!.getId()
        const result = await this.RepudiationGroundRepository.removeRepuditationGround(id)
        if (result instanceof Result.Success) {
            const repudiationGround = this.state.repudiationGround
            let totalCount = this.state.totalCount
            const index = repudiationGround.findIndex((it) => it.getId() === id)
            if (index !== -1) {
                repudiationGround.splice(index, 1)
                totalCount--
            }
            this.setState({ repudiationGround: [...repudiationGround], totalCount, remove_modalOpen: false, remove_model: null }, () => toast.success('Repudiation ground removed successfully'))
        } else {
            const message = result.message || 'Something went wrong'
            toast.error(message)
        }
    }

    render() {
        return (
            <Page title="Repudiation Ground" description="Manage Repudiation Grounds">
                {/* Add Repudiation Ground Modal */}
                <Modal
                    open={this.state.add_modalOpen}
                    onClose={() => this.setState({ add_modalOpen: false })}
                    size="tiny"
                    closeIcon
                    closeOnDimmerClick={false}
                    closeOnEscape={false}
                    trigger={
                        <Button icon labelPosition="left" color="green" onClick={() => this.setState({ add_modalOpen: true })}>
                            <Icon name="add" />
                            Add New
                        </Button>
                    }
                >
                    <Modal.Header>Add New Repudiation Ground</Modal.Header>
                    <Modal.Content>
                        <RepudiationGroundForm onSuccess={this.onRepudiationGroundAdded} />
                    </Modal.Content>
                </Modal>
                {/* Add Repudiation Ground Modal */}

                {/* Update Repudiation Ground Modal */}
                <Modal open={this.state.update_modalOpen} onClose={() => this.setState({ update_modalOpen: false, update_model: null })} size="tiny" closeIcon closeOnDimmerClick={false} closeOnEscape={false}>
                    <Modal.Header>Update Repudiation Ground</Modal.Header>
                    <Modal.Content>
                        <RepudiationGroundForm model={this.state.update_model || undefined} onSuccess={this.onRepudiationGroundUpdated} />
                    </Modal.Content>
                </Modal>
                {/* Update Repudiation Ground Modal */}

                {/* Remove Repudiation Ground Modal */}
                <Modal open={this.state.remove_modalOpen} onClose={() => this.setState({ remove_modalOpen: false, remove_model: null })} size="mini" closeIcon closeOnDimmerClick={false} closeOnEscape={false}>
                    <Modal.Header>Confirmation</Modal.Header>
                    <Modal.Content>
                        <RemovalConfirmationForm title="Are you sure you want to remove this?" remove={this.removeRepudiationGround} cancel={() => this.setState({ remove_modalOpen: false, remove_model: null })} />
                    </Modal.Content>
                </Modal>
                {/* Remove Repudiation Ground Modal */}

                <Table
                    renderer={this.columnRenderer}
                    data={this.state.repudiationGround}
                    totalDataCount={this.state.totalCount}
                    loading={this.state.loading}
                    load={this.load}
                    onRowsPerPageChange={this.onRowsPerPageChange}
                    onSearch={this.setFilter}
                    onClear={() => this.setFilter('')}
                />
            </Page>
        )
    }
}

export default (props: any) => {
    return (
        <RequirePermission permission={permissions.Master.CRUD}>
            <RepudiationGroundPage {...props} />
        </RequirePermission>
    )
}
