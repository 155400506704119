import DocumentPendencyRM from '../responsemodel/DocumentPendencyRM'
import DocumentPendency from '../../models/DocumentPendency'
import DataMapper from './DataMapperBilling'

export default class DocumentPendencyDataMapper extends DataMapper<DocumentPendencyRM, DocumentPendency[]> {
  map = (rm: DocumentPendencyRM) => {
    let documentPendency: DocumentPendency[] = []

    rm.investigation.forEach((inv) => {
      let tmp = new DocumentPendency(
        rm.id,
        rm.case_id,
        rm.claim_number,
        rm.client_id,
        rm.client_name,
        rm.close_date,

        inv.investigation_id,
        inv.investigator_emp_id,
        inv.investigator_id,
        inv.investigator_name,
        inv.document_charges,
        inv.approved_charges,
        inv.extra_charges,
        inv.document_receipts_arrived,
        inv.approved_receipts_arrived,
        inv.extra_receipts_arrived,
        inv.evidence_arrived,
        inv.case_portion || '-',
        inv.investigation_location || '-',
        inv.travel_allowance || '-'
      )
      documentPendency.push(tmp)
    })

    return documentPendency
  }
}
