import ProtectedAPIRepository from './ProtectedAPIRepository'
import SalaryRM from '../responsemodel/SalaryRM'
import Result from './Result'
import SalaryDataMapper from '../datamapper/SalaryDataMapper'
import APIResponseModel from './APIResponseModel'

class SalaryRepository extends ProtectedAPIRepository {
  private dataMapper = new SalaryDataMapper()

  getSalary = async (data: { userId: string; month: string; year: string }) => {
    const response = await this.post<APIResponseModel<SalaryRM>>('/salary/get', { user_id: data.userId, month: data.month, year: data.year })
    if (response instanceof Result.Success) {
      const data: APIResponseModel<SalaryRM> = response.data
      if (data.data !== null) {
        const salaryData = this.dataMapper.map(data.data.items[0])
        return new Result.Success(salaryData)
      } else {
        return new Result.Error(0, 'Data is null')
      }
    } else {
      return response
    }
  }

  // confirmSalary = async (ro: SalaryRequestObject) => {
  //     const response = await this.post<APIResponseModel<SalaryRM>>('/salary/print', ro);
  //     console.log(response)
  //     if (response instanceof Result.Success) {
  //        // const data: APIResponseModel<SalaryRM> = response.data;
  //         const data = response.data
  //         if (data.data !== null) {
  //             const settings = this.dataMapper.map(data.data.items[0])
  //             return new Result.Success(settings)
  //         } else {
  //             return new Result.Error(0, 'Data is null')
  //         }
  //     } else {
  //         return response
  //     }
  // }
  //
}

export default SalaryRepository

// export interface SalaryRequestObject {
//
// 	month: number,
// 	year: number,
//
// 	earning_basic_salary: number
// 	earning_hra: number
// 	earning_mi_allowance: number
// 	earning_conveyance_allowance?: number
// 	earning_special_allowance: number
// 	earning_incentives: number
//
// 	reimbursement_travel_allowance: number
// 	reimbursement_other_expense: number
// 	reimbursement_document_expense?: number
// 	reimbursement_case_allowance?: number
// 	reimbursement_dearnessAllowance?: number
//
// 	leave_paid_leaves: number
// 	leave_unpaid_leaves: number
// 	leave_available_leaves: number
// 	leave_total_deduction: number
//
// 	bonus: number
//
// }
