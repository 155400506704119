import React from "react";
import constants from "../../../../common/constants";
import ClaimAnalystLocationInput from "./ClaimAnalystLocationInput";
import Location from "../../../../models/Location";
import { Divider, Header } from "semantic-ui-react";
import InvestigatorLocationInput from "./InvestigatorLocationInput";

interface Props {
  value: UserLocationInputValue[][];
  onChange(value: UserLocationInputValue[][]): void;
  roleTitle: string;
  locations: Location[];
}

const UserLocationInput = ({ roleTitle, ...rest }: Props) => {
  let content = null;

  switch (roleTitle) {
    case constants.roleTitle.investigator:
      content = <InvestigatorLocationInput {...rest} />;
      break;
    case constants.roleTitle.ca:
      content = <ClaimAnalystLocationInput {...rest} />;
      break;
    default:
      content = null;
      break;
  }

  if (content === null) {
    return null;
  } else {
    return (
      <>
        <Divider />
        <Header as="h4" color="grey">
          Work Location
        </Header>
        {content}
      </>
    );
  }
};

export interface UserLocationInputValue {
  state: string;
  district: string;
}

UserLocationInput.defaultProps = {
  value: [],
  onChange: () => {},
};

export default React.memo(UserLocationInput);
