import React, { Component } from "react";
import Reminder from "../../../../../models/Reminder";
import styled from "styled-components";
import { Header, Button } from "semantic-ui-react";
import CompleteCase from "../../../../../models/CompleteCase";
import CaseRepository from "../../../../../common/repository/CaseRepository";
import Result from "../../../../../common/repository/Result";
import { toast } from "../../../../common/Toast";

const Container = styled.div`
  padding: 4px 16px;

  background: white;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
`;

interface Props {
  caseModel: CompleteCase;
  model: Reminder;
  caseRepo: CaseRepository;
  updateModel?(c: CompleteCase): void;
}

interface State {
  loading: boolean;
}

class ReminderListItem extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  onClose = () => {
    const { model, caseModel, caseRepo, updateModel } = this.props;
    this.setState({ loading: true }, async () => {
      const result = await caseRepo.closeReminder({
        case_id: caseModel.getId(),
        remainder_id: model.getId(),
      });

      if (result instanceof Result.Success) {
        this.setState({ loading: false }, () => {
          const c = result.data as CompleteCase;
          if (updateModel) updateModel(c);
        });
      } else {
        this.setState({ loading: false }, () => {
          const message = result.message || "Something went wrong";
          toast.error(message);
        });
      }
    });
  };

  render() {
    const { model } = this.props;
    let closedContent = null;
    if (!model.getActive() && model.getClosedBy()) {
      let closedBy = model.getClosedBy()!!.getName();
      let on = model.getClosedOn()!!;
      closedContent = (
        <div>
          Closed By: {closedBy} on {on}
        </div>
      );
    }

    return (
      <Container>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Header as="h5" style={{ margin: 0 }}>
            {model.getMadeBy().getName()}
          </Header>
          <div>{model.getMadeOn()}</div>
        </div>

        {model.getActive() && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div>Time remaining: {model.getValidUpto()}</div>
            <Button
              size="mini"
              disabled={this.state.loading}
              loading={this.state.loading}
              onClick={this.onClose}
            >
              Close
            </Button>
          </div>
        )}

        {!model.getActive() && closedContent}

        {model.getText()}
      </Container>
    );
  }
}

export default ReminderListItem;
