import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import { Provider } from 'react-redux'
import configureStore, { AppState } from './redux'
// import * as serviceWorker from './serviceWorker'


ReactDOM.render(
    <Provider store={configureStore()}>
        <App />
    </Provider>, 
    document.getElementById('root')
)

// serviceWorker.unregister();
