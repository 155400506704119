import DataMapper from './DataMapper'
import InvoiceSectionRM from '../responsemodel/InvoiceSectionRM'
import InvoiceSection, { InvoiceSection_Invoice } from '../../models/InvoiceSection'

export default class InvoiceSectionDataMapper extends DataMapper<InvoiceSectionRM, InvoiceSection> {
  map = (rm: InvoiceSectionRM) => {
    return new InvoiceSection(
      rm.id,
      rm.case_id,
      rm.claim_number,
      rm.client_id,
      rm.client_name,
      rm.case_type_id,
      rm.case_type_title,
      rm.close_date,
      rm.document_expense,
      rm.approved_expense,
      rm.extra_expense,

      rm.invoice != null
        ? new InvoiceSection_Invoice(
            rm.invoice.invoice_number,
            rm.invoice.invoice_date,
            rm.invoice.professional_fee,
            rm.invoice.document_expense,
            rm.invoice.approved_expense,
            rm.invoice.other_expense,
            rm.invoice.incentive,
            rm.invoice.deduction,
            rm.invoice.gst,
            rm.invoice.invoice_url
          )
        : null
    )
  }
}
