
import ProtectedAPIRepository from './ProtectedAPIRepository'
import APIResponseModel from './APIResponseModel'
import Result from './Result'
import LodgingExpensesDataMapper from '../datamapper/LodgingExpensesDataMapper'
import LodgingExpensesRM from '../responsemodel/LodgingExpensesRM'

export default class LodgingExpensesRepository extends ProtectedAPIRepository {
    private LodgingExpensesDataMapper = new LodgingExpensesDataMapper()

    getLodgingExpenses = async (ro?: GetLodgingExpensesObject) => {
        const response = await this.post<APIResponseModel<LodgingExpensesRM>>('/case/getLodgingExpenses', ro)
        if (response instanceof Result.Success) {
            const data: APIResponseModel<LodgingExpensesRM> = response.data
            if (data.data !== null) {
                const MrdCharges = data.data.items.map((it) => this.LodgingExpensesDataMapper.map(it))
                return new Result.Success({
                    totalItemCount: data.data.total_item_count,
                    items: MrdCharges,
                })
            } else {
                return new Result.Error(0, 'Data is null')
            }
        } else {
            return response
        }
    }


    UpdateLodgingExpenses = async (ro: updateLodgingExpensesObject) => {
        const response = await this.post<APIResponseModel<LodgingExpensesRM>>('/case/updateLodgingExpenses', ro)
        if (response instanceof Result.Success) {
            const data: APIResponseModel<LodgingExpensesRM> = response.data
            if (data.data !== null) {
                const MrdCharges = this.LodgingExpensesDataMapper.map(data.data.items[0])
                return new Result.Success(MrdCharges)
            } else {
                return new Result.Error(0, 'Data is null')
            }
        } else {
            return response
        }
    }

    // removeState = async (StateId: string) => {
    //     const response = await this.delete<APIResponseModel<null>>(`/state/${StateId}`)
    //     return response
    // }

}


export interface GetLodgingExpensesObject {
    search?: string
    limit?: number
    offset?: number
    status?: string
}

export interface updateLodgingExpensesObject {
    id: string
    status: string
}