import React, { Component } from "react";
import { Form, Button } from "semantic-ui-react";
import CompleteCase from "../../../../../../models/CompleteCase";
import CaseRepository from "../../../../../../common/repository/CaseRepository";
import Result from "../../../../../../common/repository/Result";
import { toast } from "../../../../../common/Toast";

interface Props {
  model: CompleteCase;
  onSuccess?(c: CompleteCase): void;
}

interface State {
  loading: boolean;
  feedback: string;
  documents: File[];
}

class PushBackForm extends Component<Props, State> {
  private caseRepo = new CaseRepository();

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      feedback: "",
      documents: [],
    };
  }

  onSubmit = () => {
    this.setState({ loading: true }, async () => {
      const { model, onSuccess } = this.props;
      const result = await this.caseRepo.pushback({
        case_id: model.getId(),
        triggers: this.state.feedback,
        documents: this.state.documents,
      });

      if (result instanceof Result.Success) {
        const c = result.data;
        this.setState({ loading: false }, () => {
          if (onSuccess) onSuccess(c as CompleteCase);
        });
      } else {
        this.setState({ loading: false }, () => {
          const message = result.message || "Something went wrong";
          toast.error(message);
        });
      }
    });
  };

  render() {
    return (
      <Form loading={this.state.loading}>
        <Form.TextArea
          label="Feedback (Re-work Triggers)"
          value={this.state.feedback}
          onChange={(_, { value }) =>
            this.setState({ feedback: value as string })
          }
        />

        <Form.Input
          type="file"
          label="Documents"
          accept="image/*, .pdf, .doc, .docx, .csv, .xls, .xlsx"
          multiple
          onChange={(e) => {
            const files = e.target.files;
            if (files !== null) {
              let fileArr: File[] = [];
              for (let i = 0; i < files.length; i++) fileArr.push(files[i]);
              this.setState({ documents: fileArr });
            } else {
              this.setState({ documents: [] });
            }
          }}
        />

        <Button
          positive
          disabled={
            this.state.loading || this.state.feedback.trim().length === 0
          }
          loading={this.state.loading}
          onClick={this.onSubmit}
        >
          Submit
        </Button>
      </Form>
    );
  }
}

export default PushBackForm;
