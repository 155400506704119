import React from 'react'
import Page from '../../../layout/Page'
import { Modal, Icon, Button, DropdownItemProps, Segment, Header, Form } from 'semantic-ui-react'
import Result from '../../../../common/repository/Result'
import { toast } from '../../../common/Toast'
import Table from '../../../app/Table'
import RequirePermission from '../../../base/RequirePermission'
import permissions from '../../../../common/permissions'
import Filters from './Filters'
import FilterPageContext from './FilterPageContext'
import MrdRepository, { GetMRD_ChargesObject } from "../../../../common/repository/MrdRepository"
import MrdChargeColumnRenderer from "./MrdChargesColumnRenderer"
import MrdCharges from "../../../../models/MrdCharges"
import MrdApproveForn from "./MrdApproveForm"
import config from '../../../../common/repository/config'

const statusData = ["Pending", "Accepted", "Rejected"]
const statusDatas = statusData.map((it) => {
    return { text: it, value: it, key: it };
});

interface State {
    loading: boolean
    filter: string
    limit: number
    offset: number
    totalCount: number
    mrdCharge: MrdCharges[]
    update_modalOpen: boolean
    update_model: MrdCharges | null
    remove_modalOpen: boolean
    remove_model: MrdCharges | null
    loadingEssentialData: boolean
    statusFilter: string
    statusOptions: DropdownItemProps[]
}
interface Props {
    ismodel: MrdCharges;
}

class MrdChardesPage extends React.Component<Props, State> {

    private MrdRepository = new MrdRepository()

    private columnRenderer = new MrdChargeColumnRenderer(
        (model: MrdCharges) => {
            let receipt = <a className={"ui button"}>NA</a>;
            if (
                model.getMrd_receipt() != null
                    ? model.getMrd_receipt()
                        ? true
                        : false
                    : false
            ) {
                receipt = (
                    <a
                        className={"ui primary button"}
                        href={`${config.mrdRecipt}${model.getMrd_receipt()}`}
                        target={"_blank"}
                    >
                        View
                    </a>
                );
            }


            return (<>
                {receipt}
                {model.getAccepted() == "Pending" ? <Button.Group >
                    <Button onClick={() => this.setState({ update_modalOpen: true, update_model: model })}>Approved</Button>
                    <Button negative onClick={() => this.setState({ remove_modalOpen: true, remove_model: model })}>Decline</Button>
                </Button.Group> :
                    (model.getAccepted() == "Accepted" ? <Button color="green">{model.getAccepted()} </Button> : <Button color="red">{model.getAccepted()}</Button>)}
            </>)
        },
        (obj: any) => true
    )

    constructor(props: any) {
        super(props)

        this.state = {
            loading: false,
            limit: 10,
            offset: 0,
            filter: '',
            totalCount: 0,
            mrdCharge: [],
            update_modalOpen: false,
            update_model: null,
            remove_modalOpen: false,
            remove_model: null,

            loadingEssentialData: true,
            statusFilter: '',
            statusOptions: statusDatas,
        }
        console.log(this.props.ismodel?.getId(), "rops")
    }

    async componentDidMount() {
        this.loadData()
        // await this.effectLoadEssentialData()
    }
    private onRowsPerPageChange = (page: number, rowsPerPage: number) => {
        // console.log('onRowsPerPageChange', page, rowsPerPage)
        const offset = (page - 1) * rowsPerPage,
            limit = offset + rowsPerPage
        this.setState({ offset, limit }, this.loadData)
    }

    private setFilter = (filter: string) => {
        this.setState({ filter, offset: 0 }, this.loadData)
    }

    private clearAllFilters = async () => {
        await this.setState({
            limit: 0,
            offset: 0,
            statusFilter: '',
        })
        await this.loadData()
    }

    private getMrdCharges = async () => {
        const ro: GetMRD_ChargesObject = {
            limit: this.state.limit,
            offset: this.state.offset,
            search: this.state.filter,
        }

        if (this.state.statusFilter) ro.accepted = this.state.statusFilter

        return await this.MrdRepository.getMrdCharges(ro)
    }

    private loadData = async () => {
        if (this.state.statusFilter) {
            await this.setState({ offset: 0 })
        }
        let offset = this.state.offset

        this.setState({ loading: true }, async () => {
            const result = await this.getMrdCharges()

            if (result instanceof Result.Success) {
                if (offset === 0) {
                    //if offset 0 initialize as new list
                    const mrdCharge = result.data.items
                    const totalCount = result.data.totalItemCount
                    await this.setState({
                        loading: false,
                        offset: mrdCharge.length,
                        mrdCharge,
                        totalCount: totalCount,
                    })
                } else {
                    //else check for duplicate items in the new data
                    // and then concat with the old
                    const mrdCharge = [...this.state.mrdCharge, ...result.data.items]
                    const offset = mrdCharge.length
                    this.setState({ loading: false, offset, mrdCharge })
                }
            } else {
                const message = result.message || 'Something went wrong'
                await this.setState({ loading: false }, () => toast.error(message))
            }
        })
    }

    private UpdateMrdCharge = async (state: MrdCharges) => {
        const mrdCharge = this.state.mrdCharge
        const index = mrdCharge.findIndex((it) => it.getId() === state.getId())
        let totalCount = this.state.totalCount
        console.log(totalCount, "1")
        if (index !== -1) mrdCharge[index] = state
        console.log(totalCount, "2")
        if (index !== -1) {
            mrdCharge.splice(index, 1)
            totalCount--
        }
        
        this.setState({ mrdCharge: [...mrdCharge], totalCount, update_modalOpen: false, update_model: null, remove_modalOpen: false, remove_model: null })
        this.loadData()
    }

    private setStatsFilter = (val: string) => this.setState({ statusFilter: val })

    render() {
        return (
            <FilterPageContext.Provider
                value={{
                    options: {
                        statusData: this.state.statusOptions,
                    },
                    filters: {
                        status: this.state.statusFilter,
                        setStatus: this.setStatsFilter,
                        applyFilters: this.loadData,
                        clearAllFilters: this.clearAllFilters,
                    },
                }}
            >
                <Page title="Mrd Charges" description="Manage Mrd Charges">

                    <Segment>
                        <Filters />
                    </Segment>
                    {/* Approve Modal */}
                    <Modal open={this.state.update_modalOpen} onClose={() => this.setState({ update_modalOpen: false, update_model: null })} size="mini" closeIcon closeOnDimmerClick={false} closeOnEscape={false}>
                        <Modal.Header>Confirmation</Modal.Header>
                        <Modal.Content>
                            <MrdApproveForn model={this.state.update_model || undefined} onSuccess={this.UpdateMrdCharge} status={"Approvel"} />
                        </Modal.Content>
                    </Modal>
                    {/* Approve Modal */}

                    {/* Decline */}
                    <Modal open={this.state.remove_modalOpen} onClose={() => this.setState({ remove_modalOpen: false, remove_model: null })} size="mini" closeIcon closeOnDimmerClick={false} closeOnEscape={false}>
                        <Modal.Header>Confirmation</Modal.Header>
                        <Modal.Content>
                            <MrdApproveForn model={this.state.remove_model || undefined} onSuccess={this.UpdateMrdCharge} status={"Decline"} />
                        </Modal.Content>
                    </Modal>
                    {/* Decline */}
                    <Table
                        renderer={this.columnRenderer}
                        data={this.state.mrdCharge}
                        totalDataCount={this.state.totalCount}
                        loading={this.state.loading}
                        load={this.loadData}
                        onRowsPerPageChange={this.onRowsPerPageChange}
                        onSearch={this.setFilter}
                        onClear={() => this.setFilter('')}
                    />
                </Page>
            </FilterPageContext.Provider>
        )
    }
}

export default (props: any) => {
    return (
        <RequirePermission permission={permissions.Master.CRUD}>
            <MrdChardesPage {...props} />
        </RequirePermission>
    )
}
