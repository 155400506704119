import React, { useContext } from 'react'
import Page from '../../../layout/Page'
import { Tab } from 'semantic-ui-react'
import UserForm from './UserForm'
import UserSalaryForm from './UserSalaryForm'
import { useLocation } from 'react-router-dom'
import UserFormPageContext from './UserFormPageContext'

function Layout() {
  const location = useLocation()
  const ctx = useContext(UserFormPageContext)
  const menuItems = [
    {
      menuItem: 'Detail',
      render: () => <UserForm />,
    },
    {
      menuItem: 'Salary',
      render: () => (ctx.user === null ? 'Please add user first.' : <UserSalaryForm />),
    },
  ]

  return (
    <Page title={getPageTitle()}>
      <Tab menu={{ secondary: true, pointing: true }} panes={menuItems} />
    </Page>
  )

  function getPageTitle() {
    if (location.pathname.includes('/add')) return 'Add New User'
    else return 'Update User'
  }
}

export default Layout
