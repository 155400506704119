import ResizeObserver from '@juggle/resize-observer'

/**
 * This is a wrapper class over ResizeObserver Polyfill module.
 * This class' responsibilty is to create the ResizeObserver appropriately.
 * If native available then native otherwise the Polyfilled version.
 */
class ElementResizeObserver extends ResizeObserver {
  static getInstance(cb) {
    const RO = window['ResizeObserver'] || ResizeObserver
    return new RO(cb)
  }
}

export default ElementResizeObserver
