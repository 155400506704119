import ProtectedAPIRepository from './ProtectedAPIRepository'
import APIResponseModel from './APIResponseModel'
import Result from './Result'
import UserAttendanceRM from '../responsemodel/UserAttendanceRM'
import UserAttendanceDataMapper from '../datamapper/UserAttendanceDataMapper'
import HolidayRM from '../responsemodel/HolidayRM'
import HolidayDataMapper from '../datamapper/HolidayDataMapper'

export default class AttendanceRepository extends ProtectedAPIRepository {
  private userAttendanceDataMapper = new UserAttendanceDataMapper()
  private holidayDataMapper = new HolidayDataMapper()

  uploadAttendance = async (file: File) => {
    const formData = this.constructFormData({ attendance: file })
    const response = await this.put<APIResponseModel<UserAttendanceRM>>('/attendance/upload', formData)
    if (response instanceof Result.Success) {
      const data: APIResponseModel<UserAttendanceRM> = response.data
      if (data.data !== null) {
        const items = data.data.items.map((it) => this.userAttendanceDataMapper.map(it))
        return new Result.Success({
          items,
          totalItemCount: data.data.total_item_count,
        })
      } else {
        return new Result.Error(0, 'Data is null')
      }
    } else {
      return response
    }
  }

  getAttendance = async (ro: GetAttendanceRequestObject) => {
    const response = await this.post<APIResponseModel<UserAttendanceRM>>('/attendance', ro)
    if (response instanceof Result.Success) {
      const data: APIResponseModel<UserAttendanceRM> = response.data
      if (data.data !== null) {
        const items = data.data.items.map((it) => this.userAttendanceDataMapper.map(it))
        return new Result.Success({
          items,
          totalItemCount: data.data.total_item_count,
        })
      } else {
        return new Result.Error(0, 'Data is null')
      }
    } else {
      return response
    }
  }

  getHolidays = async () => {
    const response = await this.post<APIResponseModel<HolidayRM>>('/holiday')
    if (response instanceof Result.Success) {
      const data: APIResponseModel<HolidayRM> = response.data
      if (data.data !== null) {
        return new Result.Success(data.data.items.map((it) => this.holidayDataMapper.map(it)))
      } else {
        return new Result.Error(0, 'Data is null')
      }
    } else {
      return response
    }
  }

  updateHolidays = async (ro: UpdateHolidaysRequestObject[]) => {
    const response = await this.put<APIResponseModel<HolidayRM>>('/holiday', ro)
    if (response instanceof Result.Success) {
      const data: APIResponseModel<HolidayRM> = response.data
      if (data.data !== null) {
        return new Result.Success(data.data.items.map((it) => this.holidayDataMapper.map(it)))
      } else {
        return new Result.Error(0, 'Data is null')
      }
    } else {
      return response
    }
  }
}

interface GetAttendanceRequestObject {
  id?: string
  user_id?: string
  month?: string
  year?: string
  search?: string
  limit?: number
  offset?: number
}

interface UpdateHolidaysRequestObject {
  title: string
  date: string
}
