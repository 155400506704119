import DataMapper from './DataMapper'
import Reminder from '../../models/Reminder'
import ReminderRM from '../responsemodel/ReminderRM'
import UserDataMapper from './UserDataMapper'

class ReminderDataMapper extends DataMapper<ReminderRM, Reminder> {
  private userDataMapper = new UserDataMapper()
  map = (rm: ReminderRM) => {
    const user = this.userDataMapper.map(rm.by)
    return new Reminder(rm.id, rm.text, user, rm.on, rm.valid_upto, rm.remaining_time, rm.active, null, null)
  }
}

export default ReminderDataMapper
