import DataMapper from './../DataMapper'
// import CaseServiceDataRM from '../../responsemodel/CaseServiceDataRM'
// import CaseServiceData from '../../../models/CaseServiceData'/
import HospitalInfrastructureData from '../../../models/CaseServiceData/HospitalInfrastructureData'
import HospitalInfrastructureDataRM from '../../responsemodel/CaseServiceDataRM/HospitalInfrastructureDataRM'
import phone from '../../validator/phone'
class HospitalInfrastructureDataMapper extends DataMapper<HospitalInfrastructureDataRM, HospitalInfrastructureData> {
    map = (rm: HospitalInfrastructureDataRM) => new HospitalInfrastructureData(rm.bed_icu, rm.changeNamePastMonth, rm.contact_person, rm.createdAt, rm.dateOfChange, rm.designation, rm.full_time_doctor, rm.hospitalData, rm.hospital, rm.hospitalType, rm.hospital_images, rm.hospital_reg_number, rm.icu_facility, rm.inHouse_lab, rm.inHouse_pharmacy, rm.isFieldSaved, rm.number_of_doctor, rm.old_name, rm.ot_facility, rm.patients_in_icu, rm.patients_in_opd, rm.phone, rm.visible_staff)
}
export default HospitalInfrastructureDataMapper
