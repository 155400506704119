import React, { Component } from "react";
import styled from "styled-components";
import { Segment, Form, TextArea, Header, Button } from "semantic-ui-react";
import CompleteCase from "../../../../../models/CompleteCase";
import ReminderListItem from "./ReminderListItem";
import CaseRepository from "../../../../../common/repository/CaseRepository";
import Result from "../../../../../common/repository/Result";
import { toast } from "../../../../common/Toast";
import DateTimePicker from "../../../../app/DateTimePicker";

const ChildSpacing = styled.div`
  & > div {
    margin: 0 16px 8px 16px;
  }
`;

interface State {
  loading: boolean;
  text: string;
  datetime: string;
}

interface Props {
  model: CompleteCase;
  updateModel?(model: CompleteCase): void;
}

class Reminders extends Component<Props, State> {
  private caseRepo = new CaseRepository();

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      text: "",
      datetime: "",
    };
  }

  addReminder = () => {
    this.setState({ loading: true }, async () => {
      const { model, updateModel } = this.props;
      const result = await this.caseRepo.addReminder({
        case_id: model.getId(),
        remainder: { text: this.state.text, valid_upto: this.state.datetime },
      });

      if (result instanceof Result.Success) {
        this.setState({ loading: false, text: "", datetime: "" }, () => {
          const c = result.data as CompleteCase;
          toast.success("Reminder added successfully");
          if (updateModel) updateModel(c);
        });
      } else {
        this.setState({ loading: false }, () => {
          const message = result.message || "Something went wrong";
          toast.error(message);
        });
      }
    });
  };

  addMonthsToCurrentDate = (months: number) => {
    let date = new Date(Date.now());
    let d = date.getDate();
    date.setMonth(date.getMonth() + +months);
    if (date.getDate() != d) {
      date.setDate(0);
    }
    return date;
  };

  render() {
    const { model, updateModel } = this.props;

    return (
      <Segment basic loading={this.state.loading} style={{ padding: 0 }}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          {/* section */}
          <div style={{ flex: 1, marginRight: 8 }}>
            <Form>
              <Header as="h4">Add New Reminder</Header>
              <DateTimePicker
                type="datetime"
                value={this.state.datetime}
                maxDate={this.addMonthsToCurrentDate(2)}
                onChange={(value) => this.setState({ datetime: value })}
              />

              <TextArea
                placeholder="Enter description"
                value={this.state.text}
                onChange={(_, { value }) =>
                  this.setState({ text: value as string })
                }
                style={{ margin: "16px 0" }}
              />

              <Button
                positive
                onClick={this.addReminder}
                disabled={
                  this.state.loading ||
                  this.state.text.trim().length === 0 ||
                  this.state.datetime.trim().length === 0
                }
                style={{ width: "100%" }}
              >
                Submit
              </Button>
            </Form>
          </div>
          {/* section */}

          {/* section */}
          <div style={{ flex: 2 }}>
            <div style={{ height: 300, overflowY: "scroll" }}>
              <ChildSpacing>
                {model.getReminders().map((it) => (
                  <ReminderListItem
                    model={it}
                    caseModel={model}
                    updateModel={updateModel}
                    caseRepo={this.caseRepo}
                  />
                ))}
              </ChildSpacing>
            </div>
          </div>
          {/* section */}
        </div>
      </Segment>
    );
  }
}

export default Reminders;
