import WrappingRepository from './WrappingRepository'
import { CancelToken } from 'axios'

export default class Repository extends WrappingRepository {
  protected get = <T>(path: string, queryParams?: {}, cancelToken?: CancelToken) => this.wrappedGet<T>(path, queryParams, cancelToken)
  protected put = <T>(path: string, body?: {}, cancelToken?: CancelToken) => this.wrappedPut<T>(path, body, cancelToken)
  protected post = <T>(path: string, body?: {}, cancelToken?: CancelToken) => this.wrappedPost<T>(path, body, cancelToken)
  protected patch = <T>(path: string, body?: {}, cancelToken?: CancelToken) => this.wrappedPatch<T>(path, body, cancelToken)
  protected delete = <T>(path: string, queryParams?: {}, cancelToken?: CancelToken) => this.wrappedDelete<T>(path, queryParams, cancelToken)
}
