import DomainModel from './DomainModel'

export default class UserHistory extends DomainModel {
    constructor(private id: string,
        private action: string,
        private actionBy: string,
        private date: string,
        private previousAddress: string,
        private previousCoordinates: [],
        private requestAddress: string,
        private requestCoordinates: [],
        private updatedCoordinates: [],
        private UpdatedAddress: string,
    ) {
        super()
    }
    getId = () => this.id
    getAction = () => this.action
    getActionBy = () => this.actionBy
    getDate = () => this.date
    getPreviousAddress = () => this.previousAddress
    getPreviousCoordinates = () => this.previousCoordinates
    getRequestAddress = () => this.requestAddress
    getRequestCoordinates = () => this.requestCoordinates
    getUpdatedCoordinates = () => this.updatedCoordinates
    getUpdatedAddress = () => this.UpdatedAddress


}
