import React from "react";
import User from "../../../../models/User";
import Settings from "../../../../models/Settings";
import UserRepository from "../../../../common/repository/UserRepository";

interface UserFormPageContextType {
  userRepo: UserRepository;
  isLoading: boolean;
  setIsLoading(bool: boolean): void;
  user: User | null;
  setUser(user: User): void;
  settings: Settings | null;
  setSettings(settings: Settings): void;
  userSalaryAmount: number;
  setUserSalaryAmount(amount: number): void;
  specialAllowance: number;
  setSpecialAllowance(amount: number): void;
}

const UserFormPageContext = React.createContext({} as UserFormPageContextType);
export default UserFormPageContext;
