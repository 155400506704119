import React, { Component } from "react";
import LocationInput, {
  LocationInputValue,
} from "../../../../../common/LocationInput";
import CompleteCase from "../../../../../../models/CompleteCase";
import { Form, FormField, Button } from "semantic-ui-react";
import StateDistrictRepository from "../../../../../../common/repository/StateDistrictRepository";
import CaseRepository from "../../../../../../common/repository/CaseRepository";
import Result from "../../../../../../common/repository/Result";
import { toast } from "../../../../../common/Toast";
import CaseType from "../../../../../../models/CaseType";

interface Props {
  model: CompleteCase;
  caseType: CaseType;
  onSuccess?(model: CompleteCase): void;
}

interface State {
  loading: boolean;
  location: LocationInputValue | null;
  address: string;
  contact: string;
}

class CaseOptionalFieldsInputForm extends Component<Props, State> {
  private stateDistrictRepo = new StateDistrictRepository();
  private caseRepo = new CaseRepository();

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      location: null,
      address: "",
      contact: "",
    };
  }

  onSubmit = () => {
    this.setState({ loading: true }, async () => {
      const { model, caseType, onSuccess } = this.props;
      const result = await this.caseRepo.addInsuredDetail({
        case_id: model.getId(),
        insured_location: this.state.location!,
        insured_address: this.state.address,
        insured_phone: this.state.contact,
        case_type: caseType.getId(),
        case_category: caseType.getCaseCategoryCode(),
      });

      if (result instanceof Result.Success) {
        const c = result.data;
        this.setState({ loading: false }, () => {
          toast.success("Successfully added Insured information");
          if (onSuccess) onSuccess(c as CompleteCase);
        });
      } else {
        this.setState({ loading: false }, () => {
          const message = result.message || "Something went wrong";
          toast.error(message);
        });
      }
    });
  };

  render() {
    return (
      <Form loading={this.state.loading}>
        <LocationInput
          label={<Form.Field>Insured Location</Form.Field>}
          value={this.state.location}
          onChange={(value) => this.setState({ location: value })}
          stateDistrictRepo={this.stateDistrictRepo}
        />

        <Form.Input
          label="Insured Address"
          placeholder="Home Address"
          value={this.state.address}
          onChange={(_, { value }) => this.setState({ address: value })}
        />

        <Form.Input
          label="Insured Contact"
          placeholder="Mobile Number"
          value={this.state.contact}
          onChange={(_, { value }) => this.setState({ contact: value })}
        />

        <Button
          positive
          onClick={this.onSubmit}
          disabled={
            this.state.loading ||
            this.state.address.trim().length === 0 ||
            this.state.contact.trim().length === 0 ||
            this.state.location === null ||
            this.state.location.state.trim().length === 0 ||
            this.state.location.district.trim().length === 0
          }
        >
          Submit
        </Button>
      </Form>
    );
  }
}

export default CaseOptionalFieldsInputForm;
