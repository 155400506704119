import DomainModel from './DomainModel'

export default class ClaimType extends DomainModel {
  constructor(private title: string, private fee: number, private GSTPercentage: number, private HSNCode: string) {
    super()
  }

  getTitle = () => this.title
  getFee = () => this.fee
  getGSTPercentage = () => this.GSTPercentage
  getHSNCode = () => this.HSNCode
}
