import React, { useState, useEffect } from "react";
import Page from "../../../layout/Page";
import { Form, Button, Segment, Icon } from "semantic-ui-react";
import AttendanceRepository from "../../../../common/repository/AttendanceRepository";
import Result from "../../../../common/repository/Result";
import { toast } from "../../../common/Toast";
import DateTimePicker from "../../../app/DateTimePicker";

export default function HolidayPage() {
  const attendanceRepo = new AttendanceRepository();
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [holidays, setHolidays] = useState<{ title: string; date: string }[]>(
    []
  );

  useEffect(() => {
    getHolidays();
  }, [isLoading]);
  useEffect(() => {
    updateHolidays();
  }, [isSubmitting]);

  return (
    <Page title="Holiday Page" description="Manage yearly Holidays">
      <Segment.Group>
        <Segment>
          <Button
            primary
            size="mini"
            onClick={() => setHolidays([...holidays, { title: "", date: "" }])}
          >
            Add
          </Button>
        </Segment>
        <Segment>
          {createForm()}
          {holidays.length > 0 ? (
            <Button positive onClick={() => setIsSubmitting(true)}>
              Submit
            </Button>
          ) : null}
        </Segment>
      </Segment.Group>
    </Page>
  );

  async function getHolidays() {
    if (!isLoading) return;

    const result = await attendanceRepo.getHolidays();
    if (result instanceof Result.Success) {
      setHolidays(
        result.data.map((it) => ({ title: it.getTitle(), date: it.getDate() }))
      );
    } else {
      const message = result.message || "Could not load Holidays";
      toast.error(message);
    }
    setIsLoading(false);
  }

  async function updateHolidays() {
    if (!isSubmitting) return;
    let filtered = holidays.filter(
      (it) => it.date.trim().length !== 0 && it.title.trim().length !== 0
    );
    const result = await attendanceRepo.updateHolidays(filtered);
    if (result instanceof Result.Success) {
      setHolidays(
        result.data.map((it) => ({ title: it.getTitle(), date: it.getDate() }))
      );
    } else {
      const message = result.message || "Something went wrong";
      toast.error(message);
    }
    setIsSubmitting(false);
  }

  function createForm() {
    const fieldComponents = holidays.map((it, i) => {
      return (
        <Form.Group key={i}>
          <Form.Input
            placeholder="e.g. Diwali"
            value={it.title}
            onChange={(_, { value }) => {
              holidays[i].title = value;
              setHolidays([...holidays]);
            }}
          />
          <Form.Input>
            <DateTimePicker
              type="date"
              placeholder="Pick date"
              value={it.date}
              onChange={(value) => {
                holidays[i].date = value;
                setHolidays([...holidays]);
              }}
            />
          </Form.Input>
          <Form.Field>
            <Button icon negative size="mini" onClick={() => removeHoliday(i)}>
              <Icon name="x" />
            </Button>
          </Form.Field>
        </Form.Group>
      );
    });
    return <Form loading={isSubmitting}>{fieldComponents}</Form>;
  }

  function removeHoliday(index: number) {
    holidays.splice(index, 1);
    setHolidays([...holidays]);
  }
}
