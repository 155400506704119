import React from "react";
import { Form } from "semantic-ui-react";
import Button from "../../elements/Button";
import Result from "../../../common/repository/Result";
import { toast } from "../Toast";
import StateDM from "./../../../models/State";
import StatesDistrictsRepository from "../../../common/repository/StatesDistrictsRepository"
import States from "../../../models/States";
import { TagsInput } from "react-tag-input-component";

interface State {
  form_loading: boolean;
  form_loadingDistricts: boolean;
  state_name: string
  state_code: string
  state_gst_code: string
  // state_districts: string
  state_district: string[]
}

interface Props {
  model?: States;
  states?: StateDM[];
  onSuccess(states: States): void;
  onError?(): void;
}

class StatesForm extends React.Component<Props, State> {

  private statesRepo = new StatesDistrictsRepository();

  constructor(props: Props) {
    super(props);
    let loadStates = true;
    if (props.states) loadStates = false;

    const stateFromModel = this.deriveStateFromModel();
    this.state = {
      form_loading: false,
      form_loadingDistricts: false,
      // state_districts: "",
      ...stateFromModel,
    };
  }

  componentDidMount() {
    if (this.props.model?.getId()) this.getDistricts()
  }

  private deriveStateFromModel = () => {
    const model = this.props.model;
    return {
      state_name: (model && model.getName()) || "",
      state_code: (model && model.getCode()) || "",
      state_gst_code: (model && model.getGSTCode()) || "",
      state_district: (model && model.getDistrict()) || [],
    };
  };

  private onSubmit = () => {
    this.setState({ form_loading: true }, () => {
      if (this.props.model) this.updateState();
      else this.addStates();

    });
  };

  private getDistricts = () => {
    const state = this.state.state_name;
    this.setState({ form_loadingDistricts: true }, async () => {
      const result = await this.statesRepo.getDistricts({ state });
      if (result instanceof Result.Success) {
        const districts = result.data.items;
        this.setState({
          form_loadingDistricts: false,
          state_district: districts,
        });
      } else {
        const message = result.message || "Something went wrong";
        this.setState({ form_loadingDistricts: false }, () =>
          toast.error(message)
        );
      }
    });
  };

  private addStates = async () => {
    const result = await this.statesRepo.addStates({
      name: this.state.state_name,
      code: this.state.state_code,
      GSTCode: this.state.state_gst_code,
    });

    if (result instanceof Result.Success) {
      const states = result.data;
      toast.success("State Added Successfully!");
      this.props.onSuccess(states);
    } else {
      const message = result.message || "Something went wrong";
      this.setState({ form_loading: false }, () => {
        toast.error(message);
        if (this.props.onError) this.props.onError();
      });
    }
  };

  private updateState = async () => {
    const stateId = this.props.model!.getId();
    const result = await this.statesRepo.updateState({
      id: stateId,
      name: this.state.state_name,
      code: this.state.state_code,
      GSTCode: this.state.state_gst_code,
      districts: this.state.state_district,
    });

    if (result instanceof Result.Success) {
      const state = result.data;
      toast.success("state Updated Successfully!");
      this.props.onSuccess(state);
    } else {
      const message = result.message || "Something went wrong";
      this.setState({ form_loading: false }, () => {
        toast.error(message);
        if (this.props.onError) this.props.onError();
      });
    }
  };


  render() {

    return (
      <Form loading={this.state.form_loading}>
        {/*state name */}
        <Form.Input
          label="Name"
          placeholder="Punjab"
          value={this.state.state_name}
          onChange={(_, { value }) => this.setState({ state_name: value })}
        />
        {/*state name */}
        {/*state Code */}
        <Form.Input
          label="Code"
          placeholder="Pb"
          value={this.state.state_code}
          onChange={(_, { value }) => this.setState({ state_code: value })}
        />
        {/*state Code */}

        {/* GST Code */}
        <Form.Input
          label="GST Code"
          placeholder="..."
          value={this.state.state_gst_code}
          onChange={(_, { value }) => this.setState({ state_gst_code: value })}
        />
        {/* GST Code */}

        {/* District */}
        {this.props.model?.getId() && <>
          <label>District</label>
          <TagsInput
            value={this.state.state_district}
            onChange={(value:any) => this.setState({ state_district: value })}
            name="District"
            placeHolder="Mohali"
          /></>}
        {/* District */}

        <Button
          positive
          onClick={this.onSubmit}
          disabled={
            this.props.model?.getId() ?
              (this.state.state_name.length === 0 ||
                this.state.state_code.length === 0 ||
                this.state.state_gst_code.length === 0 ||
                this.state.state_district.length === 0)
              :
              (this.state.state_name.length === 0 ||
                this.state.state_code.length === 0 ||
                this.state.state_gst_code.length === 0)
          }
        >
          Submit
        </Button>
      </Form>
    );
  }
}

export default StatesForm;
