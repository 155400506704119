import React, { useState, useContext, useEffect } from 'react'
import { Form, Input, Header, Divider, Segment, Button } from 'semantic-ui-react'
import FormLabel from '../../common/FormLabel'
import SettingsPageContext from './SettingsPageContext'
import Result from '../../../common/repository/Result'
import { toast } from '../../common/Toast'
import validator from '../../../common/validator'

function SalarySegment() {
  const ctx = useContext(SettingsPageContext)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [hra, setHra] = useState('')
  const [miAllowance, setMiAllowance] = useState('')
  const [bonus, setBonus] = useState('')
  const [extraCaseFee, setExtraCaseFee] = useState('')
  const [cityExpense, setCityExpense] = useState('')
  const [conveyanceAllowance, setConveyanceAllowance] = useState('')
  const [primaryLocationTravelAllowance, setPrimaryLocationTravelAllowance] = useState('')
  const [secondaryLocationTravelAllowance, setSecondaryLocationTravelAllowance] = useState('')
  const [tertiaryLocationTravelAllowance, setTertiaryLocationTravelAllowance] = useState('')

  useEffect(effectInitializeValue, [ctx.settings])
  useEffect(() => {
    effectOnSubmit()
  }, [isSubmitting])

  return (
    <Form loading={isSubmitting}>
      <Header as="h3" color="grey">
        Basic
      </Header>
      <Form.Group inline>
        <Form.Field>
          <FormLabel width="100px">HRA</FormLabel>
          <Input
            label={{ basic: true, content: '%' }}
            labelPosition="right"
            value={hra}
            onChange={(_, { value }) => {
              if (validator.number.isValid(value)) setHra(value)
            }}
          />
        </Form.Field>
      </Form.Group>

      <Form.Group inline>
        <Form.Field>
          <FormLabel width="100px">M & I Allowance</FormLabel>
          <Input
            label={{ basic: true, content: '%' }}
            labelPosition="right"
            value={miAllowance}
            onChange={(_, { value }) => {
              if (validator.number.isValid(value)) setMiAllowance(value)
            }}
          />
        </Form.Field>
      </Form.Group>

      <Form.Group inline>
        <Form.Field>
          <FormLabel width="100px">Bonus</FormLabel>
          <Input
            label={{ basic: true, content: '%' }}
            labelPosition="right"
            value={bonus}
            onChange={(_, { value }) => {
              if (validator.number.isValid(value)) setBonus(value)
            }}
          />
        </Form.Field>
      </Form.Group>

      <Divider />

      <Header as="h3" color="grey">
        Investigator
      </Header>
      <Form.Group inline>
        <Form.Field>
          <FormLabel width="130px">Extra Case Fee</FormLabel>
          <Input
            label={{ basic: true, content: 'Rs.' }}
            labelPosition="right"
            value={extraCaseFee}
            onChange={(_, { value }) => {
              if (validator.number.isValid(value)) setExtraCaseFee(value)
            }}
          />
        </Form.Field>
      </Form.Group>

      <Form.Group inline>
        <Form.Field>
          <FormLabel width="130px">City Expense</FormLabel>
          <Input
            label={{ basic: true, content: 'Rs.' }}
            labelPosition="right"
            value={cityExpense}
            onChange={(_, { value }) => {
              if (validator.number.isValid(value)) setCityExpense(value)
            }}
          />
        </Form.Field>
      </Form.Group>

      <Form.Group inline>
        <Form.Field>
          <FormLabel width="130px">Conveyance Allowance</FormLabel>
          <Input
            label={{ basic: true, content: 'Rs.' }}
            labelPosition="right"
            value={conveyanceAllowance}
            onChange={(_, { value }) => {
              if (validator.number.isValid(value)) setConveyanceAllowance(value)
            }}
          />
        </Form.Field>
      </Form.Group>

      <Header as="h5" color="grey">
        Travel Allowance
      </Header>

      <Form.Group inline>
        <Form.Field>
          <FormLabel width="130px">Primary Location</FormLabel>
          <Input
            label={{ basic: true, content: 'Rs.' }}
            labelPosition="right"
            value={primaryLocationTravelAllowance}
            onChange={(_, { value }) => {
              if (validator.number.isValid(value)) setPrimaryLocationTravelAllowance(value)
            }}
          />
        </Form.Field>
      </Form.Group>

      <Form.Group inline>
        <Form.Field>
          <FormLabel width="130px">Secondary Location</FormLabel>
          <Input
            label={{ basic: true, content: 'Rs.' }}
            labelPosition="right"
            value={secondaryLocationTravelAllowance}
            onChange={(_, { value }) => {
              if (validator.number.isValid(value)) setSecondaryLocationTravelAllowance(value)
            }}
          />
        </Form.Field>
      </Form.Group>

      <Form.Group inline>
        <Form.Field>
          <FormLabel width="130px">Tertiary Location</FormLabel>
          <Input
            label={{ basic: true, content: 'Rs.' }}
            labelPosition="right"
            value={tertiaryLocationTravelAllowance}
            onChange={(_, { value }) => {
              if (validator.number.isValid(value)) setTertiaryLocationTravelAllowance(value)
            }}
          />
        </Form.Field>
      </Form.Group>

      <Button primary disabled={isSubmitButtonDisabled()} onClick={() => setIsSubmitting(true)}>
        Submit
      </Button>
    </Form>
  )

  function effectInitializeValue() {
    if (ctx.settings === null) return

    const salary = ctx.settings.getSalary()
    setHra((salary.hraPercentage && salary.hraPercentage.toString()) || '')
    setMiAllowance((salary.miAllowancePercentage && salary.miAllowancePercentage.toString()) || '')
    setBonus((salary.bonusPercentage && salary.bonusPercentage.toString()) || '')
    setExtraCaseFee((salary.investigator.extraCaseFee && salary.investigator.extraCaseFee.toString()) || '')
    setCityExpense((salary.investigator.cityExpense && salary.investigator.cityExpense.toString()) || '')
    setConveyanceAllowance((salary.investigator.conveyanceAllowance && salary.investigator.conveyanceAllowance.toString()) || '')
    setPrimaryLocationTravelAllowance((salary.investigator.travelAllowance.primary && salary.investigator.travelAllowance.primary.toString()) || '')
    setSecondaryLocationTravelAllowance((salary.investigator.travelAllowance.secondary && salary.investigator.travelAllowance.secondary.toString()) || '')
    setTertiaryLocationTravelAllowance((salary.investigator.travelAllowance.tertiary && salary.investigator.travelAllowance.tertiary.toString()) || '')
  }

  async function effectOnSubmit() {
    if (!isSubmitting || ctx.settings === null) return

    const result = await ctx.settingsRepo.updateSettings({
      id: ctx.settings.getId(),
      salary: {
        basic: {
          hra: parseFloat(hra),
          bonus: parseFloat(bonus),
          mi_allowance: parseFloat(miAllowance),
        },
        investigator: {
          extra_case_fee: parseFloat(extraCaseFee),
          city_expense: parseFloat(cityExpense),
          conveyance_allowance: parseFloat(conveyanceAllowance),
          travel_allowance: {
            primary: parseFloat(primaryLocationTravelAllowance),
            secondary: parseFloat(secondaryLocationTravelAllowance),
            tertiary: parseFloat(tertiaryLocationTravelAllowance),
          },
        },
      },
    })

    if (result instanceof Result.Success) {
      const settings = result.data
      ctx.setSettings(settings)
      toast.success('Successfully updated Settings')
    } else {
      const message = result.message || 'Something went wrong'
      toast.error(message)
    }
    setIsSubmitting(false)
  }

  function isSubmitButtonDisabled() {
    return (
      isSubmitting ||
      hra.trim().length === 0 ||
      miAllowance.trim().length === 0 ||
      bonus.trim().length === 0 ||
      extraCaseFee.trim().length === 0 ||
      cityExpense.trim().length === 0 ||
      conveyanceAllowance.trim().length === 0 ||
      primaryLocationTravelAllowance.trim().length === 0 ||
      secondaryLocationTravelAllowance.trim().length === 0 ||
      tertiaryLocationTravelAllowance.trim().length === 0
    )
  }
}

export default SalarySegment
