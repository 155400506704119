import React from "react";
import { Form, Segment } from "semantic-ui-react";
import Button from "../../../../elements/Button";
import InvoiceSection from "../../../../../models/InvoiceSection";
import Result from "../../../../../common/repository/Result";
import { toast } from "../../../../common/Toast";
import { RouteComponentProps } from "react-router-dom";
import ReportRepository from '../../../../../common/repository/ReportRepository'
import PathologistDatail from "../../../../../models/ReportModels/PathologistDatail"
import config from "../../../../../common/repository/config"
import moment from "moment"

interface Props {
  model?: PathologistDatail;

  onSuccess: (invoiceSection: InvoiceSection) => void;
  onError?: () => void;
}

interface MatchProps {
  Id: string;
}
interface Props extends RouteComponentProps<MatchProps> { }

interface State {
  form_loading: boolean;
  pathologistDatails: PathologistDatail[]
  case_id: string
  contactNumber: string
  createdAt: string
  doctorAvailableInHospital: string
  doctorConfirmation: string
  hospital_id: string
  investigator_id: string
  isFieldSaved: boolean
  pathologistName: string
  personalMeeting: string
  personalMeetingPic: string
  qualification: string
  registrationCertificate: string
  registrationNumber: string
  updatedAt: string
  feedback: string
  feedBackID: string
}

class PathologistDetailForm extends React.Component<Props, State> {
  private ReportRepository = new ReportRepository()

  constructor(props: Props) {
    super(props);

    const stateFromModel = this.deriveStateFromModel();
    this.state = {
      form_loading: false,
      pathologistDatails: [],
      feedback: "",
      feedBackID: "",
      ...stateFromModel,
    };
    console.log(this.props.model, "model")
  }


  componentDidMount() {
    this.getPathologistDetail()
  }
  private deriveStateFromModel = () => {
    const model = this.props.model;
    return {
      case_id: (model && model.getCase_id()) || "",
      contactNumber: (model && model.getContactNumber()) || "",
      createdAt: (model && model.getCreatedAt()) || "",
      doctorAvailableInHospital: (model && model.getDoctorAvailableInHospital()) || "",
      doctorConfirmation: (model && model.getDoctorConfirmation()) || "",
      hospital_id: (model && model.getHospital_id()) || "",
      investigator_id: (model && model.getInvestigator_id()) || "",
      isFieldSaved: (model && model.getIsFieldSaved()) || false,
      pathologistName: (model && model.getPathologistName()) || "",
      personalMeeting: (model && model.getPersonalMeeting()) || "",
      personalMeetingPic: (model && model.getPersonalMeetingPic()) || "",
      qualification: (model && model.getQualification()) || "",
      registrationCertificate: (model && model.getRegistrationCertificate()) || "",
      registrationNumber: (model && model.getRegistrationNumber()) || "",
      updatedAt: (model && model.getUpdatedAt()) || "",
    };
  };

  private updateFeedBack = async () => {
    const InveId = this.props.match.params;
    const result = await this.ReportRepository.updatepathologistFeedBack({
      type: "pathologist",
      feedback: this.state.feedback,
      feedback_id: this.state.feedBackID,
      pathologist_id: InveId.Id.toString(),
    });
    if (result instanceof Result.Success) {
      const state = result.data;
      toast.success("state Updated Successfully!");
    } else {
      const message = result.message || "Something went wrong";
      this.setState({ form_loading: false }, () => {
        toast.error(message);
        if (this.props.onError) this.props.onError();
      });
    }
  };

  private getPathologistDetail = () => {
    const InveId = this.props.match.params;
    this.setState({ form_loading: true }, async () => {
      const result = await this.ReportRepository.getPathologistDetail({ pathologist_id: InveId.Id });
      if (result instanceof Result.Success) {
        const pathologistDatails = result.data.items;
        console.log(pathologistDatails)
        this.setState({
          form_loading: false,
          pathologistDatails: pathologistDatails,
          feedback: pathologistDatails[0].getFeedBack(),
          feedBackID: pathologistDatails[0].getFeedBackId()
        });
      } else {
        const message = result.message || "Something went wrong";
        this.setState({ form_loading: false }, () =>
          toast.error(message)
        );
      }
    });
  };

  render(): JSX.Element {
    let noAvailable = "not available"

    return (
      <Segment >
        <h1>Pathologist Detail</h1>
        <Form loading={this.state.form_loading}>
          <Form.Group>
            <Form.Input
              required
              type="input"
              width="4"
              label="Pathologist Name"
              value={this.state.pathologistDatails[0]?.getPathologistName() || noAvailable}
            />
            <Form.Input
              required
              type="input"
              width="4"
              label="Registration Number"
              value={this.state.pathologistDatails[0]?.getRegistrationNumber() || noAvailable}
            />

            {this.state.pathologistDatails[0]?.getRegistrationCertificate() ?
              <Form.Input
                required
                type="input"
                width="4"
                label="Registration Certificate"
              >
                {<a className={"ui green  button"}
                  href={`${config.mrdRecipt}${this.state.pathologistDatails[0]?.getRegistrationCertificate()}`}
                  target={"_blank"}
                >
                  Certificate
                </a> || "not available "}
              </Form.Input> :
              <Form.Input
                required
                type="input"
                width="4"
                label="Registration Certificate"
                value={noAvailable}
              />
            }
            <Form.Input
              required
              type="input"
              width="4"
              label="Qualification"
              value={this.state.pathologistDatails[0]?.getQualification() || noAvailable}
            />
          </Form.Group>
          <Form.Group>
            <Form.Input
              required
              type="input"
              width="4"
              label="Was Pathologist Available in Hospital"
              value={this.state.pathologistDatails[0]?.getDoctorAvailableInHospital() || noAvailable}
            />
            <Form.Input
              required
              type="input"
              width="4"
              label="Personal Meeting"
              value={this.state.pathologistDatails[0]?.getPersonalMeeting() || noAvailable}
            />

            {this.state.pathologistDatails[0]?.getPersonalMeetingPic() ? <Form.Input
              required
              type="input"
              width="4"
              label="Personal Meeting Image"
            >
              <a className={"ui green  button"}
                href={`${config.mrdRecipt}${this.state.pathologistDatails[0]?.getPersonalMeetingPic()}`}
                target={"_blank"}
              >
                Meeting Image
              </a>
            </Form.Input> :
              <Form.Input
                required
                type="input"
                width="4"
                label="Personal Meeting Image"
                value={noAvailable}
              />
            }

            <Form.Input
              required
              type="input"
              width="4"
              label="Pathologist Meeting"
            // value={this.state.pathologistDatails[0]?.()}
            />
          </Form.Group>
          <Form.Group>
            <Form.Input
              required
              type="input"
              width="4"
              label="Created At"
              value={moment(this.state.pathologistDatails[0]?.getCreatedAt()).format("LL") || noAvailable}
            />
            <Form.Input
              required
              type="input"
              width="4"
              label="Updated At"
              value={moment(this.state.pathologistDatails[0]?.getUpdatedAt()).format("LL") || noAvailable}
            />
          </Form.Group>

          <Form.Group>
            <Form.Input
              required
              type="input"
              width="4"
              label="Pathologist Confirmation"
              value={this.state.pathologistDatails[0]?.getDoctorConfirmation() || noAvailable}
            />
            <Form.Input
              required
              type="input"
              width="4"
              label="Pathologist Contact Number"
              value={this.state.pathologistDatails[0]?.getContactNumber() || noAvailable}
            />
            <Form.Input
              type="input"
              width="4"
              label="Number and Name of Hospitals Attached to Pathologist"
              value={this.state.pathologistDatails[0]?.getHospitalName()}
            />
            <Form.Input
              type="input"
              width="4"
              label="% Of Cases Confirmed By Pathologist"
            />
          </Form.Group>
          <Form.Group>
            <Form.Input
              type="input"
              width="4"
              label="% Of Cases Not Confirmed By Pathologist"
            // value={this.state.pathologistDatails[0]?.getHospital()}
            />
          </Form.Group>

          <h1>Feedback</h1>
          <Form.Group>
            <Form.Input
              type="input"
              width="16"
              label="Feedback"
              value={this.state.feedback}
              onChange={(_, { value }) => this.setState({ feedback: value })}
            />
          </Form.Group>
          <Button
            positive
            onClick={this.updateFeedBack}
            disabled={this.state.feedback.length === 0}
          >
            Submit
          </Button>
        </Form>
      </Segment>

    );
  }
}

export default PathologistDetailForm;
