import DomainModel from '../DomainModel'

export default class TreatingDoctor extends DomainModel {
    constructor(private _id: string, private name: string, private mobile: string, private registrationNumber: string) {
        super()
    }

    getId = () => this._id
    getName = () => this.name
    getMobile = () => this.mobile
    getRegistrationNumber = () => this.registrationNumber
}
