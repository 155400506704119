import DomainModel from './DomainModel'

export default class Hospital extends DomainModel {
  constructor(private id: string, private name: string, private district: string, private state: string) {
    super()
  }

  getId = () => this.id
  getName = () => this.name
  getDistrict = () => this.district
  getState = () => this.state
}
