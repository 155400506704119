import DomainModel from './DomainModel'

export default class CancelledByInvestigatorList extends DomainModel {
    constructor(public _id: string, private address: string,
        private isAddressChange: string,
        private name: string,
        private tempNewAddress: string,
        private addressChangeReqTime: string,
    ) {
        super()
    }

    getId = () => this._id
    getAddress = () => this.address
    getIsAddressChange = () => this.isAddressChange
    getInvestigator = () => this.name
    getTempNewAddress = () => this.tempNewAddress
    getAddressChangeReqTime = () => this.addressChangeReqTime

}