
import DataMapper from '../DataMapper'
import Notifecations from '../../../models/NotifecationModels/Notifecations'
import NotifecationRM from '../../responsemodel/NotifecationRM/NotifecationRM'

export default class NotifecatoinDataMapper extends DataMapper<NotifecationRM, Notifecations> {
    map = (rm: NotifecationRM) => new Notifecations(
        rm._id,
        rm.createdAt,
        rm.message,
        rm.name,
        rm.title,
        rm.type,
        rm.type_id,
        rm.unread,
    )
}
