import React from "react";
import Page from "../../../layout/Page";
import { Modal, Icon, Button, DropdownItemProps, Segment, Header, } from "semantic-ui-react";
import Result from "../../../../common/repository/Result";
import { toast } from "../../../common/Toast";
import Table from "../../../app/Table";
import RequirePermission from "../../../base/RequirePermission";
import permissions from "../../../../common/permissions";

import UploadBulkPaymentForm from "./UploadBulkPaymentForm";
import UploadBulkPaymentReceiptForm from "./UploadBulkPaymentReceiptForm";
import PaymentTemplateDownloadForm from "./PaymentTemplateDownloadForm";
import PaymentUpdateForm from "./PaymentUpdateForm";
import PaymentSection from "../../../../models/PaymentSection";
import PaymentSectionColumnRenderer from "./PaymentSectionColumnRenderer";
import Filters from "./Filters";
import FilterPageContext from "./FilterPageContext";
import ClientRepository from "../../../../common/repository/ClientRepository";
import PaymentSectionRepository, {
  GetPaymentSectionRequestObject,
} from "../../../../common/repository/PaymentSectionRepository";
import CaseTypeRepository from "../../../../common/repository/CaseTypeRepository";

interface State {
  loading: boolean;

  filter: string;
  limit: number;
  offset: number;
  totalCount: number;
  paymentSection: PaymentSection[];

  update_modalOpen: boolean;
  update_model: PaymentSection | null;

  remove_modalOpen: boolean;
  remove_model: PaymentSection | null;

  downloadTemplateModalOpen: boolean;
  uploadTemplateModalOpen: boolean;
  paymentReceiptModalOpen: boolean;

  loadingEssentialData: boolean;
  clientOptions: DropdownItemProps[];
  caseTypeOptions: DropdownItemProps[];
  clientFilter: string;
  caseTypeFilter: string;
  claimNumberFilter: string;
  invoiceNumberFilter: string;
  fromFilter: string;
  toFilter: string;
  fromPaymentDateFilter: string;
  toPaymentDateFilter: string;

  paymentStatus: string;

  docPendingFilter: boolean;
  paymentPendingFilter: boolean;
  paymentUploadPendingFilter: boolean;

  bulkPaymentUploadResponseStatus: boolean;
  bulkPaymentUploadResponse: number[];

  paymentResponseSegmentStatus: boolean;
  paymentResponseSegmentData: string[];
  Reinvestigation: boolean
  Pushback: boolean
}

class PaymentSectionPage extends React.Component<{}, State> {
  private paymentSectionRepo = new PaymentSectionRepository();
  private clientRepo = new ClientRepository();
  private caseTypeRepo = new CaseTypeRepository();
  private filters: any;

  private columnRenderer = new PaymentSectionColumnRenderer(
    (model: PaymentSection) => {
      let receipt = <a className={"ui button"}>NA</a>;
      if (
        model.getPayment() != null
          ? model.getPayment()?.getReceiptUrl()
            ? true
            : false
          : false
      ) {
        receipt = (
          <a
            className={"ui primary button"}
            href={`${model.getPayment()?.getReceiptUrl()}`}
            target={"_blank"}
          >
            View
          </a>
        );
      }
      return (
        <>
          {receipt}
          <Button
            positive
            onClick={() =>
              this.setState({ update_modalOpen: true, update_model: model })
            }
          >
            Open
          </Button>
        </>
      );
    },
    (obj: any) => true
  );

  constructor(props: any) {
    super(props);

    this.filters = props.history.location.state;
    this.state = {
      loading: false,
      limit: Table.ROWS_PER_PAGE[Table.ROWS_PER_PAGE.length - 1] * 2,
      offset: 0,
      filter: "",
      totalCount: 0,
      paymentSection: [],
      update_modalOpen: false,
      update_model: null,
      downloadTemplateModalOpen: false,
      uploadTemplateModalOpen: false,
      paymentReceiptModalOpen: false,
      remove_modalOpen: false,
      remove_model: null,

      loadingEssentialData: true,
      clientOptions: [],
      caseTypeOptions: [],
      clientFilter: "",
      caseTypeFilter: "",
      claimNumberFilter: "",
      invoiceNumberFilter: "",
      fromFilter: "",
      toFilter: "",
      fromPaymentDateFilter: "",
      toPaymentDateFilter: "",

      paymentStatus: "",

      docPendingFilter: false,
      paymentPendingFilter: false,
      paymentUploadPendingFilter: false,

      bulkPaymentUploadResponse: [],
      bulkPaymentUploadResponseStatus: false,

      paymentResponseSegmentStatus: false,
      paymentResponseSegmentData: [],

      Reinvestigation: false,
      Pushback: false,
    };
  }

  async componentDidMount() {
    this.loadData();
    await this.effectLoadEssentialData();
  }

  private setFilter = (filter: string) => {
    this.setState({ filter, offset: 0 }, this.loadData);
  };

  private clearAllFilters = async () => {
    await this.setState({
      limit: 0,
      offset: 0,
      caseTypeFilter: "",
      clientFilter: "",
      claimNumberFilter: "",
      invoiceNumberFilter: "",
      docPendingFilter: false,
      paymentPendingFilter: false,
      paymentUploadPendingFilter: false,
      paymentStatus: "",
      fromFilter: "",
      toFilter: "",
      fromPaymentDateFilter: "",
      toPaymentDateFilter: "",
      Reinvestigation: false,
      Pushback: false,
    });
    await this.loadData();
  };

  private onPaymentSectionUpdated = async (prop: PaymentSection) => {
    const paymentSection = this.state.paymentSection;
    const index = paymentSection.findIndex((it) => it.getId() === prop.getId());
    if (index !== -1) paymentSection[index] = prop;

    this.setState({
      paymentSection: [...paymentSection],
      update_modalOpen: false,
      update_model: null,
    });
  };

  private getPaymentSection = async () => {
    const ro: GetPaymentSectionRequestObject = {
      limit: this.state.limit,
      offset: this.state.offset,
    };

    if (this.state.filter) ro.search = this.state.filter;

    if (this.state.claimNumberFilter)
      ro.claim_number = this.state.claimNumberFilter;
    if (this.state.invoiceNumberFilter)
      ro.invoice_number = this.state.invoiceNumberFilter;
    if (this.state.clientFilter) ro.client_id = this.state.clientFilter;
    if (this.state.caseTypeFilter) ro.case_type_id = this.state.caseTypeFilter;

    // if(this.state.fromFilter) ro.from = this.state.fromFilter;
    // if(this.state.toFilter) ro.to = this.state.toFilter;
    if (this.state.fromFilter) ro.from_invoice_date = this.state.fromFilter;
    if (this.state.toFilter) ro.to_invoice_date = this.state.toFilter;

    if (this.state.fromPaymentDateFilter)
      ro.from_payment_date = this.state.fromPaymentDateFilter;
    if (this.state.toPaymentDateFilter)
      ro.to_payment_date = this.state.toPaymentDateFilter;

    ro.docReceived = this.state.docPendingFilter;
    ro.paymentReceiptUploadPending = this.state.paymentUploadPendingFilter;
    ro.paymentPending = this.state.paymentPendingFilter;
    if (this.state.paymentStatus.trim().length > 0)
      ro.paymentStatus = this.state.paymentStatus;
      if (this.state.Reinvestigation) ro.reinvestigation = this.state.Reinvestigation
      if (this.state.Pushback) ro.pushback = this.state.Pushback
  
    return await this.paymentSectionRepo.getData(ro);
  };

  private bulkPaymentUploadResponse = (rs: any) => {
    if ((rs as number[]).length > 0)
      this.setState({
        uploadTemplateModalOpen: false,
        bulkPaymentUploadResponseStatus: true,
        bulkPaymentUploadResponse: rs,
      });
    else this.setState({ uploadTemplateModalOpen: false });
    this.loadData();
  };

  private bulkInvoiceReceiptUploadResponse = (rs: any) => {
    this.setState({
      paymentResponseSegmentData: rs,
      paymentResponseSegmentStatus: true,
      paymentReceiptModalOpen: false,
    });
    this.loadData();
  };

  /**
   * loads new data
   * if offset is 0 then set it as new list
   * else appends to the old list
   */
  private loadData = async () => {
    let offset = this.state.offset;

    this.setState({ loading: true }, async () => {
      const result = await this.getPaymentSection();

      if (result instanceof Result.Success) {
        if (offset === 0) {
          //if offset 0 initialize as new list
          const invoice = result.data.items;
          const totalCount = result.data.totalItemCount;
          this.setState({
            loading: false,
            offset: invoice.length,
            paymentSection: invoice,
            totalCount: totalCount,
          });
        } else {
          //else check for duplicate items in the new data
          // and then concat with the old
          const paymentSection = [
            ...this.state.paymentSection,
            ...result.data.items,
          ];
          const offset = paymentSection.length;
          this.setState({ loading: false, offset, paymentSection });
        }
      } else {
        const message = result.message || "Something went wrong";
        this.setState({ loading: false }, () => toast.error(message));
      }
    });
  };

  private fetchData = async () => {
    await this.setState({ limit: 100, offset: 0 });
    if (
      this.state.caseTypeFilter ||
      this.state.paymentPendingFilter ||
      this.state.paymentUploadPendingFilter ||
      this.state.docPendingFilter ||
      this.state.fromFilter ||
      this.state.toFilter ||
      this.state.clientFilter ||
      this.state.claimNumberFilter ||
      this.state.invoiceNumberFilter ||
      this.state.filter ||
      this.state.fromPaymentDateFilter ||
      this.state.toPaymentDateFilter ||
      this.state.paymentStatus||
      this.state.Pushback ||
      this.state.Reinvestigation
    ) {
      await this.setState({ offset: 0 });
    }
    this.loadData();
  };

  private effectLoadEssentialData = async () => {
    if (!this.state.loadingEssentialData) return;
    const p1 = this.clientRepo.getClients();

    const p2 = this.caseTypeRepo.getCaseTypes({});
    const results = await Promise.all([p1, p2]);

    if (results[0] instanceof Result.Success) {
      this.setState({
        clientOptions: results[0].data.items.map((it) => ({
          text: it.getName(),
          value: it.getId(),
          key: it.getId(),
        })),
      });
    } else {
      const message = results[0].message || "Could not load Clients";
      toast.error(message);
    }

    if (results[1] instanceof Result.Success) {
      this.setState({
        caseTypeOptions: results[1].data.items.map((it) => ({
          text: it.getTitle(),
          value: it.getId(),
          key: it.getId(),
        })),
      });
    } else {
      const message = results[1].message || "Could not load Case Types";
      toast.error(message);
    }

    this.setState({ loadingEssentialData: false });
  };

  private setDocPendingFilter = (val: boolean) =>
    this.setState({ docPendingFilter: val });
  private setPaymentPendingFilter = (val: boolean) =>
    this.setState({ paymentPendingFilter: val });
  private setSearchByPaymentStatus = (val: string) =>
    this.setState({ paymentStatus: val });
  private setPaymentUploadPendingFilter = (val: boolean) =>
    this.setState({ paymentUploadPendingFilter: val });
  private setClientFilter = (val: string) =>
    this.setState({ clientFilter: val });
  private setCaseTypeFilter = (val: string) =>
    this.setState({ caseTypeFilter: val });
  private setClaimNumberFilter = (val: string) =>
    this.setState({ claimNumberFilter: val });
  private setInvoiceNumberFilter = (val: string) =>
    this.setState({ invoiceNumberFilter: val });
  private setFromFilter = (val: string) => this.setState({ fromFilter: val });
  private setToFilter = (val: string) => this.setState({ toFilter: val });
  private setFromPaymentDateFilter = (val: string) =>
    this.setState({ fromPaymentDateFilter: val });
  private setToPaymentDateFilter = (val: string) =>
    this.setState({ toPaymentDateFilter: val });
    private setReinvestigation = (val: boolean) => this.setState({ Reinvestigation: val })
    private setPushback = (val: boolean) => this.setState({ Pushback: val })
  

  render() {
    let paymentUploadResponse = null;
    if (this.state.bulkPaymentUploadResponseStatus) {
      paymentUploadResponse = (
        <Segment>
          <h4>
            Rows Skipped while bulk Uploading &nbsp;&nbsp;
            <Button
              onClick={() =>
                this.setState({
                  bulkPaymentUploadResponseStatus: false,
                  bulkPaymentUploadResponse: [],
                })
              }
            >
              Dismiss
            </Button>{" "}
          </h4>
          <p>
            {this.state.bulkPaymentUploadResponse.map((it) => {
              return <span style={{ padding: "10px" }}>{it} &nbsp; </span>;
            })}
          </p>
        </Segment>
      );
    }

    let paymentReceiptUploadResponse = null;
    if (this.state.paymentResponseSegmentStatus) {
      paymentReceiptUploadResponse = (
        <Segment>
          <h4>
            Files with these names are skipped &nbsp;&nbsp;
            <Button
              onClick={() =>
                this.setState({
                  paymentResponseSegmentStatus: false,
                  paymentResponseSegmentData: [],
                })
              }
            >
              Dismiss
            </Button>{" "}
          </h4>
          <p>
            {this.state.paymentResponseSegmentData.map((it) => {
              return <span style={{ padding: "10px" }}>{it} &nbsp; </span>;
            })}
          </p>
        </Segment>
      );
    }

    return (
      <FilterPageContext.Provider
        value={{
          options: {
            client: this.state.clientOptions,
            caseType: this.state.caseTypeOptions,
          },
          filters: {
            client: this.state.clientFilter,
            setClient: this.setClientFilter,
            caseType: this.state.caseTypeFilter,
            setCaseType: this.setCaseTypeFilter,
            claimNumber: this.state.claimNumberFilter,
            setClaimNumber: this.setClaimNumberFilter,
            invoiceNumber: this.state.invoiceNumberFilter,
            setInvoiceNumber: this.setInvoiceNumberFilter,
            from: this.state.fromFilter,
            setFrom: this.setFromFilter,
            to: this.state.toFilter,
            setTo: this.setToFilter,
            fromPaymentDate: this.state.fromPaymentDateFilter,
            setFromPaymentDate: this.setFromPaymentDateFilter,
            toPaymentDate: this.state.toPaymentDateFilter,
            setToPaymentDate: this.setToPaymentDateFilter,
            applyFilters: this.fetchData,
            clearAllFilters: this.clearAllFilters,
            searchByDocPending: this.state.docPendingFilter,
            searchByPaymentPending: this.state.paymentPendingFilter,
            searchByUploadPending: this.state.paymentUploadPendingFilter,
            setSearchByDocPending: this.setDocPendingFilter,
            setSearchByPaymentPending: this.setPaymentPendingFilter,
            setSearchByUploadPending: this.setPaymentUploadPendingFilter,
            searchByPaymentStatus: this.state.paymentStatus,
            setSearchByPaymentStatus: this.setSearchByPaymentStatus,

            Reinvestigation: this.state.Reinvestigation,
            setReinvestigation: this.setReinvestigation,
            Pushback: this.state.Pushback,
            setPushback: this.setPushback,
          },
        }}
      >
        <Page title="Payment Record" description="Manage payment related data">
          {paymentUploadResponse}
          {paymentReceiptUploadResponse}
          <Segment.Group>
            <Segment>
              <Modal
                open={this.state.downloadTemplateModalOpen}
                size="mini"
                closeIcon
                closeOnDimmerClick={false}
                closeOnEscape={false}
                onClose={() =>
                  this.setState({ downloadTemplateModalOpen: false })
                }
                trigger={
                  <span>
                    <label style={{ marginRight: 16 }}>Step 1:</label>
                    <Button
                      primary
                      onClick={() =>
                        this.setState({ downloadTemplateModalOpen: true })
                      }
                    >
                      Download Template
                    </Button>
                  </span>
                }
              >
                <Modal.Header>Download Template</Modal.Header>
                <Modal.Content>
                  <PaymentTemplateDownloadForm
                    onSuccess={() => {
                      this.setState({ downloadTemplateModalOpen: false });
                    }}
                  />
                </Modal.Content>
              </Modal>

              <Icon name="arrow right" style={{ margin: "0 2rem" }} />
              <label>Step 2: Fill it.</label>
              <Icon name="arrow right" style={{ margin: "0 2rem" }} />
              <Modal
                open={this.state.uploadTemplateModalOpen}
                size="mini"
                closeIcon
                closeOnDimmerClick={false}
                closeOnEscape={false}
                onClose={() =>
                  this.setState({ uploadTemplateModalOpen: false })
                }
                trigger={
                  <span>
                    <label style={{ marginRight: 16 }}>Step 3:</label>
                    <Button
                      onClick={() => {
                        this.setState({ uploadTemplateModalOpen: true });
                      }}
                      primary
                    >
                      Upload Payment Template
                    </Button>
                  </span>
                }
              >
                <Modal.Header>Upload Payment Template</Modal.Header>
                <Modal.Content>
                  <UploadBulkPaymentForm
                    onSuccess={this.bulkPaymentUploadResponse}
                  />
                </Modal.Content>
              </Modal>

              <Icon name="arrow right" style={{ margin: "0 2rem" }} />
              <Modal
                open={this.state.paymentReceiptModalOpen}
                size="mini"
                closeIcon
                closeOnDimmerClick={false}
                closeOnEscape={false}
                onClose={() =>
                  this.setState({ paymentReceiptModalOpen: false })
                }
                trigger={
                  <span>
                    <label style={{ marginRight: 16 }}>Step 4:</label>
                    <Button
                      onClick={() => {
                        this.setState({ paymentReceiptModalOpen: true });
                      }}
                      primary
                    >
                      Upload Payment Advice
                    </Button>
                  </span>
                }
              >
                <Modal.Header>Upload Payment Advice</Modal.Header>
                <Modal.Content>
                  <UploadBulkPaymentReceiptForm
                    onSuccess={this.bulkInvoiceReceiptUploadResponse}
                  />
                </Modal.Content>
              </Modal>
            </Segment>
          </Segment.Group>

          <Segment>
            <Filters />
          </Segment>

          {/*Update invoice section Modal */}
          <Modal
            open={this.state.update_modalOpen}
            onClose={() =>
              this.setState({ update_modalOpen: false, update_model: null })
            }
            size="tiny"
            closeIcon
            closeOnDimmerClick={false}
            closeOnEscape={false}
          >
            <Modal.Header>Update Information</Modal.Header>
            <Modal.Content>
              <PaymentUpdateForm
                model={this.state.update_model || undefined}
                onSuccess={this.onPaymentSectionUpdated}
              />
            </Modal.Content>
          </Modal>
          {/*Update invoice section Modal */}

          <Table
            renderer={this.columnRenderer}
            data={this.state.paymentSection}
            totalDataCount={this.state.totalCount}
            loading={this.state.loading}
            load={this.loadData}
            onSearch={this.setFilter}
            onClear={() => this.setFilter("")}
          />
        </Page>
      </FilterPageContext.Provider>
    );
  }
}

export default (props: any) => {
  return (
    <RequirePermission permission={permissions.Billing.update}>
      <PaymentSectionPage {...props} />
    </RequirePermission>
  );
};
