import React from "react";
import DataTable, { IDataTableProps } from "react-data-table-component";
import "./index.css";

interface Props<T> extends IDataTableProps<T> {}
const List = (props: Props<{}>) => {
  return (
    <DataTable
      {...props}
      noTableHead={true}
      noHeader={true}
      customTheme={{ rows: { borderStyle: "none" } }}
      style={{ overflowX: "unset" }}
    />
  );
};

export default List;
