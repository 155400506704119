import React from "react";

import SidebarLayout from "./SidebarLayout";
import HeaderLayout from "./HeaderLayout";

import "./index.css";
import constants from "./../../../common/constants";

interface Props {
  header: JSX.Element;
  sidebar: JSX.Element;
}

interface State {
  isSidebarOpen: boolean;
}

class NavigationLayout extends React.Component<Props, State> {
  //header
  private static HEADER_ID = "header";

  //body
  private static BODY_ID = "body";

  //sidebar
  private static SIDEBAR_WIDTH = `${constants.sidebarWidth}px`;
  private static SIDEBAR_ID = "sidebar";
  private static HIDE_SIDEBAR_WINDOW_THRESHOLD = 700;

  constructor(props: Props) {
    super(props);
    this.state = { isSidebarOpen: true };
  }

  private Header = (): JSX.Element => {
    const props = {
      id: NavigationLayout.HEADER_ID,
      onHeightChanged: this.onHeaderHeightChange,
      className: "app-header-layout",
      children: this.props.header,
    };
    return <HeaderLayout {...props} />;
  };

  private onHeaderHeightChange = (newHeight: string | null) => {
    const body = document.getElementById(NavigationLayout.BODY_ID);
    if (body && newHeight) {
      body.style.transform = `translateY(${newHeight})`;
      body.style.height = `calc(100vh - ${newHeight})`;
    }
  };

  private Sidebar = (): JSX.Element => {
    const props = {
      id: NavigationLayout.SIDEBAR_ID,
      width: NavigationLayout.SIDEBAR_WIDTH,
      show: this.state.isSidebarOpen,
      className: "app-sidebar-layout",
      children: this.props.sidebar,
    };
    return <SidebarLayout {...props} />;
  };

  toggleSidebarState = (): void =>
    this.setState((currentState) => {
      return { isSidebarOpen: !currentState.isSidebarOpen };
    });

  render(): JSX.Element {
    return (
      <React.Fragment>
        <this.Header />
        <div id={NavigationLayout.BODY_ID} className="app-body">
          <this.Sidebar />
          <div className="app-page-layout">{this.props.children}</div>
        </div>
      </React.Fragment>
    );
  }

  componentDidMount() {
    this._initWindowResizeListener();
  }

  /**
   * Window Resize Listener will be used to check window width,
   * if its lower then the set threshold then I will toggle the sidebar
   * to hidden state
   */
  private _initWindowResizeListener = () => {
    window.addEventListener("resize", this._onWindowResize);
  };

  private _onWindowResize = () => {
    const windowWidth = window.innerWidth;
    if (
      windowWidth <= NavigationLayout.HIDE_SIDEBAR_WINDOW_THRESHOLD &&
      this.state.isSidebarOpen
    ) {
      this.setState({ isSidebarOpen: false });
    }
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this._onWindowResize);
  }
}

export default NavigationLayout;
