import DomainModel from './DomainModel'

export default class InvestigatorActivity extends DomainModel {
    constructor(public _id: string, private investigator_name: string, private case_data: [], private distance: any,) {

        super()
    }

    getId = () => this._id
    getInvestigatorName = () => this.investigator_name
    getCaseData = () => this.case_data
    getDistance = () => this.distance
}
