import DomainModel from './DomainModel'

class CaseCount extends DomainModel {
  constructor(private state: string, private count: number) {
    super()
  }

  getState = () => this.state
  getCount = () => this.count
}

export default CaseCount
