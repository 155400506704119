import React, { useState, useEffect } from "react";
import { Form, Dropdown, Modal, Popup, Button } from "semantic-ui-react";
import FormLabel from "../../common/FormLabel";
import Hospital from "../../../models/Hospital";
import HospitalRepository from "../../../common/repository/HospitalRepository";
import HospitalForm from "../../common/HospitalForm";
import Result from "../../../common/repository/Result";
import { toast } from "../../common/Toast";
import CaseFormButtonText from "./CaseFormButtonText";

/**
 * Render this field inside a <Form />
 */

interface CaseFormInputHospitalProps {
  value: string;
  onChange(value: string): void;
  hospitalRepo: HospitalRepository;

  loading?: boolean;
  hospitals?: Hospital[];
  labelWidth?: string;
  required?: boolean;
}

// FIXME derive loading and options from props to state properly
const CaseFormInputHospital = (props: CaseFormInputHospitalProps) => {
  const { value, onChange, hospitalRepo, hospitals, labelWidth, required } =
    props;

  const toOption = (hospital: Hospital) => {
    return {
      text: hospital.getName(),
      value: hospital.getId(),
      key: hospital.getId(),
      description: hospital.getDistrict(),
    };
  };

  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState(
    (hospitals && hospitals.map((it) => toOption(it))) || []
  );
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (hospitals !== undefined)
      setOptions(hospitals.map((it) => toOption(it)));
  }, [hospitals]);

  useEffect(() => {
    setLoading(loading);
  }, [loading]);

  useEffect(() => {
    if (hospitals !== undefined) return;

    setLoading(true);
    (async () => {
      const result = await hospitalRepo.getHospitals();
      if (result instanceof Result.Success) {
        const items = result.data.items;
        setOptions(items.map((it) => toOption(it)));
      } else {
        const message = result.message || "Could not load hospitals";
        toast.error(message);
      }
      setLoading(false);
    })();
  }, []);

  const width = labelWidth || "100px";
  return (
    <Form.Group inline>
      <Form.Field required={required}>
        <FormLabel width={width}>Hospital</FormLabel>
        <Dropdown
          search
          selection
          loading={loading}
          placeholder="Select Hospital"
          value={value}
          onChange={(_, { value }) => onChange(value as string)}
          options={options}
        />
      </Form.Field>
      <Form.Field>
        <Popup
          inverted
          position="top center"
          content="Clear Selection"
          trigger={
            <Button size="mini" icon="erase" onClick={() => onChange("")} />
          }
        />
      </Form.Field>
      <Form.Field>
        <Modal
          size="tiny"
          closeIcon
          closeOnDimmerClick={false}
          closeOnEscape={false}
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          // trigger={
          //   <CaseFormButtonText onClick={() => setModalOpen(true)}>
          //     Add New
          //   </CaseFormButtonText>
          // }
        >
          <Modal.Header>Add New Hospital</Modal.Header>
          <Modal.Content>
            <HospitalForm
              onSuccess={(hospital) => {
                setModalOpen(false);
                setOptions([toOption(hospital), ...options]);
              }}
            />
          </Modal.Content>
        </Modal>
      </Form.Field>
    </Form.Group>
  );
};

export default React.memo(CaseFormInputHospital);
