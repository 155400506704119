import DomainModel from './DomainModel'

class DocumentPendency extends DomainModel {
  constructor(
    private id: string,
    private caseId: string,
    private claimNumber: string,
    private clientId: string,
    private clientName: string,
    private closeDate: string,

    private investigationId: string,
    private investigatorEmpId: string,
    private investigatorId: string,
    private investigatorName: string,
    private documentCharges: number,
    private approvedCharges: number,
    private extraCharges: number,
    private documentReceiptsArrived: boolean,
    private approvedReceiptsArrived: boolean,
    private extraReceiptsArrived: boolean,
    private evidenceArrived: boolean,
    private casePortion: number | string,
    private investigationLocation: string,
    private travelAllowance: number | string
  ) {
    super()
  }

  getId = () => this.id
  getCaseId = () => this.caseId
  getClaimNumber = () => this.claimNumber
  getClientId = () => this.clientId
  getClientName = () => this.clientName
  getCloseDate = () => this.closeDate

  getInvestigationId = () => this.investigationId
  getInvestigatorId = () => this.investigatorId
  getInvestigatorEmpId = () => this.investigatorEmpId
  getInvestigatorName = () => this.investigatorName
  getDocumentCharges = () => this.documentCharges
  getApprovedCharges = () => this.approvedCharges
  getExtraCharges = () => this.extraCharges
  getDocumentReceiptStatus = () => this.documentReceiptsArrived
  getApprovedReceiptStatus = () => this.approvedReceiptsArrived
  getExtraReceiptStatus = () => this.extraReceiptsArrived
  getEvidenceStatus = () => this.evidenceArrived

  getCasePortion = () => this.casePortion
  getInvestigationLocation = () => this.investigationLocation
  getTravelAllowance = () => this.travelAllowance
}

export default DocumentPendency
