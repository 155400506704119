import React, { useContext } from "react";
import { Grid, Form, Header } from "semantic-ui-react";
import FilterPageContext from "./FilterPageContext";
import DateTimePicker from "../../../app/DateTimePicker";

function Filters() {
    const ctx = useContext(FilterPageContext);

    let data: { key: string; value: any }[] = [];
    if (ctx.filters.to) data.push({ key: "to", value: ctx.filters.to });
    if (ctx.filters.from) data.push({ key: "from", value: ctx.filters.from });
    if (ctx.filters.applier)
        data.push({ key: "applier", value: ctx.filters.applier });
    if (ctx.filters.status) data.push({ key: "status", value: ctx.filters.status });

    return (
        <>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Header as="h4">Filter</Header>
            </div>
            <Grid columns={5}>
                <Grid.Row>
                    <Grid.Column>
                        <Form.Input label="Start Date">
                            <DateTimePicker
                                placeholder="Select Start Date"
                                type="date"
                                value={ctx.filters.from}
                                onChange={ctx.filters.setFrom}
                            />
                        </Form.Input>
                    </Grid.Column>
                    <Grid.Column>
                        <Form.Input label="End Date">
                            <DateTimePicker
                                placeholder="Select End Date"
                                type="date"
                                value={ctx.filters.to}
                                onChange={ctx.filters.setTo}
                            />
                        </Form.Input>
                    </Grid.Column>
                    <Grid.Column>
                        <Form.Dropdown
                            label="Status"
                            search
                            selection
                            placeholder="Select Status"
                            options={ctx.options.statusData}
                            value={ctx.filters.status}
                            onChange={(_, { value }) => ctx.filters.setStatus(value as string)}
                        />
                    </Grid.Column>

                    <Grid.Column>
                        <Form.Input
                            label="Applier "
                            placeholder="Enter applier name"
                            value={ctx.filters.applier}
                            onChange={(_, { value }) => ctx.filters.setApplier(value)}
                        />
                    </Grid.Column>

                    <Grid.Column>
                        &nbsp;
                        <div className="ui buttons">
                            <button
                                className="ui blue button"
                                onClick={ctx.filters.applyFilters}
                            >
                                Apply Filters
                            </button>
                            <button
                                className="ui button"
                                onClick={ctx.filters.clearAllFilters}
                            >
                                Clear
                            </button>
                        </div>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </>
    );
}

export default Filters;
