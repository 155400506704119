export default {
  Case: {
    initiate: 'Case.initiate',
    generate: 'Case.generate',
    allocate: 'Case.allocate',
    fold: 'Case.fold',
    check: 'Case.check',
    pushback: 'Case.pushback',
    close: 'Case.close',
    grade: 'Case.grade',
    withdraw: 'Case.withdraw',
    remove: 'Case.remove',
     reinvestigate: 'Case.reinvestigate',
    View: {
      initiated: 'Case.View.initiated',
      generated: 'Case.View.generated',
      open: 'Case.View.open',
      reinvestigate: 'Case.View.reinvestigate',
      fold: 'Case.View.fold',
      checked: 'Case.View.checked',
      close: 'Case.View.close',
      graded: 'Case.View.graded',
    },
    Trigger: {
      update: 'Case.Trigger.update',
    },
    Remark: {
      update: 'Case.Remark.update',
    },
    Reminder: {
      update: 'Case.Reminder.update',
    },
    Investigator: {
      remove: 'Case.Investigator.remove',
    },
  },
  Manage: {
    CRUD: 'Manage.CRUD',
  },
  Master: {
    CRUD: 'Master.CRUD',
  },
  Setting: {
    update: 'Setting.update',
  },
  User: {
    login: 'User.login',
  },
  Billing: {
    update: 'Billing.update',
  },
  Evaluation: {
    find: 'Evaluation.find',
  },
  Backup: 'Backup',
}
