import styled from "styled-components";

const LabelContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  .ui {
    margin: 2px;
  }
`;

export default LabelContainer;
