import React from 'react'
import { DropdownItemProps } from 'semantic-ui-react'

interface ContextType {
    options: Options
    filters: Filters
}

interface Options {
    statusData: DropdownItemProps[]
}

interface Filters {
    //   client: string
    //   setClient(client: string): void
    status: string
    setStatus(status: string): void
    applier: string
    setApplier(applier: string): void
    from: string
    setFrom(from: string): void
    to: string
    setTo(to: string): void
    applyFilters(): void
    clearAllFilters(): void
}

const Context = React.createContext({} as ContextType)
export default Context
