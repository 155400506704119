import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Segment, Header, Button, Icon, Label } from "semantic-ui-react";
import CompleteCase from "../../../../../../models/CompleteCase";
import InvestigationCard from "./InvestigationCard";
import CaseRepository from "../../../../../../common/repository/CaseRepository";
import Result from "../../../../../../common/repository/Result";
import { toast } from "../../../../../common/Toast";
import QCData from "../../../../../../models/QCData";
import FilesPopup from "../../FilesPopup";
import URLResource from "../../../../../../models/URLResource";

interface Props {
  model: CompleteCase;
}

interface State {
  loading: boolean;
  pushbackModalOpen: boolean;
  repudiationEvidencePopupOpen: boolean;
  data: QCData | null;
}

class ActionFinalReport extends Component<Props, State> {
  private caseRepo = new CaseRepository();
  private portalRoot: HTMLElement | null = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      pushbackModalOpen: false,
      repudiationEvidencePopupOpen: false,
      data: null,
    };
  }

  componentDidMount() {
    this.getData();
    this.portalRoot = document.getElementsByTagName("body")[0];
  }

  getData = () => {
    this.setState({ loading: true }, async () => {
      const { model } = this.props;
      const result = await this.caseRepo.getQCData({ case_id: model.getId() });

      if (result instanceof Result.Success) {
        const data = result.data;
        this.setState({ loading: false, data: data });
      } else {
        this.setState({ loading: false }, () => {
          const message = result.message || "Something went wrong";
          toast.error(message);
        });
      }
    });
  };

  render() {
    const { model } = this.props;

    let allocations = null;
    if (this.state.data !== null)
      allocations = this.state.data
        .getAllocations()
        .map((it) => <InvestigationCard model={it} newData={model} />);

    let report = null;
    if (this.state.data !== null) report = this.state.data.getReport();

    let finalReport = this.props.model.getFinalReport();

    let queryTriggerDocument: any;
    if (this.state.data !== null)
      queryTriggerDocument = this.state.data.getQueryTriggerDocument();


    let repudiationEvidence: URLResource[] = [];
    if (this.state.data !== null)
      repudiationEvidence = this.state.data.getRepudiationEvidences();

    return (
      <Segment basic loading={this.state.loading}>
        <Header as="h3">
          Case Report
          {/*<Header.Subheader>*/}
          {/*    Case Type: {model.getType().getTitle()}*/}
          {/*</Header.Subheader>*/}
        </Header>

        {/* report */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "flex-end",
          }}
        >
          <div>
            {/* report */}
            <Button
              as="a"
              href={report && report.getURL()}
              disabled={report === null}
              target="_blank"
              icon
              primary
              labelPosition="left"
              size="tiny"
            >
              <Icon name="file alternate outline" />
              View Report
            </Button>
            {/* report */}

            {/* final report */}
            <Button
              as="a"
              href={finalReport && finalReport.getURL()}
              disabled={finalReport === null}
              target="_blank"
              icon
              primary
              labelPosition="left"
              size="tiny"
            >
              <Icon name="file alternate outline" />
              View Final Report
            </Button>
            {/* final report */}

            {/* repudiation evidence */}
            <Button
              icon
              primary
              labelPosition="left"
              size="tiny"
              disabled={
                this.state.repudiationEvidencePopupOpen ||
                repudiationEvidence.length === 0
              }
              onClick={() =>
                this.setState({ repudiationEvidencePopupOpen: true })
              }
            >
              <Icon name="file alternate outline" />
              {repudiationEvidence.length === 0
                ? "No Repudiation Evidence"
                : "View Repudiation Evidence"}
            </Button>
            {/* repudiation evidence */}
            <Label basic size={"large"} color={"blue"}>
              Case Type{" "}
              <Label.Detail>{model.getType().getTitle()}</Label.Detail>
            </Label>
            <Label basic size={"large"} color={"blue"}>
              Case Outcome <Label.Detail>{model.getOutcome()}</Label.Detail>
            </Label>

           {queryTriggerDocument?.getURL() !== null && <Button
              as="a"
              href={queryTriggerDocument && queryTriggerDocument.getURL()}
              disabled={queryTriggerDocument === null}
              target="_blank"
              icon
              primary
              labelPosition="left"
              size="tiny"
            >
              <Icon name="file alternate outline" />
              Query Trigger Document
            </Button>}


            {this.state.repudiationEvidencePopupOpen &&
              ReactDOM.createPortal(
                <FilesPopup
                  title="Repudiation Evidence"
                  onClose={() =>
                    this.setState({ repudiationEvidencePopupOpen: false })
                  }
                  files={repudiationEvidence}
                />,
                this.portalRoot!
              )}
          </div>
        </div>

        {allocations}
      </Segment>
    );
  }
}

export default ActionFinalReport;
