import React from "react";
import ForgetPasswordLayout from "./ForgetPasswordLayout";
import { useSelector } from "react-redux";
import { AppState } from "../../../redux";
import { Redirect, useLocation } from "react-router-dom";

const ForgetPasswordPage = () => {
  const authState = useSelector((state: AppState) => state.auth);
  const location = useLocation();

  if (authState.user !== null) {
    const path = location.state || "/";
    return <Redirect to={path} />;
  }

  return <ForgetPasswordLayout />;
};

export default ForgetPasswordPage;
