import DomainModel from './../DomainModel'

class AttendentInfoData extends DomainModel {
    constructor(
        private alternativePhone: string,
        private contactNumber: string,
        private email: string,
        private hospitalData: { name: string },
        private investigatorName: string,
        private isFieldSaved: string,
        private occupation: string,
        private occupationAddress: string,
        private occupationId: string,
        private relationWithInsured: string,
        private supportingDocuments: any,
    ) {
        super()
    }
    getAlternativePhone = () => this.alternativePhone
    getContactNumber = () => this.contactNumber
    getEmail = () => this.email
    getHospitalData = () => this.hospitalData
    getInvestigatorName = () => this.investigatorName
    getIsFieldSaved = () => this.isFieldSaved
    getOccupation = () => this.occupation
    getOccupationAddress = () => this.occupationAddress
    getOccupationId = () => this.occupationId
    getRelationWithInsured = () => this.relationWithInsured
    getSupportingDocuments = () => this.supportingDocuments

}

export default AttendentInfoData
