import { createContext } from 'react'
import AttendanceRepository from '../../../../common/repository/AttendanceRepository'
import { DropdownItemProps } from 'semantic-ui-react'

interface ContextType {
  attendanceRepo: AttendanceRepository
  today: Date
  monthOptions: DropdownItemProps[]
  yearOptions: DropdownItemProps[]
}

export default createContext({} as ContextType)
