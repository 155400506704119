import React, { useState, useContext } from "react";
import { Form } from "semantic-ui-react";
import axios from "axios";
import LocalStorage from "../../../../lib/LocalStorage";
import ReportPageContext from "./ReportPageContext";
import config from "../../../../common/repository/config";


function DownloadTemplateForm() {
  const ctx = useContext(ReportPageContext);
  const m = ctx.today.getMonth() + 1;
  const [month, setMonth] = useState(m < 10 ? `0${m}` : m.toString());
  const [year, setYear] = useState(ctx.today.getFullYear().toString());

  return (
    <Form>
      <Form.Dropdown
        label="Month"
        search
        selection
        value={month}
        options={ctx.monthOptions}
        onChange={(_, { value }) => setMonth(value as string)}
      />

      <Form.Dropdown
        label="Year"
        search
        selection
        value={year}
        options={ctx.yearOptions}
        onChange={(_, { value }) => setYear(value as string)}
      />

      <Form.Button positive onClick={downloadTemplate}>
        Download
      </Form.Button>
    </Form>
  );

  async function downloadTemplate() {
    const token = LocalStorage.get(LocalStorage.KEY_AUTH_TOKEN);
    const response = await axios({
      url: config.apiBaseURL+`/attendance/template/${month}-${year}`,
      method: "GET",
      responseType: "blob",
      headers: { ["x-access-token"]: token },
    });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "template.xlsx");
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
}

export default DownloadTemplateForm;
