import React from "react";

export const textColors = {
  black: "#222222",
  gray: "#676767",
};

interface TextProps extends BaseTextProps {
  size?: string;
  weight?: number;
  color?: string;

  as?: "p" | "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
}

interface BaseTextProps {
  children?: React.ReactNode;
}

export const Title = (props: BaseTextProps) => (
  <Text as="h2">{props.children}</Text>
);
export const SubTitle = (props: BaseTextProps) => (
  <Text as="h4">{props.children}</Text>
);
export const Description = (props: BaseTextProps) => (
  <Text color={textColors.gray}>{props.children}</Text>
);

const Text = (props: TextProps) => {
  const color = props.color || textColors.black;
  const defaultStyles = { color };
  let fontSize: string, fontWeight: number;
  let style: {};

  switch (props.as) {
    case "h1":
      fontSize = props.size || "34px";
      fontWeight = props.weight || 700;
      style = { ...defaultStyles, fontSize, fontWeight };
      return <h1 style={style}>{props.children}</h1>;

    case "h2":
      fontSize = props.size || "27px";
      fontWeight = props.weight || 700;
      style = { ...defaultStyles, fontSize, fontWeight };
      return <h2 style={style}>{props.children}</h2>;

    case "h3":
      fontSize = props.size || "21px";
      fontWeight = props.weight || 700;
      style = { ...defaultStyles, fontSize, fontWeight };
      return <h3 style={style}>{props.children}</h3>;

    case "h4":
      fontSize = props.size || "17px";
      fontWeight = props.weight || 700;
      style = { ...defaultStyles, fontSize, fontWeight };
      return <h4 style={style}>{props.children}</h4>;

    case "h5":
      fontSize = props.size || "14px";
      fontWeight = props.weight || 700;
      style = { ...defaultStyles, fontSize, fontWeight };
      return <h5 style={style}>{props.children}</h5>;

    case "h6":
      fontSize = props.size || "11px";
      fontWeight = props.weight || 700;
      style = { ...defaultStyles, fontSize, fontWeight };
      return <h6 style={style}>{props.children}</h6>;

    case "p":
    default:
      fontSize = props.size || "14px";
      fontWeight = props.weight || 400;
      style = { ...defaultStyles, fontSize, fontWeight };
      return <p style={style}>{props.children}</p>;
  }
};

export default Text;
