import React from "react";
import { Form, Input, Radio, Segment } from "semantic-ui-react";
import Button from "../../../../elements/Button";
import InvoiceSection from "../../../../../models/InvoiceSection";
import Result from "../../../../../common/repository/Result";
import { toast } from "../../../../common/Toast";
import { RouteComponentProps } from "react-router-dom";
import ReportRepository, { GetHospitalInfraInvestigatorDetailRequestObject } from '../../../../../common/repository/ReportRepository'
import TreatingDoctorDetail from "../../../../../models/ReportModels/TreatingDoctorDetail";
import moment from "moment";
import config from "../../../../../common/repository/config"
interface Props {
  //if model is passed that means update
  model?: TreatingDoctorDetail;

  onSuccess: (invoiceSection: InvoiceSection) => void;
  onError?: () => void;
}

interface MatchProps {
  Id: string;
}
interface Props extends RouteComponentProps<MatchProps> { }

interface State {
  form_loading: boolean;
  treatingDoctorDetail: TreatingDoctorDetail[]
  case_id: string
  contactNumber: string
  createdAt: string
  doctorAvailableInHospital: string
  doctorConfirmation: string
  hospital_id: string
  investigator_id: string
  isFieldSaved: boolean
  personalMeeting: string
  personalMeetingPic: string
  qualification: string
  registrationCertificate: string
  registrationNumber: string
  treatingDoctorName: string
  updatedAt: string
  feedback: string
  feedBackID: string
}

class TreatingDoctorDetailForm extends React.Component<Props, State> {
  private ReportRepository = new ReportRepository()

  constructor(props: Props) {
    super(props);

    const stateFromModel = this.deriveStateFromModel();
    this.state = {
      form_loading: false,
      treatingDoctorDetail: [],
      feedback: "",
      feedBackID: "",
      ...stateFromModel,
    };
    console.log(this.props.model, "model")
  }


  componentDidMount() {
    this.getTreatingDoctorDetail()
  }
  private deriveStateFromModel = () => {
    const model = this.props.model;
    return {
      case_id: (model && model.getCase_id()) || "",
      contactNumber: (model && model.getContactNumber()) || "",
      createdAt: (model && model.getCreatedAt()) || "",
      doctorAvailableInHospital: (model && model.getDoctorAvailableInHospital()) || "",
      doctorConfirmation: (model && model.getDoctorConfirmation()) || "",
      hospital_id: (model && model.getHospital_id()) || "",
      investigator_id: (model && model.getInvestigator_id()) || "",
      isFieldSaved: (model && model.getIsFieldSaved()) || false,
      personalMeeting: (model && model.getPersonalMeeting()) || "",
      personalMeetingPic: (model && model.getPersonalMeetingPic()) || "",
      qualification: (model && model.getQualification()) || "",
      registrationCertificate: (model && model.getRegistrationCertificate()) || "",
      registrationNumber: (model && model.getRegistrationNumber()) || "",
      treatingDoctorName: (model && model.getTreatingDoctorName()) || "",
      updatedAt: (model && model.getUpdatedAt()) || "",
    };
  };

  private updateFeedBack = async () => {
    const InveId = this.props.match.params;
    const result = await this.ReportRepository.updateTreatingDoctorFeedBack({
      type: "doctor",
      feedback: this.state.feedback,
      feedback_id: this.state.feedBackID,
      treatingdoctor_id: InveId.Id.toString(),
    });
    if (result instanceof Result.Success) {
      const state = result.data;
      toast.success("state Updated Successfully!");
    } else {
      const message = result.message || "Something went wrong";
      this.setState({ form_loading: false }, () => {
        toast.error(message);
        if (this.props.onError) this.props.onError();
      });
    }
  };


  private getTreatingDoctorDetail = () => {
    const InveId = this.props.match.params;
    this.setState({ form_loading: true }, async () => {
      const result = await this.ReportRepository.getTreatingDoctorDetail({ treating_doctor_id: InveId.Id });
      if (result instanceof Result.Success) {
        const treatingDoctorDetail = result.data.items;
        console.log(treatingDoctorDetail)
        this.setState({
          form_loading: false,
          treatingDoctorDetail: treatingDoctorDetail,
          feedback: treatingDoctorDetail[0].getFeedBack(),
          feedBackID: treatingDoctorDetail[0].getFeedBackId()
        });
      } else {
        const message = result.message || "Something went wrong";
        this.setState({ form_loading: false }, () =>
          toast.error(message)
        );
      }
    });
  };

  render(): JSX.Element {
    let noAvailable = "not available"

    return (
      <Segment >
        <h1>Treating Doctor Detail </h1>
        <Form loading={this.state.form_loading}>
          <Form.Group>
            <Form.Input
              required
              type="input"
              width="4"
              label="Treating Doctor Name"
              value={this.state.treatingDoctorDetail[0]?.getTreatingDoctorName() || noAvailable}
            />
            <Form.Input
              required
              type="input"
              width="4"
              label="Registration Number"
              value={this.state.treatingDoctorDetail[0]?.getRegistrationNumber() || noAvailable}
            />
            {this.state.treatingDoctorDetail[0]?.getRegistrationCertificate() ?
              <Form.Input
                required
                type="input"
                width="4"
                label="Registration Certificate"
              >
                {<a className={"ui green  button"}
                  href={`${config.mrdRecipt}${this.state.treatingDoctorDetail[0]?.getRegistrationCertificate()}`}
                  target={"_blank"}
                >
                  Certificate
                </a> || "not available "}
              </Form.Input> :
              <Form.Input
                required
                type="input"
                width="4"
                label="Registration Certificate"
                value={noAvailable}
              />
            }

            <Form.Input
              required
              type="input"
              width="4"
              label="Qualification"
              value={this.state.treatingDoctorDetail[0]?.getQualification() || noAvailable}
            />
          </Form.Group>
          <Form.Group>
            <Form.Input
              required
              type="input"
              width="4"
              label="Was Doctor Available in Hospital"
              value={this.state.treatingDoctorDetail[0]?.getDoctorAvailableInHospital() || noAvailable}
            />
            <Form.Input
              required
              type="input"
              width="4"
              label="Personal Meeting"
              value={this.state.treatingDoctorDetail[0]?.getPersonalMeeting() || noAvailable}
            />
            {this.state.treatingDoctorDetail[0]?.getPersonalMeetingPic() ? <Form.Input
              required
              type="input"
              width="4"
              label="Personal Meeting Image"
            >
              <a className={"ui green  button"}
                href={`${config.mrdRecipt}${this.state.treatingDoctorDetail[0]?.getPersonalMeetingPic()}`}
                target={"_blank"}
              >
                Meeting Image
              </a>
            </Form.Input> :
              <Form.Input
                required
                type="input"
                width="4"
                label="Personal Meeting Image"
                value={noAvailable}
              />
            }
            <Form.Input
              required
              type="input"
              width="4"
              label="Meeting With Doctor"
              value={noAvailable}
            />
          </Form.Group>


          <Form.Group>
            <Form.Input
              required
              type="input"
              width="4"
              label="Doctor Confirmation"
              value={this.state.treatingDoctorDetail[0]?.getDoctorConfirmation() || noAvailable}
            />
            <Form.Input
              required
              type="input"
              width="4"
              label="Doctor Contact Number"
              value={this.state.treatingDoctorDetail[0]?.getContactNumber() || noAvailable}
            />
            <Form.Input
              required
              type="input"
              width="4"
              label="Created At"
              value={moment(this.state.treatingDoctorDetail[0]?.getCreatedAt()).format("LL") || noAvailable}
            />
            <Form.Input
              required
              type="input"
              width="4"
              label="Updated At"
              value={moment(this.state.treatingDoctorDetail[0]?.getUpdatedAt()).format("LL") || noAvailable}
            />

          </Form.Group>
          <Form.Group>
            <Form.Input
              required
              type="input"
              width="4"
              label="% Of Cases Not Confirmed By Doctor"
            // value={this.state.treatingDoctorDetail[0]?.getHospital()}
            />
            <Form.Input
              required
              type="input"
              width="4"
              label="Name of Hospitals Attached to Doctor"
              value={this.state.treatingDoctorDetail[0]?.getHospitalName()}
            />
            <Form.Input
              required
              type="input"
              width="4"
              label="% Of Cases Confirmed By Doctor"
            // value={this.state.treatingDoctorDetail[0]?.getNumber_of_doctor()}
            />
          </Form.Group>

          <h1>Feedback</h1>
          <Form.Group>
            <Form.Input
              type="input"
              width="16"
              label="Feedback"
              value={this.state.feedback}
              onChange={(_, { value }) => this.setState({ feedback: value })}
            />
          </Form.Group>
          <Button
            positive
            onClick={this.updateFeedBack}
            disabled={this.state.feedback.length === 0}
          >
            Submit
          </Button>
        </Form>
      </Segment>

    );
  }
}

export default TreatingDoctorDetailForm;
