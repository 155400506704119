import React from 'react'
import Page from '../../../layout/Page'
import { Modal, Icon, Button, DropdownItemProps, Segment, Header, Form } from 'semantic-ui-react'
import Result from '../../../../common/repository/Result'
import { toast } from '../../../common/Toast'
import Table from '../../../app/Table'
import RequirePermission from '../../../base/RequirePermission'
import permissions from '../../../../common/permissions'
import InvestigatorActivityColumnRenderer from "./InvestigatorActivityColumnRenderer"
import MrdCharges from "../../../../models/MrdCharges"
// import MrdApproveForn from "./MrdApproveForm"
import config from '../../../../common/repository/config'
import InvestigatorActivity from '../../../../models/InvestigatorActivity'
import InvestigatoeActivityRepository, { InvestigatoeActivityObject } from '../../../../common/repository/InvestigatorActivityRepository'
import { Link } from 'react-router-dom'
import Filters from "./Filters"
import FilterPageContext from './FilterPageContext'
import axios from "axios";
import LocalStorage from "../../../../lib/LocalStorage";

const statusData = ["Pending", "Accepted", "Rejected"]
const statusDatas = statusData.map((it) => {
    return { text: it, value: it, key: it };
});

interface State {
    loading: boolean
    filter: string
    limit: number
    offset: number
    totalCount: number
    mrdCharge: MrdCharges[]
    investigatorActivity: InvestigatorActivity[]
    update_modalOpen: boolean
    update_model: InvestigatorActivity | null
    remove_modalOpen: boolean
    remove_model: InvestigatorActivity | null
    loadingEssentialData: boolean
    statusFilter: string
    statusOptions: DropdownItemProps[]
    fromFilter: string
    toFilter: string
}


class InvestigatorActivityPage extends React.Component<{}, State> {

    private InvestigatoeActivityRepository = new InvestigatoeActivityRepository()

    private columnRenderer = new InvestigatorActivityColumnRenderer(
        (model: InvestigatorActivity) => {
            let id = model.getId()

            return (<>

                <Button
                    style={{ width: 150 }}
                    primary
                    as={Link}
                    to={{
                        pathname: `/master/investigator-activity-detail/${id}`,
                    }}
                >
                    View
                </Button>
            </>)
        },
        (obj: any) => true
    )

    constructor(props: any) {
        super(props)

        this.state = {
            loading: false,
            limit: 10,
            offset: 0,
            filter: '',
            totalCount: 0,
            mrdCharge: [],
            investigatorActivity: [],
            update_modalOpen: false,
            update_model: null,
            remove_modalOpen: false,
            remove_model: null,

            loadingEssentialData: true,
            statusFilter: '',
            statusOptions: statusDatas,
            fromFilter: "",
            toFilter: "",
        }
    }

    async componentDidMount() {
        this.loadData()
        // await this.effectLoadEssentialData()
    }
    private onRowsPerPageChange = (page: number, rowsPerPage: number) => {
        // console.log('onRowsPerPageChange', page, rowsPerPage)
        const offset = (page - 1) * rowsPerPage,
            limit = offset + rowsPerPage
        this.setState({ offset, limit }, this.loadData)
    }

    private setFilter = (filter: string) => {
        this.setState({ filter, offset: 0 }, this.loadData)
    }

    private clearAllFilters = async () => {
        await this.setState({
            limit: 0,
            offset: 0,
            statusFilter: '',
            fromFilter: '',
            toFilter: '',
        })
        await this.loadData()
    }

    private getInvestigatoeActivity = async () => {
        const ro: InvestigatoeActivityObject = {
            limit: this.state.limit,
            offset: this.state.offset,
            search: this.state.filter,
            startDate: this.state.fromFilter,
             endDate: this.state.toFilter
        }

        // if (this.state.statusFilter) ro.accepted = this.state.statusFilter

        return await this.InvestigatoeActivityRepository.getInvestigatoeActivity(ro)
    }

    private loadData = async () => {
        if (this.state.statusFilter||this.state.fromFilter||this.state.toFilter) {
            await this.setState({ offset: 0 })
        }
        let offset = this.state.offset

        this.setState({ loading: true }, async () => {
            const result = await this.getInvestigatoeActivity()

            if (result instanceof Result.Success) {
                if (offset === 0) {
                    //if offset 0 initialize as new list
                    const investigatorActivity = result.data.items
                    const totalCount = result.data.totalItemCount
                    await this.setState({
                        loading: false,
                        offset: investigatorActivity.length,
                        investigatorActivity,
                        totalCount: totalCount,
                    })
                } else {
                    //else check for duplicate items in the new data
                    // and then concat with the old
                    const investigatorActivity = [...this.state.investigatorActivity, ...result.data.items]
                    const offset = investigatorActivity.length
                    this.setState({ loading: false, offset, investigatorActivity })
                }
            } else {
                const message = result.message || 'Something went wrong'
                await this.setState({ loading: false }, () => toast.error(message))
            }
        })
    }


    private downloadData = async () => {
        // let rs = createCountRequestObject();
        let ro: InvestigatoeActivityObject = {  startDate: this.state.fromFilter, endDate: this.state.toFilter,action:"investigatorActivityAll", };
    
        if (!ro) ro = {};
        const token = LocalStorage.get(LocalStorage.KEY_AUTH_TOKEN);
        try {
          if (Object.entries(ro).length > 0) {
            toast.success("Download will start shortly!");
            let reqData = axios({
              url: config.apiBaseURL + `/download`,
              method: "POST",
              responseType: "blob",
              data: ro,
              headers: { ["x-access-token"]: token },
            });
            reqData
              .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "investigatorActivityAll.xlsx");
                document.body.appendChild(link);
                link.click();
                link.remove();
              })
              .catch((e) => {
                toast.error("Something went wrong while downloading data!");
              });
          } else {
            toast.error("download feature not available yet!.");
          }
        } catch (e) {
          toast.error("Something went wrong while downloading");
        }
      };

    private setStatsFilter = (val: string) => this.setState({ statusFilter: val })

    private setFromFilter = (val: string) => this.setState({ fromFilter: val })
  private setToFilter = (val: string) => this.setState({ toFilter: val })

    render() {
        return (

            <FilterPageContext.Provider
            value={{
              filters: {
                from: this.state.fromFilter,
                setFrom: this.setFromFilter,
                to: this.state.toFilter,
                setTo: this.setToFilter,
                applyFilters: this.loadData,
                clearAllFilters: this.clearAllFilters,
                downloadData: this.downloadData
              },
            }}
          >
            <Page title="Investigator Activity" description="Manage Investigator Activity">
                <Segment>
                        <Filters />
                    </Segment>
                {/* Approve Modal */}
                <Modal open={this.state.update_modalOpen} onClose={() => this.setState({ update_modalOpen: false, update_model: null })} size="mini" closeIcon closeOnDimmerClick={false} closeOnEscape={false}>
                    <Modal.Header>Confirmation</Modal.Header>
                    <Modal.Content>
                        {/* <MrdApproveForn model={this.state.update_model || undefined} onSuccess={this.UpdateMrdCharge} status={"Approvel"} /> */}
                    </Modal.Content>
                </Modal>
                {/* Approve Modal */}

                {/* Decline */}
                <Modal open={this.state.remove_modalOpen} onClose={() => this.setState({ remove_modalOpen: false, remove_model: null })} size="mini" closeIcon closeOnDimmerClick={false} closeOnEscape={false}>
                    <Modal.Header>Confirmation</Modal.Header>
                    <Modal.Content>
                        {/* <MrdApproveForn model={this.state.remove_model || undefined} onSuccess={this.UpdateMrdCharge} status={"Decline"} /> */}
                    </Modal.Content>
                </Modal>
                {/* Decline */}
                <Table
                    renderer={this.columnRenderer}
                    data={this.state.investigatorActivity}
                    totalDataCount={this.state.totalCount}
                    loading={this.state.loading}
                    load={this.loadData}
                    onRowsPerPageChange={this.onRowsPerPageChange}
                    onSearch={this.setFilter}
                    onClear={() => this.setFilter('')}
                    DownloadBtn={true}
                />
            </Page>
      </FilterPageContext.Provider>

        )
    }
}

export default (props: any) => {
    return (
        // <RequirePermission permission={permissions.Master.CRUD}>
            <InvestigatorActivityPage {...props} />
        // </RequirePermission>
    )
}
