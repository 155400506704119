const RANGE_START = 0
const RANGE_END = 2

function isBetweenRange(value: number) {
  return value >= RANGE_START && value <= RANGE_END
}

export default {
  isValid: (value: string | number) => {
    if (typeof value === 'number') return isBetweenRange(value)
    return isBetweenRange(parseFloat(value))
  },
}
