import DomainModel from '../DomainModel'

export default class PathologistDatail extends DomainModel {
    constructor(
        private _id: string,
        private case_id: string,
        private contactNumber: string,
        private createdAt: string,
        private doctorAvailableInHospital: string,
        private doctorConfirmation: string,
        private hospital_id: string,
        private hospital_name: string,
        private investigator_id: string,
        private isFieldSaved: boolean,
        private pathologistName: string,
        private personalMeeting: string,
        private personalMeetingPic: string,
        private qualification: string,
        private registrationCertificate: string,
        private registrationNumber: string,
        private updatedAt: string,
        private feedback: string,
        private feedback_id: string
    ) {
        super()
    }
    getId = () => this._id
    getCase_id = () => this.case_id
    getContactNumber = () => this.contactNumber
    getCreatedAt = () => this.createdAt
    getDoctorAvailableInHospital = () => this.doctorAvailableInHospital
    getDoctorConfirmation = () => this.doctorConfirmation
    getHospital_id = () => this.hospital_id
    getHospitalName = () => this.hospital_name
    getInvestigator_id = () => this.investigator_id
    getIsFieldSaved = () => this.isFieldSaved
    getPathologistName = () => this.pathologistName
    getPersonalMeeting = () => this.personalMeeting
    getPersonalMeetingPic = () => this.personalMeetingPic
    getQualification = () => this.qualification
    getRegistrationCertificate = () => this.registrationCertificate
    getRegistrationNumber = () => this.registrationNumber
    getUpdatedAt = () => this.updatedAt
    getFeedBack = () => this.feedback
    getFeedBackId = () => this.feedback_id
}
