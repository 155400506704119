import ActionColumnRenderer from '../../../../app/Table/renderer/ActionColumnRenderer'
import PathologistInvestigator from '../../../../../models/ReportModels/PathologistInvestigator'
import moment from 'moment'

export default class PathologistInvestigatorsColumnRenderer extends ActionColumnRenderer<PathologistInvestigator> {
    getColumns() {
        return [
            {
                name: 'Name',
                selector: 'name',
            },
            {
                name: 'Claim Number',
                selector: 'claim_number',
            },
            {
                name: 'Modified On',
                selector: 'modified_on',
                format: (row: {}) => {
                    if (row instanceof PathologistInvestigator) {
                        const Date = row.getModifiedOn()
                        if (Date !== null) return moment(Date).format('LL')
                    }
                },
            },
            {
                name: 'Actions',
                selector: '_id',
                grow: 0,
                width: '180px',
                cell: this.renderActionColumn,
            },
        ]
    }
}
