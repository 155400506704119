import React from 'react'
import { DropdownItemProps } from 'semantic-ui-react'

interface ContextType {
  options: Options
  filters: Filters
}

interface Options {
  category: DropdownItemProps[]
  client: DropdownItemProps[]
  districts: DropdownItemProps[]
  user: DropdownItemProps[]
  userRoles: DropdownItemProps[]
  hospital: DropdownItemProps[]
}

interface Filters {
  category: string
  setCategory(category: string): void
  client: string
  setClient(client: string): void
  district: string
  setDistrict(district: string): void
  user: string
  setUser(user: string): void
  userRole: string
  setUserRole(userRole: string): void
  hospital: string
  setHospital(hospital: string): void
  search: string
  setSearch(search: string): void
  from: string
  setFrom(from: string): void
  to: string
  setTo(to: string): void
  viewType: string
  setViewType(viewType: string): void
  evaluationByClosedDate: boolean
  setEvaluationByClosedDate(evaluationBy: boolean): void
}

const Context = React.createContext({} as ContextType)
export default Context
