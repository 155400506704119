import React, { useContext } from 'react'
import User from '../../../../models/User'
import Role from '../../../../models/Role'
import {Form, Checkbox, Divider, Dropdown, Header, Input, Message, Button, Card} from 'semantic-ui-react'
import './../../../../extensions/date'
import UserFormPageContext from './UserFormPageContext'
import { useHistory } from 'react-router-dom'
import Salary from "../../../../models/Salary";
import {UserLocationInputValue} from "../../manage/UserFormPage/UserLocationInput";
import LocalStorage from "../../../../lib/LocalStorage";
import axios from "axios";
import {toast} from "../../../common/Toast";
interface Props {
    model?: User
    salaryMonth: string
    salaryYear: string
    salary?: Salary
    history: { push(path: string): void }
    onSuccess(user: User): void
}



interface State {
    form_loading: boolean
    form_selectedProfilePicFile: File | null

    user_id: string
    user_name: string
    user_employeeId: string
    user_email: string
    user_otherEmail: string
    user_phone: string
    user_otherPhone: string
    user_role: string
    user_parent: string
    user_primaryLocations: UserLocationInputValue[]
    user_secondaryLocations: UserLocationInputValue[]
    user_tertiaryLocations: UserLocationInputValue[]
    user_joiningDate: string
    user_profilePicURL: string | null
    user_dob: string
    user_address: string
    user_active: boolean

    earnings: {
        basic_salary: number
        hra: number
        mi_allowance: number
        special_allowance: number
        conveyance_allowance: number
        other_allowance: number
        other_allowance_remarks?: string
    }
    reimbursements: {
        travel_allowance: number
        document_expense: number
        city_allowance: number
        other_expense: number
        other_expense_remarks?: string
    }
    other_benefits: {
        case_allowance: number
        incentive: number
    }
    deductions: {
        leave_deduction: number
        bonus: number
        other_deduction: number
        other_deduction_remarks?: string
    }
    other: {
        paid_leaves: number
        unpaid_leaves: number
        available_leaves: number
        total_cases: number
    }
}

class UserForm extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        const userStateFromModel = this.deriveUserStateFromModel(this.props.model);
        const salarySateFromModel = this.deriveSalaryFromSalary(this.props.salary)
        let form_selectedRole: Role | null = null
        if (this.props.model) form_selectedRole = this.props.model.getRole()
        this.state = {
            form_loading: true,
            ...userStateFromModel,
            ...salarySateFromModel,
            form_selectedProfilePicFile: null
        }
    }

    componentDidMount() {}
    componentDidUpdate(prevProps:any) {

        if (prevProps.model !== this.props.model) {
            const stateFromModel = this.deriveUserStateFromModel(this.props.model);
            this.setState({...stateFromModel})
        }
        if(prevProps.salary !== this.props.salary){
            const salaryModel = this.deriveSalaryFromSalary(this.props.salary);
            this.setState({...salaryModel, form_loading: false})

        }
    }

    private salaryMonthString = (m: string) =>{
        let res = "";

        switch(m){
            case '1':
            case '01':
                res = "January";
                break
            case '2':
            case '02':
                res = "February";
                break
            case '3':
            case '03':
                res = "March";
                break
            case '4':
            case '04':
                res = "April";
                break
            case '5':
            case '05':
                res = "May";
                break
            case '6':
            case '06':
                res = "June";
                break
            case '7':
            case '07':
                res = "July";
                break
            case '8':
            case '08':
                res = "August";
                break
            case '9':
            case '09':
                res = "September";
                break
            case '10': res = "October";
                break
            case '11': res = "November";
                break
            case '12': res = "December";
                break
            default: res = "";
        }
        return res;
    }
    private totalEarnings = () => {
        let total = this.state.earnings.other_allowance + this.state.earnings.conveyance_allowance + this.state.earnings.special_allowance + this.state.earnings.mi_allowance + this.state.earnings.hra + this.state.earnings.basic_salary;
        return total;
    };
    private totalReimbursements = () => {
        let total = this.state.reimbursements.city_allowance + this.state.reimbursements.document_expense + this.state.reimbursements.other_expense + this.state.reimbursements.travel_allowance;
        return total;
    }
    private totalOtherBenefits = () =>{
        return this.state.other_benefits.incentive + this.state.other_benefits.case_allowance + this.state.deductions.bonus;
    }
    private totalDeductions = () =>{
        return this.state.deductions.bonus + this.state.deductions.other_deduction + this.state.deductions.leave_deduction;
    }

    private ctc = () => {
        let total = this.totalEarnings()  + this.totalOtherBenefits();
        return Math.round(total);
    }
    private netPayable = () => {
        let total = this.ctc() + this.totalReimbursements() - this.totalDeductions();
        return Math.round(total);
    }

    private deriveUserStateFromModel = (model?: User) => {
        let active = true
        if (model) 
            active = model.getActive()

        return {
            user_id: (model && model.getId()) || '',
            user_name: (model && model.getName()) || '',
            user_employeeId: (model && model.getEmployeeId()) || '',
            user_email: (model && model.getEmail()) || '',
            user_otherEmail: (model && model.getOtherEmail()) || '',
            user_phone: (model && model.getPhone()) || '',
            user_otherPhone: (model && model.getOtherPhone()) || '',
            user_role: (model && model.getRole().getId()) || '',
            user_parent: (model && model.getParent()) || '',
            user_primaryLocations: (model && model.getPrimaryLocations().map(it => ({ state: it.getState(), district: it.getDistrict() }))) || [],
            user_secondaryLocations: (model && model.getSecondaryLocations().map(it => ({ state: it.getState(), district: it.getDistrict() }))) || [],
            user_tertiaryLocations: (model && model.getTertiaryLocations().map(it => ({ state: it.getState(), district: it.getDistrict() }))) || [],
            user_joiningDate: (model && model.getJoiningDate()) || '',
            user_profilePicURL: (model && model.getProfilePicURL()) || '',
            user_dob: (model && model.getDOB()) || '',
            user_address: (model && model.getAddress()) || '',
            user_active: active
        }
    }

    private deriveSalaryFromSalary = (salary?: Salary) => {
        return  {
            earnings: {
                basic_salary: (salary && salary!.getEarning_basicSalary() ) || 0,
                hra: (salary && salary!.getEarning_hra() ) || 0,
                mi_allowance: (salary && salary!.getEarning_miAllowance() ) || 0 ,
                special_allowance: (salary && salary!.getEarning_specialAllowance() ) || 0 ,
                conveyance_allowance: (salary && salary!.getEarning_conveyanceAllowance() ) || 0 ,
                other_allowance: (salary && salary!.getEarning_otherAllowance() ) || 0,
                other_allowance_remarks: (salary && salary!.getEarning_otherAllowanceRemarks() )
            },
            reimbursements: {
                travel_allowance: (salary && salary!.getReimbursement_travelAllowance() ) || 0 ,
                document_expense: (salary && salary!.getReimbursement_documentExpense() ) || 0 ,
                city_allowance: (salary && salary!.getReimbursement_cityAllowance() ) || 0 ,
                other_expense: (salary && salary!.getReimbursement_otherExpense() ) || 0,
                other_expense_remarks: (salary && salary!.getReimbursement_otherExpenseRemarks() )
            },
            other_benefits: {
                case_allowance: (salary && salary!.getOtherBenefit_caseAllowance() ) || 0 ,
                incentive: (salary && salary!.getOtherBenefit_incentive() ) || 0
            },
            deductions: {
                leave_deduction: (salary && salary!.getDeduction_leaveDeduction() ) || 0 ,
                other_deduction: (salary && salary!.getDeduction_otherDeduction() ) || 0 ,
                other_deduction_remarks: (salary && salary!.getDeduction_otherDeductionRemarks() ),
                bonus: (salary && salary!.getDeduction_bonus() ) || 0
            },
            other: {
                paid_leaves: (salary && salary!.getOther_paidLeaves() ) || 0 ,
                unpaid_leaves: (salary && salary!.getOther_unpaidLeaves() ) || 0 ,
                available_leaves: (salary && salary!.getOther_availableLeaves() ) || 0 ,
                total_cases: (salary && salary!.getOther_totalCases() ) || 0
            }
        }
    }

    private onSubmit = () => {
        this.setState({ form_loading: true }, async () => {
            this.confirmSalary();
        })
    }

    private confirmSalary = async () => {
        /** create request object */

        try{
            const data= {
                user: this.state.user_id,
                month: parseInt(this.props.salaryMonth),
                year: parseInt(this.props.salaryYear),
                earnings: {
                    basic_salary: this.state.earnings.basic_salary,
                    hra: this.state.earnings.hra,
                    mi_allowance: this.state.earnings.mi_allowance,
                    special_allowance: this.state.earnings.special_allowance,
                    conveyance_allowance: this.state.earnings.conveyance_allowance,
                    other_allowance: this.state.earnings.other_allowance,
                    other_allowance_remarks: this.state.earnings.other_allowance_remarks? this.state.earnings.other_allowance_remarks : undefined
                },
                reimbursements: {
                    travel_allowance: this.state.reimbursements.travel_allowance,
                    document_expense: this.state.reimbursements.document_expense,
                    city_allowance: this.state.reimbursements.city_allowance,
                    other_expense: this.state.reimbursements.other_expense,
                    other_expense_remarks: this.state.reimbursements.other_expense_remarks? this.state.reimbursements.other_expense_remarks : undefined
                },
                other_benefits: {
                    case_allowance: this.state.other_benefits.case_allowance,
                    incentive: this.state.other_benefits.incentive
                },
                deductions: {
                    leave_deduction: this.state.deductions.leave_deduction,
                    bonus: this.state.deductions.bonus,
                    other_deduction: this.state.deductions.other_deduction,
                    other_deduction_remarks: this.state.deductions.other_deduction_remarks? this.state.deductions.other_deduction_remarks : undefined
                },
                other: {
                    paid_leaves: this.state.other.paid_leaves,
                    unpaid_leaves: this.state.other.unpaid_leaves,
                    available_leaves: this.state.other.available_leaves,
                    total_cases: this.state.other.total_cases
                }
            };

            this.props.history.push('/salary');

            const token = LocalStorage.get(LocalStorage.KEY_AUTH_TOKEN);

            const response = await axios({
                url: `/api/salary/print`,
                method: 'POST',
                data: data,
                responseType: 'blob',
                headers: { ['x-access-token' ]: token }
            });

            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            //link.setAttribute('download',
            // `${this.salaryMonthString(this.props.salaryMonth)}_${this.props.salaryYear}_${this.state.user_name}_salary_slip.pdf`)
            link.setAttribute('download', `salary_slip.pdf`)

            document.body.appendChild(link)
            link.click()
            link.remove()
        }
        catch(e){
            toast.error("SomeThing went wrong while downloading Salary Slip.")
        }

    }


    render() {

        return (
            <Form loading={this.state.form_loading}>
                <h2>{this.props.model?.getName()}, <span style={{fontSize:"16px"}}>({ this.props.model?.getRole().getTitle()})</span>  salary of {this.salaryMonthString(this.props.salaryMonth)} {this.props.salaryYear}</h2>
                <p>Joined Amable on {this.props.model?.getJoiningDate()}</p>

                <Divider />
                {/*earning section*/}
                <Header as='h4' color='grey'>Earnings  ( {this.totalEarnings()} )</Header>
                <Form.Group>
                    <Form.Field required>
                        <label style={{ width: 185 }}>Basic Salary</label>
                        <Input 
                            placeholder='5000'
                            disabled={true}
                            value={this.state.earnings.basic_salary}
                            onChange={(_, { value }) => this.setState(
                                prevState => ({
                                    earnings: {
                                        ...prevState.earnings,
                                        basic_salary: isNaN(parseInt(value))? 0: parseInt(value)
                                    }
                                })
                            )}
                        />
                    </Form.Field>
                    <Form.Field required>
                        <label style={{ width: 185 }}>HRA</label>
                        <Input
                            placeholder='5000'
                            disabled={true}
                            value={this.state.earnings.hra}
                            onChange={(_, { value }) => this.setState(
                                prevState => ({
                                    earnings: {
                                        ...prevState.earnings,
                                        hra: isNaN(parseInt(value))? 0: parseInt(value)
                                    }
                                })
                            )}
                        />
                    </Form.Field>
                    <Form.Field required>
                        <label style={{ width: 185 }}>Medical & Insurance Allowance</label>
                        <Input
                            placeholder='5000'
                            disabled={true}
                            value={this.state.earnings.mi_allowance}
                            onChange={(_, { value }) => this.setState(
                                prevState => ({
                                    earnings: {
                                        ...prevState.earnings,
                                        mi_allowance: isNaN(parseInt(value))? 0: parseInt(value)
                                    }
                                })
                            )}
                        />
                    </Form.Field>
                    <Form.Field required>
                        <label style={{ width: 185 }}>Special Allowance</label>
                        <Input
                            placeholder='5000'
                            value={this.state.earnings.special_allowance}
                            onChange={(_, { value }) => this.setState(
                                prevState => ({
                                    earnings: {
                                        ...prevState.earnings,
                                        special_allowance: isNaN(parseInt(value))? 0: parseInt(value)
                                    }
                                })
                            )}
                        />
                    </Form.Field>
                    <Form.Field required>
                        <label style={{ width: 185 }}>Conveyance Allowance</label>
                        <Input
                            placeholder='5000'
                            value={this.state.earnings.conveyance_allowance}
                            onChange={(_, { value }) => this.setState(
                                prevState => ({
                                    earnings: {
                                        ...prevState.earnings,
                                        conveyance_allowance: isNaN(parseInt(value))? 0: parseInt(value)
                                    }
                                })
                            )}
                        />
                    </Form.Field>
                </Form.Group>
                <Form.Group>
                    <Form.Field required>
                        <label style={{ width: 185 }}>Other Allowance</label>
                        <Input
                            placeholder='5000'
                            value={this.state.earnings.other_allowance}
                            onChange={(_, { value }) => this.setState(
                                prevState => ({
                                    earnings: {
                                        ...prevState.earnings,
                                        other_allowance: isNaN(parseInt(value))? 0: parseInt(value)
                                    }
                                })
                            )}
                        />
                    </Form.Field>
                    {this.state.earnings.other_allowance>0 && (
                        <Form.Field>
                            <label style={{ width: 185 }}>Other Allowance Remarks</label>
                            <Input
                                placeholder=''
                                value={this.state.earnings.other_allowance_remarks}
                                onChange={(_, { value }) => this.setState(
                                    prevState => ({
                                        earnings: {
                                            ...prevState.earnings,
                                            other_allowance_remarks: value
                                        }
                                    })
                                )}
                            />
                        </Form.Field>
                    )}
                </Form.Group>
                {/*earning section*/}
                <Divider />
                {/*reimbursements section*/}
                <Header as='h4' color='grey'>Reimbursements  ( {this.totalReimbursements()} )</Header>
                <Form.Group>
                    <Form.Field required>
                        <label style={{ width: 185 }}>Travel allowance</label>
                        <Input
                            placeholder='5000'
                            value={this.state.reimbursements.travel_allowance}
                            onChange={(_, { value }) => this.setState(
                                prevState => ({
                                    reimbursements: {
                                        ...prevState.reimbursements,
                                        travel_allowance: isNaN(parseInt(value))? 0: parseInt(value)
                                    }
                                })
                            )}
                        />
                    </Form.Field>
                    <Form.Field required>
                        <label style={{ width: 185 }}>MRD Charges</label>
                        <Input
                            placeholder='5000'
                            value={this.state.reimbursements.document_expense}
                            onChange={(_, { value }) => this.setState(
                                prevState => ({
                                    reimbursements: {
                                        ...prevState.reimbursements,
                                        document_expense: isNaN(parseInt(value))? 0: parseInt(value)
                                    }
                                })
                            )}
                        />
                    </Form.Field>
                    <Form.Field required>
                        <label style={{ width: 185 }}>City Allowance</label>
                        <Input
                            placeholder='5000'
                            value={this.state.reimbursements.city_allowance}
                            onChange={(_, { value }) => this.setState(
                                prevState => ({
                                    reimbursements: {
                                        ...prevState.reimbursements,
                                        city_allowance: isNaN(parseInt(value))? 0: parseInt(value)
                                    }
                                })
                            )}
                        />
                    </Form.Field>
                    <Form.Field required>
                        <label style={{ width: 185 }}>Other Expenses</label>
                        <Input
                            placeholder='5000'
                            value={this.state.reimbursements.other_expense}
                            onChange={(_, { value }) => this.setState(
                                prevState => ({
                                    reimbursements: {
                                        ...prevState.reimbursements,
                                        other_expense: isNaN(parseInt(value))? 0: parseInt(value)
                                    }
                                })
                            )}
                        />
                    </Form.Field>
                    {this.state.reimbursements.other_expense>0 && (
                        <Form.Field>
                            <label style={{ width: 185 }}>Other Expenses Remarks</label>
                            <Input
                                placeholder=''
                                value={this.state.reimbursements.other_expense_remarks}
                                onChange={(_, { value }) => this.setState(
                                    prevState => ({
                                        reimbursements: {
                                            ...prevState.reimbursements,
                                            other_expense_remarks: value
                                        }
                                    })
                                )}
                            />
                        </Form.Field>
                    )}
                </Form.Group>
                {/*reimbursements section*/}
                <Divider/>
                {/*Other benefits section*/}
                <Header as='h4' color='grey'>Other Benefits  ( {this.totalOtherBenefits()} )</Header>
                <Form.Group>
                    <Form.Field required>
                        <label style={{ width: 185 }}>Case Allowance</label>
                        <Input
                            placeholder='5000'
                            value={this.state.other_benefits.case_allowance}
                            onChange={(_, { value }) => this.setState(
                                prevState => ({
                                    other_benefits: {
                                        ...prevState.other_benefits,
                                        case_allowance: isNaN(parseInt(value))? 0: parseInt(value)
                                    }
                                })
                            )}
                        />
                    </Form.Field>
                    <Form.Field required>
                        <label style={{ width: 185 }}>Incentive</label>
                        <Input
                            placeholder='5000'
                            value={this.state.other_benefits.incentive}
                            onChange={(_, { value }) => this.setState(
                                prevState => ({
                                    other_benefits: {
                                        ...prevState.other_benefits,
                                        incentive: isNaN(parseInt(value))? 0: parseInt(value)
                                    }
                                })
                            )}
                        />
                    </Form.Field>
                    <Form.Field required>
                        <label style={{ width: 185 }}>Bonus</label>
                        <Input
                            placeholder='5000'
                            disabled={true}
                            value={this.state.deductions.bonus}
                            onChange={(_, { value }) => this.setState(
                                prevState => ({
                                    deductions: {
                                        ...prevState.deductions,
                                        bonus: isNaN(parseInt(value))? 0: parseInt(value)
                                    }
                                })
                            )}
                        />
                    </Form.Field>
                </Form.Group>
                {/*Other benefits section*/}
                <Divider/>
                {/*deductions section*/}
                <Header as='h4' color='grey'>Deductions  ( {this.totalDeductions()} )</Header>
                <Form.Group>
                    <Form.Field required>
                        <label style={{ width: 185 }}>Leave Deduction</label>
                        <Input
                            placeholder='5000'
                            disabled={true}
                            value={this.state.deductions.leave_deduction}
                            onChange={(_, { value }) => this.setState(
                                prevState => ({
                                    deductions: {
                                        ...prevState.deductions,
                                        leave_deduction: isNaN(parseInt(value))? 0: parseInt(value)
                                    }
                                })
                            )}
                        />
                    </Form.Field>
                    <Form.Field required>
                        <label style={{ width: 185 }}>Bonus</label>
                        <Input
                            placeholder='5000'
                            disabled={true}
                            value={this.state.deductions.bonus}
                            onChange={(_, { value }) => this.setState(
                                prevState => ({
                                    deductions: {
                                        ...prevState.deductions,
                                        bonus: isNaN(parseInt(value))? 0: parseInt(value)
                                    }
                                })
                            )}
                        />
                    </Form.Field>
                    <Form.Field required>
                        <label style={{ width: 185 }}>Other Deduction</label>
                        <Input
                            placeholder='5000'
                            value={this.state.deductions.other_deduction}
                            onChange={(_, { value }) => this.setState(
                                prevState => ({
                                    deductions: {
                                        ...prevState.deductions,
                                        other_deduction: isNaN(parseInt(value))? 0: parseInt(value)
                                    }
                                })
                            )}
                        />
                    </Form.Field>
                    {this.state.deductions.other_deduction>0 && (
                        <Form.Field>
                            <label style={{ width: 185 }}>Other Deduction Remarks</label>
                            <Input
                                placeholder=''
                                value={this.state.deductions.other_deduction_remarks}
                                onChange={(_, { value }) => this.setState(
                                    prevState => ({
                                        deductions: {
                                            ...prevState.deductions,
                                            other_deduction_remarks: value
                                        }
                                    })
                                )}
                            />
                        </Form.Field>
                    )}

                </Form.Group>
                {/*deductions section*/}
                <Divider/>
                {/*other section*/}
                <Header as='h4' color='grey'>Other </Header>
                <Form.Group>
                    <Form.Field required>
                        <label style={{ width: 185 }}>Paid Leaves</label>
                        <Input
                            placeholder='5000'
                            disabled={true}
                            value={this.state.other.paid_leaves}
                            onChange={(_, { value }) => this.setState(
                                prevState => ({
                                    other: {
                                        ...prevState.other,
                                        paid_leaves: isNaN(parseInt(value))? 0: parseInt(value)
                                    }
                                })
                            )}
                        />
                    </Form.Field>
                    <Form.Field required>
                        <label style={{ width: 185 }}>Unpaid Leaves</label>
                        <Input
                            placeholder='5000'
                            disabled={true}
                            value={this.state.other.unpaid_leaves}
                            onChange={(_, { value }) => this.setState(
                                prevState => ({
                                    other: {
                                        ...prevState.other,
                                        unpaid_leaves: isNaN(parseInt(value))? 0: parseInt(value)
                                    }
                                })
                            )}
                        />
                    </Form.Field>
                    <Form.Field required>
                        <label style={{ width: 185 }}>Available Leaves</label>
                        <Input
                            placeholder='5000'
                            disabled={true}
                            value={this.state.other.available_leaves}
                            onChange={(_, { value }) => this.setState(
                                prevState => ({
                                    other: {
                                        ...prevState.other,
                                        available_leaves: isNaN(parseInt(value))? 0: parseInt(value)
                                    }
                                })
                            )}
                        />
                    </Form.Field>
                    <Form.Field required>
                        <label style={{ width: 185 }}>Total Cases</label>
                        <Input
                            placeholder='5000'
                            disabled={true}
                            value={this.state.other.total_cases}
                            onChange={(_, { value }) => this.setState(
                                prevState => ({
                                    other: {
                                        ...prevState.other,
                                        total_cases: isNaN(parseInt(value))? 0: parseInt(value)
                                    }
                                })
                            )}
                        />
                    </Form.Field>
                </Form.Group>
                {/*other section*/}

                <Divider/>
                {/*Total section*/}
                <Header as='h4' color='grey'>CTC {this.ctc()} &nbsp;&nbsp;&nbsp;&nbsp; Net Payable {this.netPayable()}</Header>
                {/*Total section*/}
                <Divider/>

                <Button 
                    primary 
                    size='large'
                    onClick={this.onSubmit}
                    disabled={
                        this.state.form_loading
                        // this.state.user_role.trim().length === 0 ||
                        // this.state.user_employeeId.trim().length === 0 ||
                        // this.state.user_name.trim().length === 0 ||
                        // this.state.user_phone.trim().length === 0 ||
                        // this.state.user_email.trim().length === 0 ||
                        //
                    }
                    loading={this.state.form_loading}
                    style={{ margin: '16px 0' }}>
                    Download Slip
                </Button>
            </Form>
        )
    }
}

export default () => {
    const ctx = useContext(UserFormPageContext);
    const history = useHistory()
    return <UserForm model={ctx.user || undefined} salaryMonth={ctx.salaryMonth} salaryYear={ctx.salaryYear} salary={ctx.salary || undefined} history={history} onSuccess={ctx.setUser}/>
}
