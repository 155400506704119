import React from "react";
import { Table,Segment } from "semantic-ui-react";

interface InsuredInfoDataTableProps {
    data: any;
}

const InsuredInfoDataTable = (props: InsuredInfoDataTableProps) => {
    const { data } = props;
    return (
        <>
            <Table striped compact celled structured>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Name</Table.HeaderCell>
                        <Table.HeaderCell>Contact Number</Table.HeaderCell>
                        <Table.HeaderCell width={3}>Occupation</Table.HeaderCell>
                        <Table.HeaderCell>Investigator Name</Table.HeaderCell>
                        <Table.HeaderCell>Email</Table.HeaderCell>
                        <Table.HeaderCell>Hospital</Table.HeaderCell>
                        <Table.HeaderCell>Insured Confirmation</Table.HeaderCell>
                        <Table.HeaderCell>Meeting Place</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body style={{ fontWeight: 500 }}>

                    {data.map((item: any) =>
                        <Table.Row>
                            <Table.Cell width={3}>{item.name || "--"}</Table.Cell>
                            <Table.Cell width={3}>{item.contactNumber}</Table.Cell>
                            <Table.Cell width={3}>{item.occupation}</Table.Cell>
                            <Table.Cell>{item.investigatorName}</Table.Cell>
                            <Table.Cell>{item.email || "--"}</Table.Cell>
                            <Table.Cell>{item.hospitalData.name}</Table.Cell>
                            <Table.Cell>{item.insuredConfirmation}</Table.Cell>
                            <Table.Cell>{item.meetingPlace}</Table.Cell>
                        </Table.Row>)}
                    <Table.Row>
                    </Table.Row>
                </Table.Body>
            </Table> 
            {!data.length && <Segment basic textAlign={"center"}>
                No Record Found
            </Segment>
            }
        </>
    );
};

export default React.memo(InsuredInfoDataTable);
