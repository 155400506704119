import React from "react";
import { Form, Button } from "semantic-ui-react";
import Case from "../../../../models/Case";
import CaseRepository, {
  AppendCaseRequestObject,
} from "../../../../common/repository/CaseRepository";
import Result from "../../../../common/repository/Result";
import { toast } from "../../../common/Toast";

export interface CaseAppendFormProps {
  model: Case;
  onSuccess(c: Case): void;
}

interface CaseAppendFormState {
  loading: boolean;

  case_triggers: string;
  form_documents: File[];
}

export default class CaseAppendForm extends React.Component<
  CaseAppendFormProps,
  CaseAppendFormState
> {
  private caseRepo = new CaseRepository();

  constructor(props: CaseAppendFormProps) {
    super(props);
    this.state = {
      loading: false,
      case_triggers: "",
      form_documents: [],
    };
  }

  private onSubmit = () => {
    const ro: AppendCaseRequestObject = {
      id: this.props.model.getId(),
    };

    if (this.state.case_triggers.trim().length !== 0)
      ro.triggers = this.state.case_triggers;

    if (this.state.form_documents.length > 0)
      ro.documents = this.state.form_documents;

    this.setState({ loading: true }, async () => {
      const result = await this.caseRepo.appendCase(ro);
      if (result instanceof Result.Success) {
        const c = result.data;
        this.setState({ loading: false }, () => {
          toast.success("Case updated successfully");
          this.props.onSuccess(c);
        });
      } else {
        this.setState({ loading: false }, () => {
          const message = result.message || "Something went wrong";
          toast.error(message);
        });
      }
    });
  };

  render() {
    return (
      <Form loading={this.state.loading}>
        <Form.TextArea
          label="New Triggers"
          value={this.state.case_triggers}
          onChange={(_, { value }) =>
            this.setState({ case_triggers: value as string })
          }
        />

        <Form.Input
          type="file"
          label="New Documents"
          accept="image/*, .pdf, .doc, .docx, .csv, .xls, .xlsx"
          multiple
          onChange={(e) => {
            const files = e.target.files;
            if (files !== null) {
              let fileArr: File[] = [];
              for (let i = 0; i < files.length; i++) fileArr.push(files[i]);
              this.setState({ form_documents: fileArr });
            } else {
              this.setState({ form_documents: [] });
            }
          }}
        />

        <Button
          positive
          disabled={
            this.state.loading ||
            (this.state.case_triggers.trim().length === 0 &&
              this.state.form_documents.length === 0)
          }
          loading={this.state.loading}
          onClick={this.onSubmit}
        >
          Submit
        </Button>
      </Form>
    );
  }
}
