import React from "react";
import { Form } from "semantic-ui-react";
import Button from "../../elements/Button";
import Hospital from "../../../models/Hospital";
import HospitalRepository from "../../../common/repository/HospitalRepository";
import Result from "../../../common/repository/Result";
import { toast } from "../Toast";
import StateDistrictRepository from "../../../common/repository/StateDistrictRepository";
import StateDM from "./../../../models/State";
import "./style.css"
interface State {
  loading: boolean;
  filter: string
  form_loading: boolean;
  form_loadingStates: boolean;
  form_states: StateDM[];
  form_loadingDistricts: boolean;
  form_districts: string[];

  hospital_name: string;
  hospital_state: string;
  hospital_district: string;
  hospitals: Hospital[] | any
  predictions: [] | any
  isOpen: any,
  searchValue: any,
  selectedOption: any,
}

interface Props {
  model?: Hospital;
  states?: StateDM[];
  hospitalList?: Hospital | any
  onSuccess(hospital: Hospital): void;
  onError?(): void;
}
class HospitalForm extends React.Component<Props, State> {
  private hospitalRepo = new HospitalRepository();
  private stateRepo = new StateDistrictRepository();

  constructor(props: Props) {
    super(props);
    let loadStates = true;
    if (props.states) loadStates = false;

    const stateFromModel = this.deriveHospitalStateFromModel();
    this.state = {
      loading: false,
      filter: "",
      form_loading: false,
      form_loadingStates: loadStates,
      form_states: props.states || [],
      form_loadingDistricts: false,
      form_districts: [],
      hospitals: [],
      predictions: [],
      isOpen: false,
      searchValue: '',
      selectedOption: null,
      ...stateFromModel,
    };
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    this.getStates();
    //if this is an update model
    //then check if state is already selected and load it's districts
    if (this.state.hospital_state) this.getDistricts();
  }

  private load = () => {
    this.setState({ form_loading: false }, async () => {
      const result = await this.getHospitals()
      if (result instanceof Result.Success) {
        const hospitals = result.data.items
        console.log(hospitals, "hospitals")
        this.setState({ predictions: hospitals, form_loading: false })
      } else {
        this.setState({ form_loading: false }, () => {
          const message = result.message || 'Something went wrong'
          toast.error(message)
        })
      }
    })
  }

  private getHospitals = async () => {
    const ro = {
      search: this.state.hospital_name,
      district: this.state.hospital_district,
      state: this.state.hospital_state,
      isActive: true
    }
    return await this.hospitalRepo.getHospitals(ro)
  }

  async onChange(e: any) {
    const value = e.target.value;
    this.setState({
      hospital_name: value
    });
    if (value.length > 1 && this.state.hospital_state && this.state.hospital_district) {
      await this.load();
    } else {
      this.setState({
        predictions: []
      });
    }
  }

  private deriveHospitalStateFromModel = () => {
    const model = this.props.model;
    return {
      hospital_name: (model && model.getName()) || "",
      hospital_state: (model && model.getState()) || "",
      hospital_district: (model && model.getDistrict()) || "",
    };
  };

  private getStates = () => {
    this.setState({ form_loadingStates: true }, async () => {
      const result = await this.stateRepo.getStates();
      if (result instanceof Result.Success) {
        const states = result.data.items;
        this.setState({ form_loadingStates: false, form_states: states });
      } else {
        const message = result.message || "Something went wrong";
        this.setState({ form_loadingStates: false }, () =>
          toast.error(message)
        );
      }
    });
  };

  private getDistricts = () => {
    const state = this.state.hospital_state;
    this.setState({ form_loadingDistricts: true }, async () => {
      const result = await this.stateRepo.getDistricts({ state });
      if (result instanceof Result.Success) {
        const districts = result.data.items;
        this.setState({
          form_loadingDistricts: false,
          form_districts: districts,
        });
      } else {
        const message = result.message || "Something went wrong";
        this.setState({ form_loadingDistricts: false }, () =>
          toast.error(message)
        );
      }
    });
  };

  private onSubmit = () => {
    this.setState({ form_loading: true }, () => {
      if (this.props.model) this.updateHospital();
      else this.addHospital();
    });
  };

  private addHospital = async () => {
    const result = await this.hospitalRepo.addHospital({
      name: this.state.hospital_name.trim(),
      state: this.state.hospital_state,
      district: this.state.hospital_district,
    });

    if (result instanceof Result.Success) {
      const hospital = result.data;
      toast.success("Hospital Added Successfully!");
      this.props.onSuccess(hospital);
    } else {
      const message = result.message || "Something went wrong";
      this.setState({ form_loading: false }, () => {
        toast.error(message);
        if (this.props.onError) this.props.onError();
      });
    }
  };

  private updateHospital = async () => {
    const hospitalId = this.props.model!.getId();
    const result = await this.hospitalRepo.updateHospital({
      id: hospitalId,
      name: this.state.hospital_name.trim(),
      state: this.state.hospital_state,
      district: this.state.hospital_district,
    });

    if (result instanceof Result.Success) {
      const hospital = result.data;
      toast.success("Hospital Updated Successfully!");
      this.props.onSuccess(hospital);
    } else {
      const message = result.message || "Something went wrong";
      this.setState({ form_loading: false }, () => {
        toast.error(message);
        if (this.props.onError) this.props.onError();
      });
    }
  };

  private toggleDropdown = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };

  handleSearchChange = (e: any) => {
    const searchQuery = e.target.value;
    this.setState({ searchValue: searchQuery });
  };

  selectOption = (option: any) => {
    this.setState({
      selectedOption: option,
      searchValue: '',
      isOpen: false,
    });
  };

  render() {
    { console.log(this.state.hospitals, "99999999999") }

    const stateOptions = this.state.form_states.map((it) => {
      return { text: it.getName(), value: it.getName(), key: it.getCode() };
    });

    const districtsOptions = this.state.form_districts.map((it, i) => {
      return { text: it, value: it, key: i + it };
    });

    const { isOpen, searchValue, selectedOption } = this.state;

    const filteredOptions = districtsOptions.filter((option) =>
      option.text.toLowerCase().includes(searchValue.toLowerCase())
    );

    return (
      <Form loading={this.state.form_loading}>


        {/* state */}
        <Form.Dropdown
          search
          selection
          loading={this.state.form_loadingStates}
          options={stateOptions}
          label="State"
          placeholder="Select State"
          value={this.state.hospital_state}
          onChange={(_, { value }) => {
            if (!value) return;
            const selectedState = value.toString();
            this.setState({ hospital_state: selectedState }, this.getDistricts);
          }}
        />
        {/* state */}

        {/* district */}
        <Form.Dropdown
          search
          selection
          loading={this.state.form_loadingDistricts}
          options={districtsOptions}
          label="District"
          placeholder="Select District"
          value={this.state.hospital_district}
          onChange={(_, { value }) => {
            if (!value) return;
            const selectedDistrict = value.toString();
            this.setState({ hospital_district: selectedDistrict });
          }}
        />

        {/* <div className="custom-select">
          <div className="select-input" onClick={this.toggleDropdown}>
            {selectedOption ? selectedOption.text : 'Select an option'}
          </div>
          {isOpen && (
            <div className="options-container">
              <input
                type="text"
                className="search-input"
                value={searchValue}
                onChange={this.handleSearchChange}
                placeholder="Search..."
              />
              <ul className="options-list">
                {filteredOptions.map((option) => (
                  <li
                    key={option.value}
                    className="option"
                    onClick={() => this.selectOption(option)}
                  >
                    {option.text}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div> */}



        {/* district */}
        {/* name */}
        {/* <Form.Input
          label="Name"
          placeholder="e.g. Fortis"
          value={this.state.hospital_name}
          onChange={(_, { value }) => this.setState({ hospital_name: value })}
        /> */}
        <label htmlFor="">Name</label>
        <input type="text" value={this.state.hospital_name} onChange={this.onChange} />
        {this.state.predictions.length > 1 && <div className="border_hospital">
          {
            (this.state.predictions && this.state.predictions.length) ? this.state.predictions.map((item: any, index: number) => (
              <div key={index + item}>{item.getName()}</div>
            )) : ""
          }
        </div>}
        {/* name */}
        <br />
        <Button
          className="margin-top"
          positive
          onClick={this.onSubmit}
          disabled={
            this.state.hospital_name.trim().length === 0 ||
            this.state.hospital_state.length === 0 ||
            this.state.hospital_district.length === 0
          }
        >
          Submit
        </Button>
      </Form>
    );
  }
}

export default HospitalForm;
