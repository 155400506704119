import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import constants from "./../../../common/constants";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../../../redux";
import { toast } from "../../common/Toast";
import Result from "../../../common/repository/Result";
import { Icon, Image, Popup, Button, Segment, Header as SemanticHeader, } from "semantic-ui-react";
import LocalStorage from "../../../lib/LocalStorage";
import { setAuth } from "../../../redux/auth/actioncreators";
import { Link } from "react-router-dom";
import { io } from "socket.io-client";
import config from "../../../common/repository/config";
import NotifecationRepo from "../../../common/repository/NotifecationRepo";
import { GlobalContext } from "../../../App";

const Container = styled.div`
  width: 100%;
  padding: 8px;

  display: flex;
  flex-direction: row;
  align-items: center;

  background-color: #fff;
  border-bottom: 1px solid #f1f1f1;
  box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.03);
`;

const Header = (): JSX.Element => {

  const { Count, setCounts } = useContext<any>(GlobalContext)
  const { totalCount } = Count
  const notifecationRepo = new NotifecationRepo()

  const authState = useSelector((state: AppState) => state.auth);
  const dispatch = useDispatch();
  const user = authState.user;
  const name = (user && user.getName()) || "";
  const title = (user && user.getRole().getTitle()) || "";
  const socket = io(config.apiBaseURLSocket)
  let notificationBox: Notification


  useEffect(() => {
    // We need to ask the user for permission
    Notification.requestPermission()
    socket.on('notification', getNotification)
    socket.emit("add", { user_id: user?.getId() });
    GetAllCount()

  }, [])

  const getNotification = (notify: any) => {
    if (!notify) return null

    setCounts((obj: any) => {
      let totalCaseCount = obj.totalCount + 1
      return ({ ...obj, [notify.type]: obj[notify.type] + 1, totalCount: totalCaseCount })
    })
    notificationBox = new Notification(notify.title, { body: notify.message, icon: '', data: notify })
    notificationBox.addEventListener('click', (notificationBox) => {
      setCounts((obj: any) => {
        let totalCaseCount = obj.totalCount - 1
        return ({ ...obj, [notify.type]: obj[notify.type] + 1, totalCount: totalCaseCount })
      })
    })
  }

  async function GetAllCount() {
    const result = await notifecationRepo.getNotifectionAllCount();
    if (result instanceof Result.Success) {
      setCounts((obj: any) => {
        // console.log('obj', ({ ...obj, totalCount: result.data.totalItemCount }));
        return ({ ...obj, totalCount: result.data.totalItemCount })

      })
    } else {
      const message = result.message || "Could not load Count";
      toast.error(message);
    }
  }

  const onLogout = () => {
    socket.emit('logout', { user_id: user?.getId() })
    LocalStorage.remove(LocalStorage.KEY_AUTH_TOKEN);
    localStorage.removeItem("caseCounts");
    dispatch(setAuth(null));
  };

  return (
    <Container style={{ backgroundColor: "#FF6600", color: "white" }}>
      <div style={{ width: `${constants.sidebarWidth}px` }}>
        <strong style={{ fontSize: "20px" }}>{user && user.getName()}</strong>
        <br />
        {user && user.getRole().getTitle()}
      </div>


      <div style={{ flex: 1, textAlign: "center" }}>
        <h1 style={{ color: "white", margin: "0px", fontSize: "32px" }}>
          Claims Deck
        </h1>
        {/*<p style={{margin:"0px",fontSize:"20px"}}>Consultancy Services</p>*/}
      </div>

      <div style={{ margin: "0 16px" }}>
        {(user?.getRole().getTitle() === constants.roleTitle.admin || user?.getRole().getTitle() === constants.roleTitle.ca) && <>
          {totalCount > 0 && <span style={{ position: "relative", right: "-18px", background: "red", padding: "5px 10px", borderRadius: "50%" }} >{totalCount <= 99 ? totalCount : "99+"}</span>}
          <Popup
            basic
            on="click"
            trigger={
              <Icon name='bell' size='big' style={{ fontSize: "3em", cursor: "pointer" }} />
            }
          >
            <Segment.Group>
              <Segment>
                <Link to="/notification">Notification</Link>
              </Segment>
            </Segment.Group>
          </Popup> </>}

        <Popup
          basic
          on="click"
          trigger={
            <Image
              avatar
              src={user && user.getProfilePicURL()}
              style={{ height: "4em", width: "4em" }}
            />
          }
        >
          <Segment.Group>
            <Segment>
              <SemanticHeader as="h4">
                {name}
                <SemanticHeader.Subheader>{title}</SemanticHeader.Subheader>
              </SemanticHeader>
            </Segment>

            <Segment>
              <Link to="/profile">Update Profile</Link>
            </Segment>

            <Segment>
              <Button color="red" onClick={onLogout}>
                Logout
              </Button>
            </Segment>
          </Segment.Group>
        </Popup>
      </div>
    </Container >
  );
};

export default Header;
