import React, { useState, useEffect } from 'react'
import Page from '../../layout/Page'
import { Grid, Menu, MenuItemProps, Segment } from 'semantic-ui-react'
import SalarySegment from './SalarySegment'
import IncentiveSegment from './IncentiveSegment'
import LeavesSegment from './LeavesSegment'
import Settings from '../../../models/Settings'
import SettingsPageContext from './SettingsPageContext'
import SettingsRepository from '../../../common/repository/SettingsRepository'
import Result from '../../../common/repository/Result'
import { toast } from '../../common/Toast'
import RequirePermission from '../../base/RequirePermission'
import permissions from '../../../common/permissions'
import ClaimSizeSegment from './ClaimSizeSegment'

function SettingsPage() {
  const settingsRepo = new SettingsRepository()
  const [activeMenu, setActiveMenu] = useState('salary')
  const [isLoading, setIsLoading] = useState(true)
  const [isUpdating, setIsUpdating] = useState(false)
  const [settings, setSettings] = useState<Settings | null>(null)

  useEffect(() => {
    effectLoadEssentialData()
  }, [isLoading])

  return (
    <SettingsPageContext.Provider
      value={{
        settingsRepo,
        isLoading,
        setIsLoading,
        isUpdating,
        setIsUpdating,
        settings,
        setSettings,
      }}
    >
      <Page title="Settings" description="Manage settings related to Salary and Icentive calculation.">
        <Grid>
          <Grid.Column width={3}>
            <Menu fluid vertical tabular>
              {createMenuItems()}
            </Menu>
          </Grid.Column>

          <Grid.Column width={13}>
            <Segment loading={isLoading || isUpdating}>{settings === null ? null : renderSegment()}</Segment>
          </Grid.Column>
        </Grid>
      </Page>
    </SettingsPageContext.Provider>
  )

  async function effectLoadEssentialData() {
    if (!isLoading) return
    const result = await settingsRepo.getSettings()
    if (result instanceof Result.Success) {
      setSettings(result.data)
    } else {
      const message = result.message || 'Could not load settings'
      toast.error(message)
    }
    setIsLoading(false)
  }

  function createMenuItems() {
    return MENU_ITEMS.map((it) => <Menu.Item name={it} active={activeMenu === it} onClick={changeActiveMenu} />)
  }

  function changeActiveMenu(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, { name }: MenuItemProps) {
    if (name === undefined) name = 'salary'
    setActiveMenu(name)
  }

  function renderSegment() {
    switch (activeMenu) {
      case MENU_ITEMS[0]:
        return <SalarySegment />
      case MENU_ITEMS[1]:
        return <IncentiveSegment />
      case MENU_ITEMS[2]:
        return <LeavesSegment />
      case MENU_ITEMS[3]:
        return <ClaimSizeSegment />
    }
  }
}

const MENU_ITEMS = ['salary', 'incentive', 'leaves', 'claim size']

export default (props: any) => {
  return (
    <RequirePermission permission={permissions.Setting.update}>
      <SettingsPage {...props} />
    </RequirePermission>
  )
}
