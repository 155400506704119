import React from 'react'
import Page from '../../../layout/Page'
import { Icon, Button, Modal, ButtonOr } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import User from '../../../../models/User'
import Table from '../../../app/Table'
import UserColumnRenderer from './UserColumnRenderer'
import UserRepository from '../../../../common/repository/UserRepository'
import Result from '../../../../common/repository/Result'
import { toast } from '../../../common/Toast'
import RemovalConfirmationForm from '../../../common/RemovalConfirmationForm'
import RequirePermission from '../../../base/RequirePermission'
import permissions from '../../../../common/permissions'

interface State {
  loading: boolean
  filter: string
  limit: number
  offset: number

  users: User[]
  totalUserCount: number

  remove_modalOpen: boolean
  remove_model: User | null
}

class UsersPage extends React.Component<{}, State> {
  private userRepo = new UserRepository()

  private columnRenderer = new UserColumnRenderer(
    (model: User) => {
      if (model.getActive()) {
        return (<>
          <Button size="mini" as={Link} to={`/manage/users/history/${model.getId()}`}>
            History
          </Button>
          <Button.Group size="mini">
            <Button as={Link} to={`/manage/users/update/${model.getId()}`}>
              Open
            </Button>
            <Button negative onClick={() => this.setState({ remove_modalOpen: true, remove_model: model })}>
              Remove
            </Button>
            {/* <ButtonOr /> */}

          </Button.Group>
        </>
        )
      } else {
        return (
          <Button.Group size="mini">
            <Button as={Link} to={`/manage/users/update/${model.getId()}`}>
              Open
            </Button>
            <Button disabled={true} onClick={() => this.setState({ remove_modalOpen: true, remove_model: model })}>
              Remove
            </Button>
          </Button.Group>
        )
      }
    },
    (obj: any) => obj.locations !== undefined
  )

  constructor(props: {}) {
    super(props)
    this.state = {
      loading: false,
      filter: '',
      limit: 10,
      offset: 0,

      users: [],
      totalUserCount: 0,

      remove_modalOpen: false,
      remove_model: null,
    }
  }

  componentDidMount() {
    // this.setState({ offset: 0, limit: 10 })
    this.load()
  }

  private onRowsPerPageChange = (page: number, rowsPerPage: number) => {
    // console.log('onRowsPerPageChange', page, rowsPerPage)
    const offset = (page - 1) * rowsPerPage,
      limit = offset + rowsPerPage
    this.setState({ offset, limit }, this.load)
  }

  private load = () => {
    const { offset } = this.state
    this.setState({ loading: true }, async () => {
      const result = await this.getUsers()
      if (result instanceof Result.Success) {
        if (offset === 0) {
          const users = result.data.items
          const totalUserCount = result.data.totalItemCount
          const offset = users.length
          this.setState({ loading: false, offset, users, totalUserCount })
        } else {
          const users = [...this.state.users, ...result.data.items]
          const offset = users.length
          this.setState({ loading: false, users, offset })
        }
      } else {
        this.setState({ loading: false }, () => {
          const message = result.message || 'Could not load Users'
          toast.error(message)
        })
      }
    })
  }

  private getUsers = async () => {
    const ro = {
      search: this.state.filter,
      limit: this.state.limit,
      offset: this.state.offset,
    }
    return await this.userRepo.getUsers(ro)
  }

  private setFilter = (filter: string) => this.setState({ offset: 0, filter }, this.load)

  private removeUser = async () => {
    const id = this.state.remove_model!.getId()
    const result = await this.userRepo.removeUser(id)
    if (result instanceof Result.Success) {
      const users = this.state.users
      let totalUserCount = this.state.totalUserCount
      const index = users.findIndex((it) => it.getId() === id)
      if (index !== -1) {
        users.splice(index, 1)
        totalUserCount--
      }
      this.setState(
        {
          users: [...users],
          totalUserCount,
          remove_modalOpen: false,
          remove_model: null,
        },
        () => toast.success('Successfully removed user')
      )
      this.load()
    } else {
      const message = result.message || 'Something went wrong'
      toast.error(message)
    }
  }

  render() {
    return (
      <Page title="Users" description="">
        <Button as={Link} to="/manage/users/add" icon labelPosition="left" color="green">
          <Icon name="add" />
          Add New
        </Button>

        <Modal open={this.state.remove_modalOpen} onClose={() => this.setState({ remove_modalOpen: false, remove_model: null })} size="mini" closeIcon closeOnDimmerClick={false} closeOnEscape={false}>
          <Modal.Header>Confirmation</Modal.Header>
          <Modal.Content>
            <RemovalConfirmationForm title="Are you sure you want to remove this?" remove={this.removeUser} cancel={() => this.setState({ remove_model: null, remove_modalOpen: false })} />
          </Modal.Content>
        </Modal>

        <Table
          renderer={this.columnRenderer}
          data={this.state.users}
          totalDataCount={this.state.totalUserCount}
          loading={this.state.loading}
          load={this.load}
          onRowsPerPageChange={this.onRowsPerPageChange}
          onSearch={this.setFilter}
          onClear={() => this.setFilter('')}
        />
      </Page>
    )
  }
}

export default (props: any) => {
  return (
    <RequirePermission permission={permissions.Manage.CRUD}>
      <UsersPage {...props} />
    </RequirePermission>
  )
}
