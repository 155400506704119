import DomainModel from '../DomainModel'

export default class InsuredInvestigator extends DomainModel {
    constructor(private _id: string, private claim_number: string, private modified_on: string, private name: string) {
        super()
    }
    getId = () => this._id
    getClaimNumber = () => this.claim_number
    getModifiedOn = () => this.modified_on
    getName = () => this.name
}
