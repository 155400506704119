import React, { useContext, useState, useEffect } from "react";
import { Form, Button } from "semantic-ui-react";
import DateTimePicker from "../../../app/DateTimePicker";
import UserWarningPageContext from "./UserWarningPageContext";
import Result from "../../../../common/repository/Result";
import { toast } from "../../../common/Toast";
import UserWarning from "../../../../models/UserWarning";

interface Props {
  onSuccess?(model: UserWarning): void;
}

function UserWarningForm({ onSuccess }: Props) {
  const ctx = useContext(UserWarningPageContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [userId, setUserId] = useState(
    (ctx.selectedUserWarning && ctx.selectedUserWarning.getUser().getId()) || ""
  );
  const [date, setDate] = useState(
    (ctx.selectedUserWarning && ctx.selectedUserWarning.getDate()) || ""
  );
  const [warning, setWarning] = useState(
    (ctx.selectedUserWarning && ctx.selectedUserWarning.getWarning()) || ""
  );

  useEffect(() => {
    addUserWarning();
  }, [isSubmitting]);

  return (
    <Form loading={isSubmitting}>
      <Form.Dropdown
        search
        selection
        label="User"
        placeholder="Select User"
        options={ctx.users.map((it) => ({
          text: it.getName(),
          value: it.getId(),
          key: it.getId(),
        }))}
        value={userId}
        onChange={(_, { value }) => setUserId(value as string)}
      />

      <Form.Input label="Date">
        <DateTimePicker
          type="date"
          value={date}
          onChange={setDate}
          placeholder="Pick Date"
        />
      </Form.Input>

      <Form.Input
        label="Warning"
        placeholder="Enter Warning"
        value={warning}
        onChange={(_, { value }) => setWarning(value)}
      />

      <Button
        primary
        disabled={
          ctx.selectedUserWarning !== null ||
          userId.trim().length === 0 ||
          date.trim().length === 0 ||
          warning.trim().length === 0
        }
        onClick={() => setIsSubmitting(true)}
      >
        Submit
      </Button>
    </Form>
  );

  async function addUserWarning() {
    if (!isSubmitting) return;

    const result = await ctx.userRepo.addWarning({
      user_id: userId,
      warning: warning,
      date: date,
    });
    if (result instanceof Result.Success) {
      toast.success("Successfully added User Warning");
      if (onSuccess) onSuccess(result.data);
    } else {
      const message = result.message || "Something went wrong";
      toast.error(message);
    }
    setIsSubmitting(false);
  }
}

export default UserWarningForm;
