import DataMapper from './DataMapper'

import LodgingExpenses from '../../models/LodgingExpenses'
import LodgingExpensesRM from '../responsemodel/LodgingExpensesRM'

export default class LodgingExpensesDataMapper extends DataMapper<LodgingExpensesRM, LodgingExpenses> {
    map = (rm: LodgingExpensesRM) => {
        return new LodgingExpenses(
            rm._id,
            rm.amount,
            rm.appliedDate,
            rm.investigator_name,
            rm.location,
            rm.notes,
            rm.receipt,
            rm.rejectedBy,
            rm.status,
            rm.type,
            rm.updatedAt,
        )
    }
}
