import React from 'react'
import Page from '../../../layout/Page'
import { Modal, Icon, Button } from 'semantic-ui-react'
import Result from '../../../../common/repository/Result'
import { toast } from '../../../common/Toast'
import States from '../../../../models/States'
import RemovalConfirmationForm from '../../../common/RemovalConfirmationForm'
import Table from '../../../app/Table'
import RequirePermission from '../../../base/RequirePermission'
import permissions from '../../../../common/permissions'
import StatesColumnRenderer from './StatesColumnRenderer'
import StateForm from '../../../common/StatesForm'
import StatesDistrictsRepository from "../../../../common/repository/StatesDistrictsRepository"

interface State {
    loading: boolean
    filter: string
    limit: number
    offset: number
    totalStateCount: number
    states: States[]
    add_modalOpen: boolean
    update_modalOpen: boolean
    update_model: States | null

    remove_modalOpen: boolean
    remove_model: States | null
}

class StatePage extends React.Component<{}, State> {

    private statesRepo = new StatesDistrictsRepository()

    private columnRenderer = new StatesColumnRenderer(
        (model: States) => {
            return (
                <Button.Group size="mini">
                    <Button onClick={() => this.setState({ update_model: model, update_modalOpen: true })}>Open</Button>
                    <Button negative onClick={() => this.setState({ remove_model: model, remove_modalOpen: true })}>
                        Remove
                    </Button>
                </Button.Group>
            )
        },
        (obj: any) => obj.name !== undefined
    )

    constructor(props: {}) {
        super(props)
        this.state = {
            loading: false,
            filter: '',
            limit: 10,
            offset: 0,
            totalStateCount: 0,
            states: [],
            add_modalOpen: false,
            update_modalOpen: false,
            update_model: null,
            remove_modalOpen: false,
            remove_model: null,
        }
    }

    componentDidMount() {
        this.load()
    }

    private onRowsPerPageChange = (page: number, rowsPerPage: number) => {
        // console.log('onRowsPerPageChange', page, rowsPerPage)
        const offset = (page - 1) * rowsPerPage,
            limit = offset + rowsPerPage
        this.setState({ offset, limit }, this.load)
    }
    private load = () => {
        const { offset } = this.state
        this.setState({ loading: true }, async () => {
            const result = await this.getStates()
            if (result instanceof Result.Success) {
                if (offset === 0) {
                    const states = result.data.items
                    const totalStateCount = result.data.totalItemCount
                    const offset = states.length
                    this.setState({ loading: false, offset, states, totalStateCount })
                } else {
                    const states = [...this.state.states, ...result.data.items]
                    const offset = states.length
                    this.setState({ loading: false, offset, states })
                }
            } else {
                this.setState({ loading: false }, () => {
                    const message = result.message || 'Something went wrong'
                    toast.error(message)
                })
            }
        })
    }

    private getStates = async () => {
        const ro = {
            search: this.state.filter,
            limit: this.state.limit,
            offset: this.state.offset,
        }
        return await this.statesRepo.getStates(ro)
    }

    private setFilter = (filter: string) => {
        this.setState({ filter, offset: 0 }, this.load)
    }

    private onStatesAdded = (state: States) => {
        this.setState((currentState) => {
            const states = [state, ...currentState.states]
            const totalStateCount = currentState.totalStateCount + 1
            return { states, totalStateCount, add_modalOpen: false }
        })
    }

    private onStateUpdated = (state: States) => {
        const states = this.state.states
        const index = states.findIndex((it) => it.getId() === state.getId())
        if (index !== -1) states[index] = state
        this.setState({ states: [...states], update_modalOpen: false, update_model: null })
    }

    private removeState = async () => {
        const id = this.state.remove_model!.getId()
        const result = await this.statesRepo.removeState(id)
        if (result instanceof Result.Success) {
            const states = this.state.states
            let totalStateCount = this.state.totalStateCount
            const index = states.findIndex((it) => it.getId() === id)
            if (index !== -1) {
                states.splice(index, 1)
                totalStateCount--
            }
            this.setState({ states: [...states], totalStateCount, remove_modalOpen: false, remove_model: null }, () => toast.success('State removed successfully'))
        } else {
            const message = result.message || 'Something went wrong'
            toast.error(message)
        }
    }

    render() {
        return (
            <Page title="States" description="Manage States">
                {/* Add State Modal */}
                <Modal
                    open={this.state.add_modalOpen}
                    onClose={() => this.setState({ add_modalOpen: false })}
                    size="tiny"
                    closeIcon
                    closeOnDimmerClick={false}
                    closeOnEscape={false}
                    trigger={
                        <Button icon labelPosition="left" color="green" onClick={() => this.setState({ add_modalOpen: true })}>
                            <Icon name="add" />
                            Add New
                        </Button>
                    }
                >
                    <Modal.Header>Add New State</Modal.Header>
                    <Modal.Content>
                        <StateForm onSuccess={this.onStatesAdded} />
                    </Modal.Content>
                </Modal>
                {/* Add State Modal */}

                {/* Update State Modal */}
                <Modal open={this.state.update_modalOpen} onClose={() => this.setState({ update_modalOpen: false, update_model: null })} size="tiny" closeIcon closeOnDimmerClick={false} closeOnEscape={false}>
                    <Modal.Header>Update State</Modal.Header>
                    <Modal.Content>
                        <StateForm model={this.state.update_model || undefined} onSuccess={this.onStateUpdated} />
                    </Modal.Content>
                </Modal>
                {/* Update state Modal */}

                {/* Remove state Modal */}
                <Modal open={this.state.remove_modalOpen} onClose={() => this.setState({ remove_modalOpen: false, remove_model: null })} size="mini" closeIcon closeOnDimmerClick={false} closeOnEscape={false}>
                    <Modal.Header>Confirmation</Modal.Header>
                    <Modal.Content>
                        <RemovalConfirmationForm title="Are you sure you want to remove this?" remove={this.removeState} cancel={() => this.setState({ remove_modalOpen: false, remove_model: null })} />
                    </Modal.Content>
                </Modal>
                {/* Remove state Modal */}

                <Table
                    renderer={this.columnRenderer}
                    data={this.state.states}
                    totalDataCount={this.state.totalStateCount}
                    loading={this.state.loading}
                    load={this.load}
                    onRowsPerPageChange={this.onRowsPerPageChange}
                    onSearch={this.setFilter}
                    onClear={() => this.setFilter('')}
                />
            </Page>
        )
    }
}

export default (props: any) => {
    return (
        <RequirePermission permission={permissions.Master.CRUD}>
            <StatePage {...props} />
        </RequirePermission>
    )
}
