import React from "react";
import { Grid, Checkbox, Segment } from "semantic-ui-react";
import Text from "../../../elements/Text";
import { PermissionType } from "./permissionlist";

interface Props {
  list: PermissionType[];
  permissions: string[];
  onPermissionToggle(name: string, operation: "add" | "remove"): void;
}

const Permissions = (props: Props) => {
  const permissionSet = new Set(props.permissions);

  const content = props.list.map((it) => {
    const permissions = it.permissions.map((permission) => {
      return (
        <Grid.Column>
          <Checkbox
            name={permission.value}
            onChange={(_, { name }) => {
              const has = permissionSet.has(name!);
              if (has) props.onPermissionToggle(name!, "remove");
              else props.onPermissionToggle(name!, "add");
            }}
            checked={permissionSet.has(permission.value)}
            label={permission.label}
          />
        </Grid.Column>
      );
    });

    return (
      <React.Fragment>
        <Grid.Row style={{ paddingBottom: 0 }}>
          <Grid.Column>
            <Text as="h5">{it.title}</Text>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>{permissions}</Grid.Row>
      </React.Fragment>
    );
  });
  return (
    <Segment>
      <Grid columns={5}>{content}</Grid>
    </Segment>
  );
};

export default Permissions;
