
import DataMapper from '../DataMapper'
import PathologistDetailRM from '../../responsemodel/ReportRM/PathologistDetailRM'
import PathologistDatail from '../../../models/ReportModels/PathologistDatail'

export default class PathologistDetailDataMapper extends DataMapper<PathologistDetailRM, PathologistDatail> {
    map = (rm: PathologistDetailRM) => new PathologistDatail(
        rm._id,
        rm.case_id,
        rm.contactNumber,
        rm.createdAt,
        rm.doctorAvailableInHospital,
        rm.doctorConfirmation,
        rm.hospital_id,
        rm.hospital_name,
        rm.investigator_id,
        rm.isFieldSaved,
        rm.pathologistName,
        rm.personalMeeting,
        rm.personalMeetingPic,
        rm.qualification,
        rm.registrationCertificate,
        rm.registrationNumber,
        rm.updatedAt,
        rm.feedback,
        rm.feedback_id
    )
}
