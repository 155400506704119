import phone from './phone'
import email from './email'
import casePortion from './caseportion'
import integer from './integer'
import positiveNumber from './positive-number'
import number from './number'

export default {
  email,
  phone,
  casePortion,
  integer,
  positiveNumber,
  number,
}
