import DomainModel from './DomainModel'

class CaseHistory extends DomainModel {
  constructor(private data: any[], private by: any, private state: string, private on: string) {
    super()
  }

  getData = () => this.data
  getBy = () => this.by
  getState = () => this.state
  getOn = () => this.on

  stringify = () => {
    return JSON.stringify({ data: this.data, by: this.by, state: this.state, on: this.on }, null, '  ')
  }

  format = () => {
    const stringified = this.stringify()
    return stringified
      .replace(/[{}""]/g, '')
      .replace(/.*id.*/g, '') //remove ids
      .replace(/^\n$/g, '')
  }
}

export default CaseHistory
