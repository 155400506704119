import ProtectedAPIRepository from './ProtectedAPIRepository'
import APIResponseModel from './APIResponseModel'
import Result from './Result'
import StatesRM from '../responsemodel/StatesRM'
import StatesDataMapper from '../datamapper/StatesDataMapper'

export default class StatesDistrictsRepository extends ProtectedAPIRepository {
    private StatesDataMapper = new StatesDataMapper()

    getStates = async (ro?: GetStatesRequestObject) => {
        const response = await this.post<APIResponseModel<StatesRM>>('/state/all', ro)
        if (response instanceof Result.Success) {
            const data: APIResponseModel<StatesRM> = response.data
            if (data.data !== null) {
                const states = data.data.items.map((it) => this.StatesDataMapper.map(it))
                return new Result.Success({
                    totalItemCount: data.data.total_item_count,
                    items: states,
                })
            } else {
                return new Result.Error(0, 'Data is null')
            }
        } else {
            return response
        }
    }

    addStates = async (ro: AddStatesRequestObject) => {
        const response = await this.post<APIResponseModel<StatesRM>>('/state/add', ro)
        if (response instanceof Result.Success) {
            const data: APIResponseModel<StatesRM> = response.data
            if (data.data !== null) {
                const states = this.StatesDataMapper.map(data.data.items[0])
                return new Result.Success(states)
            } else {
                return new Result.Error(0, 'Data is null')
            }
        } else {
            return response
        }
    }


    getDistricts = async (ro: GetDistrictsRequestObject) => {
        const response = await this.post<APIResponseModel<string>>('/state', ro)
        if (response instanceof Result.Success) {
            const data = response.data
            if (data.data !== null) {
                return new Result.Success({
                    totalItemCount: data.data.total_item_count,
                    items: data.data.items,
                })
            } else {
                return new Result.Error(0, 'Data is null')
            }
        } else {
            return response
        }
    }

    updateState = async (ro: UpdateStateRequestObject) => {
        const response = await this.post<APIResponseModel<StatesRM>>('/state/update', ro)
        if (response instanceof Result.Success) {
            const data: APIResponseModel<StatesRM> = response.data
            if (data.data !== null) {
                const state = this.StatesDataMapper.map(data.data.items[0])
                return new Result.Success(state)
            } else {
                return new Result.Error(0, 'Data is null')
            }
        } else {
            return response
        }
    }

    removeState = async (StateId: string) => {
        const response = await this.delete<APIResponseModel<null>>(`/state/${StateId}`)
        return response
    }

}

interface GetDistrictsRequestObject {
    state: string
}


export interface GetStatesRequestObject {
    search?: string
    name?: string
    code?: string
    GSTCode?: string
}

export interface AddStatesRequestObject {
    name: string
    code: string
    GSTCode: string
}

export interface UpdateStateRequestObject {
    id: string
    name: string
    code: string
    GSTCode: string
    districts: string[]
}