import React, { useState } from "react";
import { Modal, Button, Message } from "semantic-ui-react";
import CaseAppendForm, { CaseAppendFormProps } from "./CaseAppendForm";
import Case from "../../../../models/Case";
import constants from "../../../../common/constants";
import { Link } from "react-router-dom";
import CaseRepository from "../../../../common/repository/CaseRepository";
import { toast } from "../../../common/Toast";
import Result from "../../../../common/repository/Result";

interface CaseAppendFormModalProps extends CaseAppendFormProps { }

const CaseAppendFormModal = (props: CaseAppendFormModalProps) => {
  const model = props.model;
  const [open, setOpen] = useState(false);
  const { onSuccess, ...restProps } = props;
  const [loading, setLoading] = useState(false)
  console.log(props, "mooo")

  const caseRepo = new CaseRepository();


  const onAddWaterMark = async () => {
    setLoading(true);
    try {
      const result = await caseRepo.AddWaterMark( model.getId());
      if (result instanceof Result.Success) {
        const c = result.data;
        setLoading(false);
        toast.success("Water Mark Added successfully");
      } else {
        setLoading(false);
        const message = result.message || "Something went wrong";
        toast.error(message);
      }
    } catch (error) {
      setLoading(false);
      // const errorMessage = error || "An error occurred";
      toast.error("Something went wrong");
    }
  };

  const caseState = model.getState();
  return (
    <>
      <Modal
        size="tiny"
        open={open}
        closeIcon
        closeOnDimmerClick={false}
        closeOnEscape={false}
        onClose={() => setOpen(false)}
        trigger={
          <Button
            primary
            onClick={() => setOpen(true)}
            disabled={
              caseState !== constants.caseStates.generated &&
              caseState !== constants.caseStates.open &&
              caseState !== constants.caseStates.reinvestigate
            }
          >
            Add New Triggers/Documents
          </Button>
        }
      >
        <Modal.Header>Add New Case Information</Modal.Header>
        <Modal.Content>
          <Message info compact>
            NOTE: This information will get added into case and will not
            overwrite the existing one.
          </Message>
          <CaseAppendForm
            {...restProps}
            onSuccess={(c: Case) => {
              setOpen(false);
              onSuccess(c);
            }}
          />
        </Modal.Content>
      </Modal>
      <Button primary as={Link} to={`/case/edit/${model.getId()}`}>
        Edit Case Information
      </Button>
      <Button primary loading={loading} onClick={onAddWaterMark}>
        Add Water Mark
      </Button>
    </>
  );
};

export default CaseAppendFormModal;
