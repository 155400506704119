import React from "react";
import { Form, Button, Icon } from "semantic-ui-react";

interface State {
  loading: boolean;
}

interface Props {
  title: string;
  remove(): void;
  cancel(): void;
}

export default class RemovalConfirmationForm extends React.Component<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  remove = () => {
    this.setState({ loading: true }, async () => {
      await this.props.remove();
      this.setState({ loading: false });
    });
  };

  render() {
    return (
      <Form loading={this.state.loading}>
        <p>{this.props.title}</p>
        <Button color="red" inverted onClick={this.remove}>
          Remove
        </Button>
        <Button onClick={this.props.cancel} style={{ marginLeft: 4 }}>
          Cancel
        </Button>
      </Form>
    );
  }
}
