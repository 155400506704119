import React from "react";
import { Divider, Form, Input, Radio } from "semantic-ui-react";
import Button from "../../../elements/Button";
import PaymentSection from "../../../../models/PaymentSection";
import Result from "../../../../common/repository/Result";
import { toast } from "../../../common/Toast";
import PaymentSectionRepository from "../../../../common/repository/PaymentSectionRepository";
import FormLabel from "../../../common/FormLabel";
import DateTimePicker from "../../../app/DateTimePicker";
import constants from "../../../../common/constants";

interface Props {
  //if model is passed that means update
  model?: PaymentSection;

  onSuccess: (invoiceSection: PaymentSection) => void;
  onError?: () => void;
}
interface Invoice {
  invoice_number: string;
  professional_fee: number;
  approved_expense: number;
  document_expense: number;
  extra_expense: number;
  incentive: number;
  deduction: number;
  gst: number;
  invoice_url: string | null;
}

interface Payment {
  tds: number;
  received_amount: number;
  payment_date: string;
  utr_no: string;
  settled: boolean;
  status: string;
  slip_url: string | null;
}

interface State {
  form_loading: boolean;

  claim_number: string;
  case_type_title: string;
  invoice: Invoice | null;
  invoiceAmount: number;
  payment: Payment | null;
  paymentReceipt: File | null;
}

class InvoiceUpdateForm extends React.Component<Props, State> {
  private paymentSectionRepo = new PaymentSectionRepository();

  constructor(props: Props) {
    super(props);

    const stateFromModel = this.derivePaymentSectionStateFromModel();
    this.state = {
      form_loading: false,
      paymentReceipt: null,
      ...stateFromModel,
    };
  }

  private derivePaymentSectionStateFromModel = () => {
    const model = this.props.model;
    let invoice: {
      invoice_number: string;
      professional_fee: number;
      approved_expense: number;
      document_expense: number;
      extra_expense: number;
      incentive: number;
      deduction: number;
      gst: number;
      invoice_url: string | null;
    } = {
      invoice_number: "",
      professional_fee: 0,
      approved_expense: 0,
      document_expense: 0,
      extra_expense: 0,
      incentive: 0,
      deduction: 0,
      gst: 0,
      invoice_url: null,
    };
    let payment: {
      tds: number;
      received_amount: number;
      payment_date: string;
      utr_no: string;
      settled: boolean;
      status: string;
      slip_url: string | null;
    } = {
      tds: 10,
      received_amount: 0,
      payment_date: "",
      utr_no: "",
      settled: false,
      status: "",
      slip_url: null,
    };

    if (model?.getInvoice() != null) {
      invoice = {
        invoice_number: model.getInvoice()!.getInvoiceNumber(),
        professional_fee: model.getInvoice()!.getProfessionalFee(),
        approved_expense: model.getInvoice()!.getApprovedExpense(),
        document_expense: model.getInvoice()!.getDocumentExpense(),
        extra_expense: model.getInvoice()!.getExtraExpense(),
        incentive: model.getInvoice()!.getIncentive(),
        deduction: model.getInvoice()!.getDeduction(),
        gst: model.getInvoice()!.getGst(),
        invoice_url: model.getInvoice()!.getInvoiceUrl(),
      };
    }

    if (model?.getPayment() != null) {
      payment = {
        tds: model.getPayment()!.getTds(),
        received_amount: model.getPayment()!.getReceivedAmount(),
        payment_date: model.getPayment()!.getPaymentDate(),
        utr_no: model.getPayment()!.getUTRNo(),
        settled: model.getPayment()!.getSettledStatus(),
        status: model.getPayment()!.getSettledStatus() ? "Yes" : "No",
        slip_url: model.getPayment()!.getReceiptUrl(),
      };
    }

    let tmp =
      model?.getInvoice()!.getProfessionalFee() +
      model?.getInvoice()!.getDocumentExpense() +
      model?.getInvoice()!.getApprovedExpense() +
      model?.getInvoice()!.getExtraExpense() +
      model?.getInvoice()!.getIncentive() -
      model?.getInvoice()!.getDeduction();

    return {
      claim_number: (model && model.getClaimNumber()) || "",
      case_type_title: (model && model.getCaseTypeTitle()) || "",
      invoice,
      payment,
      invoiceAmount: (model && model?.getInvoice()!.getInvoiceAmount()) || 0,
    };
  };

  private onSubmit = () => {
    this.setState({ form_loading: true }, () => {
      this.updatePaymentSection();
    });
  };

  private updatePaymentSection = async () => {
    let status = constants.paymentStatus.pending;

    let invoiceAmount = this.state.invoiceAmount;
    let gstAmount = this.state.invoice!.gst;

    let amtTmp = invoiceAmount - gstAmount;
    let dueAmount =
      amtTmp - Math.floor((amtTmp * this.state.payment!.tds) / 100) + gstAmount;

    if (this.state.payment!.received_amount >= dueAmount)
      status = constants.paymentStatus.complete;
    else status = constants.paymentStatus.partial;

    if (this.state.payment!.settled) status = constants.paymentStatus.complete;

    let ro = {
      claim_number: this.state.claim_number,
      payment_receipt:
        this.state.paymentReceipt != null
          ? this.state.paymentReceipt
          : undefined,
      payment: {
        tds: this.state.payment!.tds,
        received_amount: this.state.payment!.received_amount,
        payment_date: this.state.payment!.payment_date,
        utr_no: this.state.payment!.utr_no,

        settled: this.state.payment!.settled,
        status: status,
      },
    };

    const result = await this.paymentSectionRepo.updateData(ro);

    if (result instanceof Result.Success) {
      const receivedData = result.data;
      this.setState({ form_loading: false }, () => {
        toast.success("Information updated successfully");
        this.props.onSuccess(receivedData);
      });
    } else {
      this.setState({ form_loading: false }, () => {
        const message = result.message || "Something went wrong";
        toast.error(message);
        if (this.props.onError) this.props.onError();
      });
    }
  };

  render(): JSX.Element {
    let paymentReceipt = null;
    if (this.state.payment?.slip_url != null) {
      if (typeof this.state.payment.slip_url == "string")
        paymentReceipt = (
          <a
            className="ui primary button"
            href={this.state.payment!.slip_url}
            target="_blank"
          >
            {" "}
            View Receipt{" "}
          </a>
        );
    }
    let invoiceReceipt = null;
    if (this.state.invoice?.invoice_url != null) {
      if (typeof this.state.invoice.invoice_url == "string")
        invoiceReceipt = (
          <a
            className="ui primary button"
            href={this.state.invoice!.invoice_url}
            target="_blank"
          >
            {" "}
            View Invoice Receipt{" "}
          </a>
        );
    }
    let invoiceAmount = 0;

    return (
      <Form loading={this.state.form_loading}>
        {/* Not changeable fields */}
        <Form.Group>
          <Form.Input
            width="8"
            label="Claim Number"
            value={this.state.claim_number}
          />
          <Form.Input
            width="8"
            label="Case Type"
            value={this.state.case_type_title}
          />
        </Form.Group>
        <Form.Group>
          <Form.Input
            width="4"
            label="Document Charges"
            value={this.state.invoice?.document_expense}
          />
          <Form.Input
            width="4"
            label="Approved Expense (Invoice)"
            value={this.state.invoice?.approved_expense}
          />
          <Form.Input
            width="4"
            label="Extra Expense (Investigator)"
            value={this.state.invoice?.extra_expense}
          />
          <Form.Input
            width="4"
            label="Invoice Number"
            value={this.state.invoice?.invoice_number}
          />
        </Form.Group>
        <Form.Group>
          <Form.Input
            width="4"
            label="Professional Fee"
            value={this.state.invoice?.professional_fee}
          />
          <Form.Input
            width="4"
            label="Incentive"
            value={this.state.invoice?.incentive}
          />
          <Form.Input
            width="4"
            label="Deduction"
            value={this.state.invoice?.deduction}
          />
          <Form.Input
            width="4"
            label="GST(amount)"
            value={this.state.invoice?.gst}
          />
        </Form.Group>
        <Form.Group>
          {invoiceReceipt}
          &nbsp;&nbsp;&nbsp;
          <h3>Total Invoice Amount: {this.state.invoiceAmount} Rs. </h3>
        </Form.Group>
        <Divider />
        {/* not changeable fields */}

        <Form.Group>
          <Form.Input
            required
            type="number"
            width="8"
            label="TDS (%)"
            value={this.state.payment?.tds}
            onChange={(_, { value }) =>
              this.setState((prevState) => {
                let payment = Object.assign({}, prevState.payment);
                payment.tds = Number(value);
                return { payment };
              })
            }
          />
          <Form.Input
            required
            type="number"
            width="8"
            label="Amount Received"
            value={this.state.payment?.received_amount}
            onChange={(_, { value }) =>
              this.setState((prevState) => {
                let payment = Object.assign({}, prevState.payment);
                payment.received_amount = Number(value);
                return { payment };
              })
            }
          />
        </Form.Group>
        <Form.Group>
          <Form.Input
            required
            type="text"
            width="8"
            label="UTR Number"
            value={this.state.payment?.utr_no}
            onChange={(_, { value }) =>
              this.setState((prevState) => {
                let payment = Object.assign({}, prevState.payment);
                payment.utr_no = value;
                return { payment };
              })
            }
          />
          <Form.Field required>
            <FormLabel width={"100%"}>Payment Date</FormLabel>
            <Input>
              <DateTimePicker
                type="date"
                placeholder="Pick Payment Date"
                value={this.state.payment?.payment_date}
                onChange={(value) =>
                  this.setState((prevState) => {
                    let payment = Object.assign({}, prevState.payment);
                    payment.payment_date = value;
                    return { payment };
                  })
                }
              />
            </Input>
          </Form.Field>
        </Form.Group>
        <Form.Group>
          <Form.Field>Payment Settled</Form.Field>
          <Form.Field>
            <Radio
              label="Yes"
              name="settled"
              checked={this.state.payment?.settled}
              onChange={(_, { value }) =>
                this.setState((prevState) => {
                  let payment = Object.assign({}, prevState.payment);
                  payment.settled = true;
                  return { payment };
                })
              }
            />
          </Form.Field>
          <Form.Field>
            <Radio
              label="No"
              name="settled"
              checked={!this.state.payment?.settled}
              onChange={(_, { value }) =>
                this.setState((prevState) => {
                  let payment = Object.assign({}, prevState.payment);
                  payment.settled = false;
                  return { payment };
                })
              }
            />
          </Form.Field>

          {/*<Form.Input required*/}
          {/*    type='number'*/}
          {/*    width='8'*/}
          {/*    label='Amount Received'*/}
          {/*    value={this.state.payment?.received_amount}*/}
          {/*    onChange={(_, { value }) =>*/}
          {/*        this.setState(prevState => {*/}
          {/*            let payment = Object.assign({}, prevState.payment);*/}
          {/*            payment.received_amount = Number(value);*/}
          {/*            return { payment };*/}
          {/*        })*/}
          {/*    }*/}
          {/*/>*/}
        </Form.Group>

        <Form.Group>
          {paymentReceipt}
          <Form.Field>
            <FormLabel width="100px">Payment Receipt</FormLabel>
            <Input
              type="file"
              accept="image/*, .pdf, .doc, .docx, .csv, .xls, .xlsx"
              onChange={(e) => {
                if (e.target.files) {
                  const files = e.target.files;
                  let fileArr: File[] = [];
                  for (let i = 0; i < files.length; i++) fileArr.push(files[i]);
                  this.setState({ paymentReceipt: fileArr[0] });
                }
              }}
            />
          </Form.Field>
        </Form.Group>

        <Button
          positive
          onClick={this.onSubmit}
          disabled={
            this.state.payment?.utr_no.trim().length === 0 ||
            this.state.payment!.tds < 0 ||
            this.state.payment!.received_amount < 0 ||
            this.state.payment!.payment_date === ""
          }
        >
          Submit
        </Button>
      </Form>
    );
  }
}

export default InvoiceUpdateForm;
