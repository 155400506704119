import React from "react";
import Location from "../../../../models/Location";
import { Form, Dropdown } from "semantic-ui-react";
import FormLabel from "../../../common/FormLabel";
import { UserLocationInputValue } from "./UserLocationInput";

interface Props {
  value: UserLocationInputValue[][];
  onChange(value: UserLocationInputValue[][]): void;
  locations: Location[];
}

const InvestigatorLocationInput = ({ value, onChange, locations }: Props) => {
  const options = locations.map((it, i) => {
    const value = { state: it.getState(), district: it.getDistrict() };
    return {
      text: `${it.getDistrict()}, ${it.getState()}`,
      value: JSON.stringify(value),
      key: `${i}`,
    };
  });

  return (
    <>
      <Form.Group inline>
        <Form.Field required>
          <FormLabel width="150px">Primary Locations</FormLabel>
          <Dropdown
            search
            selection
            multiple
            options={options}
            value={value[0].map((it) => JSON.stringify(it))}
            onChange={(_, data) => {
              let values = data.value as string[];
              onChange([
                values.map((it) => JSON.parse(it)),
                value[1],
                value[2],
              ]);
            }}
          />
        </Form.Field>
      </Form.Group>

      <Form.Group inline>
        <Form.Field required>
          <FormLabel width="150px">Secondary Locations</FormLabel>
          <Dropdown
            search
            selection
            multiple
            options={options}
            value={value[1].map((it) => JSON.stringify(it))}
            onChange={(_, data) => {
              let values = data.value as string[];
              onChange([
                value[0],
                values.map((it) => JSON.parse(it)),
                value[2],
              ]);
            }}
          />
        </Form.Field>
      </Form.Group>

      <Form.Group inline>
        <Form.Field required>
          <FormLabel width="150px">Tertiary Locations</FormLabel>
          <Dropdown
            search
            selection
            multiple
            options={options}
            value={value[2].map((it) => JSON.stringify(it))}
            onChange={(_, data) => {
              let values = data.value as string[];
              onChange([
                value[0],
                value[1],
                values.map((it) => JSON.parse(it)),
              ]);
            }}
          />
        </Form.Field>
      </Form.Group>
    </>
  );
};

export default InvestigatorLocationInput;
