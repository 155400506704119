import React from "react";
import { IDataTableProps } from "react-data-table-component";
import Renderer from "./renderer/Renderer";
import ColumnRenderer from "./renderer/ColumnRenderer";
import Table from "./Table";
import RowRenderer from "./renderer/RowRenderer";
import List from "./List";

interface Props<T> extends IDataTableProps<T> {
  renderer: Renderer;
}

const TableFactory = (props: Props<{}>) => {
  if (props.renderer instanceof ColumnRenderer) {
    return <Table {...props} columns={props.renderer.getColumns()} />;
  } else if (props.renderer instanceof RowRenderer) {
    return <List {...props} columns={[props.renderer.getColumn()]} />;
  } else {
    return null;
  }
};

export default TableFactory;
