import React from "react";
import Container from "./Container";
import RangeInput2Context from "./RangeInput2Context";

function RangeInput2(props: Props) {
  return (
    <RangeInput2Context.Provider
      value={{
        value: props.value,
        setValue: props.onChange,
        valueValidator: props.valueValidator,
        labelSuffix: props.labelSuffix,
      }}
    >
      <Container />
    </RangeInput2Context.Provider>
  );
}

interface Props {
  value: string[];
  onChange?(value: string[]): void;
  valueValidator?(text: string): boolean;
  labelSuffix?: string;
}

export default RangeInput2;
