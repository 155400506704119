import React, { useState } from 'react'
import { Table, Button, Modal as SemanticModal, Form, Icon } from 'semantic-ui-react'


const BillingLocations = ({ value, onChange }: Props) => {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const internalValues = value.locations.map((it, i) => ({ _id: i.toString(), city: it.city, address: it.address }))
    const rowComponents = internalValues.map(it => (
        <Row value={it} onUpdate={updateLocationAndUpdate} onRemove={removeLocationAndUpdate}/>
    ))

    return (
        <>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Table size='small' compact celled>
                    <TableHeader />
                    <Table.Body>
                        {rowComponents}
                        <EmptyRow onAdd={openModal}/>
                    </Table.Body>
                </Table>
            </div>
        {isModalOpen && (
            <Modal 
                open={true}
                onClose={closeModal}
                onSubmit={addNewLocationAndUpdate}
                />
        )}
        </>
    )

    function openModal() {
         setIsModalOpen(true)
    }

    function closeModal() {
        setIsModalOpen(false)
    }

    function addNewLocationAndUpdate(internalValue: Omit<InternalValue, '_id'>) {
        const location = { city: internalValue.city, address: internalValue.address }
        value.locations.push(location)
        if (onChange) onChange(value)
    }

    function updateLocationAndUpdate(internalValue: InternalValue) {
        const index = internalValues.findIndex(it => it._id === internalValue._id)
        if (index === -1) return
        const location = { city: internalValue.city, address: internalValue.address }
        value.locations[index] = location
        if (onChange) onChange(value)
    }

    function removeLocationAndUpdate(internalValue: InternalValue) {
        const index = internalValues.findIndex(it => it._id === internalValue._id)
        if (index === -1) return
        value.locations.splice(index, 1)
        if (onChange) onChange(value)
    }
}


/** Renders table's header row */
const TableHeader = () => {
    return (
        <Table.Header>
            <Table.Row>
                <Table.HeaderCell>City</Table.HeaderCell>
                <Table.HeaderCell>Address</Table.HeaderCell>
                <Table.HeaderCell>Action</Table.HeaderCell>
            </Table.Row>
        </Table.Header>
    )
}


interface EmptyRowProps {
    onAdd?(): void
}

const EmptyRow = ({ onAdd }: EmptyRowProps) => {
    return (
        <Table.Row>
            <Table.Cell colSpan='3'>
                <Button onClick={onAdd} primary size='mini'>Add</Button>
            </Table.Cell>
        </Table.Row>
    )
}


interface RowProps {
    value: InternalValue
    onUpdate?(value: InternalValue): void
    onRemove?(value: InternalValue): void
}

const Row = ({ 
    value,
    onUpdate,
    onRemove
}: RowProps) => {
    const [isModalOpen, setIsModalOpen] = useState(false)

    return (
        <>
        <Table.Row>
            <Table.Cell>{value.city}</Table.Cell>
            <Table.Cell>{value.address}</Table.Cell>
            <Table.Cell>
                <Button icon size='mini' onClick={openModal}>
                    <Icon name='edit' />
                </Button>
                <Button icon negative size='mini' onClick={() => {
                    if (onRemove) onRemove(value)
                }}>
                    <Icon name='x' />
                </Button>
            </Table.Cell>
        </Table.Row>
        { isModalOpen && (
            <Modal 
                open={isModalOpen}
                value={value} 
                onClose={closeModal}
                onSubmit={onUpdate} />
        )}
        </>
    )

    function openModal() {
        setIsModalOpen(true)
    }

    function closeModal() {
        setIsModalOpen(false)
    }
}

interface ModalProps {
    open?: boolean
    value?: InternalValue
    onSubmit?(data: Omit<InternalValue, '_id'>): void
    onClose?(): void
}

const Modal = ({ 
    open,
    value,
    onSubmit,
    onClose
}: ModalProps) => {
    const [city, setCity] = useState(value && value.city || '')
    const [address, setAddress] = useState(value && value.address || '')

    return (
        <SemanticModal
            size='mini'
            closeIcon
            closeOnDimmerClick={false}
            closeOnEscape={false}
            onClose={onClose}
            open={open}>
            <SemanticModal.Header>
                {value ? 'Update Location' : 'Add New Location'}
            </SemanticModal.Header>

            <SemanticModal.Content>
                <Form>
                    <Form.Input
                        label='City'
                        placeholder='Enter City'
                        value={city}
                        onChange={(_, { value }) => setCity(value)} />

                    <Form.Input
                        label='Address'
                        placeholder='Enter Address'
                        value={address}
                        onChange={(_, { value }) => setAddress(value)} />

                    <Button
                        primary
                        onClick={() => {
                            if (onSubmit) {
                                const data: any = { city, address }
                                if (value) data._id = value._id
                                onSubmit(data)
                            }

                            if (onClose) {
                                onClose()
                            }
                        }}
                        disabled={
                            city.trim().length === 0 ||
                            address.trim().length === 0
                        }>
                        Submit
                    </Button>

                </Form>
            </SemanticModal.Content>
        </SemanticModal>
    )
}


interface Props {
    value: Value
    onChange?(value: Value): void
}


interface Value {
    _id: string //unique id to distinguish b/w values
    state: string
    gstNumber: string
    locations: {
        city: string
        address: string
    }[]
}

interface InternalValue {
    _id: string
    city: string
    address: string
}

export default BillingLocations