import DomainModel from './DomainModel'

export default class Location extends DomainModel {
  constructor(private state: string, private district: string) {
    super()
  }

  getState = () => this.state
  getDistrict = () => this.district
}
