import React from "react";
import constants from "./../../../common/constants";
import CaseFormInputDateTime from "./CaseFormInputDateTime";
import CaseFormInputDate from "./CaseFormInputDate";

/**
 * Factory that returns the InwardDateInput or InwardDateTimeInput
 * based on the caseCategoryCode
 *
 * if caseCategoryCode is LTI only then return DateTimeInput otherwise
 * only the DateInput
 */
interface CaseFormInputInwardDateTimeFactoryProps {
  caseCategoryCode: string;

  value: string;
  onChange(value: string): void;
  labelWidth?: string;
}

const CaseFormInputInwardDateTimeFactory = (
  props: CaseFormInputInwardDateTimeFactoryProps
) => {
  const { caseCategoryCode, ...restProps } = props;
  if (props.caseCategoryCode === constants.UDI.CaseCategory.code.LTI)
    return (
      <CaseFormInputDateTime
        {...restProps}
        label="Inward Date-Time"
        placeholder="Pick Inward Date-Time"
      />
    );
  else
    return (
      <CaseFormInputDate
        {...restProps}
        label="Inward Date"
        placeholder="Pick Inward Date"
      />
    );
};

export default React.memo(CaseFormInputInwardDateTimeFactory);
