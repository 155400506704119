import DomainModel from './DomainModel'
import URLResource from './URLResource'
import AllocatedClaimAnalyst from './AllocatedClaimAnalyst'

class QCData extends DomainModel {
  constructor(
    private caseId: string,
    private report: URLResource,
    private query_trigger_document: URLResource,
    // private repudiation_ground: string,
    private repudiationEvidences: URLResource[],
    private allocations: AllocatedClaimAnalyst[]) {
    super()
  }

  getCaseId = () => this.caseId
  getReport = () => this.report
  getRepudiationEvidences = () => this.repudiationEvidences
  getAllocations = () => this.allocations
  // getRepudiationGround = () => this.repudiation_ground
  getQueryTriggerDocument = () => this.query_trigger_document
}

export default QCData
