export {}
declare global {
  interface Date {
    toAPIFormat(): string
  }
}

Date.prototype.toAPIFormat = function () {
  let day: number | string = this.getDate()
  if (day < 10) day = `0${day}`

  let month: number | string = this.getMonth() + 1
  if (month < 10) month = `0${month}`

  const year = this.getFullYear()
  return `${year}/${month}/${day}`
}
