import React from "react";
import { Divider, Form, Segment } from "semantic-ui-react";
import Button from "../../../elements/Button";
import DocDispatchSection from "../../../../models/DocDispatchSection";
import { toast } from "../../../common/Toast";
import FormLabel from "../../../common/FormLabel";
import DateTimePicker from "../../../app/DateTimePicker";
import constants from "../../../../common/constants";

interface Props {
  //if model is passed that means update
  model?: DocDispatchSection;

  onSuccess: () => void;
  onError?: () => void;
}

interface IInvestigation {
  investigator_emp_id: string;
  investigator_name: string;
  document_charges: number;
  approved_charges: number;
  extra_charges: number;
  document_receipts_arrived_date: string;
  approved_receipts_arrived_date: string;
  extra_receipts_arrived_date: string;
  evidence_arrived_date: string;
}

interface State {
  claim_number: string;
  client_name: string;
  case_type_title: string;
  investigation: IInvestigation[];
}

class DocDispatchDocInfoForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const stateFromModel = this.deriveDocDispatchSectionStateFromModel();
    this.state = {
      ...stateFromModel,
    };
  }

  private deriveDocDispatchSectionStateFromModel = () => {
    const model = this.props.model;

    let investigation: IInvestigation[] = [];

    model?.getInvestigation().forEach((it) => {
      let tmp: IInvestigation = {
        investigator_emp_id: it.getInvestigatorEmpId(),
        investigator_name: it.getInvestigatorName(),
        document_receipts_arrived_date: it.getDocumentDate(),
        approved_receipts_arrived_date: it.getApprovedDocDate(),
        extra_receipts_arrived_date: it.getExtraDocDate(),
        evidence_arrived_date: it.getEvidenceDate(),

        document_charges: it.getDocumentCharges(),
        approved_charges: it.getApprovedCharges(),
        extra_charges: it.getExtraCharges(),
      };

      investigation.push(tmp);
    });

    return {
      claim_number: (model && model.getClaimNumber()) || "",
      case_type_title: (model && model.getCaseTypeTitle()) || "",
      client_name: (model && model.getClientName()) || "",
      investigation,
    };
  };

  private onClose = () => {
    this.props.onSuccess();
  };

  render(): JSX.Element {
    let documentsInformation = null;
    if (this.state.investigation.length > 0)
      documentsInformation = this.state.investigation.map((it) => {
        let docCharges = null;
        let approvedCharges = null;
        let extraCharges = null;
        if (it.document_charges > 0) {
          docCharges = (
            <>
              Document Charges (<b>{it.document_charges} Rs</b>) Received On{" "}
              <b>{it.document_receipts_arrived_date}</b>
              <br />
            </>
          );
        }
        if (it.approved_charges > 0) {
          approvedCharges = (
            <>
              Approved Expense (Invoice) (<b>{it.approved_charges} Rs</b>)
              Received On <b>{it.approved_receipts_arrived_date}</b>
              <br />
            </>
          );
        }
        if (it.extra_charges > 0) {
          extraCharges = (
            <>
              Extra Expense (Investigator) (<b>{it.extra_charges} Rs</b>)
              Received On <b>{it.extra_receipts_arrived_date}</b>
              <br />
            </>
          );
        }

        return (
          <Segment>
            <b>{it.investigator_name}</b>
            <br />
            {docCharges}
            {approvedCharges}
            {extraCharges}
            Evidence Received On <b>{it.evidence_arrived_date}</b>
          </Segment>
        );
      });

    return (
      <Form>
        <Form.Group>
          <Form.Input
            width="5"
            label="Claim Number"
            value={this.state.claim_number}
          />
          <Form.Input
            width="5"
            label="Case Type"
            value={this.state.case_type_title}
          />
          <Form.Input
            width="6"
            label="Client Name"
            value={this.state.client_name}
          />
        </Form.Group>

        {documentsInformation}

        <Button positive onClick={this.onClose}>
          Close
        </Button>
      </Form>
    );
  }
}

export default DocDispatchDocInfoForm;
