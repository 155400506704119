import React from "react";
import { Form } from "semantic-ui-react";
import Button from "../../../elements/Button";
import Result from "../../../../common/repository/Result";
import { toast } from "../../../common/Toast";
import StatesDistrictsRepository from "../../../../common/repository/StatesDistrictsRepository"
// import StateDM from "./../../../../models/State";
// import States from "../../../../models/States";
// import CaseCategoryRM from "../../../../common/responsemodel/CaseCategoryRM";
import CaseCategory from "../../../../models/CaseCategory";
import CaseCategoryRepository from "../../../../common/repository/CaseCategoryRepository"
interface State {
    form_loading: boolean;
    caseCat_name: string;
    caseCat_code: string;
}

interface Props {
    model?: CaseCategory;
    caseCate?: CaseCategory[];
    onSuccess(caseCate: CaseCategory): void;
    onError?(): void;
}

class CaseCategoryForm extends React.Component<Props, State> {
    private CaseCategoryRepo = new CaseCategoryRepository();


    private statesRepo = new StatesDistrictsRepository();

    constructor(props: Props) {
        super(props);
        let loadStates = true;
        if (props.caseCate) loadStates = false;

        const caseCategpryFromModel = this.deriveStateFromModel();
        this.state = {
            form_loading: false,
            ...caseCategpryFromModel,
        };
    }
    private deriveStateFromModel = () => {

        const model = this.props.model;
        return {
            caseCat_name: (model && model.getTitle()) || "",
            caseCat_code: (model && model.getCode()) || "",
        };
    };

    private onSubmit = () => {
        this.setState({ form_loading: true }, () => {
            if (this.props.model) this.updateCaseCategory();
            else this.addCaseCategory();

        });
    };

    private addCaseCategory = async () => {
        const result = await this.CaseCategoryRepo.addCaseCategory({
            name: this.state.caseCat_name,
            code: this.state.caseCat_code,
        });

        if (result instanceof Result.Success) {
            const CaseCategory = result.data;
            console.log(CaseCategory, "caswww")
            toast.success("Case Category Added Successfully!");
            this.props.onSuccess(CaseCategory);
        } else {
            const message = result.message || "Something went wrong";
            this.setState({ form_loading: false }, () => {
                toast.error(message);
                if (this.props.onError) this.props.onError();
            });
        }
    };

    private updateCaseCategory = async () => {
        const CaseCatId = this.props.model!.getId();
        const result = await this.CaseCategoryRepo.updateCateCategory({
            id: CaseCatId,
            name: this.state.caseCat_name,
            code: this.state.caseCat_code,
        });
        if (result instanceof Result.Success) {
            const CaseCategory = result.data;
            toast.success("Case Category Updated Successfully!");
            this.props.onSuccess(CaseCategory);
        } else {
            const message = result.message || "Something went wrong";
            this.setState({ form_loading: false }, () => {
                toast.error(message);
                if (this.props.onError) this.props.onError();
            });
        }
    };


    render() {

        return (
            <Form loading={this.state.form_loading}>
                {/*state name */}
                <Form.Input
                    label="Name"
                    placeholder="..."
                    value={this.state.caseCat_name}
                    onChange={(_, { value }) => this.setState({ caseCat_name: value })}
                />
                {/*state name */}
                {/*state Code */}
                <Form.Input
                    label="Code"
                    placeholder=".."
                    value={this.state.caseCat_code}
                    onChange={(_, { value }) => this.setState({ caseCat_code: value })}
                />
                {/*state Code */}

                <Button
                    positive
                    onClick={this.onSubmit}
                    disabled={this.state.caseCat_name.length === 0 ||
                        this.state.caseCat_code.length === 0
                    }
                >
                    Submit
                </Button>
            </Form>
        );
    }
}

export default CaseCategoryForm;

