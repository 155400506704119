import React from 'react'
import Page from '../../../layout/Page'
import { Modal, Icon, Button } from 'semantic-ui-react'
import CaseTypeForm from '../../../common/CaseTypeForm'
import CaseType from '../../../../models/CaseType'
import CaseTypeRepository from '../../../../common/repository/CaseTypeRepository'
import Result from '../../../../common/repository/Result'
import { toast } from '../../../common/Toast'
import RemovalConfirmationForm from '../../../common/RemovalConfirmationForm'
import Table from '../../../app/Table'
import CaseTypeColumnRenderer from './CaseTypeColumnRenderer'
import RequirePermission from '../../../base/RequirePermission'
import permissions from '../../../../common/permissions'

interface State {
  loading: boolean

  filter: string
  limit: number
  offset: number
  totalCaseTypeCount: number
  caseTypes: CaseType[]

  add_modalOpen: boolean

  update_modalOpen: boolean
  update_model: CaseType | null

  remove_modalOpen: boolean
  remove_model: CaseType | null
}

class CaseTypePage extends React.Component<{}, State> {
  private caseTypeRepo = new CaseTypeRepository()
  private columnRenderer = new CaseTypeColumnRenderer(
    (model: CaseType) => {
      return (
        <Button.Group size="mini">
          <Button onClick={() => this.setState({ update_modalOpen: true, update_model: model })}>Open</Button>
          <Button negative onClick={() => this.setState({ remove_modalOpen: true, remove_model: model })}>
            Remove
          </Button>
        </Button.Group>
      )
    },
    (obj: any) => obj.internalTAT !== undefined
  )

  constructor(props: {}) {
    super(props)
    this.state = {
      loading: false,
      limit: 10,
      offset: 0,
      filter: '',
      totalCaseTypeCount: 0,
      caseTypes: [],
      add_modalOpen: false,
      update_modalOpen: false,
      update_model: null,
      remove_modalOpen: false,
      remove_model: null,
    }
  }

  async componentDidMount() {
    this.loadData()
  }
  private onRowsPerPageChange = (page: number, rowsPerPage: number) => {
    // console.log('onRowsPerPageChange', page, rowsPerPage)
    const offset = (page - 1) * rowsPerPage,
      limit = offset + rowsPerPage
    this.setState({ offset, limit }, this.loadData)
  }

  private setFilter = (filter: string) => {
    this.setState({ filter, offset: 0 }, this.loadData)
  }

  private onCaseTypeAdded = (caseType: CaseType) => {
    this.setState((currentState) => {
      const caseTypes = [caseType, ...currentState.caseTypes]
      const totalCaseTypeCount = currentState.totalCaseTypeCount + 1
      return { caseTypes, totalCaseTypeCount, add_modalOpen: false }
    })
  }

  private onCaseTypeUpdated = (caseType: CaseType) => {
    const caseTypes = this.state.caseTypes
    const index = caseTypes.findIndex((it) => it.getId() === caseType.getId())
    if (index !== -1) caseTypes[index] = caseType
    this.setState({
      caseTypes: [...caseTypes],
      update_modalOpen: false,
      update_model: null,
    })
  }

  private removeCaseType = async () => {
    const id = this.state.remove_model!.getId()
    const result = await this.caseTypeRepo.removeCaseType(id)
    if (result instanceof Result.Success) {
      this.setState(
        (currentState) => {
          const caseTypes = currentState.caseTypes
          const index = caseTypes.findIndex((it) => it.getId() === id)
          if (index !== -1) caseTypes.splice(index, 1)
          const totalCaseTypeCount = currentState.totalCaseTypeCount - 1
          return {
            caseTypes: [...caseTypes],
            totalCaseTypeCount,
            remove_modalOpen: false,
            remove_model: null,
          }
        },
        () => toast.success('Successfully removed Case Type')
      )
    } else {
      const message = result.message || 'Something went wrong'
      toast.error(message)
    }
  }

  private getCaseTypes = async () => {
    const ro = {
      search: this.state.filter,
      limit: this.state.limit,
      offset: this.state.offset,
    }
    return await this.caseTypeRepo.getCaseTypes(ro)
  }

  /**
   * loads new data
   * if offset is 0 then set it as new list
   * else appends to the old list
   */
  private loadData = () => {
    const { offset } = this.state

    this.setState({ loading: true }, async () => {
      const result = await this.getCaseTypes()
      if (result instanceof Result.Success) {
        if (offset === 0) {
          //if offset 0 initialize as new list
          const caseTypes = result.data.items
          const totalCount = result.data.totalItemCount
          this.setState({
            loading: false,
            offset: caseTypes.length,
            caseTypes,
            totalCaseTypeCount: totalCount,
          })
        } else {
          //else check for duplicate items in the new data
          // and then concat with the old
          const caseTypes = [...this.state.caseTypes, ...result.data.items]
          const offset = caseTypes.length
          this.setState({ loading: false, offset, caseTypes })
        }
      } else {
        const message = result.message || 'Something went wrong'
        this.setState({ loading: false }, () => toast.error(message))
      }
    })
  }

  render() {
    return (
      <Page title="Case Types" description="Create and manage the types of the case your company deals in.">
        {/* Add CaseType Modal */}
        <Modal
          open={this.state.add_modalOpen}
          onClose={() => this.setState({ add_modalOpen: false })}
          size="tiny"
          closeIcon
          closeOnDimmerClick={false}
          closeOnEscape={false}
          trigger={
            <Button icon labelPosition="left" color="green" onClick={() => this.setState({ add_modalOpen: true })}>
              <Icon name="add" />
              Create New
            </Button>
          }
        >
          <Modal.Header>Create New Case Type</Modal.Header>
          <Modal.Content>
            <CaseTypeForm onSuccess={this.onCaseTypeAdded} />
          </Modal.Content>
        </Modal>
        {/* Add CaseType Modal */}

        {/* Update CaseType Modal */}
        <Modal open={this.state.update_modalOpen} onClose={() => this.setState({ update_modalOpen: false, update_model: null })} size="tiny" closeIcon closeOnDimmerClick={false} closeOnEscape={false}>
          <Modal.Header>Update Case Type</Modal.Header>
          <Modal.Content>
            <CaseTypeForm model={this.state.update_model || undefined} onSuccess={this.onCaseTypeUpdated} />
          </Modal.Content>
        </Modal>
        {/* Update CaseType Modal */}

        {/* Remove CaseType Modal */}
        <Modal open={this.state.remove_modalOpen} onClose={() => this.setState({ remove_modalOpen: false, remove_model: null })} size="mini" closeIcon closeOnDimmerClick={false} closeOnEscape={false}>
          <Modal.Header>Confirmation</Modal.Header>
          <Modal.Content>
            <RemovalConfirmationForm title="Are you sure you want to remove this?" remove={this.removeCaseType} cancel={() => this.setState({ remove_modalOpen: false, remove_model: null })} />
          </Modal.Content>
        </Modal>
        {/* Remove CaseType Modal */}

        <Table
          renderer={this.columnRenderer}
          data={this.state.caseTypes}
          totalDataCount={this.state.totalCaseTypeCount}
          loading={this.state.loading}
          onRowsPerPageChange={this.onRowsPerPageChange}
          load={this.loadData}
          onSearch={this.setFilter}
          onClear={() => this.setFilter('')}
        />
      </Page>
    )
  }
}

export default (props: any) => {
  return (
    <RequirePermission permission={permissions.Master.CRUD}>
      <CaseTypePage {...props} />
    </RequirePermission>
  )
}
