import React from "react";
import User from "../../../models/User";
import { Card, Header, Button, Popup } from "semantic-ui-react";
import { SemanticCOLORS } from "semantic-ui-react/dist/commonjs/generic";

function AttendanceCard({ user, attendanceState, onChange }: Props) {
  let cardColor = selectCardColor(attendanceState);

  return (
    <Card color={cardColor as SemanticCOLORS} style={{ margin: 0 }}>
      <Card.Content>
        <Header as="h4">
          {user.getName()}
          <Header.Subheader>{user.getRole().getTitle()}</Header.Subheader>
        </Header>
        <AttendanceControl
          user={user}
          attendanceState={attendanceState}
          onChange={onChange}
        />
      </Card.Content>
    </Card>
  );

  function selectCardColor(attendanceState: string): string {
    switch (attendanceState) {
      case "Present":
        return "green";
      case "Absent":
        return "red";
      case "Leave":
      case "Half Leave":
      case "Short Leave":
        return "gray";
      default:
        return "white";
    }
  }
}

const attendanceStates = [
  { title: "Present", code: "P", value: "Present", color: "green" },
  { title: "Absent", code: "A", value: "Absent", color: "red" },
  { title: "Leave", code: "L", value: "Leave", color: "gray" },
  { title: "Half Leave", code: "HL", value: "Half Leave", color: "gray" },
  { title: "Short Leave", code: "SL", value: "Short Leave", color: "gray" },
];
function AttendanceControl({ user, attendanceState, onChange }: Props) {
  const controlComponents = attendanceStates.map((it) => (
    <Popup
      inverted
      content={it.title}
      position="top center"
      trigger={
        <Button
          content={it.code}
          primary={it.value === attendanceState}
          onClick={() => onChange!(user.getId(), "Present")}
          style={{ padding: 12 }}
        />
      }
    />
  ));
  return <Button.Group size="mini">{controlComponents}</Button.Group>;
}

interface Props {
  user: User;
  attendanceState: string;
  onChange?(userId: string, attendanceState: string): void;
}

export default AttendanceCard;
