import DomainModel from './DomainModel'

class InvestigatorActivityDetail extends DomainModel {
    constructor(private checkDate: string, private investigator_name: string, private punchInTime: string, private punchOutTime: string, private totalDistance: any, private details: any[]) {
        super()
    }

    getCheckDate = () => this.checkDate
    getInvestigatorName = () => this.investigator_name
    getPunchInTime = () => this.punchInTime
    getPunchOutTime = () => this.punchOutTime
    getTotalDistance = () => this.totalDistance
    getDetails = () => this.details

    // stringify = () => {
    //     return JSON.stringify({ data: this.data, by: this.by, state: this.state, on: this.on }, null, '  ')
    // }

    // format = () => {
    //     const stringified = this.stringify()
    //     return stringified
    //         .replace(/[{}""]/g, '')
    //         .replace(/.*id.*/g, '') //remove ids
    //         .replace(/^\n$/g, '')
    // }
}


export default InvestigatorActivityDetail
