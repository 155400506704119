import React from "react";
import { Form } from "semantic-ui-react";
import Button from "../../elements/Button";
import Result from "../../../common/repository/Result";
import { toast } from "../Toast";
import StateDM from "./../../../models/State";
import StatesDistrictsRepository from "../../../common/repository/StatesDistrictsRepository"
import VisitPurposeModel from "../../../models/VisitPurposeModel";
import { TagsInput } from "react-tag-input-component";
import VisitPurposeRepository from "../../../common/repository/VisitPurposeRepository";

interface State {
    form_loading: boolean;
    visit_name: string
    // state_code: string
    // state_gst_code: string
    // state_districts: string
    // state_district: string[]
}

interface Props {
    model?: VisitPurposeModel;
    states?: StateDM[];
    onSuccess(states: VisitPurposeModel): void;
    onError?(): void;
}

class VisitPurposeFrom extends React.Component<Props, State> {

    // private visitPurposeRepo = new StatesDistrictsRepository();
    private visitPurposeRepo = new VisitPurposeRepository();

    constructor(props: Props) {
        super(props);
        let loadStates = true;
        if (props.states) loadStates = false;

        const stateFromModel = this.derivePurposeFromModel();
        this.state = {
            form_loading: false,
            // state_districts: "",
            ...stateFromModel,
        };
    }

    private derivePurposeFromModel = () => {
        const model = this.props.model;
        return {
            visit_name: (model && model.getPurpose()) || "",
        };
    };

    private onSubmit = () => {
        this.setState({ form_loading: true }, () => {
            if (this.props.model) this.updateVisitPurpose();
            else this.addvisitPurpose();

        });
    };


    private addvisitPurpose = async () => {
        const result = await this.visitPurposeRepo.addVisitPurpose({
            purpose: this.state.visit_name,
        });

        if (result instanceof Result.Success) {
            const states = result.data;
            toast.success("Purpose of visit Added Successfully!");
            this.props.onSuccess(states);
        } else {
            const message = result.message || "Something went wrong";
            this.setState({ form_loading: false }, () => {
                toast.error(message);
                if (this.props.onError) this.props.onError();
            });
        }
    };

    private updateVisitPurpose = async () => {
        const visitPurposeId = this.props.model!.getId();
        const result = await this.visitPurposeRepo.updateVisitPurpose({
            id: visitPurposeId,
            purpose: this.state.visit_name,
        });

        if (result instanceof Result.Success) {
            const state = result.data;
            toast.success("Purpose of visit Updated Successfully!");
            this.props.onSuccess(state);
        } else {
            const message = result.message || "Something went wrong";
            this.setState({ form_loading: false }, () => {
                toast.error(message);
                if (this.props.onError) this.props.onError();
            });
        }
    };


    render() {

        return (
            <Form loading={this.state.form_loading}>
                <Form.Input
                    label="Purpose of visit"
                    placeholder=""
                    value={this.state.visit_name}
                    onChange={(_, { value }) => this.setState({ visit_name: value })}
                />

                <Button
                    positive
                    onClick={this.onSubmit}
                    disabled={
                        this.props.model?.getId() ?
                            (this.state.visit_name.length === 0)

                            :
                            (this.state.visit_name.length === 0)
                    }
                >
                    Submit
                </Button>
            </Form>
        );
    }
}

export default VisitPurposeFrom;
