import InvestigatorAllowanceRM from './../responsemodel/InvestigatorAllowanceRM'
import InvestigatorAllowance from './../../models/InvestigatorAllowance'
import DataMapper from './DataMapper'

class InvestigatorAllowanceDataMapper extends DataMapper<InvestigatorAllowanceRM, InvestigatorAllowance> {
  map = (rm: InvestigatorAllowanceRM) => {
    return new InvestigatorAllowance(rm.id, rm.primary_location, rm.secondary_location, rm.tertiary_location)
  }
}

export default InvestigatorAllowanceDataMapper
