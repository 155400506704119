
import ProtectedAPIRepository from './ProtectedAPIRepository'
import APIResponseModel from './APIResponseModel'
import Result from './Result'
import MrdChargesRM from '../responsemodel/MrdChargesRM'
import MrdChargesDataMapper from '../datamapper/MrdChargesDataMapper'

export default class MrdRepository extends ProtectedAPIRepository {
    private MrdChargesDataMapper = new MrdChargesDataMapper()

    getMrdCharges = async (ro?: GetMRD_ChargesObject) => {
        const response = await this.post<APIResponseModel<MrdChargesRM>>('/case/getMrdCharges', ro)
        if (response instanceof Result.Success) {
            const data: APIResponseModel<MrdChargesRM> = response.data
            if (data.data !== null) {
                const MrdCharges = data.data.items.map((it) => this.MrdChargesDataMapper.map(it))
                return new Result.Success({
                    totalItemCount: data.data.total_item_count,
                    items: MrdCharges,
                })
            } else {
                return new Result.Error(0, 'Data is null')
            }
        } else {
            return response
        }
    }


    UpdateMrdCharge = async (ro: UpdateMrdChargeObject) => {
        const response = await this.post<APIResponseModel<MrdChargesRM>>('/case/updateMrdCharges', ro)
        if (response instanceof Result.Success) {
            const data: APIResponseModel<MrdChargesRM> = response.data
            if (data.data !== null) {
                const MrdCharges = this.MrdChargesDataMapper.map(data.data.items[0])
                return new Result.Success(MrdCharges)
            } else {
                return new Result.Error(0, 'Data is null')
            }
        } else {
            return response
        }
    }

    removeState = async (StateId: string) => {
        const response = await this.delete<APIResponseModel<null>>(`/state/${StateId}`)
        return response
    }

}


export interface GetMRD_ChargesObject {
    search?: string
    limit?: number
    offset?: number
    accepted?: string
}

export interface UpdateMrdChargeObject {
    id: string
    accepted: string
}