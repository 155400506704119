import React from "react";
import BaseProps from "../../base/BaseProps";

interface CaseFormButtonTextProps extends BaseProps {
  onClick(): void;
}

const CaseFormButtonText = (props: CaseFormButtonTextProps) => {
  const { onClick, children } = props;
  return (
    <p
      onClick={onClick}
      style={{
        textDecoration: "underline",
        cursor: "pointer",
        color: "#031b4d",
      }}
    >
      {children}
    </p>
  );
};

export default CaseFormButtonText;
