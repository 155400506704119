import ProtectedAPIRepository from './ProtectedAPIRepository'
import Result from './Result'
import APIResponseModel from './APIResponseModel'
import PolicyTypeRM from '../responsemodel/PolicyTypeRM'
import PolicyTypeDataMapper from '../datamapper/PolicyTypeDataMapper'

export default class PolicyTypeRepository extends ProtectedAPIRepository {
  private dataMapper = new PolicyTypeDataMapper()

  getPolicyTypes = async (ro?: GetPolicyTypesRequestObject) => {
    const response = await this.post<APIResponseModel<PolicyTypeRM>>('/policy', ro)
    if (response instanceof Result.Success) {
      const data: APIResponseModel<PolicyTypeRM> = response.data
      if (data.data !== null) {
        const policyTypes = data.data.items.map((it) => this.dataMapper.map(it))
        return new Result.Success({
          totalItemCount: data.data.total_item_count,
          items: policyTypes,
        })
      } else {
        return new Result.Error(0, 'Data is null')
      }
    } else {
      return response
    }
  }

  addPolicyType = async (ro: AddPolicyTypeRequestObject) => {
    const response = await this.put<APIResponseModel<PolicyTypeRM>>('/policy', ro)
    if (response instanceof Result.Success) {
      const data: APIResponseModel<PolicyTypeRM> = response.data
      if (data.data !== null) {
        const policy = this.dataMapper.map(data.data.items[0])
        return new Result.Success(policy)
      } else {
        return new Result.Error(0, 'Data is null')
      }
    } else {
      return response
    }
  }

  updatePolicyType = async (ro: UpdatePolicyTypeRequestObject) => {
    const response = await this.patch<APIResponseModel<PolicyTypeRM>>('/policy', ro)
    if (response instanceof Result.Success) {
      const data: APIResponseModel<PolicyTypeRM> = response.data
      if (data.data !== null) {
        const policy = this.dataMapper.map(data.data.items[0])
        return new Result.Success(policy)
      } else {
        return new Result.Error(0, 'Data is null')
      }
    } else {
      return response
    }
  }

  removePolicyType = async (policyTypeId: string) => {
    const response = await this.delete<APIResponseModel<null>>(`/policy/${policyTypeId}`)
    return response
  }
}

interface GetPolicyTypesRequestObject {
  search?: string
  id?: string
  title?: string
}

interface AddPolicyTypeRequestObject {
  title: string
  description: string
}

interface UpdatePolicyTypeRequestObject {
  id: string
  title: string
  description: string
}
