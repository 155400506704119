import DataMapper from './DataMapper'
import RemarkRM from '../responsemodel/RemarkRM'
import Remark from '../../models/Remark'
import UserDataMapper from './UserDataMapper'

class RemarkDataMapper extends DataMapper<RemarkRM, Remark> {
  private userDataMapper = new UserDataMapper()
  map = (rm: RemarkRM) => {
    const user = this.userDataMapper.map(rm.by)
    return new Remark(rm.id, rm.text, user, rm.on)
  }
}

export default RemarkDataMapper
