import React from 'react'
import Page from '../../../../layout/Page'
import { Button, Grid, Form, Segment, Header, Icon } from 'semantic-ui-react'
import HospitalRepository, { GetHospitalsRequestObject } from '../../../../../common/repository/HospitalRepository'
import Result from '../../../../../common/repository/Result'
import { toast } from '../../../../common/Toast'
import Hospital from '../../../../../models/Hospital'
import Table from '../../../../app/Table'
import HospitalColumnRenderer from '../../HospitalPage/HospitalColumnRenderer'
import RequirePermission from '../../../../base/RequirePermission'
import permissions from '../../../../../common/permissions'
import { Link } from 'react-router-dom'
import StateDistrictRepository from "../../../../../common/repository/StateDistrictRepository";
import StateDM from "../../../../../models/State";

interface State {
    loading: boolean
    filter: string
    limit: number
    offset: number
    totalHospitalCount: number
    hospitals: Hospital[]

    form_loadingStates: boolean;
    form_states: StateDM[];
    form_loadingDistricts: boolean;
    form_districts: string[];
    hospital_state: string;
    hospital_district: string;
    isExpanded: boolean
}

class HospitalPage extends React.Component<{}, State> {
    private hospitalRepo = new HospitalRepository()
    private StateDistrictRepository = new StateDistrictRepository()
    private columnRenderer = new HospitalColumnRenderer(
        (model: Hospital) => {
            let id = model.getId()
            return (
                <Button
                    style={{ width: 100 }}
                    primary
                    as={Link}
                    to={{
                        pathname: `/master/reports/hospital-infra-investigator/${id}`,
                    }}
                >
                    View
                </Button>
            )
        },
        (obj: any) => obj.name !== undefined
    )

    constructor(props: {}) {
        super(props)
        this.state = {
            loading: false,
            filter: '',
            limit: 10,
            offset: 0,
            totalHospitalCount: 0,
            hospitals: [],

            form_loadingStates: false,
            form_states: [],
            form_loadingDistricts: false,
            form_districts: [],
            hospital_state: "",
            hospital_district: "",
            isExpanded: false,
        }
    }

    componentDidMount() {
        this.load()
        this.getStates()
        if (this.state.hospital_state) this.getDistricts();
    }

    private getStates = () => {
        this.setState({ form_loadingStates: true }, async () => {
            const result = await this.StateDistrictRepository.getStates();
            if (result instanceof Result.Success) {
                const states = result.data.items;
                this.setState({ form_loadingStates: false, form_states: states });
            } else {
                const message = result.message || "Something went wrong";
                this.setState({ form_loadingStates: false }, () =>
                    toast.error(message)
                );
            }
        });
    };

    private getDistricts = () => {
        const state = this.state.hospital_state;
        this.setState({ form_loadingDistricts: true }, async () => {
            const result = await this.StateDistrictRepository.getDistricts({ state });
            if (result instanceof Result.Success) {
                const districts = result.data.items;
                this.setState({
                    form_loadingDistricts: false,
                    form_districts: districts,
                });
            } else {
                const message = result.message || "Something went wrong";
                this.setState({ form_loadingDistricts: false }, () =>
                    toast.error(message)
                );
            }
        });
    };

    private onRowsPerPageChange = (page: number, rowsPerPage: number) => {
        const offset = (page - 1) * rowsPerPage,
            limit = offset + rowsPerPage
        this.setState({ offset, limit }, this.load)
    }
    private load = async () => {
        const { offset } = this.state
        this.setState({ loading: true }, async () => {
            const result = await this.getHospitals()
            if (result instanceof Result.Success) {
                if (offset === 0) {
                    const hospitals = result.data.items
                    const totalHospitalCount = result.data.totalItemCount
                    const offset = hospitals.length
                    this.setState({ loading: false, offset, hospitals, totalHospitalCount })
                } else {
                    const hospitals = [...this.state.hospitals, ...result.data.items]
                    const offset = hospitals.length
                    this.setState({ loading: false, offset, hospitals })
                }
            } else {
                this.setState({ loading: false }, () => {
                    const message = result.message || 'Something went wrong'
                    toast.error(message)
                })
            }
        })
    }
    private getHospitals = async () => {
        const ro: GetHospitalsRequestObject = {
            search: this.state.filter,
            limit: this.state.limit,
            offset: this.state.offset,
        }
        if (this.state.hospital_state) ro.location = { state: this.state.hospital_state }
        if (this.state.hospital_district) ro.location = { state: this.state.hospital_state, district: this.state.hospital_district }
        return await this.hospitalRepo.getHospitals(ro)
    }
    private setFilter = (filter: string) => {
        this.setState({ filter, offset: 0 }, this.load)
    }

    private fetchData = async () => {
        await this.setState({ limit: 100, offset: 0 });
        if (this.state.hospital_state || this.state.hospital_district
        ) {
            await this.setState({ offset: 0 });
        }
        this.load();
    };

    private clearAllFilters = async () => {
        await this.setState({
            limit: 0,
            offset: 0,
            hospital_state: "",
            hospital_district: "",
            filter: ""
        })
        await this.load()
    }

    render() {
        const stateOptions = this.state.form_states.map((it) => {
            return { text: it.getName(), value: it.getName(), key: it.getCode() };
        });

        const districtsOptions = this.state.form_districts.map((it) => {
            return { text: it, value: it, key: it };
        });

        return (
            <Page title="Hospital" >
                <Segment>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <Header as="h4">Advance Filter</Header>
                        <Icon
                            name="arrow alternate circle down outline"
                            size="large"
                            onClick={() => this.setState({ isExpanded: !this.state.isExpanded })}
                        />
                    </div>
                    {this.state.isExpanded && (<Grid columns={5}>
                        <Grid.Row>
                            <Grid.Column>
                                <Form.Dropdown
                                    search
                                    selection
                                    loading={this.state.form_loadingStates}
                                    options={stateOptions}
                                    label="State"
                                    placeholder="Select State"
                                    value={this.state.hospital_state}
                                    onChange={(_, { value }) => {
                                        if (!value) return;
                                        const selectedState = value.toString();
                                        this.setState({ hospital_state: selectedState, hospital_district: "" }, this.getDistricts);
                                    }}
                                />
                            </Grid.Column>
                            <Grid.Column>
                                <Form.Dropdown
                                    search
                                    selection
                                    loading={this.state.form_loadingDistricts}
                                    options={districtsOptions}
                                    label="District"
                                    placeholder="Select District"
                                    value={this.state.hospital_district}
                                    onChange={(_, { value }) => {
                                        if (!value) return;
                                        const selectedDistrict = value.toString();
                                        this.setState({ hospital_district: selectedDistrict });
                                    }}
                                />
                            </Grid.Column>
                            <Grid.Column>
                                &nbsp;
                                <div className="ui buttons">
                                    <button
                                        className="ui blue button"
                                        onClick={this.fetchData}
                                    >
                                        Apply Filters
                                    </button>
                                    <button
                                        className="ui button"
                                        onClick={this.clearAllFilters}
                                    >
                                        Clear
                                    </button>
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>)}
                </Segment>
                <Table
                    renderer={this.columnRenderer}
                    data={this.state.hospitals}
                    totalDataCount={this.state.totalHospitalCount}
                    loading={this.state.loading}
                    load={this.load}
                    onRowsPerPageChange={this.onRowsPerPageChange}
                    onSearch={this.setFilter}
                    onClear={() => this.setFilter('')}
                />
            </Page>
        )
    }
}
export default (props: any) => {
    return (
        <RequirePermission permission={permissions.Master.CRUD}>
            <HospitalPage {...props} />
        </RequirePermission>
    )
}