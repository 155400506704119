import React, { useState, useEffect, useContext } from 'react'
import RangeInput2 from '../../app/RangeInput2'
import number from '../../../common/validator/number'
import { Header, Divider, Input, Segment, Button } from 'semantic-ui-react'
import FormLabel from '../../common/FormLabel'
import SettingsPageContext from './SettingsPageContext'
import Result from '../../../common/repository/Result'
import { toast } from '../../common/Toast'
import RangeInput3 from '../../app/RangeInput3'

function IncentiveSegment() {
  const ctx = useContext(SettingsPageContext)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [saleReportPointsI, setSaleReportPointsI] = useState<string[]>(Array(101).fill(''))
  const [tatAveragePointsI, setTatAveragePointsI] = useState<string[]>(Array(101).fill(''))
  const [caseTargetPointsI, setCaseTargetPointsI] = useState<string[]>(Array(31).fill(''))
  const [noLeavePointI, setNoLeavePointsI] = useState('')
  const [noRedmarkPointsI, setNoRedmarkPointsI] = useState('')
  const [incentivesI, setIncentivesI] = useState<string[]>(Array(101).fill(''))
  const [gradePointsCA, setGradePointsCA] = useState<string[]>(Array(6).fill(''))
  const [casesClosedPointsCA, setCasesClosedPointsCA] = useState<string[]>(Array(101).fill(''))
  const [noLeavePointCA, setNoLeavePointsCA] = useState('')
  const [noRedmarkPointsCA, setNoRedmarkPointsCA] = useState('')
  const [incentivesCA, setIncentivesCA] = useState<string[]>(Array(101).fill(''))
  const [gradePointsTL, setGradePointsTL] = useState<string[]>(Array(6).fill(''))
  const [casesClosedPointsTL, setCasesClosedPointsTL] = useState<string[]>(Array(101).fill(''))
  const [noLeavePointTL, setNoLeavePointsTL] = useState('')
  const [noRedmarkPointsTL, setNoRedmarkPointsTL] = useState('')
  const [incentivesTL, setIncentivesTL] = useState<string[]>(Array(101).fill(''))

  useEffect(effectInitializeValues, [ctx.settings])
  useEffect(() => {
    effectOnSubmit()
  }, [isSubmitting])

  return (
    <Segment basic loading={isSubmitting}>
      <Header as="h3" color="grey">
        Investigator
      </Header>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <FormLabel width="150px">
          <strong>Sale Report Points</strong>
        </FormLabel>
        <RangeInput2 value={saleReportPointsI} onChange={setSaleReportPointsI} labelSuffix="%" valueValidator={number.isValid} />
      </div>

      <Divider hidden />

      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <FormLabel width="150px">
          <strong>TAT Average Points</strong>
        </FormLabel>
        <RangeInput2 value={tatAveragePointsI} onChange={setTatAveragePointsI} labelSuffix="%" valueValidator={number.isValid} />
      </div>

      <Divider hidden />

      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <FormLabel width="150px">
          <strong>Case Target Points</strong>
        </FormLabel>
        <RangeInput2 value={caseTargetPointsI} onChange={setCaseTargetPointsI} valueValidator={number.isValid} />
      </div>

      <Divider hidden />

      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <FormLabel width="150px">
          <strong>No Leave Points</strong>
        </FormLabel>
        <Input
          value={noLeavePointI}
          onChange={(_, { value }) => {
            if (number.isValid(value)) setNoLeavePointsI(value)
          }}
        />
      </div>

      <Divider hidden />

      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <FormLabel width="150px">
          <strong>No Red Mark Points</strong>
        </FormLabel>
        <Input
          value={noRedmarkPointsI}
          onChange={(_, { value }) => {
            if (number.isValid(value)) setNoRedmarkPointsI(value)
          }}
        />
      </div>

      <Divider hidden />

      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <FormLabel width="150px">
          <strong>Incentives</strong>
        </FormLabel>
        <RangeInput2 value={incentivesI} onChange={setIncentivesI} valueValidator={number.isValid} />
      </div>

      <Divider />

      <Header as="h3" color="grey">
        Claim Analyst
      </Header>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <FormLabel width="150px">
          <strong>Grade Average Points</strong>
        </FormLabel>
        <RangeInput2 value={gradePointsCA} onChange={setGradePointsCA} valueValidator={number.isValid} />
      </div>
      <Divider hidden />
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <FormLabel width="150px">
          <strong>Cases Closed Points</strong>
        </FormLabel>
        <RangeInput2 value={casesClosedPointsCA} onChange={setCasesClosedPointsCA} valueValidator={number.isValid} />
      </div>

      <Divider hidden />

      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <FormLabel width="150px">
          <strong>No Leave Points</strong>
        </FormLabel>
        <Input
          value={noLeavePointCA}
          onChange={(_, { value }) => {
            if (number.isValid(value)) setNoLeavePointsCA(value)
          }}
        />
      </div>

      <Divider hidden />

      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <FormLabel width="150px">
          <strong>No Red Mark Points</strong>
        </FormLabel>
        <Input
          value={noRedmarkPointsCA}
          onChange={(_, { value }) => {
            if (number.isValid(value)) setNoRedmarkPointsCA(value)
          }}
        />
      </div>

      <Divider hidden />

      <Divider hidden />
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <FormLabel width="150px">
          <strong>Incentives</strong>
        </FormLabel>
        <RangeInput2 value={incentivesCA} onChange={setIncentivesCA} valueValidator={number.isValid} />
      </div>

      <Divider />

      <Header as="h3" color="grey">
        Team Lead
      </Header>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <FormLabel width="150px">
          <strong>Grade Average Points</strong>
        </FormLabel>
        <RangeInput2 value={gradePointsTL} onChange={setGradePointsTL} valueValidator={number.isValid} />
      </div>
      <Divider hidden />
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <FormLabel width="150px">
          <strong>Cases Closed Points</strong>
        </FormLabel>
        <RangeInput2 value={casesClosedPointsTL} onChange={setCasesClosedPointsTL} valueValidator={number.isValid} />
      </div>
      <Divider hidden />
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <FormLabel width="150px">
          <strong>No Leave Points</strong>
        </FormLabel>
        <Input
          value={noLeavePointTL}
          onChange={(_, { value }) => {
            if (number.isValid(value)) setNoLeavePointsTL(value)
          }}
        />
      </div>

      <Divider hidden />

      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <FormLabel width="150px">
          <strong>No Red Mark Points</strong>
        </FormLabel>
        <Input
          value={noRedmarkPointsTL}
          onChange={(_, { value }) => {
            if (number.isValid(value)) setNoRedmarkPointsTL(value)
          }}
        />
      </div>

      <Divider hidden />

      <Divider hidden />
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <FormLabel width="150px">
          <strong>Incentives</strong>
        </FormLabel>
        <RangeInput2 value={incentivesTL} onChange={setIncentivesTL} valueValidator={number.isValid} />
      </div>

      <Button primary onClick={() => setIsSubmitting(true)} disabled={isSubmitButtonDisable()}>
        Submit
      </Button>
    </Segment>
  )

  function effectInitializeValues() {
    if (ctx.settings === null) return
    const incentives = ctx.settings.getIncentive()
    setSaleReportPointsI(
      padArray(
        incentives.investigator.saleReportPoints.map((it) => it.toString()),
        '',
        101
      )
    )
    setTatAveragePointsI(
      padArray(
        incentives.investigator.tatAveragePoints.map((it) => it.toString()),
        '',
        101
      )
    )
    setCaseTargetPointsI(
      padArray(
        incentives.investigator.caseTargetPoints.map((it) => it.toString()),
        '',
        31
      )
    )
    setNoLeavePointsI((incentives.investigator.noLeavePoints && incentives.investigator.noLeavePoints.toString()) || '')
    setNoRedmarkPointsI((incentives.investigator.noRedmarkPoints && incentives.investigator.noRedmarkPoints.toString()) || '')
    setIncentivesI(
      padArray(
        incentives.investigator.incentives.map((it) => it.toString()),
        '',
        101
      )
    )
    setGradePointsCA(
      padArray(
        incentives.claimAnalyst.gradeAveragePoints.map((it) => it.toString()),
        '',
        6
      )
    )
    setCasesClosedPointsCA(
      padArray(
        incentives.claimAnalyst.caseClosedPoints.map((it) => it.toString()),
        '',
        101
      )
    )
    setNoLeavePointsCA((incentives.claimAnalyst.noLeavePoints && incentives.claimAnalyst.noLeavePoints.toString()) || '')
    setNoRedmarkPointsCA((incentives.claimAnalyst.noRedmarkPoints && incentives.claimAnalyst.noRedmarkPoints.toString()) || '')
    setIncentivesCA(
      padArray(
        incentives.claimAnalyst.incentives.map((it) => it.toString()),
        '',
        101
      )
    )
    setGradePointsTL(
      padArray(
        incentives.teamLead.gradeAveragePoints.map((it) => it.toString()),
        '',
        6
      )
    )
    setCasesClosedPointsTL(
      padArray(
        incentives.teamLead.caseClosedPoints.map((it) => it.toString()),
        '',
        101
      )
    )
    setNoLeavePointsTL((incentives.teamLead.noLeavePoints && incentives.teamLead.noLeavePoints.toString()) || '')
    setNoRedmarkPointsTL((incentives.teamLead.noRedmarkPoints && incentives.teamLead.noRedmarkPoints.toString()) || '')
    setIncentivesTL(
      padArray(
        incentives.teamLead.incentives.map((it) => it.toString()),
        '',
        101
      )
    )
  }

  function padArray(arr: string[], value: string, length: number) {
    if (arr.length === length) return arr
    const diff = length - arr.length
    if (diff <= 0) return arr
    return [...arr, ...Array(diff).fill(value)]
  }

  async function effectOnSubmit() {
    if (!isSubmitting || ctx.settings === null) return
    const result = await ctx.settingsRepo.updateSettings({
      id: ctx.settings.getId(),
      incentive: {
        investigator: {
          sale_report: saleReportPointsI.map((it) => parseFloat(it)),
          tat_average: tatAveragePointsI.map((it) => parseFloat(it)),
          case_target: caseTargetPointsI.map((it) => parseFloat(it)),
          no_leave: parseFloat(noLeavePointI),
          no_red_mark: parseFloat(noRedmarkPointsI),
          incentive: incentivesI.map((it) => parseFloat(it)),
        },
        claim_analyst: {
          grade_average: gradePointsCA.map((it) => parseFloat(it)),
          case_closed: casesClosedPointsCA.map((it) => parseFloat(it)),
          no_leave: parseFloat(noLeavePointCA),
          no_red_mark: parseFloat(noRedmarkPointsCA),
          incentive: incentivesCA.map((it) => parseFloat(it)),
        },
        team_lead: {
          grade_average: gradePointsTL.map((it) => parseFloat(it)),
          case_closed: casesClosedPointsTL.map((it) => parseFloat(it)),
          no_leave: parseFloat(noLeavePointTL),
          no_red_mark: parseFloat(noRedmarkPointsTL),
          incentive: incentivesTL.map((it) => parseFloat(it)),
        },
      },
    })

    if (result instanceof Result.Success) {
      const settings = result.data
      ctx.setSettings(settings)
      toast.success('Successfully updated Settings')
    } else {
      const message = result.message || 'Something went wrong'
      toast.error(message)
    }
    setIsSubmitting(false)
  }

  function isSubmitButtonDisable() {
    return (
      isSubmitting ||
      saleReportPointsI.some(isEmpty) ||
      tatAveragePointsI.some(isEmpty) ||
      caseTargetPointsI.some(isEmpty) ||
      isEmpty(noLeavePointI) ||
      isEmpty(noRedmarkPointsI) ||
      incentivesI.some(isEmpty) ||
      gradePointsCA.some(isEmpty) ||
      casesClosedPointsCA.some(isEmpty) ||
      isEmpty(noLeavePointCA) ||
      isEmpty(noRedmarkPointsCA) ||
      incentivesCA.some(isEmpty) ||
      gradePointsTL.some(isEmpty) ||
      casesClosedPointsTL.some(isEmpty) ||
      isEmpty(noLeavePointTL) ||
      isEmpty(noRedmarkPointsTL) ||
      incentivesTL.some(isEmpty)
    )
  }

  function isEmpty(str: string) {
    return str.trim().length === 0
  }
}

export default IncentiveSegment
