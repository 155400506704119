import React from "react";
import BaseProps from "../base/BaseProps";

interface FormLabelProps extends BaseProps {
  width: string;
}

const FormLabel = (props: FormLabelProps) => {
  return <label style={{ width: props.width }}>{props.children}</label>;
};

export default FormLabel;
