import React, { useState, useContext, useEffect } from "react";
import { DropdownItemProps, Form, Grid } from "semantic-ui-react";
import axios from "axios";
import LocalStorage from "../../../../lib/LocalStorage";
import UserRepository from "../../../../common/repository/UserRepository";
import constants from "../../../../common/constants";
import Result from "../../../../common/repository/Result";
import { toast } from "../../../common/Toast";
import DateTimePicker from "../../../app/DateTimePicker";
import config from "../../../../common/repository/config";

function DocumentPendencyTemplateDownloadForm({ onSuccess }: Props) {
  const [userOptions, setUserOptions] = useState<DropdownItemProps[]>([]);
  const [investigatorId, setInvestigatorId] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const userRepo = new UserRepository();

  const loadEssentialData = async () => {
    let userRole = constants.roleTitle.investigator;
    const userData = await userRepo.getUsers({
      role: userRole,
      is_active: true,
    });
    if (userData instanceof Result.Success) {
      setUserOptions(
        userData.data.items.map((it) => ({
          text: it.getName(),
          value: it.getId(),
          key: it.getId(),
        }))
      );
    } else {
      const message = userData.message || "Could not load Users";
      toast.error(message);
    }
  };

  useEffect(() => {
    if (userOptions.length == 0) {
      loadEssentialData();
    }
  }, []);

  return (
    <Form>
      <Form.Dropdown
        label="Choose Investigator"
        search
        selection
        placeholder="Select Investigator"
        options={userOptions}
        value={investigatorId}
        onChange={(_, { value }) => setInvestigatorId(value as string)}
      />

      <Form.Input label="From Date">
        <DateTimePicker
          placeholder="Select From Date"
          type="date"
          value={fromDate}
          onChange={(val) => setFromDate(val)}
        />
      </Form.Input>
      <Form.Input label="To Date" disabled={fromDate.length == 0}>
        <DateTimePicker
          placeholder="Select To Date"
          type="date"
          value={toDate}
          onChange={(val) => setToDate(val)}
        />
      </Form.Input>

      <Form.Button
        positive
        disabled={investigatorId.length == 0}
        onClick={downloadTemplate}
      >
        Download
      </Form.Button>
    </Form>
  );

  async function downloadTemplate() {
    toast.success("Download will start shortly!");
    const token = LocalStorage.get(LocalStorage.KEY_AUTH_TOKEN);
    const response = await axios({
      url: config.apiBaseURL+`/billing/documentPendency/template`,
      method: "POST",
      data: {
        investigator_id: investigatorId,
        from: fromDate.length > 0 ? fromDate : undefined,
        to: toDate.length > 0 ? toDate : undefined,
      },
      responseType: "blob",
      headers: { ["x-access-token"]: token },
    });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "documentPendencyTemplate.xlsx");
    document.body.appendChild(link);
    link.click();
    link.remove();

    if (onSuccess) onSuccess();
  }
}

interface Props {
  onSuccess?(): void;
}

export default DocumentPendencyTemplateDownloadForm;
