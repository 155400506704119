import DomainModel from './DomainModel'

class Grade extends DomainModel {
  constructor(
    private gradeAllocationHygiene: number, //0 or 1
    private gradeReportQuality: number, //0 or 1
    private gradeOutcome: number, //0,1, or 2
    private gradeTAT: number //0 or 1
  ) {
    super()
  }

  getGradeAllocationHygiene = () => this.gradeAllocationHygiene
  getGradeReportQuality = () => this.gradeReportQuality
  getGradeOutcome = () => this.gradeOutcome
  getGradeTAT = () => this.gradeTAT

  getRating = () => {
    return this.gradeAllocationHygiene + this.gradeReportQuality + this.gradeOutcome + this.gradeTAT
  }
}

export default Grade
