
import DataMapper from '../DataMapper'
import InsuredDetailRM from '../../responsemodel/ReportRM/InsuredDetailRM'
import InsuredDetail from '../../../models/ReportModels/InsuredDetail'

export default class InsuredDetailDataMapper extends DataMapper<InsuredDetailRM, InsuredDetail> {
    map = (rm: InsuredDetailRM) => new InsuredDetail(
        rm.commonData,
        rm.dataWithDate,
        rm.claimsAsAttendent,
        rm.claimsAsInsured,
        rm.repudatedAttendent,
        rm.repudatedInsured,
        rm.feedback,
        rm.feedback_id
    )
}
