import React, { useContext } from "react";
import styled from "styled-components";
import Input from "./Input";
import RangeInput2Context from "./RangeInput2Context";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 4px 16px;
`;

function Range() {
  const ctx = useContext(RangeInput2Context);

  return (
    <Container>
      {ctx.value.map((it, i) => (
        <Input
          index={i}
          labelSuffix={ctx.labelSuffix}
          value={it}
          onChange={onInputChange}
        />
      ))}
    </Container>
  );

  function onInputChange(i: number, v: string) {
    const newValue = ctx.value;
    if (ctx.valueValidator && !ctx.valueValidator(v)) return;
    for (let j = i; j < ctx.value.length; j++) newValue[j] = v;
    if (ctx.setValue) ctx.setValue([...newValue]);
  }
}

export default Range;
