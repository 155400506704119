import React from 'react'
import DataTable from 'react-data-table-component'
import { Segment } from 'semantic-ui-react'
import TableActions, { TableActionsProps } from './TableActions'
import Renderer from './renderer/Renderer'
import TableFactory from './TableFactory'
import CompleteCase from '../../../models/CompleteCase'
import DraftCase from '../../../models/DraftCase'
import User from '../../../models/User'
import Hospital from '../../../models/Hospital'
import PolicyType from '../../../models/PolicyType'
import Client from '../../../models/Client'
import InvoiceSection from '../../../models/InvoiceSection'
import PaymentSection from '../../../models/PaymentSection'

interface Props extends TableActionsProps {
  renderer: Renderer

  totalDataCount: number
  data: any[]

  loading: boolean
  load(): void
  onRowsPerPageChange?: (page: number, rowsPerPage: number) => void

  debug?: boolean
  dense?: boolean
  DownloadBtn?:boolean
}

interface State {
  page: number
  rowsPerPage: number
}

export default class Table extends React.Component<Props, State> {
  static ROWS_PER_PAGE = [10, 20, 50]

  constructor(props: Props) {
    super(props)
    this.state = {
      page: 1,
      rowsPerPage: Table.ROWS_PER_PAGE[0],
    }
  }

  componentDidMount() {
    this.removeDefaultHeader()
  }

  downloadCases = () => {
    let data = this.props.data
    let startSlice = this.state.page * this.state.rowsPerPage - this.state.rowsPerPage
    let endSlice = this.state.rowsPerPage * this.state.page
    let rs = data.slice(startSlice, endSlice)
    let Ids: string[] = []
    let action = ''
    if (rs.length > 0) {
      for (let i = 0; i < rs.length; i++) {
        let tmp = rs[i]
        if (tmp instanceof CompleteCase || tmp instanceof DraftCase) {
          Ids.push(tmp.getId().toString())
          action = 'case'
        } else if (tmp instanceof User) {
          Ids.push(tmp.getId().toString())
          action = 'user'
        } else if (tmp instanceof Hospital) {
          // Ids.push(tmp.getId().toString());
          action = 'hospital'
        } else if (tmp instanceof InvoiceSection) {
          Ids.push(tmp.getCaseId().toString())
          action = 'invoice'
        } else if (tmp instanceof PaymentSection) {
          Ids.push(tmp.getCaseId().toString())
          action = 'payment'
        }
        else if (tmp instanceof Client) {
          // Ids.push(tmp.getId().toString());
          action = 'client'
        }
      }
    }
    if (action == '') return {}
    else {
      return {
        action: action,
        data: Ids,
      }
    }
  }

  //called by DataTable when page changes
  private onPageChange = (page: number) => {
    this.setState({ page }, this.loadMore)
  }

  //called by DataTable when rowsPerPageChanges
  private onRowsPerPageChange = (rowsPerPage: number, page: number) => {
    this.setState({ page, rowsPerPage })
    this.props.onRowsPerPageChange?.(page, rowsPerPage)
  }

  /**
   * the logic in this method determines whether to call load more or not
   * currently i'm checking when user has viewed half or more of the data
   */
  private loadMore = () => {
    const { data, totalDataCount } = this.props

    if (data.length === totalDataCount) return

    const viewedItemCount = this.getCurrentViewedItemCount()

    // console.log('viewedItemCount  this.props.data.length  totalDataCount', viewedItemCount, this.props.data.length, totalDataCount)
    // if (viewedItemCount >= data.length / 2)
    if (viewedItemCount >= this.props.data.length) {
      this.props.load()
    }
  }

  private getCurrentViewedItemCount = () => {
    const { rowsPerPage, page } = this.state
    return rowsPerPage * page
  }

  private resetTable = () => {
    this.setState({ page: 1 })
  }

  render() {
    const { renderer, data, totalDataCount, loading, exportPath, onSearch, onClear,DownloadBtn } = this.props

    return (
      <Segment.Group>
        {this.renderInfo()}

        <Segment>
          <TableActions onDownload={this.downloadCases} resetTable={this.resetTable} onSearch={onSearch} onClear={onClear} DownloadBtn={DownloadBtn}/>
        </Segment>

        <Segment id="app-table">
          <TableFactory
            renderer={renderer}
            data={data.slice((this.state.page - 1) * this.state.rowsPerPage, this.state.rowsPerPage * this.state.page)}
            columns={[]}
            pagination={totalDataCount > this.state.rowsPerPage ? true : true}
            paginationServer={true}
            paginationPerPage={this.state.rowsPerPage}
            paginationTotalRows={totalDataCount}
            paginationDefaultPage={this.state.page}
            paginationRowsPerPageOptions={Table.ROWS_PER_PAGE}
            progressPending={loading}
            onChangePage={this.onPageChange}
            onChangeRowsPerPage={this.onRowsPerPageChange}
            dense={this.props.dense}
            // New properties to block first and last page buttons
            paginationIconLastPage={false}
            paginationIconFirstPage={false}
          />
        </Segment>
      </Segment.Group>
    )
  }

  private renderInfo = () => {
    if (!this.props.debug) return null
    const { data, totalDataCount } = this.props
    const { page, rowsPerPage } = this.state
    return (
      <div>
        <span style={{ marginRight: 16, fontWeight: 700 }}>
          Total Items:
          <span style={{ color: 'blue' }}>{totalDataCount}</span>
        </span>

        <span style={{ marginRight: 16, fontWeight: 700 }}>
          Items Provided:
          <span style={{ color: 'blue' }}>{data.length}</span>
        </span>

        <span style={{ marginRight: 16, fontWeight: 700 }}>
          Items Viewed:
          <span style={{ color: 'blue' }}>{this.getCurrentViewedItemCount()}</span>
        </span>

        <span style={{ marginRight: 16, fontWeight: 700 }}>
          Page Number:
          <span style={{ color: 'blue' }}>{page}</span>
        </span>

        <span style={{ marginRight: 16, fontWeight: 700 }}>
          Rows Per Page:
          <span style={{ color: 'blue' }}>{rowsPerPage}</span>
        </span>

        <span style={{ marginRight: 16, fontWeight: 700 }}>
          Loading:
          <span style={{ color: 'blue' }}>false</span>
        </span>
      </div>
    )
  }

  private removeDefaultHeader = () => {
    const el = document.getElementById('app-table')
    if (!el) return
    const els = el.getElementsByTagName('header')
    const defaultHeader = els.item(0)
    if (!defaultHeader) return
    defaultHeader.remove()
  }
}
