import ProtectedAPIRepository from './ProtectedAPIRepository'
import APIResponseModel from './APIResponseModel'
import Result from './Result'
import VisitPurposeRM from '../responsemodel/VisitPurposeRM'
import VisitPurposeDataMapper from '../datamapper/VisitPurposeDataMapper'
export default class VisitPurposeRepository extends ProtectedAPIRepository {
    private VisitPurposeDataMapper = new VisitPurposeDataMapper()

    getVisitPurpose = async (ro?: GetVisitPurposeRequestObject) => {
        const response = await this.post<APIResponseModel<VisitPurposeRM>>('/visitPurpose', ro)
        if (response instanceof Result.Success) {
            const data: APIResponseModel<VisitPurposeRM> = response.data
            if (data.data !== null) {
                const states = data.data.items.map((it) => this.VisitPurposeDataMapper.map(it))
                return new Result.Success({
                    totalItemCount: data.data.total_item_count,
                    items: states,
                })
            } else {
                return new Result.Error(0, 'Data is null')
            }
        } else {
            return response
        }
    }

    addVisitPurpose = async (ro: AddVisitPurposeRequestObject) => {
        const response = await this.put<APIResponseModel<VisitPurposeRM>>('/visitPurpose', ro)
        if (response instanceof Result.Success) {
            const data: APIResponseModel<VisitPurposeRM> = response.data
            if (data.data !== null) {
                const states = this.VisitPurposeDataMapper.map(data.data.items[0])
                return new Result.Success(states)
            } else {
                return new Result.Error(0, 'Data is null')
            }
        } else {
            return response
        }
    }



    updateVisitPurpose = async (ro: UpdateVisitPurposeRequestObject) => {
        const response = await this.patch<APIResponseModel<VisitPurposeRM>>('/visitPurpose', ro)
        if (response instanceof Result.Success) {
            const data: APIResponseModel<VisitPurposeRM> = response.data
            if (data.data !== null) {
                const state = this.VisitPurposeDataMapper.map(data.data.items[0])
                return new Result.Success(state)
            } else {
                return new Result.Error(0, 'Data is null')
            }
        } else {
            return response
        }
    }

    removeVisitPurpose = async (visitPurposeId: string) => {
        const response = await this.delete<APIResponseModel<null>>(`/visitPurpose/${visitPurposeId}`)
        return response
    }

}

export interface GetVisitPurposeRequestObject {
    search?: string
    name?: string
    // code?: string
    // GSTCode?: string
}

export interface AddVisitPurposeRequestObject {
    purpose: string
}

export interface UpdateVisitPurposeRequestObject {
    id: string
    purpose: string
}