import ActionColumnRenderer from '../../../app/Table/renderer/ActionColumnRenderer'
import DocumentPendency from '../../../../models/DocumentPendency'

export default class DocumentPendencyColumnRenderer extends ActionColumnRenderer<DocumentPendency> {
  getColumns() {
    return [
      {
        name: 'Claim Number',
        selector: 'claimNumber',
      },
      {
        name: 'Client Name',
        selector: 'clientName',
      },
      {
        name: 'Close Date',
        selector: 'closeDate',
      },
      {
        name: 'Investigator Name',
        selector: 'investigatorName',
      },
      {
        name: 'Investigator Emp. Id',
        selector: 'investigatorEmpId',
      },
      {
        name: 'Case Portion',
        selector: 'casePortion',
      },
      {
        name: 'Investigation Location',
        selector: 'investigationLocation',
      },
      {
        name: 'Travel Allowance',
        selector: 'travelAllowance',
      },
      {
        name: 'Approved Charges (Invoice)',
        selector: 'approvedCharges',
      },
      {
        name: 'Approved Receipt',
        selector: 'approvedReceiptsArrived',
        format: (row: {}) => {
          if (row instanceof DocumentPendency) {
            const status = row.getApprovedReceiptStatus()
            if (status) return `Yes`
            return `No`
          }
        },
      },
      {
        name: 'Extra Charges (Investigator)',
        selector: 'extraCharges',
      },
      {
        name: 'Extra Receipt',
        selector: 'extraReceiptsArrived',
        format: (row: {}) => {
          if (row instanceof DocumentPendency) {
            const status = row.getExtraReceiptStatus()
            if (status) return `Yes`
            return `No`
          }
        },
      },
      {
        name: 'Document Charges',
        selector: 'documentCharges',
      },
      {
        name: 'Document Receipt',
        selector: 'documentReceiptsArrived',
        format: (row: {}) => {
          if (row instanceof DocumentPendency) {
            const status = row.getDocumentReceiptStatus()
            if (status) return `Yes`
            return `No`
          }
        },
      },
      {
        name: 'Evidences',
        selector: 'evidenceArrived',
        format: (row: {}) => {
          if (row instanceof DocumentPendency) {
            const status = row.getEvidenceStatus()
            if (status) return `Yes`
            return `No`
          }
        },
      },
      {
        name: 'Actions',
        selector: 'id',
        cell: this.renderActionColumn,
      },
    ]
  }
}
