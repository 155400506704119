import React, { useState, useEffect, useContext } from 'react'
import { Form, Input, Dropdown, Button, DropdownItemProps, Popup } from 'semantic-ui-react'
import FormLabel from '../../../common/FormLabel'
import DateTimePicker from '../../../app/DateTimePicker'
import User from '../../../../models/User'
import UserRepository from '../../../../common/repository/UserRepository'
import constants from '../../../../common/constants'
import Result from '../../../../common/repository/Result'
import { toast } from '../../../common/Toast'
import ClientRepository from '../../../../common/repository/ClientRepository'
import { useHistory } from 'react-router-dom'
import ClientPageContext from './ClientPageContext'


const DetailForm = () => {
    //other hooks
    const history = useHistory()
    const ctx = useContext(ClientPageContext)

    //set state
    const [type, setType] = useState('')
    const [name, setName] = useState('')
    const [handler, setHandler] = useState('')
    const [mouDate, setMouDate] = useState('')
    const [mouFile, setMouFile] = useState<File | null>(null)
    const [loadingHandlers, setLoadingHandlers] = useState(false)
    const [handlers, setHandlers] = useState<User[]>([])
    const [submitting, setSubmitting] = useState(false)


    /** effects */
    useEffect(() => {
        const userRepo = new UserRepository()
        setLoadingHandlers(true);
        (async () => {
            const result = await userRepo.getUsersByRoleTitle({ role: constants.roleTitle.ca })
            if (result instanceof Result.Success) {
                const users = result.data.items
                setHandlers(users)
            } else {
                const message = result.message || 'Could not load handlers'
                toast.error(message)
            }
            setLoadingHandlers(false)
        })()
        
    }, [])


    /** add/update effect */
    useEffect(() => {
        if (!submitting) return

        const clientRepo = new ClientRepository();
        (async () => {
            if (ctx.client !== null) {
                const result = await clientRepo.updateClient({
                    id: ctx.client.getId(),
                    type: type,
                    name: name,
                    handler: handler,
                    mou_expiry: mouDate,
                    mou_document: mouFile || undefined
                })
                if (result instanceof Result.Success) {
                    toast.success('Successfully updated Client')
                    history.push('/master/client')
                } else {
                    const message = result.message || 'Something went wrong'
                    toast.error(message)
                }
            } else {
                const result = await clientRepo.addClient({
                    type: type,
                    name: name,
                    handler: handler,
                    mou_expiry: mouDate,
                    mou_document: mouFile || undefined
                })
                if (result instanceof Result.Success) {
                    toast.success('Successfully added New Client')
                    history.push('/master/client')
                } else {
                    const message = result.message || 'Something went wrong'
                    toast.error(message)
                }
            }
            
            setSubmitting(false)
        })()
    }, [submitting])


    useEffect(() => {
        if (ctx.client === null) return
        setType(ctx.client.getType())
        setName(ctx.client.getName())
        setHandler(ctx.client.getHandler().getId())
        setMouDate(ctx.client.getMOUExpirationDate())
    }, [ctx.client])



    const handlerOptions = handlers.map(it => ({ text: it.getName(), value: it.getId(), key: it.getId() }))

    return (
        <Form loading={ctx.loadingClient || submitting}>
            
            {/* type input */}
            <Form.Group inline>
                <FormLabel width='100px'>Type</FormLabel>
                <Dropdown
                    selection
                    options={TYPE_OPTIONS}
                    value={type}
                    onChange={(_, { value }) => setType(value as string)}
                    />
            </Form.Group>
            {/* type input */}


            {/* name input */}
            <Form.Group inline>
                <FormLabel width='100px'>Name</FormLabel>
                <Input
                    value={name}
                    onChange={(_, { value }) => setName(value)} />
            </Form.Group>
            {/* name input */}


            {/* case handler input */}
            <Form.Group inline>
                <FormLabel width='100px'>Claim Analyst</FormLabel>
                <Dropdown
                    search
                    selection
                    loading={loadingHandlers}
                    options={handlerOptions}
                    value={handler}
                    onChange={(_, { value }) => setHandler(value as string)} />
            </Form.Group>
            {/* case handler input */}


            {/* mou expiry date input */}
            <Form.Group inline>
                <FormLabel width='100px'>MOU Expiration</FormLabel>
                <Input>
                    <DateTimePicker
                        type='date'
                        value={mouDate}
                        onChange={(value) => setMouDate(value)} />
                </Input>
            </Form.Group>
            {/* mou expiry date input */}


            {/* mou document */}
            <Form.Group inline>
                <FormLabel width='100px'>MOU Document</FormLabel>
                <Form.Input 
                    type='file'
                    onChange={(e) => {
                        const files = e.target.files
                        if (files)
                            setMouFile(files[0])
                        else
                            setMouFile(null)
                    }} />
                {ctx.client && ctx.client.getMOUDocument() && (
                    <Form.Field>
                        <Popup
                            inverted
                            position='top center'
                            content={ctx.client.getMOUDocument()!.getFullName()}
                            trigger={
                                <Button
                                    size='mini'
                                    as='a'
                                    href={ctx.client.getMOUDocument()!.getURL()}
                                    target='_blank'>
                                    View MOU Document
                                </Button>
                            } />
                    </Form.Field>
                )}
            </Form.Group>
            {/* mou document */}


            <Button 
                primary
                onClick={() => setSubmitting(true)}
                disabled={
                    submitting ||
                    type.length === 0 ||
                    name.trim().length === 0 ||
                    handler.length === 0 ||
                    mouDate.length === 0
                }
                >
                Submit
            </Button>

        </Form>
    )
}


const TYPE_OPTIONS: DropdownItemProps[] = [
    { text: 'Company', value: 'Company', key: '1' },
    { text: 'TPA', value: 'Tpa', key: '2' },
]

export default DetailForm