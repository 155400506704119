import DomainModel from './DomainModel'

export default class State extends DomainModel {
  constructor(private name: string, private code: string, private GSTCode: string) {
    super()
  }

  getName = () => this.name
  getCode = () => this.code
  getGSTCode = () => this.GSTCode
}
