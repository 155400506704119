import ActionColumnRenderer from '../../../app/Table/renderer/ActionColumnRenderer'
import RepudiationGround from '../../../../models/RepudiationGround'

export default class RepudiationGroundColumnRenderer extends ActionColumnRenderer<RepudiationGround> {
  getColumns() {
    return [
      {
        name: 'Name',
        selector: 'repudiation_ground',
      },
      {
        name: 'Point',
        selector: 'point',
      },
      
      {
        name: 'Actions',
        selector: '_id',
        grow: 0,
        width: '180px',
        cell: this.renderActionColumn,
      },
    ]
  }
}
