import ActionColumnRenderer from '../../../../app/Table/renderer/ActionColumnRenderer'
import TreatingDoctor from '../../../../../models/ReportModels/TreatingDoctor'

export default class TreatingDoctorColumnRenderer extends ActionColumnRenderer<TreatingDoctor> {
    getColumns() {
        return [
            {
                name: 'Name',
                selector: 'name',
            },
            {
                name: 'Mobile',
                selector: 'mobile',
            },
            {
                name: 'Registration Number',
                selector: 'registrationNumber',
            },
            {
                name: 'Actions',
                selector: '_id',
                grow: 0,
                width: '180px',
                cell: this.renderActionColumn,
            },
        ]
    }
}
