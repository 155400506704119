import DomainModel from './DomainModel'
import CaseType from './CaseType'
import CaseCategory from './CaseCategory'
import Client from './Client'
import Location from './Location'
import URLResource from './URLResource'

export default class Case extends DomainModel {
  constructor(
    private id: string,
    private type: CaseType,
    private category: CaseCategory,
    private insuredLocation: Location | null,
    private inwardDateTime: string,
    private claimNumber: string,
    private TPA: Client | null,
    private insuranceCompany: Client | null,
    private insuranceCompanyName: string | null,
    private availableTAT: number,
    private remainingTAT: number,
    private subjectLine: string,
    private state: string,
    private tatTiming: [],
    private reinvestigation: boolean,
    private repudiation_ground: string,
    private pushback: boolean,
    private query_trigger_document: URLResource|null,
  ) {
    super()

  }

  getId = () => this.id
  getType = () => this.type
  getCategory = () => this.category
  getInsuredLocation = () => this.insuredLocation
  getInwardDateTime = () => this.inwardDateTime
  getClaimNumber = () => this.claimNumber
  getTPA = () => this.TPA
  getInsuranceCompany = () => this.insuranceCompany
  getInsuranceCompanyName = () => this.insuranceCompanyName
  getAvailableTAT = () => this.availableTAT
  getRemainingTAT = () => this.remainingTAT
  getSubjectLine = () => this.subjectLine
  getState = () => this.state
  getTatTiming = () => this.tatTiming
  getReinvestigation = () => this.reinvestigation
  getRepudiationGround = () => this.repudiation_ground
  getPushback = () => this.pushback
  getQueryTriggerDocument = () => this.query_trigger_document
}
