import React from "react";
import { Card, Icon, Image, Label } from "semantic-ui-react";
import User from "../../../../models/User";
import LabelContainer from "./LabelContainer";

interface InvestigatorProfileCardProps {
  model: User;
  investigationSpecifications: string[];
  required_doc: any[]
  serviceType: any[]
  showHeader?: boolean;
  onRemove?(model: User): void;
}

const InvestigatorProfileCard = (props: InvestigatorProfileCardProps) => {
  const { model, investigationSpecifications, showHeader, onRemove } = props;

  const primaryLocations = (
    <div>Primary: {User.getDistricts(model.getPrimaryLocations()).join()}</div>
  );
  const secondaryLocation = (
    <div>
      Secondary: {User.getDistricts(model.getSecondaryLocations()).join()}
    </div>
  );
  const tertiaryLocation = (
    <div>
      Tertiary: {User.getDistricts(model.getTertiaryLocations()).join()}
    </div>
  );
  const services = (
    <div>
      Services: {props.serviceType.join()}
    </div>
  )
  const required_doc = (
    <div>
      Required-Documents: {props.required_doc.join()}
    </div>
  )

  const renderHeader = () => {
    return (
      <Card.Header>
        <Icon
          name="x"
          color="red"
          style={{ padding: "4px", margin: "0 16px", cursor: "pointer" }}
          onClick={() => {
            if (onRemove) onRemove(model);
          }}
        />
      </Card.Header>
    );
  };

  const renderLabels = () => {
    const labels = investigationSpecifications.map((it) => <Label>{it}</Label>);
    return (
      <Card.Content extra>
        <LabelContainer>{labels}</LabelContainer>
      </Card.Content>
    );
  };

  return (
    <Card>
      {showHeader && renderHeader()}
      <Card.Content>
        <Image floated="left" src={model.getProfilePicURL()} avatar />
        <Card.Header>{model.getName()}</Card.Header>
        <div style={{ marginTop: 23 }}>
          <Card.Meta>Field Locations</Card.Meta>
          {primaryLocations}
          {secondaryLocation}
          {tertiaryLocation}
          {services}
          {required_doc}
        </div>
      </Card.Content>
      {investigationSpecifications.length > 0 && renderLabels()}
    </Card>
  );
};

export default InvestigatorProfileCard;
