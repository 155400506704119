import ProtectedAPIRepository from './ProtectedAPIRepository'
import APIResponseModel from './APIResponseModel'
import Result from './Result'
import CancelledByInvestigatorListDataMapper from '../datamapper/CancelledByInvestigatorListDataMapper'
import CancelledByInvestigatorListRM from '../responsemodel/CancelledByInvestigatorList'
import AddressChangeRequestRM from '../responsemodel/AddressChangeRequestRM'
import AddressChangeRequestDataMapper from '../datamapper/AddressChangeRequestDataMapper'
import InvestigatorActivityDataMapper from '../datamapper/InvestigatorActivityDataMapper'
import InvestigatorActivityRM from '../responsemodel/InvestigatorActivityRM'
import InvestigatorActivityDetailDataMapper from '../datamapper/InvestigatorActivityDetailDataMapper'
import InvestigatorActivityDetailRM from '../responsemodel/InvestigatorActivityDetailRM'

export default class InvestigatoeActivityRepository extends ProtectedAPIRepository {
    private CancelledByInvestigatorListDataMapper = new CancelledByInvestigatorListDataMapper()
    private AddressChangeRequestDataMapper = new AddressChangeRequestDataMapper()
    private InvestigatorActivityDataMapper = new InvestigatorActivityDataMapper()
    private InvestigatorActivityDetailDataMapper = new InvestigatorActivityDetailDataMapper()

    getInvestigatoeActivity = async (ro?: InvestigatoeActivityObject) => {
        const response = await this.post<APIResponseModel<InvestigatorActivityRM>>('/user/investigatorActivityList', ro)
        if (response instanceof Result.Success) {
            const data: APIResponseModel<InvestigatorActivityRM> = response.data
            if (data.data !== null) {
                const MrdCharges = data.data.items.map((it) => this.InvestigatorActivityDataMapper.map(it))
                return new Result.Success({
                    totalItemCount: data.data.total_item_count,
                    items: MrdCharges,
                })
            } else {
                return new Result.Error(0, 'Data is null')
            }
        } else {
            return response
        }
    }

    getInvestigatoeActivityDetail = async (ro?: GetInvestigatoeActivityDetailObject) => {
        const response = await this.post<APIResponseModel<InvestigatorActivityDetailRM>>('/user/investigatorActivityDetails', ro)
        if (response instanceof Result.Success) {
            const data: APIResponseModel<InvestigatorActivityDetailRM> = response.data
            if (data.data !== null) {
                const cases = data.data.items.map((it) => this.InvestigatorActivityDetailDataMapper.map(it))
                return new Result.Success({
                    items: cases,
                    totalItemCount: data.data.total_item_count,
                })
            } else {
                return new Result.Error(0, 'Data is null')
            }
        } else {
            return response
        }
    }


    getCancelledByInvestigatorList = async (ro?: CancelledByInvestigatorListObject) => {
        const response = await this.post<APIResponseModel<CancelledByInvestigatorListRM>>('/user/cancelledByInvestigatorList', ro)
        if (response instanceof Result.Success) {
            const data: APIResponseModel<CancelledByInvestigatorListRM> = response.data
            if (data.data !== null) {
                const MrdCharges = data.data.items.map((it) => this.CancelledByInvestigatorListDataMapper.map(it))
                return new Result.Success({
                    totalItemCount: data.data.total_item_count,
                    items: MrdCharges,
                })
            } else {
                return new Result.Error(0, 'Data is null')
            }
        } else {
            return response
        }
    }

    getAddressChangeRequestList = async (ro?: AddressChangeRequestListObject) => {
        const response = await this.post<APIResponseModel<AddressChangeRequestRM>>('/user/addressChangeRequestList', ro)
        if (response instanceof Result.Success) {
            const data: APIResponseModel<AddressChangeRequestRM> = response.data
            if (data.data !== null) {
                const AddresChange = data.data.items.map((it) => this.AddressChangeRequestDataMapper.map(it))
                return new Result.Success({
                    totalItemCount: data.data.total_item_count,
                    items: AddresChange,
                })
            } else {
                return new Result.Error(0, 'Data is null')
            }
        } else {
            return response
        }
    }

    UpdateAddresChange = async (ro: UpdateAddresChangeObject) => {
        const response = await this.post<APIResponseModel<AddressChangeRequestRM>>('/user/addressUpdate', ro)
        if (response instanceof Result.Success) {
            const data: APIResponseModel<AddressChangeRequestRM> = response.data
            if (data.data !== null) {
                const AddresChange = this.AddressChangeRequestDataMapper.map(data.data.items[0])
                return new Result.Success(AddresChange)
            } else {
                return new Result.Error(0, 'Data is null')
            }
        } else {
            return response
        }
    }
}



export interface InvestigatoeActivityObject {
    search?: string
    limit?: number
    offset?: number
    case_state?: string,
    startDate?: string, 
    endDate?: string,
    action?:any
}

export interface GetInvestigatoeActivityDetailObject {
    investigator_id?: string
    user?: { id: string; role: string }
    claim_number?: string
    exactMatch?: boolean
    state?: string
    case_category?: string
    client_id?: string
    location?: { state?: string; district?: string }
    hospital_id?: string
    case_type_id?: string
    is_withdrawn?: boolean
    insured_name?: string
    doctor_name?: string
    startDate?: string
    endDate?: string
    search?: string
    limit?: number
    offset?: number
    get_by_closed_date?: boolean
    closed_cases_active?: boolean
    action?:any,
}

export interface CancelledByInvestigatorListObject {
    search?: string
    limit?: number
    offset?: number
    case_state?: string
}


export interface AddressChangeRequestListObject {
    search?: string
    limit?: number
    offset?: number
    isAddressChange?: string
}
export interface UpdateAddresChangeObject {
    id: string
    isAddressChange: string
}