import React, { useState, useContext } from "react";
import { Grid, Form, Header, Icon, Button, Segment, Radio, } from "semantic-ui-react";
import HomePageContext from "./HomePageContext";
import DateTimePicker from "../../app/DateTimePicker";

function Filters() {
  const ctx = useContext(HomePageContext);
  const [isExpanded, setIsExpanded] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [claimNumber, setClaimNumber] = useState("");
  const [insuredName, setInsuredName] = useState("");
  const [doctorName, setDoctorName] = useState("");

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Header as="h4">Filter</Header>
        <Icon
          name="arrow alternate circle down outline"
          size="large"
          onClick={() => setIsExpanded(!isExpanded)}
        />
      </div>
      {isExpanded && (
        <Grid columns={5}>
          <Grid.Row>
            <Grid.Column>
              <Form.Dropdown
                label="User Role"
                search
                selection
                placeholder="Select User Role"
                options={ctx.options.userRoles}
                value={ctx.filters.userRole}
                onChange={(_, { value }) =>
                  ctx.filters.setUserRole(value as string)
                }
              />
            </Grid.Column>
            <Grid.Column>
              <Form.Dropdown
                label="User"
                search
                selection
                placeholder="Select User"
                options={ctx.options.user}
                value={ctx.filters.user}
                onChange={(_, { value }) =>
                  ctx.filters.setUser(value as string)
                }
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Form.Dropdown
                label="Case Category"
                search
                selection
                placeholder="Select Case Category"
                options={ctx.options.category}
                value={ctx.filters.category}
                onChange={(_, { value }) =>
                  ctx.filters.setCategory(value as string)
                }
              />
            </Grid.Column>
            <Grid.Column>
              <Form.Dropdown
                label="Case Type"
                search
                selection
                placeholder="Select Case Type"
                options={ctx.options.caseType}
                value={ctx.filters.caseType}
                onChange={(_, { value }) =>
                  ctx.filters.setCaseType(value as string)
                }
              />
            </Grid.Column>
            <Grid.Column>
              <Form.Dropdown
                label="Client"
                search
                selection
                placeholder="Select Client"
                options={ctx.options.client}
                value={ctx.filters.client}
                onChange={(_, { value }) =>
                  ctx.filters.setClient(value as string)
                }
              />
            </Grid.Column>
            <Grid.Column>
              <Form.Dropdown
                label="State"
                search
                selection
                placeholder="Select State"
                options={ctx.options.states}
                value={ctx.filters.states}
                onChange={(_, { value }) => {
                  ctx.filters.setStates(value as string)
                  clearDistrict()
                }
                }
              />
            </Grid.Column>
            <Grid.Column>
              <Form.Dropdown
                label="District"
                search
                selection
                placeholder="Select District"
                options={ctx.options.districts}
                value={ctx.filters.district}
                onChange={(_, { value }) =>
                  ctx.filters.setDistrict(value as string)
                }
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Form.Input
                label="Claim Number"
                placeholder="Enter Claim Number"
                value={claimNumber}
                onChange={(_, { value }) => setClaimNumber(value)}
                onKeyDown={(e: any) => {
                  if (e.keyCode == 13) ctx.filters.setClaimNumber(claimNumber);
                }}
              />
            </Grid.Column>
            <Grid.Column>
              <Form.Dropdown
                search
                selection
                label="Hospital"
                placeholder="Select Hospital"
                options={ctx.options.hospital}
                value={ctx.filters.hospital}
                onChange={(_, { value }) => ctx.filters.setHospital(value as string)}
                onKeyPress={(e: any) => ctx.filters.onChange(e as any)}
              />
            </Grid.Column>
            <Grid.Column>
              <Form.Input
                label="Insured Name"
                placeholder="Enter Insured Name"
                value={insuredName}
                onChange={(_, { value }) => setInsuredName(value)}
                onKeyDown={(e: any) => {
                  if (e.keyCode == 13) ctx.filters.setInsuredName(insuredName);
                }}
              />
            </Grid.Column>
            <Grid.Column>
              <Form.Input
                label="Doctor Name"
                placeholder="Enter Doctor Name"
                value={doctorName}
                onChange={(_, { value }) => setDoctorName(value)}
                onKeyDown={(e: any) => {
                  if (e.keyCode == 13) ctx.filters.setDoctorName(doctorName);
                }}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Segment compact>
                <Radio
                  toggle
                  label={"By Closed Date"}
                  checked={ctx.filters.getByClosedDate}
                  onChange={() => {
                    ctx.filters.setGetByClosedDate(
                      !ctx.filters.getByClosedDate
                    );
                  }}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column>
              <Form.Input label="From Date">
                <DateTimePicker
                  placeholder="Select From Date"
                  type="date"
                  value={ctx.filters.from}
                  onChange={ctx.filters.setFrom}
                />
              </Form.Input>
            </Grid.Column>
            <Grid.Column>
              <Form.Input label="To Date">
                <DateTimePicker
                  placeholder="Select To Date"
                  type="date"
                  value={ctx.filters.to}
                  onChange={ctx.filters.setTo}
                />
              </Form.Input>
            </Grid.Column>
            <Grid.Column>
              <Form.Input
                label="Search"
                placeholder="Enter Text..."
                value={searchText}
                onChange={(_, { value }) => setSearchText(value)}
                onKeyDown={(e: any) => {
                  if (e.keyCode == 13) ctx.filters.setSearch(searchText);
                }}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Segment compact>
                <Radio
                  toggle
                  label={"Reinvestigation"}
                  checked={ctx.filters.getReinvestigation}
                  onChange={() => {
                    ctx.filters.setGetReinvestigation(
                      !ctx.filters.getReinvestigation
                    );
                  }}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column>
              <Segment compact>
                <Radio
                  toggle
                  label={"Pushback"}
                  checked={ctx.filters.getPushback}
                  onChange={() => {
                    ctx.filters.setPushback(
                      !ctx.filters.getPushback
                    );
                  }}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <Button onClick={clearAllFilters}>Clear</Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}
    </>
  );

  function clearAllFilters() {

    ctx.filters.setUserRole("");
    ctx.filters.setUser("");
    ctx.filters.setCategory("");
    ctx.filters.setCaseType("");
    ctx.filters.setClient("");
    ctx.filters.setStates("");
    ctx.filters.setDistrict("");
    ctx.filters.setDistrictOptions([...ctx.filters.AllDistrictS])
    setClaimNumber("");
    ctx.filters.setClaimNumber("");
    ctx.filters.setHospital("");
    setInsuredName("");
    ctx.filters.setInsuredName("");
    setDoctorName("");
    ctx.filters.setDoctorName("");
    ctx.filters.setFrom("");
    ctx.filters.setTo("");
    setSearchText("");
    ctx.filters.setSearch("");
    ctx.filters.setGetByClosedDate(false);
    ctx.filters.setGetReinvestigation(false);
    ctx.filters.setPushback(false);

  }

  function clearDistrict() {
    ctx.filters.setDistrict("");
  }
}

export default Filters;
