import React from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 42px;
  display: flex;
  flex-direction: column;
  margin: 0 2px;
  border-radius: 4px;
  border: 1px solid #bbbbbb;
`;
const Label = styled.div`
  background-color: #cccccc;
  text-align: center;
  border-radius: 4px 4px 0 0;
`;
const StyledInput = styled.input`
  height: 30px;
  border: none;
  border-radius: 0 0 4px 4px;
`;

function Input({ index, labelSuffix, value, onChange }: Props) {
  return (
    <Container>
      <Label>
        {index} {labelSuffix}
      </Label>
      <StyledInput
        value={value}
        onChange={(e) => onChange && onChange(index, e.target.value)}
      />
    </Container>
  );
}

interface Props {
  index: number;
  labelSuffix?: string;
  value?: string;
  onChange?(index: number, value: string): void;
}

export default Input;
