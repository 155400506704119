
import DataMapper from '../DataMapper'
import HospitalInfraInvestigatorListDetails from '../../../models/ReportModels/HospitalInfraInvestigatorListDetails'
import HospitalInfraInvestigatorDetailRM from '../../responsemodel/ReportRM/HospitalInfraInvestigatorDetailRM'

export default class HospitalInfraInvestigatorDetailDataMapper extends DataMapper<HospitalInfraInvestigatorDetailRM, HospitalInfraInvestigatorListDetails> {
    map = (rm: HospitalInfraInvestigatorDetailRM) => new HospitalInfraInvestigatorListDetails(
        rm._id,
        rm.bed_icu,
        rm.case_id,
        rm.changeNamePastMonth,
        rm.contact_person,
        rm.createdAt,
        rm.dateOfChange,
        rm.designation,
        rm.full_time_doctor,
        rm.hospital,
        rm.hospitalType,
        rm.hospital_id,
        rm.hospital_images,
        rm.hospital_owner,
        rm.hospital_reg_certificate,
        rm.hospital_reg_number,
        rm.icu_facility,
        rm.inHouse_lab,
        rm.inHouse_pharmacy,
        rm.investigator_id,
        rm.isFieldSaved,
        rm.name,
        rm.number_of_doctor,
        rm.old_name,
        rm.ot_facility,
        rm.patients_in_icu,
        rm.patients_in_opd,
        rm.phone,
        rm.updatedAt,
        rm.visible_staff,
        rm.feedback,
        rm.feedback_id,
        rm.case_investigated_six,
        rm.repudiation_fraud,
        rm.repudiation_other,
        rm.repudiation_total,
    )
}
