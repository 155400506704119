import React from 'react'
import Page from '../../../../layout/Page'
import { Button, Segment } from 'semantic-ui-react'
import Result from '../../../../../common/repository/Result'
import { toast } from '../../../../common/Toast'
import Table from '../../../../app/Table'
import RequirePermission from '../../../../base/RequirePermission'
import permissions from '../../../../../common/permissions'
import { Link } from 'react-router-dom'
import ReportRepository, { GetAttendentInvestigatorRequestObject } from '../../../../../common/repository/ReportRepository'
import AttendentsColumnRenderer from "./AttendentsColumnRenderer"
import Attendents from "../../../../../models/ReportModels/Attendents"
import FilterPageContext from "./FilterPageContext"
import Filters from "./Filters"
interface State {
    loading: boolean
    filter: string
    limit: number
    offset: number
    totalHospitalCount: number
    attendentList: Attendents[]
    fromFilter: string
}

class AttendentsPage extends React.Component<{}, State> {
    private ReportRepository = new ReportRepository()
    private columnRenderer = new AttendentsColumnRenderer(
        (model: Attendents) => {
            let name = model.getName()
            let dob = model.getDOB()
            console.log(name)
            return (
                <Button
                    style={{ width: 100 }}
                    primary
                    as={Link}
                    to={{
                        pathname: `/master/reports/attendant-details/${name}/${dob}`,
                    }}
                >
                    View
                </Button>
            )
        },
        (obj: any) => obj.name !== undefined
    )
    constructor(props: {}) {
        super(props)
        this.state = {
            loading: false,
            filter: '',
            limit: 10,
            offset: 0,
            totalHospitalCount: 0,
            attendentList: [],
            fromFilter: ""
        }
    }
    componentDidMount() {
        this.load()
    }

    private onRowsPerPageChange = (page: number, rowsPerPage: number) => {
        const offset = (page - 1) * rowsPerPage,
            limit = offset + rowsPerPage
        this.setState({ offset, limit }, this.load)
    }
    private load = async () => {
        if (this.state.fromFilter) {
            await this.setState({ offset: 0 })
        }
        const { offset } = this.state
        this.setState({ loading: true }, async () => {
            const result = await this.getAttendentList()
            if (result instanceof Result.Success) {
                if (offset === 0) {
                    const attendentList = result.data.items
                    const totalHospitalCount = result.data.totalItemCount
                    const offset = attendentList.length
                    this.setState({ loading: false, offset, attendentList, totalHospitalCount })
                } else {
                    const attendentList = [...this.state.attendentList, ...result.data.items]
                    const offset = attendentList.length
                    this.setState({ loading: false, offset, attendentList })
                }
            } else {
                this.setState({ loading: false }, () => {
                    const message = result.message || 'Something went wrong'
                    toast.error(message)
                })
            }
        })
    }

    private getAttendentList = async () => {
        const ro: GetAttendentInvestigatorRequestObject = {
            search: this.state.filter,
            limit: this.state.limit,
            offset: this.state.offset,
        }
        if (this.state.fromFilter) ro.date = this.state.fromFilter
        return await this.ReportRepository.getAttendentList(ro)
    }

    private setFilter = (filter: string) => {
        this.setState({ filter, offset: 0 }, this.load)
    }
    private clearAllFilters = async () => {
        await this.setState({
            limit: 0,
            offset: 0,
            fromFilter: '',
        })
        await this.load()
    }

    private setFromFilter = (val: string) => this.setState({ fromFilter: val })

    render() {
        return (
            <FilterPageContext.Provider
                value={{
                    filters: {
                        from: this.state.fromFilter,
                        setFrom: this.setFromFilter,
                        applyFilters: this.load,
                        clearAllFilters: this.clearAllFilters,
                    },
                }}
            >
                <Page title="Attendents" >
                    <Segment>
                        <Filters />
                    </Segment>

                    <Table
                        renderer={this.columnRenderer}
                        data={this.state.attendentList}
                        totalDataCount={this.state.totalHospitalCount}
                        loading={this.state.loading}
                        load={this.load}
                        onRowsPerPageChange={this.onRowsPerPageChange}
                        onSearch={this.setFilter}
                        onClear={() => this.setFilter('')}
                    />
                </Page>
            </FilterPageContext.Provider>
        )
    }
}
export default (props: any) => {
    return (
        <RequirePermission permission={permissions.Master.CRUD}>
            <AttendentsPage {...props} />
        </RequirePermission>
    )
}
