// import React, {useEffect, useState} from 'react'
// import moment from 'moment'
// import { DatetimePickerTrigger } from 'rc-datetime-picker'
// import 'rc-datetime-picker/dist/picker.css'
// import { Input } from 'semantic-ui-react'
//
// const DateTimePickerGateway = (props) => {
//
//     const FORMATS = [
//             {
//                 type: 'date',
//                 representationFormat: 'YYYY/MM/DD',
//                 viewFormat: 'll'
//             },
//             {
//                 type: 'time',
//                 representationFormat: 'HH:mm',
//                 viewFormat: 'LT'
//             },
//             {
//                 type: 'datetime',
//                 representationFormat: 'YYYY/MM/DD HH:mm',
//                 viewFormat: 'lll'
//             }
//         ];
//
//     const getMomentFromProps = () => {
//         const { value, type } = props;
//         if (value && type === 'time')
//             return moment(value, 'HH:mm')
//         else if (value)
//             return moment(value)
//         else
//             return moment()
//     }
//
//     const deriveStateFromProps = () => {
//         let initObj
//         if (props.type === 'date')
//             initObj = FORMATS[0];
//         else if (props.type === 'time')
//             initObj = FORMATS[1];
//         else
//             initObj = FORMATS[2];
//
//         initObj.moment = getMomentFromProps();
//         return initObj
//     }
//
//     const [type, setType] = useState('');
//     const [representationFormat,setRepresentationFormat] = useState('');
//     const [momentType, setMomentType] = useState('');
//     const [viewFormat, setViewFormat] = useState('');
//     const [value, setValue] = useState(props.value);
//
//     const stateFromProps = deriveStateFromProps();
//     useEffect(()=>{
//
//         setType(stateFromProps.type);
//         setRepresentationFormat(stateFromProps.representationFormat);
//         setViewFormat(stateFromProps.viewFormat);
//         setMomentType(stateFromProps.moment);
//         // if (value) setValue(moment.format(viewFormat));
//     },[value])
//
//
//
//     const handleChange = (moment) => {
//         setMomentType(moment);
//         props.onChange(moment.format(representationFormat));
//     }
//
//     const placeholder = props.placeholder || 'Pick Date/Time';
//    // if (value) setValue(moment.format(viewFormat))
//
//
//     return (
//         <DatetimePickerTrigger
//                 showCalendarPicker={type === 'datetime' ? true : type === 'date'}
//                 showTimePicker={type === 'datetime' ? true : type === 'time'}
//                 moment={momentType}
//                 onChange={handleChange}
//                 maxDate = {moment(new Date(Date.now()))}
//                 closeOnSelectDay>
//                     <Input
//                         placeholder={placeholder}
//                         value={value}
//                         />
//         </DatetimePickerTrigger>
//     )
// }
//
// export default  DateTimePickerGateway;
//
//

//////////////////////////////////////////////////

import React from 'react'
import moment from 'moment'
import { DatetimePickerTrigger } from 'rc-datetime-picker'
import 'rc-datetime-picker/dist/picker.css'
import { Input } from 'semantic-ui-react'

/**
 * This is Backend Gateway for DateTimePicker
 */

class DateTimePickerGateway extends React.Component {
  static FORMATS = [
    {
      type: 'date',
      representationFormat: 'YYYY/MM/DD',
      viewFormat: 'll',
    },
    {
      type: 'time',
      representationFormat: 'HH:mm',
      viewFormat: 'LT',
    },
    {
      type: 'datetime',
      representationFormat: 'YYYY/MM/DD HH:mm',
      viewFormat: 'lll',
    },
  ]

  constructor(props) {
    super(props)
    const stateFromProps = this.deriveStateFromProps()
    this.state = {
      ...stateFromProps,
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.value !== this.props.value) {
      const stateFromProps = this.deriveStateFromProps()
      this.setState({ ...stateFromProps })
    }
  }

  deriveStateFromProps = () => {
    let initObj
    if (this.props.type === 'date') initObj = DateTimePickerGateway.FORMATS[0]
    else if (this.props.type === 'time') initObj = DateTimePickerGateway.FORMATS[1]
    else initObj = DateTimePickerGateway.FORMATS[2]

    initObj.moment = this.getMomentFromProps()
    return initObj
  }

  getMomentFromProps = () => {
    const { value, type } = this.props
    if (value && type === 'time') return moment(value, 'HH:mm')
    else if (value) return moment(value)
    else return moment()
  }

  handleChange = (moment) => {
    this.setState({ moment }, () => this.props.onChange(this.state.moment.format(this.state.representationFormat)))
  }

  render() {
    const placeholder = this.props.placeholder || 'Pick Date/Time'
    let value = this.props.value
    if (value) value = this.state.moment.format(this.state.viewFormat)
    return (
      <DatetimePickerTrigger
        showCalendarPicker={this.state.type === 'datetime' ? true : this.state.type === 'date'}
        showTimePicker={this.state.type === 'datetime' ? true : this.state.type === 'time'}
        moment={this.state.moment}
        onChange={this.handleChange}
        maxDate={this.props.maxDate ? moment(new Date(this.props.maxDate)) : moment(new Date(Date.now()))}
        closeOnSelectDay
      >
        <Input placeholder={placeholder} value={value} />
      </DatetimePickerTrigger>
    )
  }
}

export default DateTimePickerGateway
