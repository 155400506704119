import React, { useState, useReducer, useContext, useEffect } from 'react'
import { Form, Input, Button, Popup, Icon, Header } from 'semantic-ui-react'
import FormLabel from '../../../common/FormLabel'
import validator from '../../../../common/validator'
import UserFormPageContext from './UserFormPageContext'
import constants from '../../../../common/constants'
import Result from '../../../../common/repository/Result'
import { toast } from '../../../common/Toast'

function UserSalaryForm() {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [state, dispatch] = useReducer(reducer, initialState)
  const ctx = useContext(UserFormPageContext)

  useEffect(effectSetEssentialData, [ctx.userSalaryAmount, ctx.settings, ctx.specialAllowance])
  useEffect(() => {
    effectOnSubmit()
  }, [isSubmitting])

  return (
    <Form loading={isSubmitting}>
      <Form.Group inline>
        <Form.Field>
          <FormLabel width="130px">Basic Salary</FormLabel>
          <Input
            label={{ basic: true, content: 'Rs.' }}
            labelPosition="right"
            loading={ctx.isLoading}
            value={state.basicSalary.toString()}
            onChange={(_, { value }) => {
              if (validator.number.isValid(value))
                dispatch({
                  type: 'UPDATE_BASIC_SALARY',
                  value: parseFloat(value),
                })
            }}
          />
        </Form.Field>
      </Form.Group>
      <Form.Group inline>
        <Form.Field>
          <FormLabel width="130px">Special Allowance</FormLabel>
          <Input
            label={{ basic: true, content: 'Rs.' }}
            labelPosition="right"
            // loading={ctx.isLoading}
            value={state.specialAllowance.toString()}
            onChange={(_, { value }) => {
              if (validator.number.isValid(value))
                dispatch({
                  type: 'UPDATE_SPECIAL_ALLOWANCE',
                  value: parseFloat(value),
                })
            }}
          />
        </Form.Field>
      </Form.Group>
      {/*<Form.Group inline>*/}
      {/*    <Form.Field>*/}
      {/*        <FormLabel width='130px'>HRA</FormLabel>*/}
      {/*        <Input*/}
      {/*            label={{ basic: true, content: 'Rs.' }}*/}
      {/*            labelPosition='right'*/}
      {/*            value={state.hra.toString()} />*/}
      {/*    </Form.Field>*/}
      {/*    <Form.Field>*/}
      {/*        <Popup*/}
      {/*            position='top center'*/}
      {/*            trigger={<Icon name='info circle' />}>*/}
      {/*            <Header as='h5'>HRA</Header>*/}
      {/*            This value is automatically calculated from Basic Salary based on the percentage*/}
      {/*            value in the Settings/Salary section.<br />*/}
      {/*            <strong>Current Value: {state.hraPercentage}%</strong>*/}
      {/*        </Popup>*/}
      {/*    </Form.Field>*/}
      {/*</Form.Group>*/}
      {/*<Form.Group inline>*/}
      {/*    <Form.Field>*/}
      {/*        <FormLabel width='130px'>M & I Allowance</FormLabel>*/}
      {/*        <Input*/}
      {/*            label={{ basic: true, content: 'Rs.' }}*/}
      {/*            labelPosition='right'*/}
      {/*            value={state.miAllowance.toString()} />*/}
      {/*    </Form.Field>*/}
      {/*    <Form.Field>*/}
      {/*        <Popup*/}
      {/*            position='top center'*/}
      {/*            trigger={<Icon name='info circle' />}>*/}
      {/*            <Header as='h5'>Medical & Insurance Allowance</Header>*/}
      {/*            This value is automatically calculated from Basic Salary based on the percentage*/}
      {/*            value in the Settings/Salary section.<br />*/}
      {/*            <strong>Current Value: {state.miAllowancePercentage}%</strong>*/}
      {/*        </Popup>*/}
      {/*    </Form.Field>*/}
      {/*</Form.Group>*/}
      {/*{ctx.user!.getRole().getTitle() === constants.roleTitle.investigator && (*/}
      {/*    <Form.Group inline>*/}
      {/*        <Form.Field>*/}
      {/*            <FormLabel width='130px'>Conveyence Allowance</FormLabel>*/}
      {/*            <Input*/}
      {/*                label={{ basic: true, content: 'Rs.' }}*/}
      {/*                labelPosition='right'*/}
      {/*                value={state.conveyenceAllowance.toString()} />*/}
      {/*        </Form.Field>*/}
      {/*        <Form.Field>*/}
      {/*        <Popup*/}
      {/*            position='top center'*/}
      {/*            trigger={<Icon name='info circle' />}>*/}
      {/*            <Header as='h5'>Conveyence Allowance</Header>*/}
      {/*            This value is set in the Settings/Salary section.<br />*/}
      {/*            <strong>Current Value: {state.conveyenceAllowance} Rs.</strong>*/}
      {/*        </Popup>*/}
      {/*    </Form.Field>*/}
      {/*    </Form.Group>*/}
      {/*)}*/}
      {/*<Form.Group inline>*/}
      {/*    <Form.Field>*/}
      {/*        <FormLabel width='130px'>Total</FormLabel>*/}
      {/*        <Input*/}
      {/*            label={{ basic: true, content: 'Rs.' }}*/}
      {/*            labelPosition='right'*/}
      {/*            value={state.total.toString()} />*/}
      {/*    </Form.Field>*/}
      {/*</Form.Group>*/}

      <Button primary disabled={isSubmitting} onClick={() => setIsSubmitting(true)}>
        Submit
      </Button>
    </Form>
  )

  function effectSetEssentialData() {
    if (ctx.settings !== null) {
      dispatch({
        type: 'UPDATE_HRA_PERCENTAGE',
        value: ctx.settings.getSalary().hraPercentage || 0,
      })
      dispatch({
        type: 'UPDATE_MI_ALLOWANCE_PERCENTAGE',
        value: ctx.settings.getSalary().miAllowancePercentage || 0,
      })
    }
    if (ctx.user!.getRole().getTitle() === constants.roleTitle.investigator && ctx.settings !== null) {
      dispatch({
        type: 'UPDATE_CONVEYENCE_ALLOWANCE',
        value: ctx.settings.getSalary().investigator.conveyanceAllowance || 0,
      })
    }
    dispatch({ type: 'UPDATE_BASIC_SALARY', value: ctx.userSalaryAmount })
    dispatch({ type: 'UPDATE_SPECIAL_ALLOWANCE', value: ctx.specialAllowance })
  }

  async function effectOnSubmit() {
    if (!isSubmitting) return
    const result = await ctx.userRepo.updateSalary({
      user: ctx.user!.getId(),
      basic_salary: state.basicSalary,
      special_allowance: state.specialAllowance,
    })
    if (result instanceof Result.Success) {
      ctx.setUserSalaryAmount(result.data)
      toast.success('Salary updated Successfully')
    } else {
      const message = result.message || 'Something went wrong'
      toast.error(message)
    }
    setIsSubmitting(false)
  }
}

const initialState: State = {
  specialAllowance: 0,
  hraPercentage: 0,
  miAllowancePercentage: 0,
  basicSalary: 0,
  hra: 0,
  miAllowance: 0,
  conveyenceAllowance: 0,
  total: 0,
}

function reducer(state: State, { type, value }: { type: string; value: number }): State {
  if (isNaN(value)) return { ...state, basicSalary: 0, hra: 0, miAllowance: 0, total: 0 }
  if (type === 'UPDATE_BASIC_SALARY') {
    const basicSalary = value
    const hra = calculateHRA(value, state.hraPercentage)
    const miAllowance = calculateMIAllowance(value, state.miAllowancePercentage)
    const total = basicSalary + hra + miAllowance + state.conveyenceAllowance
    return { ...state, basicSalary, hra, miAllowance, total }
  } else if (type === 'UPDATE_HRA_PERCENTAGE') {
    return { ...state, hraPercentage: value }
  } else if (type === 'UPDATE_MI_ALLOWANCE_PERCENTAGE') {
    return { ...state, miAllowancePercentage: value }
  } else if (type === 'UPDATE_CONVEYENCE_ALLOWANCE') {
    return { ...state, conveyenceAllowance: value }
  } else if (type == 'UPDATE_SPECIAL_ALLOWANCE') {
    return { ...state, specialAllowance: value }
  }
  return state
}

function calculateHRA(amount: number, HRAPercentage: number): number {
  return (HRAPercentage / 100) * amount
}

function calculateMIAllowance(amount: number, MIAllowancePercentage: number): number {
  return (MIAllowancePercentage / 100) * amount
}

interface State {
  specialAllowance: number
  hraPercentage: number
  miAllowancePercentage: number
  basicSalary: number
  hra: number
  miAllowance: number
  conveyenceAllowance: number
  total: number
}

export default UserSalaryForm
