import React, { useState, useEffect } from 'react'
import { Container, Table, TableHeader, TableRow, TableHeaderCell, TableBody, TableCell, Grid,Loader } from 'semantic-ui-react';
import UserRepository from '../../../../common/repository/UserRepository'
import { toast } from '../../../common/Toast'
import Result from '../../../../common/repository/Result'
import User from '../../../../models/User'
import UserHistory from '../../../../models/UserHistory'
import moment from 'moment'
import { useParams, useHistory } from 'react-router-dom'
const UserUpdateHistory = () => {
    const params = useParams<{ userId: string }>()
    const history = useHistory()
    const userRepo = new UserRepository()
    const [userHistoryData, setUserHistoryData] = useState<any>(null)
    const [user, setUser] = useState<User | null>(null)
    const [userHistory, setUserHistory] = useState<UserHistory | any>(null)
    const [isLoading, setIsLoading] = useState(false)

    if (params.userId !== undefined && !isLoading && user === null) setIsLoading(true)
    useEffect(() => {
        getUserHistoryData()
    }, [isLoading])

    console.log(userHistoryData, "userHistory")
    async function getUserHistoryData() {
        // if (userHistoryData.length === 0) return
        setIsLoading(true)
        const result = await userRepo.getUsersHistory(params.userId)
        if (result instanceof Result.Success) {
            // console.log(result, "result")
            // setUserHistoryData(result?.data)
            setUserHistoryData(result?.data?.items?.map((it) => ({
                id: it?.getId(),
                Action: it?.getAction(),
                ActionBy: it?.getActionBy(),
                Date: it?.getDate(),
                PreviousAddress: it?.getPreviousAddress(),
                PreviousCoordinates: it?.getPreviousCoordinates(),
                RequestAddress: it?.getRequestAddress(),
                RequestCoordinates: it?.getRequestCoordinates(),
                UpdatedAddress: it?.getUpdatedAddress(),
                UpdatedCoordinates: it?.getUpdatedCoordinates(),
            }))
            )
        } else {
            const message = result.message || 'Could not load Users'
            toast.error(message)
        }
        setIsLoading(false)
    }

    return (
        <Grid >
             
            <Grid.Row>
                <Grid.Column width={16}>
                    <Container style={{ marginTop: '2em' }}>
                    {isLoading &&<Loader />}
                        <Table singleLine>
                            <TableHeader>
                                <TableRow>
                                    <TableHeaderCell>Action</TableHeaderCell>
                                    <TableHeaderCell>Action By</TableHeaderCell>
                                    <TableHeaderCell>Date</TableHeaderCell>
                                    <TableHeaderCell>Previous Address</TableHeaderCell>
                                    <TableHeaderCell>Previous Coordinates(let/lng)</TableHeaderCell>
                                    <TableHeaderCell>Request Address</TableHeaderCell>
                                    <TableHeaderCell>Request Coordinates</TableHeaderCell>
                                    <TableHeaderCell>Updated Address</TableHeaderCell>
                                    <TableHeaderCell>Updated Coordinates(let/lng)</TableHeaderCell>

                                </TableRow>
                            </TableHeader>

                            <TableBody>
                                {userHistoryData?.map((item: any) =>
                                    <TableRow>
                                        <TableCell>{item?.Action || "--"}</TableCell>
                                        <TableCell>{item?.ActionBy || "--"}</TableCell>
                                        <TableCell>{moment(item?.Date).format("lll") || "--"}</TableCell>
                                        <TableCell>{item?.PreviousAddress || "--"}</TableCell>
                                        <TableCell>{item?.PreviousCoordinates?.toString()}</TableCell>
                                        <TableCell>{item?.RequestAddress || "--"}</TableCell>
                                        <TableCell>{item?.RequestCoordinates?.toString() || "--"}</TableCell>
                                        <TableCell>{item?.UpdatedAddress || "--"}</TableCell>
                                        <TableCell>{item?.UpdatedCoordinates?.toString() || "--"}</TableCell>
                                    </TableRow>)}
                                {!userHistoryData?.length && <TableRow><TableCell colSpan={9} textAlign='center'>No record found</TableCell></TableRow>}
                            </TableBody>
                        </Table>
                    </Container>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}

export default UserUpdateHistory