import React from 'react'
import { DropdownItemProps } from 'semantic-ui-react'

interface ContextType {
  options: Options
  filters: Filters
}

interface Options {
  client: DropdownItemProps[]
  caseType: DropdownItemProps[]
}

interface Filters {
  client: string
  setClient(client: string): void
  caseType: string
  setCaseType(caseType: string): void
  claimNumber: string
  setClaimNumber(claimNumber: string): void
  invoiceNumber: string
  setInvoiceNumber(invoiceNumber: string): void
  from: string
  setFrom(from: string): void
  to: string
  setTo(to: string): void
  fromPaymentDate: string
  setFromPaymentDate(from: string): void
  toPaymentDate: string
  setToPaymentDate(to: string): void
  applyFilters(): void
  clearAllFilters(): void

  searchByDocPending: boolean
  setSearchByDocPending(searchByDocPending: boolean): void
  searchByPaymentPending: boolean
  setSearchByPaymentPending(searchByPaymentPending: boolean): void
  searchByUploadPending: boolean
  setSearchByUploadPending(searchByUploadPending: boolean): void
  searchByPaymentStatus: string
  setSearchByPaymentStatus(status: string): void

  Reinvestigation: boolean
  setReinvestigation(Reinvestigation: boolean): void
  Pushback: boolean
  setPushback(Pushback: boolean): void
}

const Context = React.createContext({} as ContextType)
export default Context
