import ColumnRenderer from './ColumnRenderer'

export default abstract class ActionColumnRenderer<T> extends ColumnRenderer {
  constructor(private action: (model: T) => React.ReactNode, private typeCheck: (obj: any) => boolean) {
    super()
  }

  /**
   * type guard to check and convert the row
   * to T
   * actual implementation will be provided by the
   * consumer
   */
  private isT = (row: any): row is T => {
    return this.typeCheck(row)
  }

  protected renderActionColumn = (row: {}) => {
    if (this.isT(row)) return this.action(row)
    else return null
  }
}
