import React, { useState } from 'react'
import Page from '../../../layout/Page'
import { Icon, Button, Modal, Grid, Dropdown } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import User from '../../../../models/User'
import Table from '../../../app/Table'
import UserColumnRenderer from './UserColumnRenderer'
import UserRepository from '../../../../common/repository/UserRepository'
import Result from '../../../../common/repository/Result'
import { toast } from '../../../common/Toast'
import RemovalConfirmationForm from '../../../common/RemovalConfirmationForm'
import RequirePermission from '../../../base/RequirePermission'
import permissions from '../../../../common/permissions'
import LocalStorage from '../../../../lib/LocalStorage'
import axios from 'axios'

interface State {
  loading: boolean
  filter: string
  limit: number
  offset: number

  users: User[]
  totalUserCount: number

  remove_modalOpen: boolean
  remove_model: User | null

  year: string
  month: string

  downloadedData: string
}

class UsersSalaryPage extends React.Component<{}, State> {
  private userRepo = new UserRepository()
  private columnRenderer = new UserColumnRenderer(
    (model: User) => {
      return (
        <Button.Group size="mini">
          <Button as={Link} to={`/salary/${model.getId()}/${this.state.month}/${this.state.year}`} primary>
            Salary
          </Button>
        </Button.Group>
      )
    },
    (obj: any) => obj.locations !== undefined
  )

  constructor(props: {}) {
    super(props)

    let today = new Date()
    let m = today.getMonth()
    this.state = {
      loading: false,
      filter: '',
      limit: 10,
      offset: 0,

      users: [],
      totalUserCount: 0,

      remove_modalOpen: false,
      remove_model: null,

      month: m < 10 ? `0${m}` : m.toString(),
      year: today.getFullYear().toString(),

      downloadedData: '',
    }
  }

  componentDidMount() {
    this.load()
  }
  private onRowsPerPageChange = (page: number, rowsPerPage: number) => {
    // console.log('onRowsPerPageChange', page, rowsPerPage)
    const offset = (page - 1) * rowsPerPage,
      limit = offset + rowsPerPage
    this.setState({ offset, limit }, this.load)
  }

  private monthOptions = [
    { text: 'January', value: '01', key: '1' },
    { text: 'February', value: '02', key: '2' },
    { text: 'March', value: '03', key: '3' },
    { text: 'April', value: '04', key: '4' },
    { text: 'May', value: '05', key: '5' },
    { text: 'June', value: '06', key: '6' },
    { text: 'July', value: '07', key: '7' },
    { text: 'August', value: '08', key: '8' },
    { text: 'September', value: '09', key: '9' },
    { text: 'October', value: '10', key: '10' },
    { text: 'November', value: '11', key: '11' },
    { text: 'December', value: '12', key: '12' },
  ]

  private yearOptions = [
    { text: '2020', value: '2020', key: '1' },
    { text: '2021', value: '2021', key: '2' },
    { text: '2022', value: '2022', key: '3' },
    { text: '2023', value: '2023', key: '4' },
    { text: '2024', value: '2024', key: '5' },
    { text: '2025', value: '2025', key: '6' },
    { text: '2026', value: '2026', key: '7' },
    { text: '2027', value: '2027', key: '8' },
    { text: '2028', value: '2028', key: '9' },
    { text: '2029', value: '2029', key: '10' },
    { text: '2030', value: '2030', key: '11' },
    { text: '2031', value: '2031', key: '12' },
    { text: '2032', value: '2032', key: '13' },
    { text: '2033', value: '2033', key: '14' },
    { text: '2034', value: '2034', key: '15' },
    { text: '2035', value: '2035', key: '16' },
    { text: '2036', value: '2036', key: '17' },
    { text: '2037', value: '2037', key: '18' },
    { text: '2038', value: '2038', key: '19' },
    { text: '2039', value: '2039', key: '20' },
  ]

  private setMonth = (month: string) => this.setState({ month: month })
  private setYear = (year: string) => this.setState({ year: year })

  private load = () => {
    const { offset } = this.state
    this.setState({ loading: true }, async () => {
      const result = await this.getUsers()
      if (result instanceof Result.Success) {
        if (offset === 0) {
          const users = result.data.items
          const totalUserCount = result.data.totalItemCount
          const offset = users.length
          this.setState({ loading: false, offset, users, totalUserCount })
        } else {
          const users = [...this.state.users, ...result.data.items]
          const offset = users.length
          this.setState({ loading: false, users, offset })
        }
      } else {
        this.setState({ loading: false }, () => {
          const message = result.message || 'Could not load Users'
          toast.error(message)
        })
      }
    })
  }

  private downloadTemplate = async () => {
    const token = LocalStorage.get(LocalStorage.KEY_AUTH_TOKEN)
    let reqData: any
    try {
      toast.success('Download will start shortly!')
      axios({
        url: `/api/salary/calculate/${this.state.month}/${this.state.year}`,
        method: 'GET',
        responseType: 'blob',
        headers: { ['x-access-token']: token, Accept: 'application/zip' },
        onDownloadProgress: (progressEvent) => {
          // let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          // console.log(progressEvent.lengthComputable)

          let percentage = `${Math.round(progressEvent.loaded / 1024 / 1024)} MB Downloaded`
          this.setState({ downloadedData: percentage })
          // console.log(downloadedData)
        },
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'salaryTemplate.zip')
          document.body.appendChild(link)
          link.click()
          link.remove()
          this.setState({ downloadedData: '' })
        })
        .catch((e) => {
          console.log(JSON.stringify(e.response))
          toast.error('Something went wrong while downloading data!')
        })
    } catch (e) {
      toast.error('Something went wrong while downloading')
    }
  }

  private getUsers = async () => {
    const ro = {
      search: this.state.filter,
      limit: this.state.limit,
      offset: this.state.offset,
      is_active: true,
    }
    return await this.userRepo.getUsers(ro)
  }

  private setFilter = (filter: string) => this.setState({ offset: 0, filter }, this.load)

  render() {
    return (
      <Page title="Salary" description="Choose month and year">
        <Grid columns={5} verticalAlign="bottom">
          <Grid.Row>
            <Grid.Column>
              <Dropdown search selection value={this.state.month} options={this.monthOptions} onChange={(_, { value }) => this.setMonth(value as string)} />
            </Grid.Column>
            <Grid.Column>
              <Dropdown search selection value={this.state.year} options={this.yearOptions} onChange={(_, { value }) => this.setYear(value as string)} />
            </Grid.Column>
            <Grid.Column>
              <Button primary onClick={this.downloadTemplate}>
                {this.state.downloadedData != '' ? this.state.downloadedData : 'Download Template'}
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Table
          renderer={this.columnRenderer}
          data={this.state.users}
          totalDataCount={this.state.totalUserCount}
          loading={this.state.loading}
          load={this.load}
          onRowsPerPageChange={this.onRowsPerPageChange}
          onSearch={this.setFilter}
          onClear={() => this.setFilter('')}
        />
      </Page>
    )
  }
}

export default (props: any) => {
  return (
    <RequirePermission permission={permissions.Manage.CRUD}>
      <UsersSalaryPage {...props} />
    </RequirePermission>
  )
}
