import React from "react";
import { Form } from "semantic-ui-react";
import Button from "../../elements/Button";
import PolicyType from "../../../models/PolicyType";
import PolicyTypeRepository from "../../../common/repository/PolicyTypeRepository";
import Result from "../../../common/repository/Result";
import { toast } from "../Toast";

interface Props {
  model?: PolicyType;
  onSuccess(policyType: PolicyType): void;
  onError?(): void;
}

interface State {
  form_loading: boolean;

  policyType_title: string;
  policyType_description: string;
}

class PolicyTypeForm extends React.Component<Props, State> {
  private policyTypeRepo = new PolicyTypeRepository();

  constructor(props: Props) {
    super(props);

    const stateFromModel = this.derivePolicyStateFromModel();
    this.state = {
      form_loading: false,
      ...stateFromModel,
    };
  }

  private derivePolicyStateFromModel = () => {
    const model = this.props.model;
    return {
      policyType_title: (model && model.getTitle()) || "",
      policyType_description: (model && model.getDescription()) || "",
    };
  };

  private onSubmit = () => {
    this.setState({ form_loading: true }, () => {
      if (this.props.model) this.updatePolicyType();
      else this.addPolicyType();
    });
  };

  private addPolicyType = async () => {
    const result = await this.policyTypeRepo.addPolicyType({
      title: this.state.policyType_title,
      description: this.state.policyType_description,
    });

    if (result instanceof Result.Success) {
      const policyType = result.data;
      toast.success("Successfully added Policy Type");
      this.props.onSuccess(policyType);
    } else {
      const message = result.message || "Something went wrong";
      this.setState({ form_loading: false }, () => {
        toast.error(message);
        if (this.props.onError) this.props.onError();
      });
    }
  };

  private updatePolicyType = async () => {
    const id = this.props.model!.getId();
    const result = await this.policyTypeRepo.updatePolicyType({
      id,
      title: this.state.policyType_title,
      description: this.state.policyType_description,
    });

    if (result instanceof Result.Success) {
      const policyType = result.data;
      toast.success("Successfully added Policy Type");
      this.props.onSuccess(policyType);
    } else {
      const message = result.message || "Something went wrong";
      this.setState({ form_loading: false }, () => {
        toast.error(message);
        if (this.props.onError) this.props.onError();
      });
    }
  };

  render() {
    return (
      <Form loading={this.state.form_loading}>
        <Form.Input
          label="Policy Title"
          placeholder="e.g. Health Insurance"
          value={this.state.policyType_title}
          onChange={(_, { value }) =>
            this.setState({ policyType_title: value })
          }
        />

        <Form.TextArea
          label="Description"
          value={this.state.policyType_description}
          onChange={(_, { value }) =>
            this.setState({ policyType_description: value as string })
          }
        />

        <Button
          positive
          disabled={
            this.state.policyType_title.length === 0 ||
            this.state.policyType_description.length === 0 ||
            this.state.form_loading
          }
          onClick={this.onSubmit}
        >
          Submit
        </Button>
      </Form>
    );
  }
}

export default PolicyTypeForm;
