import React, { Component } from "react";
import { Form, Button } from "semantic-ui-react";
import RemoveModal from "./RemoveModal";

export interface RemoveProps {
  loading?: boolean;
  title?: string;
  buttonTitle?: string;
  onRemove?(): void;
  onCancel?(): void;
}

class Remove extends Component<RemoveProps> {
  static Modal = RemoveModal;

  render() {
    const { loading, title, buttonTitle, onRemove, onCancel } = this.props;
    return (
      <Form loading={loading}>
        <p>{title ? title : "Are you sure?"}</p>
        <Button color="red" inverted onClick={onRemove}>
          {buttonTitle || "Remove"}
        </Button>
        <Button onClick={onCancel} style={{ marginLeft: 4 }}>
          Cancel
        </Button>
      </Form>
    );
  }
}

export default Remove;
