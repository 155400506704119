import React from 'react'
import Page from '../../../layout/Page'
import { Modal, Icon, Button } from 'semantic-ui-react'
import PolicyTypeForm from '../../../common/PolicyTypeForm'
import PolicyType from '../../../../models/PolicyType'
import PolicyTypeRepository from '../../../../common/repository/PolicyTypeRepository'
import Result from '../../../../common/repository/Result'
import { toast } from '../../../common/Toast'
import RemovalConfirmationForm from '../../../common/RemovalConfirmationForm'
import Table from '../../../app/Table'
import PolicyTypeColumnRenderer from './PolicyTypeColumnRenderer'
import RequirePermission from '../../../base/RequirePermission'
import permissions from '../../../../common/permissions'

interface State {
  loading: boolean
  filter: string
  limit: number
  offset: number
  totalPolicyTypeCount: number
  policyTypes: PolicyType[]

  add_modalOpen: boolean

  update_modalOpen: boolean
  update_model: PolicyType | null

  remove_modalOpen: boolean
  remove_model: PolicyType | null
}

class PolicyTypePage extends React.Component<{}, State> {
  private policyTypeRepo = new PolicyTypeRepository()
  private columnRenderer = new PolicyTypeColumnRenderer(
    (model: PolicyType) => {
      return (
        <Button.Group size="mini">
          <Button onClick={() => this.setState({ update_modalOpen: true, update_model: model })}>Open</Button>
          <Button negative onClick={() => this.setState({ remove_modalOpen: true, remove_model: model })}>
            Remove
          </Button>
        </Button.Group>
      )
    },
    (obj: any) => obj.description !== undefined
  )

  constructor(props: {}) {
    super(props)
    this.state = {
      loading: false,
      filter: '',
      limit: 10,
      offset: 0,
      totalPolicyTypeCount: 0,
      policyTypes: [],

      add_modalOpen: false,

      update_modalOpen: false,
      update_model: null,

      remove_modalOpen: false,
      remove_model: null,
    }
  }

  componentDidMount() {
    this.load()
  }
  private onRowsPerPageChange = (page: number, rowsPerPage: number) => {
    // console.log('onRowsPerPageChange', page, rowsPerPage)
    const offset = (page - 1) * rowsPerPage,
      limit = offset + rowsPerPage
    this.setState({ offset, limit }, this.load)
  }

  private load = async () => {
    const { offset } = this.state
    this.setState({ loading: true }, async () => {
      const result = await this.getPolicyTypes()
      if (result instanceof Result.Success) {
        if (offset === 0) {
          const policyTypes = result.data.items
          const totalPolicyTypeCount = result.data.totalItemCount
          const offset = policyTypes.length
          this.setState({ loading: false, offset, policyTypes, totalPolicyTypeCount })
        } else {
          const policyTypes = [...this.state.policyTypes, ...result.data.items]
          const offset = policyTypes.length
          this.setState({ loading: false, offset, policyTypes })
        }
      } else {
        this.setState({ loading: false }, () => {
          const message = result.message || 'Something went wrong'
          toast.error(message)
        })
      }
    })
  }

  private getPolicyTypes = async () => {
    const ro = {
      search: this.state.filter,
      limit: this.state.limit,
      offset: this.state.offset,
    }
    return await this.policyTypeRepo.getPolicyTypes(ro)
  }

  private setFilter = (filter: string) => {
    this.setState({ filter, offset: 0 }, this.load)
  }

  private onPolicyTypeAdded = (policyType: PolicyType) => {
    this.setState((currentState) => {
      const policyTypes = [policyType, ...currentState.policyTypes]
      const totalPolicyTypeCount = currentState.totalPolicyTypeCount + 1
      return { policyTypes, totalPolicyTypeCount, add_modalOpen: false }
    })
  }

  private onPolicyTypeUpdated = (policyType: PolicyType) => {
    const policyTypes = this.state.policyTypes
    const index = policyTypes.findIndex((it) => it.getId() === policyType.getId())
    if (index === -1) return
    policyTypes[index] = policyType
    this.setState({ policyTypes: [...policyTypes], update_modalOpen: false, update_model: null })
  }

  private removePolicyType = async () => {
    const id = this.state.remove_model!.getId()
    const result = await this.policyTypeRepo.removePolicyType(id)
    if (result instanceof Result.Success) {
      const policyTypes = this.state.policyTypes
      let totalPolicyTypeCount = this.state.totalPolicyTypeCount
      const index = policyTypes.findIndex((it) => it.getId() === id)
      if (index !== -1) {
        policyTypes.splice(index, 1)
        totalPolicyTypeCount--
      }
      this.setState({ policyTypes: [...policyTypes], totalPolicyTypeCount, remove_model: null, remove_modalOpen: false }, () => toast.success('Successfully removed Policy Type'))
    } else {
      const message = result.message || 'Something went wrong'
      toast.error(message)
    }
  }

  render() {
    return (
      <Page title="Policy Type" description="Manage Policy Types">
        {/* Update Policy Type Modal */}
        <Modal open={this.state.update_modalOpen} onClose={() => this.setState({ update_modalOpen: false, update_model: null })} size="tiny" closeIcon closeOnDimmerClick={false} closeOnEscape={false}>
          <Modal.Header>Update Policy Type</Modal.Header>
          <Modal.Content>
            <PolicyTypeForm model={this.state.update_model || undefined} onSuccess={this.onPolicyTypeUpdated} />
          </Modal.Content>
        </Modal>
        {/* Update Policy Type Modal */}

        {/* Remove Policy Type Modal */}
        <Modal size="mini" open={this.state.remove_modalOpen} onClose={() => this.setState({ remove_modalOpen: false, remove_model: null })} closeIcon closeOnDimmerClick={false} closeOnEscape={false}>
          <Modal.Header>Confirmation</Modal.Header>
          <Modal.Content>
            <RemovalConfirmationForm title="Are you sure you want to delete this?" remove={this.removePolicyType} cancel={() => this.setState({ remove_model: null, remove_modalOpen: false })} />
          </Modal.Content>
        </Modal>
        {/* Remove Policy Type Modal */}

        {/* Add Policy Type Modal */}
        <Modal
          open={this.state.add_modalOpen}
          onClose={() => this.setState({ add_modalOpen: false })}
          size="tiny"
          closeIcon
          closeOnDimmerClick={false}
          closeOnEscape={false}
          trigger={
            <Button icon labelPosition="left" color="green" onClick={() => this.setState({ add_modalOpen: true })}>
              <Icon name="add" />
              Create New
            </Button>
          }
        >
          <Modal.Header>Create New Policy Type</Modal.Header>
          <Modal.Content>
            <PolicyTypeForm onSuccess={this.onPolicyTypeAdded} />
          </Modal.Content>
        </Modal>
        {/* Add Policy Type Modal */}

        <Table
          renderer={this.columnRenderer}
          data={this.state.policyTypes}
          totalDataCount={this.state.totalPolicyTypeCount}
          loading={this.state.loading}
          load={this.load}
          onRowsPerPageChange={this.onRowsPerPageChange}
          onSearch={this.setFilter}
          onClear={() => this.setFilter('')}
        />
      </Page>
    )
  }
}

export default (props: any) => {
  return (
    <RequirePermission permission={permissions.Master.CRUD}>
      <PolicyTypePage {...props} />
    </RequirePermission>
  )
}
