import DomainModel from './DomainModel'

class InvestigatorAllowance extends DomainModel {
  constructor(private id: string, private primaryLocation: number, private secondaryLocation: number, private tertiaryLocation: number) {
    super()
  }

  getId = () => this.id
  getPrimaryLocation = () => this.primaryLocation
  getSecondaryLocation = () => this.secondaryLocation
  getTertiaryLocation = () => this.tertiaryLocation
}

export default InvestigatorAllowance
