import React from 'react'
import Client from '../../../../models/Client'
import ClientRepository from '../../../../common/repository/ClientRepository'

interface ClientPageContextType {
  client: Client | null
  setClient(client: Client | null): void

  loadClient: boolean
  setLoadClient(loadClient: boolean): void

  loadingClient: boolean
  setLoadingClient(loading: boolean): void

  clientRepo: ClientRepository
}

const ClientPageContext = React.createContext({} as ClientPageContextType)
export default ClientPageContext
