import React, { useState, useEffect } from "react";
import { Segment, Form, Header, Icon, Divider } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { setAuth } from "../../../redux/auth/actioncreators";
import { useHistory, useLocation } from "react-router-dom";
import LoginRepository from "../../../common/repository/LoginRepository";
import Result from "../../../common/repository/Result";
import { toast } from "../../common/Toast";
import LocalStorage from "../../../lib/LocalStorage";

const ForgetPasswordForm = () => {
  const loginRepo = new LoginRepository();
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");

  /** hook to perform login operation */
  useEffect(() => {
    if (!loading) return;

    (async () => {
      const result = await loginRepo.forgotPassword(email);
      if (result instanceof Result.Success) {
        toast.success(result.data!);
        history.push("/");
      } else {
        const message = result.message || "Something went wrong";
        toast.error(message);
      }
      setLoading(false);
    })();
  }, [loading]);

  return (
    <Segment.Group>
      <Segment>
        <Header as="h1">JD Healthcare - Web Dashboard</Header>
      </Segment>

      <Segment>
        <Form widths="equal" loading={loading}>
          <Form.Input
            label="Email"
            placeholder="someone@something.com"
            value={email}
            onChange={(_, { value }) => setEmail(value)}
          />
          <Divider hidden />

          <Form.Button
            size="large"
            primary
            icon
            labelPosition="right"
            disabled={loading || email.length === 0}
            onClick={() => setLoading(true)}
          >
            <Icon name="arrow right" />
            Reset Password
          </Form.Button>
          <b>
            <a onClick={() => history.push("/")}>
              <Icon name="arrow left" /> Back
            </a>
          </b>
        </Form>
      </Segment>

      <Segment textAlign="center">
        <strong>Dashboard</strong> powered by <strong>WebHopers</strong>
      </Segment>
    </Segment.Group>
  );
};

export default ForgetPasswordForm;
