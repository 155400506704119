import React, { useContext, useState } from "react";
import {
  Grid,
  Form,
  Header,
  Segment,
  Radio,
  DropdownItemProps,
} from "semantic-ui-react";
import FilterPageContext from "./FilterPageContext";
import { toast } from "../../../common/Toast";
import constants from "../../../../common/constants";
import DateTimePicker from "../../../app/DateTimePicker";

function Filters() {
  const ctx = useContext(FilterPageContext);

  let data: { key: string; value: any }[] = [];
  if (ctx.filters.to) data.push({ key: "to", value: ctx.filters.to });
  if (ctx.filters.from) data.push({ key: "from", value: ctx.filters.from });
  if (ctx.filters.toPaymentDate)
    data.push({ key: "toPaymentDate", value: ctx.filters.toPaymentDate });
  if (ctx.filters.fromPaymentDate)
    data.push({ key: "fromPaymentDate", value: ctx.filters.fromPaymentDate });
  if (ctx.filters.claimNumber)
    data.push({ key: "claimNumber", value: ctx.filters.claimNumber });
  if (ctx.filters.invoiceNumber)
    data.push({ key: "invoiceNumber", value: ctx.filters.invoiceNumber });
  if (ctx.filters.client)
    data.push({ key: "client", value: ctx.filters.client });
  if (ctx.filters.caseType)
    data.push({ key: "caseType", value: ctx.filters.caseType });

  const paymentStatus: DropdownItemProps[] = [
    {
      key: constants.paymentStatus.pending,
      text: constants.paymentStatus.pending,
      value: constants.paymentStatus.pending,
    },
    {
      key: constants.paymentStatus.partial,
      text: constants.paymentStatus.partial,
      value: constants.paymentStatus.partial,
    },
    {
      key: constants.paymentStatus.complete,
      text: constants.paymentStatus.complete,
      value: constants.paymentStatus.complete,
    },
  ];

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Header as="h4">Filter</Header>
      </div>
      <Grid columns={5}>
        <Grid.Row>
          <Grid.Column width={4}>
            <Segment>
              <Radio
                toggle
                label={"Document Received"}
                checked={ctx.filters.searchByDocPending}
                onChange={() => {
                  // if(ctx.filters.searchByUploadPending || ctx.filters.searchByInvoicePending)
                  //     toast.error("Only One Filter Applied at a Time!!");
                  // else
                  ctx.filters.setSearchByDocPending(
                    !ctx.filters.searchByDocPending
                  );
                  ctx.filters.setSearchByUploadPending(false);
                }}
              />
            </Segment>
          </Grid.Column>
          <Grid.Column width={4}>
            <Segment>
              <Radio
                toggle
                label={"Payment Advice Pending"}
                checked={ctx.filters.searchByUploadPending}
                onChange={() => {
                  // if(ctx.filters.searchByDocPending || ctx.filters.searchByInvoicePending)
                  //     toast.error("Only One Filter Applied at a Time!!");
                  // else
                  ctx.filters.setSearchByUploadPending(
                    !ctx.filters.searchByUploadPending
                  );
                  ctx.filters.setSearchByPaymentPending(false);
                  ctx.filters.setSearchByDocPending(false);
                }}
              />
            </Segment>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Form.Input label="From Invoice Date">
              <DateTimePicker
                placeholder="Select From Invoice Date"
                type="date"
                value={ctx.filters.from}
                onChange={ctx.filters.setFrom}
              />
            </Form.Input>
          </Grid.Column>
          <Grid.Column>
            <Form.Input label="To Invoice Date">
              <DateTimePicker
                placeholder="Select To Invoice Date"
                type="date"
                value={ctx.filters.to}
                onChange={ctx.filters.setTo}
              />
            </Form.Input>
          </Grid.Column>

          <Grid.Column>
            <Form.Input label="From Payment Date">
              <DateTimePicker
                placeholder="Select From Payment Date"
                type="date"
                value={ctx.filters.fromPaymentDate}
                onChange={ctx.filters.setFromPaymentDate}
              />
            </Form.Input>
          </Grid.Column>
          <Grid.Column>
            <Form.Input label="To Payment Date">
              <DateTimePicker
                placeholder="Select To Payment Date"
                type="date"
                value={ctx.filters.toPaymentDate}
                onChange={ctx.filters.setToPaymentDate}
              />
            </Form.Input>
          </Grid.Column>

          <Grid.Column>
            <Form.Dropdown
              label="Case Type"
              search
              selection
              placeholder="Choose Case Type"
              options={ctx.options.caseType}
              value={ctx.filters.caseType}
              onChange={(_, { value }) =>
                ctx.filters.setCaseType(value as string)
              }
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Form.Dropdown
              label="Client(TPA/Company)"
              search
              selection
              placeholder="Select Client"
              options={ctx.options.client}
              value={ctx.filters.client}
              onChange={(_, { value }) =>
                ctx.filters.setClient(value as string)
              }
            />
          </Grid.Column>
          <Grid.Column>
            <Form.Input
              label="Claim Number"
              placeholder="Enter Claim Number"
              value={ctx.filters.claimNumber}
              onChange={(_, { value }) => ctx.filters.setClaimNumber(value)}
              // onKeyDown={(e:any)=>{if(e.keyCode==13)ctx.filters.setClaimNumber(claimNumber)}}
            />
          </Grid.Column>

          <Grid.Column>
            <Form.Input
              label="Invoice Number"
              placeholder="Enter Invoice Number"
              value={ctx.filters.invoiceNumber}
              onChange={(_, { value }) => ctx.filters.setInvoiceNumber(value)}
              // onKeyDown={(e:any)=>{if(e.keyCode==13)ctx.filters.setClaimNumber(claimNumber)}}
            />
          </Grid.Column>

          <Grid.Column>
            <Form.Dropdown
              label="Payment Status"
              search
              selection
              placeholder="Choose Status"
              options={paymentStatus}
              value={ctx.filters.searchByPaymentStatus}
              onChange={(_, { value }) => {
                let val = value as string;
                if (val === constants.paymentStatus.pending) {
                  ctx.filters.setSearchByPaymentPending(
                    !ctx.filters.searchByPaymentPending
                  );
                  ctx.filters.setSearchByUploadPending(false);
                  ctx.filters.setSearchByPaymentStatus(val);
                } else {
                  ctx.filters.setSearchByPaymentStatus(val);
                  ctx.filters.setSearchByPaymentPending(false);
                  ctx.filters.setSearchByUploadPending(false);
                }
              }}
            />
          </Grid.Column>

          <Grid.Column >
              <Segment compact>
                <Radio
                  toggle
                  label={"Reinvestigation"}
                  checked={ctx.filters.Reinvestigation}
                  onChange={() => {
                    ctx.filters.setReinvestigation(
                      !ctx.filters.Reinvestigation
                    );
                  }}
                />
              </Segment>
            </Grid.Column>
            
            <Grid.Column>
              <Segment compact>
                <Radio
                  toggle
                  label={"Pushback"}
                  checked={ctx.filters.Pushback}
                  onChange={() => {
                    ctx.filters.setPushback(
                      !ctx.filters.Pushback
                    );
                  }}
                />
              </Segment>
            </Grid.Column>

          <Grid.Column>
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            &nbsp;
            <div className="ui buttons">
              <button
                className="ui blue button"
                onClick={ctx.filters.applyFilters}
              >
                Apply Filters
              </button>
              {/*<div className="or"></div>*/}
              <button
                className="ui button"
                onClick={ctx.filters.clearAllFilters}
              >
                Clear
              </button>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
}

export default Filters;
