import DomainModel from './DomainModel'

export default class PolicyType extends DomainModel {
  constructor(private id: string, private title: string, private description: string) {
    super()
  }

  getId = () => this.id
  getTitle = () => this.title
  getDescription = () => this.description
}
