import ProtectedAPIRepository from './ProtectedAPIRepository'
import CaseTypeDataMapper from '../datamapper/CaseTypeDataMapper'
import CaseTypeRM from '../responsemodel/CaseTypeRM'
import APIResponseModel from './APIResponseModel'
import Result from './Result'

export default class CaseTypeRepository extends ProtectedAPIRepository {
  private dataMapper = new CaseTypeDataMapper()

  getCaseTypes = async (ro?: GetCaseTypesRequestObject) => {
    const response = await this.post<APIResponseModel<CaseTypeRM>>('/caseType', ro)
    if (response instanceof Result.Success) {
      const data: APIResponseModel<CaseTypeRM> = response.data
      if (data.data !== null) {
        const caseTypes = data.data.items.map((it) => this.dataMapper.map(it))
        return new Result.Success({
          totalItemCount: data.data.total_item_count,
          items: caseTypes,
        })
      } else {
        return new Result.Error(0, 'Data is null')
      }
    } else {
      return response
    }
  }

  addCaseType = async (ro: AddCaseTypeRequestObject) => {
    const response = await this.put<APIResponseModel<CaseTypeRM>>('/caseType', ro)
    if (response instanceof Result.Success) {
      const data: APIResponseModel<CaseTypeRM> = response.data
      if (data.data !== null) {
        const caseType = this.dataMapper.map(data.data.items[0])
        return new Result.Success(caseType)
      } else {
        return new Result.Error(0, 'Data is null')
      }
    } else {
      return response
    }
  }

  updateCaseType = async (ro: UpdateCaseTypeRequestObject) => {
    const response = await this.patch<APIResponseModel<CaseTypeRM>>('/caseType', ro)
    if (response instanceof Result.Success) {
      const data: APIResponseModel<CaseTypeRM> = response.data
      if (data.data !== null) {
        const caseType = this.dataMapper.map(data.data.items[0])
        return new Result.Success(caseType)
      } else {
        return new Result.Error(0, 'Data is null')
      }
    } else {
      return response
    }
  }

  removeCaseType = async (caseTypeId: string) => {
    const response = await this.delete<APIResponseModel<null>>(`/caseType/${caseTypeId}`)
    return response
  }
}

interface GetCaseTypesRequestObject {
  search?: string
  limit?: number
  offset?: number
}

interface AddCaseTypeRequestObject {
  name: string
  case_category: string
  internal_TAT: number
  investigator_TAT: number
  capping: number
  services: any[]
  required_documents: []
}

interface UpdateCaseTypeRequestObject {
  id: string
  name: string
  case_category: string
  internal_TAT: number
  investigator_TAT: number
  capping: number
  services: any[]
  required_documents: []
}
