import ActionColumnRenderer from '../../../app/Table/renderer/ActionColumnRenderer'
import CaseCategory from '../../../../models/CaseCategory'

export default class CaseCategoryColumnRenderer extends ActionColumnRenderer<CaseCategory> {
    getColumns() {
        return [
            {
                name: 'Name',
                selector: 'title',
            },
            {
                name: 'Code',
                selector: 'code',
            },
            // {
            //     name: 'id',
            //     selector: 'id',
            // },
            {
                name: 'Actions',
                selector: 'id',
                grow: 0,
                width: '180px',
                cell: this.renderActionColumn,
            },
        ]
    }
}
