import ActionColumnRenderer from '../../../app/Table/renderer/ActionColumnRenderer'
import States from '../../../../models/States'

export default class StatesColumnRenderer extends ActionColumnRenderer<States> {
  getColumns() {
    return [
      {
        name: 'Name',
        selector: 'name',
      },
      {
        name: 'Code',
        selector: 'code',
      },
      {
        name: 'GSTCode',
        selector: 'GSTCode',
      },
      {
        name: 'Actions',
        selector: 'id',
        grow: 0,
        width: '180px',
        cell: this.renderActionColumn,
      },
    ]
  }
}
