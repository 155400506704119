import ProtectedAPIRepository from './ProtectedAPIRepository'
import APIResponseModel from './APIResponseModel'
import Result from './Result'
import DocumentPendencyDataMapper from '../datamapper/DocumentPendencyDataMapper'
import DocumentPendencyRM from '../responsemodel/DocumentPendencyRM'
import DocumentPendency from '../../models/DocumentPendency'

export default class DocumentPendencyRepository extends ProtectedAPIRepository {
  private dataMapper = new DocumentPendencyDataMapper()

  getData = async (ro?: GetDocumentPendencyRequestObject) => {
    const response = await this.post<APIResponseModel<DocumentPendencyRM>>('/billing/documentPendency', ro)

    if (response instanceof Result.Success) {
      const data: APIResponseModel<DocumentPendencyRM> = response.data
      if (data.data !== null) {
        let receivedData: DocumentPendency[] = []

        for (let i = 0; i < data.data.items.length; i++) {
          let it = data.data.items[i]
          receivedData = receivedData.concat(this.dataMapper.map(it))
        }

        return new Result.Success({
          totalItemCount: data.data.total_item_count,
          items: receivedData,
        })
      } else {
        return new Result.Error(0, 'Data is null')
      }
    } else {
      return response
    }
  }

  updateData = async (ro: UpdateDocumentPendencyRM) => {
    const response = await this.patch<APIResponseModel<DocumentPendencyRM>>('/billing/documentPendency', ro)
    if (response instanceof Result.Success) {
      const data: APIResponseModel<DocumentPendencyRM> = response.data
      if (data.data !== null) {
        const receivedData = data.data.items[0] ? this.dataMapper.map(data.data.items[0]) : []
        return new Result.Success(receivedData)
      } else {
        return new Result.Error(0, 'Data is null')
      }
    } else {
      return response
    }
  }

  uploadDocumentPendencySheet = async (file: File) => {
    const formData = this.constructFormData({ sheet: file })
    const response = await this.patch<APIResponseModel<DocumentPendencyRM>>('/billing/documentPendency/upload', formData)

    if (response instanceof Result.Success) {
      const data: APIResponseModel<DocumentPendencyRM> = response.data
      if (data.data !== null) {
        let receivedData: DocumentPendency[] = []
        for (let i = 0; i < data.data.items.length; i++) {
          let it = data.data.items[i]
          receivedData = receivedData.concat(this.dataMapper.map(it))
        }
        return new Result.Success({
          totalItemCount: data.data.total_item_count,
          items: receivedData,
        })
      } else {
        return new Result.Error(0, 'Data is null')
      }
    } else {
      return response
    }
  }
}

export interface GetDocumentPendencyRequestObject {
  search?: string
  limit?: number
  offset?: number
  id?: string
  case_id?: string
  claim_number?: string
  client_id?: string
  client_name?: string
  investigator_id?: string
  from?: string
  to?: string
  reinvestigation?: boolean
  pushback?: boolean
}

export interface UpdateDocumentPendencyRM {
  claim_number: string
  investigation: [
    {
      investigator_emp_id: string
      document_receipts_arrived: boolean
      approved_receipts_arrived: boolean
      extra_receipts_arrived: boolean
      evidence_arrived: boolean
    }
  ]
}
