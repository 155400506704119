import React from "react";
import { Form, Radio } from "semantic-ui-react";
import Button from "../../../elements/Button";
import DocumentPendency from "../../../../models/DocumentPendency";
import Result from "../../../../common/repository/Result";
import { toast } from "../../../common/Toast";
import DocumentPendencyRepository from "../../../../common/repository/DocumentPendencyRepository";

interface Props {
  //if model is passed that means update
  model?: DocumentPendency;

  onSuccess: (documentPendency: DocumentPendency) => void;
  onError?: () => void;
}

interface State {
  form_loading: boolean;

  claim_number: string;
  investigator_emp_id: string;
  document_receipts_arrived: boolean;
  approved_receipts_arrived: boolean;
  extra_receipts_arrived: boolean;
  evidence_arrived: boolean;
}

class DocumentPendencyForm extends React.Component<Props, State> {
  private documentPendencyRepo = new DocumentPendencyRepository();

  constructor(props: Props) {
    super(props);

    const stateFromModel = this.deriveDocumentPendencyStateFromModel();
    this.state = {
      form_loading: false,
      ...stateFromModel,
    };
  }

  componentDidMount() {}

  private deriveDocumentPendencyStateFromModel = () => {
    const model = this.props.model;
    return {
      claim_number: (model && model.getClaimNumber()) || "",
      investigator_emp_id: (model && model.getInvestigatorEmpId()) || "",
      document_receipts_arrived:
        (model && model.getDocumentReceiptStatus()) || false,
      approved_receipts_arrived:
        (model && model.getApprovedReceiptStatus()) || false,
      extra_receipts_arrived: (model && model.getExtraReceiptStatus()) || false,
      evidence_arrived: (model && model.getEvidenceStatus()) || false,
    };
  };

  private onSubmit = () => {
    this.setState({ form_loading: true }, () => {
      this.updateDocumentPendency();
    });
  };

  private updateDocumentPendency = async () => {
    const result = await this.documentPendencyRepo.updateData({
      claim_number: this.state.claim_number,
      investigation: [
        {
          investigator_emp_id: this.state.investigator_emp_id,
          document_receipts_arrived: this.state.document_receipts_arrived,
          approved_receipts_arrived: this.state.approved_receipts_arrived,
          extra_receipts_arrived: this.state.extra_receipts_arrived,
          evidence_arrived: this.state.evidence_arrived,
        },
      ],
    });

    if (result instanceof Result.Success) {
      const receivedData = result.data;
      this.setState({ form_loading: false }, () => {
        toast.success("Information updated successfully");
        this.props.onSuccess(receivedData[0]);
      });
    } else {
      this.setState({ form_loading: false }, () => {
        const message = result.message || "Something went wrong";
        toast.error(message);
        if (this.props.onError) this.props.onError();
      });
    }
  };

  render(): JSX.Element {
    return (
      <Form loading={this.state.form_loading}>
        {/* claim number and emp id */}
        <Form.Group inine>
          <Form.Input label="Claim Number" value={this.state.claim_number} />
          <Form.Input
            label="Investigator Id"
            value={this.state.investigator_emp_id}
          />
        </Form.Group>
        {/* claim number and emp id */}

        {/* document receipt status*/}
        <Form.Group>
          <Form.Field>Document Charges Receipt Arrived</Form.Field>
          <Form.Field>
            <Radio
              label="Yes"
              name="docArrived"
              checked={this.state.document_receipts_arrived}
              onChange={(_, { value }) =>
                this.setState({
                  document_receipts_arrived:
                    !this.state.document_receipts_arrived,
                })
              }
            />
          </Form.Field>
          <Form.Field>
            <Radio
              label="No"
              name="docArrived"
              checked={!this.state.document_receipts_arrived}
              onChange={(_, { value }) =>
                this.setState({
                  document_receipts_arrived:
                    !this.state.document_receipts_arrived,
                })
              }
            />
          </Form.Field>
        </Form.Group>
        {/* document receipt status*/}

        {/* Approved receipt status*/}
        <Form.Group>
          <Form.Field>Approved Charges(Invoice) Receipt Arrived</Form.Field>
          <Form.Field>
            <Radio
              label="Yes"
              name="approvedArrived"
              checked={this.state.approved_receipts_arrived}
              onChange={(_, { value }) =>
                this.setState({
                  approved_receipts_arrived:
                    !this.state.approved_receipts_arrived,
                })
              }
            />
          </Form.Field>
          <Form.Field>
            <Radio
              label="No"
              name="approvedArrived"
              checked={!this.state.approved_receipts_arrived}
              onChange={(_, { value }) =>
                this.setState({
                  approved_receipts_arrived:
                    !this.state.approved_receipts_arrived,
                })
              }
            />
          </Form.Field>
        </Form.Group>
        {/* Approved receipt status*/}

        {/* Extra receipt status*/}
        <Form.Group>
          <Form.Field>Extra Charges(Investigator) Receipt Arrived</Form.Field>
          <Form.Field>
            <Radio
              label="Yes"
              name="extraDocArrived"
              checked={this.state.extra_receipts_arrived}
              onChange={(_, { value }) =>
                this.setState({
                  extra_receipts_arrived: !this.state.extra_receipts_arrived,
                })
              }
            />
          </Form.Field>
          <Form.Field>
            <Radio
              label="No"
              name="extraDocArrived"
              checked={!this.state.extra_receipts_arrived}
              onChange={(_, { value }) =>
                this.setState({
                  extra_receipts_arrived: !this.state.extra_receipts_arrived,
                })
              }
            />
          </Form.Field>
        </Form.Group>
        {/* Extra receipt status*/}

        {/* evidence status*/}
        <Form.Group>
          <Form.Field>Evidence Arrived</Form.Field>
          <Form.Field>
            <Radio
              label="Yes"
              name="evidenceArrived"
              checked={this.state.evidence_arrived}
              onChange={(_, { value }) =>
                this.setState({
                  evidence_arrived: !this.state.evidence_arrived,
                })
              }
            />
          </Form.Field>
          <Form.Field>
            <Radio
              label="No"
              name="evidenceArrived"
              checked={!this.state.evidence_arrived}
              onChange={(_, { value }) =>
                this.setState({
                  evidence_arrived: !this.state.evidence_arrived,
                })
              }
            />
          </Form.Field>
        </Form.Group>
        {/* evidence status*/}

        <Button
          positive
          onClick={this.onSubmit}
          disabled={
            false
            // this.state.caseType_title.trim().length === 0 ||
            // this.state.caseType_category.trim().length === 0 ||
            // this.state.caseType_internalTATDays.trim().length === 0 ||
            // this.state.caseType_internalTATHours.trim().length === 0 ||
            // this.state.caseType_investigatorTATDays.trim().length === 0 ||
            // this.state.caseType_investigatorTATHours.trim().length === 0 ||
            // this.state.caseType_initialCasePortionCapping.trim().length === 0
          }
        >
          Submit
        </Button>
      </Form>
    );
  }
}

export default DocumentPendencyForm;
