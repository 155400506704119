import React from "react";
import { Modal } from "semantic-ui-react";
import BaseProps from "../../base/BaseProps";

export interface RemoveModalProps extends BaseProps {
  open?: boolean;
  onClose?(): void;
  header?: string;
  trigger?: JSX.Element;
}

const RemoveModal = (props: RemoveModalProps) => {
  const { open, onClose, header, trigger, children } = props;
  return (
    <Modal
      size="mini"
      open={open}
      onClose={onClose}
      closeIcon
      closeOnDimmerClick={false}
      closeOnEscape={false}
      trigger={trigger}
    >
      <Modal.Header>{header ? header : "Removal Confirmation"}</Modal.Header>
      <Modal.Content>{children}</Modal.Content>
    </Modal>
  );
};

export default RemoveModal;
