import React, { Component } from "react";
import { Form, Button } from "semantic-ui-react";
import AllocatedInvestigator from "../../../../../../models/AllocatedInvestigator";
import CaseRepository from "../../../../../../common/repository/CaseRepository";
import Result from "../../../../../../common/repository/Result";
import { toast } from "../../../../../common/Toast";

interface Props {
  model: AllocatedInvestigator;
  onSuccess?(model: AllocatedInvestigator): void;
  setEvidence: any
}

interface State {
  loading: boolean;
  evidence: File[];
}

/**
 * Renders form to upload evidence
 *
 * @param {AllocatedInvestigator} model - AllocatedInvestigator model
 * @param {object} onSuccess - Function that will be called on success with updated AllocatedInvestigator model
 */
class UploadEvidenceForm extends Component<Props, State> {
  private caseRepo = new CaseRepository();

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      evidence: [],
    };
  }

  uploadEvidence = () => {
    this.setState({ loading: true }, async () => {
      const evidence = this.state.evidence;
      const model = this.props.model;

      const result = await this.caseRepo.uploadEvidence({
        allocation_id: model.getId(),
        evidence,
      });
      if (result instanceof Result.Success) {
        this.setState({ loading: false }, () => {
          this.props.setEvidence(result.data.getEvidence());
          toast.success("Evidence uploaded successfully");
          if (this.props.onSuccess) this.props.onSuccess(result.data);
        });
      } else {
        this.setState({ loading: false }, () => {
          const message = result.message || "Something went wrong";
          toast.error(message);
        });
      }
    });
  };

  render() {
    return (
      <Form loading={this.state.loading}>
        <Form.Input
          multiple
          type="file"
          accept="image/*, .pdf, .doc, .docx, .csv, .xls, .xlsx"
          label="Evidence"
          onChange={(e) => {
            const files = e.target.files;
            if (files !== null) {
              const f: File[] = [];
              for (let i = 0; i < files.length; i++) f.push(files[i]);
              this.setState({ evidence: f });
            } else {
              this.setState({ evidence: [] });
            }
          }}
        />

        <Button
          disabled={this.state.evidence.length === 0 || this.state.loading}
          primary
          onClick={this.uploadEvidence}
        >
          Submit
        </Button>
      </Form>
    );
  }
}

export default UploadEvidenceForm;
