import DomainModel from '../DomainModel'

export default class InsuredDetail extends DomainModel {
    constructor(
        private commonData: {
            alternativePhone: string
            contactNumber: string
            dob: string
            email: string
            name: string
        },
        private dataWithDate: any[],
        private claimsAsAttendent: number,
        private claimsAsInsured: number,
        private repudatedAttendent: number,
        private repudatedInsured: number,
        private feedback: string,
        private feedback_id: string
    ) {
        super()
    }
    getCommonData = () => this.commonData
    getDataWithDate = () => this.dataWithDate
    getClaimsAsAttendent = () => this.claimsAsAttendent
    getClaimsAsInsured = () => this.claimsAsInsured
    getRepudatedAttendent = () => this.repudatedAttendent
    getRepudatedInsured = () => this.repudatedInsured
    getFeedBack = () => this.feedback
    getFeedBackId = () => this.feedback_id
}
