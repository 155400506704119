import React, { useState, useEffect } from "react";
import { Form, Dropdown, Popup, Button, Input } from "semantic-ui-react";
import FormLabel from "../../common/FormLabel";
import Result from "../../../common/repository/Result";
import { toast } from "../../common/Toast";
import CaseFormButtonText from "./CaseFormButtonText";
import ClientRepository from "../../../common/repository/ClientRepository";
import Client from "../../../models/Client";

/**
 * Render this component inside a Form
 */

interface CaseFormInputInsuranceCompanyProps {
  insuranceCompany: string;
  onInsuranceCompanyChange(value: string): void;
  insuranceCompanyName: string;
  onInsuranceCompanyNameChange(value: string): void;
  clientRepo: ClientRepository;
  isDisabled: boolean;

  insuranceCompanies?: Client[];
  labelWidth?: string;
}

const CaseFormInputInsuranceCompany = (
  props: CaseFormInputInsuranceCompanyProps
) => {
  const {
    insuranceCompany,
    onInsuranceCompanyChange,
    insuranceCompanyName,
    onInsuranceCompanyNameChange,
    clientRepo,
    insuranceCompanies,
    labelWidth,
    isDisabled,
  } = props;
  const [renderNameInput, setRenderNameInput] = useState(isDisabled);

  // console.log("disable check in company : ",renderNameInput);

  useEffect(() => {
    if (renderNameInput === false && insuranceCompanyName.trim().length > 0)
      setRenderNameInput(true);
  }, [insuranceCompanyName]);

  return (
    <React.Fragment>
      <InsuranceCompanySelect
        labelWidth={labelWidth}
        value={insuranceCompany}
        onChange={onInsuranceCompanyChange}
        disable={renderNameInput}
        onOtherClick={() => {
          setRenderNameInput(true);
          onInsuranceCompanyChange("");
        }}
        clientRepo={clientRepo}
        insuranceCompanies={insuranceCompanies}
      />

      {renderNameInput && (
        <InsuranceCompanyNameInput
          labelWidth={labelWidth}
          value={insuranceCompanyName}
          onChange={onInsuranceCompanyNameChange}
          onRemoveClick={() => {
            setRenderNameInput(false);
            onInsuranceCompanyNameChange("");
          }}
        />
      )}
    </React.Fragment>
  );
};

interface InsuranceCompanySelectProps {
  value: string;
  clientRepo: ClientRepository;
  onChange(value: string): void;
  onOtherClick(): void;

  disable?: boolean;
  insuranceCompanies?: Client[];
  labelWidth?: string;
}

const InsuranceCompanySelect = (props: InsuranceCompanySelectProps) => {
  const {
    value,
    clientRepo,
    onChange,
    onOtherClick,
    disable,
    insuranceCompanies,
    labelWidth,
  } = props;

  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState(
    insuranceCompanies &&
      insuranceCompanies.map((it) => {
        return { text: it.getName(), value: it.getId(), key: it.getId() };
      })
  );

  useEffect(() => {
    if (insuranceCompanies !== undefined) return;

    setLoading(true);
    (async () => {
      const result = await clientRepo.getCompanies();
      if (result instanceof Result.Success) {
        const items = result.data.items;
        setOptions(
          items.map((it) => {
            return { text: it.getName(), value: it.getId(), key: it.getId() };
          })
        );
      } else {
        const message = result.message || "Could not load Insurance Companies";
        toast.error(message);
      }
      setLoading(false);
    })();
  }, []);

  const width = labelWidth || "120px";
  return (
    <Form.Group inline>
      <Form.Field required disabled={disable}>
        <FormLabel width={width}>Insurance Company</FormLabel>
        <Dropdown
          search
          selection
          placeholder="Select Insurance Company"
          loading={loading}
          options={options}
          value={value}
          onChange={(_, { value }) => onChange(value as string)}
        />
      </Form.Field>
      <Form.Field>
        <CaseFormButtonText onClick={onOtherClick}>Other</CaseFormButtonText>
      </Form.Field>
    </Form.Group>
  );
};

interface InsuranceCompanyNameInputProps {
  value: string;
  onChange(value: string): void;
  onRemoveClick(): void;

  labelWidth?: string;
}

const InsuranceCompanyNameInput = (props: InsuranceCompanyNameInputProps) => {
  return (
    <Form.Group inline>
      <Form.Field required>
        <FormLabel width={props.labelWidth || "120px"}>
          Insurance Company Name
        </FormLabel>
        <Input
          placeholder="Enter Company Name"
          value={props.value}
          onChange={(_, { value }) => props.onChange(value)}
        />
      </Form.Field>
      <Form.Field>
        <Popup
          position="top center"
          inverted
          content="Remove"
          trigger={
            <Button size="mini" icon="x" onClick={props.onRemoveClick} />
          }
        ></Popup>
      </Form.Field>
    </Form.Group>
  );
};

export default React.memo(CaseFormInputInsuranceCompany);
