import DomainModel from './DomainModel'
import User from './User'

class Reminder extends DomainModel {
  constructor(
    private id: string,
    private text: string,
    private madeBy: User,
    private madeOn: string,
    private validUpto: string,
    private remainingTime: number,
    private active: boolean,
    private closedBy: User | null,
    private closedOn: string | null
  ) {
    super()
  }

  getId = () => this.id
  getText = () => this.text
  getMadeBy = () => this.madeBy
  getMadeOn = () => this.madeOn
  getValidUpto = () => this.validUpto
  getRemainingTime = () => this.remainingTime
  getActive = () => this.active
  getClosedBy = () => this.closedBy
  getClosedOn = () => this.closedOn
}

export default Reminder
