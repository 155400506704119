import React from "react";
import { useSelector } from "react-redux";
import { AppState } from "../../redux";
import { Route, Redirect, RouteProps, useLocation } from "react-router-dom";

const PrivateRoute = ({ component: Component, ...rest }: RouteProps) => {
  const authState = useSelector((state: AppState) => state.auth);
  const location = useLocation();
  if (authState.user !== null) return <Route component={Component} {...rest} />;
  else
    return <Redirect to={{ pathname: "/login", state: location.pathname }} />;
};

export default PrivateRoute;
