
import DomainModel from './DomainModel'

export default class LodgingExpenses extends DomainModel {
    //  public  x: any; 
    constructor(
        private _id: string,
        private amount: string,
        private appliedDate: string,
        private investigator_name: string,
        private location: string,
        private notes: string,
        private receipt: [],
        private rejectedBy: string,
        private status: string,
        private type: string,
        private updatedAt: string,
    ) {
        super()
    }

    getId = () => this._id
    getAmount = () => this.amount
    getAppliedDate = () => this.appliedDate
    getInvestigator_name = () => this.investigator_name
    getLocation = () => this.location
    getNotes = () => this.notes
    getReceipt = () => this.receipt
    getRejectedBy = () => this.rejectedBy
    getStatus = () => this.status
    getType = () => this.type
    getUpdatedAt = () => this.updatedAt

}
