import ProtectedAPIRepository from './ProtectedAPIRepository'
import SettingsRM from '../responsemodel/SettingsRM'
import Result from './Result'
import SettingsDataMapper from '../datamapper/SettingsDataMapper'
import APIResponseModel from './APIResponseModel'

class SettingsRepository extends ProtectedAPIRepository {
  private dataMapper = new SettingsDataMapper()

  getSettings = async () => {
    const response = await this.post<APIResponseModel<SettingsRM>>('/constants')
    if (response instanceof Result.Success) {
      const data: APIResponseModel<SettingsRM> = response.data
      if (data.data !== null) {
        const settings = this.dataMapper.map(data.data.items[0])
        return new Result.Success(settings)
      } else {
        return new Result.Error(0, 'Data is null')
      }
    } else {
      return response
    }
  }

  updateSettings = async (ro: UpdateSettingsRequestObject) => {
    const response = await this.patch<APIResponseModel<SettingsRM>>('/constants', ro)
    if (response instanceof Result.Success) {
      const data: APIResponseModel<SettingsRM> = response.data
      if (data.data !== null) {
        const settings = this.dataMapper.map(data.data.items[0])
        return new Result.Success(settings)
      } else {
        return new Result.Error(0, 'Data is null')
      }
    } else {
      return response
    }
  }
}

export default SettingsRepository

export interface UpdateSettingsRequestObject {
  id: string
  leaves?: {
    paid_leaves?: number
    short_leave_wage?: number
    accumulation?: number
    accumulation_start?: number
  }
  salary?: {
    basic?: {
      hra?: number
      mi_allowance?: number
      bonus?: number
    }
    investigator?: {
      extra_case_fee?: number
      city_expense?: number
      conveyance_allowance?: number
      travel_allowance?: {
        primary?: number
        secondary?: number
        tertiary?: number
      }
    }
  }
  incentive?: {
    investigator?: {
      sale_report?: number[]
      tat_average?: number[]
      case_target?: number[]
      no_leave?: number
      no_red_mark?: number
      incentive?: number[]
    }
    claim_analyst?: {
      grade_average?: number[]
      case_closed?: number[]
      no_leave?: number
      no_red_mark?: number
      incentive?: number[]
    }
    team_lead?: {
      grade_average?: number[]
      case_closed?: number[]
      no_leave?: number
      no_red_mark?: number
      incentive?: number[]
    }
  }
  claim_size?: {
    document_size: number
    evidence_size_LTI: number
    evidence_size_HTI: number
    evidence_size_DVI: number
  }
}
