import React from 'react'
import Page from '../../../layout/Page'
import { Modal, Button, DropdownItemProps, Segment } from 'semantic-ui-react'
import Result from '../../../../common/repository/Result'
import { toast } from '../../../common/Toast'
import Table from '../../../app/Table'
import RequirePermission from '../../../base/RequirePermission'
import permissions from '../../../../common/permissions'
import Filters from './Filters'
import FilterPageContext from './FilterPageContext'
import LodgingExpensesForm from "./LodgingExpensesForm"
import config from '../../../../common/repository/config'
import LodgingExpensesRepository, { GetLodgingExpensesObject } from '../../../../common/repository/LodgingExpensesRepository'
import LodgingExpenses from '../../../../models/LodgingExpenses'
import LodgingExpensesColumnRenderer from "./LodgingExpensesColumnRenderer"

const statusData = ["Pending", "Accepted", "Rejected"]
const statusDatas = statusData.map((it) => {
    return { text: it, value: it, key: it };
});

interface State {
    loading: boolean
    filter: string
    limit: number
    offset: number
    totalCount: number
    lodgingExpenses: LodgingExpenses[]
    update_modalOpen: boolean
    update_model: LodgingExpenses | null

    receipt_modalOpen: boolean
    receipt_model: LodgingExpenses | null

    remove_modalOpen: boolean
    remove_model: LodgingExpenses | null
    loadingEssentialData: boolean
    statusFilter: string
    statusOptions: DropdownItemProps[]
}
interface Props {
    ismodel: LodgingExpenses;
}

class LodgingExpensesPage extends React.Component<Props, State> {

    private LodgingExpensesRepository = new LodgingExpensesRepository()

    private columnRenderer = new LodgingExpensesColumnRenderer(
        (model: LodgingExpenses) => {
            return (<>
                <Button size="mini" onClick={() => this.setState({ receipt_modalOpen: true, receipt_model: model })}>View</Button>
                {model.getStatus() == "Pending" ? <Button.Group size="mini">
                    <Button onClick={() => this.setState({ update_modalOpen: true, update_model: model })}>Approved</Button>
                    <Button negative onClick={() => this.setState({ remove_modalOpen: true, remove_model: model })}>Decline</Button>
                </Button.Group> :
                    (model.getStatus() == "Accepted" ? <Button color="green">{model.getStatus()} </Button> : <Button color="red">{model.getStatus()}</Button>)}
            </>)
        },
        (obj: any) => true
    )

    constructor(props: any) {
        super(props)

        this.state = {
            loading: false,
            limit: 10,
            offset: 0,
            filter: '',
            totalCount: 0,
            lodgingExpenses: [],
            update_modalOpen: false,
            update_model: null,
            remove_modalOpen: false,
            remove_model: null,
            receipt_modalOpen: false,
            receipt_model: null,
            loadingEssentialData: true,
            statusFilter: '',
            statusOptions: statusDatas,
        }
    }

    async componentDidMount() {
        this.loadData()
        // await this.effectLoadEssentialData()
    }
    private onRowsPerPageChange = (page: number, rowsPerPage: number) => {
        // console.log('onRowsPerPageChange', page, rowsPerPage)
        const offset = (page - 1) * rowsPerPage,
            limit = offset + rowsPerPage
        this.setState({ offset, limit }, this.loadData)
    }

    private setFilter = (filter: string) => {
        this.setState({ filter, offset: 0 }, this.loadData)
    }

    private clearAllFilters = async () => {
        await this.setState({
            limit: 0,
            offset: 0,
            statusFilter: '',
        })
        await this.loadData()
    }

    private getLodgingExpenses = async () => {
        const ro: GetLodgingExpensesObject = {
            limit: this.state.limit,
            offset: this.state.offset,
            search: this.state.filter,
        }

        if (this.state.statusFilter) ro.status = this.state.statusFilter

        return await this.LodgingExpensesRepository.getLodgingExpenses(ro)
    }

    private loadData = async () => {
        if (this.state.statusFilter) {
            await this.setState({ offset: 0 })
        }
        let offset = this.state.offset

        this.setState({ loading: true }, async () => {
            const result = await this.getLodgingExpenses()
            if (result instanceof Result.Success) {
                if (offset === 0) {
                    //if offset 0 initialize as new list
                    const lodgingExpenses = result.data.items
                    const totalCount = result.data.totalItemCount
                    await this.setState({
                        loading: false,
                        offset: lodgingExpenses.length,
                        lodgingExpenses,
                        totalCount: totalCount,
                    })
                } else {
                    //else check for duplicate items in the new data
                    // and then concat with the old
                    const lodgingExpenses = [...this.state.lodgingExpenses, ...result.data.items]
                    const offset = lodgingExpenses.length
                    this.setState({ loading: false, offset, lodgingExpenses })
                }
            } else {
                const message = result.message || 'Something went wrong'
                await this.setState({ loading: false }, () => toast.error(message))
            }
        })
    }

    private UpdateLodgingExpenses = (state: LodgingExpenses) => {
        const lodgingExpenses = this.state.lodgingExpenses
        const index = lodgingExpenses.findIndex((it) => it.getId() === state.getId())
        let totalCount = this.state.totalCount
        // if (index !== -1) lodgingExpenses[index] = state
        if (index !== -1) {
            lodgingExpenses.splice(index, 1)
            totalCount--
        }
        this.setState({ lodgingExpenses: [...lodgingExpenses], totalCount, update_modalOpen: false, update_model: null, remove_modalOpen: false, remove_model: null })
        this.loadData()
    }

    private setStatsFilter = (val: string) => this.setState({ statusFilter: val })

    render() {
        return (
            <FilterPageContext.Provider
                value={{
                    options: {
                        statusData: this.state.statusOptions,
                    },
                    filters: {
                        status: this.state.statusFilter,
                        setStatus: this.setStatsFilter,
                        applyFilters: this.loadData,
                        clearAllFilters: this.clearAllFilters,
                    },
                }}
            >
                <Page title="Lodging Expenses" description="Manage Lodging Expenses">

                    <Segment>
                        <Filters />
                    </Segment>

                    <Modal open={this.state.receipt_modalOpen} onClose={() => this.setState({ receipt_modalOpen: false, receipt_model: null })} size="mini" closeIcon closeOnDimmerClick={false} closeOnEscape={false}>
                        <Modal.Header>View Recipt's</Modal.Header>
                        <Modal.Content>
                            <Segment>
                                {this.state.receipt_model?.getReceipt().map((item) =>
                                    <a className={"ui olive button"}
                                        href={`${config.mrdRecipt}${item}`}
                                        target={"_blank"}
                                    >
                                        View
                                    </a>
                                )}
                            </Segment>
                        </Modal.Content>
                    </Modal>

                    {/* Approve Modal */}
                    <Modal open={this.state.update_modalOpen} onClose={() => this.setState({ update_modalOpen: false, update_model: null })} size="mini" closeIcon closeOnDimmerClick={false} closeOnEscape={false}>
                        <Modal.Header>Confirmation</Modal.Header>
                        <Modal.Content>
                            <LodgingExpensesForm model={this.state.update_model || undefined} onSuccess={this.UpdateLodgingExpenses} status={"Approvel"} />
                        </Modal.Content>
                    </Modal>
                    {/* Approve Modal */}

                    {/* Decline */}
                    <Modal open={this.state.remove_modalOpen} onClose={() => this.setState({ remove_modalOpen: false, remove_model: null })} size="mini" closeIcon closeOnDimmerClick={false} closeOnEscape={false}>
                        <Modal.Header>Confirmation</Modal.Header>
                        <Modal.Content>
                            <LodgingExpensesForm model={this.state.remove_model || undefined} onSuccess={this.UpdateLodgingExpenses} status={"Decline"} />
                        </Modal.Content>
                    </Modal>
                    {/* Decline */}
                    <Table
                        renderer={this.columnRenderer}
                        data={this.state.lodgingExpenses}
                        totalDataCount={this.state.totalCount}
                        loading={this.state.loading}
                        load={this.loadData}
                        onRowsPerPageChange={this.onRowsPerPageChange}
                        onSearch={this.setFilter}
                        onClear={() => this.setFilter('')}
                    />
                </Page>
            </FilterPageContext.Provider>
        )
    }
}

export default (props: any) => {
    return (
        <RequirePermission permission={permissions.Master.CRUD}>
            <LodgingExpensesPage {...props} />
        </RequirePermission>
    )
}
