import DomainModel from './../DomainModel'

class TreatingDoctorsData extends DomainModel {
    constructor(
        private contactNumber: string,
        private doctorAvailableInHospital: string,
        private doctorConfirmation: string,
        private hospitalData: { name: string },
        private investigatorName: string,
        private isFieldSaved: string,
        private personalMeeting: string,
        private personalMeetingPic: string,
        private qualification: string,
        private registrationCertificate: string,
        private registrationNumber: string,
        private treatingDoctorName: string,
    ) {
        super()
    }
    getContactNumber = () => this.contactNumber
    getDoctorAvailableInHospital = () => this.doctorAvailableInHospital
    getDoctorConfirmation = () => this.doctorConfirmation
    getHospitalData = () => this.hospitalData
    getInvestigatorName = () => this.investigatorName
    getIsFieldSaved = () => this.isFieldSaved
    getPersonalMeeting = () => this.personalMeeting
    getPersonalMeetingPic = () => this.personalMeetingPic
    getQualification = () => this.qualification
    getRegistrationCertificate = () => this.registrationCertificate
    getRegistrationNumber = () => this.registrationNumber
    getTreatingDoctorName = () => this.treatingDoctorName





}
export default TreatingDoctorsData
