import User from '../../models/User'
import { SET_AUTH, AUTH_STATE_INITIALIZING } from './types'

export const setAuth = (user: User | null) => {
  return {
    type: SET_AUTH,
    payload: user,
  }
}

export const initializingAuthState = () => {
  return {
    type: AUTH_STATE_INITIALIZING,
  }
}
