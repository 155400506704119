import React, { Component } from "react";
import { Segment, TextArea, Form, Button, Header } from "semantic-ui-react";
import CompleteCase from "../../../../../models/CompleteCase";
import RemarkListItem from "./RemarkListIem";
import styled from "styled-components";
import CaseRepository from "../../../../../common/repository/CaseRepository";
import constants from "../../../../../common/constants";
import Result from "../../../../../common/repository/Result";
import { toast } from "../../../../common/Toast";

const ChildSpacing = styled.div`
  & > div {
    margin: 0 16px 8px 16px;
  }
`;

interface State {
  loading: boolean;
  remark: string;
}

interface Props {
  model: CompleteCase;
  updateModel?(model: CompleteCase): void;
}

class Remarks extends Component<Props, State> {
  private caseRepo = new CaseRepository();

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      remark: "",
    };
  }

  addRemark = () => {
    this.setState({ loading: true }, async () => {
      const { model, updateModel } = this.props;
      const result = await this.caseRepo.addRemark({
        case_id: model.getId(),
        remark: this.state.remark,
      });
      if (result instanceof Result.Success) {
        const c = result.data;
        this.setState({ loading: false, remark: "" }, () => {
          toast.success("Remark added successfully");
          if (updateModel) updateModel(c as CompleteCase);
        });
      } else {
        this.setState({ loading: false }, () => {
          const message = result.message || "Something went wrong";
          toast.error(message);
        });
      }
    });
  };

  render() {
    const { model } = this.props;

    return (
      <Segment basic loading={this.state.loading} style={{ padding: 0 }}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div style={{ flex: 1, marginRight: 8 }}>
            <Form>
              <Header as="h4">Add New Remark</Header>
              <TextArea
                value={this.state.remark}
                onChange={(_, { value }) =>
                  this.setState({ remark: value as string })
                }
              />
              <Button
                style={{ width: "100%", marginTop: 16 }}
                loading={this.state.loading}
                disabled={
                  this.state.loading || this.state.remark.trim().length === 0
                }
                onClick={this.addRemark}
                positive
              >
                Submit
              </Button>
            </Form>
          </div>

          <div style={{ flex: 2 }}>
            <div style={{ height: 300, overflowY: "scroll" }}>
              <ChildSpacing>
                {model.getRemarks().map((it) => (
                  <RemarkListItem model={it} />
                ))}
              </ChildSpacing>
            </div>
          </div>
        </div>
      </Segment>
    );
  }
}

export default Remarks;
