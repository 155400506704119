import React from "react";
import Page from "../../../layout/Page";
import CaseForm from "../CaseForm";
import Case from "../../../../models/Case";
import { RouteComponentProps } from "react-router-dom";
import { Segment } from "semantic-ui-react";
import CaseRepository from "../../../../common/repository/CaseRepository";
import Result from "../../../../common/repository/Result";
import { toast } from "../../../common/Toast";
import CompleteCase from "../../../../models/CompleteCase";
import permissions from "../../../../common/permissions";
import RequirePermission from "../../../base/RequirePermission";

interface MatchParams {
  caseId?: string;
}
interface Props extends RouteComponentProps<MatchParams> { }
interface State {
  loading: boolean;
  model: Case | null;
}

/**
 * When generating existing case send CategoryCode in location.state
 * so if there is an error while retrieving case user can be redirected back to correct case listing
 */
class GenerateCasePage extends React.Component<Props, State> {
  private caseRepo = new CaseRepository();

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      model: null,
    };
  }

  componentDidMount() {
    const caseId = this.props.match.params.caseId;
    if (caseId !== undefined) this.getCase(caseId);
  }

  private getCase = (caseId: string) => {
    this.setState({ loading: true }, async () => {
      const result = await this.caseRepo.getCases({ id: caseId });
      
      if (result instanceof Result.Success) {
        if (!result.data.items.length) {
          this.setState({ loading: false });
          this.props.history.push("/");
        }
        const c = result.data.items[0];
        this.setState({ loading: false, model: c });
      } else {
        // console.log("1")
        const message = result.message || "Something went wrong";
        toast.error(message);
        const categoryCode = this.props.location.state || "LTI";
        this.props.history.push(`/case/list?category=${categoryCode}`);
      }
    });
  };

  private onSuccess = (c: Case) => {
    // console.log("2")
    let redirectPath = "/case/list";
    if (c instanceof CompleteCase) {
      // console.log("3")
      const categoryCode = c.getCategory().getCode();
      const state = c.getState();
      redirectPath = "/"
      // redirectPath = redirectPath + `?category=${categoryCode}&state=${state}`;
    }
    this.props.history.push(redirectPath);
  };

  render() {
    return (
      <Page
        title="Case Generation"
        description={`
                    Once case is generated it will show up in Claim Analyst's and above Authorities Case List based
                    on location. Now they can allocate the case to the Investigators.
                `}
      >
        <Segment basic loading={this.state.loading}>
          <CaseForm
            model={this.state.model || undefined}
            onSuccess={this.onSuccess}
            props={this.props}
          />
        </Segment>
      </Page>
    );
  }
}

export default (props: any) => {
  return (
    <RequirePermission permission={permissions.Case.generate}>
      <GenerateCasePage {...props} />
    </RequirePermission>
  );
};
