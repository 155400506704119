import React, { useEffect, useState } from "react";
import { Form, Input, Popup, Button } from "semantic-ui-react";
import FormLabel from "../../common/FormLabel";
import CaseRepository from "../../../common/repository/CaseRepository";
import Result from "../../../common/repository/Result";
import { toast } from "../../common/Toast";

/**
 * Render this Component inside a <Form />
 */

interface CaseFormInputClaimNumberProps {
  value: string;
  onChange(value: string): void;

  /**
   * Will be called if claim number already exists.
   * After claim number is changed, a request is fired off to check
   * if the claim number already exists or not
   */
  onError(): void;

  /**
   * caseRepository is need to make the request
   * to check the claim number
   */
  caseRepository: CaseRepository;

  labelWidth?: string;

  /**
   * when a case is edited then claimNumber will exist. Then
   * we should also match the ids
   */
  caseId?: string;
}

const CaseFormInputClaimNumber = (props: CaseFormInputClaimNumberProps) => {
  const [checkingClaimNumber, setCheckingClaimNumber] = useState(false);
  const [error, setError] = useState(false);

  /**
   * it will get fired whenever props.value changes
   */
  useEffect(() => {
    if (props.value.trim().length === 0) return;

    setCheckingClaimNumber(true);

    (async () => {
      const result = await props.caseRepository.getCases({
        claim_number: props.value,
        exactMatch: true,
        checkForGeneration:true
      });
      if (result instanceof Result.Success) {
        if (result.data.totalItemCount > 0) {
          const c = result.data.items[0];
          if (props.caseId !== undefined && props.caseId === c.getId()) {
            setCheckingClaimNumber(false);
            return;
          }

          props.onError();
          setError(true);
        }
      } else {
        toast.error("Could not verify Claim Number");
      }
      setCheckingClaimNumber(false);
    })();
  }, [props.value, props.caseId]);

  return (
    <Form.Group inline>
      <Form.Field required error={error}>
        <FormLabel width={props.labelWidth || "100px"}>Claim Number</FormLabel>
        <Input
          loading={checkingClaimNumber}
          placeholder="Paste Claim Number"
          value={props.value}
          onPaste={(e: React.ClipboardEvent<HTMLInputElement>) => {
            setError(false);
            props.onChange(e.clipboardData.getData("Text"));
          }}
        />
      </Form.Field>
      <Form.Field>
        <Popup
          inverted
          content="Clear Field"
          position="top center"
          trigger={
            <Button
              size="mini"
              icon="erase"
              onClick={() => {
                props.onChange("");
                setError(false);
              }}
            />
          }
        />
      </Form.Field>
      {error && (
        <span style={{ color: "red" }}>Claim Number already exists</span>
      )}
    </Form.Group>
  );
};

export default React.memo(CaseFormInputClaimNumber);
