import React from 'react'
import Page from '../../layout/Page'
import UserForm from './UserForm'

const Layout = () => {
    return (
        <Page
            title='Update Profile'>
            <UserForm />
        </Page>
    )
}

export default Layout