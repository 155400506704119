import DataMapper from './DataMapper'
import UserWarning from '../../models/UserWarning'
import UserWarningRM from '../responsemodel/UserWarningRM'
import UserDataMapper from './UserDataMapper'

export default class UserWarningDataMapper extends DataMapper<UserWarningRM, UserWarning> {
  private userDataMapper = new UserDataMapper()

  map = (rm: UserWarningRM) => {
    return new UserWarning(rm.id, this.userDataMapper.map(rm.user), rm.warning, rm.date)
  }
}
