import ActionColumnRenderer from '../../../app/Table/renderer/ActionColumnRenderer'
import PaymentSection from '../../../../models/PaymentSection'
import DateTime from '../../../../lib/DateTime'

export default class PaymentSectionColumnRenderer extends ActionColumnRenderer<PaymentSection> {
  getColumns() {
    return [
      {
        name: 'Claim Number',
        selector: 'claimNumber',
      },
      {
        name: 'Client Name',
        selector: 'clientName',
      },
      {
        name: 'Case Type',
        selector: 'caseTypeTitle',
      },
      {
        name: 'Invoice Number',
        selector: 'invoice',
        format: (row: PaymentSection) => {
          if (row.getInvoice() == null) return 'NA'
          return row.getInvoice()!.getInvoiceNumber()
        },
      },
      {
        name: 'Invoice Date',
        selector: 'invoice',
        format: (row: PaymentSection) => {
          if (row.getInvoice() == null) return 'NA'
          return row.getInvoice()!.getInvoiceDate()
        },
      },
      {
        name: 'Invoice Amount',
        selector: 'invoice',
        format: (row: PaymentSection) => {
          if (row.getInvoice() == null) return 'NA'
          return row.getInvoice()?.getInvoiceAmount()
        },
      },
      {
        name: 'TDS',
        selector: 'invoice',
        format: (row: PaymentSection) => {
          if (row.getPayment() == null) return 'NA'
          return row.getPayment()!.getTds()
        },
      },
      {
        name: 'Due Amount',
        selector: 'invoice',
        format: (row: PaymentSection) => {
          if (row.getPayment() == null) return 'NA'
          //((invoice amount - gst) - TDS% ) + gst
          let invoiceAmount = row.getInvoice()!.getInvoiceAmount()
          let gstAmount = row.getInvoice()!.getGst()

          let dueAmount = invoiceAmount - gstAmount - Math.floor(((invoiceAmount - gstAmount) * row.getPayment()!.getTds()) / 100) + gstAmount
          return dueAmount
        },
      },
      {
        name: 'Received Amount',
        selector: 'invoice',
        format: (row: PaymentSection) => {
          if (row.getPayment() == null) return 'NA'
          return row.getPayment()!.getReceivedAmount()
        },
      },
      {
        name: 'Payment Date',
        selector: 'invoice',
        format: (row: PaymentSection) => {
          if (row.getPayment() == null) return 'NA'
          return DateTime.format('DD/MM/YYYY', new Date(row.getPayment()!.getPaymentDate()))
        },
      },
      {
        name: 'UTR Number',
        selector: 'invoice',
        format: (row: PaymentSection) => {
          if (row.getPayment() == null) return 'NA'
          return row.getPayment()!.getUTRNo()
        },
      },
      {
        name: 'Settled',
        selector: 'invoice',
        format: (row: PaymentSection) => {
          if (row.getPayment() == null) return 'NA'
          return row.getPayment()!.getSettledStatus() == true ? 'Yes' : 'No'
        },
      },
      {
        name: 'Status',
        selector: 'invoice',
        format: (row: PaymentSection) => {
          if (row.getPayment() == null) return 'pending'
          return row.getPayment()!.getPaymentStatus()
        },
      },

      {
        name: 'Receipt-||-Action',
        selector: 'id',
        cell: this.renderActionColumn,
      },
    ]
  }
}
