import React from 'react'
// import { DropdownItemProps } from 'semantic-ui-react'

interface ContextType {
    filters: Filters
}

// interface Options {
//     statusData: DropdownItemProps[]
// }

interface Filters {
    from: string
    setFrom(from: string): void
    applyFilters(): void
    clearAllFilters(): void
}

const Context = React.createContext({} as ContextType)
export default Context
