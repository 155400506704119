import React from 'react'
import Settings from '../../../models/Settings'
import SettingsRepository from '../../../common/repository/SettingsRepository'

interface ContextType {
  settingsRepo: SettingsRepository
  isLoading: boolean
  setIsLoading(isLoading: boolean): void
  isUpdating: boolean
  setIsUpdating(isUpdating: boolean): void
  settings: Settings | null
  setSettings(settings: Settings): void
}

const SettingsPageContext = React.createContext({} as ContextType)
export default SettingsPageContext
