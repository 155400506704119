import DataMapper from './DataMapper'
import ClaimTypeDataMapper from './ClaimTypeDataMapper'
import UserDataMapper from './UserDataMapper'
import URLResource from '../../models/URLResource'
import ClientRM from '../responsemodel/ClientRM'
import Client from '../../models/Client'
import BranchDataMapper from './BranchDataMapper'

class ClientDataMapper extends DataMapper<ClientRM, Client> {
  private userDataMapper = new UserDataMapper()
  private claimTypeDataMapper = new ClaimTypeDataMapper()
  private branchDataMapper = new BranchDataMapper()

  map = (rm: ClientRM) => {
    let mouDocument = null
    if (rm.mou_document !== null) mouDocument = new URLResource(rm.mou_document)

    let handler = this.userDataMapper.map(rm.handler)
    let claimTypes = rm.claim_types.map((it) => this.claimTypeDataMapper.map(it))
    let branches = rm.branches.map((it) => this.branchDataMapper.map(it))

    return new Client(rm.id, rm.name, rm.type, handler, rm.mou_expiry, mouDocument, claimTypes, branches)
  }
}

export default ClientDataMapper
