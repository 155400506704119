import React from "react";
import { Form } from "semantic-ui-react";
import Button from "../../../elements/Button";
import Result from "../../../../common/repository/Result";
import { toast } from "../../../common/Toast";
import AddressChangerequest from "../../../../models/AddressChangeRequest"
import InvestigatoeActivityRepository from '../../../../common/repository/InvestigatorActivityRepository'


interface State {
    form_loading: boolean;
    state_Id: string
}
interface Props {
    model?: AddressChangerequest;
    AddressChange?: AddressChangerequest[];
    onSuccess(AddressChange: AddressChangerequest): void;
    onError?(): void;
    status?: string
}

class AddressChangerequestForm extends React.Component<Props, State> {

    private InvestigatoeActivityRepository = new InvestigatoeActivityRepository()

    constructor(props: Props) {
        super(props);
        let loadStates = true;
        if (props.AddressChange) loadStates = false;

        const stateFromModel = this.AddressChangeFromModel();
        this.state = {
            form_loading: false,
            ...stateFromModel,
        };

    }

    private AddressChangeFromModel = () => {
        const model = this.props.model;
        return {
            state_Id: (model && model.getId()) || "",

        };
    };

    private onSubmit = (status: any) => {
        this.setState({ form_loading: true }, () => {
            if (this.props.model) this.updateState(status);
        });
    };

    private updateState = async (status: any) => {
        const stateId = this.props.model!.getId();
        const result = await this.InvestigatoeActivityRepository.UpdateAddresChange({
            id: stateId,
            isAddressChange: status,
        });
        if (result instanceof Result.Success) {
            const AddresChange = result.data;
            toast.success(`Address Updated ${status} Successfully!`);
            this.props.onSuccess(AddresChange);
        } else {
            const message = result.message || "Something went wrong";
            this.setState({ form_loading: false }, () => {
                toast.error(message);
                if (this.props.onError) this.props.onError();
            });
        }
    };

    render() {

        return (
            <Form loading={this.state.form_loading}>
                <>
                    {this.props.status == "Approvel" ? <p>Are you sure you want to Approve this ?</p> : <p>Are you sure you want to Rejected this?</p>}
                    {this.props.model?.getIsAddressChange() === "Pending" && this.props.status == "Approvel" ? < Button color="green" inverted
                        onClick={() => this.onSubmit("Accepted")}
                    >
                        Approved
                    </Button> :
                        < Button color="red" inverted
                            onClick={() => this.onSubmit("Rejected")}>
                            Decline
                        </Button>}
                    {/* <Button style={{ marginLeft: 4 }}>
                        Cancel
                    </Button> */}
                </>
            </Form>
        );
    }
}

export default AddressChangerequestForm;
