import React, { useState, useEffect, useReducer, useContext } from 'react'
import { Table, Button, Modal as SemanticModal, Form, DropdownItemProps, Icon } from 'semantic-ui-react'
import StateDistrictRepository from '../../../../common/repository/StateDistrictRepository'
import Result from '../../../../common/repository/Result'
import { toast } from '../../../common/Toast'
import State from '../../../../models/State'
import { UpdateBillingAddressRequestObject } from '../../../../common/repository/ClientRepository'
import ClientPageContext from './ClientPageContext'
import BillingLocations from './BillingLocations'


const BillingAddressForm = () => {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [values, setValues] = useState<Value[]>([])
    const [activeValue, setActiveValue] = useState<Value | null>(null)
    const [submitting, setSubmitting] = useState(false)
    const [states, _setStates] = useState<State[]>([])
    const [stateOptions, _setStateOptions] = useState<DropdownItemProps[]>([])
    const ctx = useContext(ClientPageContext)

    useEffect(() => { effectLoadEssentialData() }, [])
    useEffect(effectInitializeState, [ctx.client])
    useEffect(() => { effectSubmitForm() }, [submitting])
    
    const rowComponents = values.map(it => (
        <Row
            activeValue={activeValue}
            value={it} 
            onRemove={removeItemFromValues} 
            onUpdate={updateItemInValues} 
            stateOptions={stateOptions}
            onView={makeValueActive}/>
    ))

    return (
        <>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ flex: 6 }}>
                <Table size='small' compact celled>
                    <TableHeader />
                    <Table.Body>
                        {rowComponents}
                        <EmptyRow onAdd={openModal}/>
                    </Table.Body>
                </Table>

                <Button
                    primary
                    onClick={() => setSubmitting(true)}
                    loading={submitting}
                    disabled={
                        submitting ||
                        values.length === 0
                    }>
                    Submit
                </Button>
            </div>

            <div style={{ flex: 4 }}>
                {activeValue && (
                    <BillingLocations
                        value={activeValue}
                        onChange={onBillingLocationUpdate} />
                )}
            </div>
        </div>
        {isModalOpen && (
            <Modal 
                open={true}
                stateOptions={stateOptions}
                onClose={closeModal} 
                onSubmit={appendDataInValues}/>
        )}
        </>
    )

    async function effectLoadEssentialData() {
        loadStates()
    }

    async function loadStates() {
        const stateDistrictRepo = new StateDistrictRepository()
        const result = await stateDistrictRepo.getStates()
        if (result instanceof Result.Success) {
            const states = result.data.items
            setStates(states)
        } else {
            const message = result.message || 'Could not load states'
            toast.error(message)
        }
    }

    function setStates(states: State[]) {
        _setStates(states)
        _setStateOptions(states.map(mapStateToDropdownItemProp))
    }
    
    function mapStateToDropdownItemProp(state: State) {
        return { text: state.getName(), value: state.getName(), key: state.getCode() } as DropdownItemProps
    }

    function effectInitializeState() {
        if (ctx.client === null)
            return
        const client = ctx.client!
        const branches = client.getBranches()
        const values = branches.map<Value>((it, i) => {
            const locations = it.getLocations().map(it => ({ city: it.city, address: it.address }))
            return {
                _id: i.toString(),
                state: it.getState(),
                gstNumber: it.getGSTNumber(),
                locations: locations
            }
        })
        setValues(values)
    }

    async function effectSubmitForm() {
        if (!submitting)
            return
        const client = ctx.client!
        const repo = ctx.clientRepo
        const ro: UpdateBillingAddressRequestObject = {
            id: client.getId(),
            branches: values.map(it => {
                return { 
                    state: it.state, 
                    gst_number: it.gstNumber,
                    locations: it.locations
                }
            })
        }
        const result = await repo.updateBillingAddress(ro)
        if (result instanceof Result.Success) {
            const client = result.data
            ctx.setClient(client)
            toast.success('Successfully updated Billing Addresses')
        } else {
            const message = result.message || 'Something went wrong'
            toast.error(message)
        }
        setSubmitting(false)
    }

    function openModal() {
         setIsModalOpen(true)
    }

    function closeModal() {
        setIsModalOpen(false)
    }

    function appendDataInValues(data: Omit<Value, '_id'>) {
        const newId = values.length.toString()
        setValues([...values, { ...data, _id: newId, locations: [] }])
    }

    function removeItemFromValues(value: Value) {
        const i = values.findIndex(it => it._id === value._id)
        if (i === -1) return
        values.splice(i, 1)
        setValues([...values])
        if (activeValue && activeValue._id === value._id)
            setActiveValue(null)
    }
    
    function updateItemInValues(value: Value) {
        const i = values.findIndex(it => it._id === value._id)
        if (i === -1) return
        values[i] = value
        setValues([...values])
    }

    function makeValueActive(value: Value) {
        setActiveValue(value)
    }

    function onBillingLocationUpdate(value: Value) {
        const i = values.findIndex(it => it._id === value._id)
        if (i === -1) return
        values[i] = value
        setValues([...values])
    }
}


/** Renders table's header row */
const TableHeader = () => {
    return (
        <Table.Header>
            <Table.Row>
                <Table.HeaderCell>State</Table.HeaderCell>
                <Table.HeaderCell>GST Number</Table.HeaderCell>
                <Table.HeaderCell>Adresses</Table.HeaderCell>
                <Table.HeaderCell>Action</Table.HeaderCell>
            </Table.Row>
        </Table.Header>
    )
}


interface EmptyRowProps {
    onAdd?(): void
}

const EmptyRow = ({ onAdd }: EmptyRowProps) => {
    return (
        <Table.Row>
            <Table.Cell colSpan='4'>
                <Button onClick={onAdd} primary size='tiny'>Add</Button>
            </Table.Cell>
        </Table.Row>
    )
}


interface RowProps {
    activeValue: Value | null
    value: Value
    stateOptions?: DropdownItemProps[]
    onUpdate?(value: Value): void
    onRemove?(value: Value): void
    onView?(value: Value): void
}

const Row = ({
    activeValue,
    value,
    stateOptions,
    onUpdate,
    onRemove,
    onView
}: RowProps) => {
    const [isModalOpen, setIsModalOpen] = useState(false)

    let isActive = activeValue !== null && activeValue._id === value._id
    return (
        <>
        <Table.Row active={isActive}>
            <Table.Cell>{value.state}</Table.Cell>
            <Table.Cell>{value.gstNumber}</Table.Cell>
            <Table.Cell>
                <Button 
                    primary 
                    size='tiny'
                    onClick={() => { if (onView) onView(value) }}>
                    View ({value.locations.length})
                </Button>
            </Table.Cell>
            <Table.Cell>
                <Button icon size='mini' onClick={openModal}>
                    <Icon name='edit' />
                </Button>
                <Button icon negative size='mini' onClick={() => {
                    if (onRemove) onRemove(value)
                }}>
                    <Icon name='x' />
                </Button>
            </Table.Cell>
        </Table.Row>
        { isModalOpen && (
            <Modal 
                open={isModalOpen}
                stateOptions={stateOptions}
                value={value} 
                onSubmit={onUpdate} 
                onClose={closeModal} />
        )}
        </>
    )

    function openModal() {
        setIsModalOpen(true)
    }

    function closeModal() {
        setIsModalOpen(false)
    }
}

interface ModalProps {
    open?: boolean
    value?: Value
    stateOptions?: DropdownItemProps[],
    onSubmit?(data: Omit<Value, '_id'>): void
    onClose?(): void
}

const Modal = ({ 
    open,
    value,
    stateOptions,
    onSubmit,
    onClose
}: ModalProps) => {
    const [state, setState] = useState(value && value.state || '')
    const [gstNumber, setGstNumber] = useState(value && value.gstNumber || '')

    return (
        <SemanticModal
            size='mini'
            closeIcon
            closeOnDimmerClick={false}
            closeOnEscape={false}
            onClose={onClose}
            open={open}>
            <SemanticModal.Header>
                {value ? 'Update Billing Address' : 'Add New Billing Address'}
            </SemanticModal.Header>

            <SemanticModal.Content>
                <Form>
                    <Form.Dropdown
                        search
                        selection
                        label='State'
                        placeholder='Select State'
                        options={stateOptions}
                        value={state}
                        onChange={(_, { value }) => setState(value as string)}/>

                    <Form.Input
                        label='GST Number'
                        placeholder='Enter GST Number'
                        value={gstNumber}
                        onChange={(_, { value }) => setGstNumber(value)} />

                    <Button
                        primary
                        onClick={() => {
                            if (onSubmit) {
                                const data: any = { state, gstNumber }
                                if (value) {
                                    data._id = value._id
                                    data.locations = value.locations
                                }
                                onSubmit(data)
                            }

                            if (onClose) {
                                onClose()
                            }
                        }}
                        disabled={
                            state.trim().length === 0 ||
                            gstNumber.trim().length === 0
                        }>
                        Submit
                    </Button>

                </Form>
            </SemanticModal.Content>
        </SemanticModal>
    )
}


interface Value {
    _id: string //unique id to distinguish b/w values
    state: string
    gstNumber: string
    locations: {
        city: string
        address: string
    }[]
}

export default BillingAddressForm