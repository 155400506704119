import ActionColumnRenderer from '../../../app/Table/renderer/ActionColumnRenderer'
import DocDispatchSection from '../../../../models/DocDispatchSection'
import DateTime from '../../../../lib/DateTime'

export default class DocDispatchSectionColumnRenderer extends ActionColumnRenderer<DocDispatchSection> {
  getColumns() {
    return [
      {
        name: 'Claim Number',
        selector: 'claimNumber',
      },
      {
        name: 'Client Name',
        selector: 'clientName',
      },
      {
        name: 'Close Date',
        selector: 'closeDate',
      },
      {
        name: 'Case Type',
        selector: 'caseTypeTitle',
      },

      {
        name: 'Dispatch Date',
        selector: 'dispatch',
        format: (row: DocDispatchSection) => {
          if (row.getDispatch() == null) return 'NA'
          return row.getDispatch()!.getDate()
        },
      },
      {
        name: 'POD slip-||-Action',
        selector: 'id',
        cell: this.renderActionColumn,
      },
    ]
  }
}
