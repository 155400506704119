import ProtectedAPIRepository from './ProtectedAPIRepository'
import APIResponseModel from './APIResponseModel'
import Result from './Result'

import RepudiationGroundDataMapper from '../datamapper/RepudiationGroundDataMapper'
import RepudiationGroundRM from '../responsemodel/RepudiationGroundRM'

export default class RepudiationGroundRepository extends ProtectedAPIRepository {

    private RepudiationGroundDataMapper = new RepudiationGroundDataMapper()

    getRepuditationGround = async (ro?: GetRepuditationGroundRequestObject) => {
        const response = await this.post<APIResponseModel<RepudiationGroundRM>>('/repudiation_ground', ro)
        if (response instanceof Result.Success) {
            const data: APIResponseModel<RepudiationGroundRM> = response.data
            if (data.data !== null) {
                const repuditationGround = data.data.items.map((it) => this.RepudiationGroundDataMapper.map(it))
                return new Result.Success({
                    totalItemCount: data.data.total_item_count,
                    items: repuditationGround,
                })
            } else {
                return new Result.Error(0, 'Data is null')
            }
        } else {
            return response
        }
    }

    addRepudiationGround = async (ro: AddRepudiationGroundRequestObject) => {
        const response = await this.put<APIResponseModel<RepudiationGroundRM>>('/repudiation_ground', ro)
        if (response instanceof Result.Success) {
            const data: APIResponseModel<RepudiationGroundRM> = response.data
            if (data.data !== null) {
                const repuditationGround = this.RepudiationGroundDataMapper.map(data.data.items[0])
                return new Result.Success(repuditationGround)
            } else {
                return new Result.Error(0, 'Data is null')
            }
        } else {
            return response
        }
    }

    updateRepuditationGround = async (ro: UpdateRepuditationGroundRequestObject) => {
        const response = await this.patch<APIResponseModel<RepudiationGroundRM>>('/repudiation_ground', ro)
        if (response instanceof Result.Success) {
            const data: APIResponseModel<RepudiationGroundRM> = response.data
            if (data.data !== null) {
                const repuditationGround = this.RepudiationGroundDataMapper.map(data.data.items[0])
                return new Result.Success(repuditationGround)
            } else {
                return new Result.Error(0, 'Data is null')
            }
        } else {
            return response
        }
    }

    removeRepuditationGround = async (repuditationGroundId: string) => {
        const response = await this.delete<APIResponseModel<null>>(`/repudiation_ground/${repuditationGroundId}`)
        return response
    }
}

export interface GetRepuditationGroundRequestObject {
    search?: string
    limit?: any
    offset?: any
}

export interface AddRepudiationGroundRequestObject {
    repudiation_ground: string
    point: any
}

export interface UpdateRepuditationGroundRequestObject {
    id: string
    repudiation_ground: string
    point: any
}
