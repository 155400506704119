import React, { useState, useContext, useEffect } from "react";
import {
  DropdownItemProps,
  Form,
  Grid,
  Radio,
  Segment,
} from "semantic-ui-react";
import axios from "axios";
import LocalStorage from "../../../../lib/LocalStorage";
import ClientRepository from "../../../../common/repository/ClientRepository";
import Result from "../../../../common/repository/Result";
import { toast } from "../../../common/Toast";
import CaseTypeRepository from "../../../../common/repository/CaseTypeRepository";
import DateTimePicker from "../../../app/DateTimePicker";
import config from "../../../../common/repository/config";

function InvoiceTemplateDownloadForm({ onSuccess }: Props) {
  const [clientOptions, setClientOptions] = useState<DropdownItemProps[]>([]);
  const [caseTypeOptions, setCaseTypeOptions] = useState<DropdownItemProps[]>(
    []
  );
  const [clientId, setClientId] = useState("");
  const [caseTypeId, setCaseTypeId] = useState("");
  const [docReceived, setDocReceived] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const clientRepo = new ClientRepository();
  const caseTypeRepo = new CaseTypeRepository();

  const loadEssentialData = async () => {
    const userData = await clientRepo.getClients({});
    if (userData instanceof Result.Success) {
      setClientOptions(
        userData.data.items.map((it) => ({
          text: it.getName(),
          value: it.getId(),
          key: it.getId(),
        }))
      );
    } else {
      const message = userData.message || "Could not load Clients";
      toast.error(message);
    }

    const caseTypesData = await caseTypeRepo.getCaseTypes({});
    if (caseTypesData instanceof Result.Success) {
      setCaseTypeOptions(
        caseTypesData.data.items.map((it) => ({
          text: it.getTitle(),
          value: it.getId(),
          key: it.getId(),
        }))
      );
    } else {
      const message = caseTypesData.message || "Could not load Case Types";
      toast.error(message);
    }
  };

  useEffect(() => {
    if (clientOptions.length == 0) {
      loadEssentialData();
    }
  }, []);

  return (
    <Form>
      <Form.Dropdown
        label="Choose Client"
        search
        selection
        placeholder="Select Client"
        options={clientOptions}
        value={clientId}
        onChange={(_, { value }) => setClientId(value as string)}
      />
      <Form.Dropdown
        label="Choose Case Type"
        search
        selection
        placeholder="Select Case Type"
        options={caseTypeOptions}
        value={caseTypeId}
        onChange={(_, { value }) => setCaseTypeId(value as string)}
      />

      <Form.Input label="From Date">
        <DateTimePicker
          placeholder="Select From Date"
          type="date"
          value={fromDate}
          onChange={(val) => setFromDate(val)}
        />
      </Form.Input>
      <Form.Input label="To Date" disabled={fromDate.length == 0}>
        <DateTimePicker
          placeholder="Select To Date"
          type="date"
          value={toDate}
          onChange={(val) => setToDate(val)}
        />
      </Form.Input>
      <Segment>
        <Radio
          toggle
          label={"Document Received"}
          checked={docReceived}
          onChange={() => {
            setDocReceived(!docReceived);
          }}
        />
      </Segment>
      <Form.Button
        positive
        disabled={clientId.length == 0}
        onClick={downloadTemplate}
      >
        Download
      </Form.Button>
    </Form>
  );

  async function downloadTemplate() {
    toast.success("Download will start shortly!");
    const token = LocalStorage.get(LocalStorage.KEY_AUTH_TOKEN);
    const response = await axios({
      url: config.apiBaseURL+`/billing/invoice/template`,
      method: "POST",
      data: {
        client_id: clientId,
        docReceived: docReceived,
        case_type_id: caseTypeId,
        from: fromDate.length > 0 ? fromDate : undefined,
        to: toDate.length > 0 ? toDate : undefined,
      },
      responseType: "blob",
      headers: { ["x-access-token"]: token },
    });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "invoiceTemplate.xlsx");
    document.body.appendChild(link);
    link.click();
    link.remove();

    if (onSuccess) onSuccess();
  }
}

interface Props {
  onSuccess?(): void;
}

export default InvoiceTemplateDownloadForm;
