import CaseHistory from '../../../../../models/CaseHistory'

function parser(item: CaseHistory) {
  const summary: { key: string; value: string }[] = []

  for (let i = 0; i < item.getData().length; i++) {
    const change = item.getData()[i]
    if (typeof change === 'object') handleChangeObject(change)
    else continue
  }

  function handleChangeObject(change: any) {
    let key: string = Object.keys(change)[0]
    let value: string
    if (Array.isArray(change[key])) value = handleArrayValue(change[key])
    else if (typeof change[key] === 'object') value = handleObjectValue(change[key])
    else value = change[key]
    key = key.replace(/_/g, ' ')
    key = toPascalCase(key)
    summary.push({ key, value })
  }

  function handleArrayValue(value: any[]) {
    return value.join(', ')
  }

  function handleObjectValue(value: any) {
    if (value.name) return value.name
    return JSON.stringify(value)
  }

  function toPascalCase(str: string) {
    return str.replace(/(\w)(\w*)/g, (g0, g1, g2) => g1.toUpperCase() + g2.toLowerCase())
  }

  return {
    getSummary: function () {
      return summary
    },
    getUser: function () {
      const user = item.getBy()
      return user.name ? user.name : 'Unknown'
    },
    getTimestamp: function () {
      return item.getOn()
    },
    getCaseState: function () {
      return item.getState()
    },
  }
}

export default parser
