import OpenAPIRepository from './OpenAPIRepository'
import APIResponseModel from './APIResponseModel'
import Result from './Result'
import UserDataMapper from '../datamapper/UserDataMapper'

export default class LoginRepository extends OpenAPIRepository {
  private userDataMapper = new UserDataMapper()

  login = async (ro: LoginRequestObject) => {
    const response = await this.post<APIResponseModel<any>>('/login', ro)
    if (response instanceof Result.Success) {
      const data: APIResponseModel<any> = response.data
      if (data.data !== null) {
        const token = data.data.items[0]
        const user = this.userDataMapper.map(data.data.items[1])
        return new Result.Success({ token, user })
      } else {
        return new Result.Error(0, 'Data is null')
      }
    } else {
      return response
    }
  }

  forgotPassword = async (email: string) => {
    const response = await this.post<APIResponseModel<any>>('/user/forgotPassword', { email: email })
    if (response instanceof Result.Success) {
      const data: APIResponseModel<any> = response.data
      return new Result.Success(data.message)
    } else {
      return response
    }
  }
}

export interface LoginRequestObject {
  email: string
  password: string
}
