import React, { useState, useContext, useEffect } from "react";
import { Form, Input } from "semantic-ui-react";
import Result from "../../../../common/repository/Result";
import { toast } from "../../../common/Toast";
import PaymentSectionRepository from "../../../../common/repository/PaymentSectionRepository";

function UploadBulkPaymentReceiptForm({ onSuccess }: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [files, setFiles] = useState<File[]>();
  const paymentRepo = new PaymentSectionRepository();

  useEffect(() => {
    uploadSheet();
  }, [isLoading, files]);

  return (
    <Form loading={isLoading}>
      <Input
        type="file"
        //label='Choose Invoice Receipts'
        accept="image/*, .pdf, .doc, .docx, .csv, .xls, .xlsx"
        multiple
        onChange={(e) => {
          if (e.target.files) {
            const files = e.target.files;
            let fileArr: File[] = [];
            for (let i = 0; i < files.length; i++) fileArr.push(files[i]);
            setFiles(fileArr);
          }
        }}
      />

      <Form.Button
        positive
        disabled={isLoading || !files}
        onClick={() => setIsLoading(true)}
      >
        Submit
      </Form.Button>
    </Form>
  );

  async function uploadSheet() {
    if (!isLoading || !files) return;
    const result = await paymentRepo.uploadPaymentReceipts(files!);

    if (result instanceof Result.Success) {
      if (result.data.items.length == 0)
        toast.success("Receipts uploaded successfully");
      else {
        toast.success(
          "Receipts uploaded successfully. Some Files are skipped!"
        );
      }

      if (onSuccess) onSuccess(result.data.items);
    } else {
      const message = result.message || "Something went wrong";
      toast.error(message);
    }
    setIsLoading(false);
  }
}

interface Props {
  onSuccess?(items: any): any;
}

export default UploadBulkPaymentReceiptForm;
