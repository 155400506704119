import React from 'react'
import { Form, Header, Dropdown, Input, Divider, TextArea, Button, Icon, Popup, DropdownItemProps } from 'semantic-ui-react'
import CaseCategoryRepository from '../../../common/repository/CaseCategoryRepository'
import CaseTypeRepository from '../../../common/repository/CaseTypeRepository'
import TPARepository from '../../../common/repository/TPARepository'
import CompanyRepository from '../../../common/repository/CompanyRepository'
import StateDistrictRepository from '../../../common/repository/StateDistrictRepository'
import StatesDistrictsRepository from '../../../common/repository/StatesDistrictsRepository'
import HospitalRepository, { GetHospitalsRequestObject } from '../../../common/repository/HospitalRepository'
import PolicyTypeRepository from '../../../common/repository/PolicyTypeRepository'
import Result from '../../../common/repository/Result'
import { toast } from '../../common/Toast'
import CaseCategory from '../../../models/CaseCategory'
import CaseType from '../../../models/CaseType'
import constants from '../../../common/constants'
import CaseRepository, { GenerateCaseRequestObject } from '../../../common/repository/CaseRepository'
import Case from '../../../models/Case'
import CaseFormInputInwardDateTimeFactory from './CaseFormInputInwardDateTimeFactory'
import FormLabel from '../../common/FormLabel'
import CaseFormInputClaimNumber from './CaseFormInputClaimNumber'
import CaseFormInputTPA from './CaseFormInputTPA'
import CaseFormInputInsuranceCompany from './CaseFormInputInsuranceCompany'
import CaseFormInputHospital from './CaseFormInputHospital'
import CaseFormInputDate from './CaseFormInputDate'
import CaseFormInputSubjectLine from './CaseFormInputSubjectLine'
import CaseFormInputLocation from './CaseFormInputLocation'
import Hospital from '../../../models/Hospital'
import DraftCase from '../../../models/DraftCase'
import CompleteCase from '../../../models/CompleteCase'
import { LocationInputValue } from '../../common/LocationInput'
import URLResource from '../../../models/URLResource'
import validator from '../../../common/validator'
import ClientRepository from '../../../common/repository/ClientRepository'
import moment from "moment";

interface Props {
    model?: Case
    onSuccess(c: Case): void
    onError?(): void
}

interface State {
    case_category: CaseCategory | null
    case_type: CaseType | null
    case_inwardDateTime: string
    case_claimNumber: string
    case_TPA: string                //id
    case_insuranceCompany: string   //id
    case_insuranceCompanyName: string
    case_insuredName: string
    case_insuredLocation: LocationInputValue | null
    case_insuredContact: string
    case_insuredAddress: string
    case_hospitalLocation: LocationInputValue | null
    case_hospital: string           //id
    case_policyType: string         //id
    case_policyInception: string
    case_policyNumber: string
    case_availableTATDays: string
    case_availableTATHours: string
    case_subjectLine: string
    case_triggers: string
    case_documents: URLResource[]

    options_caseCategoryLoading: boolean
    options_caseTypeLoading: boolean
    options_TPALoading: boolean
    options_insuranceCompanyLoading: boolean
    options_stateLoading: boolean
    options_districtLoading: boolean
    options_hospitalLoading: boolean
    options_policyTypeLoading: boolean
    options_TATDurationLoading: boolean
    options_caseCategory: DropdownItemProps[]
    options_caseType: DropdownItemProps[]
    options_policyType: DropdownItemProps[]
    options_TATDuration: DropdownItemProps[]

    form_loading: boolean
    form_caseCategories: CaseCategory[]
    form_caseTypes: CaseType[]
    form_errorClaimNumberExists: boolean
    form_hospitals: Hospital[]
    form_loadingHospitals: boolean
    form_caseDocuments: File[]
}

export default class CaseForm extends React.Component<Props, State> {
    private caseRepo = new CaseRepository()
    private caseCategoryRepo = new CaseCategoryRepository()
    private caseTypeRepo = new CaseTypeRepository()
    private clientRepo = new ClientRepository()
    private stateDistrictRepo = new StateDistrictRepository()
    private hospitalRepo = new HospitalRepository()
    private policyTypeRepo = new PolicyTypeRepository()

    constructor(props: Props) {
        super(props)
        const derivedCaseStateFromModel = this.deriveCaseStateFromModel();
        this.state = {
            ...derivedCaseStateFromModel,
            options_caseCategoryLoading: false,
            options_caseTypeLoading: false,
            options_TPALoading: false,
            options_insuranceCompanyLoading: false,
            options_stateLoading: false,
            options_districtLoading: false,
            options_hospitalLoading: false,
            options_policyTypeLoading: false,
            options_TATDurationLoading: false,
            options_caseCategory: [],
            options_caseType: [],
            options_policyType: [],
            options_TATDuration: [
                { text: 'Hours', value: 'hours', key: 1 },
                { text: 'Days', value: 'days', key: 2 }
            ],
            form_loading: false,
            form_caseCategories: [],
            form_caseTypes: [],
            form_errorClaimNumberExists: false,
            form_hospitals: [],
            form_loadingHospitals: true,
            form_caseDocuments: []
        }
    }
    componentDidMount() {
        this.getCaseCategories()
        this.getHospitals()
        this.getCaseTypes()
        this.getPolicyTypes()
    }


    componentDidUpdate(prevProps: Props) {
        if (this.props.model !== prevProps.model) {
            const stateFromModel = this.deriveCaseStateFromModel()
            this.setState({ ...stateFromModel }, () => {
                this.getHospitals()
            })
        }

    }


    private deriveCaseStateFromModel = () => {

        let rs: any;
        if (this.props.model && this.props.model instanceof DraftCase)
            rs = this.deriveCaseStateFromDraftCaseModel(this.props.model)
        else if (this.props.model && this.props.model instanceof CompleteCase)
            rs = this.deriveCaseStateFromCompleteCaseModel(this.props.model)
        else rs = {
            case_category: null,
            case_type: null,
            case_inwardDateTime: '',
            case_claimNumber: '',
            case_TPA: '',
            case_insuranceCompany: '',
            case_insuranceCompanyName: '',
            case_insuredName: '',
            case_insuredLocation: null,
            case_insuredContact: '',
            case_insuredAddress: '',
            case_hospitalLocation: null,
            case_hospital: '',
            case_policyType: '',
            case_policyInception: '',
            case_policyNumber: '',
            case_availableTATDays: '',
            case_availableTATHours: '',
            case_subjectLine: '',
            case_triggers: '',
            case_documents: []
        }

        return rs;
    }


    private deriveCaseStateFromDraftCaseModel = (c: DraftCase) => {
        let tpa = ''
        if (c.getTPA() !== null)
            tpa = c.getTPA()!.getId();

        let insuranceCompany = '';
        if (c.getInsuranceCompany() !== null)
            insuranceCompany = c.getInsuranceCompany()!.getId();

        let insuranceCompanyName = '';
        if (c.getInsuranceCompanyName() !== null)
            insuranceCompanyName = c.getInsuranceCompanyName()!;
        let availableTATPair = CaseType.getTATPair(c.getAvailableTAT());
        return {
            case_category: c.getCategory(),
            case_type: c.getType(),
            case_inwardDateTime: c.getInwardDateTime(),
            case_claimNumber: c.getClaimNumber(),
            case_TPA: tpa,
            case_insuranceCompany: insuranceCompany,
            case_insuranceCompanyName: insuranceCompanyName,
            case_insuredName: '',
            case_insuredLocation: null,
            case_insuredContact: '',
            case_insuredAddress: '',
            case_hospitalLocation: null,
            case_hospital: '',
            case_policyType: '',
            case_policyInception: '',
            case_policyNumber: '',
            case_availableTATDays: availableTATPair[0].toString(),
            case_availableTATHours: availableTATPair[1].toString(),
            case_subjectLine: c.getSubjectLine(),
            case_triggers: '',
            case_documents: []
        }
    }


    private deriveCaseStateFromCompleteCaseModel = (c: CompleteCase) => {

        let tpa = ''
        if (c.getTPA() !== null)
            tpa = c.getTPA()!.getId()

        let insuranceCompany = ''
        if (c.getInsuranceCompany() !== null)
            insuranceCompany = c.getInsuranceCompany()!.getId()

        let insuranceCompanyName = ''
        if (c.getInsuranceCompanyName() !== null)
            insuranceCompanyName = c.getInsuranceCompanyName()!

        let insuredLocation: LocationInputValue | null = null
        if (c.getInsuredLocation() !== null) {
            const location = c.getInsuredLocation()!
            insuredLocation = { state: location.getState(), district: location.getDistrict() }
        }

        let insuredContact = ''
        if (c.getInsuredContact() !== null)
            insuredContact = c.getInsuredContact()!

        let insuredAddress = ''
        if (c.getInsuredAddress() !== null)
            insuredAddress = c.getInsuredAddress()!

        let hospital = ''
        if (c.getHospital() !== null)
            hospital = c.getHospital()!.getId()

        let availableTATPair = CaseType.getTATPair(c.getAvailableTAT());

        return {
            case_category: c.getCategory(),
            case_type: c.getType(),
            case_inwardDateTime: c.getInwardDateTime(),
            case_claimNumber: c.getClaimNumber(),
            case_TPA: tpa,
            case_insuranceCompany: insuranceCompany,
            case_insuranceCompanyName: insuranceCompanyName,
            case_insuredName: c.getInsuredName(),
            case_insuredLocation: insuredLocation,
            case_insuredContact: insuredContact,
            case_insuredAddress: insuredAddress,
            case_hospitalLocation: null,
            case_hospital: hospital,
            case_policyType: c.getPolicyType().getId(),
            case_policyInception: moment(c.getPolicyInception()).format("YYYY/MM/DD"),
            case_policyNumber: c.getPolicyNumber(),
            case_availableTATDays: availableTATPair[0].toString(),
            case_availableTATHours: availableTATPair[1].toString(),
            case_subjectLine: c.getSubjectLine(),
            case_triggers: c.getTriggers(),
            case_documents: c.getDocuments()
        }
    }


    private getCaseCategories = () => {
        this.setState({ options_caseCategoryLoading: true }, async () => {
            const result = await this.caseCategoryRepo.getCategories()
            if (result instanceof Result.Success) {
                const options = result.data.items.map(it => {
                    return { text: it.getTitle(), value: it.getCode(), key: it.getCode() }
                })
                this.setState({ options_caseCategory: options, options_caseCategoryLoading: false, form_caseCategories: result.data.items })
            } else {
                this.setState({ options_caseCategoryLoading: false }, () => {
                    toast.error('Could not load Case Categories')
                })
            }
        })
    }


    private getCaseTypes = () => {
        this.setState({ options_caseTypeLoading: true }, async () => {
            const result = await this.caseTypeRepo.getCaseTypes()
            if (result instanceof Result.Success) {
                const options = result.data.items.map(it => {
                    return { text: it.getTitle(), value: it.getId(), key: it.getId() }
                })
                this.setState({ options_caseTypeLoading: false, options_caseType: options, form_caseTypes: result.data.items })
            } else {
                this.setState({ options_caseTypeLoading: false }, () => {
                    const message = result.message || 'Could not load Case Types'
                    toast.error(message)
                })
            }
        })
    }


    private getPolicyTypes = () => {
        this.setState({ options_policyTypeLoading: true }, async () => {
            const result = await this.policyTypeRepo.getPolicyTypes()
            if (result instanceof Result.Success) {
                const options = result.data.items.map(it => {
                    return { text: it.getTitle(), value: it.getId(), key: it.getId() }
                })
                this.setState({ options_policyTypeLoading: false, options_policyType: options })
            } else {
                this.setState({ options_policyTypeLoading: false }, () => {
                    const message = result.message || 'Could not Policy Types'
                    toast.error(message)
                })
            }
        })
    }


    private getHospitals = () => {
        this.setState({ form_loadingHospitals: true, form_hospitals: [] }, async () => {
            (async () => {
                const ro: GetHospitalsRequestObject = {}
                const location = this.state.case_hospitalLocation
                if (location !== null) {
                    ro.state = location.state
                    ro.district = location.district
                }
                const result = await this.hospitalRepo.getHospitals(ro)
                if (result instanceof Result.Success) {
                    const hospitals = result.data.items;
                    this.setState({ form_loadingHospitals: false, form_hospitals: hospitals })
                } else {
                    this.setState({ form_loadingHospitals: false }, () => {
                        const message = result.message || 'Something went wrong'
                        toast.error(message)
                    })
                }
            })()
        })
    }


    private insuredStateAndDistrictIsRequired = () => {
        const caseCategory = this.state.case_category
        if (caseCategory === null)
            return false

        if (caseCategory.getCode() === constants.UDI.CaseCategory.code.LTI)
            return false
        return true
    }

    /**
     * If this method returns true then Submit button should be enabled
     */
    private enableSubmitButton = () => {
        return (
            this.state.case_category !== null &&
            this.state.case_type !== null &&
            this.state.case_inwardDateTime.trim().length !== 0 &&
            this.state.case_claimNumber.trim().length !== 0 &&
            (
                this.state.case_insuranceCompany.trim().length !== 0 ||
                this.state.case_insuranceCompanyName.trim().length !== 0
            ) &&
            this.state.case_insuredName.trim().length !== 0 &&
            this.state.case_hospital.trim().length !== 0 &&
            this.state.case_policyType.trim().length !== 0 &&
            // this.state.case_policyInception.trim().length !== 0 &&
            this.state.case_policyNumber.trim().length !== 0 &&
            this.state.case_availableTATDays.trim().length !== 0 &&
            this.state.case_availableTATHours.trim().length !== 0 &&
            this.state.case_triggers.trim().length !== 0 &&
            //this.state.form_caseDocuments.length !== 0 &&
            this.state.form_errorClaimNumberExists === false &&
            (
                this.state.case_category.getCode() !== constants.UDI.CaseCategory.code.LTI ?
                    (
                        this.state.case_insuredLocation !== null &&
                        this.state.case_insuredLocation.state.trim().length !== 0 &&
                        this.state.case_insuredLocation.district.trim().length !== 0
                    ) : true
            ) &&
            (
                this.state.case_category.getCode() !== constants.UDI.CaseCategory.code.LTI ?
                    this.state.case_insuredContact.trim().length !== 0 : true
            ) &&
            (
                this.state.case_category.getCode() !== constants.UDI.CaseCategory.code.LTI ?
                    this.state.case_insuredAddress.trim().length !== 0 : true
            ) &&
            this.state.case_subjectLine.trim().length !== 0
        )
    }


    onSubmit = () => {
        const caseCategory = this.state.case_category!
        const caseType = this.state.case_type!

        const days = parseInt(this.state.case_availableTATDays)
        const hours = parseInt(this.state.case_availableTATHours)
        const availableTAT = CaseType.toHours(days) + hours

        let inwardDateTime = this.state.case_inwardDateTime
        if (caseCategory.getCode() !== constants.UDI.CaseCategory.code.LTI)
            inwardDateTime = `${inwardDateTime}`

        this.setState({ form_loading: true }, async () => {
            const ro: GenerateCaseRequestObject = {
                case_category: caseCategory.getCode(),
                case_type: caseType.getId(),
                inward_date_time: inwardDateTime,
                claim_number: this.state.case_claimNumber,
                insured_name: this.state.case_insuredName,
                hospital: this.state.case_hospital,
                policy: this.state.case_policyType,
                policy_inception: this.state.case_policyInception,
                policy_number: this.state.case_policyNumber,
                available_TAT: availableTAT,
                subject_line: this.state.case_subjectLine,
                triggers: this.state.case_triggers,
                documents: this.state.form_caseDocuments
            }

            if (this.state.case_TPA.trim().length !== 0)
                ro.tpa = this.state.case_TPA

            /**
             * one of the both is required
             */
            if (this.state.case_insuranceCompany.trim().length !== 0)
                ro.insurance_company = this.state.case_insuranceCompany
            else
                ro.insurance_company_name = this.state.case_insuranceCompanyName


            if (this.state.case_insuredLocation !== null) {
                const state = this.state.case_insuredLocation.state
                const district = this.state.case_insuredLocation.district
                ro.insured_location = { state, district }
            }

            if (this.state.case_insuredContact.trim().length !== 0)
                ro.insured_phone = this.state.case_insuredContact

            if (this.state.case_insuredAddress.trim().length !== 0)
                ro.insured_address = this.state.case_insuredAddress

            if (this.props.model)
                ro.id = this.props.model!.getId()

            const result = await this.caseRepo.updateCase(ro)
            if (result instanceof Result.Success) {
                const c = result.data
                this.setState({ form_loading: false }, () => {
                    toast.success('Successfully updated case')
                    this.props.onSuccess(c)
                })
            } else {
                this.setState({ form_loading: false }, () => {
                    const message = result.message || 'Something went wrong'
                    toast.error(message)
                    if (this.props.onError)
                        this.props.onError()
                })
            }
        })
    }


    render() {
        if (this.state.case_claimNumber.length > 0)
            return (
                <Form loading={this.state.form_loading}>
                    <Header as='h4' color='grey'>Case Specific Information</Header>


                    {/* case type input */}
                    <Form.Group inline>
                        <Form.Field required>
                            <FormLabel width='120px'>Case Type</FormLabel>
                            <Dropdown
                                search
                                selection
                                placeholder='Select Case Type'
                                loading={this.state.options_caseTypeLoading}
                                value={(this.state.case_type && this.state.case_type.getId()) || ''}
                                options={this.state.options_caseType}
                                onChange={(_, { value }) => {
                                    const index = this.state.form_caseTypes.findIndex(it => it.getId() === value)
                                    if (index === -1)
                                        return
                                    const caseType = this.state.form_caseTypes[index]
                                    const TATpair = CaseType.getTATPair(caseType.getInternalTAT())
                                    const caseCategoryCode = caseType.getCaseCategoryCode()
                                    const i = this.state.form_caseCategories.findIndex(it => it.getCode() === caseCategoryCode)
                                    if (i === -1)
                                        return
                                    const caseCategory = this.state.form_caseCategories[i]
                                    this.setState({
                                        case_type: caseType,
                                        case_availableTATDays: TATpair[0].toString(),
                                        case_availableTATHours: TATpair[1].toString(),
                                        case_category: caseCategory
                                    })
                                }} />
                        </Form.Field>
                    </Form.Group>
                    {/* case type input */}


                    {/* case category input */}
                    <Form.Group inline>
                        <Form.Field required>
                            <FormLabel width='120px'>Case Category</FormLabel>
                            <Dropdown
                                disabled
                                selection
                                placeholder='Select Category'
                                loading={this.state.options_caseCategoryLoading}
                                value={(this.state.case_category && this.state.case_category.getCode()) || ''}
                                options={this.state.options_caseCategory}
                                onChange={(_, { value }) => {
                                    const index = this.state.form_caseCategories.findIndex(it => it.getCode() === value)
                                    if (index === -1) return
                                    const caseCategory = this.state.form_caseCategories[index]
                                    this.setState({ case_category: caseCategory })
                                }} />
                        </Form.Field>
                        <Form.Field>
                            <Popup
                                position='top center'
                                inverted
                                trigger={<Icon name='warning sign' color='orange' />}>
                                <p>Case Category is automatically set from Case Type</p>
                            </Popup>
                        </Form.Field>
                    </Form.Group>
                    {/* case category input */}

                    {/* inward date-time input */}
                    <CaseFormInputInwardDateTimeFactory
                        labelWidth='120px'
                        caseCategoryCode={(this.state.case_category && this.state.case_category.getCode()) || ''}
                        value={this.state.case_inwardDateTime}
                        onChange={value => this.setState({ case_inwardDateTime: value })} />
                    {/* inward date-time input */}


                    {/* claim number input */}
                    <Form.Group inline>
                        <Form.Field required>
                            <FormLabel width='120px'>Claim Number</FormLabel>
                            <Input
                                value={this.state.case_claimNumber}
                                disabled={true}
                            //onChange={(_, { value }) => this.setState({ case_insuredName: value })}
                            />
                        </Form.Field>
                    </Form.Group>
                    {/*<CaseFormInputClaimNumber*/}
                    {/*    labelWidth='120px'*/}
                    {/*    caseId={(this.props.model && this.props.model.getId())}*/}
                    {/*    value={this.state.case_claimNumber}*/}
                    {/*    onChange={value => this.setState({ case_claimNumber: value, form_errorClaimNumberExists: false })}*/}
                    {/*    onError={() => this.setState({ form_errorClaimNumberExists: true })}*/}
                    {/*    caseRepository={this.caseRepo} />*/}
                    {/* claim number input */}


                    {/* subject line input */}
                    <CaseFormInputSubjectLine
                        labelWidth='120px'
                        value={this.state.case_subjectLine}
                        onChange={value => this.setState({ case_subjectLine: value })} />
                    {/* subject line input */}


                    <Divider />
                    {/* <Header as='h4' color='grey'>TPA and Insurance Company</Header> */}
                    <Header as='h4' color='grey'>Insurance Company</Header>



                    {/* tpa input */}
                    <CaseFormInputTPA
                    labelWidth='160px'
                    isDisabled = {false}
                    value={this.state.case_TPA}
                    onChange={value => this.setState({ case_TPA: value })}
                    clientRepo={this.clientRepo}  />
                    {/* tpa input */}


                    {/* insurance company input */}
                    <CaseFormInputInsuranceCompany
                        labelWidth='160px'
                        isDisabled={false}
                        insuranceCompany={this.state.case_insuranceCompany}
                        onInsuranceCompanyChange={value => this.setState({ case_insuranceCompany: value })}
                        insuranceCompanyName={this.state.case_insuranceCompanyName}
                        onInsuranceCompanyNameChange={value => this.setState({ case_insuranceCompanyName: value })}
                        clientRepo={this.clientRepo} />
                    {/* insurance company input */}

                    <Divider />
                    <Header as='h4' color='grey'>Insured Entity Information</Header>


                    {/* insured name input */}
                    <Form.Group inline>
                        <Form.Field required>
                            <FormLabel width='150px'>Insured Name</FormLabel>
                            <Input
                                placeholder='Enter Name'
                                value={this.state.case_insuredName}
                                onChange={(_, { value }) => this.setState({ case_insuredName: value })} />
                        </Form.Field>
                    </Form.Group>
                    {/* insured name input */}


                    {/* insured state/district input */}
                    <CaseFormInputLocation
                        labelWidth='150px'
                        required={this.insuredStateAndDistrictIsRequired()}
                        label='Insured State & District'
                        value={this.state.case_insuredLocation}
                        onChange={value => {
                            this.setState({ case_insuredLocation: value })
                        }}
                        stateDistrictRepo={this.stateDistrictRepo} />
                    {/* insured state/district input */}


                    {/* insured contact and address input */}
                    {this.renderInsuredContactAndAddressInput()}
                    {/* insured contact and address input */}


                    <Divider />
                    <Header as='h4' color='grey'>Other Information</Header>

                    {/* hospital state district */}
                    <CaseFormInputLocation
                        labelWidth='150px'
                        label='Hospital State & District'
                        value={this.state.case_hospitalLocation}
                        onChange={value => this.setState({ case_hospitalLocation: value }, () => {
                            if (value !== null && value.state.trim().length > 0 && value.district.trim().length > 0)
                                this.getHospitals()
                        })}
                        stateDistrictRepo={this.stateDistrictRepo} />
                    {/* hospital state district */}


                    {/* hospital input */}
                    <CaseFormInputHospital
                        required
                        loading={this.state.form_loadingHospitals}
                        hospitals={this.state.form_hospitals}
                        labelWidth='150px'
                        value={this.state.case_hospital}
                        onChange={value => this.setState({ case_hospital: value })}
                        hospitalRepo={this.hospitalRepo} />
                    {/* hospital input */}


                    {/* policy type input */}
                    <Form.Group inline>
                        <Form.Field required>
                            <FormLabel width='150px'>Policy Type</FormLabel>
                            <Dropdown
                                search
                                selection
                                placeholder='Select Policy Type'
                                loading={this.state.options_policyTypeLoading}
                                options={this.state.options_policyType}
                                value={this.state.case_policyType}
                                onChange={(_, { value }) => this.setState({ case_policyType: value as string })} />
                        </Form.Field>
                    </Form.Group>
                    {/* policy type input */}


                    {/* policy inception input */}
                    <CaseFormInputDate
                        labelWidth='150px'
                        value={this.state.case_policyInception}
                        onChange={value => {
                            this.setState({ case_policyInception: value })
                        }
                        }
                        label='Policy Inception'
                        placeholder='Pick Inception Date' />
                    {/* policy inception input */}


                    {/* policy number input */}
                    <Form.Group inline>
                        <Form.Field required>
                            <FormLabel width='150px'>Claim Amount</FormLabel>
                            <Input
                                placeholder='Enter Claim Amount'
                                value={this.state.case_policyNumber}
                                onChange={(_, { value }) => this.setState({ case_policyNumber: value })} />
                        </Form.Field>
                    </Form.Group>
                    {/* policy number input */}



                    {/* available TAT input */}
                    <Form.Group inline>
                        <Form.Field required>
                            <FormLabel width='150px'>Avaiable TAT</FormLabel>
                            <Input
                                label={{ basic: true, content: 'days' }}
                                labelPosition='right'
                                placeholder='Enter days'
                                value={this.state.case_availableTATDays}
                                onChange={(_, { value }) => {
                                    if (validator.integer.isValid(value))
                                        this.setState({ case_availableTATDays: value })
                                }} />
                        </Form.Field>
                        <Form.Field>
                            <Input
                                label={{ basic: true, content: 'hours' }}
                                labelPosition='right'
                                placeholder='Enter hours'
                                value={this.state.case_availableTATHours}
                                onChange={(_, { value }) => {
                                    if (validator.integer.isValid(value))
                                        this.setState({ case_availableTATHours: value })
                                }} />
                        </Form.Field>
                    </Form.Group>
                    {/* available tat input */}


                    <Divider />
                    <Header as='h4' color='grey'>Trigger and Documents</Header>


                    {/* documents input */}
                    <Form.Group inline>
                        <Form.Field required>
                            <FormLabel width='100px'>Documents</FormLabel>
                            <Input
                                type='file'
                                accept='image/*, .pdf, .doc, .docx, .csv, .xls, .xlsx'
                                multiple
                                onChange={(e) => {
                                    if (e.target.files) {
                                        const files = e.target.files
                                        let fileArr: File[] = []
                                        for (let i = 0; i < files.length; i++)
                                            fileArr.push(files[i])
                                        this.setState({ form_caseDocuments: fileArr })
                                    }
                                }} />
                        </Form.Field>
                    </Form.Group>
                    {/* documents input */}


                    {/* triggers input */}
                    <Form.Group inline>
                        <Form.Field required>
                            <FormLabel width='100px'>Triggers</FormLabel>
                            <TextArea
                                value={this.state.case_triggers}
                                onChange={(_, { value }) => this.setState({ case_triggers: value as string })} />
                        </Form.Field>
                    </Form.Group>
                    {/* triggers input */}



                    {/* button */}
                    <Button
                        positive
                        size='large'
                        disabled={!this.enableSubmitButton()}
                        onClick={this.onSubmit}
                        style={{ margin: '16px 16px' }}>
                        Submit
                    </Button>
                    {/* button */}

                </Form>
            )
        else return <Form loading={this.state.form_loading}> <h1>Loading...</h1> </Form>
    }



    renderInsuredContactAndAddressInput = () => {
        const caseCategory = this.state.case_category
        if (caseCategory === null)
            return null

        if (caseCategory.getCode() === constants.UDI.CaseCategory.code.LTI)
            return null

        return (
            <React.Fragment>
                <Form.Group inline>
                    <Form.Field required>
                        <FormLabel width='150px'>Insured Contact</FormLabel>
                        <Input
                            placeholder='Enter Phone Number'
                            value={this.state.case_insuredContact}
                            onChange={(_, { value }) => {
                                if (validator.phone.isValid(value))
                                    this.setState({ case_insuredContact: value })
                            }} />
                    </Form.Field>
                </Form.Group>

                <Form.Group inline>
                    <Form.Field required>
                        <FormLabel width='150px'>Insured Address</FormLabel>
                        <Input
                            placeholder='Enter Address'
                            value={this.state.case_insuredAddress}
                            onChange={(_, { value }) => this.setState({ case_insuredAddress: value })} />
                    </Form.Field>
                </Form.Group>
            </React.Fragment>
        )
    }
}
