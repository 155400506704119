import DataMapper from './DataMapper'
import SettingsRM from '../responsemodel/SettingsRM'
import Settings from '../../models/Settings'

class SettingsDataMapper extends DataMapper<SettingsRM, Settings> {
  map = (rm: SettingsRM) => {
    return new Settings(
      rm.id,
      {
        hraPercentage: rm.salary.basic.hra,
        bonusPercentage: rm.salary.basic.bonus,
        miAllowancePercentage: rm.salary.basic.mi_allowance,
        investigator: {
          extraCaseFee: rm.salary.investigator.extra_case_fee,
          cityExpense: rm.salary.investigator.city_expense,
          conveyanceAllowance: rm.salary.investigator.conveyance_allowance,
          travelAllowance: { ...rm.salary.investigator.travel_allowance },
        },
      },
      {
        investigator: {
          saleReportPoints: rm.incentive.investigator.sale_report || [],
          tatAveragePoints: rm.incentive.investigator.tat_average || [],
          caseTargetPoints: rm.incentive.investigator.case_target || [],
          noLeavePoints: rm.incentive.investigator.no_leave,
          noRedmarkPoints: rm.incentive.investigator.no_red_mark,
          incentives: rm.incentive.investigator.incentive || [],
        },
        claimAnalyst: {
          gradeAveragePoints: rm.incentive.claim_analyst.grade_average || [],
          caseClosedPoints: rm.incentive.claim_analyst.case_closed || [],
          noLeavePoints: rm.incentive.claim_analyst.no_leave,
          noRedmarkPoints: rm.incentive.claim_analyst.no_red_mark,
          incentives: rm.incentive.claim_analyst.incentive || [],
        },
        teamLead: {
          gradeAveragePoints: rm.incentive.team_lead.grade_average || [],
          caseClosedPoints: rm.incentive.team_lead.case_closed || [],
          noLeavePoints: rm.incentive.team_lead.no_leave,
          noRedmarkPoints: rm.incentive.team_lead.no_red_mark,
          incentives: rm.incentive.team_lead.incentive || [],
        },
      },
      {
        paidLeavesCount: rm.leaves.paid_leaves,
        shortLeaveWageDeductPercentage: rm.leaves.short_leave_wage,
        halfLeaveWageDeductPercentage: rm.leaves.half_leave_wage,
        fullLeaveWageDeductPercentage: rm.leaves.full_leave_wage,
        leaveAccumulationCount: rm.leaves.accumulation,
        leaveAccumulationBegining: rm.leaves.accumulation_start,
      },
      {
        documentSize: rm.claim_size.document_size,
        evidenceSizeLTI: rm.claim_size.evidence_size_LTI,
        evidenceSizeHVI: rm.claim_size.evidence_size_HTI,
        evidenceSizeDTI: rm.claim_size.evidence_size_DVI,
      }
    )
  }
}

export default SettingsDataMapper
