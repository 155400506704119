import React from "react";
import {
  Segment,
  Header,
  Rating,
  TextArea,
  Form,
} from "semantic-ui-react";
import AllocatedClaimAnalyst from "../../../../../../models/AllocatedClaimAnalyst";
import Location from "../../../../../../models/Location";

export interface GradingCardInputValue {
  id: string;
  claimAnalystId: string;
  allocationHygiene: number;
  reportQuality: number;
  outcome: number;
  tat: number;
  feedback: string;
  investigators: {
    id: string;
    allocationId: string;
    capping: string;
    outcome: string;
    investigationLocation: Location | null;
    allowance: number;
    documentExpense: number;
    extraExpense: number;
    approvedExpense: number;
  }[];
}

interface Props {
  model: AllocatedClaimAnalyst;
  value: GradingCardInputValue;
  onChange?(value: GradingCardInputValue): void;
}

const GradingCard = (props: Props) => {
  const { model, value, onChange } = props;
  const portalRoot = document.getElementsByTagName("body")[0];

  // value.allocationHygiene = 0;
  // value.reportQuality = 0;
  // value.outcome = 0;
  // value.tat = 0;
  const grade = model.getGrade();
  if (grade !== null) {
    value.allocationHygiene = grade.getGradeAllocationHygiene();
    value.reportQuality = grade.getGradeReportQuality();
    value.outcome = grade.getGradeOutcome();
    value.tat = grade.getGradeTAT();
  }

  const internalOnChange = <T extends keyof GradingCardInputValue>(
    key: T,
    v: any
  ) => {
    const newValue = { ...value, [key]: v };
    if (onChange) onChange(newValue);
  };

  return (
    <Segment>
      {/* section */}
      <div>
        {/* <Header as="h4">{model.getClaimAnalyst().getName()}</Header> */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div style={{ marginRight: 8 }}>Allocation Hygiene:</div>
          <Rating
            icon="star"
            maxRating={1}
            size="huge"
            rating={value.allocationHygiene}
            onRate={(_, { rating }) =>
              internalOnChange("allocationHygiene", rating)
            }
          />

          <div style={{ marginRight: 8, marginLeft: 36 }}>Report Quality:</div>
          <Rating
            icon="star"
            maxRating={1}
            size="huge"
            rating={value.reportQuality}
            onRate={(_, { rating }) =>
              internalOnChange("reportQuality", rating)
            }
          />

          <div style={{ marginRight: 8, marginLeft: 36 }}>Outcome:</div>
          <Rating
            icon="star"
            maxRating={2}
            size="huge"
            clearable
            rating={value.outcome}
            onRate={(_, { rating }) => internalOnChange("outcome", rating)}
          />

          <div style={{ marginRight: 8, marginLeft: 36 }}>TAT:</div>
          <Rating
            icon="star"
            maxRating={1}
            size="huge"
            rating={value.tat}
            onRate={(_, { rating }) => internalOnChange("tat", rating)}
          />
        </div>
      </div>
      <Header as="h4">Feedback</Header>
      <Form>
        <TextArea
          rows={3}
          value={value.feedback}
          onChange={(_, { value }) =>
            internalOnChange("feedback", value as string)
          }
        />
      </Form>
      {/* section */}
    </Segment>
  );
};

GradingCard.initialValue = {
  id: "",
  claimAnalystId: "",
  allocationHygiene: 0,
  reportQuality: 0,
  outcome: 0,
  tat: 0,
  feedback: "",
  investigators: [],
} as GradingCardInputValue;

export default GradingCard;
