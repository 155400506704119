import React from "react";
import DateTimePickerGateway from "./DateTimePickerGateway";

/**
 * This is front gateway for DateTimePicker
 * Its responsibilities are
 *
 * 1. Define Interface for Props
 * 2. Memoization for optimization
 * (If props provided when parent rerenders are same
 * then this component will not rerender)
 */

interface Props {
  type: "date" | "time" | "datetime";
  value: string | undefined;
  onChange(value: string): void;
  maxDate?: Date;
  placeholder?: string;
}

const DateTimePicker = (props: Props) => {
  return <DateTimePickerGateway {...props} />;
};

export default DateTimePicker;
