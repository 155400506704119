import React from "react";
import InitiateCaseForm from "./InitiateCaseForm";
import Page from "../../../layout/Page";
import { Segment } from "semantic-ui-react";
import Case from "../../../../models/Case";
import { RouteComponentProps } from "react-router-dom";
import RequirePermission from "../../../base/RequirePermission";
import permissions from "../../../../common/permissions";

const InitiateCasePage = (props: RouteComponentProps) => {
  const pageDescription = `
        Initiate a case by filling the below fields. After Case Initiation,
        the case will show up in respective Claim Analyst's and above authorities'
        Case List for further process i.e Case Generation.
    `;
  return (
    <Page title="Initiate Case" description={pageDescription}>
      <Segment basic>
        <InitiateCaseForm
          onSuccess={(c: Case) => {
            const category = c.getCategory();
            props.history.push(
              `/`
            );
            // props.history.push(
            //   `/case/list?category=${category.getCode()}&state=${c.getState()}`
            // );

          }}
        />
      </Segment>
    </Page>
  );
};

export default (props: any) => {
  return (
    <RequirePermission permission={permissions.Case.initiate}>
      <InitiateCasePage {...props} />
    </RequirePermission>
  );
};
