import DomainModel from './DomainModel'

export default class Holiday extends DomainModel {
  constructor(private date: string, private title: string) {
    super()
  }

  getDate = () => this.date
  getTitle = () => this.title
}
