import React, { useState, useEffect } from "react";
import { Label, Step } from "semantic-ui-react";
import CompleteCase from "../../../../models/CompleteCase";
import constants from "../../../../common/constants";
import CaseRepository from "../../../../common/repository/CaseRepository";
import Result from "../../../../common/repository/Result";
import { toast } from "../../../common/Toast";

interface CaseProgressStepGroupProps {
  model: CompleteCase;
}

const getClaimSize = async (claimNumber: string) => {
  let caseRepo = new CaseRepository();
  const result = await caseRepo.getClaimSize(claimNumber.toLowerCase());
  if (result instanceof Result.Success) {
    return result.data[0] as { documents_size: number; evidences_size: number };
  } else {
    const message =
      result.message || "Something went wrong while fetching Claim Size";
    toast.error(message);
    return false;
  }
};

const CaseProgressStepGroup = (props: CaseProgressStepGroupProps) => {
  const { model } = props;
  const state = model.getState();
  const claimNumber = model.getClaimNumber();
  let [documentsSize, setDocumentSize] = useState(0);
  let [evidencesSize, setEvidenceSize] = useState(0);

  useEffect(() => {
    (async () => {
      let rs = await getClaimSize(claimNumber);
      if (typeof rs != "boolean") {
        setDocumentSize(rs.documents_size);
        setEvidenceSize(rs.evidences_size);
      }
    })();
  }, []);

  return (
    <>
      <Step.Group ordered size="mini" as={"span"}>
        <Step
          completed={
            state === constants.caseStates.open ||
            state === constants.caseStates.reinvestigate ||
            state === constants.caseStates.fold ||
            state === constants.caseStates.checked ||
            state === constants.caseStates.close ||
            state === constants.caseStates.graded
          }
        >
          Allocated
        </Step>

        <Step
          completed={
            state === constants.caseStates.fold ||
            state === constants.caseStates.checked ||
            state === constants.caseStates.close ||
            state === constants.caseStates.graded
          }
        >
          Initial Closure
        </Step>

        <Step
          completed={
            state === constants.caseStates.checked ||
            state === constants.caseStates.close ||
            state === constants.caseStates.graded
          }
        >
          Quality Checked
        </Step>

        <Step
          completed={
            state === constants.caseStates.close ||
            state === constants.caseStates.graded
          }
        >
          Closed
        </Step>

        <Step completed={state === constants.caseStates.graded}>Graded</Step>
      </Step.Group>
      <div style={{ float: "right" }}>
        {documentsSize > 0 && (
          <Label color="blue" ribbon={"right"}>
            Documents Size {formatBytes(documentsSize)}
          </Label>
        )}
        <br />
        {evidencesSize > 0 && (
          <Label color="orange" ribbon={"right"}>
            Evidences Size {formatBytes(evidencesSize)}
          </Label>
        )}
      </div>
    </>
  );
};

const formatBytes = (a: number, b = 2) => {
  if (0 === a) return "0 Bytes";
  const c = 0 > b ? 0 : b,
    d = Math.floor(Math.log(a) / Math.log(1024));
  return (
    parseFloat((a / Math.pow(1024, d)).toFixed(c)) +
    " " +
    ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d]
  );
};

export default CaseProgressStepGroup;
