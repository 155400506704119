import React, { useState, useContext } from 'react'
import { Grid, Form, Header, Icon, Button, Radio, Segment } from 'semantic-ui-react'
import PerformanceEvaluationPageContext from './PerformanceEvaluationPageContext'
import DateTimePicker from '../../app/DateTimePicker'
import constants from '../../../common/constants'

function Filters() {
  const ctx = useContext(PerformanceEvaluationPageContext)
  const [isExpanded, setIsExpanded] = useState(false)
  let [clientVisible, setClientVisible] = useState(false)
  let [userVisible, setUserVisible] = useState(false)
  let [userRoleCheck, setUserRoleCheck] = useState('')
  let [evaluationType, setEvaluationType] = useState('')
  let [evaluateByCloseDate, setEvaluateByCloseDate] = useState(false)
  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Header as="h4">
          Filter
          <Header.Subheader>
            - <strong>From</strong> and <strong>To</strong> Filters are required for Performance Evaluation to take effect.
            <br />- Performance Evaluation will begin once you enter the required filters or change any filter.
          </Header.Subheader>
        </Header>
        <Icon name="arrow alternate circle down outline" size="large" onClick={() => setIsExpanded(!isExpanded)} />
      </div>
      <Grid columns={5}>
        <Grid.Row>
          <Grid.Column>
            <Segment compact>
              <Radio
                toggle
                label={'Evaluation From Closed Date'}
                onChange={() => {
                  ctx.filters.setEvaluationByClosedDate(!ctx.filters.evaluationByClosedDate)
                }}
              />
            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Form.Input label="From Date">
              <DateTimePicker placeholder="Select From Date" type="date" value={ctx.filters.from} onChange={ctx.filters.setFrom} />
            </Form.Input>
          </Grid.Column>
          <Grid.Column>
            <Form.Input label="To Date">
              <DateTimePicker placeholder="Select To Date" type="date" value={ctx.filters.to} onChange={ctx.filters.setTo} />
            </Form.Input>
          </Grid.Column>
          <Grid.Column>
            <Form.Dropdown
              label="Evaluation Type"
              search
              selection
              placeholder="Select type"
              options={[
                { key: 'user', text: 'User', value: 'user' },
                { key: 'client', text: 'Client', value: 'client' },
                { key: 'allclients', text: 'All Clients', value: 'allclients' },
                { key: 'investigator', text: 'All Investigator', value: 'Investigator' },
                { key: 'claim_analyst', text: 'All Claim Analyst', value: 'Claim Analyst' },
                // { key: 'team_lead', text: 'Team Lead', value: 'Team Lead' },

              ]}
              value={evaluationType}
              onChange={(_, { value }) => {
                setEvaluationType(value as string)
                if (value == 'user') {
                  setUserVisible(true)
                  setClientVisible(false)
                } else if (value == 'client') {
                  setClientVisible(true)
                  setUserVisible(false)
                } else if (value == 'allclients') {
                  ctx.filters.setViewType('client')
                } else if (value == 'Investigator') {
                  ctx.filters.setViewType('investigator')
                } else if (value == 'Claim Analyst') {
                  ctx.filters.setViewType('claim_analyst')
                }
                // else if (value == 'Team Lead') {
                //   ctx.filters.setViewType('team_lead')
                // } 
                else {
                  setClientVisible(false)
                  setUserVisible(false)
                }
              }}
            />
          </Grid.Column>
          {clientVisible && (
            <Grid.Column>
              <Form.Dropdown
                label="Client"
                search
                selection
                placeholder="Select Client"
                options={ctx.options.client}
                value={ctx.filters.client}
                onChange={(_, { value }) => {
                  ctx.filters.setClient(value as string)
                  ctx.filters.setViewType('client')
                }}
              />
            </Grid.Column>
          )}
          {userVisible && (
            <>
              <Grid.Column>
                <Form.Dropdown
                  label="User Role"
                  search
                  selection
                  placeholder="Select User Role"
                  options={ctx.options.userRoles}
                  value={ctx.filters.userRole}
                  onChange={(_, { value }) => {
                    ctx.filters.setUserRole(value as string)
                    setUserRoleCheck(value as string)
                  }}
                />
              </Grid.Column>
              <Grid.Column>
                <Form.Dropdown
                  label="User"
                  search
                  selection
                  placeholder="Select User"
                  options={ctx.options.user}
                  value={ctx.filters.user}
                  onChange={(_, { value }) => {
                    ctx.filters.setUser(value as string)
                    if (userRoleCheck == constants.roleTitle.investigator) {
                      ctx.filters.setViewType('investigator')
                    } else {
                      ctx.filters.setViewType('user')
                    }
                  }}
                />
              </Grid.Column>
            </>
          )}

          <Grid.Column>{!isExpanded && <Button onClick={clearAllFilters}>Clear</Button>}</Grid.Column>
        </Grid.Row>
      </Grid>
      {isExpanded && (
        <Grid columns={5}>
          <Grid.Row></Grid.Row>
          <Grid.Row>
            {!clientVisible && (
              <Grid.Column>
                <Form.Dropdown
                  label="Client"
                  search
                  selection
                  placeholder="Select Client"
                  options={ctx.options.client}
                  value={ctx.filters.client}
                  onChange={(_, { value }) => ctx.filters.setClient(value as string)}
                />
              </Grid.Column>
            )}
            {!userVisible && (
              <>
                <Grid.Column>
                  <Form.Dropdown
                    label="User Role"
                    search
                    selection
                    placeholder="Select User Role"
                    options={ctx.options.userRoles}
                    value={ctx.filters.userRole}
                    onChange={(_, { value }) => ctx.filters.setUserRole(value as string)}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Form.Dropdown
                    label="User"
                    search
                    selection
                    placeholder="Select User"
                    options={ctx.options.user}
                    value={ctx.filters.user}
                    onChange={(_, { value }) => ctx.filters.setUser(value as string)}
                  />
                </Grid.Column>
              </>
            )}
            <Grid.Column>
              <Form.Dropdown
                label="District"
                search
                selection
                placeholder="Select District"
                options={ctx.options.districts}
                value={ctx.filters.district}
                onChange={(_, { value }) => ctx.filters.setDistrict(value as string)}
              />
            </Grid.Column>
            <Grid.Column>
              <Form.Dropdown
                label="Case Category"
                search
                selection
                placeholder="Select Case Category"
                options={ctx.options.category}
                value={ctx.filters.category}
                onChange={(_, { value }) => ctx.filters.setCategory(value as string)}
              />
            </Grid.Column>
            <Grid.Column>
              <Form.Dropdown
                search
                selection
                label="Hospital"
                placeholder="Select Hospital"
                options={ctx.options.hospital}
                value={ctx.filters.hospital}
                onChange={(_, { value }) => ctx.filters.setHospital(value as string)}
              />
            </Grid.Column>
            <Grid.Column>
              <Form.Input label="Search" placeholder="Enter Text..." value={ctx.filters.search} onChange={(_, { value }) => ctx.filters.setSearch(value)} />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Button onClick={clearAllFilters}>Clear</Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}
    </>
  )

  function clearAllFilters() {
    ctx.filters.setUserRole('')
    ctx.filters.setUser('')
    ctx.filters.setCategory('')
    ctx.filters.setClient('')
    ctx.filters.setDistrict('')
    ctx.filters.setHospital('')
    ctx.filters.setFrom('')
    ctx.filters.setTo('')
    ctx.filters.setSearch('')
    ctx.filters.setViewType('')
    ctx.filters.setEvaluationByClosedDate(false)
    setUserVisible(false)
    setClientVisible(false)
  }
}

export default Filters
