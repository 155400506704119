import React from "react";
import { ToastContainer, toast as reactToast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import "./index.css";

class Toast extends React.Component {
  render() {
    return <ToastContainer />;
  }
}

interface ToastContentProps {
  header: string;
  message: string;
}

const ToastContent = (props: ToastContentProps) => {
  return (
    <div>
      <h4 style={{ color: "white", marginBottom: 4 }}>{props.header}</h4>
      <p>{props.message}</p>
    </div>
  );
};

export const toast = (message: string) => {
  reactToast.success(<ToastContent header="Notification" message={message} />, {
    className: "app-toast app-toast-normal",
    progressClassName: "app-toast-progress",
  });
};

toast.success = (message: string) => {
  reactToast.success(<ToastContent header="Success" message={message} />, {
    className: "app-toast app-toast-success",
    progressClassName: "app-toast-progress",
  });
};

toast.warn = (message: string) => {
  reactToast.warn(<ToastContent header="Warning" message={message} />, {
    className: "app-toast app-toast-warn",
    progressClassName: "app-toast-progress",
  });
};

toast.error = (message: string) => {
  reactToast.error(<ToastContent header="Error" message={message} />, {
    className: "app-toast app-toast-error",
    progressClassName: "app-toast-progress",
  });
};

export default Toast;
