import DomainModel from './DomainModel'

class CaseService extends DomainModel {
    constructor(private caseService: any,) {
        super()
    }
    getServiceData = () => this.caseService
}

export default CaseService
