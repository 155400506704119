import ActionColumnRenderer from '../../../app/Table/renderer/ActionColumnRenderer'
import VisitPurposeModel from '../../../../models/VisitPurposeModel'

export default class VisitPurposeColumnRenderer extends ActionColumnRenderer<VisitPurposeModel> {
    getColumns() {
        return [
            {
                name: 'Purpose',
                selector: 'purpose',
            },
            {
                name: 'Actions',
                selector: 'id',
                grow: 0,
                width: '180px',
                cell: this.renderActionColumn,
            },
        ]
    }
}
