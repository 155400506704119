import React, { useContext } from "react";
import { Segment, Card as SemanticCard, Header } from "semantic-ui-react";
import styled from "styled-components";
import HomePageContext from "./HomePageContext";
import constants from "../../../common/constants";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

function Cards() {
  const ctx = useContext(HomePageContext);

  return (
    <>
      <Header as="h4">Cases</Header>
      <SemanticCard.Group itemsPerRow={4}>
        <Card
          title={"Pending Generation"}
          count={ctx.count.initiated}
          onClick={() => ctx.navigate(constants.caseStates.initiated)}
        />
        <Card
          title={"Pending Allocation"}
          count={ctx.count.generated}
          onClick={() => ctx.navigate(constants.caseStates.generated)}
        />
        <Card
          title={"Open"}
          count={ctx.count.open}
          onClick={() => ctx.navigate(constants.caseStates.open)}
        />
        <Card
          title={"Reinvestigate"}
          count={ctx.count.reinvestigate}
          onClick={() => ctx.navigate(constants.caseStates.reinvestigate)}
        />
        <Card
          title={"Pending QC"}
          count={ctx.count.fold}
          onClick={() => ctx.navigate(constants.caseStates.fold)}
        />
        <Card
          title={"Pending Closure"}
          count={ctx.count.checked}
          onClick={() => ctx.navigate(constants.caseStates.checked)}
        />
        <Card
          title={"Pending Grading"}
          count={ctx.count.close}
          onClick={() => ctx.navigate(constants.caseStates.close)}
        />

        {/*<Card*/}
        {/*    title={'Graded'}*/}
        {/*    count={ctx.count.graded}*/}
        {/*    onClick={() => ctx.navigate(constants.caseStates.graded)}/>*/}
      </SemanticCard.Group>
    </>
  );
}

function Card({ title, count, onClick }: CardProps) {
  return (
    <SemanticCard
      onClick={onClick}
      style={{
        backgroundColor: "#1221d6",
        color: "white",
        height: "100px",
        textAlign: "center",
      }}
    >
      <SemanticCard.Content style={{ marginTop: "20px" }}>
        <SemanticCard.Header
          style={{ backgroundColor: "#1221d6", color: "white" }}
        >
          {title}
        </SemanticCard.Header>
        <SemanticCard.Meta
          style={{ backgroundColor: "#1221d6", color: "white" }}
        >
          {count}
        </SemanticCard.Meta>
      </SemanticCard.Content>
    </SemanticCard>
  );
}

interface CardProps {
  title: string;
  count: string;
  onClick(): void;
}

export default Cards;
