import DomainModel from '../DomainModel'

export default class InsuredModel extends DomainModel {
    constructor(private _id: string, private name: string, private mobile: string, private dob: string) {
        super()
    }

    getId = () => this._id
    getName = () => this.name
    getMobile = () => this.mobile
    getDOB = () => this.dob
}
