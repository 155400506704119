import React from "react";
import { Segment, List, DropdownItemProps, Image, Grid, Divider, Header, Icon, Button, Form, Accordion, } from "semantic-ui-react";
import UserRepository from "../../../../../common/repository/UserRepository";
import Result from "../../../../../common/repository/Result";
import User from "../../../../../models/User";
import InvestigatorProfileCard from "../InvestigatorProfileCard";
import CaseRepository from "../../../../../common/repository/CaseRepository";
import Case from "../../../../../models/Case";
import { toast } from "../../../../common/Toast";
import constants from "../../../../../common/constants";
import RequirePermission from "../../../../base/RequirePermission";
import permissions from "../../../../../common/permissions";
import { TagsInput } from "react-tag-input-component";

const investigationSpecifications = ["Cashless", "Detailed Investigation", "Insured Part", "Hospital Part", "Vicinity Check", "Document Verification",];
interface ActionAllocationProps {
  model: Case;
  updateModel(c: Case): void;
}

interface ActionAllocationState {
  active: boolean;
  loading: boolean;
  loadingInvestigators: boolean;
  investigatorOptions: DropdownItemProps[];
  investigators: User[];

  alloc_selectedInvestigator: string; //id
  alloc_selectedInvestigationSpecifications: string[];
  alloc_otherInvestigationSpecifications: string;
  alloc_selection: { investigator: User; specs: string[]; serviceType: string[], required_doc: string[] }[];
  servicesType: any[]
  services: string[]
  required_documents: string[]
}

class ActionAllocation extends React.Component<
  ActionAllocationProps,
  ActionAllocationState
> {
  private usersRepo = new UserRepository();
  private caseRepo = new CaseRepository();

  constructor(props: ActionAllocationProps) {
    super(props);
    this.state = {
      active: false,
      loading: false,
      loadingInvestigators: false,
      investigatorOptions: [],
      investigators: [],
      alloc_selectedInvestigator: "",
      alloc_selectedInvestigationSpecifications: [],
      alloc_otherInvestigationSpecifications: "",
      alloc_selection: [],
      servicesType: props.model.getType().getServices(),
      services: props.model.getType().getServices(),
      required_documents: props.model.getType().getRequiredDocumne()
    };
  }

  componentDidMount() {
    this.getInvestigators();
  }

  private getInvestigators = () => {
    this.setState({ loadingInvestigators: true }, async () => {
      const result = await this.usersRepo.getUsersByRoleTitle({
        role: constants.roleTitle.investigator
        // is_active: true,
      });
      if (result instanceof Result.Success) {
        const investigators = result.data.items;
        const options = investigators.map((it) =>
          this.investigatorToDropDownOption(it)
        );
        this.setState({
          loadingInvestigators: false,
          investigatorOptions: options,
          investigators,
        });
      } else {
        this.setState({ loadingInvestigators: false }, () => {
          const message = result.message || "Could not load Investigators";
          toast.error(message);
        });
      }
    });
  };

  private allocateInvesitgators = () => {
    this.setState({ loading: true }, async () => {
      const { model, updateModel } = this.props;
      const allocations = this.state.alloc_selection.map((it) => {
        // console.log(it, "newit")
        return {
          investigator_id: it.investigator.getId(),
          specifications: it.specs,
          services: it.serviceType,
          required_documents: it.required_doc
        };
      });

      const result = await this.caseRepo.allocateInvestigators({
        case_id: model.getId(),
        allocations,
        // services:
        // required_documents:
      });
      if (result instanceof Result.Success) {
        const c = result.data;
        this.setState(
          {
            loading: false,
            investigatorOptions: [],
            investigators: [],
            alloc_selectedInvestigator: "",
            alloc_selectedInvestigationSpecifications: [],
            alloc_otherInvestigationSpecifications: "",
            alloc_selection: [],

            // services: [],
            // required_documents: []
          },
          () => {
            updateModel(c);
            toast.success("Case Allocated Successfully");
            this.getInvestigators();
          }
        );
      } else {
        const message = result.message || "Something went wrong";
        this.setState({ loading: false }, () => {
          toast.error(message);
        });
      }
    });
  };

  handleService = (e: any) => {
    const { value, checked } = e.target
    if (checked) {
      this.setState({ services: [...this.state.services, value] })
    } else {
      this.setState({ services: this.state.services.filter((e) => e !== value) })
    }
  }

  handleCheckboxChange = (event: any) => {
    let newArray = [...this.state.services, event.target.id];
    if (this.state.services.includes(event.target.id)) {
      newArray = newArray.filter(day => day !== event.target.id);
    }
    this.setState({
      services: newArray
    });
  };

  render() {
    const selectionList = this.state.alloc_selection.map((it) => {
      return (
        <InvestigatorProfileCard
          showHeader
          model={it.investigator}
          investigationSpecifications={it.specs}
          required_doc={it.required_doc}
          serviceType={it.serviceType}
          onRemove={this.onRemove}
        />
      );
    });

    const investigationSpecsOptions = investigationSpecifications.map((it) => {
      return { text: it, value: it, key: it };
    });

    const caseState = this.props.model.getState();
    return (
      <Accordion>
        <Accordion.Title active={this.state.active}>
          <Button
            primary
            disabled={
              caseState !== constants.caseStates.generated &&
              caseState !== constants.caseStates.open &&
              caseState !== constants.caseStates.reinvestigate
            }
            onClick={() => this.setState({ active: !this.state.active })}
          >
            <Icon name="dropdown" />
            Allocate Investigator
          </Button>
        </Accordion.Title>
        <Accordion.Content active={this.state.active}>
          <Segment loading={this.state.loading}>
            <Grid columns={2}>
              <Divider vertical>
                <Icon name="arrow alternate circle right" />
              </Divider>
              <Grid.Row>
                {/* investigator selection */}
                <Grid.Column>
                  <Header as="h4">
                    Step 1
                    <Header.Subheader>
                      Select an Investigator from the Dropdown, assign him
                      investigation specifications then add him to the Selection
                      List for Allocation.
                    </Header.Subheader>
                  </Header>

                  <Form>
                    <Form.Dropdown
                      search
                      selection
                      label="Select Invesitgator"
                      loading={this.state.loadingInvestigators}
                      value={this.state.alloc_selectedInvestigator}
                      onChange={(_, { value }) =>
                        this.setState({
                          alloc_selectedInvestigator: value as string,
                        })
                      }
                      options={this.state.investigatorOptions}
                      placeholder="Select Investigator"
                    />

                    <Form.Dropdown
                      fluid
                      selection
                      multiple
                      label="Investigation Specifications"
                      placeholder="Select Multiple"
                      options={investigationSpecsOptions}
                      value={
                        this.state.alloc_selectedInvestigationSpecifications
                      }
                      onChange={(_, { value }) =>
                        this.setState({
                          alloc_selectedInvestigationSpecifications:
                            value as string[],
                        })
                      }
                    />
                    <label><strong> Services</strong></label>
                    <Grid>
                      <Grid.Row>
                        {this.state.servicesType.map((item, index) => <>
                          <Grid.Column width={8}>
                            <input
                              className="CaseTypeCheck"
                              key={index}
                              type="checkbox"
                              id={item}
                              value={item}
                              checked={this.state.services.includes(item)}
                              onChange={this.handleCheckboxChange}
                            />
                            <label htmlFor={item}>{item}</label>
                          </Grid.Column>
                        </>
                        )}
                      </Grid.Row>
                    </Grid>

                    <label><strong> Required Documents</strong></label>
                    <TagsInput
                      value={this.state.required_documents}
                      // onChange={(value) => this.setState({ required_documents: value })}
                      name="Required Documents"
                      placeHolder="PanCard"
                      disabled
                    />

                    <Form.Input
                      label="Other Specifications"
                      placeholder="e.g. Accidental, Health"
                      value={this.state.alloc_otherInvestigationSpecifications}
                      onChange={(_, { value }) =>
                        this.setState({
                          alloc_otherInvestigationSpecifications: value,
                        })
                      }
                    />
                    <Button
                      primary
                      icon
                      labelPosition="right"
                      onClick={this.onAdd}
                      disabled={
                        this.state.alloc_selectedInvestigator.length === 0 ||
                        this.state.services.length === 0 || this.state.required_documents.length === 0 ||
                        (this.state.alloc_selectedInvestigationSpecifications
                          .length === 0 &&
                          this.state.alloc_otherInvestigationSpecifications.trim()
                            .length === 0)
                      }
                    >
                      Add
                      <Icon name="arrow alternate circle right" />
                    </Button>
                  </Form>
                </Grid.Column>
                {/* investigator selection */}

                {/* allocation  */}
                <Grid.Column>
                  <Header as="h4">
                    Step 2
                    <Header.Subheader>
                      Click on the Allocate Button to start Investigation.
                      Investigators will receive an email with Case Detail.
                    </Header.Subheader>
                  </Header>

                  <Header as="h5">
                    Selected Investigators: {this.state.alloc_selection.length}
                  </Header>
                  <List>{selectionList}</List>
                  <Button
                    icon
                    positive
                    disabled={this.state.alloc_selection.length === 0}
                    labelPosition="right"
                    onClick={this.allocateInvesitgators}
                  >
                    Allocate Investigator
                    {this.state.alloc_selection.length > 1 ? "s" : ""}
                    <Icon name="check circle" />
                  </Button>
                </Grid.Column>
                {/* allocation  */}
              </Grid.Row>
            </Grid>
          </Segment>
        </Accordion.Content>
      </Accordion>
    );
  }

  private onAdd = () => {
    const id = this.state.alloc_selectedInvestigator;
    const investigator = this.findInvestigatorById(id);
    if (investigator === null) return;

    //remove investigator from options
    this.removeInvestigatorFromOptions(id);

    //get specs
    const specs = this.getSpecs();

    const serviceType = this.state.services
    const required_doc = this.state.required_documents

    // console.log(serviceType, required_doc, "qqqqqqqqqqqqqqqqqqqqqqqqq")
    //add investigator to selection list
    this.addInvestigatorInSelectionList(investigator, specs, serviceType, required_doc);

    //clear the current selection

    this.setState({
      alloc_selectedInvestigator: "",
      alloc_selectedInvestigationSpecifications: [],
      alloc_otherInvestigationSpecifications: "",
      // services: [],
      // required_documents: [],
    });
  };

  private removeInvestigatorFromOptions = (id: string) => {
    const options = this.state.investigatorOptions;
    const index = options.findIndex((it) => it.value === id);
    if (index === -1) return;
    options.splice(index, 1);
    this.setState({ investigatorOptions: [...options] });
  };

  private addInvestigatorInSelectionList = (
    investigator: User,
    specs: string[],
    serviceType: string[],
    required_doc: string[]

  ) => {
    const selection = this.state.alloc_selection;
    selection.push({ investigator, specs, serviceType, required_doc });
    this.setState({ alloc_selection: [...selection] });
  };

  private findInvestigatorById = (id: string) => {
    const index = this.state.investigators.findIndex((it) => it.getId() === id);
    if (index === -1) return null;
    return this.state.investigators[index];
  };

  private getSpecs = () => {
    const investigationSpecs = this.state.alloc_selectedInvestigationSpecifications;
    const services = this.state.services
    const required_doc = this.state.required_documents
    const otherSpecs = this.state.alloc_otherInvestigationSpecifications;

    let totalSpecs = [...investigationSpecs];
    if (this.state.alloc_otherInvestigationSpecifications.trim().length > 0) {
      totalSpecs = totalSpecs.concat(otherSpecs.split(","));
    }
    return totalSpecs;
  };

  private onRemove = (investigator: User) => {
    //remove investigator from selection list
    this.removeInvestigatorFromSelectionList(investigator);

    //add investigator back to list
    this.addInvestigatorInOptions(investigator);
  };

  private removeInvestigatorFromSelectionList = (investigator: User) => {
    const selection = this.state.alloc_selection;
    const index = selection.findIndex(
      (it) => it.investigator.getId() === investigator.getId()
    );
    if (index === -1) return;

    selection.splice(index, 1);
    this.setState({ alloc_selection: [...selection] });
  };

  private addInvestigatorInOptions = (investigator: User) => {
    const options = this.state.investigatorOptions;
    options.push(this.investigatorToDropDownOption(investigator));
    this.setState({ investigatorOptions: [...options] });
  };

  renderInvestigatorDropdownLabel = (model: User) => {
    const districts = User.getDistricts(model.getPrimaryLocations()).join();
    return (
      <div style={{ display: "flex" }}>
        <Image src={model.getProfilePicURL()} avatar />
        <div style={{ margin: "0 4px" }}>
          <div>{model.getName()}</div>
          <div style={{ fontSize: 12 }}>{districts}</div>
        </div>
      </div>
    );
  };

  private investigatorToDropDownOption = (investigator: User) => {
    return {
      key: investigator.getId(),
      value: investigator.getId(),
      text: investigator.getName(),
      content: this.renderInvestigatorDropdownLabel(investigator),
    };
  };
}

export default (props: any) => {
  return (
    <RequirePermission permission={permissions.Case.allocate}>
      <ActionAllocation {...props} />
    </RequirePermission>
  );
};
