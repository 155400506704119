import React, { useState, useEffect } from "react";
import State from "../../models/State";
import {
  Form,
  Dropdown,
  Button,
  Popup,
  DropdownItemProps,
} from "semantic-ui-react";
import StateDistrictRepository from "../../common/repository/StateDistrictRepository";
import StatesDistrictsRepository from "../../common/repository/StatesDistrictsRepository";
import Result from "../../common/repository/Result";
import { toast } from "./Toast";
import styled from "styled-components";

/**
 * The purpose of this component is to take input
 * state and district from user and provide the value
 * in encapsulated form i.e. LocationInputValue
 *
 * Value can be set with the value prop
 * New value can be obtained through onChange callback
 *
 * Value will be null unless both state and districts are selected
 */

const Container = styled.div`
  .field {
    margin: 0 !important
        ;
  }
`;

export interface LocationInputValue {
  state: string;
  district: string;
}

interface Props {
  value: LocationInputValue | null;
  onChange(location: LocationInputValue | null): void;
  stateDistrictRepo: StateDistrictRepository;

  inline?: boolean;
  required?: boolean;
  label?: JSX.Element;
  erase?: boolean;
  states?: State[];
}

const LocationInput = (props: Props) => {
  const stateToOption = (state: State) => {
    return {
      text: state.getName(),
      value: state.getName(),
      key: state.getCode(),
    };
  };
  const districtToOption = (district: string) => {
    return { text: district, value: district, key: district };
  };

  const {
    value,
    onChange,
    stateDistrictRepo,
    inline,
    required,
    label,
    erase,
    states,
  } = props;
  let internalValue: LocationInputValue = { state: "", district: "" };
  if (value !== null) internalValue = value;

  const internalOnChange = (key: string, value: string) => {
    if (key === "state") {
      onChange({ state: value, district: "" });
    } else {
      const newValue = { ...internalValue, [key]: value };
      onChange(newValue);
    }
  };

  const [loadingStates, setLoadingStates] = useState(false);
  const [stateOptions, setStateOptions] = useState(
    states && states.map((it) => stateToOption(it))
  );

  const [loadingDistricts, setLoadingDistricts] = useState(false);
  const [districtOptions, setDistrictOptions] = useState(
    [] as DropdownItemProps[]
  );

  /**
   * Effect hook to load the states
   */
  useEffect(() => {
    if (states !== undefined)
      //this means states are provided
      return;

    setLoadingStates(true);
    (async () => {
      const result = await stateDistrictRepo.getStates();
      if (result instanceof Result.Success) {
        const items = result.data.items;
        setStateOptions(items.map((it) => stateToOption(it)));
      } else {
        const message = result.message || "Could not load states";
        toast.error(message);
      }
      setLoadingStates(false);
    })();
  }, []);

  /**
   * Effect hook to load districts whenever
   * state changes
   */
  useEffect(() => {
    if (internalValue.state.trim().length === 0) return;

    setLoadingDistricts(true);
    (async () => {
      const result = await stateDistrictRepo.getDistricts({
        state: internalValue.state,
      });
      if (result instanceof Result.Success) {
        const items = result.data.items;
        setDistrictOptions(items.map((it) => districtToOption(it)));
      } else {
        const message = result.message || "Could not load districts";
        toast.error(message);
      }
      setLoadingDistricts(false);
    })();
  }, [internalValue.state]);

  return (
    <Container>
      {!inline && label}
      <Form.Group inline={inline}>
        <Form.Field required={required}>
          {inline && label}

          <Dropdown
            search
            selection
            placeholder="Select State"
            loading={loadingStates}
            options={stateOptions}
            value={internalValue.state}
            onChange={(_, { value }) => {
              internalOnChange("state", value as string);
            }}
          />
        </Form.Field>

        <Form.Field>
          <Dropdown
            search
            selection
            placeholder="Select District"
            loading={loadingDistricts}
            options={districtOptions}
            value={internalValue.district}
            onChange={(_, { value }) => {
              internalOnChange("district", value as string);
            }}
          />
        </Form.Field>

        {erase && (
          <Form.Field>
            <Popup
              inverted
              position="top center"
              content="Clear Selection"
              trigger={
                <Button
                  size="mini"
                  icon="erase"
                  onClick={() => onChange(null)}
                />
              }
            />
          </Form.Field>
        )}
      </Form.Group>
    </Container>
  );
};

export default React.memo(LocationInput);
