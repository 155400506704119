import React, { useState, useEffect, useContext } from 'react'
import { Table, Button, Modal as SemanticModal, Form, Icon } from 'semantic-ui-react'
import ClientRepository from '../../../../common/repository/ClientRepository'
import Result from '../../../../common/repository/Result'
import { toast } from '../../../common/Toast'
import ClientPageContext from './ClientPageContext'
import validator from '../../../../common/validator'


const ClaimTypeForm = () => {
    const clientRepo = new ClientRepository()
    const ctx = useContext(ClientPageContext)

    /** states */
    const [open, setOpen] = useState(false)
    const [values, setValues] = useState<Value[]>([])
    const [submitting, setSubmitting] = useState(false)

    const openModal = () => setOpen(true)
    
    const closeModal = () => setOpen(false)

    const onAdd = (value: Omit<Value, '_id'>) => {
        const newId = values.length.toString()
        setValues([...values, {...value, _id: newId}])
    }

    const onUpdate = (value: Value) => {
        const i = values.findIndex(it => it._id === value._id)
        if (i === -1) return
        values[i] = value
        setValues([...values])
    }

    const onRemove = (value: Value) => {
        const i = values.findIndex(it => it._id === value._id)
        if (i === -1) return
        values.splice(i, 1)
        setValues([...values])
    }


    /** hook to update claim types */
    useEffect(() => {
        if (!submitting) return
        if (ctx.client === null) return

        (async () => {
            const result = await clientRepo.updateClaimTypes({
                id: ctx.client!.getId(),
                claim_types: values.map(it => ({
                    title: it.title,
                    fee: parseFloat(it.fee),
                    gst_percentage: parseFloat(it.gstPercentage),
                    hsn_code: it.hsnCode
                }))
            })

            if (result instanceof Result.Success) {
                toast.success('Successfully update Claim Types')
                ctx.setClient(result.data)
            } else {
                const message = result.message || 'Something went wrong'
                toast.error(message)
            }
            setSubmitting(false)
        })()

    }, [submitting])


    /** hook to set claim types from model */
    useEffect(() => {
        if (ctx.client === null) return

        const values = ctx.client.getClaimTypes().map<Value>((it, i) => ({
            _id: i.toString(),
            title: it.getTitle(),
            fee: it.getFee().toString(),
            gstPercentage: it.getGSTPercentage().toString(),
            hsnCode: it.getHSNCode()
        }))
        setValues(values)

    }, [ctx.client])

    
    const rows = values.map(it => <Row value={it} onUpdate={onUpdate} onRemove={onRemove}/>)
    return (
        <>
            <div style={{ width: '60%' }}>
                <Table size='small' compact celled>
                    <TableHeader />
                    <Table.Body>
                        {rows}
                        <EmptyRow onAdd={openModal} />
                    </Table.Body>
                </Table>

                <Button
                    primary
                    onClick={() => setSubmitting(true)}
                    loading={submitting}
                    disabled={
                        submitting ||
                        values.length === 0
                    }>
                    Submit
                </Button>
            </div>
            {open && <Modal open={true} onClose={closeModal} onSubmit={onAdd}/>}
        </>
        
    )
}

/** Renders table's header row */
const TableHeader = () => {
    return (
        <Table.Header>
            <Table.Row>
                <Table.HeaderCell>Title</Table.HeaderCell>
                <Table.HeaderCell>Fee</Table.HeaderCell>
                <Table.HeaderCell>GST %</Table.HeaderCell>
                <Table.HeaderCell>HSN Code</Table.HeaderCell>
                <Table.HeaderCell>Action</Table.HeaderCell>
            </Table.Row>
        </Table.Header>
    )
}


/** Renders table's last row */
interface EmptyRowProps {
    onAdd?(): void
}

const EmptyRow = ({ onAdd }: EmptyRowProps) => {
    return (
        <Table.Row>
            <Table.Cell colSpan='5'>
                <Button onClick={onAdd} primary size='mini'>Add</Button>
            </Table.Cell>
        </Table.Row>
    )
}


/** Renders a row in table */
interface RowProps {
    value: Value
    onUpdate?(value: Value): void
    onRemove?(value: Value): void
}

const Row = ({ 
    value,
    onUpdate,
    onRemove
}: RowProps) => {
    const [open, setOpen] = useState(false)
    const openModal = () => setOpen(true)
    const closeModal = () => setOpen(false)

    return (
        <>
        <Table.Row>
            <Table.Cell>{value.title}</Table.Cell>
            <Table.Cell>{value.fee}</Table.Cell>
            <Table.Cell>{value.gstPercentage}</Table.Cell>
            <Table.Cell>{value.hsnCode}</Table.Cell>
            <Table.Cell>
                <Button icon size='mini' onClick={openModal}>
                    <Icon name='edit' />
                </Button>
                <Button icon negative size='mini' onClick={() => {
                    if (onRemove) onRemove(value)
                }}>
                    <Icon name='x' />
                </Button>
            </Table.Cell>
        </Table.Row>
        { open && (
            <Modal 
                open={open} 
                value={value} 
                onSubmit={onUpdate} 
                onClose={closeModal} />
        )}
        </>
    )
}


/** Modal to add or update a row */
interface ModalProps {
    open?: boolean
    value?: Value
    onSubmit?(data: Omit<Value, '_id'>): void
    onClose?(): void
}

const Modal = ({ 
    open,
    value, 
    onSubmit,
    onClose
}: ModalProps) => {
    const [title, setTitle] = useState(value && value.title || '')
    const [fee, setFee] = useState(value && value.fee || '')
    const [gstPercentage, setGstPercentage] = useState(value && value.gstPercentage || '')
    const [hsnCode, setHsnCode] = useState(value && value.hsnCode || '')

    return (
        <SemanticModal
            size='tiny'
            closeIcon
            closeOnDimmerClick={false}
            closeOnEscape={false}
            onClose={onClose}
            open={open}>
            <SemanticModal.Header>
                {value ? 'Update Claim Type' : 'Add New Claim Type'}
            </SemanticModal.Header>

            <SemanticModal.Content>
                <Form>
                    <Form.Input
                        label='Title'
                        placeholder='e.g. Cashess'
                        value={title}
                        onChange={(_, { value }) => setTitle(value)}/>

                    <Form.Input
                        label='Fee'
                        placeholder='e.g. 2500'
                        value={fee}
                        onChange={(_, { value }) => {
                            if (validator.number.isValid(value))
                                setFee(value)
                        }} />

                    <Form.Input
                        label='GST %'
                        placeholder='e.g. 18'
                        value={gstPercentage}
                        onChange={(_, { value }) => {
                            if (validator.number.isValid(value))
                                setGstPercentage(value)
                        }} />

                    <Form.Input
                        label='HSN Code'
                        placeholder='e.g. 07031010'
                        value={hsnCode}
                        onChange={(_, { value }) => setHsnCode(value)} />

                    <Button
                        primary
                        onClick={() => {
                            if (onSubmit) {
                                const data: any = { title, fee, gstPercentage, hsnCode }
                                if (value) data._id = value._id
                                onSubmit(data)
                            }

                            if (onClose) {
                                onClose()
                            }
                        }}
                        disabled={
                            title.trim().length === 0 ||
                            fee.trim().length === 0 ||
                            gstPercentage.trim().length === 0 ||
                            hsnCode.trim().length === 0
                        }>
                        Submit
                    </Button>

                </Form>
            </SemanticModal.Content>
        </SemanticModal>
    )
}



interface Value {
    _id: string //unique id to distinguish b/w values
    title: string
    fee: string
    gstPercentage: string
    hsnCode: string
}

export default ClaimTypeForm