import React from "react";

interface ContextType {
  value: string[];
  setValue?(value: string[]): void;
  valueValidator?(text: string): boolean;
  labelSuffix?: string;
}
const RangeInput2Context = React.createContext({} as ContextType);
export default RangeInput2Context;
