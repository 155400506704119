class LocalStorage {
  private static KEY_PREFIX = '__jdhealthcare__'
  static KEY_AUTH_TOKEN = `${LocalStorage.KEY_PREFIX}auth_token`
  static KEY_APP_STATE = `${LocalStorage.KEY_PREFIX}app_state`

  static set(key: string, value: string) {
    try {
      localStorage.setItem(key, value)
    } catch (e) {
      console.log(e)
    }
  }

  static get(key: string): string | null {
    try {
      return localStorage.getItem(key)
    } catch (e) {
      console.log(e)
      return null
    }
  }

  static remove(key: string) {
    try {
      localStorage.removeItem(key)
    } catch (e) {
      console.log(e)
    }
  }

  static setJson(key: string, json: any) {
    const value = JSON.stringify(json)
    LocalStorage.set(key, value)
  }

  static getJson(key: string): any | null {
    const value = LocalStorage.get(key)
    if (value) return JSON.parse(value)
    else return null
  }

  static removeJson(key: string) {
    LocalStorage.remove(key)
  }
}

export default LocalStorage
