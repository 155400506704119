import React, { useContext } from 'react'
import Page from '../../../layout/Page'
import DetailForm from './DetailForm'
import { Tab } from 'semantic-ui-react'
import ClaimTypeForm from './ClaimTypeForm'
import BillingAddressForm from './BillingAddressForm'
import ClientPageContext from './ClientPageContext'

const Layout = () => {
    const ctx = useContext(ClientPageContext)

    const MENU_ITEMS = [
        { menuItem: 'Detail', render: () => <DetailForm /> },
        { menuItem: 'Claim Type', render: () => ctx.client !== null ? <ClaimTypeForm /> : 'Please add a client first.'},
        { menuItem: 'Billing Address', render: () => ctx.client !== null ? <BillingAddressForm /> : 'Please add a client first.' },
    ]
    return (
        <Page
            title='Client'
            description='Add/Update your clients'>

            <Tab
                menu={{ secondary: true, pointing: true }}
                panes={MENU_ITEMS} />

        </Page>
    )
}

export default Layout