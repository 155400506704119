import DomainModel from './DomainModel'

export default class States extends DomainModel {
  constructor(private id: string, private name: string, private code: string, private GSTCode: string, private districts: []) {
    super()
  }
  getId = () => this.id
  getName = () => this.name
  getCode = () => this.code
  getGSTCode = () => this.GSTCode
  getDistrict = () => this.districts
}
