import React from 'react'
import { useDispatch } from 'react-redux'
import LocalStorage from "../../../../lib/LocalStorage";
import { setAuth } from "../../../../redux/auth/actioncreators";
import Index from "./index"

export default function CurrentUserLogOut() {
    const dispatch = useDispatch();

    const onLogout = () => {
        LocalStorage.remove(LocalStorage.KEY_AUTH_TOKEN);
        dispatch(setAuth(null));
    };
    return (
        <Index onLogout={onLogout} />
    )
}
