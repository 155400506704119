import React from "react";
import { Form, Input, Popup, Button } from "semantic-ui-react";
import FormLabel from "../../common/FormLabel";

/**
 * Render this Component inside a <Form />
 */

interface CaseFormInputSubjectLineProps {
  value: string;
  onChange(value: string): void;

  labelWidth?: string;
}

const CaseFormInputSubjectLine = (props: CaseFormInputSubjectLineProps) => {
  return (
    <Form.Group inline>
      <Form.Field required>
        <FormLabel width={props.labelWidth || "100px"}>Subject Line</FormLabel>
        <Input
          placeholder="Paste Email Subject Line"
          value={props.value}
          onPaste={(e: React.ClipboardEvent<HTMLInputElement>) =>
            props.onChange(e.clipboardData.getData("Text"))
          }
        />
      </Form.Field>
      <Form.Field>
        <Popup
          inverted
          content="Clear Field"
          position="top center"
          trigger={
            <Button
              size="mini"
              icon="erase"
              onClick={() => props.onChange("")}
            />
          }
        />
      </Form.Field>
    </Form.Group>
  );
};

export default React.memo(CaseFormInputSubjectLine);
