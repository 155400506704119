import ProtectedAPIRepository from './ProtectedAPIRepository'
import InvestigatorAllowanceDataMapper from '../datamapper/InvestigatorAllowanceDataMapper'
import APIResponseModel from './APIResponseModel'
import InvestigatorAllowanceRM from '../responsemodel/InvestigatorAllowanceRM'
import Result from './Result'

class InvestigatorAllowanceRepository extends ProtectedAPIRepository {
  private dataMapper = new InvestigatorAllowanceDataMapper()

  getInvestigatorAllowance = async () => {
    const response = await this.get<APIResponseModel<InvestigatorAllowanceRM>>('/investigatorAllowance')
    if (response instanceof Result.Success) {
      const data: APIResponseModel<InvestigatorAllowanceRM> = response.data
      if (data.data !== null) {
        return new Result.Success(this.dataMapper.map(data.data.items[0]))
      } else {
        return new Result.Error(0, 'Data is null')
      }
    } else {
      return response
    }
  }

  updateInvestigatorAllowance = async (ro: UpdateInvestigatorAllowanceRequestObject) => {
    const response = await this.patch<APIResponseModel<InvestigatorAllowanceRM>>('/investigatorAllowance', ro)
    if (response instanceof Result.Success) {
      const data: APIResponseModel<InvestigatorAllowanceRM> = response.data
      if (data.data !== null) {
        return new Result.Success(this.dataMapper.map(data.data.items[0]))
      } else {
        return new Result.Error(0, 'Data is null')
      }
    } else {
      return response
    }
  }
}

export interface UpdateInvestigatorAllowanceRequestObject {
  id: string
  primary_location: number
  secondary_location: number
  tertiary_location: number
}

export default InvestigatorAllowanceRepository
