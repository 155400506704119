import DomainModel from './DomainModel'

export default class CaseType extends DomainModel {
  constructor(private id: string, private title: string, private caseCategoryCode: string, private internalTAT: number, private investigatorTAT: number, private initialCasePortionCapping: number, private services: [], private required_documents: []) {
    super()
  }

  getId = () => this.id
  getTitle = () => this.title
  getCaseCategoryCode = () => this.caseCategoryCode
  getInternalTAT = () => this.internalTAT
  getInvestigatorTAT = () => this.investigatorTAT
  getInitialCasePortionCapping = () => this.initialCasePortionCapping

  static toDays = (hours: number) => Math.floor(hours / 9)
  static toHours = (days: number) => days * 9

  static getTATPair = (hours: number): [number, number] => {
    const days = CaseType.toDays(hours)
    const remainingHours = hours % 9
    return [days, remainingHours]
  }

  getFormattedInternalTAT = () => this.getFormattedTAT(this.internalTAT)
  getFormattedInvestigatorTAT = () => this.getFormattedTAT(this.investigatorTAT)
  getServices = () => this.services
  getRequiredDocumne = () => this.required_documents

  private getFormattedTAT = (TAT: number): [number, 'days' | 'hours'] => {
    if (TAT % 9 !== 0) return [TAT, 'hours']
    else return [TAT / 9, 'days']
  }

  static formatTAT = (hours: number) => {
    if (hours < 9) return `${hours} hour${hours === 1 ? '' : 's'}`

    const days = Math.floor(hours / 9)
    const hrs = hours % 9

    if (hrs === 0) return `${days} day${days === 1 ? '' : 's'}`
    else return `${days} day${days === 1 ? '' : 's'}, ${hrs} hour${hrs === 1 ? '' : 's'}`
  }
}
