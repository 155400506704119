
import ProtectedAPIRepository from './ProtectedAPIRepository'
import APIResponseModel from './APIResponseModel'
import Result from './Result'
import LeaveRequestRM from '../responsemodel/LeaveReruestRM'
import LeaveRequestDataMapper from '../datamapper/LeaveRequestDatamapper'

export default class LeaveRequestRepository extends ProtectedAPIRepository {

    private leaveReqDataMapper = new LeaveRequestDataMapper()

    getLeaveRequest = async (ro?: GetLeaveRequestObject) => {
        const response = await this.post<APIResponseModel<LeaveRequestRM>>('/leave/getAllLeave', ro)
        if (response instanceof Result.Success) {
            const data: APIResponseModel<LeaveRequestRM> = response.data
            if (data.data !== null) {
                const leavesReq = data.data.items.map((it) => this.leaveReqDataMapper.map(it))
                return new Result.Success({
                    totalItemCount: data.data.total_item_count,
                    items: leavesReq,
                })
            } else {
                return new Result.Error(0, 'Data is null')
            }
        } else {
            return response
        }
    }
    updateLeave = async (ro: UpdateLeaveRequestObject) => {
        const response = await this.post<APIResponseModel<LeaveRequestRM>>('/leave/updateLeaveStatus', ro)
        if (response instanceof Result.Success) {
            const data: APIResponseModel<LeaveRequestRM> = response.data
            if (data.data !== null) {
                const leavesReq = this.leaveReqDataMapper.map(data.data.items[0])
                return new Result.Success(leavesReq)
            } else {
                return new Result.Error(0, 'Data is null')
            }
        } else {
            return response
        }
    }
    getLeaveRequestDetails = async (ro?: GetLeaveRequestDetails) => {
        const response = await this.post<APIResponseModel<LeaveRequestRM>>('/leave/getLeaveById', ro)
        if (response instanceof Result.Success) {
            const data: APIResponseModel<LeaveRequestRM> = response.data
            if (data.data !== null) {
                return response.data.data?.items
                // const leavesReq = data.data.items.map((it) => this.leaveReqDataMapper.map(it))
                // console.log('leavesReq', leavesReq);

                // return new Result.Success({
                //     totalItemCount: data.data.total_item_count,
                //     items: leavesReq,
                // })
            }
            // else {
            //     return new Result.Error(0, 'Data is null')
            // }
        }
    }
}

export interface GetLeaveRequestDetails {
    id: any
}
export interface GetLeaveRequestObject {
    limit: number
    offset: number
    search?: string
    status?: string
    startDate?: string
    endDate?: string
}

export interface UpdateLeaveRequestObject {
    id: string
    status: string
    rejectedReason?: any
}


