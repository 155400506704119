import React from "react";
import { Table, Segment } from "semantic-ui-react";

interface HospitalInfrastructureDataTableProps {
    data: any;
}

const HospitalInfrastructureDataTable = (props: HospitalInfrastructureDataTableProps) => {
    const { data } = props;
    return (
        <>
            <Table striped compact celled structured>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Hospital</Table.HeaderCell>
                        <Table.HeaderCell>Hospital Type</Table.HeaderCell>
                        <Table.HeaderCell width={3}>Hospital Reg Number</Table.HeaderCell>
                        <Table.HeaderCell>Icu Facility</Table.HeaderCell>
                        <Table.HeaderCell>InHouse Lab</Table.HeaderCell>
                        <Table.HeaderCell>InHouse Pharmacy</Table.HeaderCell>
                        <Table.HeaderCell>Number of Doctor </Table.HeaderCell>

                    </Table.Row>
                </Table.Header>
                <Table.Body style={{ fontWeight: 500 }}>

                    {data.map((item: any) =>
                        <Table.Row>
                            <Table.Cell>{item.hospitalData.name}</Table.Cell>
                            <Table.Cell width={3}>{item.hospitalType}</Table.Cell>
                            <Table.Cell width={3}>{item.hospital_reg_number}</Table.Cell>
                            <Table.Cell>{item.icu_facility}</Table.Cell>
                            <Table.Cell>{item.inHouse_lab}</Table.Cell>
                            <Table.Cell>{item.inHouse_pharmacy}</Table.Cell>
                            <Table.Cell>{item.number_of_doctor || "--"}</Table.Cell>

                        </Table.Row>
                    )}

                </Table.Body>
            </Table>
            {!data.length && <Segment basic textAlign={"center"}>
                No Record Found
            </Segment>
            }
        </>
    );
};

export default React.memo(HospitalInfrastructureDataTable);
