import React from "react";
import { Divider, Form, Input, Radio } from "semantic-ui-react";
import Button from "../../../elements/Button";
import DocDispatchSection from "../../../../models/DocDispatchSection";
import Result from "../../../../common/repository/Result";
import { toast } from "../../../common/Toast";
import DocDispatchSectionRepository from "../../../../common/repository/DocDispatchSectionRepository";
import FormLabel from "../../../common/FormLabel";
import DateTimePicker from "../../../app/DateTimePicker";
import constants from "../../../../common/constants";

interface Props {
  //if model is passed that means update
  model?: DocDispatchSection;

  onSuccess: (invoiceSection: DocDispatchSection) => void;
  onError?: () => void;
}

interface Dispatch {
  date: string;
  slip_url: string | null;
}

interface State {
  form_loading: boolean;

  claim_number: string;
  case_type_title: string;
  dispatch: Dispatch | null;
  PODReceipt: File | null;
}

class InvoiceUpdateForm extends React.Component<Props, State> {
  private paymentSectionRepo = new DocDispatchSectionRepository();

  constructor(props: Props) {
    super(props);

    const stateFromModel = this.deriveDocDispatchSectionStateFromModel();
    this.state = {
      form_loading: false,
      PODReceipt: null,
      ...stateFromModel,
    };
  }

  private deriveDocDispatchSectionStateFromModel = () => {
    const model = this.props.model;
    let dispatch: {
      date: string;
      slip_url: string | null;
    } = {
      date: "",
      slip_url: null,
    };

    if (model?.getDispatch() != null) {
      dispatch = {
        date: model.getDispatch()!.getDate(),
        slip_url: model.getDispatch()!.getSlipUrl(),
      };
    }

    return {
      claim_number: (model && model.getClaimNumber()) || "",
      case_type_title: (model && model.getCaseTypeTitle()) || "",
      dispatch,
    };
  };

  private onSubmit = () => {
    this.setState({ form_loading: true }, () => {
      this.updateDocDispatchSection();
    });
  };

  private updateDocDispatchSection = async () => {
    if (this.state.PODReceipt == null) toast.error("Please Choose POD slip");
    else {
      let ro = {
        claim_number: this.state.claim_number,
        pod_receipt: this.state.PODReceipt,
        dispatch: {
          date: this.state.dispatch!.date,
        },
      };

      const result = await this.paymentSectionRepo.updateData(ro);

      if (result instanceof Result.Success) {
        const receivedData = result.data;
        this.setState({ form_loading: false }, () => {
          toast.success("Information updated successfully");
          this.props.onSuccess(receivedData);
        });
      } else {
        this.setState({ form_loading: false }, () => {
          const message = result.message || "Something went wrong";
          toast.error(message);
          if (this.props.onError) this.props.onError();
        });
      }
    }
  };

  render(): JSX.Element {
    let PODReceipt = null;
    if (this.state.dispatch?.slip_url != null) {
      if (typeof this.state.dispatch.slip_url == "string")
        PODReceipt = (
          <a
            className="ui primary button"
            href={this.state.dispatch!.slip_url}
            target="_blank"
          >
            {" "}
            View Old Receipt{" "}
          </a>
        );
    }
    return (
      <Form loading={this.state.form_loading}>
        {/* Not changeable fields */}
        <Form.Group>
          <Form.Input
            width="8"
            label="Claim Number"
            value={this.state.claim_number}
          />
          <Form.Input
            width="8"
            label="Case Type"
            value={this.state.case_type_title}
          />
        </Form.Group>
        <Divider />
        {/* not changeable fields */}

        <Form.Group>
          <Form.Field required>
            <FormLabel width={"100%"}>Dispatch Date</FormLabel>
            <Input>
              <DateTimePicker
                type="date"
                placeholder="Pick Dispatch Date"
                value={this.state.dispatch?.date}
                onChange={(value) =>
                  this.setState((prevState) => {
                    let dispatch = Object.assign({}, prevState.dispatch);
                    dispatch.date = value;
                    return { dispatch };
                  })
                }
              />
            </Input>
          </Form.Field>
        </Form.Group>
        <Form.Group>
          {PODReceipt}
          <Form.Field>
            <FormLabel width="100px">POD Receipt</FormLabel>
            <Input
              type="file"
              accept="image/*, .pdf, .doc, .docx, .csv, .xls, .xlsx"
              onChange={(e) => {
                if (e.target.files) {
                  const files = e.target.files;
                  let fileArr: File[] = [];
                  for (let i = 0; i < files.length; i++) fileArr.push(files[i]);
                  this.setState({ PODReceipt: fileArr[0] });
                }
              }}
            />
          </Form.Field>
        </Form.Group>

        <Button
          positive
          onClick={this.onSubmit}
          disabled={
            this.state.dispatch!.date === "" || this.state.PODReceipt === null
          }
        >
          Submit
        </Button>
      </Form>
    );
  }
}

export default InvoiceUpdateForm;
