import UserRM from '../responsemodel/UserRM'
import User from '../../models/User'
import DataMapper from './DataMapper'
import RoleDataMapper from './RoleDataMapper'
import LocationDataMapper from './LocationDataMapper'

export default class UserDataMapper extends DataMapper<UserRM, User> {
  private roleDataMapper = new RoleDataMapper()
  private locationDataMapper = new LocationDataMapper()

  map = (rm: UserRM): User => {
    const role = this.roleDataMapper.map(rm.role)
    const locations = rm.locations.map((it) => it.map((it) => this.locationDataMapper.map(it)))

    return new User(rm.id, rm.emp_id, rm.name, rm.email, rm.other_email, rm.phone, rm.other_phone, rm.joining_date, role, locations, rm.address, rm.dob, rm.profile_pic, rm.parent, rm.is_active, rm.clients, rm.case_categories, rm.investigator_location)
  }
}
