import React, { useContext, useState, useEffect } from 'react'
import Layout from './Layout'
import { useParams } from 'react-router-dom'
import ClientPageContext from './ClientPageContext'
import Client from '../../../../models/Client'
import ClientRepository from '../../../../common/repository/ClientRepository'
import Result from '../../../../common/repository/Result'
import { toast } from '../../../common/Toast'

const ClientPage = () => {
  const params = useParams<{ clientId: string }>()
  const ctx = useContext(ClientPageContext)
  const clientRepo = new ClientRepository()

  /** states */
  const [loadingClient, setLoadingClient] = useState(false)
  const [loadClient, setLoadClient] = useState(false)
  const [client, setClient] = useState<Client | null>(null)

  /**
   * Load client from API if
   *  its update page
   *  client isnt loaded yet.
   */
  if (params.clientId !== undefined && client === null && loadClient === false) {
    setLoadClient(true)
  }

  /** effects */
  useEffect(() => {
    if (loadClient === false) return

    setLoadingClient(true)
    ;(async () => {
      const result = await clientRepo.getClients({ id: params.clientId })
      if (result instanceof Result.Success) {
        const client = result.data.items[0]
        setClient(client)
      } else {
        const message = result.message || 'Could not load Client'
        toast.error(message)
      }
      setLoadClient(false)
      setLoadingClient(false)
    })()
  }, [loadClient])

  return (
    <ClientPageContext.Provider
      value={{
        loadingClient,
        setLoadingClient,
        loadClient,
        setLoadClient,
        client,
        setClient,
        clientRepo,
      }}
    >
      <Layout />
    </ClientPageContext.Provider>
  )
}

export default ClientPage
