import React, { useState, useEffect } from 'react'
import Layout from './Layout'
import permissions from '../../../../common/permissions'
import RequirePermission from '../../../base/RequirePermission'
import ClientListingPageContext from './ClientListingPageContext'
import Client from '../../../../models/Client'
import Table from '../../../app/Table'
import ClientRepository, { GetClientsRequestObject } from '../../../../common/repository/ClientRepository'
import Result from '../../../../common/repository/Result'
import { toast } from '../../../common/Toast'

const ClientListingPage = () => {
  const clientRepo = new ClientRepository()

  /** state */
  const [loading, setLoading] = useState(false)
  const [clients, setClients] = useState<Client[]>([])
  const [filter, setFilter] = useState('')
  const [limit, setLimit] = useState(10)
  const [offset, setOffset] = useState(0)
  const [count, setCount] = useState(0)
  const [load, setLoad] = useState(true)
  const [remove, setRemove] = useState(false)
  const [clientToRemove, setClientToRemove] = useState<Client | null>(null)
  const [removeCallback, setRemoveCallback] = useState<(() => void) | null>(null)

  /** effects */
  useEffect(() => {
    if (!load) return

    /** initialize request obect */
    const ro: GetClientsRequestObject = {
      limit: limit,
      offset: offset,
      search: filter,
    }

    setLoading(true)
    ;(async () => {
      const result = await clientRepo.getClients(ro)
      if (result instanceof Result.Success) {
        if (offset === 0) {
          setClients(result.data.items)
          setCount(result.data.totalItemCount)
          setOffset(result.data.items.length)
        } else {
          const totalClients = [...clients, ...result.data.items]
          setClients(totalClients)
          setOffset(totalClients.length)
        }
      } else {
        const message = result.message || 'Something went wrong'
        toast.error(message)
      }
      setLoading(false)
      setLoad(false)
    })()
  }, [load])

  useEffect(() => {
    if (!remove) return
    if (clientToRemove === null) return
    ;(async () => {
      const result = await clientRepo.removeClient(clientToRemove.getId())
      if (result instanceof Result.Success) {
        const index = clients.findIndex((it) => it.getId() === clientToRemove.getId())
        if (index === -1) return
        clients.splice(index, 1)
        setClients([...clients])
        setCount(count - 1)
        setClientToRemove(null)
        if (removeCallback) removeCallback()
        setRemoveCallback(null)
      } else {
        const message = result.message || 'Something went wrong'
        toast.error(message)
      }
    })()
  }, [remove])

  return (
    <ClientListingPageContext.Provider
      value={{
        loading,
        setLoading,
        clients,
        setClients,
        filter,
        setFilter,
        limit,
        setLimit,
        offset,
        setOffset,
        count,
        setCount,
        load: () => setLoad(true),
        remove: (client: Client, cb: () => void) => {
          setRemove(true)
          setClientToRemove(client)
          setRemoveCallback(cb)
        },
      }}
    >
      <Layout />
    </ClientListingPageContext.Provider>
  )
}

export default (props: any) => {
  return (
    <RequirePermission permission={permissions.Master.CRUD}>
      <ClientListingPage {...props} />
    </RequirePermission>
  )
}
