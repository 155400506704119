import { useSelector } from "react-redux";
import { AppState } from "../../redux";

interface Props {
  permission: string | Array<string>;
  any?: boolean;
  children: any;
}

const RequirePermission = (props: Props) => {
  const { permission, any } = props;
  const { user } = useSelector((state: AppState) => state.auth);

  if (user === null) return null;

  if (Array.isArray(permission)) {
    if (any) return user.hasAnyPermission(permission) ? props.children : null;
    else return user.hasAllPermissions(permission) ? props.children : null;
  } else {
    return user.hasPermission(permission) ? props.children : null;
  }
};

export default RequirePermission;
