import DataMapper from './DataMapper'
import BranchRM from '../responsemodel/BranchRM'
import Branch from '../../models/Branch'

class BranchDataMapper extends DataMapper<BranchRM, Branch> {
  map = (rm: BranchRM) => {
    return new Branch(rm.state, rm.gst_number, rm.locations)
  }
}

export default BranchDataMapper
