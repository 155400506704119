import React from 'react'
import Page from '../../../layout/Page'
import { Modal, Icon, Button, DropdownItemProps, Segment, Header, Form } from 'semantic-ui-react'
import Result from '../../../../common/repository/Result'
import { toast } from '../../../common/Toast'
import Table from '../../../app/Table'
import RequirePermission from '../../../base/RequirePermission'
import permissions from '../../../../common/permissions'
import Filters from './Filters'
import FilterPageContext from './FilterPageContext'
import MrdChargeColumnRenderer from "./CanceliedByInvestigatorColumnRenderer"
import MrdCharges from "../../../../models/MrdCharges"

import CancelledByInvestigatorList from '../../../../models/CancelledByInvestigatorList'
import InvestigatoeActivityRepository, { CancelledByInvestigatorListObject } from '../../../../common/repository/InvestigatorActivityRepository'

const statusData = ["generated", "open"]
const statusDatas = statusData.map((it) => {
    return { text: it, value: it, key: it };
});

interface State {
    loading: boolean
    filter: string
    limit: number
    offset: number
    totalCount: number
    CancelledByInve: CancelledByInvestigatorList[]
    loadingEssentialData: boolean
    statusFilter: string
    statusOptions: DropdownItemProps[]
}
interface Props {
    ismodel: MrdCharges;
}

class CancelledByInvestigatorListPage extends React.Component<Props, State> {

    private InvestigatoeActivityRepository = new InvestigatoeActivityRepository()

    private columnRenderer = new MrdChargeColumnRenderer(
        (model: CancelledByInvestigatorList) => {
            return (<>
                {(model.getCaseState() == "generated" ? <Button color="green">{model.getCaseState()} </Button> : <Button color="red">{model.getCaseState()}</Button>)}
            </>)
        },
        (obj: any) => true
    )

    constructor(props: any) {
        super(props)

        this.state = {
            loading: false,
            limit: 10,
            offset: 0,
            filter: '',
            totalCount: 0,
            CancelledByInve: [],
            loadingEssentialData: true,
            statusFilter: '',
            statusOptions: statusDatas,
        }
        console.log(this.props.ismodel?.getId(), "rops")
    }

    async componentDidMount() {
        this.loadData()
        // await this.effectLoadEssentialData()
    }
    private onRowsPerPageChange = (page: number, rowsPerPage: number) => {
        // console.log('onRowsPerPageChange', page, rowsPerPage)
        const offset = (page - 1) * rowsPerPage,
            limit = offset + rowsPerPage
        this.setState({ offset, limit }, this.loadData)
    }

    private setFilter = (filter: string) => {
        this.setState({ filter, offset: 0 }, this.loadData)
    }

    private clearAllFilters = async () => {
        await this.setState({
            limit: 0,
            offset: 0,
            statusFilter: '',
        })
        await this.loadData()
    }

    private getCancelledByInvestigatorList = async () => {
        const ro: CancelledByInvestigatorListObject = {
            limit: this.state.limit,
            offset: this.state.offset,
            search: this.state.filter,
        }

        if (this.state.statusFilter) ro.case_state = this.state.statusFilter

        return await this.InvestigatoeActivityRepository.getCancelledByInvestigatorList(ro)
    }

    private loadData = async () => {
        if (this.state.statusFilter) {
            await this.setState({ offset: 0 })
        }
        let offset = this.state.offset

        this.setState({ loading: true }, async () => {
            const result = await this.getCancelledByInvestigatorList()

            if (result instanceof Result.Success) {
                if (offset === 0) {
                    //if offset 0 initialize as new list
                    const CancelledByInve = result.data.items
                    const totalCount = result.data.totalItemCount
                    await this.setState({
                        loading: false,
                        offset: CancelledByInve.length,
                        CancelledByInve,
                        totalCount: totalCount,
                    })
                } else {
                    //else check for duplicate items in the new data
                    // and then concat with the old
                    const CancelledByInve = [...this.state.CancelledByInve, ...result.data.items]
                    const offset = CancelledByInve.length
                    this.setState({ loading: false, offset, CancelledByInve })
                }
            } else {
                const message = result.message || 'Something went wrong'
                await this.setState({ loading: false }, () => toast.error(message))
            }
        })
    }

    private setStatsFilter = (val: string) => this.setState({ statusFilter: val })

    render() {
        return (
            <FilterPageContext.Provider
                value={{
                    options: {
                        statusData: this.state.statusOptions,
                    },
                    filters: {
                        status: this.state.statusFilter,
                        setStatus: this.setStatsFilter,
                        applyFilters: this.loadData,
                        clearAllFilters: this.clearAllFilters,
                    },
                }}
            >
                <Page title="Cancelled By Investigator" description="Manage Cancelled By Investigator">

                    <Segment>
                        <Filters />
                    </Segment>

                    <Table
                        renderer={this.columnRenderer}
                        data={this.state.CancelledByInve}
                        totalDataCount={this.state.totalCount}
                        loading={this.state.loading}
                        load={this.loadData}
                        onRowsPerPageChange={this.onRowsPerPageChange}
                        onSearch={this.setFilter}
                        onClear={() => this.setFilter('')}
                    />
                </Page>
            </FilterPageContext.Provider>
        )
    }
}

export default (props: any) => {
    return (
        <RequirePermission permission={permissions.Master.CRUD}>
            <CancelledByInvestigatorListPage {...props} />
        </RequirePermission>
    )
}
