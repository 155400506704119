import ProtectedAPIRepository from './ProtectedAPIRepository'
import APIResponseModel from './APIResponseModel'
import Result from './Result'
import PaymentSectionRM from '../responsemodel/PaymentSectionRM'
import PaymentSectionDataMapper from '../datamapper/PaymentSectionDataMapper'
import PaymentSection from '../../models/PaymentSection'

export default class PaymentSectionRepository extends ProtectedAPIRepository {
  private dataMapper = new PaymentSectionDataMapper()

  getData = async (ro?: GetPaymentSectionRequestObject) => {
    const response = await this.post<APIResponseModel<PaymentSectionRM>>('/billing/payment', ro)
    if (response instanceof Result.Success) {
      const data: APIResponseModel<PaymentSectionRM> = response.data
      if (data.data !== null) {
        const items = data.data.items.map((it) => this.dataMapper.map(it))
        return new Result.Success({
          totalItemCount: data.data.total_item_count,
          items: items,
        })
      } else {
        return new Result.Error(0, 'Data is null')
      }
    } else {
      return response
    }
  }

  updateData = async (ro: UpdatePaymentSectionRM) => {
    const formData = this.constructFormData(ro)
    const response = await this.patch<APIResponseModel<PaymentSectionRM>>('/billing/payment', formData)
    if (response instanceof Result.Success) {
      const data: APIResponseModel<PaymentSectionRM> = response.data
      if (data.data !== null) {
        const receivedData = this.dataMapper.map(data.data.items[0])
        return new Result.Success(receivedData)
      } else {
        return new Result.Error(0, 'Data is null')
      }
    } else {
      return response
    }
  }

  uploadPaymentPendencySheet = async (file: File) => {
    const formData = this.constructFormData({ sheet: file })
    const response = await this.patch<APIResponseModel<number[]>>('/billing/payment/uploadTemplate', formData)
    if (response instanceof Result.Success) {
      const data: APIResponseModel<number[]> = response.data
      if (data.data !== null) {
        let receivedData = data.data.items
        return new Result.Success({
          totalItemCount: data.data.total_item_count,
          items: receivedData,
        })
      } else {
        return new Result.Error(0, 'Data is null')
      }
    } else {
      return response
    }
  }

  uploadPaymentReceipts = async (files: File[]) => {
    const formData = this.constructFormData({ sheet: files })
    // const response = await this.patch<APIResponseModel<PaymentSectionRM>>('/billing/invoice/uploadReceipts',
    // formData)
    const response = await this.patch<APIResponseModel<string[]>>('/billing/payment/uploadReceipts', formData)

    if (response instanceof Result.Success) {
      // const data: APIResponseModel<PaymentSectionRM> = response.data
      const data: APIResponseModel<string[]> = response.data
      if (data.data !== null) {
        // let receivedData: PaymentSection[] = [];
        // for(let i=0; i< data.data.items.length; i++){
        //     let it= data.data.items[i];
        //     receivedData = receivedData.concat(this.dataMapper.map(it))
        // }

        let receivedData = data.data.items
        return new Result.Success({
          totalItemCount: data.data.total_item_count,
          items: receivedData,
        })
      } else {
        return new Result.Error(0, 'Data is null')
      }
    } else {
      return response
    }
  }
}

export interface GetPaymentSectionRequestObject {
  id?: string
  case_id?: string
  claim_number?: string
  client_id?: string
  client_name?: string
  case_type_id?: string
  case_type_title?: string
  invoice_number?: string

  docReceived?: boolean
  paymentPending?: boolean
  paymentStatus?: string
  paymentReceiptUploadPending?: boolean

  from_invoice_date?: string
  to_invoice_date?: string
  from?: string
  to?: string
  from_payment_date?: string
  to_payment_date?: string
  search?: string
  limit?: number
  offset?: number

  reinvestigation?: boolean
  pushback?: boolean
}

export interface UpdatePaymentSectionRM {
  claim_number: string

  payment_receipt?: File
  payment: {
    tds: number
    received_amount: number
    payment_date: string
    utr_no: string

    settled: boolean
    status: string
  }
}
