import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Segment, Header, Grid, Form, Divider, Button } from "semantic-ui-react";
import FilesPopup from "../../FilesPopup";
import AllocatedClaimAnalyst from "../../../../../../models/AllocatedClaimAnalyst";
import AllocatedInvestigator from "../../../../../../models/AllocatedInvestigator";
import validator from "../../../../../../common/validator";
import constants from "../../../../../../common/constants";
import Location from "../../../../../../models/Location";
import CaseFormInputLocation from "../../../CaseFormInputLocation";
import StateDistrictRepository from "../../../../../../common/repository/StateDistrictRepository";

import CompleteCase from "../../../../../../models/CompleteCase";
import CaseRepository from "../../../../../../common/repository/CaseRepository";
import Result from "../../../../../../common/repository/Result";
import moment from "moment";

export interface InvestigationCardInputValue {
  id: string;
  claimAnalystId: string;
  allocationHygiene: number;
  reportQuality: number;
  outcome: number;
  tat: number;
  feedback: string;
  investigators: {
    id: string;
    allocationId: string;
    capping: string;
    outcome: string;
    investigationLocation: Location | null;
    allowance: number;
    documentExpense: number;
    extraExpense: number;
    approvedExpense: number;
  }[];
}

interface Props {
  model: AllocatedClaimAnalyst;
  value: InvestigationCardInputValue;
  onChange?(value: InvestigationCardInputValue): void;
  newData: CompleteCase;
}

const InvestigationCard = (props: Props) => {
  const { model, value, onChange, newData } = props;
  const portalRoot = document.getElementsByTagName("body")[0];

  const repo = new CaseRepository();
  const [lastUser, setLastUser] = useState<any>([])

  const internalOnChange = <T extends keyof InvestigationCardInputValue>(
    key: T,
    v: any
  ) => {
    const newValue = { ...value, [key]: v };
    if (onChange) onChange(newValue);
  };
  useEffect(() => {
    getLastUser()
  }, [])

  async function getLastUser() {
    const result = await repo.getLastActivityUser(newData.getId());
    console.log(result?.items)
    setLastUser(result?.items)
    if (result instanceof Result.Success) {
      setLastUser(result.data.items);
    }
  }

  return (
    <Segment>
      {/* <Heade>{model.getInvestigator().getName()} </Heade> */}
      {lastUser && <Header as="h3">
        {`State: ${lastUser?.state}`}<br />
        {lastUser?.name}<br />
        {moment(lastUser.date).format("LL")}
      </Header>}
      {model.getAllocatedInvestigators().map((it, i) => (
        <>
          <InvestigatorListItem
            model={it}
            value={value}
            portalRoot={portalRoot}
            onChange={internalOnChange}
          />
          {i === model.getAllocatedInvestigators().length - 1 ? null : (
            <Divider />
          )}
        </>
      ))}
    </Segment>
  );
};

interface InvestigatorListItemProps {
  model: AllocatedInvestigator;
  value: InvestigationCardInputValue;
  portalRoot: HTMLElement;
  onChange?(key: string, value: any): void;
}

const InvestigatorListItem = (props: InvestigatorListItemProps) => {
  const { model, value, portalRoot, onChange } = props;
  const [open, setOpen] = useState(false);
  const [documentReceiptVisible, setDocumentReceiptVisible] = useState(false);
  const [expenseReceiptVisible, setExpenseReceiptVisible] = useState(false);
  const [approvedReceiptVisible, setApprovedReceiptVisible] = useState(false);
  const [x, setX] = useState(0);
  const [y, setY] = useState(0);

  const stateDistrictRepo = new StateDistrictRepository();

  const internalValue = value.investigators.find(
    (it) => it.allocationId === model.getId()
  );
  if (internalValue === undefined) return null;

  const internalOnChange = (key: string, v: string) => {
    const i = value.investigators.findIndex(
      (it) => it.allocationId === model.getId()
    );
    if (i === -1) return;

    // let invLocState = key=='locState'? v : internalValue.investigationLocation?.getState() ? internalValue.investigationLocation?.getState() : '';
    // let invLocDistrict = key=='locDistrict'? v : internalValue.investigationLocation?.getDistrict() ? internalValue.investigationLocation?.getDistrict() : '';
    // let invLoc = new Location(invLocState, invLocDistrict);
    // let newValue = { ...internalValue, [key]: v, investigationLocation: invLoc };

    let newValue = { ...internalValue, [key]: v };

    if (key == "invLoc") {
      let loc = JSON.parse(v);
      let invLoc = new Location(loc.state, loc.district);
      newValue = { ...newValue, investigationLocation: invLoc };
    }

    value.investigators[i] = newValue;
    if (onChange) onChange("investigators", [...value.investigators]);
  };

  const setInvLocation = (state: string, district: string) => {
    internalOnChange(
      "invLoc",
      JSON.stringify({ state: state, district: district })
    );
  };

  const evidence = model.getEvidence();
  const documentReceipts = model.getDocumentExpenseReceipt();
  const extraExpenseReceipts = model.getExtraExpenseReceipt();
  const approvedExpenseReceipts = model.getApprovedExpenseReceipt();

  const options = Object.values(constants.caseOutcome).map((it) => ({
    text: it,
    value: it,
    key: it,
  }));
  return (
    <>
      <Header as="h5">
        {model.getInvestigator().getName()} &nbsp;&nbsp;&nbsp;
        <Button
          onClick={(e) => {
            setX(e.clientX);
            setY(e.clientY);
            setOpen(true);
          }}
          disabled={open || evidence.length === 0}
          primary
        >
          {evidence.length === 0 ? "No Evidence" : "View Evidence"}
        </Button>
      </Header>
      <Grid columns={5}>
        <Grid.Row>
          <Grid.Column>
            <Form.Dropdown
              search
              selection
              label="Outcome"
              value={internalValue.outcome}
              options={options}
              onChange={(_, { value }) =>
                internalOnChange("outcome", value as string)
              }
            />
          </Grid.Column>
          <Grid.Column>
            <Form.Input
              label="Case Portion"
              value={internalValue.capping}
              onChange={(_, { value }) => {
                if (validator.casePortion.isValid(value) || value === "")
                  internalOnChange("capping", value);
              }}
            />
          </Grid.Column>
          {/*<Grid.Column>*/}
          {/*    <Form.Input*/}
          {/*        label='Investigation State'*/}
          {/*        value={internalValue.investigationLocation? `${internalValue.investigationLocation.getState()}` : ''}*/}
          {/*        onChange={(_, { value }) => {*/}
          {/*            internalOnChange("locState",value)*/}
          {/*        }}*/}
          {/*    />*/}
          {/*</Grid.Column>*/}
          {/*<Grid.Column>*/}
          {/*    <Form.Input*/}
          {/*        label='Investigation District'*/}
          {/*        value={internalValue.investigationLocation? `${internalValue.investigationLocation.getDistrict()}`: ''}*/}
          {/*        onChange={(_, { value }) => {*/}
          {/*            internalOnChange("locDistrict",value)*/}
          {/*        }}*/}
          {/*    />*/}
          {/*</Grid.Column>*/}
          <Grid.Column>
            <Form.Input
              label="Allowance"
              value={internalValue.allowance}
              onChange={(_, { value }) => {
                internalOnChange("allowance", value);
              }}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <CaseFormInputLocation
              labelWidth="150px"
              label="Investigation Location"
              value={{
                state: internalValue.investigationLocation?.getState() || "",
                district:
                  internalValue.investigationLocation?.getDistrict() || "",
              }}
              onChange={(value) =>
                setInvLocation(value?.state || "", value?.district || "")
              }
              stateDistrictRepo={stateDistrictRepo}
            />
          </Grid.Column>
          <Grid.Column>
            <Form.Input
              label="Document Charges"
              value={internalValue.documentExpense}
              onChange={(_, { value }) => {
                internalOnChange("documentExpense", value);
              }}
            />
            <Button
              style={{ width: "177px" }}
              onClick={(e) => {
                setX(e.clientX);
                setY(e.clientY);
                setDocumentReceiptVisible(true);
              }}
              disabled={documentReceiptVisible || documentReceipts.length === 0}
              primary
            >
              {documentReceipts.length === 0
                ? "No Document Receipts"
                : "View Document Receipts"}
            </Button>
          </Grid.Column>
          <Grid.Column>
            <Form.Input
              label="Extra Document Charges(Investigator)"
              value={internalValue.extraExpense}
              onChange={(_, { value }) => {
                internalOnChange("extraExpense", value);
              }}
            />
            <Button
              style={{ width: "177px" }}
              onClick={(e) => {
                setX(e.clientX);
                setY(e.clientY);
                setExpenseReceiptVisible(true);
              }}
              disabled={
                expenseReceiptVisible || extraExpenseReceipts.length === 0
              }
              primary
            >
              {extraExpenseReceipts.length === 0
                ? "No Extra Document Receipts"
                : "View Extra Document" + " Receipts"}
            </Button>
          </Grid.Column>
          <Grid.Column>
            <Form.Input
              label="Approved Document Charges(Invoice)"
              value={internalValue.approvedExpense}
              onChange={(_, { value }) => {
                internalOnChange("approvedExpense", value);
              }}
            />
            <Button
              style={{ width: "177px" }}
              onClick={(e) => {
                setX(e.clientX);
                setY(e.clientY);
                setApprovedReceiptVisible(true);
              }}
              disabled={
                approvedReceiptVisible || approvedExpenseReceipts.length === 0
              }
              primary
            >
              {approvedExpenseReceipts.length === 0
                ? "No Approved Document Receipts"
                : "View Approved" + " Document" + " Receipts"}
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {open &&
        ReactDOM.createPortal(
          <FilesPopup
            x={x}
            y={y}
            onClose={() => setOpen(false)}
            title={`Evidence By - ${model.getInvestigator().getName()}`}
            files={model.getEvidence()}
          />,
          portalRoot
        )}
      {documentReceiptVisible &&
        ReactDOM.createPortal(
          <FilesPopup
            x={x}
            y={y}
            onClose={() => setDocumentReceiptVisible(false)}
            title={`Document Receipts`}
            files={model.getDocumentExpenseReceipt()}
          />,
          portalRoot
        )}
      {expenseReceiptVisible &&
        ReactDOM.createPortal(
          <FilesPopup
            x={x}
            y={y}
            onClose={() => setExpenseReceiptVisible(false)}
            title={`Document Extra Expense Receipts`}
            files={model.getExtraExpenseReceipt()}
          />,
          portalRoot
        )}
      {approvedReceiptVisible &&
        ReactDOM.createPortal(
          <FilesPopup
            x={x}
            y={y}
            onClose={() => setApprovedReceiptVisible(false)}
            title={`Approved Expense Receipts`}
            files={model.getApprovedExpenseReceipt()}
          />,
          portalRoot
        )}
    </>
  );
};

InvestigationCard.initialValue = {
  id: "",
  claimAnalystId: "",
  allocationHygiene: 0,
  reportQuality: 0,
  outcome: 0,
  tat: 0,
  feedback: "",
  investigators: [],
} as InvestigationCardInputValue;

export default InvestigationCard;
