import ActionColumnRenderer from '../../../app/Table/renderer/ActionColumnRenderer'
import LodgingExpenses from '../../../../models/LodgingExpenses'
import moment from 'moment'


export default class LodgingExpensesColumnRenderer extends ActionColumnRenderer<LodgingExpenses> {
    getColumns() {
        return [
            {
                name: 'Investigator Name',
                selector: 'investigator_name',
            },
            {
                name: 'Amount(RS)',
                selector: 'amount',
            },
            {
                name: 'Applied Date',
                selector: 'appliedDate',
                format: (row: {}) => {
                    if (row instanceof LodgingExpenses) {
                        const startDate = row.getAppliedDate()
                        if (startDate !== null) return moment(startDate).format('LL')
                    }
                },
            },
            {
                name: 'Location',
                selector: 'location',
            },
            {
                name: "Notes",
                selector: "notes"
            },
            {
                name: "Type",
                selector: "type"
            },

            {
                name: 'Actions',
                selector: '_id',
                grow: 0,
                width: '180px',
                cell: this.renderActionColumn,
            },
        ]
    }
}
