import React from 'react'
import Page from '../../../layout/Page'
import { Modal, Icon, Button } from 'semantic-ui-react'
import Result from '../../../../common/repository/Result'
import { toast } from '../../../common/Toast'
import RemovalConfirmationForm from '../../../common/RemovalConfirmationForm'
import Table from '../../../app/Table'
import RequirePermission from '../../../base/RequirePermission'
import permissions from '../../../../common/permissions'
import CaseCategoryColumnRenderer from './CaseCategoryColumnRenderer'
import CaseCategoryForm from './CaseCategoryForm'
import CaseCategory from '../../../../models/CaseCategory'
import CaseCategoryRepository from '../../../../common/repository/CaseCategoryRepository'
interface State {
    loading: boolean
    filter: string
    limit: number
    offset: number
    totalvisitPurposeCount: number
    CaseCategory: CaseCategory[]
    add_modalOpen: boolean
    update_modalOpen: boolean
    update_model: CaseCategory | null

    remove_modalOpen: boolean
    remove_model: CaseCategory | null
}

class CaseCategoryPage extends React.Component<{}, State> {

    private CaseCategoryRepo = new CaseCategoryRepository()

    private columnRenderer = new CaseCategoryColumnRenderer(
        (model: CaseCategory) => {
            return (
                <Button.Group size="mini">
                    {console.log(model, "model")}
                    <Button onClick={() => this.setState({ update_model: model, update_modalOpen: true })}>Open</Button>
                    <Button negative onClick={() => this.setState({ remove_model: model, remove_modalOpen: true })}>
                        Remove
                    </Button>
                </Button.Group>
            )
        },
        (obj: any) => obj.title)

    constructor(props: {}) {
        super(props)
        this.state = {
            loading: false,
            filter: '',
            limit: 10,
            offset: 0,
            totalvisitPurposeCount: 0,
            CaseCategory: [],
            add_modalOpen: false,
            update_modalOpen: false,
            update_model: null,
            remove_modalOpen: false,
            remove_model: null,
        }
    }

    componentDidMount() {
        this.load()
    }

    private onRowsPerPageChange = (page: number, rowsPerPage: number) => {
        const offset = (page - 1) * rowsPerPage,
            limit = offset + rowsPerPage
        this.setState({ offset, limit }, this.load)
    }
    private load = () => {
        const { offset } = this.state
        this.setState({ loading: true }, async () => {
            const result = await this.getvisitList()
            if (result instanceof Result.Success) {
                if (offset === 0) {
                    const CaseCategory = result.data.items
                    const totalvisitPurposeCount = result.data.totalItemCount
                    const offset = CaseCategory.length
                    this.setState({ loading: false, offset, CaseCategory, totalvisitPurposeCount })
                } else {
                    const CaseCategory = [...this.state.CaseCategory, ...result.data.items]
                    const offset = CaseCategory.length
                    this.setState({ loading: false, offset, CaseCategory })
                }
            } else {
                this.setState({ loading: false }, () => {
                    const message = result.message || 'Something went wrong'
                    toast.error(message)
                })
            }
        })
    }

    private getvisitList = async () => {
        const ro = {
            search: this.state.filter,
            limit: this.state.limit,
            offset: this.state.offset,
        }
        return await this.CaseCategoryRepo.getCaseCategory(ro)
    }

    private setFilter = (filter: string) => {
        this.setState({ filter, offset: 0 }, this.load)
    }

    private onStatesAdded = (state: CaseCategory) => {
        this.setState((currentVisit) => {
            const CaseCategory = [state, ...currentVisit.CaseCategory]
            const totalvisitPurposeCount = currentVisit.totalvisitPurposeCount + 1
            return { CaseCategory, totalvisitPurposeCount, add_modalOpen: false }
        })
    }

    private onStateUpdated = (state: CaseCategory) => {
        const CaseCategory = this.state.CaseCategory
        const index = CaseCategory.findIndex((it) => it.getId() === state.getId())
        if (index !== -1) CaseCategory[index] = state
        this.setState({ CaseCategory: [...CaseCategory], update_modalOpen: false, update_model: null })
    }

    private removeVisit = async () => {
        const id = this.state.remove_model!.getId()
        const result = await this.CaseCategoryRepo.removeCaseCategory(id)
        if (result instanceof Result.Success) {
            const CaseCategory = this.state.CaseCategory
            let totalvisitPurposeCount = this.state.totalvisitPurposeCount
            const index = CaseCategory.findIndex((it) => it.getId() === id)
            if (index !== -1) {
                CaseCategory.splice(index, 1)
                totalvisitPurposeCount--
            }
            this.setState({ CaseCategory: [...CaseCategory], totalvisitPurposeCount, remove_modalOpen: false, remove_model: null }, () => toast.success(' removed successfully'))
        } else {
            const message = result.message || 'Something went wrong'
            toast.error(message)
        }
    }

    render() {
        return (
            <Page title="Case Category " description="Manage Case Category ">
                {/* Add State Modal */}
                <Modal
                    open={this.state.add_modalOpen}
                    onClose={() => this.setState({ add_modalOpen: false })}
                    size="tiny"
                    closeIcon
                    closeOnDimmerClick={false}
                    closeOnEscape={false}
                    trigger={
                        <Button icon labelPosition="left" color="green" onClick={() => this.setState({ add_modalOpen: true })}>
                            <Icon name="add" />
                            Add New
                        </Button>
                    }
                >
                    <Modal.Header>Add New Case Category</Modal.Header>
                    <Modal.Content>
                        <CaseCategoryForm onSuccess={this.onStatesAdded} />
                    </Modal.Content>
                </Modal>
                {/* Add State Modal */}

                {/* Update State Modal */}
                <Modal open={this.state.update_modalOpen} onClose={() => this.setState({ update_modalOpen: false, update_model: null })} size="tiny" closeIcon closeOnDimmerClick={false} closeOnEscape={false}>
                    <Modal.Header>Update Case Category</Modal.Header>
                    <Modal.Content>
                        <CaseCategoryForm model={this.state.update_model || undefined} onSuccess={this.onStateUpdated} />
                    </Modal.Content>
                </Modal>
                {/* Update state Modal */}

                {/* Remove state Modal */}
                <Modal open={this.state.remove_modalOpen} onClose={() => this.setState({ remove_modalOpen: false, remove_model: null })} size="mini" closeIcon closeOnDimmerClick={false} closeOnEscape={false}>
                    <Modal.Header>Confirmation</Modal.Header>
                    <Modal.Content>
                        <RemovalConfirmationForm title="Are you sure you want to remove this?" remove={this.removeVisit} cancel={() => this.setState({ remove_modalOpen: false, remove_model: null })} />
                    </Modal.Content>
                </Modal>
                {/* Remove state Modal */}

                <Table
                    renderer={this.columnRenderer}
                    data={this.state.CaseCategory}
                    totalDataCount={this.state.totalvisitPurposeCount}
                    loading={this.state.loading}
                    load={this.load}
                    onRowsPerPageChange={this.onRowsPerPageChange}
                    onSearch={this.setFilter}
                    onClear={() => this.setFilter('')}
                />
            </Page>
        )
    }
}

export default (props: any) => {
    return (
        <RequirePermission permission={permissions.Master.CRUD}>
            <CaseCategoryPage {...props} />
        </RequirePermission>
    )
}
