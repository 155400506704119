import React from "react";
import LocationInput, { LocationInputValue } from "../../common/LocationInput";
import FormLabel from "../../common/FormLabel";
import StateDistrictRepository from "../../../common/repository/StateDistrictRepository";
import StatesDistrictsRepository from "../../../common/repository/StatesDistrictsRepository";

interface CaseFormInputLocationProps {
  value: LocationInputValue | null;
  onChange(location: LocationInputValue | null): void;
  stateDistrictRepo: StateDistrictRepository;

  label: string;
  labelWidth?: string;
  erase?: boolean;
  required?: boolean;
}

const CaseFormInputLocation = (props: CaseFormInputLocationProps) => {
  const { label, labelWidth, ...restProps } = props;
  const width = labelWidth || "100px";
  return (
    <LocationInput
      {...restProps}
      inline
      label={<FormLabel width={width}>{label}</FormLabel>}
    />
  );
};

export default CaseFormInputLocation;
