import DomainModel from './DomainModel'

export default class CancelledByInvestigatorList extends DomainModel {
    constructor(public _id: string, private reason: string, private case_state: string, private claim_number: string, private createdAt: string, private updatedAt: string, private name: string) {
        super()
    }

    getId = () => this._id
    getReason = () => this.reason
    getCaseState = () => this.case_state
    getClaimNumber = () => this.claim_number
    getCreatedAt = () => this.createdAt
    getUpdatedAt = () => this.updatedAt
    getInvestigator = () => this.name
}