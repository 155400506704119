import React from "react";
import { Table, Segment } from "semantic-ui-react";

interface DoctorPathologistsDataTableProps {
    data: any;
}

const DoctorPathologistsDataTable = (props: DoctorPathologistsDataTableProps) => {
    const { data } = props;

    return (
        <>
            <Table striped compact celled structured>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Investigator Name</Table.HeaderCell>
                        <Table.HeaderCell>Contact Number</Table.HeaderCell>
                        <Table.HeaderCell>Email</Table.HeaderCell>
                        <Table.HeaderCell width={3}>Pathologist Name</Table.HeaderCell>
                        <Table.HeaderCell>Personal Meeting</Table.HeaderCell>
                        <Table.HeaderCell>Qualification</Table.HeaderCell>
                        <Table.HeaderCell>Hospital</Table.HeaderCell>
                        <Table.HeaderCell>Registration Number</Table.HeaderCell>

                    </Table.Row>
                </Table.Header>
                <Table.Body style={{ fontWeight: 500 }}>

                    {data.map((item: any, index: any) =>
                        <Table.Row key={index}>
                            <Table.Cell>{item.investigatorName}</Table.Cell>
                            <Table.Cell width={3}>{item.contactNumber}</Table.Cell>
                            <Table.Cell>{item.email || "--"}</Table.Cell>
                            <Table.Cell width={3}>{item.pathologistName}</Table.Cell>
                            <Table.Cell>{item.personalMeeting}</Table.Cell>
                            <Table.Cell>{item.qualification}</Table.Cell>
                            <Table.Cell>{item.hospitalData.name}</Table.Cell>
                            <Table.Cell>{item.registrationNumber}</Table.Cell>

                        </Table.Row>)}
                    <Table.Row>
                    </Table.Row>
                </Table.Body>
            </Table>
            {!data.length && <Segment basic textAlign={"center"}>
                No Record Found
            </Segment>
            }
        </>
    );
};

export default React.memo(DoctorPathologistsDataTable);
